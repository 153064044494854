import { Tooltip } from "./tooltip.model";

export const TooltipIds: {
  [key: string]: Tooltip["Id"];
} = {

  // User Communications page
  DAILY_REMINDER_EMAIL: 1,
  WEEKLY_REMINDER_EMAIL: 2,
  MONTLY_REMINDER_EMAIL: 3,
  DO_NOT_SEND_REMINDER_EMAIL: 4,

  // Steps ADMIN Settings page
  THEME_CHOOSE: 5,
  THEME_VERSES: 6,
  THEMED_PLAN: 7,
  PLATFORM_PAGE: 8,
  PLATFORM_PLAN: 9,
  CUSTOM_LINK: 10,

  // Modals
  MODAL_SURVEY: 11,
  MODAL_EMPTY_MESSAGE_AREA: 12,
  MODAL_CREATE_COLLECTION_HELP: 13,
  MODAL_CREATE_FOLDER_HELP: 14,
  MODAL_CREATE_PERSONA_SUB_ACCOUNT_HELP: 15,
  MODAL_PHONE_NUMBER: 16,
};

export const TooltipsCollections: {
  [key: string]: Record<string, Tooltip["Id"]>
} = {
  MASTER_REPORT_SUMMARY: {
    unique_users: 17,
    unique_guests: 18,
    page_loads: 19,
    with_plevel: 20,
    plevel_retakes: 21,
    first_time_plevel: 22,
    theme_picker_pages: 23,
    theme_verse_pages: 24,
  }
}
