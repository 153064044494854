import { takeWhile, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Umbrella, IUmbrella } from './umbrella';
import { UmbrellaService } from './umbrella.service';
import { list } from '@animations';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { IServiceStates } from '@rallysite/global-interfaces';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { AccountService } from '@board-accounts/account.service';
import { Account } from '@board-accounts/account.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'board-umbrella-list',
  templateUrl: './umbrella-list.component.html',
  styleUrls: ['./umbrella-list.component.scss'],
  animations: [list],
})
export class UmbrellaListComponent implements OnInit, OnDestroy {
  alive = true;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  @Input() currentItem: any;
  @Input() umbrella: Umbrella;
  @Input() account: Account;

  states: IServiceStates;
  state: number;

  umbrellas$: Observable<Umbrella[]>;
  umbrellas: Umbrella[];

  archiveUmbrella: IUmbrella;
  startupUmbrella: IUmbrella;

  constructor(
    private messageBus: MessageBusService,
    private umbrellaService: UmbrellaService,
    private accountService: AccountService,
    public dialog: MatDialog
  ) {
    this.states = UmbrellaService.STATES;
  }

  open(umbrella: Umbrella) {
    this.umbrellaService.openUmbrella(umbrella);
  }

  toggleOpen(umbrella: Umbrella | IUmbrella) {
    const openState = umbrella._open;
    this.umbrellas.forEach(u => u._open = false);
    umbrella._open = !openState;
  }

  trackByFn(index: number, umbrella: Umbrella): string {
    return umbrella.Id;
  }

  private loadUmbrellas(account: Account) {
    let loaded = false;
    this.umbrellaService.umbrellas$.pipe(
      filter(umbrellas => {
        return !!umbrellas && umbrellas[0].AccountId === account.Id;
      }),
      takeWhile(() => !loaded))
      .subscribe((umbrellas) => {
        loaded = true;
        this.umbrellas = umbrellas;
        this.archiveUmbrella = this.umbrellas.find(u => u.isArchive);
        this.startupUmbrella = this.umbrellas.find(u => u.isStartUp);

        this.messageBus.publish(MessageBusActions.UMBRELLAS_LOADED);
      });
    this.umbrellaService.loadAll(this.account);
  }

  ngOnInit() {
    this.umbrellaService.loadingState.subscribe(state => {
      this.state = state;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['account'] && changes['account'].currentValue) {
      this.loadUmbrellas(this.account);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.umbrellas, event.previousIndex, event.currentIndex);
    this.accountService.orderUmbrellas(this.account, { id: this.umbrellas[event.currentIndex].Id, index: event.currentIndex });
  }
}
