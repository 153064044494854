import {ChangeDetectionStrategy, Component} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ShareOverlayRef} from './share-overlay-ref';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SocialShareService} from '../social-share.service';
import {SocialShare} from '../social-share.enum';

@Component({
  selector: 'share-overlay',
  styleUrls: ['./share-overlay.component.scss'],
  templateUrl: './share-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})

export class ShareOverlayComponent {

  constructor(
    private shareOverlayRef: ShareOverlayRef,
    private formBuilder: FormBuilder,
    private shareService: SocialShareService
  ) {
  }

  // convenience getter for easy access to form fields
  get f() { return this.shareForm.controls; }

  readonly LINK_COPY = SocialShare.LINK_COPY;

  shareLink: string;


  /** FORM */
  shareForm: FormGroup;

  submitted = false;
  /** end of FORM */

  copied = false;
  copiedError = false;
  bootstrapForm() {
    this.shareForm = this.formBuilder.group({
      link: [{ value: this.shareLink, disabled: true }, [Validators.required]],
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.shareForm.invalid) {
      return false;
    }
    return true;
  }
  copySuccess(data) {
    if (!this.copied) {
      this.shareService.countShare(SocialShare.LINK_COPY);
    }
    this.copied = true;

    // this.alertService.snackSuccess(`Page link successfully copied!`, { duration: 2000 })
    // this.dialogRef.close();
  }
  copyError(data) {
    this.copiedError = true;
    // this.alertService.snackError(`There was an error copying the page link!`)
    // this.dialogRef.close();
  }

  onClose() {
    this.shareOverlayRef.close();
  }

  ngOnInit() {
    this.shareLink = this.shareService.getPageLink();
    this.bootstrapForm();
    this.shareService.countClicks();
  }
}
