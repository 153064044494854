import { generateId } from "../generate_block_id";
import { IModalBlock } from "../modal-block.interface";
import {
    ModalBlockAction,
    ModalBlockType,
    MODAL_BLOCK_PREFIX
} from "../types";
import { ModalImage } from "./image/modal-image";

export class ModalImageBlock implements IModalBlock {
    Type: ModalBlockType = ModalBlockType.IMAGE;
    ModalId: string;
    Id: string;
    BlockId: string;
    CreateDate: string;
    UpdateDate: string;

    _httpAction: ModalBlockAction = ModalBlockAction.UPDATE;

    private _image: ModalImage;
    set Content(value: ModalImage) {
        this._image = value instanceof ModalImage ? value : new ModalImage(value)
    }
    get Content(): ModalImage {
        return this._image;
    }

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${MODAL_BLOCK_PREFIX.IMAGE}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
        if (!this._image.BlockId) {
            this._image.BlockId = this.BlockId;
        }
    }

    toDb() {
        return {
            Id: this.Id,
            ModalId: this.ModalId,
            BlockId: this.BlockId,
            Content: this.Content.toDb(),
            Type: this.Type,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }
}
