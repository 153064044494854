import { ISize } from "./size.class";

export interface IGalleryImage {
    id: string;

    small: IGalleryImageSize;
    medium: IGalleryImageSize;
    large: IGalleryImageSize;

    model?: any;

    src: string;
    loadedInGallery: boolean;
    loadedInViewer: boolean;
    activeInViewer: boolean

    loading: boolean;
}

export interface IGalleryImageSize {
    url: string;
    width: number;
    height: number;
    loaded?: boolean;
}

export class GalleryImage implements IGalleryImage {

    id: string;

    small: IGalleryImageSize;
    medium: IGalleryImageSize;
    large: IGalleryImageSize;

    naturalWidth: number;
    naturalHeight: number;
    
    width: number;
    height: number;

    model: any;

    src: string = '';
    loadedInGallery: boolean = false;
    loadedInViewer: boolean = false;
    activeInViewer: boolean = false;
    loading: boolean = false;

    uploading: boolean = false;

    constructor(data: any, sizes: {
        small: ISize, medium: ISize, large: ISize
    }) {

        for (let prop in data) {
            switch (prop) {
                case 'small':
                case 'medium':
                case 'large':
                    this[prop] = {
                        url: data[prop],
                        width: sizes[prop].width,
                        height: sizes[prop].height
                    }
                    break;
                default:
                    this[prop] = data[prop];
            }
        }

    }

}