<form [formGroup]="form">
  <mat-slide-toggle class="mgc-toggle" formControlName="enableClosingModal"
                    [checked]="this.form.controls.enableClosingModal.value"> Allow user to close modal.
  </mat-slide-toggle>
  <p class="field-description">Yes means enable the closing 'X' in the top right corner. No means hide closing 'X'.</p>

  <mgc-form-field label="Frequency" class="mb-4">
    <mat-select formControlName="closingFrequency">
      <mat-option value="one">One</mat-option>
      <mat-option value="all">All</mat-option>
    </mat-select>
  </mgc-form-field>
  <p class="field-description">Frequency if closed. This is per visit. One means once per visit. All means show on every page per visit.</p>
</form>
