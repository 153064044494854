import { DoNotShowAgainOption, IDoNotShowAgainOption, DNSA_OPTIONS } from "./do-not-show-again/do-not-show-again.options.class";

export interface IUserSettings {
    accounts: Array<string>;
    doNotShowAgain: IDoNotShowAgainOption[];

    toDb();
}

export class UserSettings implements IUserSettings {
    accounts: Array<string> = [];
    doNotShowAgain: IDoNotShowAgainOption[];

    constructor(private data: any) {
        for (let prop in data) {
            if (prop.toLowerCase() === 'donotshowagain') {
                this.doNotShowAgain = this.handleDNSA(data[prop]);
            } else {
                this[prop] = data[prop];
            }
        }

        if (!this.doNotShowAgain) {
            this.doNotShowAgain = this.handleDNSA([]);
        }
    }

    private handleDNSA(data: Array<string>): IDoNotShowAgainOption[] {
        let options: DoNotShowAgainOption[] = [];
        for (let o in DNSA_OPTIONS) {
            let id = DNSA_OPTIONS[o].id;
            options.push(new DoNotShowAgainOption(id, !!data.find(eId => eId === id)));
        }
        return options;
    }

    toDb() {
        return {
            accounts: this.accounts,
            doNotShowAgain: this.doNotShowAgain.filter(dnsa => dnsa.checked).map(dnsa => {
                return dnsa.id;
            })
        }
    }
}