<p [style.text-align]="'center'">Please add your name.</p>

<form [formGroup]="form">
    <div class="content">
        <div class="mgc-row">
            <mgc-form-field label="First Name*" class="mgc-col" [invalid]="submitted && f.FirstName.errors">
                <div class=" field-wrapper">
                    <input tabindex="-1" formControlName="FirstName" name="FirstName">
                </div>
                <ng-container invalid-feedback *ngIf="submitted && f.FirstName.errors">
                    <div *ngIf="f.FirstName.errors.required">First name is required</div>
                    <div *ngIf="f.FirstName.errors.shouldNotMatch">First name is matching your email <strong
                            [innerHTML]="user.Email"></strong>. Please use some other first name.</div>
                </ng-container>
            </mgc-form-field>
        </div>

        <div class="mgc-row">
            <mgc-form-field label="Last Name *" class="mgc-col" [invalid]="submitted && f.LastName.errors">
                <div class=" field-wrapper">
                    <input tabindex="-1" formControlName="LastName" name="LastName">
                </div>
                <ng-container invalid-feedback *ngIf="submitted && f.LastName.errors">
                    <div *ngIf="f.LastName.errors.required">Last name is required</div>
                </ng-container>
            </mgc-form-field>
        </div>
    </div>
</form>