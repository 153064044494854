import { ComponentRef, Directive, ElementRef, Input, OnInit, EventEmitter, Output, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ConfirmOverlayComponent } from './confirm-overlay.component';
import { ConfirmOverlayRef } from './confirm-overlay-ref';

@Directive()
@Injectable()
export class ConfirmOverlayService {

    @Input('confirm') text = 'You sure?';
    private overlayRef: OverlayRef;
    private elementRef: ElementRef

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private overlayPositionBuilder: OverlayPositionBuilder,
        // private elementRef: ElementRef
    ) {
    }

    open(el, text: string = null) {
        this.elementRef = el;
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        // Instantiate remote control
        const dialogRef = new ConfirmOverlayRef(this.overlayRef);
        const injector = this.createInjector(dialogRef);

        const confirmRef: ComponentRef<ConfirmOverlayComponent>
            = this.overlayRef.attach(new ComponentPortal(ConfirmOverlayComponent, null, injector));
        confirmRef.instance.text = text || this.text;

        // Subscribe to a stream that emits when the backdrop was clicked
        this.overlayRef.backdropClick().subscribe(_ => dialogRef.close(false));

        return dialogRef;
    }

    private createInjector(dialogRef: ConfirmOverlayRef): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(ConfirmOverlayRef, dialogRef);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                offsetY: -5,
                offsetX: 10
            }]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'confirm-overlay-backdrop',
            panelClass: 'confirm-overlay',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
            width: '150px'
        });

        return overlayConfig;
    }

    //     @HostListener('click')
    //     show() {
    //         if (this.overlayRef.hasAttached()) {
    //             this.overlayRef.detach();
    //             return;
    //         }

    //         const tooltipRef: ComponentRef<ConfirmOverlayComponent>
    //             = this.overlayRef.attach(new ComponentPortal(ConfirmOverlayComponent));
    //         tooltipRef.instance.text = this.text;
    //     }

    //     @HostListener('mouseout')
    //     hide() {
    //         // this.overlayRef.detach();
    //     }
}
