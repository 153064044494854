import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { SQOptionsDropdownComponent } from './dropdown/sq-options-dropdown.component';
import { SQOptionsComponent } from './sq-options.component';
import { MgcDividerModule, MgcFormFieldModule } from '@rallysite/mgc-components';
import { ReactiveFormsModule } from '@angular/forms';
import { SQOptionsMultipleComponent } from './multiple/sq-options-multiple.component';
import { SQOptionsSingleComponent } from './single/sq-options-single.component';
import { SQOptionsStringComponent } from './string/sq-options-string.component';
import { SQOptionsYearComponent } from './year/sq-options-year.component';
import { SQOptionsNumberComponent } from './number/sq-options-number.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,

    MgcDividerModule,
    MgcFormFieldModule,
  ],

  declarations: [
    SQOptionsComponent,
    SQOptionsDropdownComponent,
    SQOptionsMultipleComponent,
    SQOptionsSingleComponent,
    SQOptionsStringComponent,
    SQOptionsYearComponent,
    SQOptionsNumberComponent,

  ],
  exports: [
    SQOptionsDropdownComponent,
    SQOptionsMultipleComponent,
    SQOptionsSingleComponent,
    SQOptionsStringComponent,
    SQOptionsYearComponent,
    SQOptionsNumberComponent,
  ]
})
export class SurveyQuestionTypesModule { }
