
import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserService } from './user.service';
import { CentralDetailsComponent } from './central-details.component';
import { User } from './user.model';

import { IAlertsClass, ServiceAlertClass, AlertService } from '@rallysite/components/alert';

@Component({
  selector: 'user-modal',
  templateUrl: './user-modal.html',
})
export class UserModal implements OnInit, OnDestroy {
  private alive: boolean = true;
  state: number;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CentralDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      user: User
    }) {
  }

  get states() {
    return UserService.STATES;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.userService.resetProcessingState$().pipe(
    //   takeWhile(() => this.alive))
    //   .subscribe(state => {
    //     this.state = state;
    //     if (this.state === this.states.DONE) {
    //       this.dialogRef.close();
    //     } else if (this.state === this.states.UNAUTHORIZED) {
    //       this.dialogRef.close('unauthorized');
    //     }
    //   });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
