import {ModalVideoBlock} from "@board/modals/modals-editor/modal-factory/blocks/video-block/video-block";
import {IModalBlock} from "@board/modals/modals-editor/modal-factory/blocks/modal-block.interface";

export class Modal {
    Id: string | number | null;
    SystemName: string;
    InternalName: string;
    ModalType: string;
    InternalDescription: string;
    IsEnabled: boolean;
    Title: string;
    Description: string;
    Config: Record<string, any>;
    CreateDate: string;
    UpdateDate: string;
    BlocksMetadata: any;
    blocks?: IModalBlock[];

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
          Id: this.Id,
          SystemName: this.SystemName,
          InternalName: this.InternalName,
          ModalType: this.ModalType,
          InternalDescription: this.InternalDescription,
          IsEnabled: this.IsEnabled,
          Title: this.Title,
          Description: this.Description,
          Config: this.Config,
          CreateDate: this.CreateDate,
          UpdateDate: this.UpdateDate,
        }
    }

    toJSON() {
        return {
          Id: this.Id,
          SystemName: this.SystemName,
          InternalName: this.InternalName,
          ModalType: this.ModalType,
          InternalDescription: this.InternalDescription,
          IsEnabled: this.IsEnabled,
          Title: this.Title,
          Description: this.Description,
          Config: this.Config,
          CreateDate: this.CreateDate,
          UpdateDate: this.UpdateDate,
        }
    }

}
