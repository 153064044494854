import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ReactionsOverlayComponent } from './reactions-overlay.component';
import { ReactionsOverlayRef } from './reactions-overlay-ref';
import { NoteReaction, CommentReaction } from './reaction.model';

@Injectable()
export class ReactionsOverlayService {

    private overlayRef: OverlayRef;
    private elementRef: ElementRef

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private overlayPositionBuilder: OverlayPositionBuilder,
    ) {
    }

    open(el, currentReaction: NoteReaction | CommentReaction) {
        this.elementRef = el;
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        // Instantiate remote control
        const dialogRef = new ReactionsOverlayRef(this.overlayRef);
        const injector = this.createInjector(dialogRef);

        const ref: ComponentRef<ReactionsOverlayComponent>
            = this.overlayRef.attach(new ComponentPortal(ReactionsOverlayComponent, null, injector));
        ref.instance.currentReaction = currentReaction;

        // Subscribe to a stream that emits when the backdrop was clicked
        this.overlayRef.backdropClick().subscribe(_ => this.overlayRef.detach());

        return dialogRef;
    }

    private createInjector(dialogRef: ReactionsOverlayRef): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(ReactionsOverlayRef, dialogRef);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                offsetY: -5,
                offsetX: 0
            }]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'reactions-overlay-backdrop',
            panelClass: 'reactions-overlay',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
            width: '220px'
        });

        return overlayConfig;
    }
}
