<mgc-loader *ngIf="!ready"></mgc-loader>

<form [formGroup]="settingsForm" *ngIf="ready">

  <!-- <div class="mgc-row">
    <mgc-form-field label="Plan Name *" class="mgc-col-sm-6" [class.disabled]="!allowUpdate"
      [invalid]="submitted && f.Name.errors">
      <div class="field-wrapper">
        <input tabindex="-1" formControlName="Name">
        <span class="placeholder" *ngIf="!f.Name.value">Plan name</span>
      </div>
      <ng-container invalid-feedback *ngIf="submitted && f.Name.errors">
        <div *ngIf="f.Name.errors.required">Plan name is required</div>
      </ng-container>
    </mgc-form-field>
  </div> -->

  <div class="mgc-row">
    <mgc-form-field label="Choose collection" *ngIf="!data.umbrella.isStartUp" class="mgc-col-sm-6">
      <mat-icon svgIcon="board-mat:collection"></mat-icon>
      <div class="field-wrapper">
        <mat-select formControlName="umbrella" name="umbrella">
          <mat-option [value]="defaultUmbrella">
            <span [innerHTML]="defaultUmbrella.Name"></span>
          </mat-option>
          <ng-container *ngFor="let umbrella of umbrellas | sortUmbrellas">
            <mat-option *ngIf="!umbrella.isStartUp || !project.Id" [value]="umbrella">
              <mat-icon svgIcon="board-mat:collection"></mat-icon>
              <span [innerHTML]="umbrella.Name"></span>
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </mgc-form-field>
  </div>

</form>