import {NgModule} from "@angular/core";
import {GlobalModalsComponent} from "./global-modals.component";
import {AssessmentModalComponent} from "./modal/assessment/assessment-modal.component";
import {MgcButtonModule, MgcDialogModule} from "@rallysite/mgc-components";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {SurveysDataModule} from "@libraries/surveys/surveys/survey-data.module";
import {CommonModule} from "@angular/common";
import {MissedModalComponent} from "./modal/missed/missed-modal.component";
import {TaskV2Module} from "@items/task-v2/task-v2.module";
import {ModalsModule} from "@board/modals/modals.module";
import {StreakModalComponent} from "./modal/streak/streak-modal.component";

@NgModule({
  imports: [
    MgcDialogModule,
    MgcButtonModule,
    MatDialogModule,
    MatIconModule,
    SurveysDataModule,
    CommonModule,
    TaskV2Module,
    ModalsModule,
  ],

  declarations: [
    GlobalModalsComponent,
    AssessmentModalComponent,
    MissedModalComponent,
    StreakModalComponent,
  ],

  exports: [
    GlobalModalsComponent
  ]
})
export class GlobalModalsModule {}
