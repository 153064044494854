import { Component, Input, Inject, ViewChild } from "@angular/core";
import { ServiceStates } from "@rallysite/global-interfaces";
import { GalleryOrganizerComponent } from "./gallery-organizer.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MultiGalleryComponent } from "@libraries/multi-gallery";

@Component({
    templateUrl: 'gallery-organizer-modal.component.html'
})
export class GalleryOrganizerModalComponent {

    @Input() items: Array<any>;

    @ViewChild(GalleryOrganizerComponent) galleryOrganizer: GalleryOrganizerComponent;
    @ViewChild(MultiGalleryComponent) multiGallery: MultiGalleryComponent;

    state: number;
    get states() {
        return ServiceStates.STATES;
    }

    galleryItems: Array<any>;
    galleryOptions: any;
    imageQuality: string;

    showMultiGallery: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<GalleryOrganizerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            imageQuality: string,
            items: Array<any>,
            galleryOptions: {
                tokenGetter: () => string,
                categories: Array<any>
            }
        }
    ) {
        this.galleryItems = data.items;
        this.galleryOptions = data.galleryOptions;
        this.imageQuality = data.imageQuality;
    }

    closeDialog() {
        switch (true) {
            case !!this.galleryOrganizer:
                this.dialogRef.close();
                break;
            case !!this.multiGallery:
                this.toggleMultiGallery(false);
                break;
        }
    }


    get processing(): boolean {
        return false;
    }

    onProcess() {
        switch (true) {
            case !!this.galleryOrganizer:
                this.dialogRef.close(this.galleryItems);
                break;
            case !!this.multiGallery:
                this.multiGallery.selectedFiles.forEach(image => {
                    this.galleryItems.push(image);
                });
                this.toggleMultiGallery(false);
                break;
        }
    }

    toggleMultiGallery(value: boolean = null) {
        if (typeof value === 'boolean') {
            this.showMultiGallery = value;
        } else {
            this.showMultiGallery = !this.showMultiGallery;
        }
    }
}
