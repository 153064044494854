
import { filter, takeWhile, map, catchError, take } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { Umbrella, IUmbrella } from '@board/items/umbrella';
import { Project, TargetProject } from './project';
import { BaseService } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';
import { CurrentIdService } from '@board/_services/current-id.service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { MessageBusService, MessageBusActions, WINDOW } from '@rallysite/global-services';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Notification } from '@board/_components/notification';
import { CounterService } from '@libraries/counter/counter.service';
import { environment } from 'environments/environment';
import { RedirectModal } from './modal/redirect-modal';
import { MatDialog } from '@angular/material/dialog';
import { IPromoImageMetadata } from '@panel-components/publish/promo-section/promo-image-metadata';
import { IThemeOptions } from '@libraries/theming';
import { Brand } from '@board-brands/brand.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {

  private _projects: any;
  private _project: BehaviorSubject<Project>;
  private dataStore: {
    projects: any,
    projectsStarting: Array<any>,
  }

  private targetProjects: {
    [id: string]: TargetProject
  } = {};

  private _loadingState: BehaviorSubject<number>;
  private _processingState: BehaviorSubject<number>;

  private _endPoint: string;

  private _projectsStarting: any

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(WINDOW) private window: Window,
    private dialog: MatDialog,
    private targetService: TargetResourceService,
    private messageBus: MessageBusService,
    private currentIdService: CurrentIdService,
    private alertService: AlertService,
    private counterService: CounterService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/projects`;
    this.dataStore = { projects: [], projectsStarting: [] };
    this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    this._projects = {};
    this._project = <BehaviorSubject<Project>>new BehaviorSubject(null);

    this._projectsStarting = {};
  }

  get processingState$(): Observable<number> {
    return this._processingState.asObservable();
  }
  resetProcessingState$(): Observable<number> {
    this._processingState.next(-1);
    return this.processingState$;
  }

  getProjects$(umbrella: IUmbrella): Observable<Project[]> {
    if (!this._projects[umbrella._uid]) {
      this._projects[umbrella._uid] = {
        _projects: <BehaviorSubject<Project[]>>new BehaviorSubject(null),
        _loadingState: <BehaviorSubject<number>>new BehaviorSubject(-1)
      }
    }
    return this._projects[umbrella._uid]._projects.asObservable();
  }
  loadingState$(umbrella: IUmbrella): Observable<number> {
    return this._projects[umbrella._uid]._loadingState.asObservable();
  }

  getStartingProjects$(umbrella: IUmbrella): Observable<Array<any>> {
    if (!this._projectsStarting[umbrella._uid]) {
      this._projectsStarting[umbrella._uid] = <BehaviorSubject<Array<any>>>new BehaviorSubject(null);
    }

    return this._projectsStarting[umbrella._uid].asObservable();
  }

  get project$(): Observable<Project> {
    return this._project.asObservable();
  }
  get project(): Project {
    return this._project.getValue();
  }

  resetProject() {
    this._project.next(null);
    return this.project$;
  }

  loadAll(umbrella: IUmbrella, force: boolean = false) {
    if (!umbrella) return;

    if (this.inStorage(umbrella) && !force) {
      this.fetch(umbrella);

      // if (this.targetService.targetLevel > 0) {
      //   this.setCurrentProjectFromTarget(umbrella);
      // }
      return;
    }

    this._projects[umbrella._uid]._loadingState.next(ProjectService.STATES.LOADING);
    this.dataStore.projects[umbrella._uid] = [];
    this.dataStore.projectsStarting = [];

    let params = new HttpParams({
      fromObject: {
        'filter[AccountId]': umbrella.AccountId,
        'filter[_umbrellaId]': umbrella.Id
      }
    });
    this.http.get(this._endPoint, { params: params })
      .subscribe(
        data => {
          for (let key in data) {
            let project = data[key];
            if (project['_isStarting']) {
              this.dataStore.projectsStarting.push(project);
            } else {
              this.dataStore.projects[umbrella._uid].push(new Project(project, umbrella.Id, umbrella._uid))
            }
          };
          this.fetch(umbrella);

          if (this.targetService.targetLevel > 0) {
            this.setCurrentProjectFromTarget(umbrella);
          }
        },
        error => {
          console.log('Could not load projects');
          delete (this.dataStore.projects[umbrella._uid]);
          this._projects[umbrella._uid]._loadingState.next(ProjectService.STATES.ERROR);
          this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_LIST)
        }
      )
  }

  getProjectByRequest(projectByRequest, umbrella) {
    let endPoint = `${this.config.endpoint}/api/project-request`;
    return this.http.get(`${endPoint}/${projectByRequest.RequestId}`).pipe(
      map(data => {
        if (data['status'] == 'done') {
          let project = new Project(data['project'], umbrella.Id, umbrella._uid);
          this.addUI(project, umbrella._uid);

          return project;
        }
        projectByRequest._status = data['status'];
        console.log('project status: ' + data['status']);
        return null;
      }), catchError(error => {
        return observableOf(null);
      })
    )
  }

  /**
   * @param project
   */
  getProjectPubdata(project: Project): Observable<any> {
    if (project._pubData) {
      return observableOf(project._pubData)
    }

    return this.http.get(`${this._endPoint}/${project.Id}/pubdata`).pipe(
      map(data => {
        project._pubData = data;
        return project._pubData;
      }),
      catchError(error => {
        console.log('Could not get  getProjectPubdata.');
        return observableOf(null);
      })
    )
  }


  tagProject(project: Project, tagIds: string[]) {
    return this.http.put(`${this._endPoint}/${project.Id}/tag-it`, { tagIds: tagIds }).pipe(
      map(data => {
        project._pubData['_selectedTags'] = tagIds;
        return true;
      }), catchError(error => {
        return observableOf(null);
      })
    )
  }

  ptagProject(project: Project, tagIds: string[]) {
    return this.http.put(`${this._endPoint}/${project.Id}/ptag-it`, { tagIds: tagIds }).pipe(
      map(data => {
        project._pubData['_p_selectedTags'] = tagIds;
        return true;
      }), catchError(error => {
        return observableOf(null);
      })
    )
  }

  /**
   * This method is actioned by QueryRequestService
   *
   * @param id
   */
  loadTarget(id: string) {
    if (this.targetProjects[id]) {
      return observableOf(this.targetProjects[id]);
    }

    return this.http.get(`${this._endPoint}/${id}/target`).pipe(
      map(data => {
        this.targetProjects[id] = new TargetProject(data);
        return this.targetProjects[id]
      }), catchError(error => {
        console.log('Could not load target project');
        return observableOf(null);
      })
    )
  }

  create(project: Project, umbrella: Umbrella) {
    let umbrellaInView = project._umbrellaId === umbrella.Id || umbrella._open;
    project._umbrellaId = umbrella.Id;
    return this.http.post(this._endPoint, project.toDb()).pipe(
      map(data => {
        let newProject = new Project(data, umbrella.Id, umbrella._uid);
        this.addUI(newProject, umbrella._uid);

        if (umbrellaInView) {
          this.fetch(umbrella);
        }
        return newProject;
      }), catchError(error => {
        console.log('Could not create project.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_CREATE)
        return observableOf(null);
      })
    )
  }

  update(project: Project, umbrella: Umbrella, allowUpdate: boolean = true) {
    let prevUmbrellaUid: string;
    if (project._umbrellaId !== umbrella.Id) {
      prevUmbrellaUid = umbrella.AccountId + '_' + project._umbrellaId;
      project._umbrellaId = project._umbrellaId + '|' + umbrella.Id;
    }

    let request = this.http.put(`${this._endPoint}/${project.Id}`, project.toDb());
    if (!allowUpdate) {
      request = this.http.put(`${this._endPoint}/${project.Id}/collection`, {
        UpdateDate: project.UpdateDate,
        _umbrellaId: project._umbrellaId,
      });
    }

    return request.pipe(
      map(data => {
        project.__update(Object.assign(data, {
          _umbrellaId: umbrella.Id,
          _umbrellaUId: umbrella._uid
        }));

        if (prevUmbrellaUid) {
          this.removeUI(project, prevUmbrellaUid).addUI(project, umbrella._uid);
          this._project.next(project);
        }

        if (data['__accountMetadata']) {
          this.messageBus.publish(MessageBusActions.ACCOUNT_METADATA, data['__accountMetadata']);
        }

        this.fetch(null, prevUmbrellaUid);
        this.fetch(umbrella);

        return project;
      }), catchError(error => {
        console.log('Could not update project.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_UPDATE);
        return observableOf(null);
      })
    )
  }

  saveProjectTheme(project: Project, theme: IThemeOptions): Observable<Project> {
    this.alertService.clear();
    let payload = {
      theme: theme
    }

    return this.http.put(`${this._endPoint}/${project.Id}/settings/theme`, payload).pipe(
      map(data => {
        project.__update({
          Settings: { ...project.Settings, ...{ theme: data['theme'] } },
          UpdateDate: data['UpdateDate']
        });
        return project;
      }), catchError(error => {
        console.log('Could not update project theme.');
        this.alertService.error("Could not update the project theme!", ServiceAlertClass.ALERTS.PROJECT_UPDATE);
        return observableOf(null);
      })
    )
  }

  updatePublishSettings(project: Project) {
    return this.http.put(`${this._endPoint}/${project.Id}/pubset`, project.toDbPublish()).pipe(
      map(data => {
        project.__update(data);
        if (project.BrandId) {
          this.messageBus.publish(MessageBusActions.PUBLISH_DATA_UPDATE, project.BrandId);
        }
        return project;
      }), catchError(error => {
        console.log('Could not update project.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_UPDATE);
        return observableOf(null);
      })
    )
  }

  updateHeroImageMetadata(project: Project, heroImageMetadata: IPromoImageMetadata): Observable<Project> {
    let payload = {
      heroImage: heroImageMetadata
    }

    return this.http.put(`${this._endPoint}/${project.Id}/metadata/hero`, payload).pipe(
      map(data => {
        project.__update({
          Metadata: { ...project.Metadata, ...{ heroImage: data['heroImage'] } },
          UpdateDate: data['UpdateDate']
        });

        return project;
      }), catchError(error => {
        console.log('Could not update hero image metadata.');
        this.alertService.error("Could not update the promo image!", ServiceAlertClass.ALERTS.PROJECT_UPDATE);
        return observableOf(null);
      })
    )
  }

  updateLandingHeroImageMetadata(project: Project, landingHeroImageMetadata: IPromoImageMetadata): Observable<Project> {
    let payload = {
      landingHeroImage: landingHeroImageMetadata
    }
    return this.http.put(`${this._endPoint}/${project.Id}/metadata/landingHero`, payload).pipe(
      map(data => {
        project.__update({
          Metadata: { ...project.Metadata, ...{ landingHeroImage: data['landingHeroImage'] } },
          UpdateDate: data['UpdateDate']
        });
        return project;
      }), catchError(error => {
        console.log('Could not update landingHero image metadata.');
        this.alertService.error("Could not update the landingHero image!", ServiceAlertClass.ALERTS.PROJECT_UPDATE);
        return observableOf(null);
      })
    )
  }

  checkVisibleNameAvailability(uname: string, brand: Brand, project: Project): Observable<Project> {
    this.alertService.clear();
    let endPoint = `${this._endPoint}/${project.Id}/checkName?name=${uname}`;
    if (brand) {
      endPoint += `&brandId=${brand.Id}`;
    }

    return this.http.get(endPoint).pipe(
      map(data => {
        return data['uname'];
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_CHECK_NAME)
        return observableOf(null);
      })
    )
  }

  show(project: Project) {
    if (!project.Id) {
      return observableOf(project);
    }
    this.currentIdService.currentParticipantId = project._participant.Id;

    if (project._resmap) {
      this._project.next(project);
      return observableOf(project);
    }

    this._loadingState.next(ProjectService.STATES.LOADING);
    return this.http.get(`${this._endPoint}/${project.Id}`).pipe(
      map(data => {
        project._resmap = data['_resmap'];
        project._permap = data['_permap'];
        if (data['_contest']) {
          project._contest = data['_contest'];
        }

        this._project.next(project);
        this._loadingState.next(ProjectService.STATES.DONE);
        return project;
      }), catchError(error => {
        console.log('Could not open project.');
        if (error.status === 401) {
          this._loadingState.next(ProjectService.STATES.UNAUTHORIZED);
        } else {
          this._loadingState.next(ProjectService.STATES.ERROR);
        }
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_VIEW);
        return observableOf(null);
      }));
  }


  getStatistics(project: Project) {
    if (project._statistics) {
      return observableOf(project._statistics);
    }

    return this.http.get(`${this._endPoint}/${project.Id}/statistics`).pipe(
      map(data => {
        project._statistics = data;
        return project._statistics;
      }), catchError(error => {
        console.log('Could not get statistics.');
        return observableOf(null);
      }));
  }

  orderGroups(project: Project, payload: { id: string, index: number }) {
    this.http.put(`${this._endPoint}/${project.Id}/metadata/groups`, payload)
      .subscribe(
        data => {
          project.__update({
            Metadata: data['Metadata'],
            UpdateDate: data['UpdateDate']
          });

          this._processingState.next(ProjectService.STATES.DONE);
        },
        error => {
          console.log('Could not order project groups.');
          if (error.status === 401) {
            this._processingState.next(ProjectService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(ProjectService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  orderTasks(project: Project, payload: { groupId: string, id: string, index: number }) {
    this.http.put(`${this._endPoint}/${project.Id}/metadata/tasks`, payload)
      .subscribe(
        data => {
          project.__update({
            Metadata: data['Metadata'],
            UpdateDate: data['UpdateDate']
          });

          this._processingState.next(ProjectService.STATES.DONE);
        },
        error => {
          console.log('Could not order tasks.');
          if (error.status === 401) {
            this._processingState.next(ProjectService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(ProjectService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  readNotifications(project: Project) {
    if (project._isNew) {
      this.http.put(`${this._endPoint}/${project.Id}/not-new`, {
        Id: project._isNew.Id
      })
        .subscribe(() => {
          project._isNew = null;
        })
    }

    if (!project.notifications.length) {
      return;
    }

    this.http.put(`${this._endPoint}/${project.Id}/readNotifications`, {
      ids: project.notifications
    })
      .subscribe(
        data => {
          this.messageBus.publish(MessageBusActions.NOTIFICATION_READ, {
            type: Notification.EVENTS.PROJECT,
            read: project.notifications.length
          });
          project.readUINotifications();

          this._processingState.next(ProjectService.STATES.DONE);
        },
        error => {
          console.log('Could not mark notifications as read.');
          if (error.status === 401) {
            this._processingState.next(ProjectService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(ProjectService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  handleNotification(notification: Notification, umbrella: IUmbrella) {
    if (notification.isProject()) {
      let umbrellaUid = notification.targetPath.AccountId + '_' + notification.targetPath._umbrellaId;
      switch (notification.action()) {
        case 'create':
          this.addUI(new Project(notification.data, notification.targetPath._umbrellaId, umbrellaUid), umbrellaUid);
          if (notification._inView) {
            this.fetch(umbrella);
          }
          break;
        case 'update':
          this.updateUI(new Project(notification.data, notification.targetPath._umbrellaId, umbrellaUid), umbrellaUid);
          if (notification._inView) {
            // this.fetch();
          }
          break;
      }
    }
  }

  remove(project: Project, archiveUmbrella: IUmbrella) {
    // this._processingState.next(ProjectService.STATES.PROCESSING);
    project._state = 'remove';

    // just need the current accountId; archiveUmbrella.AccountId gives us that
    // project.AccountId is not reliable since the project could be created by somebody else
    // project.AccountId is the project owner AccountId not the current logged in AccountId
    let prevUmbrellaUid: string = archiveUmbrella.AccountId + '_' + project._umbrellaId;
    return this.http.delete(`${this._endPoint}/${project.Id}`).pipe(
      map(data => {
        let rProject = new Project(data, archiveUmbrella.Id, archiveUmbrella._uid);
        this.removeUI(project, prevUmbrellaUid).addUI(rProject, archiveUmbrella._uid);

        this._project.next(rProject);
        this._processingState.next(ProjectService.STATES.DONE);

        archiveUmbrella._show = true;
        this.fetch(archiveUmbrella);
        return true;
      }), catchError(error => {
        console.log('Could not remove project.');
        this.alertService.snackError(error);
        return observableOf(null);
      })
    )
  }

  private inStorage(umbrella: IUmbrella) {
    return !!this.dataStore.projects[umbrella._uid];
  }

  private fetch(umbrella: IUmbrella, umbrellaUid: string = null) {
    if (umbrella) {
      umbrellaUid = umbrella._uid;
    }

    if (this._projectsStarting[umbrellaUid]) {
      this._projectsStarting[umbrellaUid].next(Object.assign({}, this.dataStore).projectsStarting);
    }

    // console.log(['fetch umbrellaUid ->>> ', umbrellaUid]);
    if (!this.dataStore.projects[umbrellaUid]) {
      return;
    }
    let uProjects = Object.assign({}, this.dataStore).projects[umbrellaUid];
    this._projects[umbrellaUid]._projects.next(uProjects);

    // if (uProjects.length > 0) {
    this._projects[umbrellaUid]._loadingState.next(ProjectService.STATES.DONE);
    // } else {
    // this._projects[umbrellaUid]._loadingState.next(ProjectService.STATES.EMPTY);
    // }
  }

  private addUI(project: Project, umbrellaUid: string) {
    if (this.dataStore.projects[umbrellaUid]) {
      this.dataStore.projects[umbrellaUid].splice(0, 0, project);
    }
    return this;
  }
  private removeUI(project: Project, umbrellaUid: string) {
    if (this.dataStore.projects[umbrellaUid]) {
      this.dataStore.projects[umbrellaUid].forEach((t, i) => {
        if (t.Id === project.Id) {
          this.dataStore.projects[umbrellaUid].splice(i, 1);
        }
      });
    }
    return this;
  }
  private updateUI(project: Project, umbrellaUid: string) {
    if (this.dataStore.projects[umbrellaUid]) {
      this.dataStore.projects[umbrellaUid].forEach((t, i) => {
        if (t.Id === project.Id) {
          this.dataStore.projects[umbrellaUid][i] = project;
        }
      });
    }
    return this;
  }

  private setCurrentProjectFromTarget(umbrella: IUmbrella) {
    let targetsSet: boolean = false;
    this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
      .subscribe((targets) => {
        if (targets.projectId) {
          targetsSet = true;
          let project = this.findProject(targets.projectId, umbrella._uid);
          if (project) {
            if (project._participant && project._participant.Confirmation === 'yes') {
              this.openTargetProject(project);
            } else {
              project._state = 'ex-target';
            }
          }
        }
      })
  }

  findProject(projectId: string, umbrellaUid?: string): Project {
    let project: Project;

    if (!umbrellaUid) {
      for (let u in this.dataStore.projects) {
        for (let p in this.dataStore.projects[u]) {
          if (this.dataStore.projects[u][p].Id === projectId) {
            project = this.dataStore.projects[u][p];
            break;
          }
        }
      }
      return project || null;
    }


    if (!this.dataStore.projects[umbrellaUid]) {
      return null;
    }
    for (let p of this.dataStore.projects[umbrellaUid]) {
      if (p.Id === projectId) {
        project = p;
        break;
      }
    }

    return project || null;
  }

  openProject(project: Project, targetExists: boolean = false, projectIsTarget: boolean = false) {
    if (environment.redirectIfBRP && project.isBRP) {
      this.redirect(environment.redirectIfBRP, project);
      return observableOf(null);
    }

    this.counterService.ping(project.Id);
    if (project._statistics && project._statistics.views >= 0) {
      project._statistics.views++;
    }

    this.currentIdService.currentParticipantId = project._participant.Id;
    this._project.next(null);

    let done: boolean = false;
    this.project$.pipe(
      filter(project => !!project),
      takeWhile(() => !done))
      .subscribe(project => {
        done = true;
        if (!targetExists || (targetExists && projectIsTarget)) {
          if (targetExists && projectIsTarget) {
            this.targetService.reset();
          }
          this.messageBus.publish(MessageBusActions.ACTIVATE_PROJECT, { project: project, action: 'open' });
          this.readNotifications(project);
        } else {
          this.messageBus.publish(MessageBusActions.ACTIVATE_PROJECT, { project: project, action: 'target' });
        }
      }, error => {
        console.log('Could not open project.');
        if (error.status === 401) {
          this._processingState.next(ProjectService.STATES.UNAUTHORIZED);
        } else {
          this._processingState.next(ProjectService.STATES.ERROR);
        }
        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_VIEW)
      });

    return this.show(project);
  }

  openTargetProject(project: Project) {
    this.openProject(project, true, this.targetService.targetLevel === 1).subscribe();
  }

  /**
   *
   * @param url
   * @param project
   */
  redirect(url: string, project: Project) {
    this.dialog.open(RedirectModal, {
      data: {
        project: project,
        url: url
      }
    })
  }

}
