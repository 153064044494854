<ng-container *ngIf="isBrowser">
    <div>
        <h2 class="title">Background Color:</h2>
        <color-chrome [color]="options.navigation" disableAlpha="true"
            (onChangeComplete)="changeNavigationComplete($event)">
        </color-chrome>
    </div>

    <div>
        <h2 class="title">Accent Color:</h2>
        <color-chrome [color]="options.primary" disableAlpha="true" (onChangeComplete)="changePrimaryComplete($event)">
        </color-chrome>
    </div>
</ng-container>