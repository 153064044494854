// import { IBrandMember } from "./brand-member/member.model";
import { User } from "@rallysite/user";

export const DEFAULT_BRAND_UNAME: string = 'p';

export interface IBrand {
    Id?: string;
    UserId: string;
    AccountId: string;
    Name: string;
    UName: string;
    Status: string;
    Description: string;
    Thumbnail: string;
    BackgroundImage: string;
    UpdateDate: string;

    OwnerFirstName?: string;
    OwnerLastName?: string;
}

export class Brand implements IBrand {
    Id: string;
    UserId: string;
    AccountId: string;
    Name: string;
    UName: string;
    Status: string;
    Description: string;
    Thumbnail: string;
    BackgroundImage: string;
    UpdateDate: string;

    OwnerFirstName: string;
    OwnerLastName: string;

    // _member: IBrandMember;
    _member: any;
    _permap: string;

    _type: string = 'brand';

    Initials: string;
    _timestamp: number;

    bgImage: any = null;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }

        this.Initials = this.Name ? this.Name.substring(0, 2) : '';
        this._timestamp = Math.random();
    }

    settingsOptions({ compInView, edit }: { compInView: string, edit?: boolean }) {
        edit = !!edit;
        return {
            data: {
                title: (edit ? 'Edit ' : 'Choose ') + 'Brand',
                brand: this,
                compInView: compInView,
                edit: edit
            }
        }
    }

    toDb() {
        return {
            Id: this.Id,
            UserId: this.UserId,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            Description: this.Description,
            Thumbnail: this.Thumbnail,
            BackgroundImage: this.BackgroundImage,
            UpdateDate: this.UpdateDate
        }
    }

}