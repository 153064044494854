import { InjectionToken } from "@angular/core";

export const TOKEN_HELPER = new InjectionToken<ITokenHelper>('TOKEN_HELPER');

export interface ITokenHelper {
    setSocketId(value: string): void;
    getAuthToken: () => string;
}

export class NoTokenHelper implements ITokenHelper {
    setSocketId(value: string) {
    }
    getAuthToken() {
        return '';
    }
}