import { IBrand } from "@board-brands/brand.model";
import { Image } from "@board-components/image/image";
import { ISurveySettings, ISurveySocial } from "./survey-factory/survey-settings/survey-settings.interface";

export interface ISurvey {
    Id: string;
    AccountId: string;
    BrandId: string;
    Name: string;
    PublicName: string;
    Description: string;
    HasScore: boolean;
    Updateable: boolean;
    Retakeable: boolean;
    Shareable: boolean;
    IsConditional: boolean;

    IsPublic: boolean;
    Featured: boolean;

    Settings: ISurveySettings;
    Status: string;
    UpdateDate: string;

    OwnerFirstName?: string;
    OwnerLastName?: string;

    TakenId: string;

    bgColor: string;
    social: ISurveySocial;

    _brand: IBrand;
    showPageHeaderFooter: boolean;
    showBrand: boolean;
    showFeedback: boolean;
    emailWhenDone: boolean;
    separateUserReport: boolean;
    showSurveyInfo: boolean;
    showHeroImage: boolean;
    heroImage: Image;

    isTheAssessment: boolean;
}

export class Survey implements ISurvey {
    Id: string;
    get isTheAssessment() {
        return this.Id.toString() === '1';
    }

    AccountId: string;
    BrandId: string;
    Name: string;
    PublicName: string;
    Description: string;
    HasScore = true;
    Updateable = false;
    Retakeable = true;
    Shareable = false;
    IsConditional = false;
    IsPublic = false;
    Featured = false;

    _settings: ISurveySettings;

    get Settings(): ISurveySettings {
        return this._settings
    }
    set Settings(data: ISurveySettings) {
        this._settings = data;
    }

    Status: string;
    UpdateDate: string;

    TakenId: string = null;

    _brand: IBrand;

    get bgColor(): string {
        return this.Settings && this.Settings.bgColor || '#f6f6f6';
    }
    set bgColor(value: string) {
        this.Settings || (this.Settings = {});
        this.Settings.bgColor = value;
    }

    get social(): ISurveySocial {
        return this.Settings && this.Settings.socialMedia || {};
    }
    set social(value: ISurveySocial) {
        this.Settings || (this.Settings = {});
        this.Settings.socialMedia = value;
    }

    get separateUserReport(): boolean {
        return this.Settings && this.Settings.separateUserReport || false;
    }
    set separateUserReport(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.separateUserReport = value;
    }

    get showFeedback(): boolean {
        return this.Settings && this.Settings.showFeedback || false;
    }
    set showFeedback(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.showFeedback = value;
    }

    get emailWhenDone(): boolean {
        return this.Settings && this.Settings.emailWhenDone || false;
    }
    set emailWhenDone(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.emailWhenDone = value;
    }

    get showPageHeaderFooter(): boolean {
        return this.Settings && this.Settings.showPageHeaderFooter !== false;
    }
    set showPageHeaderFooter(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.showPageHeaderFooter = value;
    }

    get showBrand(): boolean {
        return this.Settings && this.Settings.showBrand !== false;
    }
    set showBrand(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.showBrand = value;
    }

    get showSurveyInfo(): boolean {
        return this.Settings && this.Settings.showSurveyInfo !== false;
    }
    set showSurveyInfo(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.showSurveyInfo = value;
    }

    get showHeroImage(): boolean {
        return this.Settings && this.Settings.showHeroImage || false;
    }
    set showHeroImage(value: boolean) {
        this.Settings || (this.Settings = {});
        this.Settings.showHeroImage = value;
    }

    get heroImage(): Image {
        return this.Settings && this.Settings.heroImage ? this.Settings.heroImage : null;
    }
    set heroImage(value: Image) {
        this.Settings || (this.Settings = {});
        this.Settings.heroImage = value;
    }

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            AccountId: this.AccountId,
            BrandId: this.BrandId,
            Name: this.Name,
            PublicName: this.PublicName,
            Description: this.Description,
            HasScore: this.HasScore,
            Updateable: this.Updateable,
            Retakeable: this.Retakeable,
            Shareable: this.Shareable,
            IsConditional: this.IsConditional,
            IsPublic: this.IsPublic,
            Featured: this.Featured,
            Status: this.Status,
            Settings: this.Settings,
            UpdateDate: this.UpdateDate
        }
    }

    toJSON() {
        return {
            Id: this.Id,
            AccountId: this.AccountId,
            BrandId: this.BrandId,
            Name: this.Name,
            PublicName: this.PublicName,
            Description: this.Description,
            HasScore: this.HasScore,
            Updateable: this.Updateable,
            Retakeable: this.Retakeable,
            Shareable: this.Shareable,
            IsConditional: this.IsConditional,
            IsPublic: this.IsPublic,
            Featured: this.Featured,
            Status: this.Status,
            UpdateDate: this.UpdateDate,
            Settings: this.Settings,

            TakenId: this.TakenId
        }
    }

}
