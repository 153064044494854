import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

export const list =
    trigger('list', [
        transition('* => *', [
            query('.new', stagger('300ms', [
                animate('.3s ease-in', keyframes([
                    style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                    style({ opacity: .5, transform: 'translateY(10px)', offset: 0.6 }),
                    style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
                ]))]), { optional: true }),
            query('.remove', stagger('300ms', [
                animate('.4s ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                    style({ opacity: .5, transform: 'translateX(35px)', offset: 0.3 }),
                    style({ opacity: 0, transform: 'translateX(75%)', offset: 1.0 }),
                ]))]), { optional: true }),
            query('.move', stagger('100ms', [
                animate('.4s ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                    style({ opacity: .5, transform: 'translateX(35px)', offset: 0.3 }),
                    style({ opacity: 0, transform: 'translateX(75%)', offset: 1.0 }),
                ]))]), { optional: true })
        ])
    ]);
