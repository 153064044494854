import { FactoryProvider, NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { JoditComponent } from './jodit.component';
import { environment } from 'environments/environment';
import { JODIT } from './jodit.token';
import { JoditService } from './jodit.service';

declare const require: any;

export function joditFactory(platformId: any) {
  if (isPlatformServer(platformId)) {
    return {};
  }

  // let joditPackage: string;
  // switch (environment.env) {
  //   case 'dev':
  //     joditPackage = './jodit';
  //     break;
  //   default:
  //     joditPackage = './jodit.min';
  // }

  return require('./jodit.min').Jodit;
};

const joditProvider: FactoryProvider = {
  provide: JODIT,
  useFactory: joditFactory,
  deps: [PLATFORM_ID]
};


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    JoditComponent
  ],
  exports: [
    JoditComponent
  ],
  providers: [
    JoditService,
    joditProvider]
})

export class JoditModule {
  constructor(
    joditService: JoditService,
  ) {
    joditService.bootstrap();
  }
}