import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalBaseBlockComponent } from '../modal-base-block.component';
import { ModalImageBlock } from './image-block';

@Component({
  selector: 'modal-image-block',
  templateUrl: './image-block.component.html',
  styleUrl: './image-block.component.scss'
})
export class ModalImageBlockComponent extends ModalBaseBlockComponent {
  @Input() block: ModalImageBlock;
  @Output() remove: EventEmitter<ModalImageBlock> = new EventEmitter(null);

  onRemove() {
    this.remove.emit(this.block);
  }

}
