<mgc-form-field *ngIf="allowPostAs" [label]="label" multiline>
    <div class="field-wrapper select-row">
        <mgc-loader *ngIf="!postAs?.Id"></mgc-loader>

        <mat-select class="mgc-select" [(value)]="postAs" name="admin">
            <mat-select-trigger>
                <div class="mgc-account">
                    <account-avatar size="30" [account]="postAs"></account-avatar>
                    <span class="info">
                        <span class="name" [innerHTML]="postAs?.Name"></span>
                    </span>
                </div>
            </mat-select-trigger>

            <mat-option *ngFor="let admin of admins" [value]="admin._account" (click)="selectPostAs(admin)">
                <div class="mgc-account">
                    <account-avatar size="30" [account]="admin._account"></account-avatar>
                    <span class="info">
                        <span class="name" [innerHTML]="admin._account.Name"></span>
                    </span>
                </div>
            </mat-option>

        </mat-select>
    </div>
</mgc-form-field>