<form [formGroup]="form">
  <mat-select formControlName="selectedOption">

    <mat-select-trigger>
      <span [innerHTML]="f.selectedOption.value?.Text" [style.opacity]="f.selectedOption.value.Id?1:.6"></span>
    </mat-select-trigger>

    <mat-option class="q-option" [value]="noOption">
      <span style="opacity: .6;">Choose</span>
    </mat-option>
    <mgc-divider></mgc-divider>

    <ng-container *ngFor="let option of options">
      <mat-option class="q-option" [value]="option">
        <span [innerHTML]="option.Text"></span>
      </mat-option>
    </ng-container>

  </mat-select>
</form>
<!-- <ng-container *ngFor="let option of options">
  <div>  <span [innerHTML]="option.Text"></span></div>
  <div style="margin:0px 5px 0 25px;" [style.color]="option.Points>0?'#93c47d':'red'">{{ option.Points }} points</div>
</ng-container> -->