import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { algoPanels, IAlgoTagPanel } from './algo.panels';
import { AlgoTagScoreComponent } from './algotag/algotag-score.component';
import { AlgoTag, AlgoTagAction, AlgoTagBase, IAlgoTag, IAlgoTagBase } from './algotag/algotag.model';
import { AlgoTagType } from './algotag/algotag.types';
import { AlgoTagsService, IAlgoTags } from './algotags.service';


const filterAlgoTags = (types: AlgoTagType[] = []): IAlgoTagPanel[] => {
    if (types.length) {
        return algoPanels.filter((at: IAlgoTagPanel) => types.findIndex(t => t === at.category) >= 0)
    }

    return algoPanels;
}


@Component({
    selector: 'algotag-list',
    templateUrl: './algotag-list.component.html',
    styleUrls: ['./algotag-list.component.scss'],
})
export class AlgoTagListComponent implements OnInit {
    @Input() key: string;
    @Input() types: AlgoTagType[] = [];

    @ViewChildren(AlgoTagScoreComponent) algotagScoreComponents: QueryList<AlgoTagScoreComponent>

    loading = false;
    processing = false;

    algoTagPanels: IAlgoTagPanel[];
    panelCategory = AlgoTagType;

    inputTags: IAlgoTags = {
        custom: [],
        predefined: []
    }

    // keep track of removed tags
    removedTags: IAlgoTags = {
        custom: [],
        predefined: []
    }

    filteredTags: {
        [key in AlgoTagType]?: IAlgoTag[]
    } = {}

    // we are working on this object
    selectedTags: IAlgoTags = {
        custom: [],
        predefined: []
    }

    constructor(
        private algoTagsService: AlgoTagsService
    ) { }

    onRemoveChange(tagType: 'custom' | 'predefined', tag: IAlgoTag | IAlgoTag[]) {
        const tags = Array.isArray(tag) ? tag : [tag];

        tags.forEach(tag => {
            // only if tag is comming from DB, we keep track of it
            // otherwise, no need to keep track of it
            if (!tag.TagToItemId) {
                return;
            }
            switch (tag._httpAction) {
                case AlgoTagAction.DETACH:
                    this.algoTagsService.addTo(this.removedTags[tagType], tag);
                    break;
                case AlgoTagAction.ATTACH:
                    this.algoTagsService.removeFrom(this.removedTags[tagType], tag);
                    break;
            }
        })
    }

    filterAlgoTags() {
        // split algotags by types
        for (let key in AlgoTagType) {
            this.filteredTags[AlgoTagType[key]] = this.selectedTags.predefined.filter(t => t.Type === AlgoTagType[key])
        }
    }

    save(): Observable<IAlgoTags> {
        if (this.processing) return;
        this.algotagScoreComponents.forEach((comp) => comp.submit());

        // concat/merge all algotags back into one array
        const predefined = [].concat(...Object.values(this.filteredTags)).concat(this.removedTags.predefined);
        const custom = this.selectedTags.custom.concat(this.removedTags.custom);

        this.processing = true;
        return this.algoTagsService
            .saveAlgoTags(this.key, { custom, predefined })
            .pipe(
                finalize(() => this.processing = false),
                tap(() => {
                    this.removedTags = {
                        custom: [],
                        predefined: []
                    }
                }
                ));
    }

    cancel() {
        this.setInitialState();
    }

    private setInitialState() {
        // deep cloning input data into working data;
        this.selectedTags.custom = this.inputTags.custom.map((tag: IAlgoTagBase) => new AlgoTagBase(tag.toJSON()));
        this.selectedTags.predefined = this.inputTags.predefined.map((tag: IAlgoTag) => new AlgoTag(tag.toJSON()));
    }

    hasScore(tag: IAlgoTag): boolean {
        return tag.Type === AlgoTagType.HEAD ||
            tag.Type === AlgoTagType.GROWTH ||
            tag.Type === AlgoTagType.NEED;
    }

    isDropdown(tag: IAlgoTag): boolean {
        return tag.Type === AlgoTagType.AGE ||
            tag.Type === AlgoTagType.GENDER ||
            tag.Type === AlgoTagType.PLEVEL;
    }

    isTheme(tag: IAlgoTag): boolean {
        return tag.Type === AlgoTagType.THEME_NEED ||
            tag.Type === AlgoTagType.THEME_GROWTH;
    }

    ngOnInit() {
        this.loading = true;
        this.algoTagPanels = filterAlgoTags(this.types);

        forkJoin(
            this.algoTagsService.getPredefinedAlgoTags(),
            this.algoTagsService.getAlgoTags(this.key),
        ).pipe(
            finalize(() => this.loading = false))
            .subscribe((values: [IAlgoTag[], IAlgoTags]) => {
                const algoTagsWithName: IAlgoTag[] = values[0];
                this.inputTags = values[1];

                // bind Type to input
                this.inputTags.predefined = this.inputTags.predefined.map((tag: IAlgoTag) => {
                    const at = algoTagsWithName.find((t: IAlgoTag) => t.Id === tag.Id)
                    if (at) {
                        tag.Name = at.Name;
                        tag.Type = at.Type;
                    }
                    return tag;
                })

                const predefined = algoTagsWithName
                    .filter(this.hasScore)
                    .map((tag: IAlgoTag) => {
                        let inputTag = this.inputTags.predefined.find((t: IAlgoTag) => t.Id === tag.Id)
                        if (inputTag) {
                            inputTag.Name = tag.Name;
                            inputTag.Type = tag.Type;
                        } else {
                            inputTag = new AlgoTag({
                                ...tag.toJSON(),
                                _httpAction: AlgoTagAction.ATTACH
                            });
                        }
                        return inputTag;
                    })
                    .concat(
                        this.inputTags.predefined
                            .filter(this.isDropdown)
                            .map((tag: IAlgoTag) => {
                                const at = algoTagsWithName.find((t: IAlgoTag) => t.Id === tag.Id)
                                if (at) {
                                    tag.Name = at.Name;
                                    tag.Type = at.Type;
                                }
                                return tag;
                            })
                    )
                    .concat(
                        this.inputTags.predefined
                            .filter(this.isTheme)
                            .map((tag: IAlgoTag) => {
                                const at = algoTagsWithName.find((t: IAlgoTag) => t.Id === tag.Id)
                                if (at) {
                                    tag.Name = at.Name;
                                    tag.Type = at.Type;
                                }
                                return tag;
                            })
                    )

                this.inputTags.predefined = predefined;

                this.setInitialState();
                this.filterAlgoTags();
            })

    }

}
