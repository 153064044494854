import { Component, Input, HostBinding, Directive } from "@angular/core";


@Component({
    selector: 'mgc-information',
    template: `
        <mat-icon class="mgc-icon" svgIcon="board-mat:info_outline"></mat-icon>
        <span><ng-content></ng-content></span>
    `,
    styleUrls: ['./information.scss'],

})
export class MgcInformationComponent {
    @HostBinding('class.mgc-information') informationClass = true;
}
