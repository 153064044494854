import { NgModule } from '@angular/core';
import { NotAllowedModal } from './not-allowed-modal';
import { MgcButtonModule, MgcDialogModule } from '@rallysite/mgc-components';
import { MatIconModule } from '@angular/material/icon';
import { PermissionService } from './permission.service';

@NgModule({
    imports: [
        MgcDialogModule,
        MgcButtonModule,
        MatIconModule
    ],
    declarations: [
        NotAllowedModal
    ],
    exports: [
        NotAllowedModal
    ],
    providers: [PermissionService]
})
export class PermissionModule { }
