<mgc-form-field label="Promo Image" multiline class="promo-image" (click)="rsUploader.click()">
  <div class="field-wrapper">

    <rs-uploader [style.font-size]="0" #rsUploader renderAs='content' [options]="promoImageUploaderOptions"
      (onBeforeUpload)="onImageBeforeUpload($event)" (onProgress)="onImageProgress($event)"
      (onSuccess)="onImageSuccess($event)" (onError)="onImageError($event)">
    </rs-uploader>

    <div class="image-wrapper">
      <span *ngIf="!hasUrl && !promoImage.item?.isUploading">Click here to add image</span>
      <mgc-icon-button class="active" icon="board-mat:mode_edit" *ngIf="hasUrl && !promoImage.item?.isUploading">
      </mgc-icon-button>

      <div class="image">
        <div class="bg-contain-center" [style.background-image]="promoUrl"></div>
      </div>

      <ng-container *ngIf="promoImage.item && promoImage.item.isUploading">
        <div class="overlay"></div>
        <mat-progress-spinner class="spinner" color="primary" mode="determinate" [value]="promoImage.item?.progress"
          diameter="30">
        </mat-progress-spinner>
      </ng-container>
    </div>
  </div>

</mgc-form-field>

<ng-container [formGroup]="form">
  <mgc-form-field label="Add promo image message" multiline>
    <div class="field-wrapper">
      <textarea formControlName="promoImageMessage"></textarea>
    </div>
  </mgc-form-field>
</ng-container>