import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { filter, takeWhile } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { Project } from '../project';
import { IProjectLibraryFile } from './project-library-file';
import { ProjectLibraryService } from './project-library.service';

import { AuthService } from '@rallysite/auth-service';
import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { GalleryOrganizerModalComponent } from '@libraries/gallery-organizer';
import { Observable } from 'rxjs';

@Component({
  selector: 'project-library',
  template: `
      <gallery *ngIf="galleryImages" [data]="galleryImages" [quality]="imageQuality">
        <span *ngIf="!galleryImages.length && organize" [style.padding.px]="15" [style.color]="'gray'">
          This is your image carousel...
        </span>
      </gallery>
  `,
  styles: [`
    :host{
      display: block;
      position: relative;
      width: 100%;
      padding: 0 4px;
    }
  `]
})
export class ProjectLibraryComponent implements OnInit {

  @Input() project: Project;
  @Input() organize: boolean = false;

  @Output() updated: EventEmitter<any> = new EventEmitter<any>(null);

  imageQuality: 'small' | 'medium' | 'large' = 'medium';

  alive: boolean = true;
  state: number;
  updateImageLibraryMetadata: Observable<boolean>;

  galleryImages: {
    id: string,
    small: string,
    medium: string,
    large: string
  }[];

  constructor(
    private projectLibraryService: ProjectLibraryService,
    private authService: AuthService,
    @Inject(APP_CONFIG) private config: AppConfig,
    public dialog: MatDialog
  ) {
  }

  private convertToGallery(files: IProjectLibraryFile[], id: string = "Id") {
    let endPoint: string = this.projectLibraryService.endPoint;
    return files.map((file) => {
      return {
        id: file[id],
        small: `${endPoint}/${file[id]}/url/small`,
        medium: `${endPoint}/${file[id]}/url/medium`,
        large: `${endPoint}/${file[id]}/url/large`,
      }
    })
  }

  /**
   * public API
   * called when need to show organizer
   */
  showOrganizer() {
    // this.organizerVisible = true;
    this.dialog.open(GalleryOrganizerModalComponent, {
      data: {
        imageQuality: this.imageQuality,
        items: this.galleryImages,
        galleryOptions: this.galleryOptions
      }
    }).afterClosed().pipe()
      .subscribe((items: Array<any>) => {
        // cancel/close
        if (!items) {
          return;
        }

        // each id in galleryImages coresponds to a file Id in DB
        let ids = items.map(img => img.id);
        if (ids) {
          this.updateImageLibraryMetadata = this.projectLibraryService.updateImageLibraryMetadata(this.project, ids);
          this.updated.emit(true);
        }
        // a bit forced
        this.galleryImages = this.convertToGallery(items, 'id');

      })
  }

  ngOnInit() {
    // TODO: states to identify loading, error, done
    this.projectLibraryService.libraryFiles$.pipe(
      filter(pFiles => {
        return !!pFiles;
      }),
      takeWhile(() => this.alive))
      .subscribe((pFiles) => {
        this.galleryImages = this.convertToGallery(pFiles);
      })
    this.projectLibraryService.loadProjectLibraryFiles(this.project);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private get galleryOptions() {
    return {
      tokenGetter: this.authService.tokenGetter,
      categories: [
        {
          id: this.project.Id,
          text: 'Project Images',
          filename: 'pfile',
          endPoint: `${this.config.endpoint}/api/project-library`,
          payload: {
            ProjectId: this.project.Id
          },
        },
        // {
        //   id: this.project.Id + '111111',
        //   text: 'Project Images 11111',
        //   filename: 'pfile1111',
        //   endPoint: `${this.config.endpoint}/api/project-library11111`,
        //   payload: {
        //     ProjectId: this.project.Id + '111111'
        //   }
        // }
      ]
    }
  }

}
