import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { AlertService } from '@rallysite/components/alert';

import { IDoNotShowAgainOption } from './do-not-show-again/do-not-show-again.options.class';
import { IUserContactDetail } from './user-contact-detail.model';
import { UserService } from './user.service';


@Component({
  selector: 'central-details',
  templateUrl: './central-details.component.html',
})
export class CentralDetailsComponent implements OnInit, OnDestroy {

  @Input() data: any;

  private alive: boolean = true;

  dnsaOptions: IDoNotShowAgainOption[];
  newDetail: IUserContactDetail;

  close$: Subject<any> = new Subject<any>();

  hide: boolean = true;
  userPassword: any = {
    opassword: '',
    password: '',
    cpassword: ''
  };

  state: number;
  get states() {
    return UserService.STATES;
  }

  constructor(
    private userService: UserService,
    private alertService: AlertService,
  ) {
  }


  onSave(): void {
    this.alertService.clear();
    this.userService.update(this.data.user, this.userPassword).subscribe();
  }

  onCancel(): void {
    this.close$.next('cancel');
  }

  trackByFn(index: number, detail: IUserContactDetail): string {
    return detail.id;
  }

  removeDetail(detail: IUserContactDetail) {
    for (let i in this.data.user.ContactDetails) {
      if (detail.id === this.data.user.ContactDetails[i].id)
        this.data.user.ContactDetails.splice(i, 1);
    }
  }

  addDetail() {
    if (this.newDetail.type.trim() && this.newDetail.value.trim()) {
      this.newDetail.id = this.newDetail.type + '.' + this.random(3);
      this.newDetail.name = this.ucFirst(this.newDetail.type);
      this.data.user.ContactDetails.push(this.newDetail);
      this.resetNewDetail();
    }
  }

  random(length: number): string {
    let str: string = '';
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (var i = length; i > 0; --i) {
      str += chars[Math.floor(Math.random() * chars.length)]
    }
    return str
  }

  ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  resetNewDetail() {
    this.newDetail = {
      id: '',
      type: '',
      name: '',
      value: '',
      _checked: false
    };
  }

  ngOnInit() {
    this.dnsaOptions = this.userService.user.Settings.doNotShowAgain;
    // this.userService.resetProcessingState$()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe(state => {
    //     this.state = state;
    //     if (this.state === this.states.DONE) {
    //       this.close$.next();
    //     } else if (this.state === this.states.UNAUTHORIZED) {
    //       this.close$.next('unauthorized');
    //     }
    //   });

    this.resetNewDetail();
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
