import { File, IFile } from "@panel-components/notes/attachments";
import { generateId } from "../generate_block_id";
import { ITaskV2Block } from "../task-v2-block.interface";
import { TaskV2BlockAction, TaskV2BlockType, TASK_V2_BLOCK_PREFIX } from "../types";

export interface ITaskV2AttachmentsBlockContent {
    imageOrder: string[],
    files: IFile[]
}

export class TaskV2AttachmentsBlock implements ITaskV2Block {
    Type: TaskV2BlockType = TaskV2BlockType.ATTACHMENTS;
    Id: string;
    TaskId: string;
    BlockId: string;
    CreateDate: string;
    UpdateDate: string;

    private _content: ITaskV2AttachmentsBlockContent;
    set Content(data: ITaskV2AttachmentsBlockContent) {
        this._content = {
            imageOrder: data.imageOrder || [],
            files: (data.files || []).map((fData: IFile) => {
                return new File(fData);
            })
        }
    }
    get Content(): ITaskV2AttachmentsBlockContent {
        return this._content;
    }

    // annoing workaround to make it compliant with other models with attachments Note, Comment
    get Settings() {
        return this._content;
    }
    set Settings(data) {
        this.Content = data;
    }

    _httpAction: TaskV2BlockAction = TaskV2BlockAction.UPDATE;

    _state: string;

    constructor(data: any = {}) {
        data = { BlockId: generateId(`${TASK_V2_BLOCK_PREFIX.ATTACHMENTS}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            TaskId: this.TaskId,
            Type: this.Type,
            Content: {
                // store to db only the images order
                imageOrder: this.Content.imageOrder
            },
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            TaskId: this.TaskId,
            Type: this.Type,
            Content: this.Content,
            Settings: this.Settings,
            _httpAction: this._httpAction
        }
    }

}
