export class CSVEmailRecord {
    row: number;
    email: any;
    level: any;

    constructor(data: any = {}){
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
} 