import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@rallysite/global-services';

const BOARD_SMALL_SIZE: number = 576;

@Injectable({
    providedIn: 'root',
})
export class BoardViewService {

    private _height: number;
    get height(): number {
        return this._height;
    }

    private _width: number;
    get width(): number {
        return this._width;
    }

    constructor(
        @Inject(WINDOW) public window: Window
    ) {
    }

    smallView(): boolean {
        return this.handle()._width <= BOARD_SMALL_SIZE;
    }

    handle() {
        this._width = this.window.innerWidth;
        this._height = this.window.innerHeight;
        return this;
    }






}