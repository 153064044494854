
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Project } from '@board/items/project';
import { BaseService } from '@board/_services/base-service.service';
import { DefaultScheduledFolder, IScheduledFolder } from './scheduled-folder';

@Injectable({
    providedIn: 'root'
})
export class ScheduledFolderService extends BaseService {
    private dataStore: {
        defaultFolders: DefaultScheduledFolder[]
    }

    constructor(
        private http: HttpClient,
    ) {
        super();
        this.dataStore = { defaultFolders: [] };
    }

    getDefaultFolder(project: Project): DefaultScheduledFolder {
        if (!this.dataStore.defaultFolders[project.Id]) {
            this.dataStore.defaultFolders[project.Id] = new DefaultScheduledFolder(project, {});
        }

        return this.dataStore.defaultFolders[project.Id];
    }

    activateFolder(folder: IScheduledFolder) {
        folder._active = true;
    }
}