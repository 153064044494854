
export interface ISocialShareOptions {
    id: string;
    name: string;
    icon: string;
    url: string;
}

export class SocialShare {

    public static LINK_COPY: ISocialShareOptions = {
        id: 'linkcopy',
        name: 'LinkCopy',
        icon: 'board-mat:copy',
        url: ''
    };

    public static FACEBOOK: ISocialShareOptions = {
        id: 'facebook',
        name: 'Facebook',
        icon: 'board-mat:facebook',
        url: 'https://www.facebook.com/sharer.php?u=[URL]'
    };

    public static TWITTER: ISocialShareOptions = {
        id: 'twitter',
        name: 'Twitter',
        icon: 'board-mat:twitter',
        url: 'https://twitter.com/intent/tweet?url=[URL]'
    };

    public static LINKEDIN: ISocialShareOptions = {
        id: 'linkedin',
        name: 'Linkedin',
        icon: 'board-mat:linkedin',
        url: 'https://www.linkedin.com/sharing/share-offsite/?url=[URL]'
    };

    public static MAIL: ISocialShareOptions = {
        id: 'mail',
        name: 'Mail',
        icon: 'board-mat:mail',
        url: 'mailto:?subject=[SUBJECT]&body=[BODY][URL]'
    };

    public static TUMBLR: ISocialShareOptions = {
        id: 'tumblr',
        name: 'Tumblr',
        icon: 'board-mat:tumblr',
        url: 'https://www.facebook.com/sharer/sharer.php?u=[URL]'
    };

    public static DIGG: ISocialShareOptions = {
        id: 'digg',
        name: 'Digg',
        icon: 'board-mat:curios',
        url: 'http://digg.com/submit?url=[URL]'
    };

    public static REDDIT: ISocialShareOptions = {
        id: 'reddit',
        name: 'Reddit',
        icon: 'board-mat:reddit',
        url: 'https://reddit.com/submit?url=[URL]'
    };

    public static PINTEREST: ISocialShareOptions = {
        id: 'pinterest',
        name: 'Pinterest',
        icon: 'board-mat:pinterest',
        url: 'https://www.pinterest.com/pin/find/?url=[URL]'
    };

}
