export interface ITooltip {
  Id?: number;
  Name?: string;
  Heading?: string;
  Content?: string;
  Icon?: string;
  UpdateDate?: string;

  update?(data: ITooltip);
  toDb?();
}

export class Tooltip implements ITooltip {
  Id?: number;
  Name?: string;
  Heading?: string;
  Content?: string;
  Icon?: string;
  UpdateDate?: string;

  constructor(data: ITooltip) {
    this.update(data);
  }

  update?(data: ITooltip) {
    data = { Name: "Help Tip", Content: "Help Content", Heading: "", ...data };
    for (let prop in data) {
      this[prop] = data[prop];
    }
  }

  toDb?() {
    return {
      Id: this.Id,
      Name: this.Name,
      Heading: this.Heading,
      Content: this.Content,
      Icon: this.Icon,
      UpdateDate: this.UpdateDate,
    };
  }
}
