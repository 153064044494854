import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MustActivateComponent } from './must-activate.component';
import { UserService } from '@rallysite/user';
import { AlertService } from '@rallysite/components/alert';


@Component({
  selector: 'activate-modal',
  templateUrl: './activate.modal.html',
  styleUrls: ['./activate.modal.scss'],
})
export class ActivateModal {

  @ViewChild(MustActivateComponent) mustActivate: MustActivateComponent;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<ActivateModal>,
  ) {
  }

  get activated() {
    return this.userService.user._isConfirmed;
  }

  onSubmit() {
    this.alertService.clear();

    switch (true) {
      case !!this.mustActivate:
        this.mustActivate.onSubmit();
        break;
    }
  }

  resend() {
    this.alertService.clear();
    switch (true) {
      case !!this.mustActivate:
        this.mustActivate.resendCode();
        break;
    }
  }

  get processing() {
    let isProcessing: boolean = false;
    switch (true) {
      case !!this.mustActivate:
        isProcessing = this.mustActivate.processing;
        break;
    }
    return isProcessing
  }


  get resending() {
    let isResending: boolean = false;
    switch (true) {
      case !!this.mustActivate:
        isResending = this.mustActivate.resending;
        break;
    }
    return isResending;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
