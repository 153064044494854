import { Injectable } from "@angular/core";
import { IQuestion, Question } from "@libraries/surveys/__spiritual-assessment/question.model";
import { TransferStateConverter } from "../transfer-state.converter";

type SerializedData = IQuestion | IQuestion[]

@Injectable({
  providedIn: 'root',
})
export class QuestionsConverter extends TransferStateConverter<IQuestion | IQuestion[]> {
  constructor(
  ) {
    super();
  }

  fromTransferState(data: SerializedData): IQuestion | IQuestion[] {
    if (Array.isArray(data)) {
      const questions: IQuestion[] = [];
      for (let key in data) {
        questions.push(new Question(data[key]));
      }
      return questions;
    }

    return new Question(data);
  }

  toTransferState(data: IQuestion | IQuestion[]): SerializedData {
    return data;
  }
}