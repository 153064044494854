import { NgModule } from '@angular/core';
import { MgcNavListComponent } from './nav-list.component';
import { MgcListItemComponent, MgcListItemDraggbleDirective, DraggableComponent } from './nav-list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { CdkDragHandle } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    MatIconModule,
    CdkDragHandle,
  ],
    declarations: [
        MgcNavListComponent,
        MgcListItemComponent,
        MgcListItemDraggbleDirective,
        DraggableComponent,
    ],
    exports: [
        MgcNavListComponent,
        MgcListItemComponent,
        MgcListItemDraggbleDirective
    ],
    providers: []
})
export class MgcListModule {
}
