
import { takeWhile, finalize, take } from 'rxjs/operators';
import {
  Component, Input, OnInit, OnDestroy, HostBinding,
  ElementRef, HostListener, EventEmitter, Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ISettingsComponent } from '@rallysite/global-interfaces';
import { Umbrella, IUmbrella, UmbrellaService } from '@board/items/umbrella';
import { IPanelComponent, PanelComponents } from '@panel-components';
import { AccountService } from '@board-accounts/account.service';
import { ConfirmOverlayService } from '@board/_components/confirm-overlay/confirm-overlay.service';
import { PermissionService } from '@board/_services';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Project } from '../project';
import { ProjectService } from '@items/project';

@Component({
  selector: 'project-settings',
  templateUrl: './project-settings.component.html',
  styles: [`
    :host{
      display: block;
      height: 100%;
    }
  `]
})
export class ProjectSettingsComponent implements ISettingsComponent, OnInit, OnDestroy {
  @HostBinding('class.SM') get SM_class() {
    return this.isSM;
  }

  constructor(
    private umbrellaService: UmbrellaService,
    private projectService: ProjectService,
    private accountService: AccountService,
    private confirmOverlay: ConfirmOverlayService,
    private el: ElementRef,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() { return this.settingsForm.controls; }
  /** end of FORM */

  get states() {
    return ProjectService.STATES;
  }
  private isSM = false;

  @HostBinding('class.mgc-settings-container') settingsContainer = true;

  taskPanels: [];

  ready = false;
  private alive = true;
  state: number;
  showAdvanced = false;
  umbrellas: Umbrella[];
  sidePanels: Array<IPanelComponent>;

  taskGroupEnabled = false;
  hidePeopleStatus: boolean;
  rankTopPick = true;
  rankMostPopular = false;

  project: Project;

  defaultUmbrella: IUmbrella;
  allowUpdate = false;

  processing = false;

  @Input() data: any;

  close$: Subject<any> = new Subject<any>();
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();


  /** FORM */
  settingsForm: FormGroup;

  submitted = false;

  @HostListener('window:resize', ['$event'])
  onResize(event = null) {
    this.isSM = this.el.nativeElement.offsetWidth < 500;
  }

  bootstrapForm() {
    this.settingsForm = this.formBuilder.group({
      Name: new FormControl({ value: this.project.Name || '', disabled: !this.allowUpdate }, Validators.required),
      umbrella: this.data.umbrella || this.defaultUmbrella,

      eMessageCustomUrlEnabled: this.project.Settings.eMessage.customUrl.enabled,
      eMessageCustomUrlLabel: this.project.Settings.eMessage.customUrl.label,
      eMessageCustomUrlValue: this.project.Settings.eMessage.customUrl.value,

      label_seeItemList: this.project.labels.seeItemList,

      GTOrganisationId: new FormControl({ value: this.project.GTOrganisationId || '', disabled: !this.allowUpdate }),
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.settingsForm.invalid) {
      return false;
    }

    return true;
  }


  updateProps() { }


  onSave(newData: any = null): void {
    let request;
    let action: string;

    if (!this.submit() || this.processing) {
      return;
    }

    newData || (newData = this.data);

    this.updateProps();

    Object.keys(this.settingsForm.value).forEach(prop => {
      if (prop.indexOf('label_') === 0) {
        const label = prop.replace('label_', '');
        this.project.Settings.labels[label] = this.settingsForm.get(prop).value;
      }
    });

    ['Name', 'GTOrganisationId'].forEach(prop => this.project[prop] = this.settingsForm.get(prop).value);
    if (!newData.project.Id) {
      request = this.projectService.create(this.project, this.settingsForm.get('umbrella').value);
      action = 'create';
    } else {
      request = this.projectService.update(this.project, this.settingsForm.get('umbrella').value, this.allowUpdate);
      action = 'update';
    }

    this.processing = true;
    request.pipe(take(1), finalize(() => this.processing = false))
      .subscribe(response => {
        if (response) {
          this.onClose.emit(action);
        }
      });
  }

  onDelete(event) {
    if (this.processing) {
      return;
    }

    this.project._state = 'removing';
    this.confirmOverlay.open(event.target).afterClosed()
      .subscribe((confirmed) => {
        if (!confirmed) {
          this.project._state = '';
          return;
        }

        this.processing = true;
        const archive = this.umbrellaService.findArchive(this.accountService.currentAccount.Id);
        this.projectService.remove(this.project, archive).pipe(
          take(1),
          finalize(() => this.processing = false))
          .subscribe(result => {
            if (result) {
              this.onClose.emit('delete');
            } else {
              this.project._state = '';
            }
          });
      });
  }

  onCancel(): void {
    this.onClose.emit('cancel');
  }

  togglePeopleStatus(value: boolean) {
    this.hidePeopleStatus = !this.hidePeopleStatus;
    this.project.Settings.restrictions.showPeople = !this.hidePeopleStatus;
    this.project.Settings.restrictions.showStatus = !this.hidePeopleStatus;
  }

  toggleSidePanelIsOpen(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.sidePanelIsOpen = matSlideToggle.checked;
  }

  toggleTaskCompleteButton(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.enableTaskCompleteButton = matSlideToggle.checked;
  }

  toggleCollapseCommentsOption(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.collapseComments = matSlideToggle.checked;
  }

  toggleHideDatesOption(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.hideDates = matSlideToggle.checked;
  }

  toggleHideAuthorOption(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.hideAuthor = matSlideToggle.checked;
  }

  toggleEnableRecyclerOption(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.enableRecycler = matSlideToggle.checked;
  }

  toggleAllowAnyAuthUserToCommentOption(matSlideToggle: MatSlideToggleChange) {
    this.project.Settings.allowAnyAuthUserToComment = matSlideToggle.checked;
  }

  toggleGTPushNotifications(matSlideToggle: MatSlideToggleChange) {
    this.project.GTPushNotifications = matSlideToggle.checked;
  }

  toggleInUse(matSlideToggle: MatSlideToggleChange) {
    this.project.InUse = matSlideToggle.checked;
  }

  private bootstrap(project) {
    this.project = project;

    this.sidePanels = [
      PanelComponents.PARTICIPANTS,
      PanelComponents.DASHBOARD,
      PanelComponents.PUBLISH,
    ];
    if (this.project.contest) {
      this.sidePanels.unshift(PanelComponents.CONTEST);
    }

    this.defaultUmbrella = this.umbrellaService.getDefault(this.accountService.currentAccount.Id);

    this.hidePeopleStatus = !this.project.Settings.restrictions.showPeople
      || !this.project.Settings.restrictions.showStatus;

    this.projectService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (this.state === this.states.DONE) {
          this.close$.next(this.projectService.project);
        } else if (this.state === this.states.UNAUTHORIZED) {
          this.close$.next('unauthorized');
        }
      });

    this.umbrellaService.umbrellas$.pipe(
      takeWhile(() => this.alive))
      .subscribe(umbrellas => {
        this.umbrellas = umbrellas;
      });

    this.onResize();
    this.allowUpdate =
      !this.project.Id
      || (this.project.Id
        && this.permissionService.allowsAction('update.project', this.project, { silent: true, project: this.project }));

    this.bootstrapForm();
  }

  ngOnInit() {

    this.projectService.show(this.data.project).pipe(
      take(1), finalize(() => this.ready = true))
      .subscribe(project => {
        this.bootstrap(project);
      });
  }


  ngOnDestroy() {
    this.alive = false;
  }

}

@Component({
  selector: 'project-settings-name',
  templateUrl: './project-settings-name.component.html',
  styles: [`
    :host{
      display: block;
      height: 100%;
    }
  `]
})
export class ProjectSettingsNameComponent extends ProjectSettingsComponent { }



@Component({
  selector: 'project-settings-restricted-users',
  templateUrl: './project-settings-restricted-users.component.html',
  styles: [`
    :host{
      display: block;
      height: 100%;
    }
  `]
})
export class ProjectSettingsRestrictedUsersComponent extends ProjectSettingsComponent {
}
