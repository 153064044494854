<mgc-list-item collection [draggable]="umbrella.isDraggable">
    <div class="prefix">
        <mgc-icon-button  icon="board-mat:collection{{umbrella._open?'_open':''}}"></mgc-icon-button>
    </div>
    <div class="content" (click)="toggleOpen()" [attr.title]="umbrella.Name">
        <span [innerHTML]="umbrella.Name"></span>
        <ul *ngIf="!umbrella._open">
            <!-- <li *ngIf="umbrella._flaggedNotes">
                <mat-icon svgIcon="board-mat:flag"></mat-icon>
            </li> -->
        </ul>
    </div>

    <div class="suffix nm" *ngIf="umbrella.isDraggable">
        <ng-container *ngIf="umbrella._state!='remove'">
            <mat-icon *ngIf="umbrella._flaggedNotes" class="flag" svgIcon="board-mat:flag"></mat-icon>
            <!-- <mat-chip-list class="mgc-chip-list">
                <mat-chip color="accent" *ngIf="umbrella._notifications > 0">{{umbrella._notifications}}</mat-chip>
            </mat-chip-list> -->
            <mat-icon *ngIf="umbrella._notifications > 0" class="notifications-dot" svgIcon="board-mat:dot"></mat-icon>
            <mgc-icon-button *ngIf="umbrella._open" icon="board-mat:more_vert" (click)="openSettings()"></mgc-icon-button>
        </ng-container>
        <mgc-loader *ngIf="umbrella._state=='remove'"></mgc-loader>
    </div>
</mgc-list-item>

<!-- <ul class="extended-details" *ngIf="umbrella._open && umbrella._flaggedNotes">
    <li>
        <mat-icon svgIcon="board-mat:flag"></mat-icon>
    </li>
</ul> -->

<ng-content></ng-content>

