import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SurveyOverlayRef } from './survey-overlay-ref';
import { ISurveyOverlayAction } from './survey-overlay-interfaces';

@Component({
  selector: 'survey-overlay',
  styleUrls: ['./survey-overlay.component.scss'],
  templateUrl: './survey-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})

export class SurveyOverlayComponent {

  @Input() message: string = '';
  @Input() actions: ISurveyOverlayAction[] = [];

  constructor(
    private SurveyOverlayRef: SurveyOverlayRef,
  ) {
  }

  onClose(action:ISurveyOverlayAction) {
    this.SurveyOverlayRef.close({
      action: action
    });
  }

  ngOnInit() {
  }
}
