import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { SeoService } from 'app/_services/seo.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPageOptions, LEGAL_PAGES, PUBLIC_PAGES, TOOLS_PAGES } from './pages.enum';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  backPage: IPageOptions = null;

  get activePage(): IPageOptions {
    return this._activePage.getValue();
  }

  private _activePage: BehaviorSubject<IPageOptions>;
  get activePage$(): Observable<IPageOptions> {
    return this._activePage.asObservable();
  }
  constructor(
    private seoService: SeoService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this._activePage = new BehaviorSubject<IPageOptions>(null);
  }

  emit(page: IPageOptions) {

    let pageName: string;
    this._activePage.next(page);
    if (page.data && page.data.name) {
      pageName = page.data.name;
    } else {
      pageName = page.name;
    }

    if (this.isAdminPage(page)) {
      pageName = `${pageName} - ${this.config.app_name}`;
    }
    this.seoService.updateTitle(pageName);
  }

  isPublicPage(page: IPageOptions) {
    if (!page) return false;
    const allPublicPages = {
      ...PUBLIC_PAGES,
      ...LEGAL_PAGES
    };
    return !!Object.keys(allPublicPages).find(h => page.id == allPublicPages[h].id);
  }

  isAdminPage(page: IPageOptions) {
    if (!page) return false;
    return !!Object.keys(TOOLS_PAGES).find(h => page.id == TOOLS_PAGES[h].id);
  }

}
