import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { InfoTooltipDirective } from './info-tooltip.directive';
import { InfoTooltipComponent } from './info-tooltip.component';
import { MgcButtonModule } from '@rallysite/mgc-components';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        MgcButtonModule
    ],
    declarations: [
        InfoTooltipDirective,
        InfoTooltipComponent,
    ],
    providers: [],
    exports: [
        InfoTooltipDirective
    ]
})
export class InfoTooltipModule {
}
