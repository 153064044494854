import { IPanelComponent, PanelComponents } from "@panel-components";
import { EmailItemBody } from "./email-item-body";

export interface IEmailItemSettings {
    defaultPanel: IPanelComponent;
    sideDefaultPanel: IPanelComponent;

    senderName: string;
    emailSubject: string;
    emailBody: EmailItemBody;

    toDb(): void;
    toJSON(): void;
}

export class EmailItemSettings implements IEmailItemSettings {
    private _defaultPanel: IPanelComponent;
    private _sideDefaultPanel: IPanelComponent;

    senderName: string;
    emailSubject: string;
    _emailBody: EmailItemBody;
    set emailBody(value: any) {
        this._emailBody = new EmailItemBody(value);
    }
    get emailBody(): any {
        return this._emailBody;
    }

    constructor(data?: any) {
        data = { emailBody: null, emailSubject: '', senderName: '', ...data };
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    get defaultPanel(): IPanelComponent {
        return this._defaultPanel || PanelComponents.EMAIL_ITEM_BOARD;
    }
    set defaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        this._defaultPanel = this.findPanel(id);
    }

    get sideDefaultPanel(): IPanelComponent {
        return this._sideDefaultPanel || PanelComponents.SETTINGS;
    }
    set sideDefaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        if (id === PanelComponents.STATUS.id) {
            id = PanelComponents.SETTINGS.id;
        }
        this._sideDefaultPanel = this.findPanel(id);
    }

    private findPanel(id: string) {
        let dPanel: IPanelComponent = null;
        for (let panel in PanelComponents) {
            if (id === PanelComponents[panel].id) {
                dPanel = PanelComponents[panel];
                break;
            }
        }
        return dPanel;
    }

    toDb() {
        return {
            defaultPanel: this.defaultPanel.id,
            sideDefaultPanel: this.sideDefaultPanel.id,

            senderName: this.senderName,
            emailSubject: this.emailSubject,
            emailBody: this._emailBody.toDb(),
        }
    }

    toJSON() {
        return this.toDb();
    }

}