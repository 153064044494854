<mgc-dialog>

  <mgc-dialog-header title="" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>

    <div mat-dialog-content class="cp-dialog-content">

      <p>All Bible reading plans are now a part of the <strong>BacktotheBible.app</strong> experience.</p>
      <p>You will be re-directed there and can continue to use the same user login details.</p>
      <p>We hope you enjoy our new Bible Engagement Platform!</p>

    </div>

  </mgc-dialog-content>

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="processing" (click)="onClick()"> <span>Ok</span> </mgc-raised-button>
  </mgc-dialog-actions>

</mgc-dialog>