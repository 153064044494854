import { ISurveyQuestionOption } from "../models/survey-question-option.model";

export interface IQuestionOption{
    id: number;
    position: number;
    text: string;
    default: boolean; 
}

export class QuestionOption implements ISurveyQuestionOption, IQuestionOption {
    id: number;
    position: number;
    text: string;
    default: boolean;

    public get Id(): number {
        return this.id;
    }
    public get Text(): string {
        return this.text;
    }
    public get Position(): number {
        return this.position;
    }
    public get Default(): boolean {
        return this.default;
    }
    public get JumpTo(): string {
        return null;
    }
    public get UserAnswer(): string {
        return null;
    }

    selected: boolean = false;

    constructor(data: IQuestionOption) {

        for (let prop in data) {
            this[prop] = data[prop];
        }

    }

    toJSON() {
        return {
            Id: this.Id,
            Text: this.Text,
            Position: this.Position,
            Default: this.Default,
            JumpTo: this.JumpTo,
            selected: this.selected
        }
    }
}