import { Component, OnInit, Input, SimpleChange, Inject } from "@angular/core";
import { BoardViewService } from "@board/_services";
import { AppConfig, APP_CONFIG } from "@rallysite/config";

@Component({
    selector: 'note-preview, comment-preview, reply-preview',
    template: '<div [innerHTML]="text | safe : \'html\' "></div>',
    styles: [`
        :host{
            display: block;
        }
    `]
})
export class NotePreviewComponent implements OnInit {
    @Input() text: string;

    readonly DEFAULT_W: number = 560;
    readonly DEFAULT_H: number = 315;

    embedWidth: string;
    embedHeight: string;

    constructor(
        private boardViewService: BoardViewService,
        @Inject(APP_CONFIG) readonly appConfig: AppConfig
    ) {
        this.boardViewService.handle();

        let width = this.boardViewService.width;
        switch (true) {
            case width > 576:
                let w = Math.min(this.DEFAULT_W, width - 350/** nav width */ - 50/** side nav */ - 60 /** left-right note padding */);
                this.embedWidth = '' + w;
                this.embedHeight = '' + (w * this.DEFAULT_H / this.DEFAULT_W);
                break;
            default:
                this.embedWidth = '100%';
                this.embedHeight = '' + ((width - 60/** left-right note padding  */) * this.DEFAULT_H / this.DEFAULT_W);
        }
    }

    private embeds(type: string, data: any) {
        let w = data.width || this.embedWidth;
        let h = data.height || this.embedHeight;

        switch (type) {
            case 'youtube':
                return `<br><iframe width="${w}" height="${h}" src="https://www.youtube.com/embed/${data.id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br>`
            case 'vimeo':
                return `<br><iframe  width="${w}" height="${h}" src="https://player.vimeo.com/video/${data.id}"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><br>`;
            // <p><a href="https://vimeo.com/263974008">The hunter</a> from <a href="https://vimeo.com/user2264676">jari vaara</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
            case 'player':
                return `<br><iframe  width="${w}" height="${h}" src="${this.appConfig.playerPath}/embed.html?id=${data.id}&width=${w}&height=${h}"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><br>`;
        }
    }

    private embed(str: string) {
        let parts = str.split(':');
        let id: string = null;
        if (parts[2]) {
            id = parts[2];

            switch (parts[1].toLowerCase()) {
                case 'youtube':
                    return this.embeds('youtube', { id: id });
                case 'vimeo':
                    return this.embeds('vimeo', { id: id });
                case 'player':
                    return this.embeds('player', { id: id });
            }
        }
        return '';
    }

    private handleEmbeds(text: string) {
        const START_MACRO: string = '[[--';
        const END_MACRO: string = '--]]';

        let si = text.indexOf(START_MACRO);
        let ei = text.indexOf(END_MACRO);

        if (si >= 0 && ei >= 0) {
            text = text.substring(0, si) + this.embed(text.substring(si + START_MACRO.length, ei)) + text.substring(ei + END_MACRO.length);
            return this.handleEmbeds(text);
        } else {
            return text;
        }

    }

    ngOnInit() {
        this.text = this.handleEmbeds(this.text);
    }

    ngOnChanges(change: SimpleChange) {
        if (change['text'] && !change['text'].isFirstChange() && change['text'].currentValue) {
            this.text = this.handleEmbeds(this.text);
        }
    }

}
