import { OverlayRef } from "@angular/cdk/overlay";

export class ViewerOverlayRef {

  constructor(
    private overlayRef: OverlayRef) {
  }

  close(): void {
    this.overlayRef.dispose();
  }
}