import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MgcDialogModule, MgcButtonModule } from '@rallysite/mgc-components';
import {
    DnsaNoteListEmptyComponent,
    DnsaGroupCreateComponent,
    DnsaFolderCreateComponent,
    DnsaAccountCreateComponent,
    DnsaProjectCreateComponent,
    DnsaTaskCreateComponent,
    DoNotShowAgainOptionComponent
} from './dnsa-option-components';
import { DoNotShowAgainInlineComponent } from './do-not-show-again-inline.component';
import { DoNotShowAgainOptionDirective } from './do-no-show-again-option.directive';
import { DoNotShowAgainModal, DoNotShowAgainModalWrapper } from './do-not-show-again-modal';
import { DoNotShowAgainSettingsComponent } from './do-not-show-again-settings.component';
import { AlertModule } from '@rallysite/components/alert/alert.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MgcDialogModule,
        MgcButtonModule,
        AlertModule,
    ],
    declarations: [
        DoNotShowAgainSettingsComponent,
        DoNotShowAgainOptionDirective,
        DoNotShowAgainInlineComponent,
        DoNotShowAgainOptionComponent,
        DoNotShowAgainModal,
        DoNotShowAgainModalWrapper,
        DnsaNoteListEmptyComponent,
        DnsaGroupCreateComponent,
        DnsaFolderCreateComponent,
        DnsaAccountCreateComponent,
        DnsaProjectCreateComponent,
        DnsaTaskCreateComponent
    ],
    exports: [
        DoNotShowAgainSettingsComponent,
        DoNotShowAgainModal,
        DoNotShowAgainModalWrapper,
        DoNotShowAgainInlineComponent,
        DoNotShowAgainOptionComponent,
        DnsaNoteListEmptyComponent,
        DnsaGroupCreateComponent,
        DnsaFolderCreateComponent,
        DnsaAccountCreateComponent,
        DnsaProjectCreateComponent,
        DnsaTaskCreateComponent,
    ]
})
export class DoNotShowAgainModule { }
