import { Project } from "../project";

export interface IScheduledFolder {
    Id: string;
    ProjectId: string;
    AccountId: string;
    Name: string;
    Status: string;
    UpdateDate: string;

    _active: boolean;
    _state: string;
    _type: string;
    isDefault: boolean;

    getId();
}

export class BaseScheduledFolder {
    readonly _type: string = 'scheduled-folder';

    Id: string;
    ProjectId: string;
    AccountId: string;
    Name: string;
    Status: string;
    UpdateDate: string;

    _active: boolean;
    _state: string;

    constructor() { }

    getId() {
        return this.Id === DefaultScheduledFolder.ID ? null : this.Id;
    }

    get isDefault() {
        return this.Id === DefaultScheduledFolder.ID;
    }
}

export class DefaultScheduledFolder extends BaseScheduledFolder implements IScheduledFolder {

    public static ID: string = '_default_';

    constructor(project: Project, data: any) {
        super();
        for (let prop in data) {
            switch (prop.toLowerCase()) {
                default:
                    this[prop] = data[prop];
            }
        }

        this.Id = DefaultScheduledFolder.ID;
        this.ProjectId = project.Id;
        this.AccountId = project.AccountId;
        this.Name = 'Keep on top';

        this._active = true; // active (open) all the time
    }
}

export class ScheduledFolder extends BaseScheduledFolder implements IScheduledFolder {

    constructor(data: any) {
        super();
        for (let prop in data) {
            switch (prop.toLowerCase()) {
                default:
                    this[prop] = data[prop];
            }
        }
    }
}
