import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of as observableOf } from "rxjs";
import { IAccountSettings, AccountSettings } from "@board-accounts/account.settings.class";
import { Account } from "@board-accounts/account.model";
import { map } from "rxjs/operators";
import { MessageBusService, MessageBusActions } from "@rallysite/global-services";
import { ThemingService } from "@libraries/theming/theming.service";
import { IThemeOptions } from "@libraries/theming";
import { AccountService } from "@board-accounts/account.service";

@Injectable({
    providedIn: 'root'
})
export class BoardViewSettingsService {

    private _viewMode: BehaviorSubject<string>;
    private _theme: BehaviorSubject<IThemeOptions>;

    constructor(
        private accountService: AccountService,
        private messageBus: MessageBusService,
        private themingService: ThemingService
    ) {
        this._viewMode = new BehaviorSubject<string>(null);
        this._theme = new BehaviorSubject<IThemeOptions>(null);
    }

    get viewMode$(): Observable<string> {
        return this._viewMode.asObservable();
    }
    get viewMode(): string {
        return this._viewMode.getValue();
    }

    get theme$(): Observable<IThemeOptions> {
        return this._theme.asObservable();
    }
    get theme(): IThemeOptions {
        return this._theme.getValue();
    }

    private themeChange(theme: IThemeOptions) {
        this.themingService.setTheme(theme);
    }

    init(account: Account = null) {
        if (!account) return;

        this._viewMode.next(account.Settings.viewMode);
        this.themeChange(account.Settings.theme);
    }

    update({ viewMode, theme, dry = false }: { viewMode?: string, theme?: IThemeOptions, dry?: boolean }) {
        let account = this.accountService.currentAccount;
        account.Settings.viewMode = viewMode;
        account.Settings.theme = theme;

        // if (dry) {
        //     this._viewMode.next(account.Settings.viewMode);
        //     this.themeChange(account.Settings.theme);
        //     return;
        // }

        return this.accountService.updateSettings(<AccountSettings>account.Settings).pipe(
            map((settings: IAccountSettings) => {
                if (settings) {
                    this._viewMode.next(settings.viewMode);
                    this.themeChange(settings.theme);
                }
            })
        )
    }


}