import { take, map } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { Component, OnInit, Input, Inject, HostBinding, Output, EventEmitter } from '@angular/core';

import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AuthService } from '@rallysite/auth-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPromoImageMetadata } from './promo-image-metadata';
import { Project } from '@board/items/project/project';
import { ProjectService } from '@board/items/project/project.service';

@Component({
  selector: 'mgc-promo-section',
  templateUrl: './promo-section.component.html',
})
export class PromoSectionComponent implements OnInit {

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private sanitizer: DomSanitizer,
    private projectService: ProjectService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {

  }
  get f() { return this.form.controls; }

  get dirty() {
    return this.previousPromoImageMessage !== this.form.value['promoImageMessage'];
  }
  @HostBinding('class.promo-section') promoSectionClass = true;

  @Input() project: Project;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();


  promoImageUploaderOptions: any;
  promoImage: {
    item: any,
    url: string,
    ratio: number,
    message: string
  } = { item: null, url: '', ratio: null, message: '' };
  promoUrl: SafeStyle;
  previousPromoImageMessage: string;


  form: FormGroup;

  hasUrl = false;

  /**
   * Hero image methods
   */
  onImageBeforeUpload(item: any) {
    this.promoImage.item = item;
    this.setPromoUrl();
  }

  onImageProgress(data: { item: any; response: any }) {
  }

  onImageSuccess(data: { item: any; response: any }) {
    this.promoImage.item = data.item;
    this.updatePromoImageMetadata({
      id: data.response.Id,
      systemId: data.response.SystemName,
      ratio: data.response.Ratio
    }).subscribe();
  }

  onImageError(data: { item: any; response: any }) {
  }
  private setPromoUrl() {
    let url: string;
    if (this.promoImage.item) {
      url = URL.createObjectURL(this.promoImage.item.file.rawFile);
    } else {
      url = this.promoImage.url;
    }
    this.promoUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    this.hasUrl = !!this.promoImage.url;
  }

  private updatePromoImageMetadata(heroImageMetadata: IPromoImageMetadata) {
    heroImageMetadata.message || (heroImageMetadata.message = this.promoImage.message);

    this.previousPromoImageMessage = heroImageMetadata.message;
    return this.projectService.updateHeroImageMetadata(this.project, heroImageMetadata).pipe(
      take(1), map(project => {
        if (!project) {
          return null;
        }
        this.project = project;
        this.bootstrapPromoImage();
        return this.project.Metadata.heroImage;
      }));
  }

  save() {
    if (!this.dirty) {
      return observableOf(null);
    }

    this.promoImage.message = this.form.value['promoImageMessage'];

    this.project.Metadata.heroImage
    || (this.project.Metadata.heroImage = <IPromoImageMetadata>{ id: null, ratio: null, message: '', systemId: null });

    this.project.Metadata.heroImage.message = this.promoImage.message;
    return this.updatePromoImageMetadata(this.project.Metadata.heroImage);
  }


  private bootstrapPromoImage() {
    if (this.project.Metadata.heroImage) {
      const sys = this.project.Metadata.heroImage.systemId ? this.project.Metadata.heroImage.systemId.split('.')[0] : null;

      this.promoImage.url = sys ? `${this.config.cdnProject}/${this.project.Id}/${sys}_small` : '';
      this.previousPromoImageMessage = this.promoImage.message = this.project.Metadata.heroImage.message || '';
    }

    this.setPromoUrl();
  }


  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.change.emit();
    });
  }

  ngOnInit() {
    this.bootstrapPromoImage();

    this.form = this.formBuilder.group({
      promoImageMessage: [this.promoImage.message || ''],
    });

    this.promoImageUploaderOptions = {
      autoUpload: true,
      buttonText: 'Add promo image',
      filename: 'pfile',
      tokenGetter: this.authService.tokenGetter,
      endPoint: `${this.config.endpoint}/api/project-library`,
      payload: {
        ProjectId: this.project.Id
      },
    };

    this.onChanges();
  }
}
