<div #galleryContainer class="galleryContainer">
    <div class="innerGalleryContainer">
        <ng-content></ng-content>

        <div *ngFor='let imgrow of gallery | slice:rowIndex:rowIndex+rowsPerPage; trackBy:trackByRowIdx '
            class="imagerow" [style.padding.px]="providedImageMargin/2">

            <div #imageElement class="image-wrapper mgc-gallery-item" *ngFor='let img of imgrow; trackBy: trackByImgId'
                [class.item-selected]="img.selected" [style.width.px]="img[minimalQualityCategory].width"
                [style.height.px]="img[minimalQualityCategory].height" [style.margin-right.px]="calcImageMargin()">

                <img [style.max-width.px]="img[minimalQualityCategory].width"
                    [style.min-width.px]="img[minimalQualityCategory].width"
                    [style.height.px]="img[minimalQualityCategory].height" (click)="toggleSelectImg(img)"
                    [src]="img.src | safe:'resourceUrl'" />

                <div class="check-caption" *ngIf="img.selected && !img.inUse">
                    <mat-icon svgIcon="board-mat:check-caption"></mat-icon>
                </div>

                <div class="overlay" *ngIf="img.item?.isUploading">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="30"></mat-progress-spinner>
                </div>
                <div class="overlay" *ngIf="img.item?.isReadyForUpload">
                    <mat-progress-bar color="primary" mode="buffer" value="0" bufferValue="30" [style.height.px]="3"
                        [style.width.%]="40"></mat-progress-bar>
                </div>

            </div>
        </div>
    </div>
</div>