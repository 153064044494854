<div @animation>
    <mgc-icon-button class="close-btn" tabindex="-1" (click)="onClose()" icon="board-mat:close" ></mgc-icon-button>

    <form class="copy-link" [formGroup]="shareForm">
        <div class="f-inline">
            <mgc-form-field class="flex-auto" label="" disabled>
                <div class="field-wrapper">
                    <input formControlName="link" name="shareLink">
                </div>
            </mgc-form-field>

            <mgc-raised-button class="copy-button" [socialShare]="LINK_COPY" [class.copy-done]="copied" [class.copy-error]="copiedError"
                [clipboard]="shareLink" (clipboardCopy)="copySuccess($event)" (clipboardError)="copyError($event)">
                <span [innerHTML]="copied? 'Copied' : (copiedError ? 'Error' : 'Copy')"></span>
            </mgc-raised-button>
        </div>
    </form>

</div>
