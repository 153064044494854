
import { takeWhile } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { TaskGroup, DefaultTaskGroup, ITaskGroup } from './task-group';

import { Project } from '@board/items/project';
import { BaseService, } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

@Injectable({
    providedIn: 'root'
})
export class TaskGroupService extends BaseService {
    private _taskGroups: BehaviorSubject<TaskGroup[]>;
    private _taskGroup: BehaviorSubject<TaskGroup>;
    private dataStore: {
        taskGroups: TaskGroup[],
        defaultGroups: DefaultTaskGroup[]
    }
    private _loadingState: BehaviorSubject<number>;
    private _processingState: BehaviorSubject<number>;
    private _endPoint: string;

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private targetService: TargetResourceService
    ) {
        super();
        this._endPoint = `${this.config.endpoint}/api/groups`;
        this.dataStore = { taskGroups: [], defaultGroups: [] };
        this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._taskGroup = <BehaviorSubject<TaskGroup>>new BehaviorSubject(null);
        this._taskGroups = <BehaviorSubject<TaskGroup[]>>new BehaviorSubject([]);
    }

    get loadingState$(): Observable<number> {
        return this._loadingState.asObservable();
    }
    get processingState$(): Observable<number> {
        return this._processingState.asObservable();
    }
    resetProcessingState$(): Observable<number> {
        this._processingState.next(-1);
        return this.processingState$;
    }

    get taskGroups$(): Observable<TaskGroup[]> {
        return this._taskGroups.asObservable();
    }
    get taskGroups(): Observable<TaskGroup[]> {
        return this._taskGroups.asObservable();
    }
    get taskGroup$(): Observable<TaskGroup> {
        return this._taskGroup.asObservable();
    }
    get taskGroup(): TaskGroup {
        return this._taskGroup.getValue();
    }

    loadAll(project: Project) {
        if (this.inStorage(project)) {
            this.fetch(project);
            return;
        }

        this._loadingState.next(TaskGroupService.STATES.LOADING);
        this.dataStore.taskGroups[project.Id] = [];
        let params = new HttpParams({
            fromObject: {
                'filter[ProjectId]': project.Id
            }
        })
        this.http.get(this._endPoint, { params: params, observe: 'response' })
            .subscribe(
                (response) => {
                    let data = response.body;
                    let headers = response.headers as HttpHeaders;

                    //set default group ahead of time
                    this.getDefaultGroup(project);

                    for (let key in data) {
                        let group = data[key];
                        this.dataStore.taskGroups[project.Id].push(new TaskGroup(group))
                    };
                    this.fetch(project);
                    this.activateGroupIn(project);
                },
                error => {
                    console.log('Could not load taskGroups');
                    delete (this.dataStore.taskGroups[project.Id]);
                    this._loadingState.next(TaskGroupService.STATES.ERROR);
                    this.alertService.error(error, ServiceAlertClass.ALERTS.TASKGROUP_LIST);
                }
            )
    }

    create(taskGroup: TaskGroup, project: Project) {
        this._processingState.next(TaskGroupService.STATES.PROCESSING);
        this.http.post(this._endPoint, taskGroup.toDb())
            .subscribe(
                data => {
                    this.addUI(new TaskGroup(data), project.Id);

                    // Metadata Project is updated; so it is the UpdateDate
                    // will use New Created group UpdateDate for project as well
                    project.UpdateDate = data['UpdateDate'];

                    this._processingState.next(TaskGroupService.STATES.DONE);
                    this.fetch(project);
                },
                error => {
                    console.log('Could not create taskGroup.');
                    if (error.status === 401) {
                        this._processingState.next(TaskGroupService.STATES.UNAUTHORIZED);
                    } else {
                        this._processingState.next(TaskGroupService.STATES.ERROR);
                    }
                    this.alertService.error(error, ServiceAlertClass.ALERTS.TASKGROUP_CREATE);
                });
    }

    update(taskGroup: TaskGroup, project: Project) {
        this._processingState.next(TaskGroupService.STATES.PROCESSING);
        this.http.put(`${this._endPoint}/${taskGroup.Id}`, taskGroup.toDb())
            .subscribe(data => {
                let uTG: ITaskGroup;
                if (data['Id'] === '_default_') {
                    project.Settings.defaultGroup = {
                        name: data['Name'],
                        color: data['Settings']['color']
                    }
                    project.UpdateDate = data['UpdateDate'];
                    uTG = new DefaultTaskGroup(project, { _notifications: taskGroup._notifications })
                } else {
                    uTG = new TaskGroup(data);
                }
                this.updateUI(uTG, project.Id);
                this._taskGroup.next(uTG);

                this._processingState.next(TaskGroupService.STATES.DONE);
                this.fetch(project);
            }, error => {
                console.log('Could not update project.');
                if (error.status === 401) {
                    this._processingState.next(TaskGroupService.STATES.UNAUTHORIZED);
                } else {
                    this._processingState.next(TaskGroupService.STATES.ERROR);
                }
                this.alertService.error(error, ServiceAlertClass.ALERTS.TASKGROUP_UPDATE);
            });
    }

    remove(taskGroup: TaskGroup) {
        if (!taskGroup.getId()) {
            return;
        }
        this._processingState.next(TaskGroupService.STATES.PROCESSING);
        taskGroup._state = 'remove';
        this.http.delete(`${this._endPoint}/${taskGroup.Id}`)
            .subscribe(data => {
                this.removeUI(taskGroup);
                this._processingState.next(TaskGroupService.STATES.DONE);
            },
                error => {
                    console.log('Could not remove task group.');
                    if (error.status === 401) {
                        this._processingState.next(TaskGroupService.STATES.UNAUTHORIZED);
                    } else {
                        this._processingState.next(TaskGroupService.STATES.ERROR);
                    }
                    this.alertService.snackError(error);
                }
            )
    }

    private inStorage(project: Project) {
        return !!this.dataStore.taskGroups[project.Id];
    }
    private cleanStorage(project: Project) {
        return delete (this.dataStore.taskGroups[project.Id]);
    }

    private fetch(project: Project) {
        let pTaskGroups = Object.assign({}, this.dataStore).taskGroups[project.Id];
        this._taskGroups.next(pTaskGroups);

        if (pTaskGroups.length > 0) {
            this._loadingState.next(TaskGroupService.STATES.DONE);
        } else {
            this._loadingState.next(TaskGroupService.STATES.EMPTY);
        }
    }

    private addUI(taskGroup: TaskGroup, projectId?: string) {
        projectId || (projectId = taskGroup.ProjectId);
        if (this.dataStore.taskGroups[projectId]) {
            this.dataStore.taskGroups[projectId].splice(1, 0, taskGroup);
        }
        return this;
    }
    private removeUI(taskGroup: TaskGroup, projectId?: string) {
        projectId || (projectId = taskGroup.ProjectId);
        if (this.dataStore.taskGroups[projectId]) {
            this.dataStore.taskGroups[projectId].forEach((t, i) => {
                if (t.Id === taskGroup.Id) {
                    this.dataStore.taskGroups[projectId].splice(i, 1);
                }
            });
        }
        return this;
    }
    private updateUI(taskGroup: TaskGroup, projectId?: string) {
        projectId || (projectId = taskGroup.ProjectId);
        if (this.dataStore.taskGroups[projectId]) {
            this.dataStore.taskGroups[projectId].forEach((t, i) => {
                if (t.Id === taskGroup.Id) {
                    this.dataStore.taskGroups[projectId][i] = taskGroup;
                }
            });
        }
        return this;
    }

    private activateGroupIn(project: Project) {
        if (this.targetService.targetLevel > 1) {
            let targetsSet: boolean = false;
            this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
                .subscribe((targets) => {
                    // if we have a task set; it means we MUST active a group
                    if (targets.taskId) {
                        targetsSet = true;
                        let targetGroup = this.findGroup(targets.groupId, project.Id);
                        if (targetGroup) {
                            this.activateGroup(targetGroup);
                        }
                    }
                })
        } else {
            // activate default group
            // if (this.dataStore.taskGroups[project.Id].length === 1) {
            //     this.activateGroup(this.dataStore.taskGroups[project.Id][0]);
            // }
        }
    }

    findGroup(groupId: string, projectId: string): TaskGroup {
        if (!groupId || groupId === DefaultTaskGroup.ID) {
            return this.dataStore.defaultGroups[projectId];
        }
        let targetGroup: TaskGroup;
        if (!this.dataStore.taskGroups[projectId]) {
            return null;
        }
        for (let group of this.dataStore.taskGroups[projectId]) {
            if (group.Id === groupId) {
                targetGroup = group;
                break;
            }
        }
        return targetGroup || null;
    }

    getDefaultGroup(project: Project): DefaultTaskGroup {
        if (!this.dataStore.defaultGroups[project.Id]) {
            this.dataStore.defaultGroups[project.Id] = new DefaultTaskGroup(project, {});
        }

        return this.dataStore.defaultGroups[project.Id];
    }

    activateGroup(group: TaskGroup) {
        // this.messageBus.publish(MessageBusActions.ACTIVATE_UMBRELLA, {umbrella: umbrella, action: 'open'});
        group._active = true;
    }
}