import {Inject, Injectable} from '@angular/core';
import {BaseService} from '@board/_services';
import {of as observableOf, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '@rallysite/config';
import {catchError, map} from 'rxjs/operators';
import {IModalBlock} from './modal-block.interface';
import {IModalBlocksMetadata, ModalBlocksMetadata} from './modal-blocks-metadata';
import {IModelService} from '@panel-components/notes';
import {AlertService} from '@rallysite/components/alert';
import {Modal} from "@board/modals/modals-editor/modal.model";

@Injectable({
  providedIn: 'root'
})
export class ModalBlocksService extends BaseService implements IModelService {

  private _endPoint: string;

  private dataStore: {
    [key: string]: IModalBlocksMetadata;
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService,
  ) {
    super();
    this.dataStore = {};
    this._endPoint = `${this.config.endpoint}/api/modal-blocks`;
  }

  getBlocks(modal: Modal) {
    if (this.dataStore[modal.Id]) {
      return observableOf(this.dataStore[modal.Id]);
    }

    return this.http.get(`${this._endPoint}/${modal.Id}`).pipe(
      map((data: IModalBlocksMetadata) => {
        const bMetadata = new ModalBlocksMetadata(data);
        this.dataStore[modal.Id] = bMetadata;

        if (bMetadata.blocks.length !== bMetadata.order.length ) {
          this.alertService.snackWarning('<span style="line-height:1.5"><b>Warning</b>: The order of the note blocks might not be as expected. <br/>Consider checking, reorganizing and saving.</span>');
        }

        return this.dataStore[modal.Id];
      }), catchError(error => {
        console.log('Could not load modal v2 blocks');
        return observableOf(null);
      })
    )
  }

  saveBlocks(modal: Modal, { accountId, blocks }: { accountId: string, blocks: IModalBlock[] }) {
    return this.http.put(`${this._endPoint}/${modal.Id}`, { accountId, blocks }).pipe(
      map((data: IModalBlocksMetadata) => {
        const blocksMetadata = new ModalBlocksMetadata(data);
        this.dataStore[modal.Id] = blocksMetadata;
        // !!! important To update modal BlocksMetadata
        modal.BlocksMetadata = data['BlocksMetadata'] || [];
        return this.dataStore[modal.Id];
      }), catchError(error => {
        console.log('Could not save modal v2 blocks');
        return observableOf(null);
      })
    )
  }


  /**
   * For attachments block; order Image files
   * @param newOrder
   * @param note
   * @returns
   */
  orderImages(newOrder: Array<string>, block: any) {
    return this.http.put(`${this._endPoint}/images-order/${block.BlockId}`, {
      'modalId': block.ModalId,
      'order': newOrder
    }).pipe(
      map(data => {
        block.Content.imageOrder = data['imageOrder'];
        return block;
      }),
      catchError(error => {
        console.log('Could not update Block Content. Image Order: ' + error.status);
        return observableOf(false);
      })
    )
  }

  remove(block: any) {
    // blocks are remove on general save button if they
    // are marked as deleted
    return of(null);
  }
}
