import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileSelectDirective } from './file-select.directive';
import { FileDropDirective } from './file-drop.directive';

@NgModule({
  imports: [ CommonModule ],
  declarations: [FileSelectDirective, FileDropDirective,],
  exports: [FileSelectDirective, FileDropDirective, ]
})
export class FileUploadModule { }