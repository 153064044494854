const isValidImg = function (e: any): boolean {
  if (!e) {
    return false;
  }
  if ('naturalHeight' in e) {
    return e.naturalHeight + e.naturalWidth !== 0;
  }

  return e.width !== undefined
    && e.height !== undefined
    && e.width + e.height !== 0
}

export const sendBeacon = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', (event: any) => {
      isValidImg(event) ? resolve() : reject('An error occurred!');
    });
    img.addEventListener('error', (err) => reject(err));
    img.src = url;
  });
}
