import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators'

@Component({
    selector: 'server-busy-snack',
    template: `
    <span class="warning">
        Oops! Server busy, please try again in a minute.
    </span>
    `,
    styles: [`.warning { color: orange; }`],
})
export class ServerBusySnack implements OnInit {

    countDown: any;
    data: any;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) data: any
    ) {
        this.data = data;
    }

    ngOnInit() {
        // this.countDown = timer(0, 1000).pipe(
        //     take(this.data.time),
        //     map(() => --this.data.time)
        // );
    }
}
