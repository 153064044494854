<form [formGroup]="authForm">

  <mgc-form-field label="{{ data?.label || 'Email'}}" [invalid]="submitted && f.email.errors">
    <div class="field-wrapper">
      <input formControlName="email" name="email" autocomplete="email">
      <span class="placeholder" *ngIf="!f.email.value">Enter your email here ...</span>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.email.errors">
      <div *ngIf="f.email.errors.required">Email is required</div>
      <div *ngIf="f.email.errors.email && !f.email.errors.pattern">Email must be a valid email address</div>
      <div *ngIf="f.email.errors.pattern && !f.email.errors.trimError">Email is not correctly formatted ( e.g. <b>example&#64;gmail.com</b> )</div>
      <div *ngIf="f.email.errors.trimError">Email {{f.email.errors.trimError.value}}</div>
    </ng-container>
  </mgc-form-field>

</form>