import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Note } from './note';
import { NoteModal } from './note-modal';

import { Task } from '@board/items/task';
import { Account, AccountService } from '@board-accounts/index';
import { ScheduledTask } from '@board/items/scheduled-task/scheduled-task';

@Component({
  selector: 'mgc-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.scss']
})
export class NoteInputComponent implements OnInit {
  @HostBinding('class.mgc-note-input') noteInputClass = true;
  @Input('item') task: Task | ScheduledTask;
  @HostListener('click', ['$event.target'])
  onClick(btn){
    this.newNoteModal();
  }

  value: string = ''
  private get account(): Account {
    return this.accountService.currentAccount;
  }

  constructor(
    private accountService: AccountService,
    public dialog: MatDialog,
  ) { }

  newNoteModal() {
    let note = new Note({
      Text: '',
      TaskId: this.task.Id,
      AccountId: this.account.Id
    });

    let opened: boolean = true;
    this.dialog.open(NoteModal, note.settingsOptions(this.task, false))
      .afterClosed().pipe(takeWhile(() => opened)).subscribe(result => {
        opened = false;
      });
  }

  ngOnInit() {

  }

}
