<ng-container *ngIf="participant">
    <account-avatar [with-halo]="assigned?4:0" strokeWidth="1" [size]="avatarSize"
        [account]="participant._account || {}"></account-avatar>
    <span class="info">
        <span class="name" [innerHTML]="participant._account.OwnerFirstName"></span>
        <span class="details">
            <span *ngIf="owner">Owner</span>
            <span *ngIf="!owner"
                class="{{participant.resend.alert}}">{{participant.AccessType | translatePermission}}</span>
            <span *ngIf="assigned">Assigned Person</span>
        </span>
    </span>

    <mgc-icon-button *ngIf="participant._state!==states.PROCESSING" [matMenuTriggerFor]="mgcMenu.menu"
        icon="board-mat:more_vert"></mgc-icon-button>
    <mgc-loader *ngIf="participant._state===states.PROCESSING" color="accent"></mgc-loader>
</ng-container>

<ng-content></ng-content>

<mgc-menu #mgcMenu>
    <mgc-menu-item *ngIf="assigned" (click)="unassign()" icon="board-mat:radio_checked">Unassign</mgc-menu-item>
    <mgc-menu-item *ngIf="!assigned" (click)="assign()" icon="board-mat:radio_unchecked">Assign</mgc-menu-item>
    <mgc-menu-item (click)="openDetailsModal()" icon="board-mat:user_details">User details</mgc-menu-item>
    <mgc-menu-item *ngIf="allowRemove" (click)="removeParticipant()" icon="board-mat:delete">delete</mgc-menu-item>
</mgc-menu>