<mgc-dialog>
  <mgc-dialog-header [title]="data.title" (close)="closeDialog()"></mgc-dialog-header>
  <mgc-dialog-content class="mgc-message">
    <mgc-post-as *ngIf="!data.edit" (ownerAs)="postAs($event)"></mgc-post-as>
    <div class="mgc-tabs">
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="editMode" (click)="changeToEdit()">Edit</span>
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="previewMode"
        (click)="changeToPreview()">Preview</span>
      <div class="mgc-link-bar" [style.width.px]="tabWidth" [style.left.px]="leftPosition"></div>
    </div>

    <div class="text-area" [class.preview]="previewMode">
      <textarea #textarea focusIndex="1" *ngIf="editMode" [(ngModel)]="modelText"></textarea>
      <note-preview class="preview-area" *ngIf="previewMode" [text]="(modelText || ' ') | embed: ['embed']">
      </note-preview>
    </div>

    <div class="options">
      <rs-uploader [style.font-size]="0" #rsUploader renderAs="content" [options]="uploaderOptions"
        (onAfterAddingFile)="onAfterAddingFile($event)" (onBeforeUpload)="onBeforeUploadFile($event)"
        (onProgress)="onUploadProgress($event)" (onSuccess)="onUploadSuccess($event)" (onError)="onUploadError($event)"
        (onCompleteAll)="onCompleteAll()">
      </rs-uploader>
      <mgc-icon-button icon="board-mat:camera_alt" (click)="rsUploader.click()"></mgc-icon-button>
      <mgc-icon-button icon="board-mat:attachment" (click)="rsUploader.click()"></mgc-icon-button>
      <mgc-icon-button icon="board-mat:youtube"
        infoTooltip="To embed a video inside the message just copy and paste a YouTube or Vimeo link.">
      </mgc-icon-button>
    </div>

    <attachments *ngIf="attachments.length" [data]="attachments" [model]="comment"></attachments>

  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.COMMENT_CREATE, serviceAlertClass.COMMENT_UPDATE]"></alert>

  <mgc-dialog-actions>
    <mgc-stroked-button left (click)="onDelete()" *ngIf="data.mode==='edit'">Delete</mgc-stroked-button>
    <mgc-raised-button right [processing]="state===states.PROCESSING" (click)="onSave()">Save</mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>
</mgc-dialog>
