import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Theme } from './themes/theme';
import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { ICustomImageOptions, ICustomColorOptions, ITheme, IThemeOptions, ThemeType } from './themes';
import { CustomImageTheme } from './themes/image-theme/theme.custom-image';
import { THEMES, ACTIVE_THEME, THEME_TYPE } from './tokens';
import { NavigationImagePipe } from './themes/image-theme/navigation-image.pipe';
import { CustomColorTheme } from './themes/custom-color-theme/theme.custom-color';
import { DOCUMENT, isPlatformServer } from '@angular/common';

interface IThemeArguments {
  iTheme: ITheme,
  options?: IThemeOptions
}

const themeFactory = ({ iTheme, options }: IThemeArguments) => {
  switch (iTheme.id) {
    case 'custom_image':
      return new CustomImageTheme(iTheme, options ? <ICustomImageOptions>options[options.themeId] : {});
    case 'custom_color':
      return new CustomColorTheme(iTheme, options ? <ICustomColorOptions>options[options.themeId] : {});
    default:
      return new Theme(iTheme, options ? options.type : undefined);
  }
}

@Injectable({
  providedIn: "root"
})
export class ThemingService {

  private theme: Theme;

  constructor(
    @Inject(THEMES) private iThemes: ITheme[],
    @Inject(ACTIVE_THEME) private default_iTheme: ITheme,
    @Inject(THEME_TYPE) private defaultType: ThemeType,
    @Inject(DOCUMENT) private document: any,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(NavigationImagePipe) private navImagePipe: NavigationImagePipe,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.setDefaultTheme();
  }

  setDefaultTheme() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!this.default_iTheme) return;
    this.default_iTheme = { ... this.default_iTheme, ...{ type: this.defaultType || 'light' } };
    this.setTheme({ themeId: this.default_iTheme.id, type: this.default_iTheme.type });
  }

  setPredefinedTheme(theme: ITheme, type?: ThemeType) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const iTheme = this.getTheme(theme.id);
    if (!iTheme) {
      throw Error(`No such predefined theme  < ${theme.id} >`);
    }

    this.theme = themeFactory({ iTheme: iTheme, options: { type: type } });
    this.updateTheme(this.theme);
  }

  setTheme(themeOptions: IThemeOptions) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    // when there is no theme set yetand the default one is custom_image we have to reload default theme
    // on intial setup the element where should attach image wasn't rendered
    if (!themeOptions.themeId && this.default_iTheme.id === 'custom_image') {
      this.setDefaultTheme();
      return;
    }

    const iTheme = this.getTheme(themeOptions.themeId);

    this.theme = themeFactory({ iTheme: iTheme, options: themeOptions });
    this.updateTheme(this.theme);
  }

  getTheme(id: string): ITheme {
    const iTheme = this.iThemes.find(iTh => iTh.id === id);
    if (!iTheme) {
      throw new Error(`Theme not found: '${iTheme}'`);
    }
    return iTheme;
  }

  getActiveTheme() {
    if (isPlatformServer(this.platformId)) {
      return new Theme(this.default_iTheme);
    }

    return this.theme ? this.theme : null;
  }

  getRegisteredThemes() {
    return this.iThemes;
  }

  updateTheme(theme: Theme | CustomImageTheme) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    for (const key in theme.properties) {
      this.document.documentElement.style.setProperty(key, theme.properties[key]);
    }

    setTimeout(() => {
      const navBody = this.document.getElementById('nav-body');
      if (!navBody) return;

      if (theme instanceof CustomImageTheme) {
        navBody.style.setProperty('background-image', `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${this.getImageUrl(theme)})`)
        navBody.style.setProperty('background-repeat', 'no-repeat')
        navBody.style.setProperty('background-position', 'center center')
        navBody.style.setProperty('background-size', 'cover')
        navBody.style.setProperty('transition', 'margin .3s, background-image .4s linear')
      } else {
        navBody.style.setProperty('background-image', null)
      }
    }, 10);

  }

  getImageUrl(theme: CustomImageTheme) {
    return this.navImagePipe.transform(theme.imageId, 'large', true);
  }

}