
import {
    Component, Output, EventEmitter
} from '@angular/core';
import { IPopupView } from '../popup-view.interface';

@Component({
    selector: 'welcome',
    templateUrl: './welcome.component.html',
})
export class WelcomePopupComponent implements IPopupView {
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    data: any;
    processing: boolean = false;
    submitAvailable: boolean = false;

    constructor() { }

    onSubmit(): void { }

    closeIt(): void {
        this.close.next('cancel');
    }

}
