import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CurrentIdService {

    private currentDataStore: {
        currentItemId: string,
        currentAccountId: string,
        currentUmbrellaId: string,
        currentProjectId: string,
        currentParticipantId: string,
        currentTaskId: string
    }

    constructor() {
        this.reset();
    }

    reset() {
        this.currentDataStore = {
            currentItemId: null,
            currentAccountId: null,
            currentUmbrellaId: null,
            currentProjectId: null,
            currentParticipantId: null,
            currentTaskId: null
        }
    }

    public set currentAccountId(value:string) {
        this.currentDataStore.currentAccountId = value || null;
    }
    public get currentAccountId(): string {
        return this.currentDataStore.currentAccountId;
    }

    public set currentParticipantId(value:string) {
        this.currentDataStore.currentParticipantId = value || null;
    }
    public get currentParticipantId(): string {
        return this.currentDataStore.currentParticipantId;
    }
   

}