import { interval, Observable } from "rxjs";
import { map } from 'rxjs/operators';

import * as _moment from 'moment';
const moment = _moment;

export class EntryType {
    Id: string;
    ContestId: string;

    Type: string;
    Settings: any;

    _isValid: boolean;
    untilAvailableSubscription: Observable<any>;

    processing: boolean = false;

    get isValid() {
        return this._isValid;
    }
    set isValid(val: boolean) {
        if (this.isClick && this._isValid !== val) {
            if (!val) {
                let delay = 1000 - moment().milliseconds() % 1000;
                setTimeout(() => {
                    this.untilAvailableSubscription = this.frequencyTimer();
                }, delay);
            } else {
                this.untilAvailableSubscription = null;
            }
        }

        this._isValid = val;
    }


    private frequencyTimer() {
        let frequency: any = this.Settings[this.Type].frequency;
        switch (true) {
            case frequency == 'daily':
                return interval(1000).pipe(
                    map(time => {
                        let tomorrow = moment.utc(0, "HH").add(1, 'days');
                        let now = moment.utc();
                        let duration = moment.duration(tomorrow.diff(now));
                        if (duration.hours() == 0 && duration.minutes() == 0 && duration.seconds() == 0) {
                            this.isValid = true;
                        }
                        return this.parseDuration(duration);
                    }))
            case frequency == 'hourly':
                return interval(1000).pipe(
                    map(time => {
                        let hour = moment.utc().add(1, 'hours').startOf('hour');
                        let now = moment.utc();
                        let duration = moment.duration(hour.diff(now));
                        if (duration.minutes() == 0 && duration.seconds() == 0) {
                            this.isValid = true;
                        }
                        return this.parseDuration(duration);
                    }))
            case typeof (frequency) == 'number':
                return null;
        }

        return null;
    }

    private parseDuration(duration) {
        return duration.hours() + 'h ' + duration.minutes() + 'm ' + duration.seconds() + 's'
    }

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    get isClick() {
        return this.Type === 'click';
    }

    get description() {
        return this.Settings[this.Type].description;
    }


}

export class EntryEarned {
    Id: string;
    EntryId: string;
    CreateDate: string;
    Type: string;
    _points: number;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}

export class Contest {
    Id: string;
    ProjectId: string;

    Status: string;
    Description: string;
    Settings: any;

    StartDate: string;
    EndDate: string;
    UpdateDate: string;
    CreateDate: string;

    _entryTypes: EntryType[];
    _earnedEntries: EntryEarned[];

    _isRunning: boolean;

    constructor(data: any) {
        this.bootstrap(data);
    }

    private bootstrap(data) {
        for (let prop in data) {
            switch (prop.toLowerCase()) {
                case '_entrytypes':
                    this._entryTypes = [];
                    for (let et of data[prop]) {
                        this._entryTypes.push(new EntryType(et))
                    }
                    break
                case '_earnedentries':
                    this._earnedEntries = [];
                    for (let ee of data[prop]) {
                        this.addEarnedEntry(ee)
                    }
                    break
                default:
                    this[prop] = data[prop];
            }
        }

        this._entryTypes || (this._entryTypes = []);
        this._earnedEntries || (this._earnedEntries = []);
    }

    __update(data) {
        this.bootstrap(data);
    }

    totalEntries() {
        return this._earnedEntries.reduce(((total, entry) => {
            return total + entry._points;
        }), 0);
    }

    addEarnedEntry(data) {
        let ee = new EntryEarned(data);
        this._earnedEntries.push(ee);
        return ee;
    }


}