<h2 class="title">View Settings</h2>

<mat-radio-group class="mgc-radio-group" name="theme" [value]="selectedThemeUID" (change)="onThemeChange($event)">

  <!-- <label>Dark Themes</label>
  <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of predefined_iThemes" [value]="radioOptionId({id: iTheme.id, type:'dark'})">
    <span [innerHTML]="iTheme.name"></span>
  </mat-radio-button>

  <label>Light Themes</label>
  <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of predefined_iThemes" [value]="radioOptionId({id: iTheme.id, type:'light'})">
    <span [innerHTML]="iTheme.name"></span>
  </mat-radio-button> -->

  <label>Custom Theme{{custom_iThemes.length > 1 ? 's' : ''}}</label>
  <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of custom_iThemes" [value]="radioOptionId(iTheme)">
    <span *ngIf="activeTheme.id!==iTheme.id" [innerHTML]="iTheme.name"></span>
    <mgc-stroked-button *ngIf="activeTheme.id===iTheme.id" class="height-sm" (click)="openModal(iTheme.id)">
      <span [innerHTML]="iTheme.name"></span>
    </mgc-stroked-button>
  </mat-radio-button>

</mat-radio-group>


<mgc-raised-button class="height-sm" [processing]="processing" (click)="done()" tabindex="2">
  <span [innerHTML]="processing?'':'Done'"></span>
</mgc-raised-button>