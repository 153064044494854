import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from "@rallysite/config";

@Injectable({
  providedIn: 'root',
})
export class PlatformService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
  }

  isBttba() {
    return this.config.domain.match(/backtothebible/g) ||
     this.config.domain.match(/discipleshipplace/g) ;
  }

  isProd() {
    return this.config.env === 'production'
  }

}