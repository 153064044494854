
import { takeWhile } from 'rxjs/operators';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { AccountService } from '@board-accounts/account.service';
import { Account } from '@board-accounts/account.model';


@Component({
  selector: 'account-confirm-note-modal',
  templateUrl: './account-confirm-note-modal.html',
})
export class AccountConfirmNoteModal {
  private alive: boolean = true;
  state: number;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  constructor(
    private accountService: AccountService,
    public dialogRef: MatDialogRef<AccountConfirmNoteModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      account: Account,
    }
  ) {
  }

  get states() {
    return AccountService.STATES;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  send() {
    this.accountService.resendEmail(this.data.account);
  }

  ngOnInit() {
    // this.accountService.resetProcessingState$().pipe(
    //   takeWhile(() => this.alive))
    //   .subscribe(state => {
    //     this.state = state;
    //     if (this.state === this.states.DONE) {
    //       this.dialogRef.close();
    //     } else if (this.state === this.states.UNAUTHORIZED) {
    //       this.dialogRef.close('unauthorized');
    //     }
    //   });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
