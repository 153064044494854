export const environment = {
  production: true,
  env: 'production',
  endpoint: 'api.rallysite.com',
  storeDomain: 'rallysite.com',
  domain: 'rallysite.com',
  protocol: 'https',
  socketPort: '',
  cdnProject: 'https://planassets.rallysite.com',
  cdnEmailFiles: 'https://d1ck8l2nvz9wp1.cloudfront.net',
  cdnImages: 'https://images.rallysite.com',
  playerPath: 'https://ply.rallysite.com',

  datahubUrl: 'https://sdatahub.rallysite.com',
  pageViewTrackEndpoint: 'https://farza94m91.execute-api.us-west-2.amazonaws.com/track-pageview',
  beaconEndpoint: 'https://farza94m91.execute-api.us-west-2.amazonaws.com/track',

  reminderEmailCampaignId: "8WaVfBIeJxJ",

  client: {
    grantType: 'password',
    id: '4',
    secret: 'qGBoSb07RVl4RdLn6AdFn2NWdcOAUVCkQJf8gInM',
    scope: ''
  },
  app_name: 'RallySite',
  contact: {
    email: 'contact@rallysite.com',
    url: 'https://rallysite.com'
  },
  support: {
    email: 'contact@rallysite.com',
    tutorialUrl: 'https://rallysite.com',
  },
  redirectIfBRP: 'https://backtothebible.app',
  whitelistDomains: ['api.rallysite.com'],
  ga_id: 'G-03VJ8181ZZ',
  google_site_verification: 'I2AB7sU9MbiXLPaMPdS4c3kMDcR2i9G7p_V5EhuwjAs',
  legal: {
    terms: '/legal/terms'
  }
};

