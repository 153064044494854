import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'task-v2-embed-block',
  templateUrl: './embed-block.component.html',
  styleUrls: ['./embed-block.component.scss']
})
export class TaskV2EmbedBlockComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
