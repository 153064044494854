
import { Component, OnInit, OnDestroy, Inject, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ServiceAlertClass, IAlertsClass, AlertService } from '@rallysite/components/alert';
import { ReactionsDataComponent } from './reactions-data.component';
import { Note } from '@panel-components/notes';
import { Comment } from '@panel-components/comments';
import { ReactionTypes } from '@reactions/reactions.enum';
import { TaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';
import { CommentReaction, NoteReaction } from '..';

const REFs = {
  ReactionsDataComponent: 'ReactionsDataComponent',
}

@Component({
  selector: 'reactions-modal',
  templateUrl: './reactions-modal.html',
  styleUrls: ['./reactions-modal.scss'],
})
export class ReactionsModal implements OnInit, OnDestroy, AfterViewInit {
  state: number;

  @ViewChild(ReactionsDataComponent) reactionsData: ReactionsDataComponent;
  @ViewChildren('mgcTab', { read: ElementRef }) tabsRef: QueryList<ElementRef>;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  groupedReactions: any;
  item: Note | Comment | TaskV2BlocksMetadata;

  reactions: NoteReaction[] | CommentReaction[];

  constructor(
    public dialogRef: MatDialogRef<ReactionsModal>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: Note | Comment | TaskV2BlocksMetadata,
      groupedReactions: any
    }) {

    this.groupedReactions = data.groupedReactions;
    this.item = data.item;
  }

  /** TABS */
  tabWidth = 60;
  tab = null;
  leftPosition: number = 0;

  tabActive(type: ReactionTypes = null) {
    return this.tab === type;
  }
  activateTab(type: ReactionTypes = null) {
    let index: number = 0;

    if (this.tab === type) {
      return;
    }
    this.tab = type;
    if (this.tab) {
      index = 1 + (this.groupedReactions.findIndex(el => {
        return el.type === this.tab;
      }) || 0);
    }

    this.leftPosition = this.tabWidth * index;
  }


  /** end TABS */

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.activateTab();

    this.reactions = this.item instanceof TaskV2BlocksMetadata ? this.item.reactions : this.item._reactions;
  }

  ngAfterViewInit() {
    if (this.tabsRef.length) {
      setTimeout(() => {
        this.tabWidth = this.tabsRef.first.nativeElement.offsetWidth;
      }, 20);
    }
  }

  ngOnDestroy() {
  }

}
