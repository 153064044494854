import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  ITheme,
  IThemeOptions,
  Theme,
  ICustomColorOptions,
  publicColorTheme,
  customImageTheme,
  customColorTheme
} from '@libraries/theming';
import { MatDialog } from '@angular/material/dialog';
import { ThemingService } from '@libraries/theming/theming.service';
import { Project } from '../project';
import { CustomColorModal } from '@libraries/theming/themes/custom-color-theme/modal/custom-color.modal';
import { take, finalize } from 'rxjs/operators';
import { ProjectService } from '../project.service';

@Component({
  selector: 'project-settings-theme',
  templateUrl: './project-settings-theme.component.html',
  styleUrls: ['./project-settings-theme.component.scss']
})
export class ProjectSettingsThemeComponent {

  @Input() project: Project;
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();

  processing = false;

  iThemes: ITheme[];
  predefined_iThemes: ITheme[];
  custom_iThemes: ITheme[];

  selectedThemeUID: string;
  get activeTheme(): Theme {
    return this.themingService.getActiveTheme();
  }
  themeOptions: IThemeOptions;

  constructor(
    private dialog: MatDialog,
    private themingService: ThemingService,
    private projectService: ProjectService
  ) {
  }

  revertTheme() {
    this.themingService.setTheme(this.project.Settings.theme);
  }

  radioOptionId(data) {
    return data.id + '|' + data.type;
  }

  onThemeChange(ev) {
    this.selectedThemeUID = ev.value;
    const parts = this.selectedThemeUID.split('|');
    const selectedThemeObj = { id: <string>parts[0], type: <'light' | 'dark'>parts[1] };

    this.themeOptions = {
      ...this.themeOptions, ...{
        themeId: selectedThemeObj.id,
        type: selectedThemeObj.type,
      }
    };
    this.themingService.setTheme(this.themeOptions);

    if (this.themeOptions.themeId === 'custom_color') {
      this.openModal(this.themeOptions.themeId);
    }
  }

  openModal(themeId: string) {
    let dialogRef;
    switch (themeId) {
      case 'custom_color':
        dialogRef = this.dialog.open(CustomColorModal, {
          data: {
            customColorOptions: <ICustomColorOptions>this.themeOptions.custom_color
          }
        });
        break;
    }

    if (dialogRef) {
      dialogRef.afterClosed().pipe(take(1)).subscribe((options: ICustomColorOptions) => {
        if (options) {
          this.themeOptions = {
            ...this.themeOptions, ...{
              [themeId]: options
            }
          };
        }
        this.themingService.setTheme(this.themeOptions);
      });
    }
  }

  onSave() {
    this.processing = true;
    this.projectService.saveProjectTheme(this.project, this.themeOptions).pipe(
      finalize(() => this.processing = false)).subscribe((response) => {
        if (response) {
          this.onClose.emit('update');
        }
      });
  }

  ngOnInit() {
    this.themeOptions = Object.assign({}, this.project.Settings.theme);

    this.iThemes = this.themingService.getRegisteredThemes();
    this.predefined_iThemes = this.iThemes.filter(iTh =>
      ![publicColorTheme, customImageTheme, customColorTheme].find(theme => theme.id === iTh.id)
    );
    this.custom_iThemes = this.iThemes.filter(iTh => iTh.id === customColorTheme.id);

    this.selectedThemeUID = this.radioOptionId(this.activeTheme);
  }

}
