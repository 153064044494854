import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    Provider,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Events, validEvents } from './Events';
import { customControls } from './jodit.custom-controls';
import { JODIT } from './jodit.token';
import * as Icons from '@libraries/jodit/icons/index';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { CurrentResourceService } from '@board/_services';
import { TASKS_TABS } from '@items/project/tasks-tabs';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JoditComponent),
    multi: true
};

@Component({
    selector: 'jodit-editor',
    template: `
        <ng-template></ng-template>`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./jodit.min.css'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class JoditComponent extends Events implements AfterViewInit, OnDestroy, ControlValueAccessor {

    @Input()
    set config(v: object | undefined) {
        this._config = Object.assign({}, v, {
            controls: customControls(this.Jodit, {
                player: {
                    getProjectId: () => this.currentResource.project.Id,
                    getTaskId: () => this.currentResource.currentNavTab === TASKS_TABS.SCHEDULED_TASKS
                        ? this.currentResource.scheduledTask.Id
                        : this.currentResource.task.Id
                }
            })
        }, {
            beautifyHTMLCDNUrlsJS: [
                'assets/js/jodit/js-beautify.min.js',
                'assets/js/jodit/js-beautify-html.min.js'
                // 'https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.11.0/beautify.min.js',
                // 'https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.11.0/beautify-html.min.js'
            ],
            sourceEditorCDNUrlsJS: [
                'assets/js/jodit/ace.js'
                // 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.10/ace.js'
            ],
            sourceEditorNativeOptions: {
                showGutter: true,
                theme: 'ace/theme/idle_fingers',
                mode: 'ace/mode/html',
                wrap: true,
                highlightActiveLine: true
            },
            // mobileTapTimeout: 0, // default 300
            enter: 'DIV',
            language: 'en',
            i18n: {
                en: {
                    'Keep': 'Keep Formatting',
                    'Insert as Text': 'Insert as Code',
                    'Insert only Text': 'Insert Plain Text'
                }
            },
            link: {
                noFollowCheckbox: false,
            },
        });
        if (this.element) {
            this.resetEditor();
        }
    }

    get config() {
        return this._config;
    }

    private _config = {};

    @Input() tagName = 'textarea';
    @Input() id: string | undefined;
    @Input() defaultValue: string | undefined;

    element: HTMLElement;
    editor: any;

    private onChangeCallback: (_: any) => {};
    private onTouchedCallback: () => {};

    constructor(
        private elementRef: ElementRef,
        private ngZone: NgZone,
        @Inject(JODIT) readonly Jodit: any,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private currentResource: CurrentResourceService
    ) {
        super();
        this.elementRef = elementRef;
        this.ngZone = ngZone;

        this.bootstrap();
    }

    bootstrap() {
        Object.keys(Icons)
            .forEach((path: string) => {
                this.iconRegistry.getSvgIconFromUrl(this.sanitizer.bypassSecurityTrustResourceUrl((Icons as any)[path]));
            });
    }


    createElement() {
        const tagName = typeof this.tagName === 'string' ? this.tagName : 'textarea';
        this.element = document.createElement(tagName);
        if (this.element) {
            this.element.id = this.id;
            this.elementRef.nativeElement.appendChild(this.element);
        }
    }

    get value(): string {
        if (this.editor) {
            return this.editor.getEditorValue();
        } else {
            return '';
        }
    }

    set value(v: string) {
        if (this.editor) {
            this.editor.setEditorValue(v || '');
        } else {
            this.defaultValue = v;
        }
    }

    resetEditor() {
        this.editor.destruct();
        this.createEditor();
    }

    ngAfterViewInit() {
        if (!this.element) {
            this.createElement();
            this.createEditor();
        }
    }

    createEditor() {
        // Create instance outside Angular scope
        this.ngZone.runOutsideAngular(() => {
            this.editor = new this.Jodit(this.element, this.config);
        });

        if (this.defaultValue) {
            this.editor.value = this.defaultValue;
        }

        this.editor.events
            .on('change', (value: string) => {
                if (typeof this.onChangeCallback === 'function') {
                    this.ngZone.run(() => this.onChangeCallback(value));
                }
            })
            .on('blur', () => {
                if (typeof this.onTouchedCallback === 'function') {
                    this.ngZone.run(() => this.onTouchedCallback());
                }
            });

        validEvents.forEach((eventName) => {
            const eventEmitter: EventEmitter<any> = this[eventName];
            if (eventEmitter.observers.length > 0) {
                let eventNameInJodit = eventName.substring(2);
                eventNameInJodit = eventNameInJodit.substr(0, 1).toLowerCase() + eventNameInJodit.substring(1);
                // tslint:disable-next-line:max-line-length
                this.editor.events.on(eventNameInJodit, this.ngZone.run(() => (...args: any[]) => eventEmitter.emit({
                    args,
                    editor: this.editor
                })));
            }
        });
    }

    ngOnDestroy() {
        if (this.editor) {
            this.editor.destruct();
        }
    }

    writeValue(v: any): void {
        this.value = v;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.editor.setReadOnly(isDisabled);
    }
}
