import { BaseItem } from "@board/items/base-item.model";

export class EmailCampaignGroupAttributes {
    Id: string = null;
    AccountId: string = null;
    Name: string = null;
    Status: string = null;
    Type: string = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class BaseEmailCampaignGroup extends BaseItem {
    readonly _type: string = 'email-campaign-group';

    prevAttributes: EmailCampaignGroupAttributes = new EmailCampaignGroupAttributes();
    attributes: EmailCampaignGroupAttributes = new EmailCampaignGroupAttributes();

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Status(value: string) {
        this.attributes.Status = value;
    }
    get Type(): string {
        return this.attributes.Type;
    }
    set Type(value: string) {
        this.attributes.Type = value;
    }
    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    get isDefault(): boolean {
        return this.Type === DefaultEmailCampaignGroup.ID;
    }
    get isArchive(): boolean {
        return this.Type === 'archive';
    }
    get isCustom(): boolean {
        return this.Type === 'custom';
    }

    get _id() {
        return this.Id === DefaultEmailCampaignGroup.ID ? null : this.Id;
    }
    get _uid() {
        return this.AccountId + '_' + this._id;
    }
}

export class EmailCampaignGroup extends BaseEmailCampaignGroup {
    _state: string;

    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }
    }

    toDb() {
        return {
            Id: this._id,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            UpdateDate: this.UpdateDate
        }
    }
}

export class DefaultEmailCampaignGroup extends BaseEmailCampaignGroup {
    public static ID: string = '_default_';

    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        this.Id = DefaultEmailCampaignGroup.ID;
        this.Type = DefaultEmailCampaignGroup.ID;
        this.Name = 'Keep on top';
    }
}

export class ArchiveEmailCampaignGroup extends BaseEmailCampaignGroup {
    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        this.Id = 'archive';
        this.Type = 'archive';
        this.Name = 'Archive';
    }
}