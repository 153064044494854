import {Params} from "@angular/router";
import {INextStepAction} from "@pages-footer/page-action-footer/action.constants";

export interface IPageOptions {
  id: string;
  name?: string;
  icon?: string;
  path?: string;
  disabled?: boolean;
  hide?: boolean;
  hideInHeader?: boolean;
  type?: 'absolute' | 'relative';
  data?: any;
  params?: Params;
  nextSteps?: INextStepAction[];
}

type Pages = {
  [key: string]: IPageOptions
}

export const PUBLIC_PAGES = {

  HOME: <IPageOptions>{
    id: 'home',
    name: 'Home',
    icon: '',
    path: '/',
    hideInHeader: true
  },

  LIBRARY: <IPageOptions>{
    id: 'library',
    name: 'Main Library',
    icon: '',
    path: '/library',
    hideInHeader: true
  },

  SURVEY_LIBRARY: <IPageOptions>{
    id: 'survey_library',
    name: 'Survey Library',
    icon: '',
    path: '/survey-library',
    hideInHeader: true
  },

  LEARNING: <IPageOptions>{
    id: 'learning',
    name: 'Learning',
    icon: '',
    path: '/learning',
    hide: true
  },

  TEACHING: <IPageOptions>{
    id: 'teaching',
    name: 'Teaching',
    icon: '',
    path: '/teaching',
    hide: true
  },

  PARTNERSHIPS: <IPageOptions>{
    id: 'partnerships',
    name: 'Partnerships',
    icon: '',
    path: '/partnerships',
    hide: true
  },

  FAQ: <IPageOptions>{
    id: 'faq',
    name: 'FAQ',
    icon: '',
    path: '/faq',
    hideInHeader: true,
    hide: true
  },

  SURVEY_PAGE: <IPageOptions>{
    id: 'survey',
    name: 'Survey',
    icon: '',
    path: '/surveys',
    hide: true,
    hideInHeader: true
  },

}

export const TOOLS_PAGES = {

  BOARD: <IPageOptions>{
    id: 'board',
    name: 'Teaching Tools',
    icon: '',
    path: '/admin'
  },

  BOARD_BRANDS: <IPageOptions>{
    id: 'board_brands',
    name: 'Brand Tools',
    icon: '',
    path: '/admin/brands'
  },

  BOARD_SURVEY: <IPageOptions>{
    id: 'board_survey',
    name: 'Survey Tools',
    icon: '',
    path: '/admin/surveys'
  },

  BOARD_EMAILS: <IPageOptions>{
    id: 'board_emails',
    name: 'Email Tools',
    icon: '',
    path: '/admin/emails'
  },

  BOARD_VIDEO: <IPageOptions>{
    id: 'board_video',
    name: 'Video Tools',
    icon: '',
    path: '/admin/video-manager'
  },

}

export const PERSONAL_PAGES = {

  SETTINGS: <IPageOptions>{
    id: 'personal_settings',
    name: 'My Personal Information',
    icon: '',
    path: '/admin/personal-settings'
  },

  COMMUNICATION: <IPageOptions>{
    id: 'communication',
    name: 'My Communication Preferences',
    icon: '',
    path: '/admin/communication-settings'
  },

  DASHBOARD: <IPageOptions>{
    id: 'dashboard',
    name: 'My Dashboard',
    icon: '',
    path: '/spiritual-dashboard'
  },

  SPIRITUAL_WARMUP: <IPageOptions>{
    id: 'warmup',
    name: 'Warmup',
    path: '/spiritual-dashboard/warmup',
    hide: true
  },

  STEP_PAGE: <IPageOptions>{
    id: 'step_page',
    hide: true
  },

  SPIRITUAL_WARMUP_TOPIC: <IPageOptions>{
    id: 'warmup-topic',
    hide: true
  },

  SPIRITUAL_WARMUP_TOPIC_LIST: <IPageOptions>{
    id: 'warmup-topic-list',
    hide: true
  },

  SPIRITUAL_WORKOUT: <IPageOptions>{
    id: 'workout',
    path: '/spiritual-dashboard/workout',
    hide: true
  },

  SPIRITUAL_WORKOUT_TOPIC: <IPageOptions>{
    id: 'workout-topic',
    hide: true
  },
  SPIRITUAL_WORKOUT_TOPIC_LIST: <IPageOptions>{
    id: 'workout-topic-list',
    hide: true
  },

  SPIRITUAL_EXPLORE__TOPIC: <IPageOptions>{
    id: 'explore',
    hide: true
  },

  SPIRITUAL_ASSESSMENT: <IPageOptions>{
    id: 'assessments',
    name: 'My Spiritual Assessment',
    icon: '',
    path: '/assessments',
    hide: true
  },

  MY_SUBSCRIPTIONS: <IPageOptions>{
    id: 'my-subscriptions',
    name: 'My Subscriptions',
    icon: '',
    path: '/spiritual-dashboard/mysubscriptions',
    hide: true
  },

  MY_SURVEYS: <IPageOptions>{
    id: 'my-surveys',
    name: 'My Assessmennts',
    icon: '',
    path: '/spiritual-dashboard/myassessments',
    hide: true
  },

  FEED_YOUR_SOUL: <IPageOptions>{
    id: 'feed_your_soul',
    name: 'Feed Your Soul',
    icon: '',
    path: '/feed-your-soul',
    hide: true
  },

}

export const LEGAL_PAGES = {
  TERMS: <IPageOptions>{
    id: 'terms',
    name: 'Terms and Conditions',
    icon: '',
    path: '/legal/terms'
  },
}

export const ERROR_PAGES = {
  NOT_FOUND: <IPageOptions>{
    id: 'not-found',
    name: '404 Not Found',
    icon: '',
    path: '/not-found'
  },
}


export const RESTRICTED_PAGES = {

  INDEX: <IPageOptions>{
    id: 'admin_index',
    name: '** Admin Tools',
    icon: '',
    path: '/admin/index'
  },

  REPORTING: <IPageOptions>{
    id: 'reports',
    name: 'Admin Reporting Tools',
    icon: '',
    path: '/admin/reports'
  },

  EMAILS: <IPageOptions>{
    id: 'reports-emails',
    name: 'Reports - Emails',
    icon: '',
    path: '/admin/reports/emails'
  },

  USERS: <IPageOptions>{
    id: 'reports-users',
    name: 'Reports - Users',
    icon: '',
    path: '/admin/reports/users'
  },

  ANALYTICS: <IPageOptions>{
    id: 'analytics',
    name: 'Reports - Analytics',
    icon: '',
    path: '/admin/reports/analytics'
  },

  MASTER_REPORT: <IPageOptions>{
    id: 'master-report',
    name: 'Reports - Master Report Download',
    icon: '',
    path: '/admin/reports/master-report'
  },

  BOARD_PUBLIC_PROJECTS: <IPageOptions>{
    id: 'public_projects',
    name: 'Admin Ranking Tools',
    icon: '',
    path: '/admin/public-projects'
  },

  DASHBOARD_SETTINGS: <IPageOptions>{
    id: 'dashboard_settings',
    name: 'Admin Dashboard Settings',
    icon: '',
    path: '/admin/dashboard-settings'
  },

  BOARD_EMAILS_DAILY_REMINDER: <IPageOptions>{
    id: 'daily_reminder',
    name: 'Daily System Email',
    icon: '',
    path: '/admin/daily-email'
  },

  STEPS_SETTINGS: <IPageOptions>{
    id: 'steps_settings',
    name: 'Admin Steps Settings',
    icon: '',
    path: '/admin/steps-settings'
  },

  UNSUBSCRIBE: <IPageOptions>{
    id: 'unsubscribe',
    name: 'Unsubscribe',
    icon: '',
    path: '/admin/unsubscribe'
  },

  MODALS: <IPageOptions>{
    id: 'modals',
    name: 'Modals',
    icon: '',
    path: '/admin/modals'
  },
}

export const DH_PAGES = {

  RESPONSES: <IPageOptions>{
    id: 'responses',
    name: 'Responses',
    icon: '',
    path: '/dh/responses'
  },

}

export const EXTERNAL_PAGES = {
  ADMIN_APP_CONSOLE: <IPageOptions>{
    id: 'abslute_path',
    name: 'Admin App Console',
    icon: '',
    path: 'https://prod-admin.gotandem.com/admin',
    type: 'absolute'
  },
}
