<p>
    <strong>Collections</strong> are like folders for your personal <strong>Plan</strong> organization, no one else can see them.</p>

<p>We encourage you to be conservative with creation of Collections. Some Plans that you want quick access to may not
    need to
    be inside a Collection. Other Plans may have relationships that work well to be inside Collections.
</p>

<!-- <img [style.width.%]="100" src="http://www.mypet.com/img/basic-pet-care/how-long-leave-cat-alone-lead.jpg"> -->

<p>You can always move Plans from one Collection to another.</p>