<alert [service]="[serviceAlertClass.COMMENT_LIST]"></alert>

<mgc-loader *ngIf="state===states.LOADING && !commentsReady"></mgc-loader>

<div *ngIf="commentsReady" [@list]="comments.length">
    <ng-container *ngFor="let comment of comments; trackBy: trackByFn">
        <mgc-comment [comment]="comment" [note]="note" [taskv2BlocksMetadata]="taskv2BlocksMetadata" [commentParent]="commentParent" (onCommentRemoved)="commentRemoved()"></mgc-comment>
    </ng-container>

    <span class="load-more" *ngIf="!loadingMore && moreToLoad" (click)="loadMore()">Load more {{commentParent ? 'replies' : 'comments'}}</span>
    <mgc-loader *ngIf="state===states.LOADING || loadingMore"></mgc-loader>
</div>