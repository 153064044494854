<form [formGroup]="passwordForm" (ngSubmit)="onSave()">

  <mgc-form-field label="Current Password" [invalid]="submitted && pf.oPassword.errors">
    <div class="field-wrapper">
      <input [type]="hide ? 'password' : 'text'" formControlName="oPassword" name="oldPassword"
        autocomplete="old-password">
    </div>
    <ng-container invalid-feedback *ngIf="submitted && pf.oPassword.errors">
      <div *ngIf="pf.oPassword.errors.required">Current password is required</div>
    </ng-container>
  </mgc-form-field>


  <mgc-form-field label="New Password" [invalid]="submitted && pf.password.errors">
    <div class="field-wrapper">
      <input [type]="hide ? 'password' : 'text'" formControlName="password" name="password" autocomplete="new-password">
    </div>

    <ng-container invalid-feedback *ngIf="submitted && pf.password.errors">
      <div *ngIf="pf.password.errors.required">Password is required</div>
      <div *ngIf="pf.password.errors.minlength">Password must be at least 6 characters</div>
    </ng-container>

  </mgc-form-field>


  <mgc-form-field label="Confirm Password" [invalid]="submitted && pf.cPassword.errors">
    <div class="field-wrapper">
      <input [type]="hide ? 'password' : 'text'" formControlName="cPassword" name="confirmPassword"
        autocomplete="new-password">
    </div>
    <ng-container invalid-feedback *ngIf="submitted && pf.cPassword.errors">
      <div *ngIf="pf.cPassword.errors.required">Confirm Password is required</div>
      <div *ngIf="pf.cPassword.errors.mustMatch">Passwords must match</div>
    </ng-container>
  </mgc-form-field>

</form>
<alert>