import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss']
})
export class SurveyModalComponent implements OnInit {
  @HostBinding('class.mgc-survey-modal') surveyPageClass = true;

  constructor(
    public dialogRef: MatDialogRef<SurveyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
    }
  ) {
  }

  onClose(ev = null) {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

}
