import { Directive } from '@angular/core';
import { SurveyModalTriggerDirective } from '../survey-modal/survey-modal-trigger.directive';
import { AssessmentModalComponent } from './assessment-modal.component';

@Directive({
  selector: '[assessment-modal-trigger]'
})
export class AssessmentModalTriggerDirective extends SurveyModalTriggerDirective {

  protected engage() {
    this.open(AssessmentModalComponent);
  }

}