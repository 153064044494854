import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { AssessmentModalComponent } from './assessment-modal.component';

@Component({
  selector: 'assessment-button',
  templateUrl: './../survey-modal/survey-button.html',
  styleUrls: ['./../survey-modal/survey-button.scss'],
})
export class AssessmentButtonComponent{

  constructor(
    private dialog: MatDialog,
    public snackBarRef: MatSnackBarRef<AssessmentButtonComponent>,
  ) {
  }

  engage() {

    this.snackBarRef.dismiss();

    this.dialog.open(AssessmentModalComponent, {
      maxWidth: '500px',
      minHeight: '60vh',
      height: '90vh'
    }).afterClosed().pipe(
      take(1))
      .subscribe(() => {
        //
      })

  }

  ngOnInit(){
  }

}
