import { Directive, ViewContainerRef, Inject, Output, EventEmitter } from '@angular/core';
import { Theme } from '@libraries/theming';
import { ThemingService } from '@libraries/theming/theming.service';
import { WINDOW } from '@rallysite/global-services';

import { User, UserService } from '@rallysite/user';
import { PageAuthPlaceId, PageAuthViewType } from '@page-auth';
import { AuthInlineTempComponent} from './auth-inline-temp-component';

@Directive({
  selector: '[mgc-auth-inline]',
})
export class AuthInlineTempDirective {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private userService: UserService,
    private theming: ThemingService,
    @Inject(WINDOW) private w: Window,
  ) { }
  get user() {
    return this._user || (this._user = this.userService.user);
  }

  @Output() close: EventEmitter<any> = new EventEmitter(null);

  theme: Theme;

  compRef: any;

  _user: User;

  clear() {
    if (this.compRef) {
      this.compRef.destroy();
    }
  }

  /**
   *
   * @param action
   * @param email
   * @param label
   * @param placeId
   * @param skipsignin
   */
  onAuth(
    action: PageAuthViewType = PageAuthViewType.email,
    email: string = null,
    label: string = null,
    placeId: PageAuthPlaceId = undefined,
    skipsignin = false
  ) {
    this.clear();

    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(AuthInlineTempComponent);
    const instanceRef = componentRef.instance as AuthInlineTempComponent;
    instanceRef.authAction = action;
    instanceRef.email = email;
    instanceRef.label = label;
    instanceRef.placeId = placeId;
    instanceRef.skipsignin = skipsignin;


    instanceRef.close.subscribe((svt: any) => {
      this.clear();
      this.close.emit(this.user || svt);
    });

    this.compRef = componentRef;
    componentRef.hostView.detectChanges();
  }

  ngOnInit() {
    this.theme = this.theming.getActiveTheme();
  }
}
