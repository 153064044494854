import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentComponent } from './assessment.component';
import { SurveyOverlayModule } from '@libraries/surveys/_overlay/survey-overlay.module';
import { LoaderModule } from '@libraries/surveys/_utils/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,

    SurveyOverlayModule,
    LoaderModule
  ],

  declarations: [
    AssessmentComponent
  ],
  exports: [
    AssessmentComponent
  ]
})
export class AssessmentsDataModule { }