import { takeWhile, startWith, map, filter, take } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, Inject, NgZone, ChangeDetectorRef, HostBinding } from '@angular/core';

import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { EmailService } from './email.service';

import { TimeAgoPipe, FromUtcPipe, LocalTimePipe } from 'ngx-moment';

import { Project } from '@board/items/project';
import { Account, AccountService, IAccount } from '@board/accounts';
import { UserService } from '@rallysite/user';
import { NoteService } from '@panel-components/notes';
import { Participant, ParticipantService } from '@panel-components/participants';
import { CurrentResourceService } from '@board/_services';
import { ISettingsComponent } from '@rallysite/global-interfaces';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { BrandService } from '@board-brands/brand.service';
import { Brand, DEFAULT_BRAND_UNAME } from '@board-brands/brand.model';
import { PUBLIC_PAGES } from '@pages/pages.enum';
import { MessageDataComponent } from '@board-components/message';
import { ScheduledTask } from '@items/scheduled-task/scheduled-task';
import { IEmailPayload } from './email-payload.interface';


@Component({
  selector: 'email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent extends MessageDataComponent implements ISettingsComponent, OnInit, OnDestroy {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private emailService: EmailService,
    private accountService: AccountService,
    private currentResource: CurrentResourceService,
    private brandService: BrandService,
    private participantService: ParticipantService,
    private userService: UserService,
    private noteService: NoteService,
    private changeRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super();

    this.fromUtc = new FromUtcPipe();
    this.local = new LocalTimePipe();
    this.timeAgo = new TimeAgoPipe(this.changeRef, this.ngZone);
  }

  get states() {
    return EmailService.STATES;
  }

  get publicProjectUrl() {
    const brand = this.brands.find(b => b.Id === this.project.BrandId);
    return `${this.config.protocol}://${this.config.domain}${PUBLIC_PAGES.LIBRARY.path}/${brand ? brand.UName : DEFAULT_BRAND_UNAME}/${this.project._id}`;
  }
  @HostBinding('class.mgc-email') emailClass = true;
  private alive = true;
  state: number;
  private project: Project;
  private account: Account;

  pushNotification = false;

  selectable = true;
  removable = true;
  addOnBlur = true;
  toAll = false;

  email: IEmailPayload;

  @Input() data: any;
  @ViewChild('to') toInput: ElementRef<HTMLInputElement>;

  close$: Subject<any> = new Subject<any>();
  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  toControl: FormControl = new FormControl();
  participants: Participant[] = [];
  filteredParticipants$: Observable<Participant[]>;
  filteredParticipants: Participant[];

  fromUtc: any;
  local: any;
  timeAgo: any;

  brands: Brand[];
  isScheduledTask = false;

  clickVsKeyTimeoutId: any;

  sendAs(account: IAccount) {
    this.email.from = account.Email;
    this.email.senderId = account.Id;

    let s = '';
    if (account) {
      s = `${account.OwnerFirstName || ''} ${account.OwnerLastName || ''}`;
      if (account.Name) {
        s += ` '${account.Name}'`;
      }
    }

    this.email.fromName = s;
  }

  onSave(): void {
    if (!this.isValid()) {
      return;
    }
    this.emailService.sendEmail(this.currentResource.project, this.email);
    if (this.pushNotification) {
      this.noteService.pushNotification(this.data.note).subscribe();
    }
  }

  onSchedule(): void {
    if (!this.isValid()) {
      return;
    }

    this.emailService.scheduleEmail(this.currentResource.project, this.email, this.data.task);
  }

  onCancel(): void {
    this.close$.next('cancel');
  }

  private filter(val: string | Participant): Participant[] {
    if (!val) {
      return this.participants.filter(participant => {
        return !this.email.recipients.find(r => r.address === participant.Email);
      });
    }

    let valLc: string;
    if (val instanceof Participant) {
      valLc = val.Email.toLowerCase();
    } else {
      valLc = val.toLowerCase();
    }
    return this.participants.filter(participant => {
      const accountName = (participant as Participant)._account.Name || '';
      const email = (participant as Participant).Email || '';

      return (accountName.toLowerCase().indexOf(valLc) >= 0 || email.toLowerCase().indexOf(valLc) >= 0) &&
        !this.email.recipients.find(r => r.address === participant.Email);
    });
  }

  toggleAll() {
    if (this.isScheduledTask) {
      return;
    }
    this.email.recipients = !this.toAll ? [] : this.participants.filter(p => p.Settings.allowEmailNotifications).map(p => {
      return { address: p.Email, confirmation: p.Confirmation };
    });
    this.filteredParticipants = this.toAll ? [] : this.filter('');
  }

  isValid(): boolean {
    const hasSubject = !!this.email.subject.trim();
    const hasRecipients = this.isScheduledTask ? this.toAll : this.email.recipients.length > 0;

    return hasSubject && hasRecipients;
  }

  pendingConfirmation(confirmation: 'yes' | 'no' | 'pending') {
    return confirmation === 'pending';
  }

  confirmedConfirmation(confirmation: 'yes' | 'no' | 'pending') {
    return confirmation === 'yes';
  }
  clicked(event) {
    const input = this.toInput.nativeElement;
    input.blur();
  }
  optionSelected(ev: MatAutocompleteSelectedEvent) {
    this.clickVsKeyTimeoutId = setTimeout(() => {
      this.add(<Participant>ev.option.value);
    }, 100);
  }

  private add(p: Participant) {
    const input = this.toInput.nativeElement;

    if ((p.Email || '').trim()) {
      this.email.recipients.push({ address: p.Email.trim(), confirmation: p.Confirmation });
    }

    if (this.email.recipients.length === this.participants.filter(p => p.Settings.allowEmailNotifications).length) {
      this.toAll = true;
    }

    if (input) {
      input.value = '';
    }

    this.toControl.setValue(null);
  }

  remove(recipient: any): void {
    const index = this.email.recipients.indexOf(recipient);

    if (index >= 0) {
      this.email.recipients.splice(index, 1);
    }
    this.toControl.reset();
    if (this.email.recipients.length !== this.participants.length) {
      this.toAll = false;
    }
  }

  displayFn(participant?: Participant): string | undefined {
    return participant ? participant.Email : undefined;
  }

  private sender(noAccount: boolean = false): string {
    const s = (this.userService.user.FirstName || '') + ' ' + (this.userService.user.LastName || '');
    if (noAccount) {
      return s;
    }
    return s + ' \'' + (this.account.Name || '') + '\'';
  }

  toggleOption(data: any) {
    this.email.showNoteBody = data.value;
  }

  initEmailPayload() {
    const task = this.data.task;
    const note = this.data.note;

    const body = '<span>' + (note.Wysiwyg ? note.Wysiwyg : note.Text || '') + '</span>';
    this.email = {
      from: this.account.Email,
      fromName: this.sender(),
      recipients: [],
      subject: `${this.project.Name} || ${task.Name}`,
      body: body,
      customBody: '',
      //
      noteId: note.Id,
      taskId: task.Id,
      senderId: this.account.Id,
      showNoteBody: true
    };
    if (this.isScheduledTask) {
      this.email = { ...this.email, ...task.ScheduledEmail, body: body};
    }
  }

  ngOnInit() {
    this.isScheduledTask = this.data.task instanceof ScheduledTask;

    this.emailService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (this.state === this.states.DONE) {
          this.close$.next();
        } else if (this.state === this.states.UNAUTHORIZED) {
          this.close$.next('unauthorized');
        }
      });

    this.toControl.valueChanges.pipe(
      takeWhile(() => this.alive),
      startWith(null),
      map(p => {
        return this.filter(p);
      })).subscribe((participants) => {
        this.filteredParticipants = participants;
      });

    this.account = this.accountService.currentAccount;
    this.project = this.currentResource.project;

    this.brandService.getBrands().pipe(take(1)).subscribe(brands => this.brands = brands);
    this.initEmailPayload();

    this.participantService.participants$.pipe(
      filter(all => {
        if (!all) {
          return false;
        }
        if (all.length === 0 || (all.length && all[0].ProjectId === this.currentResource.project.Id)) {
          return true;
        }
        return false;
      }),
      takeWhile(() => this.alive))
      .subscribe(all => {
        this.participants = all.filter((p => {
          return p.Confirmation === 'yes' || p.Confirmation === 'pending';
        }));
        this.filteredParticipants = this.filter('');
      });
    this.participantService.loadAll(this.currentResource.project);

  }

  ngOnDestroy() {
    this.alive = false;
  }

}
