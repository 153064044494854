
export enum SurveyQuestionType {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
    DROPDOWN = 'dropdown',
    LINEAR = 'linear',
    DATE = 'date',
    TIME = 'time',
    NUMBER = 'number',
    STRING = 'string',
    YEAR = 'year'
}

export interface ISurveyQuestionType {
    Id: number,
    Type: SurveyQuestionType,
    Text: string,
}

export interface ISurveyQuestionBlock {
    type: SurveyQuestionType;
    iconUnchecked?: string;
    iconChecked?: string;

    // id and name will be filled in from DB
    id?: number;
    name?: string;
    disabled?: boolean;
}

export const SURVEY_QUESTION_BLOCK: ISurveyQuestionBlock[] = [
    { type: SurveyQuestionType.SINGLE, iconUnchecked: 'radio_unchecked', iconChecked: 'radio_checked' },
    { type: SurveyQuestionType.MULTIPLE, iconUnchecked: 'checkbox_unchecked', iconChecked: 'checkbox_checked' },
    { type: SurveyQuestionType.DROPDOWN, iconUnchecked: 'dropdown_checked', iconChecked: 'dropdown_checked' },
    { type: SurveyQuestionType.STRING, iconUnchecked: 'info_outline', iconChecked: 'info_outline' },
    { type: SurveyQuestionType.YEAR, iconUnchecked: 'info_outline', iconChecked: 'info_outline' },
    { type: SurveyQuestionType.NUMBER, iconUnchecked: 'info_outline', iconChecked: 'info_outline' },

    { type: SurveyQuestionType.LINEAR, iconUnchecked: 'info_outline', iconChecked: 'info_outline', disabled: true },
    { type: SurveyQuestionType.DATE, iconUnchecked: 'info_outline', iconChecked: 'info_outline', disabled: true },
    { type: SurveyQuestionType.TIME, iconUnchecked: 'info_outline', iconChecked: 'info_outline', disabled: true },


]

export const qTypeUpdate = (types: ISurveyQuestionType[]) => {
    SURVEY_QUESTION_BLOCK.forEach(b => {
        const tOb: ISurveyQuestionType = types.find((t: ISurveyQuestionType) => t.Type === b.type);
        if (tOb) {
            b.name = tOb.Text;
            b.id = tOb.Id;
        }
    })
}

export const qType_to_qTypeId = (type: SurveyQuestionType): number => {
    const qBlock: ISurveyQuestionBlock = SURVEY_QUESTION_BLOCK.find(b => b.type === type);
    return qBlock ? qBlock.id : 1;
}

export const qTypeId_to_qType = (id: number): SurveyQuestionType => {
    const qBlock: ISurveyQuestionBlock = SURVEY_QUESTION_BLOCK.find(b => b.id === id);
    return qBlock ? qBlock.type : SurveyQuestionType.MULTIPLE;
}


