import { take, finalize } from 'rxjs/operators';
import {
    Component, OnInit, Output, EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPopupView } from '../popup-view.interface';
import { User, UserService } from '@rallysite/user';
import { AlertService } from '@rallysite/components/alert';

@Component({
    selector: 'phone',
    templateUrl: './phone-popup.component.html',
    styleUrls: ['./phone-popup.component.scss']
})
export class PhonePopupComponent implements IPopupView, OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    /** end of FORM */

    get closeData() {
        return this.doneStep || this.f.showAgain.value === 'never'
            ? 'close'  // just close it and don't show it again
            : { showAgain: 'later' }; // will show it again next day
    }

    get stepIndex() {
        if (this.phoneStep) { return 0; }
        if (this.doneStep) { return 1; }
    }
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    data: any;
    popupData: any;
    processing = false;

    phoneStep = true;
    doneStep = false;

    user: User;
    submitAvailable = true;

    /** FORM */
    form: FormGroup;


    submitted = false;

    bootstrapForm() {
        this.user = this.data.user;
        this.popupData = this.data.popup.data;

        this.form = this.formBuilder.group({
            Phone: [this.user.Phone || '', [Validators.required]],
            // CarrierId: [this.user.CarrierId || null, []],
            showAgain: [null],
        });
    }
    submit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return false;
        }

        return true;
    }

    showAgainChanged(ev: MouseEvent | TouchEvent, value: 'later' | 'never') {
        ev.preventDefault();
        ev.stopPropagation();

        this.form.patchValue({
            showAgain: value === this.f.showAgain.value ? null : value
        });
    }

    nextStep() {
        // check the current step and prepare the next one
        if (this.phoneStep) {
            this.phoneStep = this.processing = this.submitted = false;
            this.doneStep = true;
            setTimeout(() => {
                this.alertService.success('Thank you for subscribing & joining us!');
            }, 10);
            return;
        }
    }

    onSubmit(): void {
        if (this.f.showAgain.value === 'later') {
            this.close.emit('done');
            return;
        }

        if (this.doneStep || this.f.showAgain.value) {
            this.close.emit('done');
            return;
        }

        if (!this.submit() || this.processing) {
            return;
        }

        this.processing = true;
        this.user.Phone = this.f.Phone.value;
        this.userService.updatePhone(this.user).pipe(
            finalize(() => this.processing = false),
            take(1)).subscribe(result => {
                if (!result) {
                    this.alertService.error('Woops! Error, something went wrong.');
                    return;
                }

                if (result.phoneData) {
                    this.popupData = result.phoneData;
                }
                this.nextStep();
            });
    }

    ngOnInit() {
        this.bootstrapForm();
    }
}
