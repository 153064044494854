import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { TooltipOverlayModule } from './overlay/tooltip-overlay.module';

@NgModule({
  imports: [
    CommonModule,
    TooltipOverlayModule,
  ],

  declarations: [
    TooltipDirective
  ],
  exports: [
    TooltipDirective
  ]

})
export class TooltipModule { };