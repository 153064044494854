import { Account } from "@board-accounts/index";
import { IFrequencyValue } from "@board/communication-settings/communications.types";
import { IDoNotShowAgainOption } from "./do-not-show-again/do-not-show-again.options.class";
import { IUserContactDetail } from "./user-contact-detail.model";
import { IUserSettings, UserSettings } from "./user-settings.model";

export class User {
    Id: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Sex: 'male' | 'female' | null = null;
    Age: number = null;
    Phone: string;
    CarrierId: number;
    TM: boolean;
    ContactDetails: Array<IUserContactDetail>;
    Settings: IUserSettings;
    CreateDate: string;
    UpdateDate: string;

    _isConfirmed: boolean = false;
    _textRemindersFrequency: IFrequencyValue = null;
    /**
     * {
     *    [email] : [project list]
     * }
     */
    _projects: Array<any> = [];
    _accounts: Array<Account> = [];
    _takenSurveys: Array<{
        TakenId: number,
        SurveyId: string,
        LatestDate: string
    }> = [];

    constructor(data: any) {
        for (let prop in data) {
            if (prop.toLowerCase() === 'settings') {
                this.handleSettings(data[prop]);
            } else {
                this[prop] = data[prop];
            }
        }

        if (!this.ContactDetails) {
            this.ContactDetails = [];
        }

        this._accounts = this.sortAccounts(this._accounts);
    }

    get name() {
        return this.FirstName + ' ' + this.LastName;
    }

    handleSettings(settings: IUserSettings) {
        this.Settings = new UserSettings(settings);
    }

    hasDnsaOption(dnsaOption: IDoNotShowAgainOption): boolean {
        return !!this.Settings.doNotShowAgain.find(o => o.id === dnsaOption.id && o.checked);
    }
    checkDnsaOption(dnsaOption: IDoNotShowAgainOption) {
        let option = this.Settings.doNotShowAgain.find(o => o.id === dnsaOption.id);
        option.checked = true;
    }

    isSubscribedTo(projectId: string, account: Account = null) {
        if (!Object.keys(this._projects).length) {
            return false;
        }

        if (account && account.Email) {
            const accountEmail = account.Email.toLowerCase();
            if (account && this._projects[accountEmail]) {
                return !!this._projects[accountEmail].find(id => id === projectId);
            }
        }

        for (let email in this._projects) {
            let ids: Array<string> = this._projects[email];
            if (ids.find(id => id === projectId)) {
                return true;
            }
        }
        return false;
    }

    unsubscribeFromProject(projectId: string, account: Account = null) {
        if (!Object.keys(this._projects).length) {
            return false;
        }

        let projectIds = [];
        if (account && account.Email) {
            const accountEmail = account.Email.toLowerCase();
            if (account && this._projects[accountEmail]) {
                projectIds = this._projects[accountEmail];
            }
        } else {
            for (let email in this._projects) {
                let ids: Array<string> = this._projects[email];
                if (ids.find(id => id === projectId)) {
                    projectIds = ids;
                    break;
                }
            }
        }

        for (let i = 0; i < projectIds.length; i++) {
            if (projectIds[i] === projectId) {
                delete projectIds[i];
                break;
            }
        }
    }

    tookSurvey(surveyId: string) {
        if (!this._takenSurveys.length) {
            return undefined;
        }
        return this._takenSurveys.find(data => data.SurveyId === surveyId);
    }

    settingsOptions() {
        return {
            data: {
                title: 'My Settings',
                user: this
            }
        }
    }

    /** If any email is provided look for the account with this email */
    getPrimaryAccount(email: string = '__no_email') {
        return this._accounts.find((a: Account) => a.Email.toLowerCase() == email.toLowerCase())
            || this._accounts.find(a => a.Confirmed);
    }

    sortAccounts(accounts: Account[]): Account[] {
        let union: any = {};
        let newOrderList: Array<any> = [];

        const orderList = this.Settings.accounts;
        if (orderList.length === 0) {
            return accounts;
        }

        for (let a of accounts) {
            union[a.Id] = 1;
        }
        for (let oA of orderList) {
            union[oA]++;
            if (union[oA] === 2) {
                newOrderList.push(oA)
            }
        }
        for (let oA in union) {
            if (union[oA] === 1) {
                newOrderList.push(oA);
            }
        }

        return newOrderList.map((accountId) => {
            return accounts.find(a => a.Id === accountId);
        });
    }

    toDb() {
        return {
            Id: this.Id,
            FirstName: this.FirstName,
            LastName: this.LastName,
            Sex: this.Sex,
            Age: this.Age,
            Phone: this.Phone,
            CarrierId: this.CarrierId,
            ContactDetails: this.ContactDetails,
            Settings: this.Settings.toDb(),
            UpdateDate: this.UpdateDate
        }
    }

}