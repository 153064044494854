import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserService, User } from '@rallysite/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@rallysite/auth-service';
import { finalize, take } from 'rxjs/operators';
import { AlertService } from '@rallysite/components/alert';

@Component({
    selector: 'must-activate',
    templateUrl: 'must-activate.component.html',
})
export class MustActivateComponent implements OnInit {

    constructor(
        private alertService: AlertService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private auth: AuthService
    ) {
    }

    get user() {
        return this.userService.user;
    }

    get activated() {
        return this.user._isConfirmed;
    }

    // convenience getter for easy access to form fields
    get f() { return this.activationForm.controls; }

    @Input() message = 'You need to confirm your email address first.';
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    processing = false;
    resending = false;
    resent = false;

    /** FORM */
    activationForm: FormGroup;

    submitted = false;

    bootstrapForm() {
        this.activationForm = this.formBuilder.group({
            email: [{ value: this.user.Email, disabled: true }, [Validators.required, Validators.email]],
            acode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        });
    }
    submit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.activationForm.invalid) {
            return false;
        }

        return true;
    }
    /** end of FORM */

    onSubmit(): void {
        if (!this.submit()) {
            return;
        }

        this.processing = true;
        const payload = Object.assign({}, this.activationForm.getRawValue());
        this.auth.register_activate(payload).pipe(
            finalize(() => {
                this.processing = false;
            }),
            take(1))
            .subscribe(
                data => {
                    const rs = data['RegisterStatus'] || {};
                    if (rs['Id'] && rs['Activated']) {
                        this.user.UpdateDate = data['UpdateDate'];
                        this.user._isConfirmed = true;
                        this.alertService.success('You successfully confirmed your account!');
                    } else {
                        this.alertService.warning('Something happened and the account cannot be confirmed.');
                    }
                },
                error => {
                    this.alertService.error(error);
                });

    }

    resendCode() {
        this.resending = true;
        const payload = Object.assign({}, this.activationForm.getRawValue());
        this.auth.register_resend_code(payload).pipe(
            finalize(() => {
                this.resending = false;
            }),
            take(1))
            .subscribe(
                data => {
                    if (data['message']) {
                        this.resent = true;
                    } else {
                        this.alertService.warning('Something happened and the activation code cannot be send!');
                    }
                },
                error => {
                    this.alertService.error(error);
                });
    }

    onCancel(): void {
        this.close.next('cancel');
    }


    ngOnInit() {
        this.bootstrapForm();
    }


}
