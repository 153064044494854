<mgc-dialog>

  <mgc-dialog-header [title]="titleAuth" (close)="closeDialog($event)">
  </mgc-dialog-header>

  <mgc-dialog-content class="no-min-height">
    <mgc-auth-view [view]="authViewType" [email]="data.email" (close)="closeDialog($event)"></mgc-auth-view>
  </mgc-dialog-content>

  <mgc-dialog-actions *ngIf="authViewType===PageAuthViewType.email">
    <mgc-raised-button center rounded full [processing]="processing" (click)="onSubmit()">
      <span [innerHTML]="'Next'"></span>
    </mgc-raised-button>
  </mgc-dialog-actions>

  <mgc-dialog-actions *ngIf="authViewType===PageAuthViewType.login">
    <a left (click)="switchAuth(PageAuthViewType.register)">Create new</a>
    <mgc-raised-button right [processing]="processing" (click)="onSubmit()">Login</mgc-raised-button>
  </mgc-dialog-actions>

  <mgc-dialog-actions *ngIf="authViewType===PageAuthViewType.register">
    <a left (click)="switchAuth(PageAuthViewType.login)">Sign in instead</a>
    <mgc-raised-button right [processing]="processing" (click)="onSubmit()">Register</mgc-raised-button>
  </mgc-dialog-actions>

  <mgc-dialog-actions *ngIf="authViewType===PageAuthViewType.co_register">
    <mgc-raised-button right [processing]="processing" (click)="onSubmit()">Done</mgc-raised-button>
  </mgc-dialog-actions>

</mgc-dialog>
