
import { take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

import { Component, OnInit, ViewChild } from '@angular/core';
import { IUploaderOptions, UploaderComponent } from '@libraries/uploader';
import { MessageDataComponent } from '@board/_components/message';
import { PermissionService } from '@board/_services';
import { ConfirmOverlayService } from '@board/_components/confirm-overlay/confirm-overlay.service';
import { ServiceStates } from '@rallysite/global-interfaces';
import { AttachmentsComponent, FileService } from '../attachments';
import { File as Attachment, IFile as IAttachment } from '../attachments/file-model';
import { ModelService } from './model.service';

@Component({
  template: '<ng-content></ng-content>',
})
export class WithAttachmentsComponent extends MessageDataComponent implements OnInit {

  @ViewChild(UploaderComponent) uploader: UploaderComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  uploaderOptions: IUploaderOptions;
  state: number;
  get states() {
    return ServiceStates.STATES;
  }

  attachments: IAttachment[];
  inputAttachments: IAttachment[];

  modelText: string;
  model: any;

  constructor(
    protected modelService: ModelService,
    protected fileService: FileService,
    protected confirmOverlay: ConfirmOverlayService,
    protected permissionsSettings: PermissionService,
  ) {
    super();
  }


  // onBeforeUploadItem
  onSave() {
    if (this.state === this.states.PROCESSING){
      return;
    }

    this.uploader.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any) => {
      let attachment = new Attachment(JSON.parse(response));
      this.attachments.forEach((t, i) => {
        if (t.Id === item.id) {
          this.attachments[i] = attachment;
          this.fileService.pushFiles(this.attachments.slice(), this.model);
        }
      });

      if (!this.attachmentsComponent) {
        return;
      }
      if (this.attachmentsComponent.fImages.length) {
        this.attachmentsComponent.fImages.forEach((t, i) => {
          if (t.Id === item.id) {
            this.attachmentsComponent.fImages[i] = attachment;
          }
        });
      }
      if (this.attachmentsComponent.files.length) {
        this.attachmentsComponent.files.forEach((t, i) => {
          if (t.Id === item.id) {
            this.attachmentsComponent.files[i] = attachment;
          }
        });
      }
    }

    this.uploader.uploader.onCompleteAll = () => {
      this.orderAttachments();
    }

    let request = this.saveRequest();
    this.state = this.states.PROCESSING;
    request.subscribe(model => {
      if (!model) {
        return;
      }

      this.model = model;
      this.saveDone(model);

      let haveNew: boolean = false;
      // upload new attachments
      if (this.uploader.uploader.getNotUploadedItems().length) {
        haveNew = true;
      }

      // remove marked attachments
      let removedAttachments = []
      let dirtyAttachments = []
      this.attachments.forEach(f => {
        if (f.removed) {
          removedAttachments.push(this.fileService.remove(<Attachment>f))
        } else if (f.isDirty() && !f.isNew) {
          dirtyAttachments.push(this.fileService.update(<Attachment>f));
        }
      });

      let jobAttachments = removedAttachments.concat(dirtyAttachments);
      if (jobAttachments.length) {
        forkJoin(jobAttachments).subscribe(results => {
          if (!haveNew) {
            this.orderAttachments(this.attachments.filter(f => !f.removed));
          }
        });
      } else {
        if (!haveNew) {
          this.orderAttachments(this.attachments.slice());
        }
      }

      if (haveNew) {
        this.checkUploaderOptions();
        this.uploader.upload();
        this.fileService.pushFiles(this.attachments.slice(), this.model);
      }
      this.state = this.states.DONE;
      this.closeDialog();
    })
  }

  orderAttachments(attachments = null) {
    if (!this.attachmentsComponent) {
      return;
    }

    if (this.attachmentsComponent.fImages.length) {
      let images = this.attachmentsComponent.fImages.filter(img => !img.removed);
      this.modelService.orderImages(images.map(img => img.Id), this.model)
        .pipe(take(1))
        .subscribe((model: any) => {
          let files = (attachments || this.attachments).filter(f => !f.removed);
          let sorted = this.fileService.sort(files, model.Settings.imageOrder);
          this.fileService.pushFiles(sorted, model);
        });
    } else {
      let files = (attachments || this.attachments).filter(f => !f.removed);
      this.fileService.pushFiles(files, this.model);
    }
  }

  saveDone(model: any) {
    throw Error('saveDone() not implemented');
  }
  checkUploaderOptions() {
    throw Error('checkUploaderOptions() not implemented');
  }
  closeDialog() {
    throw Error('closeDialog() not implemented');
  }
  saveRequest(): Observable<any> {
    throw Error('saveRequest() not implemented');
  }


  /**
   * UPLOADER methods
   */
  onBeforeUploadFile(item: any) {
    this.attachments.forEach((t, i) => {
      if (t.Id === item.id) {
        item.formData.push({ name: 'Rotation', value: t.Rotation })
      }
    });
  }

  onAfterAddingFile(item: any) {
    item.id = Math.random();
    let attachment = new Attachment({
      // this id will be updated when attachment is successfully updated
      Id: item.id,
      __rawItem: item
    });

    this.attachments.splice(0, 0, attachment);
    this.attachments = this.attachments.slice();
  }

  onUploadProgress(data: { item: any; response: any }) {

  }

  onUploadSuccess(data: { item: any; response: any }) {
    //not used in this case, when modal close we loose reference
  }

  onUploadError(data: { item: any; response: any }) {

  }

  onCompleteAll() {

  }
  /**
   * END OF UPLOADER METHODS
   */

  protected bootstrap() {
    throw Error('bootstrap() method not implemented');
  }

  ngOnInit() {
    this.bootstrap();
  }

}
