<mgc-loader *ngIf="loading"></mgc-loader>

<ng-container *ngIf="ready">
    <div class="media-header pad-hrz-xs pad-sep-xxs clearfix" [class.preview]="preview">
        <div>
            <div style="margin-right: auto;">
                <ng-container *ngIf="!preview">
                    <mgc-stroked-button style="margin-right: 15px;" [matMenuTriggerFor]="mgcMenu.menu">Add Element/Block
                    </mgc-stroked-button>

                    <mgc-menu #mgcMenu>
                        <mgc-menu-item *ngFor="let block of predefinedBlocks" (click)="onAddBlock(block)">
                            <span [innerHTML]="'Add ' + block.name"></span>
                        </mgc-menu-item>
                    </mgc-menu>

                    <mgc-raised-button-no-anim [disabled]="form.invalid" [processing]="processing" (click)="onSave()">SAVE
                    </mgc-raised-button-no-anim>
                </ng-container>
            </div>
            <mgc-icon-button *ngIf="!preview" style="cursor: pointer;" class="w30" icon="board-mat:close"
                (click)="preview=true;">Close<small style="padding: 0 2px;">&</small>Preview
            </mgc-icon-button>
            <mgc-icon-button *ngIf="preview" style="cursor: pointer;" class="w30" icon="board-mat:mode_edit"
                (click)="preview=false;">Edit
            </mgc-icon-button>
        </div>
        <alert></alert>
    </div>

    <div *ngIf="!preview" class="media-body mgc-panel-body mgc-scroll-area">
        <div style="padding: 1px;" cdkDropList (cdkDropListDropped)="drop($event)"
            runInsideAngular="true">
            <ng-container *ngFor="let block of f.blocks.value" cdkDrag>
                <ng-container [ngSwitch]="block.Type">
                    <ui-text-block *ngSwitchCase="blockType.TEXT" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </ui-text-block>
                    <ui-image-block *ngSwitchCase="blockType.IMAGE" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)" (updated)="imageUpdated()">
                    </ui-image-block>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div *ngIf="preview" class="media-body mgc-panel-body mgc-scroll-area">
        <ng-container *ngFor="let block of f.blocks.value">
            <ng-container [ngSwitch]="block.Type">
                <ui-text-block *ngSwitchCase="blockType.TEXT" preview [block]="block">
                </ui-text-block>
                <ui-image-block *ngSwitchCase="blockType.IMAGE" preview [block]="block">
                </ui-image-block>
                <!-- <ui-video-block *ngSwitchCase="blockType.VIDEO" preview [block]="block">
                </ui-video-block>
                <ui-attachments-block *ngSwitchCase="blockType.ATTACHMENTS" preview [block]="block">
                </ui-attachments-block> -->
            </ng-container>
        </ng-container>
    </div>

</ng-container>
