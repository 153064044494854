import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { SQOptionsComponent } from '../sq-options.component';

@Component({
  selector: 'sq-options-dropdown',
  templateUrl: './sq-options-dropdown.component.html',
  styleUrls: ['./sq-options-dropdown.component.scss']
})
export class SQOptionsDropdownComponent extends SQOptionsComponent {

  noOption = { Id: null, Text: 'Choose ...' };

  constructor(
    protected formBuilder: FormBuilder,
  ) {
    super();
  }

  /** FORM */
  form: FormGroup;
  bootstrapForm() {
    let answer = this.question.answer();
    // just to make sure, sure, sure
    if (answer instanceof Array) {
      answer = answer[0];
    }

    this.form = this.formBuilder.group({
      selectedOption: [answer || this.noOption, [Validators.required]]
    });

    this.formSubscriptions.push(
      this.form.get('selectedOption').valueChanges.subscribe(
        (option: ISurveyQuestionOption) => {
          this.optionChange.emit(option.Id ? option : null);
        }
      )
    );
  }
  get f() { return this.form.controls; }
  /** end of FORM */

}
