<mgc-loader *ngIf="loading"></mgc-loader>

<ng-container *ngIf="ready">
    <div class="media-header mgc-vcard pad-hrz-xs pad-sep-xxs clearfix">
        <div style="width: 100%; display: flex;">
            <div style="margin-right: auto;">
                <mgc-stroked-button style="margin-right: 15px;" [matMenuTriggerFor]="mgcMenu.menu">Add Element/Block
                </mgc-stroked-button>

                <mgc-menu #mgcMenu>
                    <mgc-menu-item *ngFor="let block of predefinedBlocks" (click)="onAddBlock(block)">
                        <span [innerHTML]="'Add ' + block.name"></span>
                    </mgc-menu-item>
                </mgc-menu>

                <mgc-raised-button
                  [disabled]="form.invalid"
                  [processing]="processing"
                  (click)="onSave(true)"
                >
                  SAVE
                </mgc-raised-button>
            </div>
            <mgc-icon-button *ngIf="initialBlocks.length!=0" style="cursor: pointer;" class="w30" icon="board-mat:close"
                (click)="onCancel()">Close
            </mgc-icon-button>
        </div>

      <div class="modal-alert" [style.opacity]=".9" [style.display]="showNotification ? 'block' : 'none'">
        <alert></alert>
      </div>
    </div>

    <div class="media-body mgc-panel-body mgc-scroll-area">
        <div style="padding: 1px;" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let block of f.blocks.value">
                <ng-container [ngSwitch]="block.Type">
                    <modal-text-block cdkDrag *ngSwitchCase="blockType.TEXT" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </modal-text-block>
                    <modal-image-block cdkDrag *ngSwitchCase="blockType.IMAGE" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </modal-image-block>
                    <modal-video-block cdkDrag *ngSwitchCase="blockType.VIDEO" [block]="block" (move)="onMove(block, $event)"
                        [modal]="modal" (remove)="onRemoveBlock($event)">
                    </modal-video-block>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
