<mgc-dialog-popup>
  <mgc-dialog-header
    [title]="data.modal.Title.replace('STRK#', '' + data.dhUserActivity.streak)"
    [disableClose]="!data.modal.Config.enableClosingModal"
    (close)="closeDialog()"
  ></mgc-dialog-header>

  <mgc-dialog-content>
    <p>{{ data.modal.Description.replace('STRK#', '' + data.dhUserActivity.streak) }}</p>
    <hr>
    <ng-container *ngFor="let block of data.modal.blocks">
      <ng-container [ngSwitch]="block.Type">
        <modal-text-block *ngSwitchCase="blockType.TEXT" preview [block]="block">
        </modal-text-block>
        <modal-image-block *ngSwitchCase="blockType.IMAGE" preview [block]="block">
        </modal-image-block>
        <modal-video-block *ngSwitchCase="blockType.VIDEO" preview [block]="block" [modal]="data.modal">
        </modal-video-block>
      </ng-container>
    </ng-container>
    <div class="text-center mt-4">
      <a href="#" *ngIf="data.modal.Config.enableClosingModal" (click)="closeDialog()">Close this modal</a>
    </div>
  </mgc-dialog-content>
</mgc-dialog-popup>
