import { finalize, take } from 'rxjs/operators';
import { Component, OnInit, Input, Inject, HostListener, ElementRef, ViewChild } from '@angular/core';

import { Project, } from '@board/items/project/project';
import { ProjectService } from '@board/items/project/project.service';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Account } from '@board-accounts/account.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ParticipantService, Participant } from '@panel-components/participants';
import { ITagsData } from '@panel-components/publish/tags.component';
import { PromoSectionComponent } from '@panel-components/publish/promo-section/promo-section.component';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { IPublishSection } from './section.interface';
import { AuthService } from '@rallysite/auth-service';
import { Observable } from 'rxjs';
import { Brand, DEFAULT_BRAND_UNAME } from '@board-brands/brand.model';
import { PUBLIC_PAGES } from '@pages/pages.enum';

const SHOWABLE_OPTIONS = [
  // 'showAuthModalOnEntry',
  // 'showAuthModalOnItemClick',
];

@Component({
  selector: 'publish-settings',
  templateUrl: './publish-settings.component.html',
})
export class PublishSettingsComponent implements IPublishSection, OnInit {
  get SM_class() {
    return this.isSM;
  }

  get hasBrandRights() {
    return ParticipantService.hasLevelOrAboveRights('level-1', this.project._participant);
  }

  constructor(
    private projectService: ProjectService,
    private authService: AuthService,
    @Inject(APP_CONFIG) public config: AppConfig,
    private alertService: AlertService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private participantService: ParticipantService
  ) {}

  get projectPreviewUrl() {
    if (!this.f.IsPublic.value) {
      return 'Must be a public plan in order to have a valid URL.';
    }
    let projectUName = this.project.Id;
    if (this.publishForm.value['UName']) {
      projectUName = this.publishForm.value['UName'];
    }

    const brandUName = this.selectedBrand ? this.selectedBrand.UName : DEFAULT_BRAND_UNAME;
    return `${this.config.protocol}://${this.config.domain}${PUBLIC_PAGES.LIBRARY.path}/${brandUName}/${projectUName}`;
  }

  get storeUrl() {
    return `${this.config.protocol}://${this.config.domain}${PUBLIC_PAGES.LIBRARY.path}`;
  }
  // convenience getter for easy access to form fields
  get f() { return this.publishForm.controls; }
  get isDirty() {
    return this.publishForm.dirty;
  }
  @Input() project: Project;
  @Input() brands: Brand[];

  @ViewChild(PromoSectionComponent) promoSection: PromoSectionComponent;
  @ViewChild('uname') unameInput: ElementRef;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  serviceAlertClass: ServiceAlertClass = ServiceAlertClass.ALERTS;

  private isSM = false;

  noBrand = {
    Id: '__nobrand__',
    Name: 'Public'
  };

  noTag = {
    Id: '__notag__',
    Name: '-- no tag--'
  };

  noSupport = {
    Id: '__nosupport__',
    Name: '-- no support --'
  };

  selectedBrand: Brand;
  selectedBrandId: string;
  notBelongToSelectedBrand: Brand;

  platformTags: Array<any> = [];
  projectTags: Array<any> = [];
  platformTagsData: ITagsData;
  projectTagsData: ITagsData;

  checking = false;
  checkedDone = false;
  editVisibleName = false;

  pubdata: any = {};

  coOwners: Participant[] = [];
  selectedSupport: any;
  selectedSupportId: string;

  /** FORM */
  publishForm: FormGroup;

  isTM$: Observable<boolean>;

  @HostListener('window:resize', ['$event'])
  onResize(event = null) {
    this.isSM = this.el.nativeElement.offsetWidth < 500;
  }
  bootstrapForm() {
    this.checking = false;
    this.checkedDone = false;
    this.editVisibleName = false;

    this.publishForm = this.formBuilder.group(Object.assign({
      UName: this.project.UName,
      AllowJoin: this.project.AllowJoin,
      AllowCopy: this.project.AllowCopy,
      IsPublic: this.project.IsPublic,

      copySupportEnabled: [this.project.Settings.publish.copySupport.enabled],
      copySupportEmail: [this.project.Settings.publish.copySupport.email],
    },
      (() => {
        const o: any = {};
        SHOWABLE_OPTIONS.forEach(prop => {
          o[prop] = this.project.Settings.publish[prop];
        });
        return o;
      })()
    ));
  }
  /** end of FORM */

  makeFormDirty() {
    if (this.publishForm) {
      this.publishForm.markAsDirty();
    }
  }

  markFormPristine() {
    if (this.publishForm) {
      this.publishForm.markAsPristine();
    }
    this.platformTagsData.dirty = false;
    this.projectTagsData.dirty = false;
  }

  submitForm() {
    if (this.publishForm.invalid) {
      return false;
    }

    if (this.project.UName !== this.publishForm.value['UName']) {
      if (this.publishForm.value['UName'] && !this.checkedDone) {
        this.alertService.snackWarning('Please check the project visible name availability!', { horizontalPosition: 'center' });
        return false;
      }
    }

    this.project._pubData._brand = this.pubdata.brand || null;
    return true;
  }

  formValues() {
    return {
      UName: this.publishForm.value['UName'],
      AllowJoin: this.publishForm.value['AllowJoin'],
      AllowCopy: this.publishForm.value['AllowCopy'],
      IsPublic: this.publishForm.value['IsPublic'],
      Settings: {
        publish: Object.assign({
          copySupport: {
            enabled: this.publishForm.value['copySupportEnabled'],
            email: this.publishForm.value['copySupportEmail'],
          },
        },
          (() => {
            const o: any = {};
            SHOWABLE_OPTIONS.forEach(prop => {
              o[prop] = this.publishForm.value[prop];
            });
            return o;
          })())
      }
    };
  }

  requests() {
    const req = [];

    if (this.projectTagsData.dirty) {
      req.push(this.projectService.tagProject(this.project, this.projectTags.map(t => t.Id)));
    }
    if (this.platformTagsData.dirty) {
      req.push(this.projectService.ptagProject(this.project, this.platformTags.map(t => t.Id)));
    }
    return req;
  }


  checkVisibleName() {
    const uname = this.publishForm.value['UName'];
    if (!uname) {
      return;
    }

    this.checkedDone = false;
    this.checking = true;
    this.projectService.checkVisibleNameAvailability(uname, this.selectedBrand, this.project).pipe(
      take(1),
      finalize(() => {
        this.checking = false;
      }))
      .subscribe(validName => {
        if (validName) {
          this.publishForm.patchValue({ UName: validName });
          this.checkedDone = true;
          this.editVisibleName = false;
        }
      });
  }

  startAddUName() {
    if (!this.f.IsPublic.value) {
      return;
    }

    this.editVisibleName = true;
    this.checkedDone = false;
    this.unameInput.nativeElement.focus();
  }

  toggle(data: any, prop: string = null) {

    if (prop) {
      this.publishForm.patchValue({ [prop]: data.value });
    } else {
      this.publishForm.patchValue({ [data.option]: data.value });
      // if (data.value) {
      //   switch (data.option) {
      //     case 'AllowCopy':
      //       this.publishForm.patchValue({ AllowJoin: false });
      //       break;
      //     case 'AllowJoin':
      //       this.publishForm.patchValue({ AllowCopy: false });
      //       break;
      //   }
      // }
    }
    this.makeFormDirty();
  }

  selectBrand(brand: Brand, isInitial: boolean = false) {
    this.selectedBrand = brand;
    this.pubdata.brand = brand;
    this.notBelongToSelectedBrand = null;

    if (!this.selectedBrand) {
      this.selectedBrandId = this.noBrand.Id;
      this.project.BrandId = null;
    } else {
      this.project.BrandId = this.selectedBrand.Id;
      this.selectedBrandId = brand.Id;

      if (!this.brands.find(b => b.Id === brand.Id)) {
        this.notBelongToSelectedBrand = brand;
      }
    }

    if (!isInitial) {
      this.makeFormDirty();
    }
  }

  selectSupport(support: any, isInitial: boolean = false) {
    this.selectedSupport = support;

    if (!this.selectedSupport) {
      this.selectedSupportId = this.noSupport.Id;
      this.publishForm.patchValue({ copySupportEmail: null });
    } else {
      this.selectedSupportId = support.Id;
      this.publishForm.patchValue({ copySupportEmail: this.selectedSupport.Email });
    }

    if (!isInitial) {
      this.makeFormDirty();
    }
  }

  /**
   * TAGS
   */
  platformTagsChanged(tags) {
    this.platformTags = tags;
    this.platformTagsData.dirty = true;
    this.makeFormDirty();
  }

  projectTagsChanged(tags) {
    this.projectTags = tags;
    this.projectTagsData.dirty = true;
    this.makeFormDirty();
  }
  /** */

  toggleOption(data: any) {
    this.publishForm.patchValue({ [data.option]: data.value });
    this.makeFormDirty();
  }
  ngOnInit() {
    const pubdata = this.project._pubData || {};
    this.pubdata.brand || (this.pubdata.brand = pubdata._brand ? new Brand(pubdata._brand) : null);
    this.pubdata.account || (this.pubdata.account = pubdata._account ? new Account(pubdata._account) : null);

    this.platformTagsData = {
      label: 'Platform TAG',
      tags: pubdata['_p_tags'] ? pubdata['_p_tags'] : [],
      selectedTags: pubdata['_p_selectedTags'] ? pubdata['_p_selectedTags'] : [],
      dirty: false
    };

    this.projectTagsData = {
      label: 'Plan TAG',
      tags: pubdata['_tags'] ? pubdata['_tags'] : [],
      selectedTags: pubdata['_selectedTags'] ? pubdata['_selectedTags'] : [],
      dirty: false
    };

    this.bootstrapForm();

    this.selectBrand(this.pubdata.brand, true);

    this.onResize();

    this.participantService.loadCoOwners(this.project).pipe(take(1))
      .subscribe(data => {
        this.coOwners = data;
        this.selectSupport(
          this.coOwners.find(p => p.Email.toLowerCase() === (this.project.Settings.publish.copySupport.email || '').toLowerCase()),
          true);
      });

      this.isTM$ = this.authService.isAdminUser$();
  }
}
