<ng-container *ngIf="preview">
    <gallery *ngIf="images.length" [data]="images" [withDetails]="true" [downloadable]="!!user"></gallery>

    <div class="file-wrapper" *ngFor="let file of files; trackBy: trackByFn">
        <div class="file" [attr.title]="file.FileName">

            <attachment-overlay [item]="file" [progressOnly]="true"></attachment-overlay>
            <div class="file-type {{file.type.class}}">{{ file.type.ext }}</div>
            <div class="file-info">
                <span class="file-name"> {{ file.FileName }} </span>
                <div class="file-size">{{ file.Size | size }}</div>
            </div>
            <div class="actions" *ngIf="user && file.allowActions">
                <div *ngIf="!file.inProgress && file.viewable"  [attr.title]="'Preview ' + file.FileName">
                    <mat-icon class="cp-icon" svgIcon="board-mat:eye" (click)="previewFile($event, file)"></mat-icon>
                </div>
                <div *ngIf="!file.inProgress" [attr.title]="'Download ' + file.FileName">
                    <mat-icon class="cp-icon" svgIcon="board-mat:download" (click)="download($event, file)"></mat-icon>
                </div>
                <mgc-loader *ngIf="file.inProgress"></mgc-loader>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!preview">

    <organizer-media-grid *ngIf="fImages.length" class="scroll-area" cdkDropList (cdkDropListDropped)="drop($event)" runInsideAngular="true">
        <ng-container *ngFor="let image of fImages; let j = index; trackBy: trackByFn" cdkDrag>
            <grid-item [selected]="selected[j]" class="sortable-item drag-handle">

                <attachment-overlay [item]="image"></attachment-overlay>

                <div class="figcaption">{{ j + 1 }}</div>
                <div class="actions">
                    <mat-icon *ngIf="!image.removed" class="cp-icon" svgIcon="board-mat:rotate_left" (click)="rotateLeft($event, image)"></mat-icon>
                    <span class="fill-space"></span>
                    <mat-icon class="cp-icon" svgIcon="board-mat:{{image.removed ? 'restore' : 'delete'}}" (click)="toggleDelete($event, image)"></mat-icon>
                </div>
                <div class="covered-bg" [ngStyle]="imageStyle(image)"></div>
            </grid-item>
        </ng-container>
    </organizer-media-grid>

    <div class="file-wrapper" *ngFor="let file of files; trackBy: trackByFn">
        <div class="file" [attr.title]="file.FileName">
            <attachment-overlay [item]="file"></attachment-overlay>

            <div class="file-type {{file.type.class}}">{{ file.type.ext }}</div>
            <div class="file-info">
                <span class="file-name"> {{ file.FileName }} </span>
                <div class="file-size">{{ file.Size | size }}</div>
            </div>
            <div class="actions" (click)="toggleDelete($event, file)" *ngIf="user && file.allowActions">
                <mat-icon class="cp-icon" svgIcon="board-mat:{{file.removed ? 'restore' : 'delete'}}"></mat-icon>
            </div>
        </div>
    </div>

</ng-container>
