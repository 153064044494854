import { AlgoTagType } from "./algotag/algotag.types"

export interface IAlgoTagPanel {
    category: 'custom' | AlgoTagType,
    label: string,
    expanded: boolean
}

export const algoPanels: IAlgoTagPanel[] = [{
    category: 'custom',
    label: 'Custom Admin Tags',
    expanded: true
}, {
    category: AlgoTagType.THEME,
    label: 'Theme Tags',
    expanded: true
}, {
    category: AlgoTagType.HEAD,
    label: 'Head Algorithm',
    expanded: false
}, {
    category: AlgoTagType.GROWTH,
    label: 'Spiritual Growth Dimensions',
    expanded: false
}, {
    category: AlgoTagType.NEED,
    label: 'Felt Needs',
    expanded: false
}, {
    category: AlgoTagType.GENDER,
    label: 'Gender Labels',
    expanded: false
}, {
    category: AlgoTagType.AGE,
    label: 'Age Labels',
    expanded: false
}, {
    category: AlgoTagType.PLEVEL,
    label: 'Growth Labels / P levels',
    expanded: false
}]