import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { BoardViewSettingsService } from './boardview-settings.service';
import { BOARDVIEW_OVERLAY_DATA } from './boardview-settings.tokens';
import { BoardViewSettingsOverlayRef } from './boardview-settings-overlay-ref';
import { finalize, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ITheme, Theme, ICustomImageOptions, IThemeOptions, ICustomColorOptions } from '@libraries/theming';
import { ThemingService } from '@libraries/theming/theming.service';
import { IBoardViewSettingsData } from './boardview-settings.data';
import { IAccountSettings } from '@board-accounts/account.settings.class';
import { IMultiGalleryOptions } from '@libraries/multi-gallery';
import { ICustomImageData } from '@libraries/theming/themes/image-theme/modal/custom-image-data.interface';
import { CustomColorModal } from '@libraries/theming/themes/custom-color-theme/modal/custom-color.modal';
import { CustomImageModal } from '@libraries/theming/themes/image-theme/modal/custom-image.modal';

@Component({
  selector: 'mgc-boardview-settings',
  templateUrl: './boardview-settings.component.html',
  styleUrls: ['./boardview-settings.component.scss'],
})
export class BoardViewSettingsComponent implements OnInit {

  @HostBinding('class.mgc-boardview-settings') boardViewSettingsClass = true;

  processing: boolean = false;
  iThemes: ITheme[];
  predefined_iThemes: ITheme[];
  custom_iThemes: ITheme[];

  selectedThemeUID: string;
  get activeTheme(): Theme {
    return this.themingService.getActiveTheme();
  }

  themeOptions: IThemeOptions;
  accountSettings: IAccountSettings;
  galleryOptions: IMultiGalleryOptions;

  constructor(
    private boardViewSettingsService: BoardViewSettingsService,
    private boardViewSetOverRef: BoardViewSettingsOverlayRef,
    private dialog: MatDialog,
    private themingService: ThemingService,
    @Inject(BOARDVIEW_OVERLAY_DATA) data: IBoardViewSettingsData,
  ) {
    this.accountSettings = data.accountSettings;
    this.accountSettings.theme || (this.accountSettings.theme = {});
    this.galleryOptions = data.galleryOptions;

    this.themeOptions = Object.assign({}, this.accountSettings.theme);
  }

  radioOptionId(data) {
    return !data.type || data.id === 'custom_image' || data.id === 'custom_color' ? data.id : data.id + '|' + data.type;
  }

  onThemeChange(ev) {
    this.selectedThemeUID = ev.value;
    const parts = this.selectedThemeUID.split('|');
    const selectedThemeObj = { id: <string>parts[0], type: <'light' | 'dark'>parts[1] || null };

    this.themeOptions = {
      ...this.themeOptions, ...{
        themeId: selectedThemeObj.id,
        type: selectedThemeObj.type,
      }
    }
    this.themingService.setTheme(this.themeOptions);

    if (this.themeOptions.themeId === 'custom_image' || this.themeOptions.themeId === 'custom_color') {
      this.openModal(this.themeOptions.themeId);
    }
  }
  // applyChanges: boolean = false;
  openModal(themeId: string) {
    // this.applyChanges = false;
    let dialogRef;
    switch (themeId) {
      case 'custom_image':
        dialogRef = this.dialog.open(CustomImageModal, {
          data: <ICustomImageData>{
            customImageOptions: this.themeOptions.custom_image,
            galleryOptions: this.galleryOptions
          }
        })
        break;
      case 'custom_color':
        dialogRef = this.dialog.open(CustomColorModal, {
          data: {
            customColorOptions: <ICustomColorOptions>this.themeOptions.custom_color
          }
        })
        break;
    }

    if (dialogRef) {
      dialogRef.afterClosed().pipe(
        take(1))
        .subscribe((options: ICustomImageOptions | ICustomColorOptions) => this.onClose(themeId, options));
    }
  }

  private onSave() {
    // if (!this.applyChanges) {
    //   return;
    // }

    this.processing = true;
    this.boardViewSettingsService.update({ theme: this.themeOptions }).pipe(
      finalize(() => this.processing = false)).subscribe();
  }

  private onClose(themeId, options: ICustomImageOptions | ICustomColorOptions) {
    // if (!this.applyChanges) {
    //   return;
    // }

    if (options) {
      this.themeOptions = {
        ...this.themeOptions, ...{
          themeId: themeId,
          [themeId]: options
        }
      }
    }
    this.themingService.setTheme(this.themeOptions);
  }

  done() {
    // this.applyChanges = true;
    this.boardViewSetOverRef.close();
  }

  ngOnInit() {
    // load available themes
    this.iThemes = this.themingService.getRegisteredThemes();
    this.predefined_iThemes = this.iThemes.filter(iTh => iTh.id !== 'custom_image' && iTh.id !== 'custom_color');
    // this.custom_iThemes = this.iThemes.filter(iTh => iTh.id === 'custom_image' || iTh.id === 'custom_color');
    this.custom_iThemes = this.iThemes.filter(iTh => iTh.id === 'custom_image');

    this.selectedThemeUID = this.radioOptionId(this.activeTheme);

    this.boardViewSetOverRef.beforeClose().pipe(
      take(1))
      .subscribe(() => {
        this.onSave()
      })
  }

}
