import { NgModule, ModuleWithProviders, Optional, SkipSelf, InjectionToken } from '@angular/core';

import { SocketIoService } from './socket-io.service';
import { SOCKET_CONFIG_TOKEN, SocketIoConfig } from './socket-io-config.module';
import { TOKEN_HELPER, NoTokenHelper } from './socket-io.token.helper';

@NgModule({})
export class SocketIoModule {
    constructor(@Optional() @SkipSelf() parentModule: SocketIoModule) {
        if (parentModule) {
            throw new Error('AuthServiceModule is already loaded. It should only be imported in your application\'s main module.');
        }
    }

    static forRoot(config: SocketIoConfig): ModuleWithProviders<SocketIoModule> {
        return {
            ngModule: SocketIoModule,
            providers: [
                SocketIoService,
                { provide: SOCKET_CONFIG_TOKEN, useValue: config },
                {
                    provide: TOKEN_HELPER,
                    useClass: config.tokenHelperService || NoTokenHelper
                }
            ]
        };
    }
}
