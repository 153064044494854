
import { Injectable } from '@angular/core';
import { IUploaderOptions } from './uploader.options.model';
import { FileUploader } from './file-upload';

@Injectable()
export class UploaderService {

    static readonly STATES: {
        IDLE: number,
        PROCESSING: number,
        ERROR: number,
        DONE: number
    } = {
            IDLE: -1,
            PROCESSING: 2,
            ERROR: 3,
            DONE: 4
        }

    private _fp: FileUploader;

    constructor() { }

    initUploader(options: IUploaderOptions) {

        this._fp = new FileUploader(this.parseOptions(options));
        return this._fp;
    }

    parseOptions(options: IUploaderOptions) {
        return {
            authToken: 'Bearer ' + options.tokenGetter(),
            url: options.endPoint,
            headers: [
                { name: 'X-Requested-With', value: 'XMLHttpRequest' },
            ],
            disableMultipart: true,
            autoUpload: options.autoUpload,
            formatDataFunction: function (item) {
                let fData = new FormData();
                fData.append(options.filename, item._file, item.file.name);
                if (options.payload) {
                    for (let param in options.payload) {
                        fData.append(`data[${param}]`, options.payload[param]);
                    }
                }
                if (item.formData.length) {
                    for (let i in item.formData) {
                        let param = item.formData[i];
                        fData.append(`data[${param.name}]`, param.value);
                    }
                }
                return fData;
            }
        }
    }


    get uploader() {
        if (!this._fp) {
            throw new Error('The uploader must be initialized');
        }

        return this._fp;
    }
}