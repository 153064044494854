import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from "@rallysite/config";
import { BehaviorSubject } from 'rxjs';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class SeoService {

  title$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc
  ) {
    if (config.google_site_verification) {
      this.meta.addTag({ name: 'google-site-verification', content: config.google_site_verification });
    }
  }

  updateTitle(value: string) {
    this.title$.next(value);

    this.title.setTitle(value);
    this.meta.updateTag({ property: 'og:title', content: value });
    this.meta.updateTag({ name: 'twitter:title', content: value });
  }

  updateDescription(value: string) {
    this.meta.updateTag({ name: 'description', content: value });
    this.meta.updateTag({ property: 'og:description', content: value });
    this.meta.updateTag({ name: 'twitter:description', content: value });
  }

  updateUrl(value: string) {
    this.meta.updateTag({ property: 'og:url', content: value });
  }

  updateCanonical(value: string) {
    let link = document.querySelector('link[rel=canonical]');

    if (!link) {
      link = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
    }
    link.setAttribute('href', value);
  }

  updateSchemaOrg(schema: object) {
    let script = document.querySelector('script[type="application/ld+json"]');

    if (!script) {
      script = this.doc.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      this.doc.head.appendChild(script);
    }
    script.textContent = JSON.stringify(schema);
  }

  updateType(value: string) {
    this.meta.updateTag({ property: 'og:type', content: value });
  }

  updateImages(urls: string[], alt: string) {
    urls || (urls = [])
    if (urls.length === 0) {
      return;
    }

    let first = urls.shift();
    this.meta.updateTag({ property: "og:image", content: first });
    this.meta.updateTag({ property: "og:image:alt", content: alt });

    this.meta.updateTag({ name: "twitter:image", content: first });
    this.meta.updateTag({ name: "twitter:image:alt", content: alt });
  }

  updateKeywords(value: string | string[]) {
    if (typeof (value) === 'string') {
      const kstr = value
        .replace(/^\s+|\s+$/g, "")
        .replace(/[^a-zA-Z0-9 -]/g, "")
        .replace(/\s+/g, " ");
      const kw = kstr.split(" ").filter(k => k.split("").length > 3);
      value = kw.concat([kstr]);
    }

    this.meta.updateTag({ property: 'keywords', content: value.join(', ') });
  }

  updateMeta(tags: MetaDefinition[]) {
    tags || (tags = [])

    tags.forEach(tag => {
      this.meta.addTag(tag);
    })
  }

}
