import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IUploaderOptions, UploaderComponent } from '@libraries/uploader';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Subscription } from 'rxjs';
import { TaskV2Image } from './task-v2-image';

interface IProp {
  id: string;
  name: string;
  label: string;
  placeholder: string;
}

/** the image ratio */
const DEFAULT_IMAGE_RATIO: number = 7 / 2;
// const DEFAULT_IMAGE_RATIO: number = 2.742857142857143;

@Component({
  selector: 'task-v2-image',
  templateUrl: './task-v2-image.component.html',
  styleUrl: './task-v2-image.component.scss',
  styles: [`
      :host{ width: 100%; }
    `]
})
export class TaskV2ImageComponent implements OnInit, OnDestroy {
  @HostBinding('class.preview') get previewClass() {
    return this.preview;
  }
  get actionButtons() {
    return this._actionButtons !== false;
  }
  get preview() {
    return this._preview !== false;
  }

  get imageRatio(): number {
    return 100 / (this.image.Ratio || DEFAULT_IMAGE_RATIO);
  }

  constructor(
    private authService: AuthService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private formBuilder: FormBuilder,
  ) { }
  get f() { return this.form.controls; }

  get imageUploading(): boolean {
    return this._imageUploading;
  }
  set imageUploading(value: boolean) {
    this._imageUploading = value;
  }

  get haveImage() {
    return this.imageStyle.hasOwnProperty('background-image') || this.previewImageFile;
  }

  @Input() image: TaskV2Image;
  @Input() layout: 'v1' | 'v2' = 'v2';
  @Input('actionButtons') _actionButtons = false;
  @Input('preview') _preview = false;
  @ViewChild(UploaderComponent) uploader: UploaderComponent;

  uploaderOptions: IUploaderOptions;
  defaultBgStyle: any = {
    'background-color': '#f1f1f1',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-size': 'cover',
    'transition': 'margin .3s, background-image .4s linear'
  };
  imageBlobStyle: any;
  imageStyle: any;

  props: IProp[] = [{
    id: 'Link', name: 'Link', label: 'Image Click Through Link', placeholder: 'https://'
  }, {
    id: 'AltText', name: 'Alt', label: 'Image Alt text', placeholder: 'Add a short image description here.'
  }];
  activeProp: IProp;


  /** FORM */
  formSubscriptions: Subscription[] = [];
  form: FormGroup;

  private _imageUploading = false;

  previewImageFile: any;
  imageError = false;
  bootstrapForm() {
    this.form = this.formBuilder.group({
      Link: [this.image.Link || ''],
      AltText: [this.image.AltText || ''],
    });

    this.formSubscriptions.push(
      this.form.get('Link').valueChanges.subscribe(
        (value: string) => this.image.Link = value
      ),
      this.form.get('AltText').valueChanges.subscribe(
        (value: string) => this.image.AltText = value
      )
    );
  }
  /** end of FORM */

  activateProp(prop: IProp) {
    this.activeProp = prop;
  }

  click() {
    if (this.imageUploading) {
      return;
    }
    this.uploader.click();
  }  onBeforeUpload(item: any) {
    this.imageError = false;
    this.imageUploading = true;
    this.previewImageFile = item.file;

    this.onUpdate(this.form.getRawValue());
    this.imageBlobStyle = this.getImageBlobStyle();
  }
  onSuccess(data: { item: any; response: any }) {
    this.imageUploading = false;
    this.onUpdate(data.response.file);
  }
  onError(data: { item: any; response: any }) {
    this.imageUploading = false;
    this.imageError = true;
  }

  onUpdate(data: any) {
    this.image.update(data);
  }

  onPropUpdate(prop: IProp) {
    // v1 layout
    this.activeProp = null;
  }

  private getImageStyle() {
    if (!this.image.SystemName) {
      return this.defaultBgStyle;
    }

    const parts = this.image.SystemName.split('.');

    const imageUrl = `${this.config.cdnImages}/_tasks_v2/${this.image.TaskV2Id}/${parts[0] + '_large'}`;
    return Object.assign(this.defaultBgStyle, {
      'background-image': `url(${imageUrl})`
    });
  }

  private getImageBlobStyle() {
    if (!this.previewImageFile) {
      return this.defaultBgStyle;
    }

    const imageUrl = URL.createObjectURL(this.previewImageFile.rawFile);
    return Object.assign(this.defaultBgStyle, {
      'background-image': `url(${imageUrl})`
    });
  }

  ngOnInit() {
    this.bootstrapForm();
    this.imageStyle = this.getImageStyle();

    this.uploaderOptions = {
      autoUpload: true,
      filename: 'block-image',
      tokenGetter: this.authService.tokenGetter,
      endPoint: `${this.config.endpoint}/api/task-blocks/images`,
      payload: {
        TaskV2Id: this.image.TaskV2Id,
        BlockId: this.image.BlockId,
        AccountId: this.image.AccountId
      },
    };
  }

  ngOnDestroy() {
    for (let i = 0; i < this.formSubscriptions.length; i++) {
      this.formSubscriptions[i].unsubscribe();
    }
  }

}
