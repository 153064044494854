import {Component, Input, HostBinding, Output, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'mgc-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class MgcDialogHeaderComponent {

  @Input() title = '';
  @Input() disableClose = false;

  @HostBinding('class.mgc-dialog-header') setClass = true;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialogRef: MatDialogRef<any>,
  ) {
    if (!this.disableClose) {
      this.dialogRef.keydownEvents().subscribe(event => {
        if (event.key === 'Escape') {
          this.onClose();
        }
      });
    }
  }

  onClose() {
    this.close.next(true);
  }
}
