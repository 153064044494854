import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of as observableOf, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError, finalize, filter, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';

import { IDoNotShowAgainOption } from './do-not-show-again/do-not-show-again.options.class';
import { DoNotShowAgainModal } from './do-not-show-again/do-not-show-again-modal';
import { DoNotShowAgainOptionItem } from './do-not-show-again/dnsa-option-components/dnsa-option-item';
import { User } from './user.model';
import { BaseService } from '@board/_services/base-service.service';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private userSubject$: BehaviorSubject<User>;
  private _isUserLoading: boolean = false;
  private _endPoint: string;

  admin: {} = {};
  _allowScheduledEmails: {} = {};

  userUpdated$: Subject<any> = new Subject<any>();

  static instance?: UserService;
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super();
    if (UserService.instance !== undefined && isPlatformBrowser(platformId)) {
      throw new Error('UserService must be instantiated only once.');
    }
    UserService.instance = this;
    this._endPoint = `${this.config.endpoint}/api/user`;
    this.userSubject$ = <BehaviorSubject<User>>new BehaviorSubject<User>(null);
  }

  get user(): User {
    return this.userSubject$.getValue();
  }

  get user$(): Observable<User> {
    return this.userSubject$.asObservable();
  }

  private userCached() {
    return !!this.user;
  }

  private userSubject() {
    return this.user$.pipe(
      filter(user => user != null),
      take(1),
      map(user => {
        return user instanceof User ? user : null;
      }));
  }

  loadUser(): Observable<User> {
    if (this._isUserLoading || this.userCached()) {
      return this.userSubject();
    }

    this._isUserLoading = true;
    return this.httpClient.get(`${this._endPoint}`).pipe(
      finalize(() => this._isUserLoading = false),
      map((data: User) => {
        const user: User = new User(data)
        this.userSubject$.next(user);
        return user;
      }),
      catchError((error) => {
        console.log(['Could not load user', error]);
        this.userSubject$.next(<User>{});
        return observableOf(null);
      })
    )
  }

  isAdmin(): Observable<boolean> {
    if (!this.user) return observableOf(false);

    if (typeof (this.admin[this.user.Id]) == 'boolean') {
      return observableOf(this.admin[this.user.Id]);
    }

    return this.httpClient.get(`${this.config.endpoint}/api/admin`, { observe: 'response' }).pipe(
      map(resp => {
        this.admin[this.user.Id] = resp.status === 202;
        return this.admin[this.user.Id]
      }),
      catchError((error) => {
        this.admin[this.user.Id] = false;
        return observableOf(this.admin[this.user.Id]);
      }));
  }

  allowScheduledEmails(): Observable<boolean> {
    if (!this.user) return observableOf(false);

    if (typeof (this._allowScheduledEmails[this.user.Id]) == 'boolean') {
      return observableOf(this._allowScheduledEmails[this.user.Id]);
    }

    return this.httpClient.get(`${this.config.endpoint}/api/schema`, { observe: 'response' }).pipe(
      map(resp => {
        this._allowScheduledEmails[this.user.Id] = resp.status === 202;
        return this._allowScheduledEmails[this.user.Id]
      }),
      catchError((error) => {
        this._allowScheduledEmails[this.user.Id] = false;
        return observableOf(this._allowScheduledEmails[this.user.Id]);
      }),
      finalize(() => { }))
  }

  canAccessEmailsDashboard(): Observable<boolean> {
    // the same with allowScheduledEmails()
    return this.allowScheduledEmails();
    // return this.httpClient.get(`${this.config.endpoint}/api/schema`, { observe: 'response' }).pipe(
    //   map(resp => {
    //     return resp.status === 202;
    //   }),
    //   catchError((error) => {
    //     return observableOf(false);
    //   }),
    //   finalize(() => { }))
  }

  update(user: User, password: { oPassword: string, password: string, cPassword: string } = null) {
    let payload: any = user.toDb();
    if (password) {
      payload.opassword = password.oPassword;
      payload.password = password.password
      payload.password_confirmation = password.cPassword
    }
    return this.httpClient.put(`${this._endPoint}/${user.Id}`, payload).pipe(
      map(data => {
        user.UpdateDate = data['UpdateDate'];
        this.user.handleSettings(data['Settings']);
        this.userUpdated$.next('updated');
        return this.user;
      }), catchError(error => {
        console.log('Could not update user.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.USER);
        return observableOf(null);
      }));
  }

  updatePhone(user: User) {
    const { Phone, CarrierId } = user;

    return this.httpClient.put(`${this._endPoint}/${user.Id}/phone`, { Phone, CarrierId }).pipe(
      map(data => {
        user.UpdateDate = data['UpdateDate'];
        this.userUpdated$.next('updated');
        return data;
      }), catchError(error => {
        console.log('Could not update user.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.USER);
        return observableOf(null);
      }));
  }

  dnsaOption(dnsaOption: IDoNotShowAgainOption) {
    // this._processingState.next(UserService.STATES.PROCESSING);
    this.httpClient.put(`${this._endPoint}/${this.user.Id}/dnsa`, dnsaOption)
      .subscribe(data => {
        this.user.checkDnsaOption(dnsaOption);
        this.user.UpdateDate = data['UpdateDate'];
        this.userUpdated$.next('updated');
        // this._processingState.next(UserService.STATES.DONE);
      }, error => {
        console.log('Could not update dsan option.');
        // if (error.status === 401) {
        //   this._processingState.next(UserService.STATES.UNAUTHORIZED);
        // } else {
        //   this._processingState.next(UserService.STATES.ERROR);
        // }

        // this.alertService.error(error, ServiceAlertClass.ALERTS.USER);
      });
  }


  showDnsa(infoComponent: any, dnsaOption: IDoNotShowAgainOption, dialog: MatDialog): MatDialogRef<any> {
    return dialog.open(DoNotShowAgainModal, {
      maxWidth: '400px',
      minWidth: '300px',
      data: new DoNotShowAgainOptionItem(infoComponent, dnsaOption)
    });
  }

  removeUser() {
    this.userSubject$.next(null);
  }

}
