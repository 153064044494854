<mgc-list-item>
    <mgc-icon-button icon="board-mat:back_arrow" (click)="goBack()"> </mgc-icon-button>
    <project-avatar [name]="project.Name" [imageId]="project.avatar"
        [t]="project.avatarTimestamp"></project-avatar>
    <div class="content">
        <span class="name">{{ project.__val('Name') }}</span>
    </div>
    <div class="suffix">
        <!-- <mat-chip-list class="mgc-chip-list">
            <mat-chip color="accent" *ngIf="project._notifications > 0">{{project._notifications}}</mat-chip>
        </mat-chip-list> -->
        <mat-icon *ngIf="project._notifications > 0" class="notifications-dot" svgIcon="board-mat:dot">
        </mat-icon>
        <mgc-icon-button
          icon="board-mat:settings"
          [matMenuTriggerFor]="mgcMenu.menu">
        </mgc-icon-button>
    </div>
</mgc-list-item>

<mgc-divider></mgc-divider>

<div class="sub-item">
    <ul class="items-tabs" *ngIf="allowTabs">
        <ng-container *ngFor="let tab of tabs">
            <li [class.selected]="isTabSelected(tab)" (click)="setTab(tab)"><span>{{ tab.name }}</span></li>
        </ng-container>
    </ul>
    <span class="i-spacer"></span>
    <a *ngIf="publicUrl" [routerLink]="publicUrl" target="_blank" class="page-link">
        <mat-icon svgIcon="board-mat:open_in_new"></mat-icon>
        <span> Description page</span>
    </a>
</div>

<mgc-menu #mgcMenu>
    <mgc-menu-item
      class='menu-icon'
      *ngFor="let panel of sidePanels"
      (click)="openSettings(panel)"
      [icon]="'board-mat:'+panel.icon"
    >
      <span class='menu-title'>{{panel.name}}</span>
    </mgc-menu-item>
</mgc-menu>
