import { Component, HostBinding } from "@angular/core";

@Component({
    selector: 'mgc-nav-list',
    template: '<ng-content></ng-content>',
    styleUrls: ['./nav-list.scss']
})
export class MgcNavListComponent {
    @HostBinding('class.mgc-nav-list') setClass: boolean = true;
}
