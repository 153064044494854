import { OverlayRef } from "@angular/cdk/overlay";
import { Subject, Observable } from "rxjs";
import { ISurveyOverlayRefResponseData } from "./survey-overlay-interfaces";

export class SurveyOverlayRef {

  private _beforeClose = new Subject<ISurveyOverlayRefResponseData>();
  private _afterClosed = new Subject<ISurveyOverlayRefResponseData>();

  constructor(
    private overlayRef: OverlayRef) {
  }

  close(data: ISurveyOverlayRefResponseData = null): SurveyOverlayRef {
    this._beforeClose.next(data);
    this._beforeClose.complete();
    this.overlayRef.detachBackdrop();

    this.overlayRef.dispose();

    this._afterClosed.next(data)
    this._afterClosed.complete();
    return this;
  }

  afterClosed(): Observable<ISurveyOverlayRefResponseData> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<ISurveyOverlayRefResponseData> {
    return this._beforeClose.asObservable();
  }
}