

export interface IProjectMetadata {

}


export class ProjectMetadata implements IProjectMetadata {

    constructor(data: any = []) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

}