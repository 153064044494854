import { Injectable, Inject } from '@angular/core';
import { BaseService } from '@board/_services';
import { of as observableOf, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { map, catchError } from 'rxjs/operators';
import { IUIBlock } from './ui-block.interface';
import { SurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { uiBlockFactory } from './ui-block.factory';
import { UIAttachmentsBlock } from './attachments-block/attachments-block';
import {SelectedPLevel} from "@board/surveys/survey-factory/survey-feedbacks/survey-feedback-plevel.model";

export interface IUIBlocksServiceOptions {
  option: SurveyQuestionOption;
  force?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UIBlocksService extends BaseService {

  private _endPoint: string;

  private dataStore: {
    [key: string]: IUIBlock[];
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    super();
    this.dataStore = {};
    this._endPoint = `${this.config.endpoint}/api/option-fdbk-blocks`;
  }

  getPlevelBlocks(plevel: SelectedPLevel) {
    if (this.dataStore[`p-${plevel.PLevel}-${plevel.PgroupId}`]) {
      return observableOf(this.dataStore[`p-${plevel.PLevel}-${plevel.PgroupId}`]);
    }

    return this.http.get(`${this._endPoint}/p-${plevel.PLevel}/${plevel.PgroupId}`).pipe(
      map((blocks: IUIBlock[]) => {
        blocks || (blocks = [])
        this.dataStore[`p-${plevel.PLevel}-${plevel.PgroupId}`] = blocks.map((b: IUIBlock) => uiBlockFactory(b));
        return this.dataStore[`p-${plevel.PLevel}-${plevel.PgroupId}`];
      }), catchError(_error => {
        console.log('Could not load option feedback blocks');
        return observableOf(null);
      })
    )
  }

  getOptionBlocks(option: SurveyQuestionOption) {
    if (this.dataStore[option.Id]) {
      return observableOf(this.dataStore[option.Id]);
    }

    return this.http.get(`${this._endPoint}/${option.Id}`).pipe(
      map((blocks: IUIBlock[]) => {
        blocks || (blocks = [])
        this.dataStore[option.Id] = blocks.map((b: IUIBlock) => uiBlockFactory(b));
        return this.dataStore[option.Id];
      }), catchError(_error => {
        console.log('Could not load option feedback blocks');
        return observableOf(null);
      })
    )
  }

  savePlevelBlocks(plevel: SelectedPLevel, blocks: IUIBlock[]) {
    return this.http.put(`${this._endPoint}/p-${plevel.PLevel}/${plevel.PgroupId}`, {blocks}).pipe(
      map((blocks: IUIBlock[]) => {
        blocks || (blocks = [])
        this.dataStore[`p-${plevel.PLevel}`] = blocks.map((b: IUIBlock) => uiBlockFactory(b));
        return this.dataStore[`p-${plevel.PLevel}`];
      }), catchError(_error => {
        console.log('Could not save task v2 blocks');
        return observableOf(null);
      })
    )
  }

  saveOptionBlocks(option: SurveyQuestionOption, blocks: IUIBlock[]) {
    return this.http.put(`${this._endPoint}/${option.Id}`, {blocks}).pipe(
      map((blocks: IUIBlock[]) => {
        blocks || (blocks = [])
        this.dataStore[option.Id] = blocks.map((b: IUIBlock) => uiBlockFactory(b));
        return this.dataStore[option.Id];
      }), catchError(_error => {
        console.log('Could not save task v2 blocks');
        return observableOf(null);
      })
    )
  }



  /**
   * For attachments block; order Image files
   * @param newOrder
   * @param note
   * @returns
   */
   orderImages(newOrder: Array<string>, block: UIAttachmentsBlock) {
    return this.http.put(`${this._endPoint}/images-order/${block.BlockId}`, {
      // 'taskId': block.TaskId,
      'order': newOrder
    }).pipe(
      map(data => {
        block.Content.imageOrder = data['imageOrder'];
        return block;
      }),
      catchError(error => {
        console.log('Could not update Block Content. Image Order: ' + error.status);
        return observableOf(false);
      })
    )
  }

  remove(block: UIAttachmentsBlock) {
    // blocks are remove on general save button if they
    // are marked as deleted
    return of(null);
  }

}
