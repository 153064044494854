<form [formGroup]="publishForm">

  <mgc-toggle-section label="Display User Action Button ( {{ actionButtonStatus }} )" option="showActionButton"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)"
    [disabled]="!project.AllowJoin && !project.AllowCopy">
    <div class="asefwef">
      <mgc-form-field label="Action button text">
        <div class="field-wrapper">
          <input type="text" formControlName="actionButtonText" name="button-text" autocomplete="button-text"
            placeholder="Action Button Text">
        </div>
      </mgc-form-field>

      <button class="action-button" [style.margin-bottom.px]="15">
        <span [innerHTML]="f.actionButtonText.value"></span>
      </button>
    </div>
  </mgc-toggle-section>

  <!-- <mgc-toggle-section label="Display Public Version Of the Plan" option="showFotameco" hasInfo="true"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>Will make Items, Messages and Comments visible</span>
  </mgc-toggle-section> -->


  <mgc-toggle-section label="Skip the top Task/Item" option="skipTopTask" hasInfo="true" path="Settings.publish"
    [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, will skip the top Task/Item and instead will select 2nd Task/Item down</span>
  </mgc-toggle-section>

  <mgc-toggle-section label="Autoselect Task/Item on Mobile" option="autoselectOnMobile" hasInfo="true"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>For mobile only, when enabled will autoselect task. Otherwise, when disabled, do not auto select and
      show only the Task/Item list.</span>
  </mgc-toggle-section>



  <mgc-toggle-section label="Navigation menu starts as closed  (desktop only)" option="navigationStartsClosed"
    hasInfo="true" path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, on the plan's page the navigation panel will be initialy closed.</span>
  </mgc-toggle-section>

  <mgc-toggle-section label="Allow Users to Open & Close the navigation menu from the left (desktop only)"
    option="navigationToggle" hasInfo="true" path="Settings.publish" [project]="project"
    (change)="toggleOption($event)">
    <span info>When enabled, on the plan's page the users will be able to open and close the navigation panel.</span>
  </mgc-toggle-section>


  <mgc-toggle-section label="Allow Anonymous Comments & Reactions" option="allowAnonymous" hasInfo="true"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>Allow users to post comments and add reactions anonymously.</span>
  </mgc-toggle-section>



  <mgc-toggle-section label="Show authentication modal on page entry or refresh" option="showAuthModalOnEntry"
    hasInfo="true" path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>When user enters a plan or refreshes a plan's page, if option is enabled, the authentication
      modal will come in.</span>

    <mgc-form-field label="The modal will come in after a number of:">
      <div class="field-wrapper input-number">
        <input type="number" formControlName="showAuthModalOnEntryAfter" name="showAuthModalOnEntryAfter"
          autocomplete="showAuthModalOnEntryAfter">
      </div>
      <span>seconds</span>
    </mgc-form-field>

  </mgc-toggle-section>

  <mgc-toggle-section label="Show authentication modal on item click" option="showAuthModalOnItemClick" hasInfo="true"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>When user clicks to view an item content, if option is enabled, the authentication modal will come
      in.</span>
  </mgc-toggle-section>

  <mgc-toggle-section label="Allow any user to see/get embed code" option="allowGetEmbedCode" hasInfo="true"
    path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>When active, just below the plan, users will see the option [Get Embed Code].</span>
  </mgc-toggle-section>


  <mgc-toggle-section label="Enable option to move to the next step / item" option="goToNextStep"
    hasInfo="true" path="Settings.publish" [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, allows user to move to next item inside the plan. If the Url field below is set, then the user will be sent to that URL.</span>

    <mgc-form-field label="Label">
      <div class="field-wrapper">
        <input type="text" formControlName="goToNextStepLabel" name="goToNextStepLabel"
          autocomplete="goToNextStepLabel" placeholder="{{ f.goToNextStepUrl.value ? 'Go To Next Step' : 'Go To Next Item'}} ">
      </div>
    </mgc-form-field>

    <mgc-form-field label="Url">
      <div class="field-wrapper">
        <input type="text" formControlName="goToNextStepUrl" name="goToNextStepUrl"
          autocomplete="goToNextStepUrl" placeholder="Enter next step Url here.">
      </div>
    </mgc-form-field>

  </mgc-toggle-section>

</form>