
import {filter, takeWhile} from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { AlertService } from './alert.service';
import { IServiceAlertClass, ServiceAlertClass } from './services-alert.class';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
    @Input("service") public service: Array<IServiceAlertClass> = [ServiceAlertClass.ALERTS.DEFAULT];
    private alive: boolean = true;

    message: {
        type: string,
        text: string,
        service?: IServiceAlertClass
    };

    constructor(
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.service = [ServiceAlertClass.ALERTS.CLEAR].concat(this.service);
        this.alertService.getMessage().pipe(
            takeWhile(() => this.alive),
            filter((message) => {
                let found: boolean = false;
                if (!!message && message.service){
                   found = !!this.service.find((s) => {
                        return s.id === message.service.id 
                    })
                }
                return found ;
            }) ,)
            .subscribe(message => this.message = message || null);
    }

    ngOnDestroy() {
        this.alive = false;
    }
}