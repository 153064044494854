import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MgcDialogComponent, MgcDialogPopupComponent} from './dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MgcDialogHeaderComponent } from './dialog-header.component';
import { MgcDialogContentComponent } from './dialog-content.component';
import { MgcDialogActionsComponent } from './dialog-actions.component';
import { MgcDividerModule } from '../divider';
import { MgcButtonModule } from '../button';
import { MgcDialogSidenavComponent } from './dialog-sidenav.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,

        MatIconModule,

        MgcButtonModule,
        MgcDividerModule,
        DragDropModule
    ],
    declarations: [
        MgcDialogComponent,
        MgcDialogPopupComponent,
        MgcDialogSidenavComponent,
        MgcDialogHeaderComponent,
        MgcDialogContentComponent,
        MgcDialogActionsComponent
    ],
    exports: [
        MatDialogModule,

        MgcDialogComponent,
        MgcDialogPopupComponent,
        MgcDialogSidenavComponent,
        MgcDialogHeaderComponent,
        MgcDialogContentComponent,
        MgcDialogActionsComponent
    ],

    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: Object.assign(new MatDialogConfig(), <MatDialogConfig>{
                width: '95vw',
                maxWidth: '800px',
                minWidth: '350px',
                disableClose: true,
                hasBackdrop: true,
                backdropClass: 'mgc-dialog-backdrop',
                panelClass: 'mgc-dialog-panel',
                position: {
                    top: '10px'
                },
                data: {}
            })
        }
    ]
})
export class MgcDialogModule {
}
