import { IAccount } from "@board-accounts/account.model";
import { BaseItem } from "@board/items/base-item.model";
import { IPanelComponent, PanelComponents } from "@panel-components";
import { VideoParticipant } from "@panel-components/video-participants/video-participant";
import { BaseVideoCampaignGroup, DefaultVideoCampaignGroup } from "../video-campaign-group/video-campaign.group";

export class VideoCampaignAttributes {
    Id: string = null;
    AccountId: string = null;
    Name: string = null;
    Status: string = null;
    Metadata: object = null;
    _account: IAccount = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class BaseVideoCampaign extends BaseItem {
    readonly _type: string = 'video-campaign';

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.VIDEO_CAMPAIGN_NAME,
        PanelComponents.VIDEO_PARTICIPANTS,
    ];
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.EMPTY;
    }

    sideDefaultPanel(allowFn: Function) {
        // if (allowFn(this.Settings.sideDefaultPanel.id)) {
        //     return this.Settings.sideDefaultPanel;
        // }
        return PanelComponents.VIDEO_CAMPAIGN_NAME;
    }

    prevAttributes: VideoCampaignAttributes = new VideoCampaignAttributes();
    attributes: VideoCampaignAttributes = new VideoCampaignAttributes();

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get Account(): IAccount {
        return this.attributes._account;
    }
    set Account(value: IAccount) {
        this.attributes._account = value;
    }
    get Metadata(): object {
        return this.attributes.Metadata;
    }
    set Metadata(value: object) {
        this.attributes.Metadata = value || {};
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Status(value: string) {
        this.attributes.Status = value;
    }
    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    _groupId: string;
    _groupUId: string;
    _state: string;
    _open: boolean = false;

    _participant: VideoParticipant;
    _resmap: any;
    _permap: any;
    _scrollY: number;

    isStandalone() {
        return this._groupId === DefaultVideoCampaignGroup.ID;
    }

    isNotConfirmed() {
        return this._participant.Confirmation === 'pending';
    }

    modalOptions({ group, edit, panel }: {
        group?: BaseVideoCampaignGroup,
        panel?: IPanelComponent,
        edit?: boolean,
    }) {
        edit = !!edit;
        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Video Campaign',
                campaign: this,
                group: group,
                panel: panel,
                edit: edit,
            }
        }
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            UpdateDate: this.UpdateDate,
            _account: this.Account,
            Metadata: this.Metadata,
        });
    }
}

export class VideoCampaign extends BaseVideoCampaign {

    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        if (!this.Metadata) {
            this.Metadata = null;
        }
    }
}
