export type Serializable<T> = boolean | number | string | null | object | T;

export abstract class TransferStateConverter<T> {
  /**
   * Called by the TransferStateService to convert a serialized value to an object of type T.
   */
  abstract fromTransferState(data: Serializable<T>): T;

  /**
   * Called by the TransferStateService to convert data to a value that is serializable by TransferState.
   */
  abstract toTransferState(data: T): Serializable<T>
}