import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserService } from '@rallysite/user';
import { AlertService } from '@rallysite/components/alert';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from './must-match';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'password-change',
  templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent implements OnInit, OnDestroy {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  hide = true;
  passwordForm: FormGroup;
  submitted = false;

  private alive = true;
  state = -1;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
  }

  onSave(): Observable<any> {
    this.alertService.clear();

    if (!this.submit()) {
      return of(null);
    }

    return this.userService.update(this.userService.user, this.passwordForm.value);
  }

  bootstrapForm() {
    this.passwordForm = this.formBuilder.group({
      oPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      cPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'cPassword')
    });
  }

  // convenience getter for easy access to form fields
  get pf() { return this.passwordForm.controls; }
  submit() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    // this.userService.resetProcessingState$()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe(state => {
    //     this.state = state;
    //     if (this.state === ServiceStates.STATES.DONE) {
    //       this.close.next();
    //     } else if (this.state === ServiceStates.STATES.UNAUTHORIZED) {
    //       this.close.next('unauthorized');
    //     }
    //   });

    this.bootstrapForm();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
