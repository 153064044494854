
import { catchError, switchMap } from 'rxjs/operators';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { ServerBusySnack } from './server-busy.snack';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private auth: AuthService;

  constructor(
    private injector: Injector,
    private snackBar: MatSnackBar
  ) {
    setTimeout(() => {
      this.auth = this.injector.get(AuthService);
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>err).status) {
            case 401:
              return this.handle401Error(request, next);
            case 429:
              this.handle429Error(request);
              break;
            default:
              return observableThrowError(err);
          }
        } else {
          return observableThrowError(err);
        }
      }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // this.auth.collectFailedRequest(req);

    return this.auth.refreshToken().pipe(
      switchMap((newToken: string) => {
        if (newToken) {
          return next.handle(
            req.clone({ setHeaders: { Authorization: 'Bearer ' + newToken } })
          );
        }

        return this.auth.goToLoginPage();
      }))

  }

  private handle429Error(req) {
    let seconds = 25;
    this.snackBar.openFromComponent(ServerBusySnack, {
      // duration: seconds * 1000,
      verticalPosition: 'top',
      data: { time: seconds }
    });
  }
}
