import {
  Component,
  Input,
  Output,
  ViewChild,
  OnInit,
  Type,
  EventEmitter,
  HostBinding,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { PageAuthPlaceId, PageAuthViewType } from './page-auth-view-type.enum';
import { IPageAuthView } from './page-auth-view.interface';
import { PageAuthDirective } from './page-auth.directive';
import { PageCoRegisterComponent } from './page-co-register/page-co-register.component';
import { PageEmailCheckComponent } from './page-email-check/page-email-check.component';
import { PageSignInComponent } from './page-sign-in/page-sign-in.component';
import { PageSignUpComponent } from './page-sign-up/page-sign-up.component';

@Component({
  selector: 'mgc-auth-view',
  templateUrl: './page-auth.component.html',
  styleUrls: ['./page-auth.component.scss']
})
export class PageAuthComponent implements OnInit {
  @HostBinding('class.mgc-auth') authClass = true;

  @Input() email = '';
  @Input() label = '';
  @Input() placeId: PageAuthPlaceId = undefined;
  @Input() view: PageAuthViewType = PageAuthViewType.email;
  @Input() skipsignin = false;

  authViewHost: PageAuthDirective;
  @ViewChild(PageAuthDirective)
  set host(host: PageAuthDirective) {
    this.authViewHost = host;
    this.bootstrap();
  }

  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  instanceRef: IPageAuthView;

  ready = false;
  ready$ = new Subject<boolean>();

  private closeSubscription: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) { }


  loadView(view: PageAuthViewType) {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }

    let component: Type<IPageAuthView>;
    let data: any = {};

    switch (view) {
      case PageAuthViewType.login:
        component = PageSignInComponent;
        data = { email: this.email };
        break;
      case PageAuthViewType.register:
        component = PageSignUpComponent;
        data = { email: this.email, placeId: this.placeId};
        break;
      case PageAuthViewType.co_register:
        component = PageCoRegisterComponent;
        data = { email: this.email };
        break;
      default:
        component = PageEmailCheckComponent;
        data = { label: this.label, skipsignin: this.skipsignin };
        break;
    }

    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(component);
    this.instanceRef = componentRef.instance as IPageAuthView;
    (<IPageAuthView>this.instanceRef).data = data;

    this.closeSubscription = this.instanceRef.close
      .subscribe((s) => {
          switch (s) {
            case PageAuthViewType.login:
              this.loadView(PageAuthViewType.login);
              break;
            case PageAuthViewType.register:
              this.loadView(PageAuthViewType.register);
              break;
          }
        this.close.emit(s);
      });

    componentRef.hostView.detectChanges();
  }

  get data() {
    if (!this.instanceRef) {
      return {};
    }

    return this.instanceRef.data;
  }

  getEmail() {
    return this.instanceRef.getEmail();
  }

  onSubmit() {
    if (!this.instanceRef) {
      return;
    }
    this.instanceRef.onSubmit();
  }

  processing() {
    if (!this.instanceRef) {
      return false;
    }
    return this.instanceRef.processing;
  }

  private bootstrap(email: string = '') {
    if (!this.authViewHost || !this.view) {
      return;
    }
    this.loadView(this.view);
  }

  ngOnInit() {
    this.bootstrap();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['view'] && !changes['view'].firstChange && changes['view'].currentValue) {
      await this.bootstrap(this.view);
    }
  }

}
