import { EMAIL_BLOCKS, EmailBlockType } from "./blocks/block-types";
import { ContentBlock } from "./blocks/content-block/content-block";
import { FooterBlock } from "./blocks/footer-block/footer-block";
import { ImageBlock } from "./blocks/image-block/image-block";
import { IntroBlock } from "./blocks/intro-block/intro-block";
import { RepliesBlock } from "./blocks/replies-block/replies-block";
import { TextBlock } from "./blocks/text-block/text-block";

type EmailBodyBlock = TextBlock | ImageBlock | RepliesBlock;

export class EmailItemBody {
  body: EmailBodyBlock[] = [];

  constructor(data: any) {
    this.body = [];
    for (let k in data) {
      switch ((<EmailBodyBlock>data[k]).type) {
        case EmailBlockType.INTRO:
          this.body.push(new IntroBlock(data[k]));
          break;

        case EmailBlockType.CONTENT:
          this.body.push(new ContentBlock(data[k]));
          break;
        case EmailBlockType.REPLIES:
          this.body.push(new RepliesBlock(data[k]));
          break;

        case EmailBlockType.FOOTER:
          this.body.push(new FooterBlock(data[k]));
          break;

        case EmailBlockType.TEXT:
          this.body.push(new TextBlock(data[k]));
          break;

        case EmailBlockType.IMAGE:
          this.body.push(new ImageBlock(data[k]));
          break;
      }
    }
  }

  toDb() {
    return this.body.map((el) => {
      return el.toDb();
    });
  }

  toJSON() {
    return this.toDb();
  }
}
