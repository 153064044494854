import { animate, style, transition, trigger } from '@angular/animations';

export const ANIMATION_STATE_FORWARD = 'forward';
export const ANIMATION_STATE_BACKWARD = 'backward';

export const ANIMATION_STATE_DOWN = 'down';
export const ANIMATION_STATE_UP = 'up';

export const slideForwardAndBackward = trigger('slideForwardAndBackward', [
  transition(`void => ${ANIMATION_STATE_FORWARD}`, [
    style({
      transform: 'translateX(100%)',
      pointerEvents: 'none'
    }),
    animate(300)
  ]),
  transition(`void => ${ANIMATION_STATE_BACKWARD}`, [
    style({
      transform: 'translateX(-100%)',
      pointerEvents: 'none'
    }),
    animate(300)
  ])
]);

export const slideUpAndDown = trigger('slideUpAndDown', [
  transition(`void => ${ANIMATION_STATE_UP}`, [
    style({
      transform: 'translateY(100%)',
      pointerEvents: 'none'
    }),
    animate(300)
  ]),
  transition(`void => ${ANIMATION_STATE_DOWN}`, [
    style({
      transform: 'translateY(-100%)',
      pointerEvents: 'none'
    }),
    animate(300)
  ])
]);
