<form [formGroup]="publishForm">

  <div class="info-section" [class.SM]="SM_class">

    <div class="mgc-row" *ngIf="!hasBrandRights">
      <div class="mgc-col" [style.max-with.px]="600">

        <mgc-form-field label="Owner" nobg multiline>
          <div class="field-wrapper">
            <span [innerHTML]="pubdata.account ? pubdata.account.Name : '--- no owner ---'"></span>
          </div>
        </mgc-form-field>

        <mgc-form-field label="Brand" nobg multiline>
          <div class="field-wrapper">
            <div class="mgc-brand" *ngIf="pubdata.brand">
              <brand-avatar size="40" [brand]="pubdata.brand"></brand-avatar>
              <span class="info">
                <span class="name" [innerHTML]="pubdata.brand.Name"></span>
                <span class="details">
                  <a [attr.href]="pubdata.brand | brandPageUrl" target="_blank"
                    title="Public Brand Page">{{pubdata.brand | brandPageUrl}}</a>
                </span>
              </span>
            </div>
            <span *ngIf="!pubdata.brand">-- no brand --</span>
          </div>
        </mgc-form-field>

        <mgc-form-field label="Is Public" nobg multiline>
          <div class="field-wrapper">
            <span [innerHTML]="project.IsPublic ? 'Yes' : 'No'"></span>
            <span class="additional" *ngIf="project.IsPublic && pubdata.brand">
              <a [attr.href]="pubdata.brand | brandPageUrl:project" target="_blank"
                title="Public Brand Page">{{pubdata.brand | brandPageUrl:project}}</a>
            </span>
          </div>
        </mgc-form-field>

      </div>


      <div class="mgc-col-sm-6">
        <mgc-form-field label="Allow Join" nobg>
          <div class="field-wrapper">
            <span [innerHTML]="project.AllowJoin ? 'Yes' : 'No'"></span>
          </div>
        </mgc-form-field>

        <mgc-form-field label="Allow Copy" nobg>
          <div class="field-wrapper">
            <span [innerHTML]="project.AllowCopy ? 'Yes' : 'No'"></span>
          </div>
        </mgc-form-field>

        <mgc-form-field label="Messages Visible" nobg>
          <div class="field-wrapper">
            <span [innerHTML]="project.AllowComments ? 'Yes' : 'No'"></span>
          </div>
        </mgc-form-field>
      </div>

    </div>

    <ng-container *ngIf="hasBrandRights">
      <div class="mgc-row" [style.max-with.px]="600">
        <div class="mgc-col">

          <mgc-form-field label="Owner" nobg>
            <div class="field-wrapper">
              <span [innerHTML]="pubdata.account ? pubdata.account.Name : '--- no owner ---'"></span>
            </div>
          </mgc-form-field>

          <mgc-toggle-section label="Make Public" option="IsPublic" hasInfo="true" [project]="project"
            (change)="toggle($event)">
            <span info>Once active, this project will become visible in the <a routerLink="/library" target="_blank"
                title="Project Library">Project Library</a>
            </span>
          </mgc-toggle-section>

          <mgc-form-field label="Choose a brand if available" multiline>
            <div class="field-wrapper brand-field">
              <brand-avatar *ngIf="selectedBrand" [style.margin-right.px]="10" size="30" [brand]="selectedBrand">
              </brand-avatar>
              <mat-select class="mgc-select" [(value)]="selectedBrandId" name="brand">
                <mat-option (click)="selectBrand(null)" [value]="noBrand.Id">{{noBrand.Name}}</mat-option>

                <div *ngIf="notBelongToSelectedBrand">
                  <mat-option [value]="notBelongToSelectedBrand.Id" disabled>
                    <div class="mgc-brand">
                      <brand-avatar size="30" [brand]="notBelongToSelectedBrand"></brand-avatar>
                      <span class="info"><span class="name" [innerHTML]="notBelongToSelectedBrand.Name"></span></span>
                    </div>
                  </mat-option>
                </div>
                <mat-option [value]="brand.Id" (click)="selectBrand(brand)" *ngFor="let brand of brands">
                  <div class="mgc-brand">
                    <brand-avatar size="30" [brand]="brand"></brand-avatar>
                    <span class="info"><span class="name" [innerHTML]="brand.Name"></span></span>
                  </div>
                </mat-option>

              </mat-select>
            </div>
          </mgc-form-field>

          <mgc-form-field label="Project Visible Name in URL" [class.disabled]="!f.IsPublic.value">
            <div class="field-wrapper">
              <input #uname type="text" [readonly]="!f.IsPublic.value || !editVisibleName" formControlName="UName"
                name="VisibleName" autocomplete="visible-name">
              <span class="placeholder" *ngIf="!f.UName.value && !editVisibleName">Add a project visible name here.
                Press EDIT to start ...</span>
            </div>
            <ng-container *ngIf="!checking">
              <span *ngIf="editVisibleName" class="suffix-btn" [class.inactive]="!f.UName.value"
                (click)="checkVisibleName()">Check</span>
              <span *ngIf="!editVisibleName" class="suffix-btn" (click)="startAddUName()">
                <mat-icon *ngIf="checkedDone && !editVisibleName" svgIcon="board-mat:check"></mat-icon>
                <span>Edit</span>
              </span>
            </ng-container>
            <mgc-loader *ngIf="checking"></mgc-loader>
          </mgc-form-field>
          <alert [service]="[serviceAlertClass.PROJECT_CHECK_NAME]" [style.margin-top.px]="-15"
            [style.margin-bottom.px]="20"></alert>
          <mgc-information [style.margin-top.px]="-15" [style.margin-bottom.px]="15">
            <span [innerHTML]="projectPreviewUrl"></span>
          </mgc-information>






          <mgc-toggle-section label="Allow Join" option="AllowJoin" [checked]="f.AllowJoin.value" [project]="project"
            (change)="toggle($event)">
          </mgc-toggle-section>

          <mgc-toggle-section label="Allow Copy" option="AllowCopy" [checked]="f.AllowCopy.value" [project]="project"
            (change)="toggle($event)">
          </mgc-toggle-section>



          <mgc-promo-section [project]="project" (change)="makeFormDirty()"></mgc-promo-section>


          <ng-container *ngIf="isTM$ | async">
            <mgc-public-tags *ngIf="platformTagsData" [data]="platformTagsData" (change)="platformTagsChanged($event)">
            </mgc-public-tags>
            <mgc-public-tags *ngIf="projectTagsData" [data]="projectTagsData" (change)="projectTagsChanged($event)">
            </mgc-public-tags>

            <mgc-toggle-section *ngIf="f.AllowCopy.value" label="Enable support option for copied Plan" option="enabled"
              path="Settings.publish.copySupport" [project]="project" (change)="toggle($event, 'copySupportEnabled')">
              <mgc-information [style.margin-top.px]="-15" [style.margin-bottom.px]="15">
                <span>Give user option to have a chosen persona added to their Plan as a Co-Owner for support</span>
              </mgc-information>

              <mgc-form-field label="Choose a Co-Ownner" multiline>
                <div class="field-wrapper brand-field">
                  <account-avatar *ngIf="selectedSupport" [style.margin-right.px]="10" size="30"
                    [account]="selectedSupport._account">
                  </account-avatar>
                  <mat-select class="mgc-select" [(value)]="selectedSupportId" name="coOwner">
                    <mat-option (click)="selectSupport(null)" [value]="noSupport.Id">{{noSupport.Name}}</mat-option>
                    <mat-option [value]="support.Id" (click)="selectSupport(support)" *ngFor="let support of coOwners">
                      <div class="mgc-brand">
                        <account-avatar size="30" [account]="support._account"></account-avatar>
                        <span class="info" [innerHTML]="support._account.OwnerFirstName"></span>
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
              </mgc-form-field>
            </mgc-toggle-section>
          </ng-container>

        </div>
      </div>

    </ng-container>

  </div>

</form>