import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { AlertService, IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { PageAuthComponent } from '@page-auth';
import { PageAuthPlaceId, PageAuthViewType } from '@page-auth/page-auth-view-type.enum';

@Component({
  selector: 'auth-inline-temp',
  templateUrl: './auth-inline-temp.component.html',
  styleUrls: ['./_auth-inline-temp.scss']
})
export class AuthInlineTempComponent implements OnInit {
  @ViewChild(PageAuthComponent) authView: PageAuthComponent;

  @Input() authAction: PageAuthViewType;
  @Input() email = '';
  @Input() label = '';
  @Input() placeId: PageAuthPlaceId = undefined;

  PageAuthPlaceId = PageAuthPlaceId;

  @Input('skipsignin') _skipsignin = false;
  get skipsignin() {
    return this._skipsignin !== false;
  }
  set skipsignin(value: boolean) {
    this._skipsignin = value;
  }

  @Output() close?: EventEmitter<any> = new EventEmitter<any>();

  serviceAlertClass?: IAlertsClass = ServiceAlertClass.ALERTS;

  _authViewType: PageAuthViewType;
  get authViewType() {
    return PageAuthViewType;
  }

  constructor(
    private alertService: AlertService,
  ) {
  }

  get titleAuth() {
    let title = '';
    switch (this._authViewType) {
      case this.authViewType.login:
        title = 'Login';
        break;
      case this.authViewType.register:
        title = 'Register';
        break;
    }
    return title;
  }

  switchAuth(view: PageAuthViewType) {
    this._authViewType = view;
    return false;
  }

  onSubmit() {
    this.alertService.clear();
    this.authView.onSubmit();
  }

  /**
   * event: { authView?: PageAuthViewType, email?: string }
   * event: { state: "success", account: selected account }
   * event: "follow" | "copy"
   * @param event
   */
  onClose(event: { authView?: PageAuthViewType, email?: string } = null): void {
    if (event && event.email) {
      this.email = event.email;
    }

    if (event && event.authView) {
      this.switchAuth(event.authView);
    } else {
      this.close.emit({
        event: 'cancel',
        email: this.email || this.authView.getEmail()
      });
    }
  }

  get processing() {
    return this.authView?.processing();
  }

  ngOnInit() {
    this._authViewType = <PageAuthViewType>this.authAction || PageAuthViewType.email;
  }

}
