
import { takeWhile, catchError, map, switchMap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { Task, TargetTask } from './task';

import { BaseService } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';

import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { TaskGroupService } from '@board/items/task-group/task-group.service';
import { TaskGroup, DefaultTaskGroup, BaseTaskGroup, ITaskGroup } from '@board/items/task-group/task-group';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Project } from '@board/items/project/project';
import { Notification } from '@board/_components/notification';
import { CounterService } from '@libraries/counter/counter.service';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { TaskV2 } from '@items/task-v2/task-v2.model';
import { isTaskV2 } from '@items/task-v2/task-v2-checker';
import { Account } from '@board-accounts/index';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {
  private _groups: any = {};
  private dataStore: any = {
  };

  private targetTasks: {
    [id: string]: TargetTask
  } = {};

  private _processingState: BehaviorSubject<number>;
  private _uTask: BehaviorSubject<Task | TaskV2>;
  private _endPoint: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private targetService: TargetResourceService,
    private taskGroupService: TaskGroupService,
    private messageBus: MessageBusService,
    private alertService: AlertService,
    private counterService: CounterService,
    private ga: GoogleAnalyticsService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/tasks`;
    this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    this._uTask = <BehaviorSubject<Task>>new BehaviorSubject(null);
  }

  get processingState$(): Observable<number> {
    return this._processingState.asObservable();
  }
  resetProcessingState(): Observable<number> {
    this._processingState.next(-1);
    return this.processingState$;
  }

  private getGId(group: TaskGroup) {
    return group.ProjectId + '_' + group.getId();
  }
  getGId_v2(projectId: string, groupId: string) {
    if (groupId === DefaultTaskGroup.ID) {
      groupId = null;
    }
    return projectId + '_' + groupId;
  }
  initTasks(group: TaskGroup) {
    let id = this.getGId(group);
    this._groups[id] = {
      _tasks: <BehaviorSubject<Task[]>>new BehaviorSubject(null),
      _loadingState: <BehaviorSubject<number>>new BehaviorSubject(-1)
    }
  }
  getTasks$(group: TaskGroup): Observable<Task[]> {
    return this._groups[this.getGId(group)]._tasks.asObservable();
  }
  get task$(): Observable<Task | TaskV2> {
    return this._uTask.asObservable();
  }
  get task(): Task | TaskV2 {
    return this._uTask.getValue();
  }

  get uTask(): BehaviorSubject<Task | TaskV2> {
    return this._uTask;
  }

  loadingState$(group: TaskGroup): Observable<number> {
    return this._groups[this.getGId(group)]._loadingState.asObservable();
  }

  loadAll(group: BaseTaskGroup, project: Project) {
    if (!group) return;

    if (this.inStorage(group)) {
      this.fetch(group, project);
      return;
    }

    let gid = this.getGId(group);
    this._groups[gid]._loadingState.next(TaskService.STATES.LOADING);
    this.dataStore[gid] = { tasks: [] }
    let params = new HttpParams({
      fromObject: {
        'filter[GroupId]': group.getId(),
        'filter[ProjectId]': group.ProjectId
      }
    });
    this.http.get(this._endPoint, { params: params })
      .subscribe(data => {
        for (let key in data) {
          let task = data[key];
          if (task.ProjectId === group.ProjectId && task.GroupId === group.getId()) {
            task.contest = project.contest;
            if (isTaskV2(task)) {
              this.dataStore[gid].tasks.push(new TaskV2(task))
            } else {
              this.dataStore[gid].tasks.push(new Task(task))
            }
          }
        }
        this.fetch(group, project);

        if (this.targetService.targetLevel > 1) {
          this.setCurrentTaskFromTarget(group);
        }
      },
        error => {
          console.log('Could not load tasks');
          delete (this.dataStore[gid]);
          this._groups[gid]._loadingState.next(TaskService.STATES.ERROR);
          this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_LIST);
        }
      )
  }

  /**
   * This method is actioned by QueryRequestService
   * @param id 
   */
  loadTarget(id: string) {
    if (this.targetTasks[id]) {
      return observableOf(this.targetTasks[id]);
    }

    return this.http.get(`${this._endPoint}/${id}/target`).pipe(
      map(data => {
        this.targetTasks[id] = new TargetTask(data);
        return this.targetTasks[id];
      }),
      catchError(error => {
        console.log('Could not load target task');
        return observableOf(null);
      })
    )
  }

  create(task: Task | TaskV2, project: Project, group: BaseTaskGroup, atIndex: number = 0) {
    let payload = task.toDb();
    payload['_index'] = atIndex;
    return this.http.post(this._endPoint, payload).pipe(
      map(data => {

        let newTask = isTaskV2(task) ? new TaskV2(data) : new Task(data);
        this._uTask.next(newTask);

        if (data['__projectMetadata']) {
          project.__update({
            Metadata: data['__projectMetadata'],
            UpdateDate: data['__projectUpdateDate']
          });
        }

        if (group._active) {
          let gid = this.getGId(group);
          if (!this.dataStore[gid]) {
            this.dataStore[gid] = { tasks: [] }
          }

          this.addUI(newTask, gid).fetch(group, project);

        } else {
          this.cleanStorage(group);
        }
        return newTask;
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_CREATE);
        return observableOf(null);
      })
    )

  }

  update(task: Task | TaskV2, project: Project = null, group: BaseTaskGroup = null) {
    if (!project && !group) {
      return this.simpleUpdate(task);
    }

    this.alertService.clear();
    let gid = this.getGId(group);
    let prevGId: string;
    if (task._prevGroupId !== task.GroupId) {
      prevGId = project.Id + '_' + task._prevGroupId;
      task._state = 'moving';
    }

    return this.http.put(`${this._endPoint}/${task.Id}`, task.toDb()).pipe(
      map(data => {
        task.__update(data);

        if (data['__projectMetadata']) {
          project.__update({
            Metadata: data['__projectMetadata'],
            UpdateDate: data['__projectUpdateDate']
          });
        }

        if (prevGId) {
          let fromGroup = this.taskGroupService.findGroup(task._prevGroupId, project.Id);
          this.removeUI(task, prevGId).fetch(fromGroup, project);
          task._state = 'moved';
          // moved 
          task._prevGroupId = task.GroupId;
          task._group = group;
          this.addUI(task, gid).fetch(group, project)
          this._uTask.next(task);

          setTimeout(() => {
            task._state = '';
          }, 100);
        }
        return task;
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_UPDATE);
        return observableOf(null);
      })
    )
  }

  simpleUpdate(task: Task | TaskV2) {
    this.alertService.clear();
    return this.http.put(`${this._endPoint}/${task.Id}`, task.toDb()).pipe(
      map(data => {
        task.__update(data);
        return task;
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_UPDATE);
        return observableOf(null);
      })
    )
  }

  sendPush(task: Task | TaskV2) {
    return this.simpleUpdate(task)
      .pipe(
        switchMap(savedTask => {
          if (!savedTask) {
            null;
          };

          return this.http.get(`${this._endPoint}/${savedTask.Id}/send-push`)
            .pipe(
              map(exitCode => {
                if (exitCode === 0)  {
                  this.alertService.success('Successfuly scheduled.', ServiceAlertClass.ALERTS.TASK_SEND_PUSH);
                  return true;
                }
                this.alertService.error('Failed to schedule', ServiceAlertClass.ALERTS.TASK_SEND_PUSH);
                return false;
              }), catchError(error => {
                this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_SEND_PUSH);
                return observableOf(null);
              })
            )
        })
      )
  }

  remove(task: Task | TaskV2, group: ITaskGroup, project: Project) {
    task._state = 'remove';
    return this.http.delete(`${this._endPoint}/${task.Id}`).pipe(
      map(data => {
        if (data['UpdateDate']) {
          project.__update({ UpdateDate: data['UpdateDate'] })
        }
        this.removeUI(task);
        this.fetch(group, project);
        return true;
      }), catchError(error => {
        console.log('Could not remove task.');
        this.alertService.snackError(error);
        return observableOf(false);
      })
    )
  }


  moveTask(project: Project, taskId: string, payload: { projectId: string, fromGroupId: string, toGroupId: string, index: number }) {
    this.http.put(`${this._endPoint}/${taskId}/move`, payload)
      .subscribe(
        data => {
          let fromGid = this.getGId_v2(payload.projectId, payload.fromGroupId);
          let toGid = this.getGId_v2(payload.projectId, payload.toGroupId);

          let task: Task = this.findTask(taskId, fromGid);

          // let fromGroup = this.taskGroupService.findGroup(payload.fromGroupId, project.Id);
          let toGroup = this.taskGroupService.findGroup(payload.toGroupId, project.Id);

          if (data['__projectMetadata']) {
            project.__update({
              Metadata: data['__projectMetadata'],
              UpdateDate: data['__projectUpdateDate']
            });
          }
          task.__update({
            GroupId: payload.toGroupId === DefaultTaskGroup.ID ? null : payload.toGroupId,
            UpdateDate: data['UpdateDate'],
          });
          // moved 
          task._prevGroupId = task.GroupId;
          task._group = toGroup;

          this.removeUI(task, fromGid).addUI(task, toGid, payload.index)

          // this.fetch(fromGroup, project);
          // this.fetch(toGroup, project);

          this._uTask.next(task);
          this._processingState.next(TaskService.STATES.DONE);
        },
        error => {
          console.log('Could not move task.');
          if (error.status === 401) {
            this._processingState.next(TaskService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(TaskService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  readNotifications(task: Task) {
    if (!task.notifications) {
      return;
    }

    if (!task.notificationsIds.length) {
      task.readUINotifications();
      return;
    }

    this.http.put(`${this._endPoint}/${task.Id}/readNotifications`, {
      ids: task.notificationsIds
    })
      .subscribe(
        data => {
          this.messageBus.publish(MessageBusActions.NOTIFICATION_READ, {
            type: Notification.EVENTS.TASK,
            read: task.notifications.length
          });
          task.readUINotifications();

          this._uTask.next(task);
          this._processingState.next(TaskService.STATES.DONE);
        },
        error => {
          console.log('Could not order read task notifications.');
          if (error.status === 401) {
            this._processingState.next(TaskService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(TaskService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  handleNotification(notification: Notification, project: Project, group: BaseTaskGroup) {
    if (notification.isTask() && !isTaskV2(notification.data)) {
      let gid = this.getGId_v2(
        notification.targetPath.ProjectId,
        notification.targetPath.GroupId
      );

      switch (notification.action()) {
        case 'create':
          this.addUI(new Task(notification.data), gid);
          if (notification._inView) {
            this.fetch(group, project);
          }
          break;
        case 'update':
          this.updateUI(new Task(notification.data), gid);
          if (notification._inView) {
            this.fetch(group, project);
          }
          break;
      }
    }
  }

  completeTask(task: Task, project, account, requestOptions = {}): Observable<boolean> {
    let payload = {
      TaskId: task.Id,
      ProjectId: project.Id,
      AccountId: account.Id
    }
    return this.http.post(`${this._endPoint}/${task.Id}/complete`, payload, requestOptions).pipe(
      map(data => {
        task.markComplete(account.Id, true);
        if (task._group) {
          // when in topic feed, _group is not a must
          task._group._completeState = data['_groupCompleteState'];
        }
        this.ga.platformEvent('click_show_complete', 'complete');
        return true;
      }),
      catchError(error => {
        console.log('Could not mark complete task');
        this.alertService.snackWarning('Couldn\'t complete the task');
        return observableOf(false);
      })
    )
  }

  uncompleteTask(task: Task, account: Account, requestOptions = {}) {
    return this.http.delete(`${this._endPoint}/${task.Id}/not-complete`, requestOptions).pipe(
      map(data => {
        task.markComplete(account.Id, false);
        if (task._group) {
          // when in topic feed, _group is not a must
          task._group._completeState = data['_groupCompleteState'];
        }
        this.ga.platformEvent('click_show_complete', 'uncomplete');
        return true;
      }),
      catchError(error => {
        console.log('Could not uncomplete task');
        this.alertService.snackWarning('Couldn\'t uncomplete the task');
        return observableOf(false);
      })
    )
  }


  private inStorage(group: TaskGroup) {
    return !!this.dataStore[this.getGId(group)];
  }
  private cleanStorage(group: TaskGroup) {
    return delete (this.dataStore[this.getGId(group)]);
  }

  fetch(group: TaskGroup, project: Project) {
    let gid = this.getGId(group)
    if (!this.dataStore[gid]) {
      return;
    }

    let pTasks = Object.assign({}, this.dataStore[gid]).tasks;
    if (!this._groups[gid]) {
      group._active = true;
      this.initTasks(group);
    }
    this._groups[gid]._tasks.next(this.sort(pTasks, group, project));

    if (pTasks.length > 0) {
      this._groups[this.getGId(group)]._loadingState.next(TaskService.STATES.DONE);
    } else {
      this._groups[this.getGId(group)]._loadingState.next(TaskService.STATES.EMPTY);
    }
  }

  private addUI(task: Task | TaskV2, gid: string, index: number = 0) {
    if (!task) return this;
    if (this.dataStore[gid]) {
      this.dataStore[gid].tasks.splice(index, 0, task);
    }
    return this;
  }
  private removeUI(task: Task | TaskV2, gid?: string) {
    if (!task) return this;
    gid || (gid = this.getGId_v2(task.ProjectId, task.GroupId))
    if (this.dataStore[gid]) {
      this.dataStore[gid].tasks.forEach((t, i) => {
        if (t && t.Id === task.Id) {
          this.dataStore[gid].tasks.splice(i, 1);
        }
      });
    }
    return this;
  }
  private updateUI(task: Task | TaskV2, gid: string) {
    if (!task) return this;
    if (this.dataStore[gid]) {
      this.dataStore[gid].tasks.forEach((t, i) => {
        if (t && t.Id === task.Id) {
          this.dataStore[gid].tasks[i] = task;
        }
      });
    }
    return this;
  }

  private setCurrentTaskFromTarget(group: TaskGroup) {
    let targetsSet: boolean = false;
    this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
      .subscribe((targets) => {
        if (targets.taskId) {
          targetsSet = true;
          let task = this.findTask(targets.taskId, this.getGId(group));
          if (task) {
            this.openTask(task);
          }
        }
      })
  }

  findTask(taskId: string, gid?: string): Task {
    let task: Task;
    if (!gid) {
      for (let _gid in this.dataStore) {
        if (this.dataStore[_gid] && this.dataStore[_gid].tasks) {
          for (let i in this.dataStore[_gid].tasks) {
            let t = this.dataStore[_gid].tasks[i];
            if (t && t.Id === taskId) {
              task = t;
              break;
            }
          }
        }
      }
      return task || null;
    }

    if (!this.dataStore[gid]) {
      return null;
    }
    for (let t of this.dataStore[gid].tasks) {
      if (t && t.Id === taskId) {
        task = t;
        break;
      }
    }

    return task || null;
  }

  private findById(tId, tasks) {
    for (let t of tasks) {
      if (t.Id === tId) {
        return t;
      }
    }
    return null;
  }

  private findByNextId(tId, tasks) {
    if (tasks.length === 0) {
      return null;
    }
    let i;
    for (i = 0; i < tasks.length; i++) {
      if (tasks[i] === tId) {
        break;
      }
    }
    return tasks[i + 1] ? tasks[i + 1] : (tasks[i - 1] ? tasks[i - 1] : null);
  }

  private sort(tasks: Array<Task>, group: ITaskGroup, project: Project) {
    let gId = group.Id === DefaultTaskGroup.ID ? 'tasks' : `g_${group.Id}`;
    let union: any = {};
    let newMetadataGroups: Array<any> = [];

    project.Metadata[gId] || (project.Metadata[gId] = [])
    if (project.Metadata[gId].length === 0) {
      for (let t of tasks) {
        project.Metadata[gId].push(t.Id)
      }
    }
    for (let t of tasks) {
      union[t.Id] = 1;
    }
    for (let tId of project.Metadata[gId]) {
      union[tId]++;
      if (union[tId] === 2) {
        newMetadataGroups.push(tId)
      }
    }
    for (let tId in union) {
      if (union[tId] === 1) {
        newMetadataGroups.unshift(tId)
      }
    }
    project.Metadata[gId] = newMetadataGroups;
    return project.Metadata[gId].map(function (taskId) {
      return this.findById(taskId, tasks);
    }.bind(this))
  }

  openTask(task: Task | TaskV2, project: Project = null) {
    this.counterService.ping(task.ProjectId, task.Id);
    if (project && project._statistics && project._statistics.views >= 0) {
      project._statistics.views++;
    }

    if (this.targetService.targetLevel === 2) {
      this.targetService.reset();
      task._state = "target";
    }
    this.messageBus.publish(MessageBusActions.ACTIVATE_TASK, { task: task, action: 'open' });

    if (task instanceof Task) {
      this.readNotifications(task);
    }
  }

  nextTaskAfter(task: Task | TaskV2, group, project) {
    let gid = this.getGId_v2(task.ProjectId, task.__val('GroupId'));
    let sortedTasks = this.sort(this.dataStore[gid].tasks, group, project)
    let t = this.findByNextId(task, sortedTasks);
    return t;
  }

}