import { NgModule } from '@angular/core';
import { NotePreviewComponent } from './note-preview.component';
import { EmbedPipesModule } from 'app/_pipes/embed.pipe';
import { SafePipesModule } from 'app/_pipes/safe.pipe';


@NgModule({
    imports: [
        EmbedPipesModule,
        SafePipesModule
    ],
    declarations: [
        NotePreviewComponent,
    ],
    exports: [
        NotePreviewComponent
    ]
})
export class NotePreviewModule { }