<mgc-dialog>
  <mgc-dialog-header [title]="data.title" (close)="closeDialog()">
  </mgc-dialog-header>

  <mgc-dialog-content class="mgc-message">
    <mgc-post-as *ngIf="!data.edit" (ownerAs)="postAs($event)"></mgc-post-as>

    <jodit-editor [defaultValue]="modelText" [config]="joditConfig"></jodit-editor>

    <div class="options">
      <rs-uploader [style.font-size]="0" #rsUploader renderAs="content" [options]="uploaderOptions"
        (onAfterAddingFile)="onAfterAddingFile($event)" (onBeforeUpload)="onBeforeUploadFile($event)"
        (onProgress)="onUploadProgress($event)" (onSuccess)="onUploadSuccess($event)" (onError)="onUploadError($event)"
        (onCompleteAll)="onCompleteAll()">
      </rs-uploader>
      <mgc-icon-button icon="board-mat:camera_alt" (click)="rsUploader.click()"></mgc-icon-button>
      <mgc-icon-button icon="board-mat:attachment" (click)="rsUploader.click()"></mgc-icon-button>
      <mat-checkbox name="doNotRender">Do not render / execute code</mat-checkbox>
    </div>

    <attachments *ngIf="attachments.length" [data]="attachments" [model]="note" [preview]="mode==='preview'"></attachments>

  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.NOTE_CREATE, serviceAlertClass.NOTE_UPDATE]"></alert>

  <mgc-dialog-actions>
    <mgc-stroked-button left [processing]="note._state==='remove' && state===states.PROCESSING"
      (click)="onDelete($event)" *ngIf="data.edit">Delete</mgc-stroked-button>
    <mgc-raised-button right [processing]="note._state!=='remove' && state===states.PROCESSING" (click)="onSave()">Save
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>
</mgc-dialog>