import { IAccount } from "@board-accounts/account.model";
import { NoteReaction } from "@reactions/reaction.model";
import { ReactionTypes } from "@reactions/reactions.enum";
import { ModalImageBlock } from "./image-block/image-block";
import { IModalBlock } from "./modal-block.interface";
import { ModalTextBlock } from "./text-block/text-block";
import { ModalVideoBlock } from "./video-block/video-block";

export interface IModalBlocksMetadata {
    modalId: string;
    order: string[];
    blocks: IModalBlock[];
    account: IAccount;
    updatedAt: string;

    nComments: number;
    showComments: boolean;

    reactions: NoteReaction[];
    addReaction(reaction)
    updateReaction(reaction)
    deleteReaction(reaction)
    findReaction(reaction)
    groupedReaction(slice?: number)

    toDB();
    toJSON();
}

export class ModalBlocksMetadata implements IModalBlocksMetadata {
    modalId: string;
    order: string[] = [];
    updatedAt: string;

    nComments: number = 0;
    showComments: boolean = true;

    account: IAccount;
    private _blocks: IModalBlock[];
    set blocks(data: IModalBlock[]) {
        this.setBlocks(data)
    }
    get blocks(): IModalBlock[] {
        return this._blocks;
    }

    private _reactions: NoteReaction[] = [];
    set reactions(data: NoteReaction[]) {
        this._reactions = [];
        for (let prop in data) {
            this._reactions.push(new NoteReaction(data[prop]))
        }
    }
    get reactions(): NoteReaction[] {
        return this._reactions;
    }

    constructor(data: IModalBlocksMetadata) {
        data = { blocks: [], ...data };
        for (let prop in data) {
            this[prop] = data[prop];
        }

        if (this.order.length) {
            this._blocks = this.order.map((id: string) => {
                return this.blocks.find(block => block.BlockId == id);
            });
        }
    }

    private setBlocks(data: IModalBlock[]) {
        const blocks: IModalBlock[] = [];
        let block: IModalBlock;
        for (let k in data) {
            switch ((<IModalBlock>data[k]).Type) {
                case 'text':
                    block = new ModalTextBlock(data[k]);
                    break;
                case 'image':
                    block = new ModalImageBlock(data[k]);
                    break;
                case 'video':
                    block = new ModalVideoBlock(data[k]);
                    break;
                default:
                    block = null;
            }

            if (block) {
                blocks.push(block);
            }
        }

        this._blocks = blocks;
    }


    addReaction(reaction: any) {
        if (reaction instanceof NoteReaction) {
            this._reactions.push(reaction);
        } else {
            this._reactions.push(new NoteReaction(reaction));
        }
        return this;
    }
    updateReaction(reaction: any) {
        this._reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                r.Type = reaction.Type;
                r.UpdateDate = reaction.UpdateDate;
            }
        });
    }
    deleteReaction(reaction: any) {
        this._reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                this._reactions.splice(i, 1);
            }
        });
    }
    findReaction(accountId: string, anonymousId: string = null) {
        return this._reactions.find((reaction) => {
            return (accountId && reaction.AccountId === accountId) ||
            (anonymousId && reaction.AnonymousId === anonymousId);
        });
    }

    groupedReaction(slice: number = null) {
        slice || (slice = Object.keys(ReactionTypes).length);

        let grouped = this._reactions.reduce((rv, x) => {
            (rv[x['Type']] = rv[x['Type']] || []).push(x);
            return rv;
        }, {});

        let mapped = Object.keys(grouped).map(function (type) {
            return {
                type: type,
                value: grouped[type].length
            };
        });

        return mapped.sort((a, b) => b.value - a.value).slice(0, slice);
    };

    toDB() {
        return {
            accountId: this.account.Id,
            order: this.order
        }
    }

    toJSON() {
        return {
            modalId: this.modalId,
            order: this.order,
            blocks: this.blocks.map((b: IModalBlock) => b.toDb()),
            account: this.account
        }
    }
}
