import { NgModule } from '@angular/core';
import { MessageDataComponent } from './message-data.component';

@NgModule({
    imports: [],
    declarations: [
        MessageDataComponent,
    ],
    exports: [
        MessageDataComponent
    ]
})
export class MessageDataModule { }