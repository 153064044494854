import {
  Component, Input, Inject, SimpleChanges, OnChanges, HostBinding, OnInit, ViewChild
} from '@angular/core';

import { Project, } from '@board/items/project/project';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { PermissionService } from '@board/_services';
import { forkJoin } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ProjectService } from '@board/items/project/project.service';
import { AccountService } from '@board-accounts/index';
import { PUBLISH_TABS } from './publish-tabs.component';
import { PublishEditComponent } from './publish/publish-edit.component';
import { Brand } from '@board-brands/brand.model';
import { BrandService } from '@board-brands/brand.service';


@Component({
  selector: 'mgc-publish',
  template: `
    <mgc-loader *ngIf="!ready"></mgc-loader>
    <ng-container *ngIf="ready">
      <mgc-publish-edit *ngIf="settingsTab" [project]="project" [brands]="brands"></mgc-publish-edit>
      <mgc-publish-view *ngIf="previewTab" [project]="project"></mgc-publish-view>
    </ng-container>
  `
})
export class PublishComponent implements OnInit, OnChanges {
  @HostBinding('class.mgc-publish') publishClass = true;
  @Input('item') project: Project;
  @Input('tab') tab: PUBLISH_TABS;

  @ViewChild(PublishEditComponent) publishEditComponent: PublishEditComponent;

  ready: boolean = false;
  allowUpdateProject: boolean = false;

  brands: Brand[];
  pubdata: any;

  constructor(
    private permissionService: PermissionService,
    private projectService: ProjectService,
    private brandService: BrandService,
    private accountService: AccountService,
    @Inject(APP_CONFIG) public config: AppConfig,
  ) {
  }

  selectedTab: PUBLISH_TABS;
  get settingsTab() {
    return this.selectedTab === PUBLISH_TABS.SETTINGS;
  }
  get previewTab() {
    return this.selectedTab === PUBLISH_TABS.PREVIEW;
  }

  state: number;

  get isDirty() {
    return this.publishEditComponent && this.publishEditComponent.isDirty;
  }
  get processing() {
    return this.publishEditComponent && this.publishEditComponent.processing;
  }
  onSave() {
    this.publishEditComponent.onSave();
  }

  onCancel(): void {
    this.project.__revert();
  }

  onContentChange(tab: PUBLISH_TABS) {
    if (this.allowUpdateProject) {
      this.selectedTab = tab;
    } else {
      this.selectedTab = PUBLISH_TABS.PREVIEW;
    }
  }

  ngOnInit() {
    this.allowUpdateProject = this.permissionService.allowsAction('update.project', this.project, { silent: true });

    forkJoin(
      this.projectService.getProjectPubdata(this.project),
      this.brandService.getBrands()
    ).pipe(
      finalize(() => this.ready = true),
      take(1)
    ).subscribe(([pubdata, brands]) => {
      this.pubdata = pubdata || {};
      this.brands = brands;
    });

    this.onContentChange(this.tab);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tab'] && changes['tab'].currentValue && !changes['tab'].firstChange) {
      this.onContentChange(this.tab);
    }
  }

}
