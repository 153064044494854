import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ModalsComponent} from './modals.component';
import {TooltipModule} from '@libraries/tooltip/tooltip.module';
import {ModalsEditorComponent} from '@board/modals/modals-editor/modals-editor.component';
import {ModalListComponent} from '@board/modals/modals-editor/modal-list/modal-list.component';
import {ModalFactoryComponent} from '@board/modals/modals-editor/modal-factory/modal-factory.component';
import {
  MgcButtonModule,
  MgcDialogModule,
  MgcDividerModule,
  MgcFormFieldModule,
  MgcInformationModule,
  MgcListModule,
  MgcLoaderModule,
  MgcMenuModule
} from '@rallysite/mgc-components';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import {DatePipesModule} from '@board/_pipes';
import {ReactiveFormsModule} from '@angular/forms';
import {
  ConfigAssessmentComponent
} from '@board/modals/modals-editor/modal-factory/config/assessment/config-assessment.component';
import {AlertModule} from '@rallysite/components/alert';
import {ConfigCommonComponent} from '@board/modals/modals-editor/modal-factory/config/common/config-common.component';
import {ModalBlocksComponent} from '@board/modals/modals-editor/modal-factory/blocks/modal-blocks.component';
import {
  ModalTextBlockComponent
} from '@board/modals/modals-editor/modal-factory/blocks/text-block/text-block.component';
import {
  ModalVideoBlockComponent
} from '@board/modals/modals-editor/modal-factory/blocks/video-block/video-block.component';
import {
  ModalImageBlockComponent
} from '@board/modals/modals-editor/modal-factory/blocks/image-block/image-block.component';
import {JoditModule} from '@libraries/jodit/jodit.module';
import {SafePipesModule} from '@rallysite/global-pipes';
import {
  ModalImageComponent
} from '@board/modals/modals-editor/modal-factory/blocks/image-block/image/modal-image.component';
import {UploaderModule} from '@libraries';
import {ConfigStreakComponent} from '@board/modals/modals-editor/modal-factory/config/streak/config-streak.component';
import {ConfigMissedComponent} from '@board/modals/modals-editor/modal-factory/config/missed/config-missed.component';
import {ModalBaseBlockComponent} from '@board/modals/modals-editor/modal-factory/blocks/modal-base-block.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MgcLoaderModule,
    MgcButtonModule,
    MgcFormFieldModule,
    MgcDialogModule,
    MgcDividerModule,
    MgcMenuModule,
    MgcInformationModule,
    DatePipesModule,
    ReactiveFormsModule,
    AlertModule,
    JoditModule,
    SafePipesModule,
    UploaderModule,
    MgcListModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  declarations: [
    ModalsComponent,
    ModalsEditorComponent,
    ModalListComponent,
    ModalFactoryComponent,
    ConfigAssessmentComponent,
    ConfigCommonComponent,
    ModalBlocksComponent,
    ModalTextBlockComponent,
    ModalVideoBlockComponent,
    ModalImageBlockComponent,
    ModalImageComponent,
    ConfigStreakComponent,
    ConfigMissedComponent,
    ModalBaseBlockComponent,
  ],
  exports: [
    ModalVideoBlockComponent,
    ModalTextBlockComponent,
    ModalImageBlockComponent
  ]
})
export class ModalsModule {
}
