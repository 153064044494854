import { BOARD_VIEW_MODES } from "@board/_components/view-settings/board-view-modes.class";
import { IThemeOptions } from "@libraries/theming";

export interface IAccountSettings {
    theme: IThemeOptions;
    viewMode: string; //'clean' | 'summary' | 'detailed';

    toDb();
}

export class AccountSettings implements IAccountSettings {
    static readonly DEFAULT_VIEW_MODE: string = BOARD_VIEW_MODES.SUMMARY;

    private _themeData: IThemeOptions;
    set theme(object: IThemeOptions) {
        object || (object = {})
        this._themeData = { themeId: object.themeId, type: object.type };
        if (object.custom_image) {
            this._themeData.custom_image = object.custom_image;
        }
        if (object.custom_color) {
            this._themeData.custom_color = object.custom_color;
        }
    }
    get theme(): IThemeOptions { return this._themeData || {} }

    private _viewMode: string;
    set viewMode(object: string) { this._viewMode = object; }
    get viewMode(): string { return this._viewMode || AccountSettings.DEFAULT_VIEW_MODE }

    constructor(data?: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    // handleNavBg(figureUrlPipe: NavigationBgUrlPipe, fallback: boolean = false) {
    //     if (this.theme.class === BOARD_THEMES.IMAGE) {
    //         this.theme.image || (this.theme.image = AccountSettings.DEFAULT_IMAGE_THEME);

    //         if (this.theme.image.accent && Object.values(ACCENTS).indexOf(this.theme.image.accent) >= 0) {
    //             // all good
    //         } else {
    //             this.theme.image.accent = ACCENTS.WARM;
    //         }

    //         if (this.theme.image.id === '__default') {
    //             this.setNavBgAsImage();
    //         } else {
    //             this.setNavBgAsImage(figureUrlPipe.transform(this.theme.image.id, 'large', true));
    //         }
    //     } else
    //         // if there is a custom theme set, we look to idetify the image and set it as background image for navigation panel
    //         // fallback to previous version
    //         if (fallback && this.navigation && this.navigation.figure &&
    //             this.navigation.figure.image && this.navigation.figure.image.figureId) {
    //             this.theme = {
    //                 class: BOARD_THEMES.IMAGE,
    //                 image: {
    //                     id: this.navigation.figure.image.figureId,
    //                     accent: ACCENTS.WARM
    //                 }
    //             }
    //             this.setNavBgAsImage(figureUrlPipe.transform(this.theme.image.id, 'large', true));
    //         }

    //     if (!this.navBg) {
    //         this.setNavBgAsImage();
    //     }

    //     return this;
    // }

    // private setNavBgAsImage(imageUrl?: any) {
    //     if (!imageUrl) {
    //         imageUrl = AccountSettings.DEFAULT_IMAGE_URL;
    //     }
    //     this.navBg = {
    //         'background': `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${imageUrl})`,
    //         'background-repeat': 'no-repeat',
    //         'background-position': 'center center',
    //         'background-size': 'cover',
    //         'transition': 'margin .3s, background-image .4s linear'
    //     }
    // }

    toDb() {
        return {
            theme: this.theme,
            viewMode: this.viewMode
        }
    }

    toJSON() {
        return {
            theme: this.theme,
            viewMode: this.viewMode
        }
    }
}