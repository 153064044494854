import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from './survey.component';
import { SurveyOverlayModule } from '../_overlay/survey-overlay.module';
import { LoaderModule } from '../_utils/loader/loader.module';
import { BrandSurveyComponent } from './brand-survey.component';
import { SurveyProgressModule } from '../survey-progress/survey-progress.module';
import { MgcButtonModule, MgcDividerModule, MgcLoaderModule } from '@rallysite/mgc-components';
import { SurveyQuestionTypesModule } from './question-types/survey-question-types.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ResizedEventModule } from '@libraries/resize-detect';
import { BrandAvatarModule } from '@board-brands/_components/brand-avatar';
import { ImageModule } from '@board-components/image/x_image.module';
import { UIBlocksModule } from '@board/surveys/survey-factory/survey-feedbacks/ui-blocks/ui-blocks.module';
import { ReactiveFormsModule } from '@angular/forms';
import {AuthInlineTempModule} from '@rallysite/auth/auth-inline-temp/auth-inline-temp.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,

    MgcDividerModule,
    MgcLoaderModule,
    ResizedEventModule,
    MgcButtonModule,

    SurveyOverlayModule,
    LoaderModule,
    AuthInlineTempModule,
    SurveyProgressModule,
    SurveyQuestionTypesModule,
    BrandAvatarModule,
    ImageModule,
    UIBlocksModule
  ],

  declarations: [
    SurveyComponent,
    BrandSurveyComponent
  ],
  exports: [
    SurveyComponent,
    BrandSurveyComponent
  ]
})
export class SurveysDataModule { }
