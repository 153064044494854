import { Component, Inject, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Popup } from './popup.model';
import { IPopupView } from './popups/popup-view.interface';
import { PopupDirective } from './popups/popup.directive';
import { PopupTypeComponent, PopupMethod } from './popups/popup.types';
import { Subscription } from 'rxjs';
import { User } from '@rallysite/user';
import { Account } from '@board-accounts/account.model';

@Component({
  selector: 'popup-modal',
  templateUrl: './popup.modal.html',
  styleUrls: ['./popup.modal.scss'],
})
export class PopupModal {
  popup: Popup;

  instanceRef: IPopupView;
  private closeSubscription: Subscription;

  title = '';
  steps: Array<any>;

  popupViewHost: PopupDirective;
  @ViewChild(PopupDirective, { static: false })
  set host(host: PopupDirective) {
    this.popupViewHost = host;
    this.bootstrap();
  }

  get PopupMethod() {
    return PopupMethod;
  }

  get submitAvailable() {
    if (this.instanceRef) {
      return this.instanceRef.submitAvailable;
    }
    return false;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private dialogRef: MatDialogRef<PopupModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      popup: Popup,
      user: User,
      account: Account
    }
  ) {
    this.popup = data.popup;
  }

  ngOnInit() {
    this.loadView();
  }

  loadView() {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }

    this.title = PopupTypeComponent[this.popup.Uid].title;
    this.steps = PopupTypeComponent[this.popup.Uid].steps || [{ buttons: PopupTypeComponent[this.popup.Uid].buttons }];

    const component: Type<IPopupView> = PopupTypeComponent[this.popup.Uid].component;

    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(component);
    this.instanceRef = componentRef.instance as IPopupView;
    (<IPopupView>this.instanceRef).data = this.data;

    this.closeSubscription = this.instanceRef.close.subscribe(result => {
        this.closeDialog(result);
      });

    componentRef.hostView.detectChanges();
  }

  private bootstrap() {
    if (!this.popupViewHost) {
      return;
    }

    this.viewContainerRef.clear();

    this.viewContainerRef = this.popupViewHost.viewContainerRef;
    this.loadView();
  }

  get processing() {
    if (!this.instanceRef) {
      return false;
    }
    return this.instanceRef.processing;
  }

  // starting step
  get stepIndex() {
    if (!this.instanceRef) {
      return 0;
    }
    return this.instanceRef.stepIndex || 0;
  }

  onClick(method: PopupMethod) {
    if (!this.instanceRef) {
      return;
    }
    switch (method) {
      case PopupMethod.SUBMIT:
        this.instanceRef.onSubmit();
        break;
      case PopupMethod.CLOSE:
        this.closeDialog();
        break;
    }
  }

  closeDialog(message: string = 'close'): void {
    if (this.instanceRef) {
      message = this.instanceRef.closeData || 'close';
    }

    this.dialogRef.close(message);
  }

}
