import { map, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of as observableOf, BehaviorSubject, of, Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

import { AlgoTag, AlgoTagBase, IAlgoTag, IAlgoTagBase } from './algotag/algotag.model';

export interface IAlgoTags {
  custom: IAlgoTagBase[];
  predefined: IAlgoTag[];
}

@Injectable({
  providedIn: 'root'
})
export class AlgoTagsService {

  private _endPoint: string;

  private gettingAlgoTags: boolean = false;
  private waitingAlgoTags: BehaviorSubject<IAlgoTag[]>;

  private algoTagStore: IAlgoTag[];

  private searchStore: IAlgoTagBase[];

  private dataStore: {
    [key: string]: IAlgoTags
  } = {};

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    this._endPoint = `${this.config.endpoint}/api/algotags`;
    this.waitingAlgoTags = <BehaviorSubject<IAlgoTag[]>>new BehaviorSubject(null);
  }

  /**
   * Get predefined algotags
   * @returns 
   */
  getPredefinedAlgoTags(): Observable<IAlgoTag[]> {
    if (this.gettingAlgoTags) {
      return this.waitingAlgoTags.asObservable();
    }

    if (this.algoTagStore) {
      return of(this.algoTagStore);
    }

    this.algoTagStore = [];
    this.gettingAlgoTags = true;
    const endpoint = `${this._endPoint}/predefined`;
    return this.http.get(endpoint).pipe(
      map((data: IAlgoTag[]) => {
        for (const key in data) {
          this.algoTagStore.push(new AlgoTag(data[key]));
        }

        this.gettingAlgoTags = false;
        this.waitingAlgoTags.next(this.algoTagStore);

        return this.algoTagStore;
      }), catchError(error => {
        console.log('Could not load algo tags');
        return observableOf(null);
      })
    )
  }

  /**
   * @param key 
   * @returns 
   */
  getAlgoTags(key: string): Observable<IAlgoTags> {
    if (this.dataStore[key]) {
      return of(this.dataStore[key]);
    }

    const endpoint = `${this._endPoint}/${key.split("_").join("/")}`;
    return this.http.get(endpoint).pipe(
      map((data: IAlgoTags) => {
        return this.storeData(key, data);
      }), catchError(error => {
        console.log(`Could not load algo tags for key ${key}`);
        return observableOf(null);
      })
    )
  }

  /**
   * @param key 
   * @param param:IAlgoTags
   * @returns 
   */
  saveAlgoTags(key: string, { custom, predefined }: IAlgoTags): Observable<IAlgoTags> {

    const payload = {
      custom: custom
        .filter((t: IAlgoTagBase) => t.isDirty())
        .map(t => t.toDb()),
      predefined: predefined
        .filter((t: IAlgoTag) => t.isDirty())
        .map(t => t.toDb())
    };

    if (!payload.custom.length && !payload.predefined.length) {
      return of(this.dataStore[key]);
    }

    return this.http.put(`${this._endPoint}/${key.split("_").join("/")}`, payload).pipe(
      map((data: IAlgoTags) => {
        return this.storeData(key, data);
      }), catchError(error => {
        console.log('Could not save algo tags');
        return observableOf(null);
      })
    )
  }

  /**
   * @param term 
   * @returns 
   */
  saveNewAlgoTag(term: string): Observable<IAlgoTagBase> {
    const tag = new AlgoTagBase({
      Name: term.trim()
    });

    return this.http.post(`${this._endPoint}/custom`, tag.toDb()).pipe(
      map((data: IAlgoTagBase) => {
        const tag = new AlgoTagBase(data);
        this.searchStore.push(tag)
        return tag;
      }), catchError(error => {
        console.log('Could not save algo tags');
        return observableOf(null);
      })
    )
  }

  /**
   * 
   * @param key 
   * @param data 
   * @returns 
   */
  private storeData(key: string, { custom, predefined }: IAlgoTags): IAlgoTags {
    this.dataStore[key] = {
      custom: [],
      predefined: []
    };
    for (const k in custom) {
      this.dataStore[key].custom.push(new AlgoTagBase(custom[k]));
    }
    for (const k in predefined) {
      this.dataStore[key].predefined.push(new AlgoTag(predefined[k]));
    }

    return this.dataStore[key];
  }

  /**
   * @param tags 
   * @returns 
   */
  sort<TTag extends IAlgoTagBase>(tags: TTag[]): TTag[] {
    return tags.sort((t1, t2) => {
      const tn1 = t1.Name.toLowerCase();
      const tn2 = t2.Name.toLowerCase()
      return tn1 < tn2 ? -1 : (tn1 > tn2 ? 1 : 0);
    })
  }

  /**
   * @param tags 
   * @param tag 
   */
  removeFrom<TTag extends IAlgoTagBase>(tags: TTag[], tag: TTag): void {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].Id === tag.Id) {
        tags.splice(i, 1);
      }
    }
  }

  /**
   * @param tags 
   * @param tag 
   */
  addTo<TTag extends IAlgoTagBase>(tags: TTag[], tag: TTag): void {
    if (!tags.find(t => t.Id === tag.Id)) {
      tags.push(tag);
      this.sort(tags);
    }
  }

  /**
   * @param term 
   * @returns 
   */
  searchTags(term: string) {
    if (!term.trim()) {
      // if not search term, return empty SearchItems array.
      this.searchStore = [];
      return of(this.searchStore);
    }

    this.searchStore = [];
    const payload = {
      'limit': '30',
      'offset': '0',
      keywords: term.toLowerCase(),
    }

    const params = new HttpParams({ fromObject: payload });

    return this.http.get(`${this._endPoint}/custom`, { params }).pipe(
      map(data => {
        for (let k in data) {
          this.searchStore.push(new AlgoTagBase(data[k]));
        }

        return this.searchStore
      }, catchError(error => {
        console.log('Could not search for tags');
        return of([]);
      })
      )
    )
  }
}