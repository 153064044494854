<loader d="80" *ngIf="loading || !isBrowser"></loader>

<div *ngIf="ready" class="survey-wrapper" [class.hide]="authenticate || surveySaved" [class.sr-only]="!isBrowser">

  <div class="a-header">
    <span>Your Spiritual Assessment</span>
    <div class="a-progress">

      <span>Question {{ questionIndex + 1 }}/{{ questions.length }}</span>
      <button class="survey-button" (click)="finish($event)">
        <loader *ngIf="processing" d="22"></loader>
        <span>{{ firstTimeAssessment && haveDefaults ? 'Quit' : 'Finish' }}</span>
      </button>

    </div>
  </div>

  <div class="a-container">
    <div class="alert-error" *ngIf="error">
      Woops, something went wrong!
    </div>

    <div class="question-wrapper" *ngIf="!changingQuestion" [@slideForwardAndBackward]="animationDirection">
      <div class="question">
        <span class="q-text">
          <b *ngIf="currentQuestion?.Required">*</b>
          {{currentQuestion?.Text}}
        </span>
        <div class="q-options">
          <div class="option" *ngFor="let option of currentQuestion?.Options" (click)="selectOption(option)"
            [ngClass]="optionClass(option)">
            <span [innerHTML]="option.Text"></span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div (close)="onAuthClose($event)" mgc-auth-inline></div>

<div class="survey-end" *ngIf="surveySaved">
  <h2 class="survey-ty-text">Thank you for taking the assessment.<br>Your efforts help us do better!</h2>
  <div class="survey-ty-image filtered"></div>
</div>