import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

import { FileDetailsComponent } from './file-details.component';
import { DatePipesModule } from '@board/_pipes';

@Pipe({ name: 'size' })
export class SizePipe implements PipeTransform {
    transform(value: number): string {
        let unit
        if (value < 1024) {
            unit = 'Bytes'
        } else if (value / 1024 < 1024) {
            value = value / 1024
            unit = 'KB'
        } else if (value / 1024 / 1024 < 1024) {
            value = value / 1024 / 1024
            unit = 'MB'
        }

        return value.toFixed(2) + ' ' + unit;
    }
}

@NgModule({
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,

        DatePipesModule

    ],
    declarations: [
        FileDetailsComponent,

        SizePipe
    ],
    exports: [FileDetailsComponent]
})
export class FileDetailsModule { }
