import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CommentModal } from './modal/comment-modal';
import { CommentListComponent, CommentReplyListComponent } from './comment-list.component';
import { CommentComponent } from './comment.component';
import { ClipboardModule } from '@board/_components/clipboard';
import { SharedPanelComponentsModule } from '@panel-components/x_shared-panel-components.module';
import { MgcDialogModule } from '@rallysite/mgc-components';
import { MessageDataModule } from '@board/_components/message';
import { NotePreviewModule } from '@panel-components/notes/note-preview/x_note_preview.module';
import { AttachmentsModule } from '@panel-components/notes/attachments/x_attachments.module';
import { UploaderModule } from '@libraries';
import { InfoTooltipModule } from '@board/_components/info-tooltip/info-tooltip.module';
import { ConfirmOverlayModule } from '@board/_components/confirm-overlay/confirm-overlay.module';
import { CommentService } from './comment.service';
import { WithAttachmentsModule } from '@panel-components/notes/with-attachments/x_with-attachments.module';
import { ReactionsModule } from '@reactions/x_reactions.module';
import { EmbedPipesModule, EmbedPipe } from 'app/_pipes/embed.pipe';
import { SafePipe } from 'app/_pipes/safe.pipe';
import { PostAsModule } from '@board/_components/post-as/post-as.module';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MgcDialogModule,
        UploaderModule,
        AccountAvatarModule,
        ClipboardModule,
        MessageDataModule,
        AttachmentsModule,
        NotePreviewModule,
        SharedPanelComponentsModule,
        InfoTooltipModule,
        ConfirmOverlayModule,
        WithAttachmentsModule.withDependencies(CommentService),
        ReactionsModule,
        EmbedPipesModule,
        PostAsModule,
    ],
    declarations: [
        CommentModal,
        CommentListComponent,
        CommentReplyListComponent,
        CommentComponent
    ],
    exports: [
        CommentListComponent,
        CommentReplyListComponent,
    ],
    providers: [
        EmbedPipe,
        SafePipe
    ]
})
export class CommentsModule { }