<mgc-dialog>

  <ng-container [ngSwitch]="refInView">
    <mgc-dialog-header *ngSwitchCase="REFs.MultiGalleryComponent" title="Image Galery" (close)="closeDialog()">
    </mgc-dialog-header>
    <mgc-dialog-header *ngSwitchCase="REFs.CustomImageComponent" title="Custom Image Theme" (close)="closeDialog()">
    </mgc-dialog-header>
  </ng-container>

  <mgc-dialog-content [ngSwitch]="refInView">
    <multi-gallery *ngSwitchCase="REFs.MultiGalleryComponent" [imageQuality]="imageQuality"
      [selectedItems]="selectedItems" [options]="galleryOptions" (submitDisabled)="onSubmitDisabled($event)">
    </multi-gallery>
    <mgc-custom-image *ngSwitchCase="REFs.CustomImageComponent" (chooseMedia)="openMultiGallery()"
      [customImageOptions]="customImageOptions">
    </mgc-custom-image>
  </mgc-dialog-content>

  <alert></alert>

  <mgc-dialog-actions>
    <mgc-raised-button right [disabled]="refInView===REFs.MultiGalleryComponent && !isValid" (click)="onProcess()"
      tabindex="2">
      <ng-container [ngSwitch]="refInView">
        <span *ngSwitchCase="REFs.MultiGalleryComponent">Use selected</span>
        <span *ngSwitchCase="REFs.CustomImageComponent">Save</span>
      </ng-container>
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>