import { Component } from "@angular/core";

@Component({
    template: '<ng-content></ng-content>',
})
export class MessageDataComponent {

    mode: 'edit' | 'preview' = 'edit';
    tabWidth: number = 100;

    changeToEdit() {
        this.mode = 'edit';
    }
    changeToPreview() {
        this.mode = 'preview';
    }

    get editMode(): boolean {
        return this.mode === 'edit';
    }

    get previewMode(): boolean {
        return this.mode === 'preview';
    }

    get leftPosition(): number {
        return this.editMode ? 0 : this.tabWidth;
    }

}