import { NgModule, Pipe, PipeTransform, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountAvatarComponent } from './avatar.component';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IAccount } from '@board-accounts/account.model';


@Pipe({ name: 'avatarUrl' })
export class AvatarUrlPipe implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  transform(account: IAccount, timestamp: number = null): string {
    const url = `${this.config.endpoint}/api/avatars/${account.Id}`;
    if (timestamp) {
      return `${url}?t=${timestamp}`;
    }
    return url;
  }
}


@Pipe({ name: 'avatarBlobUrl' })
export class AvatarBlobUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(file: any): SafeResourceUrl {
    let url = URL.createObjectURL(file.rawFile);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule,
    ],
    declarations: [
        AccountAvatarComponent,
        AvatarUrlPipe,
        AvatarBlobUrlPipe
    ],
    exports: [
        AccountAvatarComponent
    ]
})
export class AccountAvatarModule { }
