import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "@rallysite/config";
import { ITooltip, Tooltip } from "./tooltip.model";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AlertService, ServiceAlertClass } from "@rallysite/components/alert";

@Injectable({
  providedIn: "root",
})
export class TooltipService {
  private _endPoint: string;

  private dataStore: {
    tooltips: Tooltip[];
  } = { tooltips: [] };

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService
  ) {
    this._endPoint = `${this.config.endpoint}/api/tooltips`;
  }

  getTooltips(tooltipIds: number[]) {
    const ids = tooltipIds.filter((id) => !this.dataStore.tooltips[id]);
    if (ids.length === 0) {
      return of([]);
    }

    let params = new HttpParams({
      fromObject: {
        "filter[Id][]": ids.map((id) => "" + id),
      },
    });

    return this.http.get(this._endPoint, { params: params }).pipe(
      map((data: ITooltip[]) => {
        data || (data = []);
        const tooltips = [];
        for (let key in data) {
          this.dataStore.tooltips[data[key].Id] = new Tooltip(data[key]);
          tooltips.push(this.dataStore.tooltips[data[key].Id]);
        }
        for (let id in ids) {
          if (!this.dataStore.tooltips[id]) {
            this.dataStore.tooltips[id] = new Tooltip({
              Id: +id,
            });
            tooltips.push(this.dataStore.tooltips[id]);
          }
        }
        return tooltips;
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  getTooltip(tooltipId: number): Observable<Tooltip> {
    if (this.dataStore.tooltips[tooltipId]) {
      return of(this.dataStore.tooltips[tooltipId]);
    }
    const endpoint = `${this._endPoint}/${tooltipId}`;
    return this.http.get(endpoint).pipe(
      map((tooltipData: ITooltip) => {
        if (!(tooltipData || <ITooltip>{}).Id) {
          tooltipData = <ITooltip>{
            Id: tooltipId,
          };
        }
        this.dataStore.tooltips[tooltipData.Id] = new Tooltip(tooltipData);
        return this.dataStore.tooltips[tooltipData.Id] || null;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  update(tooltip: Tooltip) {
    const endpoint = `${this._endPoint}/${tooltip.Id}`;
    return this.http.put(endpoint, tooltip.toDb()).pipe(
      map((tooltipData: ITooltip) => {
        this.alertService.clear();
        this.dataStore.tooltips[tooltipData.Id].update(tooltipData);
        return this.dataStore.tooltips[tooltipData.Id];
      }),
      catchError((error) => {
        console.log("Could not update tooltip.");
        this.alertService.snackError(
          error,
          ServiceAlertClass.ALERTS.TOOLTIP_UPDATE
        );
        return of(null);
      })
    );
  }
}
