<div class="factory-header">
  <div class="tabs-wrapper">
    <div class="mgc-tabs">
      <ng-container *ngFor="let tab of tabs">
        <span class="mgc-tab nup" [style.width.px]="tab.width"
              [class.active]="currentTab.key === tab.key"
              (click)="this.currentTab = tab">{{ tab.key | titlecase }}</span>
      </ng-container>
      <div class="mgc-link-bar" [style.width.px]="currentTab.width" [style.left.px]="currentTab.leftOffsetPx"></div>
    </div>
  </div>

  <mgc-raised-button
    *ngIf="currentTab.key === 'logic' || currentTab.key === 'content'" [processing]="processing"
    (click)="onSave()"
  >
    Save
  </mgc-raised-button>
</div>

<div class="modal-alert" [style.opacity]=".9" [style.display]="showNotification ? 'block' : 'none'">
  <alert></alert>
</div>

<mgc-divider [full-width]="true"></mgc-divider>

<div class="factory-body">
  <form [formGroup]="form">
    <div class="inner-factory-body" *ngIf="currentTab.key === 'logic'">
      <div class="enable-remove-container">
      <mat-slide-toggle
        [style.margin-top.px]="15"
        class="mgc-toggle mb-4"
        formControlName="IsEnabled"
        [checked]="f.IsEnabled.value"
      >
        Enable modal?
      </mat-slide-toggle>

        <mgc-icon-button (click)="deleteModal($event)" class="remove" icon="board-mat:trash"></mgc-icon-button>
      </div>

      <div class="media-block-item">
        <div class="media-block-item-header">
          <div class="media-block-drag"></div>
        </div>
        <div class="media-block-content">
          <div class="media-block-item-body">
            <mgc-form-field label="Internal Modal Name" [invalid]="submitted && f.InternalName.errors">
              <div class="field-wrapper">
                <input tabindex="-1" formControlName="InternalName">
                <span class="placeholder" *ngIf="!f.InternalName.value">Internal Modal Name</span>
              </div>
              <ng-container invalid-feedback *ngIf="submitted && f.InternalName.errors">
                <div *ngIf="f.InternalName.errors.required">This field is required</div>
              </ng-container>
            </mgc-form-field>

            <mgc-form-field label="Internal Modal Description" [invalid]="submitted && f.InternalDescription.errors">
              <div class="field-wrapper">
                <input tabindex="-1" formControlName="InternalDescription">
                <span class="placeholder" *ngIf="!f.InternalDescription.value">Internal Modal Description</span>
              </div>
              <ng-container invalid-feedback *ngIf="submitted && f.InternalDescription.errors">
                <div *ngIf="f.InternalDescription.errors.required">This field is required</div>
              </ng-container>
            </mgc-form-field>
          </div>
        </div>
      </div>

      <div class="media-block-item">
        <div class="media-block-item-header">
          <div class="media-block-drag"></div>
        </div>
        <div class="media-block-content">
          <div class="media-block-item-header mb-4">
            Allow user to close modal & frequency
          </div>
          <div class="media-block-item-body">
            <config-common [(modal)]="modal" [submitted]="submitted"></config-common>
          </div>
        </div>
      </div>

      <div class="media-block-item">
        <div class="media-block-item-header">
          <div class="media-block-drag"></div>
        </div>
        <div class="media-block-content">
          <div class="media-block-item-header mb-4">
            RULE: {{ modal.ModalType }}
          </div>
          <div class="media-block-item-body">
            <config-assessment *ngIf="modal.ModalType === 'assessment'"
                               [(modal)]="modal"
                               [submitted]="submitted"
            ></config-assessment>

            <config-missed *ngIf="modal.ModalType === 'missed'"
                           [(modal)]="modal"
                           [submitted]="submitted"
            ></config-missed>

            <config-streak *ngIf="modal.ModalType === 'streak'"
                           [(modal)]="modal"
                           [submitted]="submitted"
            ></config-streak>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-factory-body" *ngIf="currentTab.key === 'content'">
      <div class="media-block-item">
        <div class="media-block-item-header">
          <div class="media-block-drag"></div>
        </div>
        <div class="media-block-content">
          <div class="media-block-item-body">
            <mgc-form-field label="Modal Title" [invalid]="submitted && f.Title.errors">
              <div class="field-wrapper">
                <input tabindex="-1" formControlName="Title">
                <span class="placeholder" *ngIf="!f.Title.value">Modal Title</span>
              </div>
              <ng-container invalid-feedback *ngIf="submitted && f.Title.errors">
                <div *ngIf="f.Title.errors.required">This field is required</div>
              </ng-container>
            </mgc-form-field>

            <mgc-form-field label="Modal Description" [invalid]="submitted && f.Description.errors">
              <div class="field-wrapper">
                <input tabindex="-1" formControlName="Description">
                <span class="placeholder" *ngIf="!f.Description.value">Modal Description</span>
              </div>
              <ng-container invalid-feedback *ngIf="submitted && f.Description.errors">
                <div *ngIf="f.Description.errors.required">This field is required</div>
              </ng-container>
            </mgc-form-field>
          </div>
        </div>
      </div>
      <modal-blocks [item]="modal" *ngIf="!isModalNew"></modal-blocks>
      <div *ngIf="isModalNew">
        Save modal first before adding content.
      </div>
    </div>
  </form>

  <div class="inner-factory-body" *ngIf="currentTab.key === 'preview'">
    Preview is not yet ready
  </div>
</div>
