<div class="survey-inner">

  <assessment #assessment (close)="onClose($event)"></assessment>

  <div class="mgc-survey-footer" *ngIf="assessment.ready">
    <div class="survey-buttons">
      <button type="button" class="survey-button left" *ngIf="assessment.hasPrev"
        (click)="assessment.prev()"><span>Previous</span></button>
      <button type="button" class="survey-button right" *ngIf="assessment.hasNext && assessment.allowSkip && !assessment.error"
        (click)="assessment.next()"><span>Skip</span></button>
      <button type="button" class="survey-button right" *ngIf="assessment.error"
        (click)="onClose()"><span>Close</span></button>
    </div>
  </div>
</div>