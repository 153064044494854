import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({ name: 'string' })
export class StringPipe implements PipeTransform {

  constructor(
  ) { }

  transformAs(value, type) {
    switch (type) {
      case 'ucfirst': return value.charAt(0).toUpperCase() + value.slice(1);
      case 'camelize': return value.replace(/(?:(^(.)|\s(.)))/g, function ($1) { return $1.toUpperCase(); });
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  public transform(value: any, type: string | Array<string>): string {

    if (type instanceof Array) {
      for (let i = 0; i < type.length; i++) {
        let prop = type[i];
        value = this.transformAs(value, prop);
      }
    } else {
      value = this.transformAs(value, type);
    }

    return value;
  }
}


@NgModule({
  imports: [],
  declarations: [StringPipe],
  exports: [StringPipe]
})
export class StringPipesModule { }
