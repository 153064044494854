import {AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, OnDestroy} from "@angular/core";

@Directive({
    selector: "[rs-scroll], [rsScroll]",
})
export class RsScroll implements AfterContentInit, OnDestroy {
    @Input("lock-y-offset") public lockYOffset: number = 0;
    @Input("observe-attributes") public observeAttributes: string = "false";

    @Output() scrolled = new EventEmitter<number>();
    @Output() scrolledUp = new EventEmitter<number>();

    private nativeElement: HTMLElement;
    private _isLocked: boolean = false;
    private mutationObserver: MutationObserver;

    private previousScroll : number = 0;

    constructor(element: ElementRef) {
        this.nativeElement = element.nativeElement;
    }

    public getObserveAttributes(): boolean {
        return this.observeAttributes !== "" && this.observeAttributes.toLowerCase() !== "false";
    }

    public ngAfterContentInit(): void {
        // setTimeout(() => {
        //     this.forceScrollUp();
        // }, 10);

        // this.mutationObserver = new MutationObserver(() => {
        //     if (!this._isLocked) {
        //         this.scrollDown();
        //     }
        // });
        // this.mutationObserver.observe(this.nativeElement, {
        //     childList: true,
        //     subtree: true,
        //     attributes: this.getObserveAttributes(),
        // });
    }

    public ngOnDestroy(): void {
        // this.mutationObserver.disconnect();
    }

    public forceScrollUp(): void {
        this.nativeElement.scrollTop = 0;
        this.previousScroll = 0;

        this.scrolledUp.emit(0);
    }

    public isLocked(): boolean {
      return this._isLocked;
    }

    // private scrollDown(): void {
    //     console.log(['0000000000000000000000000000000 his.nativeElement.scrollHeight', this.nativeElement.scrollHeight]);
    //     // this.nativeElement.scrollTop = this.nativeElement.scrollHeight;
    // }


    @HostListener("scroll")
    private scrollHandler(): void {
        let currentScroll : number = this.nativeElement.scrollTop;
        // console.log(['currentScroll = ', currentScroll, this.nativeElement.scrollHeight, this.nativeElement.clientHeight]);
        if (currentScroll <= this.lockYOffset) {
            this.scrolledUp.emit(currentScroll);
        } else if (currentScroll > 0 /*&& currentScroll < this.nativeElement.scrollHeight - this.nativeElement.clientHeight*/) {
            if (currentScroll > this.previousScroll){
                this.scrolled.emit(currentScroll);
            } else {
                this.scrolledUp.emit(currentScroll);
            }
            this.previousScroll = currentScroll;
        }

        // const scrollFromBottom = this.nativeElement.scrollHeight - this.nativeElement.scrollTop - this.nativeElement.clientHeight;
        // this._isLocked = scrollFromBottom > this.lockYOffset;
        // console.log('scrollFromBottom = ' + scrollFromBottom);
    }
}