import { NgModule } from '@angular/core';
import { MgcDividerComponent, MgcDividerAbsoluteDirective } from './divider.component';

@NgModule({
    imports: [

    ],
    declarations: [
        MgcDividerComponent,
        MgcDividerAbsoluteDirective,
    ],

    exports: [
        MgcDividerComponent,
        MgcDividerAbsoluteDirective,
    ]
})
export class MgcDividerModule { }