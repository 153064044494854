import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
  Input,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: 'textarea[autosize]',
  host: {
    'style': 'overflow: hidden'
  }
})
export class AutosizeDirective implements AfterViewInit, OnChanges {

  @Input('autosize-text') text: string = '';
  @Input('autosize') autosize: string = 'default';
  @Input('startRows') startRows: number = 7;

  @HostBinding('attr.rows') get rows() { return this.startRows; }

  private oldText: any = null;

  constructor(
    private elem: ElementRef
  ) { }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.resize();
    }, 20)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.autosize === 'readonly' && changes['text'] !== this.oldText) {
      this.oldText = changes['text'];
      setTimeout(() => {
        this.resize();
      }, 20)
    }
  }

  @HostListener('input')
  private resize() {
    const textarea = this.elem.nativeElement as HTMLTextAreaElement;
    // Reset textarea height to auto that correctly calculate the new height
    textarea.style.height = 'auto';
    // Set new height
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}