import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MultiGalleryComponent, IMultiGalleryOptions } from "@libraries/multi-gallery";
import { CustomImageComponent } from "./custom-image.component";
import { ICustomImageData } from "./custom-image-data.interface";
import { ICustomImageOptions } from "../../theme-options.interface";

const REFs = {
    MultiGalleryComponent: 'MultiGalleyComponent',
    CustomImageComponent: 'CustomImageComponent'
}

@Component({
    selector: 'custom-image-modal',
    templateUrl: './custom-image.modal.html',
})
export class CustomImageModal implements OnInit {
    @ViewChild(MultiGalleryComponent) multiGalleryComponent: MultiGalleryComponent;
    @ViewChild(CustomImageComponent) customImageComponent: CustomImageComponent;

    refInView: string;
    get REFs() {
        return REFs;
    }

    imageQuality: string = 'medium';

    customImageOptions: ICustomImageOptions;
    galleryOptions: IMultiGalleryOptions;
    selectedItems: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<CustomImageModal>,
        @Inject(MAT_DIALOG_DATA) data: ICustomImageData
    ) {
        this.customImageOptions = data.customImageOptions || {};
        this.galleryOptions = data.galleryOptions;

        this.updateImageId(this.customImageOptions.id || '__default');
    }

    closeDialog(): void {
        switch (true) {
            case !!this.customImageComponent:
                this.dialogRef.close();
                break;
            case !!this.multiGalleryComponent:
                this.refInView = REFs.CustomImageComponent;
                break;
        }
    }

    onProcess() {
        switch (true) {
            case !!this.customImageComponent:
                this.dialogRef.close(this.customImageComponent.updatedOptions());
                break;
            case !!this.multiGalleryComponent:
                this.updateImageId((this.multiGalleryComponent.selectedFiles.pop()).id);
                this.refInView = REFs.CustomImageComponent;
                break;
        }
    }

    updateImageId(id: string) {
        this.customImageOptions.id = id;
        this.selectedItems = [{ id: this.customImageOptions.id }];
    }

    isValid: boolean = false;
    onSubmitDisabled(value) {
        this.isValid = value;
    }

    openMultiGallery() {
        this.refInView = REFs.MultiGalleryComponent;
    }

    ngOnInit() {
        this.refInView = REFs.CustomImageComponent;
    }

}


    // onThemeOptionsUpdate(value) {
    //     this.themeImage = value;
    //     if (this.themeImage && this.themeImage.id) {
    //         this.selectedItems = [{ id: this.themeImage.id }]
    //     } else {
    //         this.selectedItems = [];
    //     }
    // }
