<mgc-dialog>

  <mgc-dialog-header title="Reactions" (close)="closeDialog()"></mgc-dialog-header>

  <div class="mgc-tabs">
    <div #mgcTab class="mgc-tab" [style.width.px]="tabWidth" [class.active]="tabActive()" (click)="activateTab()"
      [innerHTML]="'all ' + reactions.length"></div>

    <div #mgcTab *ngFor="let rd of groupedReactions" class="mgc-tab" [class.active]="tabActive(rd.type)"
      [style.width.px]="tabWidth" (click)="activateTab(rd.type)" [title]="rd.type">
      <mgc-icon-button class="reaction-icon {{rd.type}}" icon="board-mat:{{rd.type}}"></mgc-icon-button>
      <span [innerHTML]="rd.value"></span>
    </div>

    <div class="mgc-link-bar" [style.width.px]="tabWidth" [style.left.px]="leftPosition"></div>
  </div>

  <!-- <mgc-dialog-content> -->
  <reactions-data class="mgc-scroll-area" [item]="item" [reactionType]="tab" (close)="closeDialog()">
  </reactions-data>
  <!-- </mgc-dialog-content> -->

</mgc-dialog>