import { Pipe, PipeTransform, Inject, NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Project } from '@board/items/project/project';
import { TOOLS_PAGES } from '@pages/pages.enum';

@Pipe({ name: 'blobUrl' })
export class BlobUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(file: any): SafeResourceUrl {
    let url = URL.createObjectURL(file.rawFile);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: 'projectUrl' })
export class ProjectUrlPipe implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  transform(project: Project): string {
    return `${this.config.protocol}://${this.config.domain}${TOOLS_PAGES.BOARD.path}?p=${project.Id}`;
  }
}

@NgModule({
  declarations: [
    BlobUrlPipe,
    ProjectUrlPipe
  ],
  exports: [
    BlobUrlPipe,
    ProjectUrlPipe
  ]
})
export class UrlPipesModule { }