import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Modal } from '../modal.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@rallysite/components/alert';
import { finalize , take} from 'rxjs/operators';
import { ModalFactoryService } from '@board/modals/modals-editor/modal-factory/modal-factory.service';
import {ConfirmOverlayService} from '@board-components/confirm-overlay/confirm-overlay.service';
import {ModalListService} from '@board/modals/modals-editor/modal-list.service';

interface Tab {
  key: string;
  leftOffsetPx: number;
  width: number;
}

@Component({
  selector: 'modal-factory',
  templateUrl: './modal-factory.component.html',
  styleUrls: ['./modal-factory.component.scss']
})
export class ModalFactoryComponent implements OnInit, OnChanges {
  @Input() modal: Modal = null;
  @Output() modalDeleted: EventEmitter<void> = new EventEmitter<void>(null);

  isModalNew = false;

  currentTab: Tab;
  tabs: Tab[] = [
    {key: 'logic', leftOffsetPx: 0, width: 100},
    {key: 'content', leftOffsetPx: 100, width: 100},
    {key: 'preview', leftOffsetPx: 200, width: 100},
  ];

  showNotification = false;
  processing = false;
  submitted = false;

  form: FormGroup;
  get f() { return this.form.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private modalFactoryService: ModalFactoryService,
    private alertService: AlertService,
    private confirmOverlay: ConfirmOverlayService,
    private modalListService: ModalListService,
  ) { }

  bootstrapForm() {
    this.form = this.formBuilder.group({
      InternalName: new FormControl(this.modal.InternalName, [
        Validators.required,
      ]),
      InternalDescription: new FormControl(this.modal.InternalDescription, [
        Validators.required,
      ]),
      IsEnabled: new FormControl(this.modal.IsEnabled, [
        Validators.required,
      ]),
      Title: new FormControl(this.modal.Title, [
        Validators.required,
      ]),
      Description: new FormControl(this.modal.Description, [
        Validators.required,
      ]),
    });

    this.form.valueChanges.subscribe(form => {
      Object.assign(this.modal, form);
    });
  }

  submit() {
    this.submitted = true;
    return !this.form.invalid;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modal'] && changes['modal'].currentValue) {
      this.currentTab = this.tabs[0];

      this.isModalNew = `${changes['modal'].currentValue.Id}`.startsWith('new');

      if (changes['modal'].previousValue && changes['modal'].currentValue.Id != changes['modal'].previousValue.Id) {
        this.bootstrapForm();
      }
    }
  }

  onSave() {
    if (!this.submit()) {
      return;
    }

    if (this.processing) {
      return;
    }

    this.processing = true;
    this.alertService.clear();

    this.modalFactoryService.saveModal(this.modal)
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe(result => {
        this.showNotification = true;
        this.processing = false;

        if (this.isModalNew) {
          this.isModalNew = false;
        }

        if (!result) {
          this.alertService.error('Woops! Error. Something went wrong.');
        } else {
          this.alertService.success('Successfully saved.');
        }

        setTimeout(() => this.showNotification = false, 1000 * 3);
      });
  }

  ngOnInit() {
    this.currentTab = this.tabs[0];
    this.bootstrapForm();
  }

  deleteModal(event) {
    this.confirmOverlay.open(event.currentTarget, 'Delete Item! You sure?').afterClosed()
      .subscribe((confirmed) => {
        if (!confirmed) {
          return;
        }

        this.modalListService.removeModal(this.modal).subscribe(() => {
          this.modalDeleted.emit();
        });
      });
  }
}
