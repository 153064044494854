export interface IUserContactDetail {
    id: string;
    type: string;
    name: string;
    value: string;
    _checked?: boolean;

    isUserDetail?: boolean;
}

export class ContactDetail implements IUserContactDetail {
    id: string;
    type: string;
    name: string;
    value: string;
    _checked?: boolean;

    isUserDetail?: boolean = false;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }

        this.isUserDetail = this.id.split('.').length > 1;
    }
}