import {Component, OnInit} from "@angular/core";
import {GlobalModalsService, ModalWithActivity} from "./global-modals.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "@rallysite/auth-service";
import {takeWhile} from "rxjs/operators";
import {MissedModalComponent} from "./modal/missed/missed-modal.component";
import {AssessmentModalComponent} from "./modal/assessment/assessment-modal.component";
import {ComponentType} from "@angular/cdk/overlay";
import {StreakModalComponent} from "./modal/streak/streak-modal.component";

@Component({
  selector: 'global-modals',
  templateUrl: 'global-modals.component.html',
  providers: [
    AssessmentModalComponent,
    MissedModalComponent,
  ],
})
export class GlobalModalsComponent implements OnInit {

  activeDialog: MatDialogRef<AssessmentModalComponent | MissedModalComponent | StreakModalComponent> | null = null;

  constructor(
    private modalsService: GlobalModalsService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.user$.pipe(takeWhile(() => true)).subscribe((user) => {
      if (user) {
        this.modalsService.openMyModal();
      } else {
        this.modalsService.resetMyModals();
      }
    });

    this.modalsService.currentModalSubscription.subscribe((modalWithActivity) => {
      if (!modalWithActivity || !modalWithActivity.modal) {
        return;
      }

      // In case we already opened dialog - skip opening again
      if (this.activeDialog !== null) {
        return;
      }

      this.openModal(modalWithActivity);
    });
  }

  openModal(modalWithActivity: ModalWithActivity) {
    this.dialog.closeAll();

    this.modalsService.trackEvent(modalWithActivity.modal.Id, 'displayed').subscribe();

    const modalComponent = (() => {
      switch (modalWithActivity.modal.ModalType) {
        case 'assessment':
          return AssessmentModalComponent;

        case 'missed':
          return MissedModalComponent;

        case 'streak':
          return StreakModalComponent;
      }
    })() as unknown as ComponentType<AssessmentModalComponent | MissedModalComponent | StreakModalComponent>;

    this.activeDialog = this.dialog.open(modalComponent, {
      data: modalWithActivity,
    });

    this.activeDialog.backdropClick().subscribe(() => {
      if (modalWithActivity.modal.Config.enableClosingModal) {
        this.activeDialog.close();
      }
    })

    this.activeDialog.afterClosed().subscribe(() => {
      this.modalsService.trackEvent(modalWithActivity.modal.Id, 'closed').subscribe();
      this.modalsService.modalBeenClosed(modalWithActivity.modal);
      this.activeDialog = null;
    });
  }
}
