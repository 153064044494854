import { trigger, state, animate, transition, style } from '@angular/animations';

export const slideInOutAnimationVertical =
    trigger('slideInOutAnimationVertical', [
        state('open', style({
            // transform: 'translate3d(0, 0, 0)',
            // visibility: 'visible',
            height: '*',
            overflow: 'hidden'
            // position: 'relative',
            // top: 0,
        })),
        state('close',   style({
            // position: 'relative',
            // top: '-100%',
            // transform: 'translate3d(0, -100%, 0)',
            height: 0,
            overflow: 'hidden'
            // visibility: 'hidden'
        })),
        transition('close => open', animate('150ms ease-in')),
        transition('open => close', animate('150ms ease-out'))
    ]);

export const slideInOutAnimationHorizontal =
    trigger('slideInOutAnimationHorizontal', [
        state('open', style({
            transform: 'translate3d(0, 0, 0)',
            visibility: 'visible',
            // width: '*',
            // overflow: 'inherit'
            position: 'relative',
            top: 0,
        })),
        state('close',   style({
            position: 'relative',
            top: '-100%',
            transform: 'translate3d(0, -100%, 0)',
            // width: 0,
            // overflow: 'hidden'
            visibility: 'hidden'
        })),
        transition('close => open', animate('150ms ease-in')),
        transition('open => close', animate('150ms ease-out'))
    ]);