import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SurveyModalComponent } from './survey-modal.component';

@Directive({
  selector: '[survey-modal-trigger]'
})
export class SurveyModalTriggerDirective {

  @HostListener('click', ['$event.target'])
  onClick() {
    this.engage();
  }

  constructor(
    private dialog: MatDialog,
  ) {
  }

  protected engage() {
    this.open(SurveyModalComponent);
  }

  protected open(component){
    this.dialog.open(component, {
      maxWidth: '500px',
      minHeight: '60vh',
      height: '90vh'
    }).afterClosed().pipe(
      take(1))
      .subscribe(() => {
        //
      })
  }

}
