
import { switchMap, map, finalize } from 'rxjs/operators';
import {
  Component, Input, OnInit, OnDestroy,
  HostBinding, Output, EventEmitter, SimpleChanges, ElementRef, ViewChild, HostListener, AfterViewInit
} from '@angular/core';

import { IServiceStates, ServiceStates } from '@rallysite/global-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ReactionTypes } from '@reactions/reactions.enum';
import { ReactionsService } from '@reactions/reactions.service';
import { BehaviorSubject } from 'rxjs';
import { Note } from '@panel-components/notes';
import { Comment } from '@panel-components/comments';
import { ReactionData } from './reaction-data.model';

@Component({
  selector: 'reactions-data',
  templateUrl: './reactions-data.component.html',
  styleUrls: ['./reactions-data.component.scss'],
})
export class ReactionsDataComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.mgc-reactions-data') reactionsDataClass = true;

  @HostListener('scroll', ['$event']) triggerCycle(event: any): void {
    this.lazyLoading()
  }

  @Input() reactionType: ReactionTypes = null; // null means all reaction types
  @Input() item: Note | Comment;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('reactionsContainer') reactionsContainerRef: ElementRef;

  private alive: boolean = true;
  state: number;
  reactions: ReactionData[];
  reactionsReady: boolean = false;

  _reactionType: BehaviorSubject<ReactionTypes> = new BehaviorSubject<ReactionTypes>(null);
  get reactionType$() {
    return this._reactionType.asObservable();
  }

  private offset: number = 0;
  private limit: number = 10;
  readonly initialLoad: number = 10;

  loadingMore: boolean = false;
  moreToLoad: boolean = false;

  constructor(
    private reactionsService: ReactionsService,
    public el: ElementRef,
    public dialog: MatDialog,
  ) {
  }

  get totalReactions() {
    return this.reactionsService.total;
  }

  trackByFn(index: number, reaction: ReactionData): string {
    return reaction.Id;
  }


  get states(): IServiceStates {
    return ServiceStates.STATES;
  }

  private getReactions(type: ReactionTypes) {
    if (type) {

    }
  }



  private getContainerHeight() {
    if (this.el.nativeElement.clientHeight === 0) {
      // for IE11
      return this.el.nativeElement.scrollHeight;
    }
    return this.el.nativeElement.clientHeight;
  }

  private lazyLoading() {
    if (!this.reactionsContainerRef) {
      return;
    }
    let containerHeight = this.getContainerHeight();
    let contentHeight = this.reactionsContainerRef.nativeElement.offsetHeight;
    let scrolled = this.el.nativeElement.scrollTop;

    if (contentHeight - scrolled <= 2 * containerHeight) {
      this.loadMore();
    }
  }

  loadMore() {
    if (this.loadingMore) {
      return;
    }

    this.loadingMore = true;
    this.offset = this.reactions.length;

    this.loadReactions(this.offset).pipe(
      finalize(() => {
        this.state = this.states.DONE;
        this.reactionsReady = true;
        this.loadingMore = false;
      }))
      .subscribe(reactions => {
        this.render(reactions);
      })
  }

  options: any;
  private loadReactions(offset: number = 0) {

    this.options = {
      item: this.item,
      type: this.reactionType,
      limit: (offset === 0 ? this.initialLoad || this.limit : this.limit),
      offset: offset
    };

    this.state = this.states.LOADING;
    return this.reactionsService.getReactions(this.options).pipe(
      map(reactions => {
        return reactions;
      })
    );
  }

  render(reactions: ReactionData[]) {
    if (!reactions) {
      return
    }

    this.reactions = reactions;
    this.moreToLoad = this.reactions.length < this.totalReactions;

    if (this.moreToLoad) {
      // just in case initialy not enouth records was loaded and the scroll isn't triggered
      // give some time to render loaded data
      setTimeout(() => {
        this.lazyLoading();
      }, 500);
    }
  }

  private bootstrapReactions() {
    this.reactionType$.pipe(
      switchMap(rType => {
        return this.loadReactions();
      })
    ).subscribe(reactions => {
      this.state = this.states.DONE;
      this.reactionsReady = true;

      this.render(reactions);
    })
  }

  ngOnInit() {
    this.bootstrapReactions();
  }

  debugMe: boolean = false;
  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.debugMe = true;
    // }, 100)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['reactionType'] && !change['reactionType'].firstChange) {

      // force scroll top
      this.el.nativeElement.scrollTop = 0;
      this.reactionsReady = false;

      this._reactionType.next(this.reactionType);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
