import { Theme } from "../theme";
import { ITheme } from "../theme.interface";

import { ACCENTS } from "./accent.class";
import { ICustomImageOptions } from "../theme-options.interface";

export const DEFAULT_IMAGE_THEME: ICustomImageOptions = {
    id: '__default',
    accent: ACCENTS.warm.id
  };

export class CustomImageTheme extends Theme {
    id: string = 'custom_image';
    name: string = 'Custom Image';

    imageOptions: ICustomImageOptions;
    get imageId() {
        return !this.imageOptions.id ? '__default' : this.imageOptions.id;
    }

    constructor(data: ITheme, imageOptions: ICustomImageOptions = DEFAULT_IMAGE_THEME) {
        super({ ...data, ...{ primaryColor: ACCENTS[(imageOptions || DEFAULT_IMAGE_THEME).accent].color } }, 'dark');
        this.imageOptions = imageOptions;
    }

}