import { Component, Input } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Project } from '../../project';

@Component({
  selector: 'signature-settings',
  templateUrl: './signature-settings.component.html',
  styles: [`
    :host{
      display: block;
    }
  `]
})
export class SignatureSettingsComponent {

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  // convenience getter for easy access to form fields
  get f() { return this.settingsForm.controls; }
  get isDirty() {
    return this.settingsForm.dirty;
  }

  @Input() project: Project;

  processing = false;
  allowUpdate = false;


  /** FORM */
  settingsForm: FormGroup;

  submitted = false;

  bootstrapForm() {
    this.settingsForm = this.formBuilder.group({
      eMessageCustomUrlEnabled: this.project.Settings.eMessage.customUrl.enabled,
      eMessageCustomUrlLabel: this.project.Settings.eMessage.customUrl.label,
      eMessageCustomUrlValue: this.project.Settings.eMessage.customUrl.value,
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.settingsForm.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  makeFormDirty() {
    if (this.settingsForm) {
      this.settingsForm.markAsDirty();
    }
  }

  markFormPristine() {
    if (this.settingsForm) {
      this.settingsForm.markAsPristine();
    }
  }

  submitForm() {
    if (this.settingsForm.invalid) {
      return false;
    }

    return true;
  }

  formValues() {
    return {
      Settings: {
        eMessage: {
          customUrl: {
            enabled: this.settingsForm.value['eMessageCustomUrlEnabled'],
            value: this.settingsForm.value['eMessageCustomUrlValue'],
            label: this.settingsForm.value['eMessageCustomUrlLabel']
          },
        }
      }
    };
  }

  toggleMessageCustomUrl(matSlideToggle: MatSlideToggleChange) {
    this.settingsForm.patchValue({
      eMessageCustomUrlEnabled: matSlideToggle.checked
    });
  }

  ngOnInit() {
    this.bootstrapForm();
  }

}
