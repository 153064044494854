import { Pipe, PipeTransform, NgModule, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transformAs(value, type) {
    value || (value = '')
    switch (type) {
      case 'linky': return this.linky(value);
      case 'lineBreak': return this.lineBreak(value);
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  private linky(value: string) {
    return (value + ' ').replace(/((?:(?:ht|f)tps?:\/\/|www)[^<>\]]+?(?![^<>\]]*([>]|<\/))(?=[\s!<\]]))/g, '<a href="$1" target="_blank">$1</a>');
  }

  private lineBreak(value: string) {
    return value.replace(/\r?\n/g, '<br/>') + '<br/>';
  }

  public transform(value: any, type: string | Array<string>): string | SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {

    if (type instanceof Array) {
      for (let i = 0; i < type.length; i++) {
        let prop = type[i];
        value = this.transformAs(value || '', prop);
      }
    } else {
      value = this.transformAs(value || '', type);
    }

    return value;
  }
}

@Pipe({ name: 'trimEmail' })
export class TrimEmailPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(value: string): SafeHtml {
    if (!value) {
      return '';
    }
    let newValue = value.split('@')[0]
    return newValue;
  }
}

@NgModule({
  imports: [],
  declarations: [SafePipe, TrimEmailPipe],
  exports: [SafePipe, TrimEmailPipe],
})

export class SafePipesModule { }
