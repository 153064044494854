
import { takeWhile, filter, take, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Note } from '../note';
import { NoteService } from '../note.service';
import { FileService } from '../attachments/file.service';
import { AttachmentsComponent } from '../attachments';

import { UploaderComponent, IUploaderOptions } from '@libraries/uploader';
import { Task } from '@board/items/task';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { AuthService } from '@rallysite/auth-service';
import { CurrentIdService, PermissionService } from '@board/_services';
import { ConfirmOverlayService } from '@board/_components/confirm-overlay/confirm-overlay.service';
import { WithAttachmentsComponent } from '../with-attachments/with-attachments.component';
import { JoditComponent } from '@libraries/jodit/jodit.component';
import { EmbedPipe } from 'app/_pipes/embed.pipe';
import { ScheduledTask } from '@board/items/scheduled-task/scheduled-task';
import { Participant } from '@panel-components/participants';
import { TOOLS_PAGES } from '@pages/pages.enum';

const buttons = TOOLS_PAGES.BOARD_VIDEO.disabled ? [
  'bold', 'italic', 'underline', '|', 'font', 'fontsize', 'brush', '|', 'link', 'vimeo', 'youtube', 'facebook', 'tiktok', 'joinme', 'zoom', 'survey', '|', 'fullsize', '|', 'dots'
] : [
  'bold', 'italic', 'underline', '|', 'font', 'fontsize', 'brush', '|', 'link', 'player', 'vimeo', 'youtube', 'facebook', 'tiktok', 'joinme', 'zoom', 'survey', '|', 'fullsize', '|', 'dots'
];

@Component({
  templateUrl: './note-modal.html',
  styleUrls: ['./note-modal.scss'],
})
export class NoteModal extends WithAttachmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(UploaderComponent) uploader: UploaderComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  @ViewChild('textarea') textarea: ElementRef;

  @ViewChild(JoditComponent) joditEditor: JoditComponent;

  uploaderOptions: IUploaderOptions;

  private alive: boolean = true;
  task: Task | ScheduledTask;
  note: Note;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  joditConfig = {
    buttons: buttons.concat(['source', 'eraser', 'strikethrough', 'align', 'paragraph', 'image', 'superscript', 'subscript', 'ul', 'ol', 'hr', 'symbol', 'table']),
    buttonsLG: buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    colorPickerDefaultTab: 'color',
    minHeight: 200,
    height: '45vh',
    toolbarButtonSize: "middle"
  };

  private ownerAccountId: string;

  constructor(
    protected noteService: NoteService,
    fileService: FileService,
    private authService: AuthService,
    confirmOverlay: ConfirmOverlayService,
    private currentIdService: CurrentIdService,
    public dialogRef: MatDialogRef<NoteModal>,
    permissionsSettings: PermissionService,
    private embed: EmbedPipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(noteService, fileService, confirmOverlay, permissionsSettings);
    this.model = this.note = <Note>data.note;
    this.task = data.task;
    this.ownerAccountId = this.model.AccountId;
  }

  postAs(admin: Participant) {
    if (admin && admin.Id != this.ownerAccountId) {
      this.note.AccountId = admin.Id;
      this.note.Settings.ownerAccountId = this.ownerAccountId;
    }
  }

  /** overridden methods */

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkUploaderOptions() {
    // update uploader options for NoteId, when note is new created
    if (!this.uploaderOptions.payload.NoteId) {
      this.uploaderOptions.payload.NoteId = this.note.Id;
    }
  }

  saveRequest(): Observable<any> {
    let request: Observable<any>;

    this.note.Text = this.joditEditor.value;
    this.note.Wysiwyg = this.joditEditor.value.replace(/\r?\n/g, '');
    if (!this.note.Id) {
      request = this.noteService.create(this.note);
    } else {
      request = this.noteService.update(this.note);
    }

    return request;
  }

  saveDone(note: Note) {
    this.note = note;
  }

  /** END overridden methods */


  onDelete(event) {
    if (!this.permissionsSettings.allowsAction('delete.note', this.note, { message: 'Not allowed to delete this note' })) {
      return false;
    }

    this.confirmOverlay.open(event.target).afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.state = this.states.PROCESSING;
          this.noteService.remove(this.note).pipe(
            finalize(() => {
              this.state = this.states.DONE;
            }),
            take(1)).subscribe(success => {
              if (success) {
                this.dialogRef.close();
              }
            });
        }
      });
  }

  /**
   * END OF UPLOADER METHODS
   */

  protected bootstrap() {
    // create a shallow copy of note attachments
    // on cancel, the note._attachments will not contain the new added ones

    this.modelText = this.note.Text;

    this.fileService.getFiles$(this.note).pipe(
      filter(files => {
        return !!files;
      }),
      takeWhile(() => this.alive))
      .subscribe(files => {
        this.attachments = files.slice();
      });
    this.fileService.pushFiles(this.note._attachments, this.note);


    this.uploaderOptions = {
      autoUpload: false,
      filename: 'file',
      tokenGetter: this.authService.tokenGetter,
      endPoint: this.fileService.endPoint,
      payload: {
        NoteId: this.note.Id,
        TaskId: this.note.TaskId,
        AccountId: this.currentIdService.currentAccountId
      }
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewInit() {
    // this.textarea.nativeElement.focus();
    if (this.joditEditor) {

      this.joditEditor.value = this.note.Wysiwyg ? this.note.Wysiwyg : this.embed.transform(this.modelText).toString();
    }
  }

}
