<mgc-loader *ngIf="loading"></mgc-loader>

<div class="mgc-note" *ngIf="ready && blocksMetadata.blocks.length">

    <div class="inner">
        <div class="note-header">
            <div class="left mgc-account">
                <account-avatar size="40" [account]="blocksMetadata.account"></account-avatar>
                <span class="info">
                    <span class="name" [innerHTML]="blocksMetadata.account.OwnerFirstName"></span>
                    <span class="details">
                        <span>{{ createDate| amFromUtc | amTz | amDateFormat:'MMM D, YYYY h:mm a z' }}
                        </span>
                        <span *ngIf="updateDate && createDate!=updateDate">Last Updated:
                            {{ updateDate | amFromUtc | amLocal | amTimeAgo }}</span>
                    </span>
                </span>
            </div>
            <span class="right">
                <mgc-icon-button style="cursor: pointer;" class="w30" icon="board-mat:mode_edit" (click)="onEdit()">Edit
                </mgc-icon-button>
            </span>
        </div>

        <div class="note-body">
            <ng-container *ngFor="let block of blocksMetadata.blocks">
                <ng-container [ngSwitch]="block.Type">
                    <task-v2-text-block *ngSwitchCase="blockType.TEXT" preview [block]="block">
                    </task-v2-text-block>
                    <task-v2-image-block *ngSwitchCase="blockType.IMAGE" preview [block]="block">
                    </task-v2-image-block>
                    <task-v2-video-block *ngSwitchCase="blockType.VIDEO" preview [block]="block" [task]="task">
                    </task-v2-video-block>
                    <task-v2-survey-block *ngSwitchCase="blockType.SURVEY" preview [block]="block">
                    </task-v2-survey-block>
                    <task-v2-attachments-block *ngSwitchCase="blockType.ATTACHMENTS" preview [block]="block">
                    </task-v2-attachments-block>
                </ng-container>
            </ng-container>
        </div>

        <div class="summary">
            <div class="reactions" *ngIf="reactionsData.length" (click)="openReactionsData($event)">
                <mgc-icon-button class="reaction-icon {{rd.type}}" *ngFor="let rd of reactionsData"
                    icon="board-mat:{{rd.type}}">
                </mgc-icon-button>
                <span class="total-reactions">{{blocksMetadata.reactions.length}}</span>
            </div>

            <div class="comments" *ngIf="blocksMetadata.nComments">
                <span class="with-pipe" *ngIf="reactionsData.length"></span>
                <span class="toggle" (click)="commentsToggle($event)" [innerHTML]="commentsLabel"></span>
            </div>

            <!-- <div *ngIf="shareEnabled && totalShares">
                <span class="with-pipe" *ngIf="reactionsData.length || blocksMetadata.nComments"></span>
                <span>{{totalShares | number}} {{ totalShares == 1 ? ' share' : ' shares'}}</span>
        </div> -->

        </div>

        <mgc-divider [full-width]="true" *ngIf="!isSnapshotTask"></mgc-divider>
        <div class="note-footer" *ngIf="!isSnapshotTask">
            <div class="left">
              <div class='mgc-icon-button-wrapper' (click)="openReactions($event)">
                <mgc-icon-button
                  style='color: #4367b2'
                  [class]="reactionClass"
                  [icon]="'board-mat:'+ (currentReaction ? currentReaction.Type : 'like')"
                >
                </mgc-icon-button>
                <span class='icon-title'>{{currentReaction ? currentReaction.Type : 'like'}}</span>
              </div>

              <div *ngIf="shareEnabled" class='mgc-icon-button-wrapper' (click)="engageShare($event)">
                <mgc-icon-button
                  [class]="shareClass"
                  icon="board-mat:like"
                >
                </mgc-icon-button>
                <span class='icon-title'>Share</span>
              </div>

                <!-- <mgc-icon-button *ngIf="allowEmail" icon="board-mat:email" (click)="openEmailModal()">
                    Email
                </mgc-icon-button> -->
              <div *ngIf="allowComment" class='mgc-icon-button-wrapper' (click)="openCommentModal()">
                <mgc-icon-button icon="board-mat:comment"></mgc-icon-button>
                <span class='icon-title'>Comment</span>
              </div>
            </div>
        </div>
    </div>

    <mgc-comment-list
      *ngIf="blocksMetadata.showComments && blocksMetadata.nComments"
      [taskv2BlocksMetadata]="blocksMetadata"
      (onCommentRemoved)="commentRemoved()"
    >
    </mgc-comment-list>

</div>
