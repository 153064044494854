import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Project } from '@board/items/project/project';
import { CSVEmailComponent } from '@libraries/csv-reader/csv-email.component';
import { ParticipantService } from '@panel-components/participants/participant.service';
import { Participant } from '@panel-components/participants/participant';
import { take } from 'rxjs/operators';
import { AccountService } from '@board/accounts';

@Component({
  selector: 'new-participant-list',
  templateUrl: './new-participant-list.component.html',
  styleUrls: ['./new-participant-list.component.scss']
})
export class NewParticipantListComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private accountService: AccountService,
  ) {
  }

  get disabled() {
    return !this.csvEmail || (this.csvEmail &&
      (this.csvEmail.records.valid.length === 0 ||
        this.csvEmail.records.invalid.length > 0 ||
        this.csvEmail.submited)
    );
  }

  get processing() {
    return this.csvEmail && this.csvEmail.processing;
  }
  @Input() project: Project;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(CSVEmailComponent) csvEmail: CSVEmailComponent;

  private alive = true;
  state: number;

  coOwners: Participant[] = [];

  senderAccount: any = {};

  onSubmit() {
    if (!this.csvEmail) {
      return;
    }

    this.csvEmail.upload();
  }

  onCancel(): void {
    this.close.next('cancel');
  }
  selectSender(sender) {
    this.senderAccount = (sender || {})._account;
    this.csvEmail.updateSender(this.senderAccount.Id);
  }


  ngOnInit() {
    this.participantService.loadCoOwners(this.project).pipe(take(1))
      .subscribe(data => {
        this.coOwners = data;
          this.selectSender(this.coOwners.find(p => {
            return p.Email.toLowerCase() === this.accountService.currentAccount.Email.toLowerCase();
          }));
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
