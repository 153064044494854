import { Injectable, Injector, ComponentRef, Inject } from '@angular/core';
import { OverlayRef, Overlay, OverlayConfig } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BoardViewSettingsComponent } from './boardview-settings.component';
import { BOARDVIEW_OVERLAY_DATA } from './boardview-settings.tokens';
import { BoardViewSettingsOverlayConfig, DEFAULT_CONFIG } from './boardview-settings.config';
import { BoardViewSettingsOverlayRef } from './boardview-settings-overlay-ref';
import { IMultiGalleryCategory, IMultiGalleryOptions } from '@libraries/multi-gallery';
import { AuthService } from '@rallysite/auth-service';
import { AccountService } from '@board-accounts/index';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

@Injectable()
export class BoardViewSettingsOverlayService {

  config: BoardViewSettingsOverlayConfig;

  // Inject overlay service
  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private accountService: AccountService,
    private authService: AuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
  }

  defaultConfig(): BoardViewSettingsOverlayConfig {
    return {
      data: {
        accountSettings: this.accountService.currentAccount.Settings,
        galleryOptions: this.accountGalleryOptions
      }
    }
  }

  open(origin: HTMLElement, config: BoardViewSettingsOverlayConfig = null) {
    config || (config = this.defaultConfig());

    // Returns an OverlayRef (which is a PortalHost)
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayRef = this.createOverlay(dialogConfig, origin);

    // Instantiate remote control
    const dialogRef = new BoardViewSettingsOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    // Subscribe to a stream that emits when the backdrop was clicked
    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: BoardViewSettingsOverlayConfig, origin: HTMLElement) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config, origin);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }


  private attachDialogContainer(overlayRef: OverlayRef, config: BoardViewSettingsOverlayConfig, dialogRef: BoardViewSettingsOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(BoardViewSettingsComponent, null, injector);
    const containerRef: ComponentRef<BoardViewSettingsComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: BoardViewSettingsOverlayConfig, dialogRef: BoardViewSettingsOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(BoardViewSettingsOverlayRef, dialogRef);
    injectionTokens.set(BOARDVIEW_OVERLAY_DATA, config.data);

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }


  private getOverlayConfig(config: BoardViewSettingsOverlayConfig, origin: HTMLElement): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 5,
        }
      ]);

    const overlayConfig = new OverlayConfig({
      width: '150px',
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      // Other strategies are .noop(), .reposition(), or .close()
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy
    });

    return overlayConfig;
  }


  get accountGalleryOptions(): IMultiGalleryOptions {
    return {
      multiSelect: false,
      tokenGetter: this.authService.tokenGetter,
      categories: [
        <IMultiGalleryCategory>{
          id: 'personal',
          text: 'My Images',
          filename: 'figure',
          endPoint: `${this.appConfig.endpoint}/api/figures`,
          payload: {
            // its not a must
            AccountId: this.accountService.currentAccount.Id
          },
        },
        <IMultiGalleryCategory>{
          id: 'stock',
          text: 'Stock Images',
          filename: 'figure',
          endPoint: `${this.appConfig.endpoint}/api/figures`,
          payload: {
            Ownership: 'platform'
          },
          allowPOST: false
        }
      ]
    }
  }


}
