<ng-container *ngIf="!preview">
  <div class="media-block-drag  flx-clm">
    <mgc-icon-button class="click-move" (click)="onMove(-1)" icon="board-mat:keyboard_arrow_up"></mgc-icon-button>
    <mgc-icon-button class="drag-handle" icon="board-mat:unfold-more" cdkDragHandle></mgc-icon-button>
    <mgc-icon-button class="click-move" (click)="onMove(1)" icon="board-mat:keyboard_arrow_down"></mgc-icon-button>
  </div>

  <div class="media-block-content pad-hrz-xs">
    <div class="media-block-item-header">
    </div>
    <div class="media-block-item-body">
      <modal-image #tv2Image [image]="block.Content"></modal-image>
    </div>
    <div class="media-block-item-footer">
      <ng-container *ngIf="!tv2Image.imageUploading">
        <mgc-icon-button class="remove" (click)="onRemove(block)" icon="board-mat:trash"></mgc-icon-button>
        <p style="padding-left: 30px;padding-right: 30px;opacity: .5;">|</p>
      </ng-container>
      <mgc-raised-button (click)="tv2Image.click()" [disabled]="tv2Image.imageUploading"> Add/Change Image
      </mgc-raised-button>
    </div>
  </div>
</ng-container>

<p *ngIf="preview">
  <modal-image preview [image]="block.Content"></modal-image>
</p>
