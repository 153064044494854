import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import * as Icons from '@libraries/jodit/icons/index';
import { take } from 'rxjs/operators';
import { JODIT } from './jodit.token';

@Injectable()
export class JoditService {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(JODIT) private Jodit: any
  ) {
    this.bootstrap();
  }

  bootstrap() {
    Object.keys(Icons)
      .forEach((path: string) => {
        this.iconRegistry.getSvgIconFromUrl(this.sanitizer.bypassSecurityTrustResourceUrl((Icons as any)[path]));
      });
  }

}
