import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "@board/modals/modals-editor/modal.model";

@Component({
  selector: 'config-streak',
  templateUrl: './config-streak.component.html',
})
export class ConfigStreakComponent implements OnInit {
  @Input() modal;
  @Output() modalChange = new EventEmitter<Modal>();
  @Input() submitted;

  form: FormGroup;
  get f() { return this.form.controls; }

  ngOnInit() {
    this.form = new FormGroup({
      seenDaysXOrMore: new FormControl(this.modal.Config.seenDaysXOrMore),
    });

    this.form.valueChanges.subscribe(newConfig => {
      this.modalChange.emit({
        ...this.modal,
        Config: {
          ...this.modal.Config,
          ...newConfig,
        }
      })
    });
  }
}
