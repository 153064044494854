<form [formGroup]="publishForm">

  <mgc-toggle-section label="Display Back Arrow" option="showBackArrow" path="Settings.publish" hasInfo="true"
    [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, the back arrow that, takes user to the main explore page, will be visble on the
      top-left of the page.</span>
  </mgc-toggle-section>

  <mgc-toggle-section label="Display Hero Image" option="showHero" path="Settings.publish" hasInfo="true"
    [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, the big hero image will be visible on top of the page.</span>

    <mat-radio-group *ngIf="f.showHero.value" class="mgc-radio-group" name="theme">

      <div>
        <mat-radio-button class="mgc-radio-button inline" value="brand" [checked]="'brand'===f.useLandingHeroFrom.value" [disabled]="!brand" (click)="onLHChange('brand')">
          <span>Use from brand: </span>
          <brand-avatar *ngIf="brand" size="30" [brand]="brand"></brand-avatar>
          <span>{{brand ?  brand.Name : '-'}}</span>
        </mat-radio-button>
        <div class="radio-img" *ngIf="f.useLandingHeroFrom.value==='brand'">
          <div #imageWrapper class="image-wrapper" [style.padding-bottom.%]="imageRatio">
            <div class="bg-image" [ngStyle]="brand | brandBackgroundStyle"> </div>
          </div>
        </div>
      </div>

      <div>
        <mat-radio-button class="mgc-radio-button inline" value="project" [checked]="'project'===f.useLandingHeroFrom.value"  (click)="onLHChange('project')">
          <span>Use custom image</span>
        </mat-radio-button>
        <mgc-landing-hero-section class="radio-img" *ngIf="f.useLandingHeroFrom.value==='project'" [project]="project">
        </mgc-landing-hero-section>
      </div>
    </mat-radio-group>

  </mgc-toggle-section>


  <!-- <mgc-toggle-section label="Display Brand Info" option="showBrandInfo" path="Settings.publish" hasInfo="true"
    [project]="project" (change)="toggleOption($event)">
    <span info>When enabled, the brand logo and the brad name will be visible.</span>
  </mgc-toggle-section> -->


  <!-- <mgc-toggle-section label="Display Plan Title" option="showName" path="Settings.publish" [project]="project"
    (change)="toggleOption($event)">
    <h1 [innerHTML]="project.__val('Name')"></h1>
  </mgc-toggle-section> -->

  <mgc-toggle-section label="Display Upper Text" option="showShortDescription" path="Settings.publish"
    [project]="project" (change)="toggleOption($event)">
    <mgc-form-field label="Add your upper area description text and HTML here" multiline>
      <div class="field-wrapper">
        <textarea autosize formControlName="ShortDescription"></textarea>
      </div>
    </mgc-form-field>
  </mgc-toggle-section>




  <mgc-toggle-section label="Display Image Carousel" option="showCarousel" path="Settings.publish" hasInfo="true"
    [project]="project" (change)="toggleOption($event)">
    <span info>Once active, the images from the carousel below will be visible in the
      <a [attr.href]="config.protocol + '://' + config.storeDomain" target="_blank" title="Project Store">
        Explore Plans Area</a></span>
    <mgc-form-field multiline class="nm">
      <div class="field-wrapper">
        <project-library #projectLibrary [project]="project" organize="true" (updated)="makeFormDirty()">
        </project-library>
      </div>
    </mgc-form-field>
    <mgc-form-field nobg multiline>
      <mgc-stroked-button (click)="projectLibrary.showOrganizer()">Add / edit images</mgc-stroked-button>
    </mgc-form-field>
  </mgc-toggle-section>

  <mgc-toggle-section label="Display Middle Text" option="showDescription" path="Settings.publish" [project]="project"
    (change)="toggleOption($event)">
    <mgc-form-field label="Add your middle area description text and HTML here" multiline>
      <div class="field-wrapper">
        <textarea autosize formControlName="Description"></textarea>
      </div>
    </mgc-form-field>
  </mgc-toggle-section>

</form>