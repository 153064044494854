<ng-container *ngIf="!preview">
  <rs-uploader [style.font-size]="0" renderAs="content" [options]="uploaderOptions" (onSuccess)="onSuccess($event)"
    (onBeforeUpload)="onBeforeUpload($event)" (onError)="onError($event)">
  </rs-uploader>

  <div class="image-wrapper" [style.padding-bottom.%]="imageRatio">
    <div class="bg-image editable" [ngStyle]="imageStyle">
      <div class="preview-image" *ngIf="previewImageFile" [ngStyle]="imageBlobStyle">
        <div class="overlay" *ngIf="imageUploading">
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="error-overlay" *ngIf="imageError">
          <div class="inner">
            <mgc-icon-button class="error" icon="board-mat:warning"></mgc-icon-button><span>error !!! <small>Possibly too
                big. Try another image.</small></span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="layout==='v1'">
        <ul class="actions" *ngIf="!imageUploading && !activeProp">
          <li (click)="click()">{{haveImage ? 'Replace' : 'Insert'}}</li>
          <ng-container *ngIf="haveImage && !imageError">
            <ng-container *ngFor="let prop of props">
              <li class="spacer">&#8226;</li>
              <li (click)="activateProp(prop)" [class.active]="image[prop.id]">{{prop.name}}</li>
            </ng-container>
          </ng-container>
        </ul>

        <form [formGroup]="form" class="props" *ngIf="activeProp">
          <ng-container *ngFor="let prop of props">
            <ng-container *ngIf="prop==activeProp">
              <mgc-form-field [label]="prop.label" class="input-wrapper">
                <div class="field-wrapper">
                  <input [formControlName]="prop.id" (keyup.enter)="onPropUpdate(prop)">
                  <span class="placeholder" *ngIf="!f[prop.id].value">{{prop.placeholder}}</span>
                </div>
              </mgc-form-field>
              <span class="btn" (click)="onPropUpdate(prop)">Done</span>
            </ng-container>
          </ng-container>
        </form>
      </ng-container>

      <p *ngIf="!haveImage" class="add-link linky" (click)="click()">Add Image</p>
    </div>
  </div>


  <div *ngIf="layout==='v2'">
    <div *ngIf="actionButtons" class="upload-btn-wrapper">
      <ng-container *ngIf="options.removable && haveImage && !imageUploading">
        <mat-icon class="remove" (click)="remove($event)" svgIcon="board-mat:trash"></mat-icon>
      </ng-container>
      <mgc-raised-button class="sm ttn" (click)="click()" [disabled]="imageUploading">{{haveImage ? 'Replace Image' : 'Add Image'}}</mgc-raised-button>
    </div>
    
    <form [formGroup]="form" style="margin-top: 10px;">
      <ng-container *ngFor="let prop of props">
          <mgc-form-field [label]="prop.label" class="input-wrapper">
            <div class="field-wrapper">
              <input [formControlName]="prop.id">
              <span class="placeholder" *ngIf="!f[prop.id].value">{{prop.placeholder}}</span>
            </div>
          </mgc-form-field>
      </ng-container>
    </form>
  </div>

</ng-container>

<ng-container *ngIf="preview">
  <a *ngIf="image.Link" [href]="image.Link" target="_blank" [attr.alt]="image.AltText"
    [attr.title]="image.AltText || ''" [style.display]="'block'" class="image-wrapper"
    [style.padding-bottom.%]="imageRatio">
    <div class="bg-image" [ngStyle]="imageStyle"> </div>
  </a>

  <div *ngIf="!image.Link" class="image-wrapper" [style.padding-bottom.%]="imageRatio">
    <div class="bg-image" [ngStyle]="imageStyle"> </div>
  </div>
</ng-container>