<form [formGroup]="activationForm">
    <h3 [style.color]="'gray'" [style.font-weight]="400" [innerHTML]="message"></h3>

    <mgc-form-field label="Email" [invalid]="submitted && f.email.errors" disabled>
        <div class="field-wrapper">
            <input tabindex="-1" formControlName="email" name="email">
        </div>
        <ng-container invalid-feedback *ngIf="submitted && f.email.errors">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
        </ng-container>
    </mgc-form-field>

    <mgc-form-field label="Activation code" [invalid]="submitted && f.acode.errors">
        <div class="field-wrapper">
            <input tabindex="-1" formControlName="acode" name="acode">
            <span class="placeholder" *ngIf="!f.acode.value">Enter here the activation code received through email...</span>
        </div>
        <ng-container invalid-feedback *ngIf="submitted && f.acode.errors">
            <div *ngIf="f.acode.errors.required">You must enter a value</div>
            <div *ngIf="f.acode.errors.pattern">Only numbres</div>
        </ng-container>
    </mgc-form-field>


    <h3 *ngIf="resent && !resending && !activated" [style.color]="'orange'" [style.font-weight]="400">Please check your email to confirm your account!</h3>
</form>