import { Component, Input, HostBinding } from "@angular/core";

@Component({
    selector: 'mgc-dialog-actions',
    template: `
        <div class="align-left">
            <ng-content select="[left]"></ng-content>
        </div>
        <div class="align-right">
            <ng-content select="[right]"></ng-content>
        </div>
    `,
    styleUrls: ['./dialog-actions.component.scss']
})
export class MgcDialogActionsComponent {

    @HostBinding('class.mgc-dialog-actions') setClass: boolean = true;
}
