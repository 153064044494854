import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Participant } from './participant';
import { Project } from '@board/items/project';
import { IServiceStates } from '@rallysite/global-interfaces';
import { Task } from '@board/items/task';
import { ParticipantService } from './participant.service';
import { CurrentResourceService } from '@board/_services/current-resource.service';
import { PermissionService } from '@board/_services/permission/permission.service';
import { DeleteConfirmModal } from '@board/_components/modals';
import { ParticipantDetailsModal } from './modals/participant-details-modal';

@Component({
  selector: 'mgc-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {
  @HostBinding('class.mgc-participant') participantClass = true;
  @HostBinding('class.mgc-account') accountClass = true;

  @Input() participant: Participant;
  @Input('item') itemInView: Project | Task;


  // [class.highlight]="participant._stateRemove==='removing'"
  // [class.remove]="participant._stateRemove==='remove'"

  avatarSize: number = 42;
  haloStyle = {
    'min-width.px': this.avatarSize + 6,
    'max-width.px': this.avatarSize + 6,
    'min-height.px': this.avatarSize + 6,
    'max-height.px': this.avatarSize + 6,
  }

  alive: boolean = true;
  states: IServiceStates;
  allowRemove: boolean = false;

  owner: boolean = false;
  assigned: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private dialog: MatDialog,
    private currentResourceService: CurrentResourceService,
    private permissionService: PermissionService
  ) {
    this.states = ParticipantService.STATES;
  }

  assign() {
    this.participantService.assign(this.participant, this.itemInView, this.project);
  }

  unassign() {
    this.participantService.unassign(this.participant, this.itemInView, this.project);
  }

  removeParticipant() {
    if (!this.permissionService.allowsAction('delete.participant', this.participant, { message: 'You are not allowed to remove this participant' })) {
      return;
    }
    this.participant._stateRemove = 'removing';
    this.dialog.open(DeleteConfirmModal, {
      disableClose: true,
      data: {
        item: this.participant,
        delete: (() => {
          this.participantService.remove(this.participant, this.project);
        }),
        text: `Are you sure you want to remove the participant <strong>${this.participant.Email}</strong> from <strong>${this.project.Name}</strong> project?`,
        title: 'Remove participant'
      }
    });
  }

  private get project(): Project {
    return this.currentResourceService.project;
  }

  openDetailsModal() {
    let dialogRef = this.dialog.open(ParticipantDetailsModal, {
      width: '70%',
      maxWidth: '800px',
      minWidth: '300px',
      minHeight: '400px',
      maxHeight: 'initial',
      position: { top: '30px' },
      disableClose: true,
      data: {
        title: 'Participant Details',
        participant: this.participant,
        project: this.project
      }
    });
  }

  ngOnInit() {
    this.allowRemove = this.permissionService.allowsAction('delete.participant', this.participant, { silent: true });
    this.owner = this.participant.isOwner(this.project);
    this.assigned = this.participant.Id === this.itemInView.AssigneeId
  }

  ngOndestroy() {
    this.alive = false;
  }
}

@Component({
  selector: 'mgc-assigned-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantAssignedComponent extends ParticipantComponent implements OnInit {
  @HostBinding('class.assigned') assignedClass = true;

  ngOnInit() {
    if (!this.participant) {
      return;
    }

    super.ngOnInit();
  }
}
