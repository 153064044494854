import { take, finalize } from 'rxjs/operators';

import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from '@rallysite/auth-service';
import { AlertService } from '@rallysite/components/alert';
import { IPageAuthView } from '../page-auth-view.interface';
import { PageAuthViewType } from '@page-auth';
import { AppConfig, APP_CONFIG } from '@rallysite/config';


@Component({
  selector: 'page-sign-in',
  templateUrl: './page-sign-in.component.html',
  styleUrls: ['./page-sign-in.component.scss']
})
export class PageSignInComponent implements IPageAuthView, OnInit {
  constructor(
    private auth: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
  }

  // convenience getter for easy access to form fields
  get f() { return this.authForm.controls; }

  @Input() data: any = {};
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  hidePassword = true;

  processing = false;

  /** FORM */
  authForm: FormGroup;

  submitted = false;

  getEmail() {
    return  this.authForm.get('email').value || null;
  }
  bootstrapForm() {
    this.authForm = this.formBuilder.group({
      email: [this.data.email || '', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      agree: [false]
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.authForm.invalid) {
      return false;
    }

    this.alertService.clear();
    if (!this.authForm.get('agree').value) {
      this.alertService.warning('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy');
      return false;
    }

    return true;
  }
  /** end of FORM */

  toggleAgree(matSlideToggle: MatSlideToggleChange) {
    this.authForm.setValue({ agree: matSlideToggle.checked });
  }

  onSubmit() {
    if (!this.submit() || this.processing) {
      return;
    }

    this.processing = true;
    const payload = Object.assign({}, this.authForm.value);
    this.auth.login(payload).pipe(take(1),
      finalize(() => {
        this.processing = false;
      }))
      .subscribe(user => {
        this.close.emit(PageAuthViewType.login);
      },
        error => {
          this.alertService.error(error);
        });
  }

  closeIt() {
    this.close.emit('close');
  }

  ngOnInit() {
    this.alertService.clear();
    this.bootstrapForm();
  }

}
