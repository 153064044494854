import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {Modal} from '../modal.model';
import {ModalListService} from '../modal-list.service';
import {BoardViewService} from '@board/_services';
import * as moment from 'moment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'modal-list',
  templateUrl: './modal-list.component.html',
  styleUrls: ['./modal-list.component.scss']
})
export class ModalListComponent implements OnInit {

  constructor(
    private modalListService: ModalListService,
    private boardViewService: BoardViewService,
  ) {
  }

  smallView: boolean = this.boardViewService.smallView();

  @Input() selectedModal: Modal;

  @Output() modalSelected: EventEmitter<Modal> = new EventEmitter<Modal>(null);
  @Output() modalLoading: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  loading = true;
  subscriptions: Subscription[] = [];
  modalsSubscription: Subscription;
  modals: Modal[] = [];
  ready = false;
  newModalId = 1;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.smallView = this.boardViewService.smallView();
  }

  public addModal(modalType: string) {
    const modal = {
      Id: 'new-' + this.newModalId++,
      ModalType: modalType,
      SystemName: '',
      InternalName: '',
      InternalDescription: '',
      IsEnabled: false,
      Title: '',
      Description: '',
      Config: {},
      CreateDate: moment().format(),
      UpdateDate: moment().format(),
    } as unknown as Modal;

    this.modals.push(modal);
    this.openModal(modal);
  }

  private loadModals(): Observable<Modal[]> {
    this.loading = true;

    // should be ready and exists a survey selected
    this.modalLoading.emit(this.ready && !!this.selectedModal);

    if (this.modalsSubscription) {
      this.modalsSubscription.unsubscribe();
    }

    return this.modalListService.getModals().pipe(
      finalize(() => {
        this.loading = false;
        this.ready = true;
        this.modalLoading.emit(false);
      }),
      map(modals => {
        if (!modals) {
          return [];
        }
        this.modalsSubscription = this.modalListService.modals$()
          .subscribe(modals => {
            this.modals = modals;
          });
        return modals;
      })
    );
  }

  openModal(modal: Modal) {
    this.selectedModal = modal;
    this.modalSelected.emit(modal);
  }

  ngOnInit() {
    this.loadModals().subscribe(modals => {
      this.modals = modals;
    });
  }

  ngOnDestroy() {
    if (this.modalsSubscription) {
      this.modalsSubscription.unsubscribe();
    }
  }

  trackByFn(index: number, modal: Modal): string {
    return `${modal.Id}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.modals, event.previousIndex, event.currentIndex);
    this.modalListService.updateOrders(
      this.modals.map(modal => modal.Id as number)
        .filter(id => Number.isInteger(id))
    ).subscribe();
  }
}
