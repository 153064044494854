import { Component, Input, HostBinding, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { Account } from '@board-accounts/account.model';

@Component({
    selector: 'account-avatar',
    templateUrl: 'avatar.component.html',
    styleUrls: ['avatar.component.scss']
})

export class AccountAvatarComponent {
    @HostBinding('class.account-avatar') accountAvatar = true;

    private timestamp: any = null;

    fontSize: number;
    textPosY: number;
    sw: number;
    sc: string;
    defaultColor: string = 'rgba(0,0,0, 0.1)';

    accountThumbnail: any;

    @Input() type: string;
    @Input() textColor: string;
    @Input() processing: boolean = false;
    @Input() previewAvatarFile: any = null;

    @Input('with-halo') hp: number = 0;
    get halo() {
        return this.hp > 0;
    }

    @Input() account: Account;
    @Input() size: number;
    @Input() strokeWidth: number;
    @Input() strokeColor: string;

    @HostBinding('style.height.px') hPx: number;
    @HostBinding('style.width.px') wPx: number;

    get fillColor(): string {
        return this.account.Color || 'white';
    }

    constructor(
        private chRef: ChangeDetectorRef
    ) { 
    } 

    ngOnInit() {
        this.hPx = this.wPx = this.size;
        this.sw = this.strokeWidth || 2;

        this.sc = this.strokeColor || (this.account.Initials ? this.fillColor : this.defaultColor);
        this.fontSize = this.size / 2 - this.sw;
        this.textPosY = this.size / 2 + this.fontSize / 3;
    }

    ngOnChanges(change: SimpleChange) {
        if (change['account'] && change['account'].currentValue) {
            this.sc = this.strokeColor || (this.account.Initials ? this.fillColor : this.defaultColor);
            this.timestamp = this.account._timestamp;
        }
    }

    ngDoCheck(){
        if (this.account && this.timestamp !== this.account._timestamp){
            this.timestamp = this.account._timestamp;
            this.chRef.detectChanges();
        }
    }
}