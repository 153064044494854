import { BaseItem } from "@board/items/base-item.model";
import { IPanelComponent, PanelComponents } from "@panel-components";
import { EmailParticipant } from "@panel-components/email-participants/email-participant";
import { BaseEmailCampaignGroup, DefaultEmailCampaignGroup } from "../email-campaign-group/email-campaign.group";

export interface IPreviewEmailData {
    subject: string,
    from: {
        name: string,
        address: string
    },
    template: string
}

export class EmailCampaignAttributes {
    Id: string = null;
    AccountId: string = null;
    Name: string = null;
    Status: string = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class BaseEmailCampaign extends BaseItem {
    readonly _type: string = 'email-campaign';

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.EMAIL_CAMPAIGN_NAME,
        PanelComponents.EMAIL_PARTICIPANTS,
        PanelComponents.EMAIL_SEND_GROUPS,
    ];
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.EMPTY;
    }

    sideDefaultPanel(allowFn: Function) {
        // if (allowFn(this.Settings.sideDefaultPanel.id)) {
        //     return this.Settings.sideDefaultPanel;
        // }
        return PanelComponents.EMAIL_CAMPAIGN_NAME;
    }

    prevAttributes: EmailCampaignAttributes = new EmailCampaignAttributes();
    attributes: EmailCampaignAttributes = new EmailCampaignAttributes();

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Status(value: string) {
        this.attributes.Status = value;
    }
    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    _groupId: string;
    _groupUId: string;
    _state: string;
    _open: boolean = false;

    _participant: EmailParticipant;
    _resmap: any;
    _permap: any;
    _scrollY: number;

    isStandalone() {
        return this._groupId === DefaultEmailCampaignGroup.ID;
    }

    isNotConfirmed() {
        return this._participant.Confirmation === 'pending';
    }

    modalOptions({ group, edit, panel }: {
        group?: BaseEmailCampaignGroup,
        panel?: IPanelComponent,
        edit?: boolean,
    }) {
        edit = !!edit;
        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Email Campaign',
                campaign: this,
                group: group,
                panel: panel,
                edit: edit,
            }
        }
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            UpdateDate: this.UpdateDate
        });
    }
}

export class EmailCampaign extends BaseEmailCampaign {

    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }
    }
}
