import { Injectable } from "@angular/core";
import { ISurveyQuestion, SurveyQuestion } from "@libraries/surveys/models/survey-question.model";
import { TransferStateConverter } from "../transfer-state.converter";

type SerializedData = ISurveyQuestion | ISurveyQuestion[]

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionsConverter extends TransferStateConverter<ISurveyQuestion | ISurveyQuestion[]> {
  constructor(
  ) {
    super();
  }

  fromTransferState(data: SerializedData): ISurveyQuestion | ISurveyQuestion[] {
    if (Array.isArray(data)) {
      const questions: ISurveyQuestion[] = [];
      for (let key in data) {
        const q = new SurveyQuestion(data[key]);
        questions.push(q);
      }
      return questions;
    }

    return new SurveyQuestion(data);
  }

  toTransferState(data: ISurveyQuestion | ISurveyQuestion[]): SerializedData {
    return data;
  }
}