<mgc-list-item project>
    <div class="icon-prefix">
        <project-avatar [name]="project.Name" [imageId]="project.avatar" [t]="project.avatarTimestamp"
            class="drag-handle"></project-avatar>
    </div>
    <div class="content" (click)="open(project)" [attr.title]="project.__val('Name')">
        <span [innerHTML]="project.__val('Name')"> </span>
        <ul *ngIf="summaryView">
            <!-- <li *ngIf="project._flaggedNotes">
                <mat-icon svgIcon="board-mat:flag"></mat-icon>
            </li> -->
        </ul>
    </div>
    <div class="suffix">
        <span class="new-label" *ngIf="project._isNew">new</span>
        <ng-container *ngIf="!(isOpening || project._state=='remove')">
            <mat-icon *ngIf="project._flaggedNotes" class="flag" svgIcon="board-mat:flag"></mat-icon>
            <!-- <mat-chip-list class="mgc-chip-list">
                <mat-chip *ngIf="project._notifications > 0">{{project._notifications}}</mat-chip>
            </mat-chip-list> -->
            <mat-icon *ngIf="project._notifications > 0" class="notifications-dot" svgIcon="board-mat:dot"></mat-icon>

            <mgc-icon-button *ngIf="project.isNotConfirmed()" class="theme-color" icon="board-mat:new-release">
            </mgc-icon-button>
            <!-- DISABLED for NOW -->
            <!-- <mgc-icon-button  *ngIf="!project.isNotConfirmed()" icon="board-mat:more_vert" (click)="openSettings()"></mgc-icon-button> -->
        </ng-container>
        <mgc-loader *ngIf="isOpening || project._state=='remove'"></mgc-loader>
    </div>
</mgc-list-item>

<!-- <ul class="extended-details" *ngIf="detailedView">
    <li *ngIf="project._flaggedNotes">
        <mat-icon svgIcon="board-mat:flag"></mat-icon>
    </li>
</ul> -->