
export const DNSA_OPTIONS = {
    NOTE_LIST_EMPTY: <IDoNotShowAgainOption>{
        id: 'nole',
        icon: '',
        title: '',
        action: 'Close',
        text: 'Empty Message Area help tips'
    },
    GROUP_CREATE: <IDoNotShowAgainOption>{
        id: 'grc',
        icon: 'collection',
        title: 'Create New Collection',
        action: 'Continue',
        text: 'Create Collection help tips'
    },
    FOLDER_CREATE: <IDoNotShowAgainOption>{
        id: 'foc',
        icon: 'folder_open',
        title: 'Create New Folder',
        action: 'Continue',
        text: 'Create Folder help tips'
    },
    ACCOUNT_CREATE: <IDoNotShowAgainOption>{
        id: 'acc',
        icon: 'person',
        title: 'Add New Account',
        action: 'Continue',
        text: 'Create \'Personal Sub-Account\' help tips'
    },
  }

export interface IDoNotShowAgainOption {
    id: string;
    icon: string;
    title: string;
    text: string;
    checked?: boolean;
    action: string;
}

export class DoNotShowAgainOption implements IDoNotShowAgainOption {
    id: string;
    icon: string;
    title: string;
    text: string;
    action: string;

    constructor(option: string, public checked: boolean = false) {
        for (let o in DNSA_OPTIONS) {
            if (option === (<IDoNotShowAgainOption>DNSA_OPTIONS[o]).id) {
                for (let prop in DNSA_OPTIONS[o]) {
                    this[prop] = DNSA_OPTIONS[o][prop]
                }
            }
        }
    }
}