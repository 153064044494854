
import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ParticipantService } from '../participant.service';
import { Participant } from '../participant';

import { IServiceStates } from '@rallysite/global-interfaces';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { Project } from '@board/items/project';


@Component({
  selector: 'participant-details-modal',
  templateUrl: './participant-details-modal.html',
  styleUrls: ['./participant-details-modal.scss']
})
export class ParticipantDetailsModal implements OnInit, OnDestroy {
  private alive: boolean = true;

  participant: Participant;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  resent: boolean = false;
  resending: boolean = false;

  get permissionsMap() {
    return ParticipantService.PERMISSION_MAP;
  }

  constructor(
    public dialogRef: MatDialogRef<ParticipantDetailsModal>,
    private participantService: ParticipantService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      participant: Participant,
      project: Project
    }) {

  }

  get states(): IServiceStates {
    return ParticipantService.STATES;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.participantService.update(this.participant, this.data.project);
  }

  resendInvitation() {
    if (!this.participant.resend.done) {
      this.participantService.resend(this.participant, this.data.project);
    }
  }

  ngOnInit() {
    this.participant = new Participant(Object.assign({}, this.data.participant));

    this.participantService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        if (state === this.states.DONE) {
          this.dialogRef.close(this.participantService.participant);
        } else if (state === this.states.UNAUTHORIZED) {
          this.dialogRef.close('unauthorized');
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
