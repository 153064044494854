
import { take } from 'rxjs/operators';

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ParticipantService, Participant } from '@panel-components/participants';
import { CurrentResourceService } from '@board/_services';
import { AccountService } from '@board/accounts';

@Component({
  selector: 'mgc-post-as',
  templateUrl: './post-as.component.html',
  styleUrls: ['./post-as.component.scss']
})
export class PostAsComponent implements OnInit {

  @Input() label = 'Post as';
  @Input() accountId: string = null;

  @Output() ownerAs: EventEmitter<Participant> = new EventEmitter(null);

  admins: Participant[] = [];
  postAs: any = {};
  allowPostAs = false;

  constructor(
    protected participantService: ParticipantService,
    protected currentResource: CurrentResourceService,
    protected accountService: AccountService
  ) {
  }

  selectPostAs(participant) {
    this.postAs = (participant || {})._account;
    this.ownerAs.emit(this.postAs);
  }

  private getAdmin(accountId: string = null) {
    if (accountId) {
      return this.admins.find(p => p._account && accountId === p._account.Id);
    }
    return this.admins.find(p => p.Email.toLowerCase() === this.accountService.currentAccount.Email.toLowerCase());
  }

  ngOnInit() {
    const project = this.currentResource.project;
    this.allowPostAs = ParticipantService.hasLevelOrAboveRights('level-1', project._participant);

    if (this.allowPostAs) {
      this.participantService.loadSuperAdminsOrAbove(project).pipe(take(1))
        .subscribe(data => {
          this.admins = data;
          const admin = this.getAdmin(this.accountId);
          this.selectPostAs(admin || this.getAdmin());
        });
    }
  }

}
