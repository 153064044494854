import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MgcDialogModule } from '@rallysite/mgc-components';
import { MatIconModule } from '@angular/material/icon';
import { SurveysDataModule } from '../surveys/survey-data.module';
import { SurveyModalTriggerDirective } from './survey-modal-trigger.directive';
import { SurveyModalComponent } from './survey-modal.component';
import { SurveyButtonModule } from './survey-button.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MgcDialogModule,
        SurveysDataModule,
        SurveyButtonModule
    ],
    declarations: [
        SurveyModalTriggerDirective,
        SurveyModalComponent,
    ],
    exports: [
        SurveyModalTriggerDirective,
        SurveyModalComponent,
    ]
})
export class SurveyModalModule { }
