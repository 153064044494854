
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '@board/items/project/project';
import { TOOLS_PAGES } from '@pages/pages.enum';
import { WINDOW } from '@rallysite/global-services';

@Component({
  selector: 'redirect-modal',
  templateUrl: './redirect-modal.html',
})
export class RedirectModal implements OnInit, OnDestroy {

  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RedirectModal>,
    @Inject(WINDOW) private w: Window,
    @Inject(MAT_DIALOG_DATA) public data: {
      project: Project,
      url: string
    }) {
  }


  onClick() {
    if (!this.w.location) return;
    this.processing = true;
    this.w.location.href = `${this.data.url}${TOOLS_PAGES.BOARD.path}?p=${this.data.project.Id}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

}
