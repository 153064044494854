
import { take, delay } from 'rxjs/operators';
import { Component, AfterViewInit, Inject } from '@angular/core';
import { MessageBusService, MobileJsBridgeService, MessageBusActions } from './_services';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import {PageViewService} from './_services/page-view.service';
import { DOCUMENT } from '@angular/common';
import {GlobalModalsService} from './global-modals/global-modals.service';

// declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: 'cp-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  loaded = false;

  constructor(
    private messageBus: MessageBusService,
    private mobileJsBridge: MobileJsBridgeService,
    public router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    private ga: GoogleAnalyticsService,
    private pageViewService: PageViewService,
    @Inject(DOCUMENT) private document,
    private globalModalsService: GlobalModalsService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.detectUserCameFromGtApp(event);
      }

      if (event instanceof NavigationEnd) {
        this.ga.start('platform', event.urlAfterRedirects);

        this.pageViewService.send({
          pageurl: event.urlAfterRedirects,
          referrerurl: this.document.referrer || '',
        });

        this.globalModalsService.handlePageNavigation();
      }
    });
  }

  ngAfterViewInit() {
    this.messageBus.on(MessageBusActions.APP_LOADED).pipe(
      take(1),
      delay(0)) // avoid expression changed ERR
      .subscribe(data => {
        this.loaded = true;
        this.mobileJsBridge.appLoaded(1, data);
      });
  }

  /**
   * Method allows to detect if user came from GT app using multiple checks.
   * Used to figure out if we should show "verification page" instead of login page
   * > When the auth token fails for any reason and the user is in the app, do not show the login page, show designed page instead.
   * @param event
   */
  detectUserCameFromGtApp(event: NavigationStart) {
    if (!event.url.includes('?')) {
      return;
    }

    const exp = event.url.split('?');
    const parsedParams = new URLSearchParams(exp[1]);

    if (parsedParams.has('view')) {
      sessionStorage.setItem('view', '1');
    }

    // If url has no token then it means user didn't pass through cross auth
    if (!parsedParams.has('token')) {
      return;

    }

    // Width more than 500 means that it's not mobile screen
    if (window.innerWidth > 500) {
      return;
    }

    console.log('Detected user navigated from GT app');

    window.localStorage.setItem('user_from_gt_app_at', `${new Date().getTime()}`);
  }
}
