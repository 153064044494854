<div class="brand" *ngIf="shape==='circle'">
    <ng-content></ng-content>
    <ng-container *ngIf="!brand.Thumbnail">
        <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
            <circle 
                [attr.cx]="size/2" 
                [attr.cy]="size/2" 
                [attr.r]="size/2 - sw"
                [attr.stroke]="sc" 
                [attr.stroke-width]="sw"
                [attr.fill]="fillColor" />
            <text 
                [attr.x]="size/2"
                [attr.y]="textPosY" 
                [attr.font-size]="fontSize"
                [attr.fill]="textColor||'white'" 
                text-anchor="middle" 
                [style.line-height.px]="fontSize">{{brand.Initials || 'O'}}</text>
        </svg>
    </ng-container>

    <ng-container *ngIf="brand.Thumbnail">
        <img [ngClass]="{'mat-elevation-z4': !!elevation}" [alt]="brand.Name || 'Brand avatar related image'" [src]="brand | brandAvatarUrl:brand._timestamp" [width]="size" [height]="size" style="border-radius: 50%; display: block;">
    </ng-container>
</div>

<div class="brand" *ngIf="shape==='rectangle'">
    <ng-content></ng-content>
    <ng-container *ngIf="!brand.Thumbnail">
        <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
            <rect 
                [attr.width]="size" 
                [attr.height]="size" 
                [attr.rx]="10" 
                [attr.ry]="10" 
                [attr.stroke]="sc" 
                [attr.stroke-width]="0"
                [attr.fill]="fillColor" />
            <text 
                [attr.x]="size/2"
                [attr.y]="textPosY" 
                [attr.font-size]="fontSize"
                [attr.fill]="textColor||'white'" 
                text-anchor="middle" 
                [style.line-height.px]="fontSize">{{brand.Initials || 'O'}}</text>
        </svg>
    </ng-container>

    <ng-container *ngIf="brand.Thumbnail">
        <img [ngClass]="{'mat-elevation-z4': !!elevation}" [alt]="brand?.Name || 'Brand avatar related image'" [src]="brand | brandAvatarUrl:brand._timestamp" 
        [width]="size" [height]="size" style="border-radius: 10px; display: block;">
    </ng-container>
    <ng-container *ngIf="previewAvatarFile">
        <img class="preview" [src]="previewAvatarFile | avatarBlobUrl" [alt]="brand?.Name || 'Brand avatar related image'" [width]="size" [height]="size" style="border-radius: 10px; display: block;">
    </ng-container>

    <div class="overlay" *ngIf="processing">
            <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
                <rect 
                    [attr.width]="size" 
                    [attr.height]="size" 
                    [attr.rx]="10" 
                    [attr.ry]="10" 
                    [attr.stroke]="sc" 
                    [attr.stroke-width]="0"
                    fill="currentColor" />
            </svg>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
        

</div>


    