import {
    Component,
    OnInit,
    OnDestroy,
    Input,
} from '@angular/core';

@Component({
    selector: 'organizer-media-details',
    template: `
        MEDIA DETAILS
    `,
    styles: [`
        :host {
            display: block;
            padding: 5px;
            background-color: magenta;
        }
    `]
})
export class OrganizerMediaDetailsComponent implements OnInit, OnDestroy {
    constructor(
    ) { }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

}