import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { TooltipOverlayService } from "./overlay/tooltip-overlay.service";
import { TooltipService } from "./tooltip.service";
import { take } from "rxjs/operators";

@Directive({
  selector: "[tooltip]",
})
export class TooltipDirective implements OnInit {
  @HostListener("click", ["$event.target"])
  onClick(target: any) {
    this.engage(target);
  }

  @Input("tooltipId") tooltipId = null;

  constructor(
    private tooltipOverlayService: TooltipOverlayService,
    private tooltipService: TooltipService,
  ) {}

  engage(target:any) {
    if (!this.tooltipId) {
      return;
    }

    this.tooltipService
      .getTooltip(this.tooltipId)
      .pipe(take(1))
      .subscribe((tooltip) => {
        this.tooltipOverlayService.open(target, tooltip);
      });
  }

  ngOnInit() {
  }
}
