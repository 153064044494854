<section class="reports-index">
  <div class="container">
    <h1>Modals</h1>

    <ul class="modals-list">
      <li *ngFor="let modal of modals" >
        <a *ngIf="modal.enabled" [routerLink]="modal.systemName">
          <span>{{modal.label}}</span>
        </a>
        <span *ngIf="!modal.enabled">{{modal.label}}</span>
        <span tooltip [tooltipId]="modal.tooltipId"></span>
      </li>
    </ul>
  </div>
</section>
