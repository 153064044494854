import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlgoTagListComponent } from './algotag-list.component';
import { AlgoTagAutoCompleteComponent } from './algotag/algotag-autocomplete.component';
import { AlgoTagDropdownComponent } from './algotag/algotag-dropdown.component';
import { AlgoTagScoreComponent } from './algotag/algotag-score.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MgcButtonModule, MgcFormFieldModule, MgcLoaderModule } from '@rallysite/mgc-components';
import { ReactiveFormsModule } from '@angular/forms';
import { AlgoTagsPipesModule } from './algotags.pipes';
import { AlgoTagSingleSelectComponent } from './algotag/algotag-single-select.component';

@NgModule({
    imports: [
      CommonModule,
      ReactiveFormsModule,

      MatIconModule,
      MatChipsModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatExpansionModule,
      MatRadioModule,

      MgcFormFieldModule,
      MgcButtonModule,
      MgcLoaderModule,

      AlgoTagsPipesModule

    ],
    declarations: [
      AlgoTagListComponent,
      AlgoTagAutoCompleteComponent,
      AlgoTagDropdownComponent,
      AlgoTagScoreComponent,
      AlgoTagSingleSelectComponent
    ],
    exports: [
      AlgoTagListComponent
    ]
})
export class AlgoTagsModule { }
