import { generateId } from "../generate_block_id";
import { ITaskV2Block } from "../task-v2-block.interface";
import {
    TaskV2BlockAction,
    TaskV2BlockType,
    TASK_V2_BLOCK_PREFIX
} from "../types";
import { TaskV2Image } from "./image/task-v2-image";

export class TaskV2ImageBlock implements ITaskV2Block {
    Type: TaskV2BlockType = TaskV2BlockType.IMAGE;
    TaskId: string;
    Id: string;
    BlockId: string;
    CreateDate: string;
    UpdateDate: string;

    _httpAction: TaskV2BlockAction = TaskV2BlockAction.UPDATE;

    private _image: TaskV2Image;
    set Content(value: TaskV2Image) {
        this._image = value instanceof TaskV2Image ? value : new TaskV2Image(value)
    }
    get Content(): TaskV2Image {
        return this._image;
    }

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${TASK_V2_BLOCK_PREFIX.IMAGE}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
        if (!this._image.BlockId) {
            this._image.BlockId = this.BlockId;
        }
    }

    toDb() {
        return {
            Id: this.Id,
            TaskId: this.TaskId,
            BlockId: this.BlockId,
            Content: this.Content.toDb(),
            Type: this.Type,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }
}
