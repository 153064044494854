import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MgcDialogModule } from '@rallysite/mgc-components';
import { MatIconModule } from '@angular/material/icon';
import { AssessmentsDataModule } from './assessment/assessments-data.module';
import { SurveyModalModule } from '../survey-modal/survey-modal.module';
import { AssessmentModalComponent } from './assessment-modal.component';
import { AssessmentModalTriggerDirective } from './assessment-modal-trigger.directive';
import { AssessmentButtonModule } from './assessment-button.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MgcDialogModule,
        AssessmentsDataModule,
        SurveyModalModule,
        AssessmentButtonModule
    ],
    declarations: [
        AssessmentModalComponent,
        AssessmentModalTriggerDirective
    ],
    exports: [
        AssessmentModalComponent,
        AssessmentModalTriggerDirective,
    ]
})
export class AssessmentModalModule { }
