import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

export class ITargets {
    accountId: string;
    umbrellaId: string;
    projectId: string;
    groupId: string;
    taskId: string;
    scheduledTaskId: string;
    noteId: string;
    _showComments?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TargetResourceService {

    targetPresent: boolean = false;
    targetLevel: number = 0;
    private dataStore: {
        targets: ITargets
    }
    private _targets: BehaviorSubject<ITargets>;

    constructor(
    ) {
        this.reset(true);
        this._targets = <BehaviorSubject<ITargets>>new BehaviorSubject({});
    }

    set currentTargets(targets: ITargets) {
        this.dataStore.targets = targets;
        this.targetLevel = 0;
        if (this.dataStore.targets.projectId) {
            this.targetLevel++;
        }
        if (this.dataStore.targets.taskId || this.dataStore.targets.scheduledTaskId) {
            this.targetLevel++;
        }
        if (this.dataStore.targets.noteId) {
            this.targetLevel++;
        }

        // console.log(['this.dataStore.targets', this.dataStore.targets]);
        this._targets.next(Object.assign({}, this.dataStore).targets);
    }

    get currentTargets$() {
        return this._targets.asObservable();
    }

    get accountId(): string {
        return this.dataStore.targets.accountId;
    }
    get umbrellaId(): string {
        return this.dataStore.targets.umbrellaId;
    }
    get projectId(): string {
        return this.dataStore.targets.projectId;
    }
    get groupId(): string {
        return this.dataStore.targets.groupId;
    }
    get taskId(): string {
        return this.dataStore.targets.taskId;
    }
    get scheduledTaskId(): string {
        return this.dataStore.targets.scheduledTaskId;
    }
    get noteId(): string {
        return this.dataStore.targets.noteId;
    }


    reset(initial: boolean = false) {
        if (!initial){
            this.targetPresent = true;
        }
        this.targetLevel = 0;
        this.dataStore = {
            targets: {
                accountId: '',
                umbrellaId: '',
                projectId: '',
                groupId: '',
                taskId: '',
                scheduledTaskId: '',
                noteId: ''
            }
        };
    }

}