<div class="settings-box toggle-button" *ngFor="let dnsaOption of options">
    <div class="flex" (click)="toggleOption(dnsaOption)">
      <div class="label">{{dnsaOption.text}}</div>
      <div class="label secondary"></div>
    </div>
  
    <div class="settings-toggle">
      <mat-slide-toggle [checked]="!dnsaOption.checked" (change)="toggleOptionByEvent($event, dnsaOption)"></mat-slide-toggle>
    </div>
  </div>
