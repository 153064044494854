export interface IServiceAlertClass {
  id: string;
  code: string;
}

export interface IAlertsClass {
  USER: IServiceAlertClass;
  ACCOUNT_LIST: IServiceAlertClass;
  ACCOUNT_CREATE: IServiceAlertClass;
  ACCOUNT_VIEW: IServiceAlertClass;
  ACCOUNT_UPDATE: IServiceAlertClass;
  ACCOUNT_DELETE: IServiceAlertClass;

  BRAND_LIST: IServiceAlertClass;
  BRAND_CREATE: IServiceAlertClass;
  BRAND_RESERVE: IServiceAlertClass;
  BRAND_VIEW: IServiceAlertClass;
  BRAND_UPDATE: IServiceAlertClass;
  BRAND_DELETE: IServiceAlertClass;

  BRAND_MEMBER_LIST: IServiceAlertClass;
  BRAND_MEMBER_CREATE: IServiceAlertClass;
  BRAND_MEMBER_VIEW: IServiceAlertClass;
  BRAND_MEMBER_UPDATE: IServiceAlertClass;
  BRAND_MEMBER_DELETE: IServiceAlertClass;

  BRAND_EMAIL_LIST_CREATE: IServiceAlertClass;
  BRAND_EMAIL_LIST_UPDATE: IServiceAlertClass;
  BRAND_EMAIL_LIST_DELETE: IServiceAlertClass;

  UMBRELLA_LIST: IServiceAlertClass;
  UMBRELLA_CREATE: IServiceAlertClass;
  UMBRELLA_VIEW: IServiceAlertClass;
  UMBRELLA_UPDATE: IServiceAlertClass;
  UMBRELLA_DELETE: IServiceAlertClass;

  PROJECT_LIST: IServiceAlertClass;
  PROJECT_CREATE: IServiceAlertClass;
  PROJECT_VIEW: IServiceAlertClass;
  PROJECT_UPDATE: IServiceAlertClass;
  PROJECT_DELETE: IServiceAlertClass;
  PROJECT_CHECK_NAME: IServiceAlertClass;
  PROJECT_REPORT: IServiceAlertClass;

  TASKGROUP_LIST: IServiceAlertClass;
  TASKGROUP_CREATE: IServiceAlertClass;
  TASKGROUP_VIEW: IServiceAlertClass;
  TASKGROUP_UPDATE: IServiceAlertClass;
  TASKGROUP_DELETE: IServiceAlertClass;

  TASK_LIST: IServiceAlertClass;
  TASK_CREATE: IServiceAlertClass;
  TASK_VIEW: IServiceAlertClass;
  TASK_UPDATE: IServiceAlertClass;
  TASK_DELETE: IServiceAlertClass;
  TASK_SEND_PUSH: IServiceAlertClass;

  NOTE_LIST: IServiceAlertClass;
  NOTE_CREATE: IServiceAlertClass;
  NOTE_VIEW: IServiceAlertClass;
  NOTE_UPDATE: IServiceAlertClass;
  NOTE_DELETE: IServiceAlertClass;

  COMMENT_LIST: IServiceAlertClass;
  COMMENT_CREATE: IServiceAlertClass;
  COMMENT_VIEW: IServiceAlertClass;
  COMMENT_UPDATE: IServiceAlertClass;
  COMMENT_DELETE: IServiceAlertClass;

  ASSIGNMENT_LIST: IServiceAlertClass;
  ASSIGNMENT_CREATE: IServiceAlertClass;
  ASSIGNMENT_VIEW: IServiceAlertClass;
  ASSIGNMENT_UPDATE: IServiceAlertClass;
  ASSIGNMENT_DELETE: IServiceAlertClass;

  ASSIGNMENT_RESPONSE_LIST: IServiceAlertClass;
  ASSIGNMENT_RESPONSE_CREATE: IServiceAlertClass;
  ASSIGNMENT_RESPONSE_VIEW: IServiceAlertClass;
  ASSIGNMENT_RESPONSE_UPDATE: IServiceAlertClass;
  ASSIGNMENT_RESPONSE_DELETE: IServiceAlertClass;

  PARTICIPANT_LIST: IServiceAlertClass;
  PARTICIPANT_CREATE: IServiceAlertClass;
  PARTICIPANT_VIEW: IServiceAlertClass;
  PARTICIPANT_UPDATE: IServiceAlertClass;
  PARTICIPANT_DELETE: IServiceAlertClass;

  DNSA_OPTION: IServiceAlertClass;

  ME_DATA: IServiceAlertClass;

  DEFAULT: IServiceAlertClass;
  CLEAR: IServiceAlertClass;

  EMAIL_CAMPAIGN_LIST: IServiceAlertClass;
  EMAIL_CAMPAIGN_CREATE: IServiceAlertClass;
  EMAIL_CAMPAIGN_VIEW: IServiceAlertClass;
  EMAIL_CAMPAIGN_UPDATE: IServiceAlertClass;
  EMAIL_CAMPAIGN_DELETE: IServiceAlertClass;

  EMAIL_ITEM_LIST: IServiceAlertClass;
  EMAIL_ITEM_CREATE: IServiceAlertClass;
  EMAIL_ITEM_DRY_TEST: IServiceAlertClass;
  EMAIL_ITEM_PRISTINE: IServiceAlertClass;
  EMAIL_ITEM_VIEW: IServiceAlertClass;
  EMAIL_ITEM_UPDATE: IServiceAlertClass;
  EMAIL_ITEM_DELETE: IServiceAlertClass;

  VIDEO_CAMPAIGN_LIST: IServiceAlertClass;
  VIDEO_CAMPAIGN_CREATE: IServiceAlertClass;
  VIDEO_CAMPAIGN_VIEW: IServiceAlertClass;
  VIDEO_CAMPAIGN_UPDATE: IServiceAlertClass;
  VIDEO_CAMPAIGN_DELETE: IServiceAlertClass;

  VIDEO_ITEM_DRY_TEST: IServiceAlertClass;
  VIDEO_ITEM_LIST: IServiceAlertClass;
  VIDEO_ITEM_CREATE: IServiceAlertClass;
  VIDEO_ITEM_UPDATE: IServiceAlertClass;

  PROJECT_COPY: IServiceAlertClass;
  PROJECT_JOIN: IServiceAlertClass;
  PROJECT_DISJOIN: IServiceAlertClass;
  PROJECT_SUBSCRIBE: IServiceAlertClass;

  AGREE: IServiceAlertClass;
  CHOOSE_ACCOUNT: IServiceAlertClass;

  SURVEY_COPY: IServiceAlertClass;
  TOOLTIP_UPDATE: IServiceAlertClass;
}

export class ServiceAlertClass {
  static readonly ALERTS: IAlertsClass = {
    USER: <IServiceAlertClass>{
      id: "user",
      code: "us01",
    },
    ACCOUNT_LIST: <IServiceAlertClass>{
      id: "account_list",
      code: "ac01",
    },
    ACCOUNT_CREATE: <IServiceAlertClass>{
      id: "account_create",
      code: "ac02",
    },
    ACCOUNT_VIEW: <IServiceAlertClass>{
      id: "account_view",
      code: "ac03",
    },
    ACCOUNT_UPDATE: <IServiceAlertClass>{
      id: "account_update",
      code: "ac04",
    },
    ACCOUNT_DELETE: <IServiceAlertClass>{
      id: "account_delete",
      code: "ac05",
    },

    BRAND_LIST: <IServiceAlertClass>{
      id: "brand_list",
      code: "br01",
    },
    BRAND_CREATE: <IServiceAlertClass>{
      id: "brand_create",
      code: "br02",
    },
    BRAND_RESERVE: <IServiceAlertClass>{
      id: "brand_reserve",
      code: "br02_1",
    },
    BRAND_VIEW: <IServiceAlertClass>{
      id: "brand_view",
      code: "br03",
    },
    BRAND_UPDATE: <IServiceAlertClass>{
      id: "brand_update",
      code: "br04",
    },
    BRAND_DELETE: <IServiceAlertClass>{
      id: "brand_delete",
      code: "br05",
    },

    UMBRELLA_LIST: <IServiceAlertClass>{
      id: "umbrella_list",
      code: "gr01",
    },
    UMBRELLA_CREATE: <IServiceAlertClass>{
      id: "umbrella_create",
      code: "gr02",
    },
    UMBRELLA_VIEW: <IServiceAlertClass>{
      id: "umbrella_view",
      code: "gr03",
    },
    UMBRELLA_UPDATE: <IServiceAlertClass>{
      id: "umbrella_update",
      code: "gr04",
    },
    UMBRELLA_DELETE: <IServiceAlertClass>{
      id: "umbrella_delete",
      code: "gr05",
    },

    PROJECT_LIST: <IServiceAlertClass>{
      id: "project_list",
      code: "pr01",
    },
    PROJECT_CREATE: <IServiceAlertClass>{
      id: "project_create",
      code: "pr02",
    },
    PROJECT_VIEW: <IServiceAlertClass>{
      id: "project_view",
      code: "pr03",
    },
    PROJECT_UPDATE: <IServiceAlertClass>{
      id: "project_update",
      code: "pr04",
    },
    PROJECT_DELETE: <IServiceAlertClass>{
      id: "project_delete",
      code: "pr05",
    },
    PROJECT_CHECK_NAME: <IServiceAlertClass>{
      id: "check_name",
      code: "pr06",
    },
    PROJECT_REPORT: <IServiceAlertClass>{
      id: "project_report",
      code: "prr07",
    },

    TASKGROUP_LIST: <IServiceAlertClass>{
      id: "taskgroup_list",
      code: "fo01",
    },
    TASKGROUP_CREATE: <IServiceAlertClass>{
      id: "taskgroup_create",
      code: "fo02",
    },
    TASKGROUP_VIEW: <IServiceAlertClass>{
      id: "taskgroup_view",
      code: "fo03",
    },
    TASKGROUP_UPDATE: <IServiceAlertClass>{
      id: "taskgroup_update",
      code: "fo04",
    },
    TASKGROUP_DELETE: <IServiceAlertClass>{
      id: "taskgroup_delete",
      code: "fo05",
    },

    TASK_LIST: <IServiceAlertClass>{
      id: "task_list",
      code: "ta01",
    },
    TASK_CREATE: <IServiceAlertClass>{
      id: "task_create",
      code: "ta02",
    },
    TASK_VIEW: <IServiceAlertClass>{
      id: "task_view",
      code: "ta03",
    },
    TASK_UPDATE: <IServiceAlertClass>{
      id: "task_update",
      code: "ta04",
    },
    TASK_DELETE: <IServiceAlertClass>{
      id: "task_delete",
      code: "ta05",
    },
    TASK_SEND_PUSH: <IServiceAlertClass>{
      id: "task_send_push",
      code: "ta06",
    },

    NOTE_LIST: <IServiceAlertClass>{
      id: "note_list",
      code: "no01",
    },
    NOTE_CREATE: <IServiceAlertClass>{
      id: "note_create",
      code: "no02",
    },
    NOTE_VIEW: <IServiceAlertClass>{
      id: "note_view",
      code: "no03",
    },
    NOTE_UPDATE: <IServiceAlertClass>{
      id: "note_update",
      code: "no04",
    },
    NOTE_DELETE: <IServiceAlertClass>{
      id: "note_delete",
      code: "no05",
    },

    COMMENT_LIST: <IServiceAlertClass>{
      id: "comment_list",
      code: "co01",
    },
    COMMENT_CREATE: <IServiceAlertClass>{
      id: "comment_create",
      code: "co02",
    },
    COMMENT_VIEW: <IServiceAlertClass>{
      id: "comment_view",
      code: "co03",
    },
    COMMENT_UPDATE: <IServiceAlertClass>{
      id: "comment_update",
      code: "co04",
    },
    COMMENT_DELETE: <IServiceAlertClass>{
      id: "comment_delete",
      code: "co05",
    },

    ASSIGNMENT_LIST: <IServiceAlertClass>{
      id: "assignment_list",
      code: "as01",
    },
    ASSIGNMENT_CREATE: <IServiceAlertClass>{
      id: "assignment_create",
      code: "as02",
    },
    ASSIGNMENT_VIEW: <IServiceAlertClass>{
      id: "assignment_view",
      code: "as03",
    },
    ASSIGNMENT_UPDATE: <IServiceAlertClass>{
      id: "assignment_update",
      code: "as04",
    },
    ASSIGNMENT_DELETE: <IServiceAlertClass>{
      id: "assignment_delete",
      code: "as05",
    },

    ASSIGNMENT_RESPONSE_LIST: <IServiceAlertClass>{
      id: "assignment_response_list",
      code: "ar01",
    },
    ASSIGNMENT_RESPONSE_CREATE: <IServiceAlertClass>{
      id: "assignment_response_create",
      code: "ar02",
    },
    ASSIGNMENT_RESPONSE_VIEW: <IServiceAlertClass>{
      id: "assignment_response_view",
      code: "ar03",
    },
    ASSIGNMENT_RESPONSE_UPDATE: <IServiceAlertClass>{
      id: "assignment_response_update",
      code: "ar04",
    },
    ASSIGNMENT_RESPONSE_DELETE: <IServiceAlertClass>{
      id: "assignment_response_delete",
      code: "ar05",
    },

    PARTICIPANT_LIST: <IServiceAlertClass>{
      id: "participant_list",
      code: "pa01",
    },
    PARTICIPANT_CREATE: <IServiceAlertClass>{
      id: "participant_create",
      code: "pa02",
    },
    PARTICIPANT_VIEW: <IServiceAlertClass>{
      id: "participant_view",
      code: "pa03",
    },
    PARTICIPANT_UPDATE: <IServiceAlertClass>{
      id: "participant_update",
      code: "pa04",
    },
    PARTICIPANT_DELETE: <IServiceAlertClass>{
      id: "participant_delete",
      code: "pa05",
    },

    BRAND_MEMBER_LIST: <IServiceAlertClass>{
      id: "brand_member_list",
      code: "bm01",
    },
    BRAND_MEMBER_CREATE: <IServiceAlertClass>{
      id: "brand_member_create",
      code: "bm02",
    },
    BRAND_MEMBER_VIEW: <IServiceAlertClass>{
      id: "brand_member_view",
      code: "bm03",
    },
    BRAND_MEMBER_UPDATE: <IServiceAlertClass>{
      id: "brand_member_update",
      code: "bm04",
    },
    BRAND_MEMBER_DELETE: <IServiceAlertClass>{
      id: "brand_member_delete",
      code: "bm05",
    },

    BRAND_EMAIL_LIST_CREATE: <IServiceAlertClass>{
      id: "brand_email_create",
      code: "bel02",
    },
    BRAND_EMAIL_LIST_UPDATE: <IServiceAlertClass>{
      id: "brand_email_update",
      code: "bel04",
    },
    BRAND_EMAIL_LIST_DELETE: <IServiceAlertClass>{
      id: "brand_email_delete",
      code: "bel05",
    },

    ME_DATA: <IServiceAlertClass>{
      id: "me_data",
      code: "me00",
    },

    DNSA_OPTION: <IServiceAlertClass>{
      id: "dnsa_option",
      code: "dnsa00",
    },

    DEFAULT: <IServiceAlertClass>{
      id: "default",
      code: "00",
    },

    CLEAR: <IServiceAlertClass>{
      id: "clear",
      code: "-",
    },

    EMAIL_CAMPAIGN_LIST: <IServiceAlertClass>{
      id: "email_campaign_list",
      code: "ec01",
    },
    EMAIL_CAMPAIGN_CREATE: <IServiceAlertClass>{
      id: "email_campaign_create",
      code: "ec02",
    },
    EMAIL_CAMPAIGN_VIEW: <IServiceAlertClass>{
      id: "email_campaign_view",
      code: "ec03",
    },
    EMAIL_CAMPAIGN_UPDATE: <IServiceAlertClass>{
      id: "email_campaign_update",
      code: "ec04",
    },
    EMAIL_CAMPAIGN_DELETE: <IServiceAlertClass>{
      id: "email_campaign_delete",
      code: "ec05",
    },

    EMAIL_ITEM_LIST: <IServiceAlertClass>{
      id: "email_item_list",
      code: "ei01",
    },
    EMAIL_ITEM_CREATE: <IServiceAlertClass>{
      id: "email_item_create",
      code: "ei02",
    },
    EMAIL_ITEM_DRY_TEST: <IServiceAlertClass>{
      id: "email_item_dry_test",
      code: "edry01",
    },
    EMAIL_ITEM_PRISTINE: <IServiceAlertClass>{
      id: "email_item_pristine",
      code: "epri01",
    },
    EMAIL_ITEM_VIEW: <IServiceAlertClass>{
      id: "email_item_view",
      code: "ei03",
    },
    EMAIL_ITEM_UPDATE: <IServiceAlertClass>{
      id: "email_item_update",
      code: "ei04",
    },
    EMAIL_ITEM_DELETE: <IServiceAlertClass>{
      id: "email_item_delete",
      code: "ei05",
    },

    VIDEO_ITEM_DRY_TEST: <IServiceAlertClass>{
      id: "video_item_dry_test",
      code: "viry01",
    },
    VIDEO_ITEM_LIST: <IServiceAlertClass>{
      id: "video_item_list",
      code: "vi01",
    },
    VIDEO_ITEM_CREATE: <IServiceAlertClass>{
      id: "video_item_create",
      code: "vi02",
    },
    VIDEO_ITEM_UPDATE: <IServiceAlertClass>{
      id: "video_item_update",
      code: "vi04",
    },

    VIDEO_CAMPAIGN_LIST: <IServiceAlertClass>{
      id: "video_campaign_list",
      code: "vc01",
    },
    VIDEO_CAMPAIGN_CREATE: <IServiceAlertClass>{
      id: "video_campaign_create",
      code: "vc02",
    },
    VIDEO_CAMPAIGN_VIEW: <IServiceAlertClass>{
      id: "video_campaign_view",
      code: "vc03",
    },
    VIDEO_CAMPAIGN_UPDATE: <IServiceAlertClass>{
      id: "video_campaign_update",
      code: "vc04",
    },
    VIDEO_CAMPAIGN_DELETE: <IServiceAlertClass>{
      id: "video_campaign_delete",
      code: "vc05",
    },

    PROJECT_JOIN: <IServiceAlertClass>{
      id: "project_join",
      code: "pr0j",
    },

    PROJECT_DISJOIN: <IServiceAlertClass>{
      id: "project_disjoin",
      code: "pr0d",
    },

    PROJECT_COPY: <IServiceAlertClass>{
      id: "project_copy",
      code: "pr0c",
    },

    PROJECT_SUBSCRIBE: <IServiceAlertClass>{
      id: "project_subscribe",
      code: "pr0s",
    },

    AGREE: <IServiceAlertClass>{
      id: "agree",
      code: "agr00",
    },
    CHOOSE_ACCOUNT: <IServiceAlertClass>{
      id: "choose_account",
      code: "cha00",
    },

    SURVEY_COPY: <IServiceAlertClass>{
      id: "survey_copy",
      code: "srvy0c",
    },

    TOOLTIP_UPDATE: <IServiceAlertClass>{
      id: "tooltip_update",
      code: "tlt0u",
    },
  };
}
