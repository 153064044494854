import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ShareOverlayRef } from './share-overlay-ref';
import { ShareOverlayComponent } from './share-overlay.component';

@Injectable()
export class ShareOverlayService {

    private overlayRef: OverlayRef;
    private elementRef: ElementRef

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private overlayPositionBuilder: OverlayPositionBuilder,
    ) {
    }

    open(el) {
        this.elementRef = el;
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        // Instantiate remote control
        const dialogRef = new ShareOverlayRef(this.overlayRef);
        const injector = this.createInjector(dialogRef);

        const confirmRef: ComponentRef<ShareOverlayComponent>
            = this.overlayRef.attach(new ComponentPortal(ShareOverlayComponent, null, injector));
        // confirmRef.instance.text = text || this.text;

        // Subscribe to a stream that emits when the backdrop was clicked
        this.overlayRef.backdropClick().subscribe(_ => dialogRef.close(false));

        return dialogRef;
    }

    private createInjector(dialogRef: ShareOverlayRef): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(ShareOverlayRef, dialogRef);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                offsetY: -5,
                offsetX: 0
            }]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'mgc-overlay-backdrop',
            panelClass: 'share-overlay',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
            width: '400px'
        });

        return overlayConfig;
    }
}
