
import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ServiceAlertClass, IAlertsClass } from '@rallysite/components/alert';
import { ServiceStates } from '@rallysite/global-interfaces';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { Account } from '@board-accounts/account.model';
import { AccountDataComponent } from '../account-data/account-data.component';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

const REFs = {
  AccountDataComponent: 'AccountDataComponent',
  PasswordChangeComponent: 'PasswordChangeComponent'
}

@Component({
  selector: 'account-modal',
  templateUrl: './account-modal.html',
})
export class AccountModal implements OnInit, OnDestroy {
  readonly REFs = REFs;
  readonly serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  @ViewChild(PasswordChangeComponent) passwordChange: PasswordChangeComponent;
  @ViewChild(AccountDataComponent) accountData: AccountDataComponent;

  state: number;
  refInView: string;
  account: Account;

  processing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AccountModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      account: Account
      edit: boolean,
      compInView: 'PasswordChangeComponent' | 'AccountDataComponent'
    }) {
    this.refInView = data.compInView;
    this.account = data.account;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSave() {
    let req: Observable<any>;
    switch (true) {
      case !!this.passwordChange:
        this.processing = true;
        req = this.passwordChange.onSave();
        break;
      case !!this.accountData:
        this.processing = true;
        req = this.accountData.onSave();
        break;
    }

    req.pipe(finalize(() => this.processing = false)).subscribe(result => {
      if (result) {
        this.closeDialog();
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
