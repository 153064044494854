import { Injectable, Injector, ComponentRef } from "@angular/core";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ViewerComponent } from "./viewer.component";
import { ViewerOverlayRef } from "./viewer-overlay-ref";
import { VIEWER_OVERLAY_DATA } from "./viewer-overlay.tokens";
import { ViewerOverlayConfig, DEFAULT_CONFIG } from "./viewer-overlay.config";

@Injectable()
export class ViewerOverlayService {

  // Inject overlay service
  constructor(
    private injector: Injector,
    private overlay: Overlay) {
  }

  open(config: ViewerOverlayConfig = {}) {
    // Returns an OverlayRef (which is a PortalHost)
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new ViewerOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    return dialogRef;
  }

  private createOverlay(config: ViewerOverlayConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ViewerOverlayConfig, dialogRef: ViewerOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ViewerComponent, null, injector);
    const containerRef: ComponentRef<ViewerComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: ViewerOverlayConfig, dialogRef: ViewerOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(ViewerOverlayRef, dialogRef);
    injectionTokens.set(VIEWER_OVERLAY_DATA, config.data);

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }


  private getOverlayConfig(config: ViewerOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      // Other strategies are .noop(), .reposition(), or .close()
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }



}