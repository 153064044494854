import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

export class MessageBusActions {
    public static APP_LOADED = '__app_loaded';
    public static UMBRELLAS_LOADED = '__umbrellas_loaded';
    public static TOGGLE_ONE_PANEL = '__toggle_one_panel';

    public static EMAILS_LOADED = '__emails_loaded';
    public static VIDEOS_LOADED = '__videos_loaded';

    public static CREATE_NEW_ITEM = '__create_new_item';

    public static ACCOUNT_METADATA = '__account_metadata';

    public static ACTIVATE_ACCOUNT = '__activate-account';
    public static ACTIVATE_UMBRELLA = '__activate-umbrella';
    public static ACTIVATE_PROJECT = '__activate-project';
    public static ACTIVATE_TASK = '__activate-task';
    public static ACTIVATE_SCHEDULED_TASk = '__activate-scheduled-task';
    public static ACTIVATE_EMAIL_CAMPAIGN = '__activate-email-campaign';
    public static ACTIVATE_EMAIL_ITEM = '__activate-email-item';

    public static ACTIVATE_VIDEO_ITEM = '__activate-video-item';
    public static ACTIVATE_VIDEO_CAMPAIGN = '__activate-video-campaign';

    public static SHOW_PANEL = '__show_panel';
    public static SHOW_SECONDARY_NAVBAR = '__show_secondary_navbar';
    public static CLOSE_SECONDARY_NAVBAR = '__close_secondary_navbar';

    public static CONTENT_SCROLL = '__content_scroll';

    public static OPEN_RIGHT_DRAWER = '__open_right_drawer';
    public static CLOSE_RIGHT_DRAWER = '__close_right_drawer';
    public static TOGGLE_RIGHT_DRAWER = '__toggle_right_drawer';
    public static RIGHT_DRAWER_STATE = '__right_drawer_state';

    public static OPEN_M2ND_PANEL = '__open_m2nd_panel';
    public static CLOSE_M2ND_PANEL = '__close_m2nd_panel';
    public static TOGGLE_M2ND_PANEL = '__toggle_m2nd_panel';
    public static M2ND_PANEL_STATE = '__m2nd_panel_state';
    public static M2ND_COMPONENT_NAME = '__m2nd_component_name';

    public static OPEN_MENU_PANEL = '__open_menu_panel';
    public static CLOSE_MENU_PANEL = '__close_menu_panel';
    public static MENU_PANEL_SWITCH_CONTENT = '__menu_panel_switch_content';

    public static OPEN_SIDE_NAV_MENU = '__open_side_nav_menu';
    public static CLOSE_SIDE_NAV_MENU = '__close_side_nav_menu';

    public static NOTIFICATION_NEW = '__notifcation_new';
    public static NOTIFICATION_READ = '__notifcation_read';

    public static NEW_NOTE_MODAL = '__new_note_modal';

    public static NAVIGATION_SCROLL_UP = '__navigation_scroll_up';
    public static NAVIGATION_SCROLL_DOWN = '__navigation_scroll_down';
    public static NAVIGATION_HEADER_RESET = '__navigation_header_reset';
    public static NAVIGATION_HEADER_PUSH_OUT = '__navigation_header_push_out';
    
    public static NOTE_FLAGGED = '__note_flagged';

    public static THEME_UPDATED = '__theme_updated';

    public static NO_TOP_TASKS = '__no_top_tasks';


    public static PUBLISH_DATA_UPDATE = '__publish_data_update';
    
    public static GO_BACK = '__go_back';

    public static BRAND_PAGE_UP = '__brand_page_yp';

    public static TASK_REMOVE = "__task_remove";
    public static TASK_UPDATE = "__task_update";

    public static AUTH_TAB_SELECT = '__auth_tab_select';

    public static LOGOUT = '__cp_logout';

    public static SCROLL_Y = '__rs_item_scroll_Y';
    public static SEARCH_ITEM_ENGAGED = '__search_item_enaged';

    public static SURVEY_COPY = '__survey_copy';
}

@Injectable({
    providedIn: 'root'
})
export class MessageBusService {
    private subjects: Subject<any>[] = [];

    publish(eventName: string, data: any = null) {
        // ensure a subject for the event name exists
        this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

        // publish event
        this.subjects[eventName].next(data);
    }

    on(eventName: string): Observable<any> {
        // ensure a subject for the event name exists
        this.subjects[eventName] = this.subjects[eventName] || new Subject<any>();

        // return observable 
        return this.subjects[eventName].asObservable();
    }
}