import { finalize, take } from 'rxjs/operators';
import { Component, OnInit, Input, SimpleChange, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IFile as IAttachment } from './file-model';
import { Note } from '../note';
import { FileService } from './file.service';

import { IServiceStates } from '@rallysite/global-interfaces';
import { BoardViewService } from '@board/_services';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { Comment } from '@panel-components/comments';
import { User, UserService } from '@rallysite/user';
import { Subscription } from 'rxjs';
import { TaskV2AttachmentsBlock } from '@items/task-v2/blocks/attachments-block/attachments-block';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  @Input() data: IAttachment[];
  @Input() model: Note | Comment | TaskV2AttachmentsBlock;

  @Input('preview') _preview = false;
  get preview() {
    return this._preview !== false;
  }

  @Output() imageOrder: EventEmitter<string[]> = new EventEmitter<string[]>(null);

  isDownloading = false;
  user: User;
  userSubscription: Subscription;

  state: number;
  get states(): IServiceStates {
    return FileService.STATES;
  }

  prevAttachments: IAttachment[] = [];

  images: {
    id: string,
    small: string,
    medium: string,
    large: string
    model?: any
  }[] = [];

  fImages: IAttachment[] = [];
  files: IAttachment[] = [];

  selected: Array<boolean> = [];
  imagesSelected = 0;

  get smalView() {
    return this.boardView.smallView();
  }

  constructor(
    private fileService: FileService,
    private userService: UserService,
    private boardView: BoardViewService,
    public dialog: MatDialog,

  ) {
  }

  trackByFn(index: number, file: IAttachment): string {
    return file.Id;
  }

  rotateLeft($ev, image: IAttachment) {
    if ($ev) {
      $ev.stopPropagation();
    }
    image.rotateLeft();
  }

  imageStyle(image: IAttachment) {
    const url = image.previewUrl('medium', this.fileService.endPoint);

    const style = Object.assign({}, image.style, {
      'background-image': `url('${url}')`
    });
    return style;
  }

  handleAttachmentsByType(attachments: IAttachment[]) {
    this.images = [];
    this.fImages = [];
    this.files = [];

    for (const i in attachments) {
      const attachment = attachments[i];
      if (attachment.isImage) {
        if (!this.preview) {
          this.fImages.push(attachment);
        } else {
          this.images.push({
            id: attachment.Id,
            small: attachment.previewUrl('small', this.fileService.endPoint),
            medium: attachment.previewUrl('medium', this.fileService.endPoint),
            large: attachment.previewUrl('large', this.fileService.endPoint),
            model: attachment
          });
        }
      } else {
        this.files.push(attachment);
      }
    }

    this.prevAttachments = attachments.slice();
  }

  previewFile($ev, file: IAttachment) {
    if ($ev) {
      $ev.stopPropagation();
    }
    this.dialog.open(PdfPreviewComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      backdropClass: 'underlay',
      data: {
        file: file,
        previewUrl: file.previewUrl('small', this.fileService.endPoint)
      }
    });
  }

  download($ev, file: IAttachment) {
    if ($ev) {
      $ev.stopPropagation();
    }

    if (this.isDownloading) {
      return;
    }

    file.inProgress = true;
    this.fileService.download(file).pipe(
      finalize(() => {
        file.inProgress = false;
      }),
      take(1)
    ).subscribe();
  }

  toggleDelete($ev, file: IAttachment) {
    if ($ev) {
      $ev.stopPropagation();
    }

    file.removed = !file.removed;
  }


  ngOnInit() {
    this.boardView.handle();

    this.userSubscription = this.userService.user$.subscribe(user => {
      this.user = user;
    });
  }

  ngOnChanges(change: SimpleChange) {
    if (change['data'] && change['data'].currentValue) {
      this.handleAttachmentsByType(this.fileService.sort(this.data, this.model.Settings.imageOrder));
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fImages, event.previousIndex, event.currentIndex);
    this.imageOrder.emit(this.images.map(i => i.id));
  }
}
