import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAlertsClass, ServiceAlertClass, AlertService } from '@rallysite/components/alert';
import { UserService } from '@rallysite/user';
import { Project } from '@board/items/project';
import { MustActivateComponent } from '@board/_components/must-activate';
import { ServiceStates } from '@rallysite/global-interfaces';
import { NewParticipantListComponent } from './new-participant-list/new-participant-list.component';
import { NewParticipantDataComponent } from './new-participant-data/new-participant-data.component';

import { saveAs } from 'file-saver';

const REFs = {
  NewParticipantDataComponent: 'NewParticipantDataComponent',
  NewParticipantListComponent: 'NewParticipantListComponent',
  MustActivateComponent: 'MustActivateComponent',
};

@Component({
  selector: 'participant-add-modal',
  templateUrl: './participant-add-modal.html',
  styleUrls: ['./participant-add-modal.scss']
})
export class ParticipantAddModal implements OnInit {
  get REFs() {
    return REFs;
  }

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ParticipantAddModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      project: Project
    }) {
  }

  get activated() {
    return this.userService.user._isConfirmed;
  }

  get showResend() {
    if (this.participantData) {
      return this.participantData.resend.show;
    } else if (this.mustActivate) {
      return true;
    }

    return false;
  }

  get resent() {
    if (this.participantData) {
      return this.participantData.participant.resend.done;
    }
    return false;
  }

  get processing() {
    let isProcessing = false;
    switch (true) {
      case !!this.participantData:
        isProcessing = this.participantData.state === ServiceStates.STATES.PROCESSING;
        break;
      case !!this.participantList:
        isProcessing = this.participantList.processing;
        break;
      case !!this.mustActivate:
        isProcessing = this.mustActivate.processing;
        break;
    }
    return isProcessing;
  }

  get resending() {
    let isResending = false;
    switch (true) {
      case !!this.participantData:
        isResending = this.participantData.participant._state === ServiceStates.STATES.RESENDING;
        break;
      case !!this.mustActivate:
        isResending = this.mustActivate.resending;
        break;
    }
    return isResending;
  }

  get disabled() {
    let isDisabled = true;
    switch (true) {
      case !!this.participantList:
        isDisabled = this.participantList.disabled;
        break;
    }
    return isDisabled;
  }

  @ViewChild(NewParticipantDataComponent) participantData: NewParticipantDataComponent;
  @ViewChild(NewParticipantListComponent) participantList: NewParticipantListComponent;

  @ViewChild(MustActivateComponent) mustActivate: MustActivateComponent;

  refInView: string;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  choseAddType = false;
  addType: 'manual' | 'upload' = 'manual';

  /** SAMPLE CSV file download */
  sampleFileName = 'ExampleMailingList.csv';
  sampleRows = [
    ['Email'],
    ['errxn@msn.com'],
    ['telbij@gmail.com'],
    ['agapow@yahoo.com']
  ];

  onSubmit() {
    if (!!this.participantList && this.disabled) {
      return;
    }

    this.alertService.clear();

    switch (true) {
      case !!this.participantData:
        this.participantData.onSubmit();
        break;
      case !!this.participantList:
        this.participantList.onSubmit();
        break;
      case !!this.mustActivate:
        this.mustActivate.onSubmit();
        break;
    }
  }

  resend() {
    this.alertService.clear();
    switch (true) {
      case !!this.participantData:
        this.participantData.resendInvitation();
        break;
      case !!this.mustActivate:
        this.mustActivate.resendCode();
        break;
    }
  }

  closeDialog(): void {
    switch (true) {
      case !!this.participantData:
      case !!this.participantList:
      case this.choseAddType:
        this.dialogRef.close();
        break;
      case !!this.mustActivate:
        if (this.mustActivate.activated) {
          this.refInView = REFs.NewParticipantDataComponent;
        } else {
          this.dialogRef.close();
        }
        break;
    }
  }

  selectInputAs(inputType) {
    if (inputType === 'manual') {
      this.refInView = REFs.NewParticipantDataComponent;
    } else {
      this.refInView = REFs.NewParticipantListComponent;
    }
    this.choseAddType = false;
  }
  downloadSample(ev) {

    const csvContent = this.sampleRows.map(e => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'csv' });
    saveAs(blob, this.sampleFileName);

    ev.stopPropagation();
    ev.preventDefault();
    return false;
  }
  /** */

  ngOnInit() {

    if (!this.activated) {
      this.refInView = REFs.MustActivateComponent;
      // } else if (ParticipantService.hasLevelOrAboveRights('level-0', this.data.project._participant)) {
    } else if (this.userService.user.TM) {
      this.choseAddType = true;
    } else {
      this.refInView = REFs.NewParticipantDataComponent;
    }

  }
}
