import { take } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, HostBinding, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Project } from '../project';

import { Umbrella } from '@board/items/umbrella';
import { ProjectModal } from '@items/project/modal';
import { UserService} from '@rallysite/user';
import { PopoverService } from '@board/_components/popover/popover.service';
import { PanelComponents, IPanelComponent } from '@panel-components';
import { CurrentResourceService, PermissionService } from '@board/_services';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { ThemingService } from '@libraries/theming/theming.service';
import { ProjectService } from '@items/project';
import { ParticipantService } from '@panel-components/participants';
import { ITasksTab, TASKS_TABS } from '../tasks-tabs';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageBusActions, MessageBusService } from '@rallysite/global-services';
import { DEFAULT_BRAND_UNAME } from '@board-brands/brand.model';

@Component({
  selector: 'board-project-selected',
  templateUrl: './project-selected.component.html',
  styleUrls: ['./project-selected.component.scss'],
})
export class ProjectSelectedComponent implements OnInit, AfterViewInit {

  get panels() { return PanelComponents; }

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private popoverService: PopoverService,
    private permissionService: PermissionService,
    private ga: GoogleAnalyticsService,
    private themingService: ThemingService,
    private projectService: ProjectService,
    private currentResource: CurrentResourceService,
    private route: ActivatedRoute,
    private messageBus: MessageBusService,
  ) { }

  get tabs(): ITasksTab[] {
    return Object.keys(TASKS_TABS).map(h => TASKS_TABS[h]);
  }
  @HostBinding('class') setClasses = 'mgc-project white';

  @Input() currentItem: any;
  @Input() project: Project;
  @Input() umbrella: Umbrella;
  @Input() smallView = false;

  @Output() backTo: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedTab: EventEmitter<ITasksTab> = new EventEmitter<ITasksTab>(null);

  private alive = true;
  showRemiderPopover = false;

  publicUrl: string;

  /** TABS Logic */
  allowTabs = false;
  private tabId: string;


  sidePanels: IPanelComponent[];
  allowProjectUpdate = false;

  private bootstrapTabs() {
    let params: Params = {};
    if (this.route.snapshot.paramMap.keys.length) {
      params = this.route.snapshot.params;
    } else if (this.route.snapshot.queryParamMap.keys.length) {
      params = this.route.snapshot.queryParams;
    }

    if (params.t) {
      this.setTab(TASKS_TABS.TASKS);
    } else if (params.s) {
      this.setTab(TASKS_TABS.SCHEDULED_TASKS);
    } else {
      this.setTab(this.tabs.find(t => t.default));
    }
  }

  isTabSelected(tab: ITasksTab): boolean {
    if (!this.tabId) { return !!tab.default; }
    return this.tabId == tab.id;
  }
  setTab(tab: ITasksTab) {
    this.tabId = tab.id;
    this.selectedTab.emit(tab);

    // triggers the changes in platform
    this.currentResource.currentNavTab = tab;
  }

  /** END TABS Logic */

  goBack() {
    this.messageBus.publish(MessageBusActions.GO_BACK);
  }

  openSettings(panel: IPanelComponent) {
    this.dialog.open(ProjectModal, this.project.settingsOptions({
      panel: panel,
      umbrella: this.umbrella,
      edit: true
    })).afterClosed().pipe(
      take(1))
      .subscribe(() => {
        if (this.project && this.project._state == 'remove') {
          this.backTo.next('home');
        }
      });
  }
  ngOnInit() {
    this.allowProjectUpdate = this.permissionService.allowsAction('update.project', this.project, { silent: true, project: this.project });
    const allowViewParticipants = this.permissionService.allowsAction('view.participant', this.project._participant, { silent: true, project: this.project });
    this.bootstrapTabs();

    this.allowTabs = ParticipantService.hasLevelOrAboveRights('level-1', this.project._participant);

    this.sidePanels = this.project.sidePanels.filter(panel => {
      return panel.id === PanelComponents.STATISTICS.id ||
        panel.id === PanelComponents.SETTINGS_PROJECT_NAME.id ||
        panel.id === PanelComponents.SETTINGS_PROJECT_E_MESSAGE.id ||
        panel.id === PanelComponents.SETTINGS_PROJECT_THEME.id ||
        panel.id === PanelComponents.SETTINGS_PROJECT_TAGGING.id
        ? this.allowProjectUpdate : (panel.id === PanelComponents.PARTICIPANTS.id ? allowViewParticipants :
          (panel.id === PanelComponents.SETTINGS_RESTRICTED_USERS.id ? !this.allowProjectUpdate : true));
    });

    if (this.project.IsPublic) {
      this.projectService.getProjectPubdata(this.project).pipe(take(1))
        .subscribe(pubdata => {
          const brand = (pubdata || {})._brand;
          const brandId = brand && brand.UName ? brand.UName : DEFAULT_BRAND_UNAME;
          const projectId = this.project.UName ? this.project.UName : this.project.Id;

          this.publicUrl = `/library/${brandId}/${projectId}`;
        });
    }

  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewInit() {
    this.themingService.setTheme(this.project.theme);
  }

}
