import { generateId } from "../generate_block_id";
import { IUIBlock } from "../ui-block.interface";
import {
    UIBlockAction,
    UIBlockType,
    UI_BLOCK_PREFIX
} from "../types";

export class UITextBlock implements IUIBlock {
    Type: UIBlockType = UIBlockType.TEXT;
    Id: string;
    OptionId: string;
    BlockId: string;
    Content: string = '';
    Position: number;

    CreateDate: string;
    UpdateDate: string;

    _httpAction: UIBlockAction = UIBlockAction.UPDATE;

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${UI_BLOCK_PREFIX.TEXT}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            OptionId: this.OptionId,
            Type: this.Type,
            Content: this.Content,
            Position: this.Position,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }

}
