export interface IFile {
  allowActions: any;
    Id?: string;
    NoteId: string;
    TaskId: string;
    AccountId: string;
    Status: string;
    MimeType: string;
    SystemName: string;
    FileName: string;
    Rotation: number;
    Scale?: number;
    Size: number;
    CreateDate: string;
    UpdateDate: string;

    __rawItem: any;

    removed: boolean;

    isImage: boolean;
    isPdf: boolean;
    type: {
        class: string,
        ext: string
    };

    isNew: boolean;
    isReadyForUpload: boolean;
    isRemoving: boolean;
    isUploading: boolean;
    inProgress: boolean;
    hasError: boolean;
    error: any;

    viewable: boolean;
    style: any;

    previewUrl(type: 'small' | 'medium' | 'large', endPoint?: string);
    isDirty(): boolean;

    rotateLeft(): void;
    updateStyle(): void;
}

export class File implements IFile {
    Id: string;
    NoteId: string;
    TaskId: string;
    AccountId: string;
    Status: string;
    MimeType: string;
    SystemName: string;
    FileName: string;
    Rotation = 0;
    Scale = 1;
    Size: number;
    CreateDate: string;
    UpdateDate: string;

    __rawItem: any;

    isImage = false;
    isPdf = false;

    private _type: {
        class: string,
        ext: string
    };
    removed = false;
    isRemoving: boolean;

    get viewable() {
        return this.isPdf;
    }
    get isNew(): boolean {
        return this.__rawItem && !this.__rawItem.isReady;
    }

    get isReadyForUpload(): boolean {
        return this.__rawItem && this.__rawItem.isReady && !this.__rawItem.isUploading;
    }
    get isUploading(): boolean {
        return this.__rawItem && this.__rawItem.isUploading;
    }

    inProgress: boolean;
    get hasError(): boolean {
        return !!this.error;
    }
    error: any;

    get allowActions(): boolean {
        return !this.__rawItem || this.isNew;
    }

    style: any = {};

    initialData: any;

    constructor(data: any) {
        for (const prop in data) {
            this[prop] = data[prop];
        }

        this.isImage = (this.MimeType || (this.__rawItem ? this.__rawItem.file.type : '')).indexOf('image') >= 0;
        this.isPdf = /pdf/.test(this.MimeType || (this.__rawItem ? this.__rawItem.file.type : ''));

        this.Size || (this.Size = this.__rawItem ? this.__rawItem.file.size : 0);
        this.FileName || (this.FileName = this.__rawItem ? this.__rawItem.file.name : 0);

        this.handleType();
        this.updateStyle();

        this.initialData = Object.assign({}, data);
    }
    handleType() {
        const types = ['generic', 'csv', 'pdf', 'doc', 'txt', 'ppt', 'xls', 'xlsx', 'psd', 'ai'];
        const type = (this.FileName || (this.__rawItem ? this.__rawItem.file.name : '')).split('.').pop().toLowerCase();
        this._type = {
            ext: type,
            class: types.indexOf(type) > 0 ? type : types[0]
        };
    }
    get type() {
        return this._type;
    }

    previewUrl(type: 'small' | 'medium' | 'large' = 'medium', endPoint?: string) {
        if (this.__rawItem) {
            // check dynamic property
            if (this.__rawItem.previewUrl) {
                return this.__rawItem.previewUrl;
            }
            this.__rawItem.previewUrl = URL.createObjectURL(this.__rawItem.file.rawFile);
            return this.__rawItem.previewUrl;
        }
        return `${endPoint}/${this.Id}/url/${type}`;
    }

    rotateLeft(): void {
        this.Rotation = ((this.Rotation || 0) - 90) % 360;
        if (this.Rotation < 0) {
            this.Rotation += 360;
        }

        this.updateStyle();
    }

    updateStyle(): void {
        this.style.transform = `translate(0px, 0px) rotate(${this.Rotation}deg) scale(${this.Scale})`;
        this.style.msTransform = this.style.transform;
        this.style.webkitTransform = this.style.transform;
        this.style.oTransform = this.style.transform;
    }

    isDirty(): boolean {
        const props = ['Rotation'];
        for (const i in props) {
            if (this[props[i]] !== this.initialData[props[i]]) {
                return true;
            }
        }

        return false;
    }

    toDb() {
        return {
            Id: this.Id,
            AccountId: this.AccountId,
            NoteId: this.NoteId,
            TaskId: this.TaskId,
            Rotation: this.Rotation,
            UpdateDate: this.UpdateDate,
        };
    }

}

