import {
    animation,
    style,
    animate,
    keyframes
} from '@angular/animations';

const DEFAULT_TIMING = .3;

export function fadeInX(oa=0, ob=1, a=0, b=0, timing = DEFAULT_TIMING) {
    return animation(
        animate(
            '{{ timing }}s {{ delay }}s',
            keyframes([
                style({
                    opacity: oa,
                    transform: 'translate3d({{ a }}, 0, 0)',
                    offset: 0
                }),
                style({
                    opacity: ob,
                    transform: 'translate3d({{ b }}, 0, 0)',
                    offset: 1
                })
            ])
        ),
        { params: { timing: timing, delay: 0, a, b } }
    );
}

export function fadeOutX(oa=1, ob=0, a=0, b=0, timing = DEFAULT_TIMING) {
    return animation(
        animate(
            '{{ timing }}s {{ delay }}s',
            keyframes([
                style({
                    opacity: oa,
                    transform: 'translate3d({{ a }}, 0, 0)',
                    offset: 0
                }),
                style({
                    opacity: ob,
                    transform: 'translate3d({{ b }}, 0, 0)',
                    offset: 1
                })
            ])
        ),
        { params: { timing: timing, delay: 0, a, b } }
    );
}

export const fadeIn = fadeInX();
export const fadeOut = fadeOutX();