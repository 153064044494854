
import { Component, OnInit, Input } from '@angular/core';
import { IFile as IAttachment } from './file-model';

@Component({
  selector: 'attachment-overlay',
  templateUrl: './attachment-overlay.component.html',
  styleUrls: ['./attachment-overlay.component.scss']
})
export class AttachmentOverlayComponent implements OnInit {

  @Input() item: IAttachment;
  @Input() progressOnly: boolean = false;

  constructor(

  ) {
  }

  ngOnInit() {

  }
}
