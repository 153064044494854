import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MgcDialogModule, MgcButtonModule } from '@rallysite/mgc-components';
import { MatRadioModule } from '@angular/material/radio';
import { MultiGalleryModule } from '@libraries/multi-gallery/x_multi-gallery.module';
import { AlertModule } from '@rallysite/components/alert';

import { ThemingComponent } from './theming.component';

import { ITheme } from './themes/theme.interface';
import { THEMES, ACTIVE_THEME, THEME_TYPE } from './tokens';

import { CustomColorModal } from './themes/custom-color-theme/modal/custom-color.modal';
import { CustomImageModal } from './themes/image-theme/modal/custom-image.modal';

import { CustomColorComponent } from './themes/custom-color-theme/modal/custom-color.component';
import { CustomImageComponent } from './themes/image-theme/modal/custom-image.component';
import { NavigationPipeModule } from './themes/image-theme/navigation-image.pipe';
import { ColorChromeModule } from 'ngx-color/chrome';


export interface ThemeOptions {
    themes: ITheme[];
    active?: ITheme;
    type?: 'light' | 'dark'
}

@NgModule({
    imports: [
        CommonModule,
        MgcDialogModule,
        MgcButtonModule,
        MatRadioModule,
        AlertModule,
        MultiGalleryModule,
        NavigationPipeModule,
        ColorChromeModule
    ],
    declarations: [
        ThemingComponent,
        CustomImageComponent,
        CustomColorComponent,
        CustomImageModal,
        CustomColorModal
    ],
    exports: [
        ThemingComponent,
    ]
})
export class ThemingModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders<ThemingModule> {
        return {
            ngModule: ThemingModule,
            providers: [{
                provide: THEMES,
                useValue: options.themes
            }, {
                provide: ACTIVE_THEME,
                useValue: options.active
            }, {
                provide: THEME_TYPE,
                useValue: options.type
            }]
        };
    }
}
