import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AssessmentButtonComponent } from './assessment-button';
import { SurveyButtonModule } from '../survey-modal/survey-button.module';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        SurveyButtonModule
    ],
    declarations: [
        AssessmentButtonComponent,
    ],
    exports: [
        AssessmentButtonComponent,
    ]
})
export class AssessmentButtonModule { }
