import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MgcFormFieldComponent } from './form-field.component';
import { AutosizeDirective } from '@board/_components/textarea-autosize.directive';

@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [
        MgcFormFieldComponent,
        AutosizeDirective
    ],

    exports: [
        MgcFormFieldComponent,
        AutosizeDirective
    ]
})
export class MgcFormFieldModule {
}