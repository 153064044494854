import { ViewChild, Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import { SignatureSettingsComponent } from "./signature-settings.component";
import { ProjectService } from "../../project.service";
import { finalize, take } from "rxjs/operators";
import { Umbrella } from "@board/items/umbrella";
import { Project } from "../../project";
import { PermissionService } from "@board/_services";

@Component({
  selector: 'project-settings-e-message',
  templateUrl: './project-settings-e-message.component.html',
  styles: [`
    :host{
      display: block;
      height: 100%;
    }
  `]
})
export class ProjectSettingsEMessageComponent implements OnInit {
  @Input() data: any;
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(SignatureSettingsComponent) signatureComponent: SignatureSettingsComponent;

  project: Project;
  umbrella: Umbrella;
  ready: boolean = false;
  allowUpdate: boolean = false;

  state: number;
  get states() {
    return ProjectService.STATES;
  }

  get components() {
    return [this.signatureComponent]
  }

  get processing() {
    return this.state === this.states.PROCESSING;
  }

  constructor(
    private projectService: ProjectService,
    private permissionService: PermissionService
  ) {
  }

  get isDirty() {
    return this.components.find(c => c.isDirty);
  }

  recursiveAssign(a, b) {
    if (Object(b) !== b) return b;
    if (Object(a) !== a) a = {};
    for (let key in b) {
      a[key] = this.recursiveAssign(a[key], b[key]);
    }
    return a;
  }

  onSave(): void {
    if (this.processing) {
      return;
    }

    if (this.components.find(c => !c.submitForm())) {
      return;
    }

    this.state = this.states.PROCESSING;
    let data: any = {};
    this.components.forEach(c => {
      let values = c.formValues();
      data = this.recursiveAssign(data, values);
    })

    const publish = { ...this.project.Settings.publish, ...data.Settings.publish };
    this.project.Settings.publish = publish;
    this.project.Settings.eMessage = data.Settings.eMessage;

    this.projectService.update(this.project, this.umbrella, this.allowUpdate).pipe(
      finalize(() => this.state = this.states.DONE))
      .subscribe(() => {
        this.components.forEach(c => c.markFormPristine());
        this.onClose.emit('success');
      });
  }

  ngOnInit() {

    this.projectService.show(this.data.project).pipe(
      take(1), finalize(() => this.ready = true))
      .subscribe(project => {
        this.project = project;
        this.umbrella = this.data.umbrella;
        this.allowUpdate = !this.project.Id || (this.project.Id && this.permissionService.allowsAction('update.project', this.project, { silent: true, project: this.project }));
      });

  }

}
