import { generateId } from "../generate_block_id";
import { ITaskV2Block } from "../task-v2-block.interface";
import { TaskV2BlockAction, TaskV2BlockType, TASK_V2_BLOCK_PREFIX } from "../types";

export class TaskV2VideoBlock implements ITaskV2Block {
    Type: TaskV2BlockType = TaskV2BlockType.VIDEO;
    Id: string;
    TaskId: string;
    BlockId: string;
    Content: string = ''; // videoURL
    CreateDate: string;
    UpdateDate: string;

    _httpAction: TaskV2BlockAction = TaskV2BlockAction.UPDATE;

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${TASK_V2_BLOCK_PREFIX.VIDEO}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            TaskId: this.TaskId,
            Type: this.Type,
            Content: this.Content,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }

}
