import { Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { DoNotShowAgainOption } from './do-not-show-again.options.class';

@Component({
  selector: 'do-not-show-again-settings',
  templateUrl: './do-not-show-again-settings.component.html',
})
export class DoNotShowAgainSettingsComponent implements OnInit {

  @Input() options: DoNotShowAgainOption[];

  constructor(
  ) {
  }

  toggleOption(option: DoNotShowAgainOption) {
    option.checked = !option.checked;
  }

  toggleOptionByEvent(ev: MatSlideToggleChange, option: DoNotShowAgainOption) {
    option.checked = ev.checked;
  }

  ngOnInit() {

  }
}
