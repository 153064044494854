import { Injectable, InjectionToken } from '@angular/core';
import { Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { DefaultUser, IUser } from './default-user.model';

export interface IUserService {
  loadUser(): Observable<IUser>;
  removeUser(): void;
  isAdmin$(): Observable<boolean>;
  allowScheduledEmails$(): Observable<boolean>;
  canAccessEmailsDashboard$(): Observable<boolean>;
  signOut(): void;
  user$: Observable<IUser>;
}
export const USER_SERVICE = new InjectionToken<IUserService>('USER_SERVICE');

@Injectable()
export class DefaultUserService implements IUserService {

  private _user: BehaviorSubject<IUser>;

  constructor(
  ) {
    this._user = <BehaviorSubject<IUser>>new BehaviorSubject(null);
  }

  get user$(): Observable<IUser> {
    return this._user.asObservable();
  }

  loadUser(): Observable<IUser> {
    const user = new DefaultUser();
    this._user.next(user);
    return observableOf(user);
  }
  removeUser() {
    this._user.next(null);
  }

  isAdmin$(): Observable<boolean> {
    return observableOf(false);
  }

  allowScheduledEmails$(): Observable<boolean> {
    return observableOf(false);
  }
  canAccessEmailsDashboard$(): Observable<boolean> {
    return observableOf(false);
  }
  signOut() { }

}