<ng-container *ngIf="!allow">
    <p class="warning">You are not allowed to view project statistics.</p>
</ng-container>

<ng-container *ngIf="allow">

    <mgc-project-statistics-tabs (tabChange)="selectedStatisticsTab = $event"></mgc-project-statistics-tabs>

    <mgc-project-statistics-subscribe *ngIf="selectedStatisticsTab===tabs.TAB1" [item]="project">
    </mgc-project-statistics-subscribe>
    <mgc-project-statistics-copy *ngIf="selectedStatisticsTab===tabs.TAB2" [item]="project"></mgc-project-statistics-copy>

</ng-container>