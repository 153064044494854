<div *ngIf="debugMe"
    style="box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);z-index:20 ; padding: 10px; width: 300px; min-height: 120px; background-color: white; color: gray;position: fixed; top: 70px; right: 10%;">
    <h4>reactions: {{reactions?.length}} ({{totalReactions}}) <span style="font-size: 90%;font-weight: normal;"
            *ngIf="state===states.LOADING">... loading - {{options?.limit}}</span></h4>
    <p>width: {{ el.nativeElement.offsetWidth}}</p>

    <p>elHeight : {{ el.nativeElement.offsetHeight}}</p>
    <p>clientHeight : {{ el.nativeElement.clientHeight}}</p>

    <p>scrollReactionsContainer: {{ reactionsContainerRef?.nativeElement.offsetHeight}}</p>

    <p>scrollTop: {{ el.nativeElement.scrollTop}}</p>
</div>


<div class="reactionsContainer" #reactionsContainer>
    <ng-container *ngIf="reactionsReady">
        <ng-container *ngFor="let reaction of reactions">
            <div class="mgc-participant">
                <account-avatar size="40" [account]="reaction._account || {}"></account-avatar>
                <span class="info">
                    <span class="name" [innerHTML]="reaction._account.OwnerFirstName"></span>
                    <span class="details">
                        <mgc-icon-button class="reaction-icon {{reaction.Type}}" icon="board-mat:{{reaction.Type}}">
                        </mgc-icon-button>
                    </span>
                </span>
            </div>
            <mgc-divider></mgc-divider>
        </ng-container>
    </ng-container>
</div>

<mgc-loader *ngIf="state===states.LOADING || loadingMore"></mgc-loader>