<mgc-dialog>

  <ng-container [ngSwitch]="refInView">
    <mgc-dialog-header *ngSwitchCase="REFs.AccountDataComponent" title="New Email" (close)="closeDialog()">
    </mgc-dialog-header>
    <mgc-dialog-header *ngSwitchCase="REFs.PasswordChangeComponent" title="Password Change" (close)="closeDialog()">
    </mgc-dialog-header>
    <mgc-dialog-header *ngSwitchDefault title="" (close)="closeDialog()"></mgc-dialog-header>
  </ng-container>

  <mgc-dialog-content>
    <account-data *ngIf="refInView===REFs.AccountDataComponent" [account]="account" (close)="closeDialog()">
    </account-data>
    <password-change *ngIf="refInView===REFs.PasswordChangeComponent" (close)="closeDialog()"></password-change>
  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.ACCOUNT_CREATE, serviceAlertClass.ACCOUNT_UPDATE, serviceAlertClass.USER]">
  </alert>

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="processing" (click)="onSave()" tabindex="2">
      <ng-container [ngSwitch]="refInView">
        <span *ngSwitchCase="REFs.AccountDataComponent">Add New</span>
        <span *ngSwitchDefault>Save</span>
      </ng-container>
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>
