import { generateId } from "@items/task-v2/blocks/generate_block_id";
import { EMAIL_BLOCKS_PREFIX, EmailBlockType } from "../block-types";

export class FooterBlock {
  id: string;
  type: string;

  constructor(data: any = null) {
    data = {
      id: generateId(`${EMAIL_BLOCKS_PREFIX.FOOTER}_`),
      type: EmailBlockType.FOOTER,
      ...data,
    };
    for (let prop in data) {
      this[prop] = data[prop];
    }
  }

  toDb() {
    return {
      id: this.id,
      type: this.type,
    };
  }

  toJSON() {
    return this.toDb();
  }
}
