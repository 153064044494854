
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { catchError, map, filter } from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';

import { BaseService } from '@board/_services/base-service.service';
import { Brand } from './brand.model';
import { AccountService } from '@board-accounts/index';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends BaseService {

  private _brands: BehaviorSubject<Brand[]>;
  private dataStore: {
    brands: Brand[],
  }
  private _endPoint: string;

  get brands$(): Observable<Brand[]> {
    const key = this.key();
    return this.dataStore[key]._brands.asObservable();
  }
  get brands(): any {
    return this.getFromStorage();
  }
  get avatarsEndPoint(): string {
    return `${this.config.endpoint}/api/brands/avatars`;
  }
  get backgroundsEndPoint(): string {
    return `${this.config.endpoint}/api/brands/bgs`;
  }

  private gettingBrands: any = {};
  private _waitingBrands: BehaviorSubject<Brand[]>;
  get waitingBrands$(): Observable<Brand[]> {
    return this._waitingBrands.asObservable();
  }

  static instance?: BrandService;
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService,
    private accountService: AccountService,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super();
    console.log(['brand b/s', isPlatformBrowser(platformId) ? 'browser' : 'server']);
    if (BrandService.instance !== undefined && isPlatformBrowser(platformId)) {
      throw new Error('BrandService must be instantiated only once.');
    }
    BrandService.instance = this;
    this._endPoint = `${this.config.endpoint}/api/brands`;
    this.dataStore = { brands: [] };

    this._brands = <BehaviorSubject<Brand[]>>new BehaviorSubject(null);
    this._waitingBrands = <BehaviorSubject<Brand[]>>new BehaviorSubject(null);
  }

  private get currentAccount() {
    return this.accountService.currentAccount;
  }

  private key() {
    const acc = this.currentAccount;
    return acc.Id;
  }

  getBrands(): Observable<Brand[]> {
    if (this.inStorage()) {
      return observableOf(this.getFromStorage());
    }

    return this._load().pipe(
      filter(brands => !!brands),
      map(brands => {
        return brands;
      }),
      catchError(error => {
        return observableOf([]);
      })
    )
  }

  private _load() {
    const key = this.key();
    if (this.gettingBrands[key]) {
      return this.waitingBrands$;
    }

    this.gettingBrands[key] = true;
    let params = new HttpParams({
      fromObject: {
        'unsubscribed': '1',
        'filter[AccountId]': this.currentAccount.Id,
      }
    });

    return this.http.get(this._endPoint, { params: params }).pipe(
      map(data => {
        this.initStorage();

        for (let key in data) {
          this.pushToStorage(new Brand(data[key]));
        }

        const brands = this.getFromStorage()
        this.gettingBrands[key] = false;
        this._waitingBrands.next(brands);
        this.emit();
        return brands;
      })
    )
  }

  reserve(brand: Brand) {
    return this.http.post(`${this._endPoint}/reserve`, brand.toDb()).pipe(
      map(data => {
        this.alertService.clear();
        return new Brand(data);
      }),
      catchError(error => {
        console.log('Could not validate brand name.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.BRAND_RESERVE);
        return observableOf(null);
      }));
  }

  create(brand: Brand) {
    return this.http.post(this._endPoint, brand.toDb()).pipe(
      map(data => {
        this.alertService.clear();

        const brand = new Brand(data);
        this.pushToStorage(brand).emit();
        return brand;
      }),
      catchError(error => {
        console.log('Could not validate brand name.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.BRAND_CREATE);
        return observableOf(null);
      }));
  }

  update(brand: Brand) {
    return this.http.put(`${this._endPoint}/${brand.Id}`, brand.toDb()).pipe(
      map(data => {
        this.alertService.clear();

        brand.UpdateDate = data['UpdateDate'];
        return brand;
      }),
      catchError(error => {
        console.log('Could not update brand.');
        this.alertService.snackError(error, ServiceAlertClass.ALERTS.BRAND_UPDATE);
        return observableOf(null);
      })
    )
  }

  show(brand: Brand) {
    if (brand._permap) {
      return observableOf(brand);
    }

    return this.http.get(`${this._endPoint}/${brand.Id}`).pipe(
      map(data => {
        brand._permap = data['_permap'];
        brand._member = data['_member'];

        return brand;
      }),
      catchError(error => {
        console.log('Could not open brand.');

        this.alertService.error(error, ServiceAlertClass.ALERTS.PROJECT_VIEW)

        return observableOf(null);
      })
    )
  }

  private initStorage() {
    const key = this.key();
    this.dataStore[key] = Object.assign(
      this.dataStore[key] || {
        _brands: <BehaviorSubject<Brand[]>>new BehaviorSubject(null),
      }, {
      brands: [],
    })
  }

  private inStorage() {
    const key = this.key();
    return !!this.dataStore[key] && !!this.dataStore[key].brands;
  }

  private pushToStorage(brand: Brand) {
    const key: string = this.key();
    this.dataStore[key].brands.push(brand);
    return this;
  }

  private getFromStorage() {
    const key: string = this.key();
    const brands = Object.assign({}, this.dataStore[key]).brands;
    return this.sort(brands);
  }

  private emit() {
    const key = this.key();
    this.dataStore[key]._brands.next(this.getFromStorage());
  }

  private sort(brands: any): Brand[] {
    return brands;
  }

  findBrand(brand: Brand, accountId?: string): Brand {
    accountId || (accountId = brand.AccountId)
    if (this.dataStore.brands[accountId]) {
      return this.dataStore.brands[accountId].find(t => t.Id === brand.Id);
    }

    return null;
  }

}