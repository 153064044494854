import { Component, OnInit, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DoNotShowAgainOptionDirective } from './do-no-show-again-option.directive';
import { DoNotShowAgainOptionComponent, DoNotShowAgainOptionItem } from './dnsa-option-components';

@Component({
  template: `
    <mgc-dialog>
      <mgc-dialog-header [title]="data.option.title" (close)="onClose()">
        <mat-icon prefix-icon svgIcon="board-mat:{{data.option.icon}}"></mat-icon>
      </mgc-dialog-header>

      <mgc-dialog-content class="sm">
          <do-not-show-again-modal-wrapper></do-not-show-again-modal-wrapper>
      </mgc-dialog-content>

      <do-not-show-again-option [option]="data.option" (close)="onClose($event)"></do-not-show-again-option>
    </mgc-dialog>
  `,
})
export class DoNotShowAgainModal implements OnInit {
  @ViewChild(DoNotShowAgainOptionDirective) dnsaOptionHost: DoNotShowAgainOptionDirective;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public dialogRef: MatDialogRef<DoNotShowAgainOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DoNotShowAgainOptionItem,
  ) {
  }

  onClose(result: string = null) {
    this.dialogRef.close(result);
  }

  loadComponent() {
    const componentRef = this.viewContainerRef.createComponent(this.data.component);
    (<DoNotShowAgainOptionComponent>componentRef.instance).option = this.data.option;
  }

  ngOnInit() {
    // this.loadComponent();
  }

}


@Component({
  selector: 'do-not-show-again-modal-wrapper',
  template: ``,
})
export class DoNotShowAgainModalWrapper implements OnInit {
  @ViewChild(DoNotShowAgainOptionDirective) dnsaOptionHost: DoNotShowAgainOptionDirective;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public dialogRef: MatDialogRef<DoNotShowAgainOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DoNotShowAgainOptionItem,
  ) {
  }

  onClose(result: string = null) {
    this.dialogRef.close(result);
  }

  loadComponent() {
    const componentRef = this.viewContainerRef.createComponent(this.data.component);
    (<DoNotShowAgainOptionComponent>componentRef.instance).option = this.data.option;
  }

  ngOnInit() {
    this.loadComponent();
  }

}
