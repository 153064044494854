
import { takeWhile, finalize, take } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

import { UmbrellaService } from './umbrella.service';
import { ISettingsComponent } from '@rallysite/global-interfaces';
import { ConfirmOverlayService } from '@board/_components/confirm-overlay/confirm-overlay.service';

@Component({
  selector: 'umbrella-settings',
  templateUrl: './umbrella-settings.component.html',
})
export class UmbrellaSettingsComponent implements ISettingsComponent, OnInit, OnDestroy {
  private alive: boolean = true;
  state: number;

  @Input() data: any;

  close$: Subject<any> = new Subject<any>();
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private umbrellaService: UmbrellaService,
    private confirmOverlay: ConfirmOverlayService,
  ) {
  }

  get states() {
    return UmbrellaService.STATES;
  }

  onSave(): void {
    if (!this.data.umbrella.Id) {
      this.umbrellaService.create(this.data.umbrella, this.data.account);
    } else {
      this.umbrellaService.update(this.data.umbrella, this.data.account);
    }
  }

  onDelete(event) {
    this.data.umbrella._state = 'removing';
    this.confirmOverlay.open(event.target).afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.umbrellaService.remove(this.data.umbrella, this.data.account);
          this.onClose.next();
        } else {
          this.data.umbrella._state = '';
        }
      });

  }

  onCancel(): void {
    this.close$.next('cancel');
  }

  ngOnInit() {
    this.umbrellaService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (this.state === this.states.DONE) {
          this.close$.next(this.umbrellaService.umbrella);
        } else if (this.state === this.states.UNAUTHORIZED) {
          this.close$.next('unauthorized');
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
