import { BaseItem, IItemStatus, ITEM_STATUSES } from "../base-item.model";

import { IPanelComponent, PanelComponents } from "@panel-components/panel-components";
import { ITaskGroup, DefaultTaskGroup } from "@board/items/task-group/task-group";
import { Notification } from "@board/_components/notification";
import { Project } from "@board/items/project/project";
import { IPanels } from "@rallysite/global-interfaces";
import { ITaskSettings, TaskSettings } from "./setings/task-settings.class";
import { Account } from "@board-accounts/index";

export interface ISocialMedia {
    title?: string;
    description?: string;
    keywords?: string;
    image?: any
}

export interface ITargetTask {
    Id?: string;
    ProjectId: string;
    GroupId: string;
    AccountId: string;
    _umbrellaId: string;
}

export class TaskAttributes {
    Id: string = null;
    AccountId: string = null;
    AssigneeId: string = null;
    ProjectId: string = null;
    GroupId: string = null;
    Name: string = null;
    Status: string = null;
    Description: string = null;
    Settings: ITaskSettings = null;
    BlocksMetadata: any = null;

    StartDate: Date = null;
    EndDate: Date = null;
    WorkHours: number = null;
    Cost: number = null;

    Scheduled: boolean = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class BaseTask extends BaseItem {

    isV2 = false;
    BlocksMetadata: any = null;

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.SETTINGS_TASK_NAME,
        PanelComponents.SETTINGS_FOLDER,
        PanelComponents.STATUS_DATES,
        PanelComponents.STATUS_STATE,
        PanelComponents.SETTINGS_SOCIAL_MEDIA,
        PanelComponents.SETTINGS_TASK_TAGGING
    ];
    readonly _type: string = 'task';
    get sidePanels(): Array<IPanelComponent> {
        if (!!this['contest']) {
            let p = [PanelComponents.CONTEST];
            for (let panel of this.SIDE_PANELS) {
                p.push(panel);
            }
            return p
        }

        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.NOTES;
    }

    prevAttributes: TaskAttributes;
    attributes: TaskAttributes;

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get AssigneeId(): string {
        return this.attributes.AssigneeId;
    }
    set AssigneeId(value: string) {
        this.attributes.AssigneeId = value;
    }
    get ProjectId(): string {
        return this.attributes.ProjectId;
    }
    set ProjectId(value: string) {
        this.attributes.ProjectId = value;
    }
    get GroupId(): string {
        return this.attributes.GroupId;
    }
    set GroupId(value: string) {
        this.attributes.GroupId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Description(): string {
        return this.attributes.Description;
    }
    set Description(value: string) {
        this.attributes.Description = value;
    }
    get Settings(): any {
        return this.attributes.Settings;
    }
    set Settings(value: any) {
        this.attributes.Settings = new TaskSettings(value || []);
    }

    set StartDate(value: Date) {
        this.attributes.StartDate = this.__date(value);
    }
    get StartDate(): Date {
        return this.attributes.StartDate;
    }
    set EndDate(value: Date) {
        this.attributes.EndDate = this.__date(value);
    }
    get EndDate(): Date {
        return this.attributes.EndDate;
    }
    set WorkHours(value: number) {
        this.attributes.WorkHours = value;
    }
    get WorkHours(): number {
        return this.attributes.WorkHours;
    }
    set Cost(value: number) {
        this.attributes.Cost = value;
    }
    get Cost(): number {
        return this.attributes.Cost;
    }

    o_Status: IItemStatus;
    set Status(value: string) {
        this.attributes.Status = value;

        for (let iStatus of ITEM_STATUSES) {
            if (iStatus.id === value) {
                this.o_Status = iStatus;
                break;
            }
        }
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Scheduled(value: boolean) {
        this.attributes.Scheduled = value;
    }
    get Scheduled(): boolean {
        return this.attributes.Scheduled;
    }

    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    socialMedia: ISocialMedia;
}

export class Task extends BaseTask implements IPanels {

    _notifications: number = 0;
    notifications: Notification[] = []; // Array<string | Notification> 
    get notificationsIds(): Array<string> {
        return this.notifications.filter(n => {
            return typeof n !== 'string' ? !(/^xxxxx.+/.test(n.id)) : true;
        }).map((n: string | Notification) => {
            if (typeof n === 'string') {
                return n;
            } else {
                return n.id;
            }
        });
    }

    _flaggedNotes: number = 0;
    _quickAdd: boolean = false;

    completeMap = [];
    isMarkedComplete(account: Account | string) {
        if (!account){
            return false;
        }
        const accountId = typeof account === 'string' ? account : account.Id;
        const find = this.completeMap.find(cm => cm.AccountId === accountId)
        if (find) {
            return find.complete;
        }
        return false;
    }

    markComplete(accountId: string, value: boolean) {
        const find = this.completeMap.find(cm => cm.AccountId === accountId)
        if (find) {
            find.complete = value;
        } else {
            this.completeMap.push({
                AccountId: accountId,
                complete: value
            });
        }
    }

    _assignments: {
        total: number,
        complete: number
    };

    _group: ITaskGroup;
    _prevGroupId: string;
    _state: string;
    _scrollY: number = 0;

    constructor(data: any) {
        super();

        this.attributes = new TaskAttributes();
        this.prevAttributes = new TaskAttributes();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        if (!data['Settings']) {
            this.Settings = null;
        }

        this._prevGroupId = this.GroupId;
        this._assignments || (this._assignments = { total: 0, complete: 0 })
        this.socialMedia = this.socialMedia || {};
    }

    increaseNotificationCounter(notification: Notification) {
        if (notification.isNote() || notification.isComment() || notification.isTask()) {
            if (this.notifications.indexOf(notification) < 0) {
                this._notifications++;
                this.notifications.push(notification)
                return true;
            }
            return false;
        }
        this._notifications++;
        return true;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }
    readUINotifications() {
        setTimeout(() => {
            this.decreaseNotificationCounter(this.notifications.length);
            this.notifications = [];
        }, 500); // must be a delay in order to keep notifications before being read and removed
    }

    settingsOptions({ project, edit, panel }: {
        project: Project,
        edit?: boolean,
        panel?: IPanelComponent
    }) {
        edit = !!edit;
        if (!this._group) {
            this._group = new DefaultTaskGroup(project, {});
        }

        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Item',
                task: this,
                project: project,
                group: this._group,
                edit: edit,
                panel: panel
            }
        }
    }

    get _defaultPanel() {
        return this.Settings.defaultPanel;
    }

    sideDefaultPanel(allowFn: Function) {
        if (this['contest']) {
            return PanelComponents.CONTEST;
        }

        if (allowFn(this.Settings.sideDefaultPanel.id)) {
            return this.Settings.sideDefaultPanel;
        }
        return PanelComponents.ME;
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            ProjectId: this.ProjectId,
            GroupId: this.GroupId,
            Name: this.Name,
            Description: this.Description,
            AccountId: this.AccountId,
            Settings: this.Settings.toDb(),
            BlocksMetadata: this.BlocksMetadata,

            Status: this.Status,
            StartDate: this.formatDbDate(this.StartDate),
            EndDate: this.formatDbDate(this.EndDate),
            WorkHours: this.WorkHours,
            Cost: this.Cost,

            UpdateDate: this.UpdateDate
        }, ['GroupId', 'StartDate', 'EndDate']);
    }
}

export class TargetTask implements ITargetTask {
    Id: string;
    ProjectId: string;
    GroupId: string;
    AccountId: string;
    _umbrellaId: string;

    constructor(private data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}
