import {Inject, Injectable} from '@angular/core';
import {Modal} from '@board/modals/modals-editor/modal.model';
import {BehaviorSubject, of as observableOf} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '@rallysite/config';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';

export interface ModalWithActivity {
  modal: Modal|null;
  dhUserActivity: {
    streak: number,
    missed: number
  };
}

@Injectable({
  providedIn: 'root'
})
export class GlobalModalsService {
  currentModal: ModalWithActivity | null;
  currentModalSubscription: BehaviorSubject<ModalWithActivity | null> = new BehaviorSubject(null);

  endpoints: Record<string, any>;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router
  ) {
      this.endpoints = {
        myModal: () => `${this.config.endpoint}/api/my-modals/display`,
        resolveModal: (id: number) => `${this.config.endpoint}/api/my-modals/${id}/resolve`,
        trackEvent: (id: number) => `${this.config.endpoint}/api/my-modals/${id}/event`,
        showModal: (id: number) => `${this.config.endpoint}/api/modals/${id}`,
      };
  }

  public openModal(modalId: number) {
    this.fetchModal(modalId).subscribe((modal) => {
      this.currentModalSubscription.next(modal);
    });
  }

  public resetMyModals() {
    this.currentModal = null;
  }
  public openMyModal() {
    setTimeout(() => {
      this.fetchMyModal().subscribe((response: ModalWithActivity) => {
        if (!response.modal) {
          return;
        }

        if (this.modalIsEligibleToShow(response.modal)) {
          this.currentModalSubscription.next(response);
          this.currentModal = response;
          return;
        }
      });
    }, 500);
  }

  public handlePageNavigation() {
    if (this.currentModal && this.modalIsEligibleToShow(this.currentModal.modal)) {
      this.currentModalSubscription.next(this.currentModal);
    }
  }

  public modalBeenClosed(modal: Modal) {
    if (modal.Config.closingFrequency === 'one') {
      sessionStorage.setItem('hide-modal-' + modal.Id, '1');
    }
  }

  private modalIsEligibleToShow(modal: Modal): boolean {
    if (sessionStorage.getItem('resolve-modal-' + modal.Id)) {
      return false;
    }

    return !(modal.Config.closingFrequency === 'one' && sessionStorage.getItem('hide-modal-' + modal.Id));
  }

  public resolveModal(modalId: any, status: 'done' | 'skip') {
    sessionStorage.setItem('resolve-modal-' + modalId, '1');

    return this.http.put(this.endpoints.resolveModal(modalId), {status}).pipe(
      catchError(error => {
        console.log('Could not load modals');
        return observableOf(null);
      })
    );
  }

  public trackEvent(modalId: any, event: 'displayed' | 'closed' | 'resolved' | 'dont-show-again') {
    return this.http.put(this.endpoints.trackEvent(modalId), {event, pagePath: this.router.url}).pipe(
      catchError(error => {
        console.log('Could not load modals');
        return observableOf(null);
      })
    );
  }

  private fetchMyModal() {
    return this.http.get(this.endpoints.myModal()).pipe(
      catchError(error => {
        console.log('Could not load my modal');
        return observableOf(null);
      })
    );
  }

  /**
   * @todo implement backend endpoint
   */
  private fetchModal(modalId: number) {
    return this.http.get(this.endpoints.showModal(modalId)).pipe(
      catchError(error => {
        console.log('Could not load modals');
        return observableOf(null);
      })
    );
  }
}
