import { Component, HostBinding, Directive, OnInit, ElementRef, ViewContainerRef, Renderer2, Input } from '@angular/core';

@Component({
    selector: 'mgc-list-item',
    template: '<ng-content></ng-content>',
    styleUrls: ['./nav-list.scss']
})
export class MgcListItemComponent {
    @HostBinding('class.mgc-list-item') setClass = true;
}

@Component({
    selector: 'drag-handle',
    template: `
        <mat-icon cdkDragHandle svgIcon="board-mat:drag"></mat-icon>
    `,
    styleUrls: ['./nav-list.scss']
})
export class DraggableComponent {
    @HostBinding('class.drag-handle') setClass = true;
}


@Directive({
    selector: 'mgc-list-item[draggable]'
})
export class MgcListItemDraggbleDirective implements OnInit {
    @Input() draggable: boolean = undefined;

    prefix: Element;
    private parenClass = 'prefix';
    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {
    }

    ngOnInit() {
        this.prefix = this.el.nativeElement.querySelector(`.${this.parenClass}`);
        if (!this.prefix) {
            this.prefix = this.renderer.createElement('div');
            this.renderer.addClass(this.prefix, this.parenClass);
            this.renderer.insertBefore(this.el.nativeElement, this.prefix, this.el.nativeElement.childNodes[0]);
        }

        if (typeof this.draggable === 'boolean' && !this.draggable) {
            return;
        }

        const ref = this.viewContainerRef.createComponent(DraggableComponent);

        this.renderer.insertBefore(this.prefix, ref.location.nativeElement, this.prefix.childNodes[0]);
    }
}
