import { NgModule } from '@angular/core';
import { AlertComponent } from './alert.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { AlertSnack } from './alert.snack';
import { SafePipesModule } from 'app/_pipes/safe.pipe';

@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        SafePipesModule
    ],
    declarations: [
        AlertComponent,
        AlertSnack,
    ],
    exports: [
        AlertComponent,
    ]
})
export class AlertModule {};
