<div class="top">
    <rs-uploader #uploader [style.font-size]="0" renderAs="content" [options]="uploaderOptions"
        (onBeforeUpload)="onImageBeforeUpload($event)" (onProgress)="onImageProgress($event)"
        (onSuccess)="onImageSuccess($event)" (onError)="onImageError($event)"></rs-uploader>

    <span>Upload images in JPEG, PNG or GIF formats. Marked ones will be added to project.</span>

    <mgc-stroked-button *ngIf="selectedCategory.allowPOST" color="primary" (click)="uploader.click()">Upload image</mgc-stroked-button>
</div>

<div class="content">
    <div class="side-bar" *ngIf="options?.categories.length > 1">
        <div class="category-list">
            <div *ngFor="let category of options.categories" (click)="selectCategory(category)" [class.selected]="selectedCategory===category">{{category.text}}</div>
        </div>
    </div>

    <div class="gallery">
        <mgc-loader *ngIf="state===states.LOADING"></mgc-loader>
        <ng-container *ngIf="state===states.ERROR">
            There is an error!
        </ng-container>

        <ng-container *ngIf="state===states.DONE && !galleryImages.length">
            <span class="mgc-gallery-info-message">no image, you have to upload some</span>
        </ng-container>

        <ng-container *ngIf="state===states.DONE">
            <gallery-grid [imageQuality]="quality" [data]="galleryImages" [multiSelect]="options.multiSelect" (toggleSelect)="toggleSelectImage($event)"></gallery-grid>
        </ng-container>

    </div>
</div>