<ng-container *ngIf="!progressOnly">
    <div class="overlay new" *ngIf="item.isNew && !item.removed && !item.hasError"><span>Added</span></div>
    <div class="overlay remove" *ngIf="item.removed"><span>removed</span></div>
    <div class="overlay has-error" *ngIf="item.hasError"><span>-- Error --</span></div>
</ng-container>
<div class="overlay" *ngIf="item.isUploading">
    <mgc-loader diameter="30"></mgc-loader>
</div>
<div class="overlay" *ngIf="item.isReadyForUpload">
    <mat-progress-bar color="primary" mode="buffer" value="0" bufferValue="30" [style.height.px]="3" [style.width.%]="40"></mat-progress-bar>
</div>
<div class="overlay remove" *ngIf="item.isRemoving">
    <mat-icon svgIcon="board-mat:delete" [style.color]="'red'"></mat-icon>
</div>