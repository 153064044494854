import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '@libraries/surveys/surveys/survey.service';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { WINDOW } from '@rallysite/global-services';
import { QuestionsConverter } from 'app/_services/transfer-state/converters/questions.converter';
import { SurveyQuestionsConverter } from 'app/_services/transfer-state/converters/survey-questions.converter';
import { SurveyConverter } from 'app/_services/transfer-state/converters/survey.converter';
import { TransferStateService } from 'app/_services/transfer-state/transfer-state.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssessmentButtonComponent } from '../assessment-button';
import { Question } from '../question.model';
import { PlatformService } from 'app/_services/platform.service';
import {PlevelService} from '../../../../_services/plevel.service';

@Injectable({
    providedIn: 'root',
})
export class AssessmentService extends SurveyService {

    constructor(
        http: HttpClient,
        @Inject(WINDOW) w: Window,
        @Inject(APP_CONFIG) config: AppConfig,
        _snackBar: MatSnackBar,
        authService: AuthService,
        @Inject(PLATFORM_ID) readonly platformId,
        readonly transferStateService: TransferStateService,
        readonly surveyQuestionsConverter: SurveyQuestionsConverter,
        readonly surveyConverter: SurveyConverter,
        protected questionsConverter: QuestionsConverter,
        protected platformService: PlatformService,
        protected plevelService: PlevelService
    ) {
        super(
          http,
          w,
          config,
          _snackBar,
          authService,
          platformId,
          transferStateService,
          surveyQuestionsConverter,
          surveyConverter,
          plevelService
        );

        this._endPoint = `${this.config.endpoint}/api/assessments`;
    }

    protected questionInstance(data) {
        return new Question(data);
    }

    protected get questionConvertor(): any {
        return this.questionsConverter;
    }

    engage() {
        if (!this.enabled()) {
            return;
        }
        super.engage(AssessmentButtonComponent);
    }

    enabled() {
        if (!!this.platformService.isBttba() || !this.platformService.isProd()) {
            return this.authService.isAuthenticated;
        }
        return false;
    }

    getLatest() {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }

        if (typeof this.dataStore.latest !== 'undefined') {
            return of(this.dataStore.latest);
        }

        const endpoint = `${this._endPoint}/1/latest`;
        return this.http.get(endpoint).pipe(
            map(latest => {
                this.setLatestDate(latest ? latest['updated_at'] : null);
                return this.dataStore.latest;
            }),
            catchError(error => {
                console.log('Could not load latest assessment');
                return of(null);
            })
        );
    }

    setLatestDate(date) {
        this.dataStore.latest = date;
    }

}
