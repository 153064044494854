<mgc-dialog>
    <mgc-dialog-header [title]="showMultiGallery?'Image gallery':'Add/Edit images'" (close)="closeDialog()">
    </mgc-dialog-header>

    <mgc-dialog-content>
        <gallery-organizer #organizer *ngIf="!showMultiGallery" [imageQuality]="imageQuality" [(items)]="galleryItems"
            (chooseMedia)="toggleMultiGallery(true)">
        </gallery-organizer>
        <multi-gallery #multiGallery *ngIf="showMultiGallery" [selectedItems]="galleryItems" [imageQuality]="imageQuality" [options]="galleryOptions"></multi-gallery>
    </mgc-dialog-content>

    <alert></alert>

    <mgc-dialog-actions>
        <mgc-raised-button right [processing]="processing" (click)="onProcess()" tabindex="2">
            <span [innerHTML]="showMultiGallery?'Add to project':'Done'"></span>
        </mgc-raised-button>
        <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
    </mgc-dialog-actions>

</mgc-dialog>