
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageAuthViewType } from '@page-auth/page-auth-view-type.enum';
import { PageAuthComponent } from '@page-auth/page-auth.component';

@Component({
  selector: 'page-auth-modal',
  templateUrl: './page-auth.modal.html',
  styleUrls: ['./page-auth.modal.scss'],
})
export class PageAuthModal implements OnInit {

  @ViewChild(PageAuthComponent) authView: PageAuthComponent;

  authViewType: PageAuthViewType;
  readonly PageAuthViewType = PageAuthViewType;

  constructor(
    public dialogRef: MatDialogRef<PageAuthModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      authAction: PageAuthViewType,
      email: string,
    }) {
  }

  get titleAuth() {
    let title: string = '';
    switch (this.authViewType) {
      case PageAuthViewType.login:
        title = 'Login';
        break;
      case PageAuthViewType.register:
        title = 'Register';
        break;
      case PageAuthViewType.co_register:
        title = '';
        break;
    }
    return title;
  }

  switchAuth(view: PageAuthViewType) {
    this.authViewType = view;
    return false;
  }

  onSubmit() {
    this.authView.onSubmit();
  }

  closeDialog(event = null): void {
    this.dialogRef.close(event);
  }

  get processing() {
    return this.authView?.processing();
  }

  ngOnInit() {
    this.authViewType = <PageAuthViewType>this.data.authAction || PageAuthViewType.email;
  }

}
