import { BaseItem, IItemStatus, ITEM_STATUSES } from '../base-item.model';

import { IPanelComponent, PanelComponents } from '@panel-components/panel-components';
import { ITaskGroup, DefaultTaskGroup } from '@board/items/task-group/task-group';
import { Project } from '@board/items/project/project';
import { IPanels } from '@rallysite/global-interfaces';
import { ISocialMedia, ITaskSettings, TaskSettings } from '@items/task/setings/task-settings.class';
import { Account } from '@board/accounts';

export class TaskV2Attributes {
    Id: string = null;
    AccountId: string = null;
    ProjectId: string = null;
    GroupId: string = null;
    Name: string = null;
    Status: string = null;
    Description: string = null;
    Settings: ITaskSettings = null;

    PushDate: string = null;
    PushTimezone: string = null;
    PushTitle: string = null;
    PushContent: string = null;
    PushLinkLabel: string = null;

    StartDate: Date = null;
    EndDate: Date = null;

    Scheduled: boolean = null;
    BlocksMetadata: any = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class BaseTaskV2 extends BaseItem {
    isV2 = true;
    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.SETTINGS_TASK_NAME,
        PanelComponents.SETTINGS_FOLDER,
        PanelComponents.STATUS_DATES,
        PanelComponents.STATUS_STATE,
        PanelComponents.SETTINGS_SOCIAL_MEDIA,
        PanelComponents.SETTINGS_TASK_TAGGING,
        PanelComponents.SETTINGS_TASK_PUSH_DATE,
    ];
    readonly _type: string = 'task';
    get sidePanels(): Array<IPanelComponent> {
        if (!!this['contest']) {
            const p = [PanelComponents.CONTEST];
            for (const panel of this.SIDE_PANELS) {
                p.push(panel);
            }
            return p;
        }

        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.NOTES;
    }

    prevAttributes: TaskV2Attributes;
    attributes: TaskV2Attributes;

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get ProjectId(): string {
        return this.attributes.ProjectId;
    }
    set ProjectId(value: string) {
        this.attributes.ProjectId = value;
    }
    get GroupId(): string {
        return this.attributes.GroupId;
    }
    set GroupId(value: string) {
        this.attributes.GroupId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Description(): string {
        return this.attributes.Description;
    }
    set Description(value: string) {
        this.attributes.Description = value;
    }
    get Settings(): any {
        return this.attributes.Settings;
    }
    set Settings(value: any) {
        this.attributes.Settings = new TaskSettings(value || []);
    }

    set StartDate(value: Date) {
        this.attributes.StartDate = this.__date(value);
    }
    get StartDate(): Date {
        return this.attributes.StartDate;
    }
    set EndDate(value: Date) {
        this.attributes.EndDate = this.__date(value);
    }
    get EndDate(): Date {
        return this.attributes.EndDate;
    }

    o_Status: IItemStatus;
    set Status(value: string) {
        this.attributes.Status = value;

        for (const iStatus of ITEM_STATUSES) {
            if (iStatus.id === value) {
                this.o_Status = iStatus;
                break;
            }
        }
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Scheduled(value: boolean) {
        this.attributes.Scheduled = value;
    }
    get Scheduled(): boolean {
        return this.attributes.Scheduled;
    }
    set BlocksMetadata(value: any) {
        this.attributes.BlocksMetadata = value;
    }
    get BlocksMetadata(): any {
        return this.attributes.BlocksMetadata;
    }
    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    set PushDate(value: string) {
        this.attributes.PushDate = value;
    }
    get PushDate(): string {
        return this.attributes.PushDate;
    }

    set PushTimezone(value: string) {
        this.attributes.PushTimezone = value;
    }
    get PushTimezone(): string {
        return this.attributes.PushTimezone;
    }

    set PushTitle(value: string) {
        this.attributes.PushTitle = value;
    }
    get PushTitle(): string {
        return this.attributes.PushTitle;
    }

    set PushContent(value: string) {
        this.attributes.PushContent = value;
    }
    get PushContent(): string {
        return this.attributes.PushContent;
    }

    set PushLinkLabel(value: string) {
        this.attributes.PushLinkLabel = value;
    }
    get PushLinkLabel(): string {
        return this.attributes.PushLinkLabel;
    }

    socialMedia: ISocialMedia;
}

export class TaskV2 extends BaseTaskV2 implements IPanels {
    _quickAdd = false;

    _group: ITaskGroup;
    _prevGroupId: string;
    _state: string;
    _scrollY = 0;

    completeMap = [];
    isMarkedComplete(account: Account | string) {
        if (!account) {
            return false;
        }
        const accountId = typeof account === 'string' ? account : account.Id;
        const find = this.completeMap.find(cm => cm.AccountId === accountId);
        if (find) {
            return find.complete;
        }
        return false;
    }

    markComplete(accountId: string, value: boolean) {
        const find = this.completeMap.find(cm => cm.AccountId === accountId);
        if (find) {
            find.complete = value;
        } else {
            this.completeMap.push({
                AccountId: accountId,
                complete: value
            });
        }
    }


    constructor(data: any) {
        super();

        this.attributes = new TaskV2Attributes();
        this.prevAttributes = new TaskV2Attributes();

        for (const prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        if (!data['Settings']) {
            this.Settings = null;
        }

        this._prevGroupId = this.GroupId;
        this.socialMedia = this.socialMedia || {};
    }

    settingsOptions({ project, edit, panel }: {
        project: Project,
        edit?: boolean,
        panel?: IPanelComponent
    }) {
        edit = !!edit;
        if (!this._group) {
            this._group = new DefaultTaskGroup(project, {});
        }

        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Item v2',
                task: this,
                project: project,
                group: this._group,
                edit: edit,
                panel: panel
            }
        };
    }

    get _defaultPanel() {
        return this.Settings.defaultPanel;
    }

    sideDefaultPanel(allowFn: Function) {
        if (allowFn(this.Settings.sideDefaultPanel.id)) {
            return this.Settings.sideDefaultPanel;
        }
        return PanelComponents.ME;
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            AccountId: this.AccountId,
            ProjectId: this.ProjectId,
            GroupId: this.GroupId,
            Name: this.Name,
            Settings: this.Settings.toDb(),
            BlocksMetadata: this.BlocksMetadata,

            // PushDate: this.PushDate,
            // PushTimezone: this.PushTimezone,
            PushTitle: this.PushTitle,
            PushContent: this.PushContent,
            PushLinkLabel: this.PushLinkLabel,

            Status: this.Status,
            StartDate: this.formatDbDate(this.StartDate),
            EndDate: this.formatDbDate(this.EndDate),

            UpdateDate: this.UpdateDate
        }, ['GroupId', 'StartDate', 'EndDate']);
    }
}
