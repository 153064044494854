import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlgoTag, AlgoTagAction, AlgoTagScore } from './algotag.model';

@Component({
  selector: 'algotag-score',
  templateUrl: './algotag-score.component.html',
  styleUrls: ['./algotag.component.scss']
})
export class AlgoTagScoreComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
  ) { }
  get f() { return this.form.controls; }

  @Input() tag: AlgoTag;

  scores: number[] = Array(5).fill(0).map((x, i) => i);
  formSubscription: Subscription;

  /** FORM */
  form: FormGroup;

  submitted = false;
  bootstrapForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      Score: [this.tag.Score],
    });

    // this.formSubscription =
    //   this.form.get('Score')
    //     .valueChanges.subscribe((score: AlgoTagScore) => {
    //       this.tag.Score = score
    //     })
  }
  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    this.tag.setScore(this.form.get('Score').value);
    return true;
  }
  /** end of FORM */


  ngOnInit() {
    this.bootstrapForm();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

}
