import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { InfoTooltipComponent } from './info-tooltip.component';

@Directive({ selector: '[infoTooltip]' })
export class InfoTooltipDirective implements OnInit {

    @Input('infoTooltip') text = '';
    private overlayRef: OverlayRef;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        // Subscribe to a stream that emits when the backdrop was clicked
        this.overlayRef.backdropClick().subscribe(_ => this.overlayRef.detach());

    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                offsetY: -5,
                offsetX: 10
            }]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'info-tooltip-backdrop',
            panelClass: 'info-tooltip-overlay',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
            width: '250px'
        });

        return overlayConfig;
    }

    @HostListener('click')
    show() {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            return;
        }

        const tooltipRef: ComponentRef<InfoTooltipComponent>
            = this.overlayRef.attach(new ComponentPortal(InfoTooltipComponent));
        tooltipRef.instance.text = this.text;
    }

    @HostListener('mouseout')
    hide() {
        // this.overlayRef.detach();
    }
}
