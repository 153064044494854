<mgc-form-field label="Choose a sender" multiline>
    <div class="field-wrapper select-row">
        <mgc-loader *ngIf="!senderAccount.Id"></mgc-loader>

        <mat-select class="mgc-select" [(value)]="senderAccount" name="coOwner">
            <mat-select-trigger>
                <div class="mgc-account">
                    <account-avatar size="30" [account]="senderAccount"></account-avatar>
                    <span class="info">
                        <span class="name" [innerHTML]="senderAccount?.Name"></span>
                    </span>
                </div>
            </mat-select-trigger>

            <mat-option *ngFor="let sender of coOwners" [value]="sender._account" (click)="selectSender(sender)">
                <div class="mgc-account">
                    <account-avatar size="30" [account]="sender._account"></account-avatar>
                    <span class="info">
                        <span class="name" [innerHTML]="sender._account.Name"></span>
                    </span>
                </div>
            </mat-option>

        </mat-select>
    </div>
</mgc-form-field>

<csv-email [project]="project"></csv-email>