import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take, takeWhile } from 'rxjs/operators';

import { TargetResourceService } from './target-resource.service';

import { MobileJsBridgeService } from '@rallysite/global-services';
import { ProjectService } from '@board/items/project/project.service';
import { TaskService } from '@board/items/task/task.service';
import { NoteService } from '@panel-components/notes/note.service';
import { ScheduledTaskService } from '@board/items/scheduled-task/scheduled-task.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QueryRequestService {

    debugMe: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private taskService: TaskService,
        private scheduledTaskService: ScheduledTaskService,
        private noteService: NoteService,
        private targetService: TargetResourceService,
        private mobileJsBridge: MobileJsBridgeService
    ) {
    }

    init(params): Observable<boolean> {
        if (params['debug-me']) {
            this.debugMe = true;
        }

        if (params.p) {
            return this.projectAsTarget(params.p);
        }
        if (params.t) {
            return this.taskAsTarget(params.t);
        }
        if (params.s) {
            return this.scheduledTaskAsTarget(params.s);
        }
        if (params.n) {
            // return this.noteAsTarget(params.n, params.c == 1);
            return of(false);
        }

        return of(false);
    }

    update(query: any) {
        if (this.targetService.targetLevel > 0) {
            return
        }

        if (this.targetService.targetPresent) {
            this.targetService.targetPresent = false;
            return;
        }

        this.router.navigate([], { relativeTo: this.route, queryParams: query })
        setTimeout(() => {
            this.mobileJsBridge.updateLocation();
        }, 100)
    }

    private projectAsTarget(projectId): Observable<boolean> {
        return this.projectService.loadTarget(projectId).pipe(
            take(1),
            map(tProject => {
                if (!tProject) {
                    return false;
                }
                this.targetService.currentTargets = {
                    accountId: tProject.AccountId,
                    umbrellaId: tProject._umbrellaId,
                    projectId: tProject.Id,
                    groupId: null,
                    taskId: null,
                    scheduledTaskId: null,
                    noteId: null
                }
                return true;
            }));
    }

    private taskAsTarget(taskId): Observable<boolean> {
        return this.taskService.loadTarget(taskId).pipe(
            take(1),
            map(tTask => {
                if (!tTask) {
                    return false;
                }
                this.targetService.currentTargets = {
                    accountId: tTask.AccountId,
                    umbrellaId: tTask._umbrellaId,
                    projectId: tTask.ProjectId,
                    groupId: tTask.GroupId,
                    taskId: tTask.Id,
                    scheduledTaskId: null,
                    noteId: null
                }
                return true;
            }))
    }

    private scheduledTaskAsTarget(taskId): Observable<boolean> {
        return this.scheduledTaskService.loadTarget(taskId).pipe(
            take(1),
            map(tTask => {
                if (!tTask) {
                    return false
                }
                this.targetService.currentTargets = {
                    accountId: tTask.AccountId,
                    umbrellaId: tTask._umbrellaId,
                    projectId: tTask.ProjectId,
                    groupId: tTask.GroupId,
                    taskId: tTask.Id,
                    scheduledTaskId: tTask.Id,
                    noteId: null
                }
                return true;
            }))
    }

    private noteAsTarget(noteId, openComments: boolean = false) {
        let nt: boolean = false;
        this.noteService.targetNote$.pipe(takeWhile(() => !nt))
            .subscribe(data => {
                if (data) {
                    nt = false;
                    this.targetService.currentTargets = {
                        accountId: data.AccountId,
                        umbrellaId: data._umbrellaId,
                        projectId: data.ProjectId,
                        groupId: data.GroupId,
                        taskId: data.TaskId,
                        scheduledTaskId: data.TaskId,
                        noteId: data.Id,
                        _showComments: data._showComments
                    }
                }
            });
        this.noteService.loadTarget(noteId, openComments);
    }
}