import { IViewerData } from '@libraries/gallery/viewer/viewer-overlay.data';

export interface ViewerOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: IViewerData;
}

export const DEFAULT_CONFIG: ViewerOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
}
