<form [formGroup]="authForm">

  <mgc-form-field label="Email" [invalid]="submitted && f.email.errors">
    <div class="field-wrapper">
      <input formControlName="email" name="email" autocomplete="email">
      <span class="placeholder" *ngIf="!f.email.value">Enter your email here ...</span>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.email.errors">
      <div *ngIf="f.email.errors.required">Email is required</div>
      <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
    </ng-container>
  </mgc-form-field>

  <mat-checkbox class="mgc-form-control" formControlName="agree" name="agree">I agree to the
    <a [attr.href]="config.legal.terms" target="_blank">Terms and
      Conditions and Privacy Policy</a>
  </mat-checkbox>

  <mgc-information *ngIf="data.placeId===PageAuthPlaceId.survey">
    <span>
      By choosing to continue you will become a member, receive an email with access to your results anytime,
      be granted access to all available content, and have immediate access to all survey results.
    </span>
  </mgc-information>

</form>
