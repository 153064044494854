<form [formGroup]="form">

  <mgc-form-field [label]="" class="input-wrapper" multiline nobg [invalid]="submitted && f.UserAnswer.errors">
    <div class="field-wrapper">
      <textarea class="q-option" autosize [startRows]="3" [autosize-text]="f.UserAnswer.value"
        formControlName="UserAnswer" placeholder="Your answer here"></textarea>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.UserAnswer.errors">
      <div *ngIf="f.UserAnswer.errors.required">This is a required field.</div>
    </ng-container>
  </mgc-form-field>

</form>