<mgc-dialog>
  <mgc-dialog-header title="Send email notification" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>
    <email #email [data]="data"></email>
  </mgc-dialog-content>

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="state===states.PROCESSING" (click)="isScheduledTask ? email.onSchedule() : email.onSave()"
      [disabled]="!email.isValid()">
      {{isScheduledTask ? 'Send when Item goes live': 'Send'}}
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>
