import { Component, HostBinding } from "@angular/core";


@Component({
    selector: 'mgc-dialog-content',
    template: `
        <div class="dialog-content-header">
            <ng-content select="[content-header]"></ng-content>
        </div>
        <div class="dialog-scroll-content">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./dialog-content.component.scss']
})
export class MgcDialogContentComponent {

    @HostBinding('class.mgc-dialog-content') setClass: boolean = true;
}
