
import { takeWhile, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Participant } from '../participant';
import { ParticipantService } from '../participant.service';

import { ServiceAlertClass, IAlertsClass } from '@rallysite/components/alert';
import { IUmbrella, UmbrellaService, Umbrella } from '@board/items/umbrella';
import { ProjectService } from '@board/items/project/project.service';
import { Project } from '@board/items/project/project';
import { AccountService } from '@board-accounts/account.service';

@Component({
  selector: 'participant-confirm-modal',
  templateUrl: './participant-confirm-modal.html',
  styleUrls: ['./participant-confirm-modal.scss'],
})
export class ParticipantConfirmModal implements OnInit, OnDestroy {
  private alive: boolean = true;
  participant: Participant;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;
  choosedOption: string = 'preview';
  umbrellas: IUmbrella[] = [];
  selectedUmbrella: IUmbrella;
  // defaultUmbrella: IUmbrella;
  state: number;

  constructor(
    private participantService: ParticipantService,
    private umbrellaService: UmbrellaService,
    private projectService: ProjectService,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ParticipantConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      project: Project,
    }) {
    this.participant = new Participant(Object.assign({}, this.data.project._participant));
  }

  get states() {
    return ParticipantService.STATES;
  }

  get projectStates() {
    return ProjectService.STATES;
  }

  onConfirm(type: 'yes' | 'no' | 'pending'): void {
    this.participant.Confirmation = type;
    switch (this.choosedOption) {
      case 'move':
        this.projectService.update(this.data.project, <Umbrella>this.selectedUmbrella);
        // after moving, will continue with confirm
        break;
      case 'preview':
        this.participantService.confirm(this.participant, this.data.project);
        break;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.participantService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (state === this.states.DONE) {
          if (this.choosedOption === 'preview') {
            this.projectService.openProject(this.data.project).subscribe();
          }
          this.dialogRef.close();
        } else if (state === this.states.UNAUTHORIZED) {
          this.dialogRef.close('unauthorized');
        }
      });

    this.projectService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (state === this.states.DONE) {
          this.participantService.confirm(this.participant, this.projectService.project);
        }
      });

    // this.defaultUmbrella = this.umbrellaService.getDefault(this.accountService.currentAccount.Id);
    this.umbrellaService.umbrellas$.pipe(take(1))
      .subscribe(umbrellas => {
        this.umbrellas = umbrellas;
        this.selectedUmbrella = this.umbrellas.find((u: Umbrella) => u.isArchive);
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
