import { Pipe, PipeTransform, Inject, NgModule, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { BoardViewService } from '@board/_services';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

export const playerRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:.+embed\.html.+ply_id=)([\w-]*)(?:.+)?)/
export const vimeoRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:vimeo\.com\/)([\w-]*))/
export const youtubeRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:(?:youtube\.[^<>\]]+?(?:(?:\/embed\/)|(?:\/watch\?v=)|(?:\/v\/))+?)|(?:youtu\.be\/))([\w-]*))/

@Pipe({ name: 'embed' })
export class EmbedPipe implements PipeTransform {

  readonly DEFAULT_W: number = 560;
  readonly DEFAULT_H: number = 315;

  embedWidth: string;
  embedHeight: string;

  constructor(
    private sanitizer: DomSanitizer,
    private boardViewService: BoardViewService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig
  ) {
    this.boardViewService.handle();

    let width = this.boardViewService.width;
    switch (true) {
      case width > 576:
        let w = Math.min(this.DEFAULT_W, width - 350/** nav width */ - 60 /** left-right note padding */);
        this.embedWidth = '' + w;
        this.embedHeight = '' + (w * this.DEFAULT_H / this.DEFAULT_W);
        break;
      default:
        this.embedWidth = '100%';
        this.embedHeight = '' + ((width - 20/** left-right note padding  */) * this.DEFAULT_H / this.DEFAULT_W);
    }
  }


  private embeds(type: string, data: any) {
    let w = data.width || this.embedWidth;
    let h = data.height || this.embedHeight;

    switch (type) {
      case 'youtube':
        return `<br><iframe width="${w}" height="${h}" src="https://www.youtube.com/embed/${data.id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe><br>`
      case 'vimeo':
        return `<br><iframe  width="${w}" height="${h}" src="https://player.vimeo.com/video/${data.id}"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><br>`;
      // <p><a href="https://vimeo.com/263974008">The hunter</a> from <a href="https://vimeo.com/user2264676">jari vaara</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
      case 'player':
        return `<br><iframe  width="${w}" height="${h}" src="${this.appConfig.playerPath}/embed.html?id=${data.id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe><br>`;
    }
  }

  private embed(str: string) {
    let parts = str.split(':');
    let id: string = null;
    if (parts[2]) {
      id = parts[2];

      switch (parts[1].toLowerCase()) {
        case 'youtube':
          return this.embeds('youtube', { id: id });
        case 'vimeo':
          return this.embeds('vimeo', { id: id });
        case 'player':
            return this.embeds('player', { id: id });
      }
    }
    return '';
  }

  private handleEmbeds(text: string) {
    const START_MACRO: string = '[[--';
    const END_MACRO: string = '--]]';

    let si = text.indexOf(START_MACRO);
    let ei = text.indexOf(END_MACRO);

    if (si >= 0 && ei >= 0) {
      text = text.substring(0, si) + this.embed(text.substring(si + START_MACRO.length, ei)) + text.substring(ei + END_MACRO.length);
      return this.handleEmbeds(text);
    } else {
      return text;
    }
  }

  private linky(value: string) {
    return (value + ' ').replace(/((?:(?:ht|f)tps?:\/\/|www)[^<>\]]+?(?![^<>\]]*([>]|<\/))(?=[\s!<\]]))/g, '<a href="$1" target="_blank">$1</a>');
  }

  private lineBreak(value: string) {
    return value.replace(/\r?\n/g, '<br/>') + '<br/>';
  }

  private youtube(value: string) {
    // return (value + ' ').replace(/((?:(?:https?:\/\/(?:www\.)?)|www\.)?youtube\.[^<>\]]+?(?:\/embed)?\/([\w-]*))/g, '[[--EMBED:YOUTUBE:$2--]]');
    return (value + ' ').replace(new RegExp(youtubeRegexr, 'g'), '[[--EMBED:YOUTUBE:$2--]]');
  }

  private vimeo(value: string) {
    return (value + ' ').replace(new RegExp(vimeoRegexr, 'g'), '[[--EMBED:VIMEO:$2--]]');
  }

  private player(value: string) {
    return (value + ' ').replace(new RegExp(playerRegexr, 'g'), '[[--EMBED:PLAYER:$2--]]');
  }

  private adjustableWidth(value) {
    return value.replace(/(<iframe.*?width=")([^"]+)/g, '$1' + this.embedWidth);
  }
  private adjustableHeight(value) {
    return value.replace(/(<iframe.*?height=")([^"]+)/g, '$1' + this.embedHeight);
  }

  transformAs(value, type) {
    value || (value = '')
    switch (type) {
      case 'video':
        value = this.youtube(value);
        value = this.vimeo(value);
        value = this.player(value);
        value = this.lineBreak(value);
        value = this.linky(value);
        return this.handleEmbeds(value);
      case 'embed':
        value = this.youtube(value);
        value = this.vimeo(value);
        value = this.player(value);
        value = this.lineBreak(value);
        value = this.linky(value);
        return this.sanitizer.sanitize(SecurityContext.HTML, value);
      case 'adjustableSize':
        value = this.adjustableWidth(this.adjustableHeight(value))
        return this.sanitizer.bypassSecurityTrustHtml(value);

      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  public transform(value: any, type: string | Array<string> = 'video'): string | SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {

    if (type instanceof Array) {
      for (let i = 0; i < type.length; i++) {
        let prop = type[i];
        value = this.transformAs(value || '', prop);
      }
    } else {
      value = this.transformAs(value || '', type);
    }

    return value;
  }
}

@NgModule({
  imports: [],
  declarations: [EmbedPipe],
  exports: [EmbedPipe],
})

export class EmbedPipesModule { }
