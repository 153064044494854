
import { takeWhile, map, catchError, take } from 'rxjs/operators';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Note, TargetNote, INote } from '../notes/note';

import { BaseService, } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Task } from '@board/items/task/task';
import { Notification } from '@board/_components/notification';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { ScheduledTask } from '@board/items/scheduled-task/scheduled-task';


export interface IModelService {
  orderImages(newOrder: Array<string>, note: any);
  remove(item: any, parent?: any);
}

interface INoteListOptions {
  item: Task | ScheduledTask,
  limit?: number,
  offset?: number
}

@Injectable({
  providedIn: 'root',
})
export class NoteService extends BaseService implements IModelService {

  private _notes: BehaviorSubject<Note[]>;
  _note: BehaviorSubject<Note>;
  private dataStore: {
    notes: {},
    total: {},
    targetNote: TargetNote,
    focusedNote: Note
  }
  private _targetNote: BehaviorSubject<TargetNote>;
  private _focusedNote: BehaviorSubject<Note>;
  private _endPoint: string;

  private options: INoteListOptions;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private targetService: TargetResourceService,
    public alertService: AlertService,
    private ga: GoogleAnalyticsService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/notes`;
    this.dataStore = { notes: null, total: null, targetNote: null, focusedNote: null };
    this._targetNote = <BehaviorSubject<TargetNote>>new BehaviorSubject(null);
    this._focusedNote = <BehaviorSubject<Note>>new BehaviorSubject(null);

    this._notes = <BehaviorSubject<Note[]>>new BehaviorSubject(null);
  }

  get targetNote$(): Observable<TargetNote> {
    return this._targetNote.asObservable();
  }
  get focusedNote$(): Observable<Note> {
    return this._focusedNote.asObservable();
  }

  get notes$(): Observable<Note[]> {
    return this._notes.asObservable();
  }

  get key(): string {
    let k: string = this.options.item.Id;
    return k;
  }

  get total(): number {
    return this.dataStore.total[this.key];
  }

  getNotes(options: INoteListOptions) {
    if (!options) return;
    this.options = options;

    if (this.inStorage()) {
      this.options.item.notifications.forEach((notification) => {
        this.handleNotification(notification);
      })
      return observableOf(this.getFromStorage());
    }

    let payload = {
      'filter[ProjectId]': options.item.ProjectId,
      'filter[TaskId]': options.item.Id,
      'limit': (options.limit || 15).toString(),
      'offset': (options.offset || 0).toString(),
    }
    let params = new HttpParams({
      fromObject: payload
    });

    return this.http.get(this._endPoint, { params: params, observe: 'response' }).pipe(
      take(1),
      map(response => {
        if (options.offset === 0) {
          this.initStorage(options);
        }
        let data = response.body;
        let headers = response.headers as HttpHeaders;
        this.dataStore.total[this.key] = parseInt(headers.get('X-Items-Count'), 10) || 0;
        for (let key in data) {
          this.pushToStorage(new Note(data[key]));
        };

        if (this.targetService.targetLevel > 2) {
          this.setCurrentNoteFromTarget(this.options.item);
        }

        return this.getFromStorage();
      }), catchError(error => {
        console.log('Could not load notes');
        this.alertService.error(error, ServiceAlertClass.ALERTS.NOTE_LIST);
        return observableOf(null);
      })
    )
  }

  /**
   * This method is actioned by QueryRequestService
   * @param id 
   */
  loadTarget(id: string, openComments: boolean) {
    this.http.get(`${this._endPoint}/${id}/target`)
      .subscribe(
        data => {
          this.dataStore.targetNote = new TargetNote(data);
          this.dataStore.targetNote._showComments = openComments;

          let tNote = Object.assign({}, this.dataStore).targetNote;
          this._targetNote.next(tNote);
        },
        error => {
          console.log('Could not load target task');
        }
      )
  }

  create(note: Note) {
    return this.http.post(this._endPoint, note.toDb()).pipe(
      map(data => {
        let newNote = new Note(data);
        newNote._state = 'new';
        newNote._attachments = note._attachments;

        setTimeout(() => {
          newNote._state = ''
        }, 100);

        this.pushToStorage(newNote, true).emit();
        this.ga.platformEvent('message', 'new');
        return newNote;
      }), catchError(error => {
        console.log('Could not create note.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.NOTE_CREATE);
        return observableOf(null);
      })
    )
  }

  update(note: Note) {
    return this.http.put(`${this._endPoint}/${note.Id}`, note.toDb()).pipe(
      map(data => {
        note.UpdateDate = data['UpdateDate'];
        this.ga.platformEvent('message', 'update');
        return note;
      }), catchError(error => {
        console.log('Could not update note. STATUS: ' + error.status);
        this.alertService.error(error, ServiceAlertClass.ALERTS.NOTE_UPDATE);
        return observableOf(null);
      })
    )
  }

  remove(note: Note) {
    note._state = 'remove';
    return this.http.delete(`${this._endPoint}/${note.Id}`).pipe(
      map(data => {
        this.removeFromStorage(note).emit();
        return true;
      }), catchError(error => {
        console.log('Could not remove note.');
        this.alertService.snackError(error);
        return observableOf(null);
      })
    )
  }

  handleNotification(notification: Notification) {
    if (notification.isNote()) {
      switch (notification.action()) {
        case 'create':
          let newNote = new Note(notification.data);
          newNote._state = 'notification_new';
          setTimeout(() => {
            newNote._state = ''
          }, 100);
          this.pushToStorage(newNote, true);
          if (notification._inView) {
            this.emit();
          }
          break;
        case 'update':
          this.updateUI(notification.data);
          break;
      }
    }
  }

  pushNotification(note: Note): Observable<boolean> {
    // console.log(['url', `${this._endPoint}/${note.Id}/push`]);
    return this.http.post(`${this._endPoint}/${note.Id}/push`, note.toDb()).pipe(
      map(data => {
        this.alertService.snackSuccess('Participants were succesfully notified');
        return true;
      }),
      catchError(error => {
        console.log('Could not push note notification');
        this.alertService.snackWarning('Couldn\'t push note notification');
        return observableOf(false);
      })
    )
  }


  flagNote(note: Note, projectId, accountId) {
    let payload = {
      NoteId: note.Id,
      TaskId: note.TaskId,
      ProjectId: projectId,
      AccountId: accountId
    }
    return this.http.post(`${this._endPoint}/${note.Id}/flag`, payload).pipe(
      map(data => {
        note._readLater = true;
        return true;
      }),
      catchError(error => {
        console.log('Could not flag note');
        this.alertService.snackWarning('Couldn\'t flag the note');
        return observableOf(false);
      })
    )
  }

  unflagNote(note: Note) {
    return this.http.delete(`${this._endPoint}/${note.Id}/flag`).pipe(
      map(data => {
        note._readLater = false;
        return true;
      }),
      catchError(error => {
        console.log('Could not unflag note');
        this.alertService.snackWarning('Couldn\'t unflag the note');
        return observableOf(false);
      })
    )
  }

  orderImages(newOrder: Array<string>, note: Note) {
    return this.http.put(`${this._endPoint}/${note.Id}/order`, {
      'UpdateDate': note.UpdateDate,
      'order': newOrder
    }).pipe(
      map(data => {
        note.Settings.imageOrder = data['imageOrder'];
        note.UpdateDate = data['UpdateDate'];
        return note;
      }),
      catchError(error => {
        console.log('Could not update note. Image Order: ' + error.status);
        return observableOf(false);
      })
    )
  }

  private initStorage(options: INoteListOptions) {
    let key = options.item.Id;
    if (!this.dataStore.notes) {
      this.dataStore.notes = {}
      this.dataStore.total = {}
    }
    this.dataStore.notes[key] = [];
    this.dataStore.total[key] = 0;
  }

  private inStorage() {
    let key = this.options.item.Id;
    if (!this.dataStore.notes || !this.dataStore.notes[key]) {
      return false;
    }

    if (this.dataStore.notes[key].length < this.options.limit + this.options.offset && this.dataStore.notes[key].length < this.dataStore.total[key]) {
      return false
    }

    return !!this.dataStore.notes[key]
  }


  private pushToStorage(note: INote, inFront: boolean = false) {
    if (inFront) {
      this.dataStore.notes[this.key].splice(0, 0, note);
    } else {
      this.dataStore.notes[this.key].push(note);
    }
    return this;
  }


  private removeFromStorage(note: INote) {
    if (this.dataStore.notes[this.key]) {
      this.dataStore.notes[this.key].forEach((t, i) => {
        if (t.Id === note.Id) {
          this.dataStore.notes[this.key].splice(i, 1);
        }
      });
    }
    return this;
  }

  private getFromStorage() {
    // if (this.dataStore.notes[key].length < this.options.limit + this.options.offset && this.dataStore.notes[key].length < this.dataStore.total[key]) {
    //   return false
    // }
    let n = Object.assign({}, this.dataStore).notes[this.key].slice(0, this.options.limit + this.options.offset);
    return n;
  }

  private emit() {
    this._notes.next(this.getFromStorage());
  }

  private updateUI(data) {
    if (this.dataStore.notes[this.key]) {
      this.dataStore.notes[this.key].forEach((note: Note, i) => {
        if (note.Id === data.Id) {
          note.__update(data);
        }
      });
    }
    return this;
  }

  private setCurrentNoteFromTarget(task: Task | ScheduledTask) {
    let targetsSet: boolean = false;
    this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
      .subscribe((targets) => {
        // console.log(['targets.noteId', targets.noteId]);
        if (targets.noteId) {
          targetsSet = true;
          let note = this.findNote(targets.noteId, task.Id);
          if (note) {
            note._showComments = targets._showComments;
            this.focusNote(note);
          }
        }
      })
  }

  findNote(noteId: string, taskId: string): Note {
    let note: Note;
    if (!this.dataStore.notes || !this.dataStore.notes[taskId]) {
      return null;
    }
    for (let n of this.dataStore.notes[taskId]) {
      if (n.Id === noteId) {
        note = n;
        break;
      }
    }

    return note || null;
  }

  focusNote(note: Note) {
    this.targetService.reset();
    this._focusedNote.next(note);
  }

}