export interface IServiceStates {
    LOADING: number;
    PROCESSING: number;
    ERROR: number;
    DONE: number;
    EMPTY: number;
    UNAUTHORIZED: number;
    RESENDING: number;
}

export class ServiceStates {
    static readonly STATES: IServiceStates = {
        LOADING: 1,
        PROCESSING: 2,
        ERROR: 3,
        DONE: 4,
        EMPTY: 5,
        UNAUTHORIZED: 6,
        RESENDING: 7
    }
}