
export interface IUser {
    Id: string;
    FirstName: string;
    LastName: string;
    Email: string;
}

export class DefaultUser implements IUser {
    Id: string = 'johndoe';
    FirstName: string = 'John';
    LastName: string = 'Doe';
    Email: string = 'john@doe';
}