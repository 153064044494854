import { Injectable, Inject } from '@angular/core';

import { SOCKET_CONFIG_TOKEN, SocketIoConfig } from './socket-io-config.module';
import { SocketConnector } from './socket-io.connector';
import { ITokenHelper, TOKEN_HELPER } from './socket-io.token.helper';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  subscribersCounter = 0;
  private connector: SocketConnector;

  constructor(
    @Inject(SOCKET_CONFIG_TOKEN) config: SocketIoConfig,
    @Inject(TOKEN_HELPER) private tokenHelper: ITokenHelper,
  ) {
    const url: string = config.url || '';
    const options: any = config.options || {};

    this.connector = new SocketConnector({
      host: url,
      auth: {
        headers: {
          'Authorization': 'Bearer ' + tokenHelper.getAuthToken(),
          'X-Requested-With': 'XMLHttpRequest',
        }
      },
    });

    this.connector.onSocketId = (id: string) => {
      this.tokenHelper.setSocketId(id);
    }
  }

  /**
   * Listen for an event on a channel instance.
   */
  listen(channel, event, callback) {
    return this.connector.listen(channel, event, callback);
  };

  /**
   * Get a channel instance by name.
   *
   * @param  {string}  channel
   * @return {object}
   */
  channel(channel) {
    return this.connector.channel(channel);
  };

  /**
   * Get a private channel instance by name.
   *
   * @param  {string} channel
   * @return {object}
   */
  privateCh(channel) {
    return this.connector.privateChannel(channel);
  };

  /**
   * Get a presence channel instance by name.
   *
   * @param  {string} channel
   * @return {object}
   */
  joinCh(channel) {
    return this.connector.presenceChannel(channel);
  };

  /**
   * Leave the given channel.
   *
   * @param  {string} channel
   */
  leaveCh(channel) {
    this.connector.leave(channel);
  };

}