<div class="preview-details" fxLayout="column" fxLayoutAlign="space-around center">
     <div class="title"> {{ file.FileName }} </div>
     <div>
          Added
          <span class="date" [attr.title]="file.CreateDate" [innerHTML]="file.CreateDate | amFromUtc | amTz:tz | amDateFormat:'MMM D, YYYY h:mm a z'"></span>
          <b> - <span [innerHTML]="file.Size | size"></span> </b>
     </div>
     <button mat-button class="download" (click)="download($event, file)" *ngIf="downloadable && file.allowActions">
          <mat-icon *ngIf="!file.inProgress" svgIcon="board-mat:download"></mat-icon>
          <mat-spinner *ngIf="file.inProgress" color="accent" [diameter]="20" [strokeWidth]="3" style="display: inline-block; vertical-align: middle;"></mat-spinner>
          <span>Download </span>
     </button>
</div>