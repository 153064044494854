import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { SurveyOverlayComponent } from './survey-overlay.component';
import { SurveyOverlayService } from './survey-overlay.service';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
    ],
    declarations: [
        SurveyOverlayComponent,
    ],
    providers: [
        SurveyOverlayService,
    ],
    exports: []
})
export class SurveyOverlayModule {
}
