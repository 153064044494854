import {
  Component, Output, EventEmitter, OnInit, Input
} from '@angular/core';

export enum PUBLISH_TABS {
  SETTINGS = 'settings',
  PREVIEW = 'preview'
}

@Component({
  selector: 'mgc-publish-tabs',
  template: `
    <div class="mgc-tabs">
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="settingsTab"
          (click)="tabClick(tabs.SETTINGS)">Edit</span>
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="previewTab"
          (click)="tabClick(tabs.PREVIEW)">Preview</span>
      <div class="mgc-link-bar" [style.width.px]="tabWidth" [style.left.px]="leftPosition"></div>
    </div>
  `, styles: [`
    :host{
      display: block;
      height: 40px;
      margin-bottom: 5px;
    }
  `]

})
export class PublishTabsComponent implements OnInit {
  @Input() tab: PUBLISH_TABS;
  @Output() tabChange: EventEmitter<PUBLISH_TABS> = new EventEmitter<null>();

  constructor(
  ) {
  }

  get tabs() {
    return PUBLISH_TABS;
  }

  /** TABS */
  tabWidth = 100;
  get settingsTab() {
    return this.tab === PUBLISH_TABS.SETTINGS;
  }
  get previewTab() {
    return this.tab === PUBLISH_TABS.PREVIEW;
  }
  get leftPosition(): number {
    return this.settingsTab ? 0 : this.tabWidth;
  }
  /** end TABS */

  tabClick(tab: PUBLISH_TABS) {
    this.tabChange.emit(this.tab = tab);
  }

  ngOnInit() {
    this.tab || (this.tab = PUBLISH_TABS.PREVIEW);
    this.tabChange.emit(this.tab);
  }

}
