
import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Project } from '../project';
import { PermissionService } from '@board/_services';
import { PROJECT_STATISTICS_TABS } from './project-statistics-tabs.component';

@Component({
  selector: 'mgc-project-statistics',
  templateUrl: './project-statistics.component.html',
})
export class ProjectStatisticsComponent implements OnInit {
  @HostBinding('class.mgc-statistics') statisticsClass = true;

  @Input('item') project: Project;

  allow: boolean = false;

  selectedStatisticsTab: PROJECT_STATISTICS_TABS;
  get tabs() { return PROJECT_STATISTICS_TABS }

  constructor(
    private permissionService: PermissionService,
  ) {
  }

  ngOnInit() {
    if (!this.permissionService.allowsAction('update.project', this.project, { silent: true })) {
      this.allow = false;
    } else {
      this.allow = true;
    }

  }

}
