
import {
    Component, OnInit, OnDestroy,
    SimpleChange, ViewChild, Output, EventEmitter, AfterViewInit
} from '@angular/core';
import { IPopupView } from '../popup-view.interface';
import { Account } from '@board-accounts/account.model';

import { Subscription } from 'rxjs';
import { AccountDataComponent } from '@board-accounts/_components/account-data/account-data.component';

@Component({
    selector: 'account-profile',
    templateUrl: './account-profile.component.html',
    styles: [`
        :host{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class AccountProfileComponent implements IPopupView, OnInit, OnDestroy, AfterViewInit {
    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(AccountDataComponent) accountData: AccountDataComponent;

    data: any;

    account: Account;

    accountDataSubscription: Subscription;

    constructor(
    ) {
    }

    get processing() {
        if (this.accountData) {
            return this.accountData.state === this.accountData.states.PROCESSING;
        }

        return false;
    }

    get submitAvailable() {
        if (this.accountData) {
            return this.accountData.dirty();
        }
        return false;
    }

    onSubmit(): void {
        this.accountData.onSave();
    }

    ngOnInit() {
        this.account = this.data.account;
    }

    ngAfterViewInit() {
        this.accountDataSubscription = this.accountData.close.subscribe(result => {
            this.close.emit(result);
        })
    }

    ngOnDestroy() {
        if (this.accountDataSubscription) {
            this.accountDataSubscription.unsubscribe();
        }
    }
}
