<div *ngIf="debugMe" style="box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);z-index:20 ; padding: 10px; width: 300px; min-height: 120px; background-color: white; color: gray;position: fixed; top: 70px; right: 10%;" >
    <h4>notes: {{notes?.length}} ({{totalNotes}}) <span style="font-size: 90%;font-weight: normal;" *ngIf="state===states.LOADING">... loading - {{options?.limit}}</span></h4>
    <p>width: {{ el.nativeElement.offsetWidth}}</p>

    <p>elHeight : {{ el.nativeElement.offsetHeight}}</p>
    <p>clientHeight : {{ el.nativeElement.clientHeight}}</p>
    
    <p>scrollNOtesContainer: {{ notesContainer?.nativeElement.offsetHeight}}</p>

    <p>scrollTop: {{ el.nativeElement.scrollTop}}</p>
</div>

<alert [service]="[serviceAlertClass.NOTE_LIST]">
</alert>

<div class="notes-container" #notesContainer *ngIf="notesReady && notes.length" [@list]="notes.length">
    <ng-container *ngFor="let note of notes; trackBy: trackByFn">
        <mgc-note [note]="note" [text]="note.Text" [task]="item"></mgc-note>
    </ng-container>
</div>

<mgc-loader *ngIf="state===states.LOADING || loadingMore"></mgc-loader>