<mgc-dialog>

  <mgc-dialog-header title="Custom Color Theme" (close)="closeDialog()">
  </mgc-dialog-header>

  <mgc-dialog-content>
    <mgc-custom-color [customColorOptions]="customColorOptions"></mgc-custom-color>
  </mgc-dialog-content>

  <alert></alert>

  <mgc-dialog-actions>
    <mgc-raised-button right (click)="onProcess()" tabindex="2"><span>Done</span>
    </mgc-raised-button>
    <!-- <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button> -->
  </mgc-dialog-actions>

</mgc-dialog>