<ng-container *ngIf="!preview">

  <div class="media-block-drag flx-clm">
    <mgc-icon-button class="click-move" (click)="onMove(-1)" icon="board-mat:keyboard_arrow_up"></mgc-icon-button>
    <mgc-icon-button class="drag-handle" icon="board-mat:unfold-more" cdkDragHandle></mgc-icon-button>
    <mgc-icon-button class="click-move" (click)="onMove(1)" icon="board-mat:keyboard_arrow_down"></mgc-icon-button>
  </div>

  <div class="media-block-content pad-hrz-xs">
    <div class="media-block-item-header">

    </div>
    <div class="media-block-item-body">
      <ng-container *ngTemplateOutlet="textBodyTpl"></ng-container>
    </div>
    <div class="media-block-item-footer">
      <mgc-icon-button class="remove" (click)="onRemove(block)" icon="board-mat:trash"></mgc-icon-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="preview">
  <div [innerHTML]="block.Content | safe : 'html'"></div>
</ng-container>

<ng-template #textBodyTpl>
  <jodit-editor (onChange)="handleOnChange($event, block)" [defaultValue]="block.Content" [config]="joditConfig">
  </jodit-editor>
</ng-template>
