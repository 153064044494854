
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { inject, Inject, Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationExtras,
  Route,
  UrlSegment,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { AuthService } from './auth.service';
import { WINDOW } from '@rallysite/global-services';
import { PUBLIC_PAGE_PARAMS } from 'app/params';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const urlSegments: UrlSegment[] = route.url;

    let url: UrlSegment;
    const lastSegment: UrlSegment = urlSegments.pop();
    if (!lastSegment || lastSegment.parameterMap.keys.length === 0) {
      url = new UrlSegment(state.url, {});
    } else {
      url = new UrlSegment(urlSegments.map(u => u.path).join('/') + `/${lastSegment.path}`, lastSegment.parameters);
    }

    return this.checkLogin(url);
  }

  /**
   *
   * @param route
   */
  canMatch(route: Route): Observable<boolean> | boolean {
    const path = `/${route.path}`;

    if (
      path.indexOf('admin') === 1 ||
      path.indexOf('assessments') === 1 ||
      path.indexOf('dashboard') === 1 ||
      path.indexOf('spiritual-dashboard') === 1 ||
      path.indexOf('feed-your-soul') === 1
    ) {
      return this.authenticate(path);
    }

    if (path.indexOf('i/auth') === 1) {
      if (this.authService.isAuthenticated) {
        this.authService.cleanUser();
      }
      return observableOf(true);
    }

    if (path.indexOf('auth') === 1) {
      if (this.authService.isAuthenticated) {
        this.router.navigate([this.authService.redirectUrl.path], { queryParams: this.authService.redirectUrl.parameters });
        return observableOf(false);
      }
      return observableOf(true);
    }

    return observableOf(false);
  }

  /**
   *
   * @param url
   */
  checkLogin(url: UrlSegment): Observable<boolean> {
    if (this.authService.isAuthenticated) {
      return observableOf(true);
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Create a dummy session id
    // let sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      // queryParams: { 'session_id': sessionId },
      // fragment: 'anchor'
    };

    // console.log(['checkLogin() -> redirect to LOGIN']);
    // Navigate to the login page with extras
    this.router.navigate([this.authService.authUrl.path], { queryParams: this.authService.authUrl.parameters });

    return observableOf(false);
  }

  /**
   *
   * @returns
   */
  private authenticate(path: string) {

    return this.authService.authenticate().pipe(
      map(user => {
        if (user) {
          return true;
        }

        if (!this.window.location) {
          return false;
        }

        const authParams = {};
        const url = new URL(this.window.location.href);
        ['email'].forEach((qs) => {
          if (url.searchParams.has(qs)) {
            authParams[qs] = url.searchParams.get(qs);
          }
        });

        const redirectParams = {};
        [...PUBLIC_PAGE_PARAMS, 'p', 't'].forEach((qs) => {
          if (url.searchParams.has(qs)) {
            redirectParams[qs] = url.searchParams.get(qs);
          }
        });

        const { pathname } = url;
        this.authService.redirectUrl = new UrlSegment(`${pathname}`, redirectParams);
        let authSegment = new UrlSegment('/auth/signin', authParams);

        if (path.indexOf('spiritual-dashboard') === 1) {
          const crossUser = this.authService.crossUserRegister.getValue();
          if (crossUser) {
            this.authService.crossUserRegister.next({ email: crossUser.email, redirect: true });
            authSegment = new UrlSegment(`/auth/confirm/${crossUser.email}`, authParams);
          }
        }

        this.router.navigate([authSegment.path], { queryParams: authSegment.parameters });
        return false;
      })
    );
  }
}

export const IsAuthGuardCanMatch: CanMatchFn = (route: ActivatedRouteSnapshot): Observable<boolean> | boolean =>
  inject(AuthGuard).canMatch(route);

export const IsAuthGuardCanActivate: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean =>
  inject(AuthGuard).canActivate(route, state);
