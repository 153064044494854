import { Task } from "@board/items/task/task";
import { Project } from "@board/items/project/project";

export interface IGroupCompleteState {
    isComplete: boolean,
    tasksComplete: number
}


export interface ITaskGroup {
    Id: string;
    ProjectId: string;
    AccountId: string;
    Name: string;
    Description: string;
    Status: string;
    // Settings: any;
    UpdateDate: string;

    _notifications: number;
    _flaggedNotes: number;
    _active: boolean;
    _type: string;
    _lastTask: Task;
    _state: string;
    isDefault: boolean;

    _completeState: IGroupCompleteState;

    toDb();
    getId();
    settingsOptions(project: Project, edit: boolean);

    increaseNotificationCounter(val: number);
    decreaseNotificationCounter(val: number);
}

export class BaseTaskGroup {
    readonly _type: string = 'task-group';

    Id: string;
    ProjectId: string;
    AccountId: string;
    Name: string;
    Description: string;
    // Settings: any;
    Status: string;
    UpdateDate: string;

    _notifications: number;
    _flaggedNotes: number;
    _active: boolean;
    _lastTask: Task = null;
    _state: string;

    data?: any;

    _completeState: IGroupCompleteState = {
        isComplete: false,
        tasksComplete: 0
    };

    constructor() { }

    increaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications + val;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }

    toDb() {
        return {
            Id: this.getId(),
            ProjectId: this.ProjectId,
            AccountId: this.AccountId,
            Name: this.Name,
            Description: this.Description,
            // Settings: this.Settings.toDb(),
            Status: this.Status,
            UpdateDate: this.UpdateDate
        }
    }

    getId() {
        return this.Id === DefaultTaskGroup.ID ? null : this.Id;
    }

    get isDefault() {
        return this.Id === DefaultTaskGroup.ID;
    }

    settingsOptions(project: Project, edit: boolean = false) {
        edit = !!edit;
        return {
            // width: '55vw',
            maxWidth: '700px',
            // minWidth: '300px',
            // disableClose: true,
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Folder',
                taskGroup: this,
                project: project,
                edit: edit
            }
        }
    }
}

export class TaskGroup extends BaseTaskGroup implements ITaskGroup {

    constructor(data: any) {
        super();

        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}

export class DefaultTaskGroup extends BaseTaskGroup implements ITaskGroup {

    public static ID: string = '_default_';

    constructor(project: Project, data: any) {
        super();
        for (let prop in data) {
            this[prop] = data[prop];
        }

        this.Id = DefaultTaskGroup.ID;
        this._active = true; // active (open) all the time
        this.ProjectId = project.Id;
        this.AccountId = project.AccountId;

        this.Name = 'Keep on top';
    }
}

