import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService } from '@board-accounts/account.service';
import { BaseService } from '@board/_services';
import { AlertService, IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { of, Subscription } from 'rxjs';
import { filter, finalize, switchMap, take } from 'rxjs/operators';
import { UIAttachmentsBlock } from './attachments-block/attachments-block';
import { UIAttachmentsBlockComponent } from './attachments-block/attachments-block.component';
import { UIImageBlock } from './image-block/image-block';
import { UIImage } from './image-block/image/ui-image';
import { IUIBlock } from './ui-block.interface';
import { UIBlocksService } from './ui-blocks.service';
import { UITextBlock } from './text-block/text-block';
import { UIBlockAction, UIBlockType, UI_BLOCKS } from './types';
import { SurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import {SelectedPLevel} from '@board/surveys/survey-factory/survey-feedbacks/survey-feedback-plevel.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'ui-blocks',
    templateUrl: './ui-blocks.component.html',
    styleUrls: ['./ui-blocks.component.scss']
})
export class UIBlocksComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    constructor(
        private formBuilder: FormBuilder,
        private blocksService: UIBlocksService,
        private accountService: AccountService,
        private alertService: AlertService
    ) { }
    get f() { return this.form.controls; }
    /** end of FORM */


    get states() {
        return BaseService.STATES;
    }


    @Input() option: SurveyQuestionOption | null;
    @Input() plevel: SelectedPLevel | null;

    @Output() close: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(UIAttachmentsBlockComponent) attachmentsBlockComponent: UIAttachmentsBlockComponent;
    serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

    ready: boolean;
    loading: boolean;
    processing: boolean;
    subscription: Subscription;

    predefinedBlocks = UI_BLOCKS;
    removedBlocks: IUIBlock[] = [];
    blockType = UIBlockType;

    accountId: string = null;

    initialBlocks: IUIBlock[] = [];

    preview: boolean;

    /** FORM */
    form: FormGroup;

    submitted = false;
    bootstrapForm(blocks: IUIBlock[]) {
        this.submitted = false;
        if (!blocks.length) {
            const block = new UITextBlock(this.option
              ? { OptionId: this.option.Id }
              : { SurveyPgroupId: this.plevel.PgroupId, PLevel: this.plevel.PLevel }
            );
            block._httpAction = UIBlockAction.CREATE;
            blocks.push(block);
        }

        this.form = this.formBuilder.group({
            blocks: [blocks]
        });
    }
    submit() {
        this.submitted = true;

        if (this.form.invalid) {
            return false;
        }

        return true;
    }

    onRemoveBlock(block: IUIBlock) {
        const blocks = this.f.blocks.value;
        for (let i = 0; i < blocks.length; i++) {
            if (blocks[i] === block) {
                // if Id, then is comming from DB, so we mark it as delete
                // otherwise, no need to keep track of it
                if (block.Id) {
                    block._httpAction = UIBlockAction.DELETE;
                    this.removedBlocks.push(block);
                }
                blocks.splice(i, 1);
                this.updatePredefinedBlocks();
            }
        }
    }

    onAddBlock(predifinedBlock) {
        let block: IUIBlock;
        switch (predifinedBlock.type) {
            case UIBlockType.TEXT:
                block = new UITextBlock(this.option
                  ? { OptionId: this.option.Id }
                  : { SurveyPgroupId: this.plevel.PgroupId, PLevel: this.plevel.PLevel }
                );
                break;
            case UIBlockType.IMAGE:
                const image = new UIImage({...(this.option
                  ? { OptionId: this.option.Id}
                  : { SurveyPgroupId: this.plevel.PgroupId, PLevel: this.plevel.PLevel }
                ), AccountId: this.accountService.currentAccount.Id});
                block = new UIImageBlock({ ...(this.option
                  ? { OptionId: this.option.Id}
                  : { SurveyPgroupId: this.plevel.PgroupId, PLevel: this.plevel.PLevel }
                ), Content: image });
                break;
            case UIBlockType.ATTACHMENTS:
                block = new UIAttachmentsBlock({ ...(this.option
                  ? { OptionId: this.option.Id}
                  : { SurveyPgroupId: this.plevel.PgroupId, PLevel: this.plevel.PLevel }
                ), Content: {} });
                this.updatePredefinedBlocks();
                break;
        }

        if (block) {
            block._httpAction = UIBlockAction.CREATE;
            this.f.blocks.value.push(block);
        }
    }

    imageUpdated() {
        // when an image is updated it means it was saved on S3 and we need to perform a save to
        // sync with DB;
        // is more to avoid images uploads without save; will not be able to delete them from S3
        this.onSave(true);
    }

    onSave(autosave = false): void {
        if (!this.submit() || this.processing) {
            return;
        }

        const removedBlocks: IUIBlock[] = this.removedBlocks.map((b: IUIBlock) => b.toDb());
        const blocks: IUIBlock[] = this.form.value['blocks']
            .map((b: IUIBlock, index: number) => {
                b.Position = index + 1;
                return b.toDb();
            });

        this.processing = true;
        this.alertService.clear();

        const saveSubscriber = this.option
          ? this.blocksService.saveOptionBlocks(this.option, blocks.concat(removedBlocks))
          : this.blocksService.savePlevelBlocks(this.plevel, blocks.concat(removedBlocks));

        saveSubscriber
            .pipe(
                take(1),
                finalize(() => this.processing = false)
            )
            .subscribe((blocks: IUIBlock[]) => {
                this.initialBlocks = blocks;
                this.removedBlocks = [];

                this.form.patchValue({ blocks: this.initialBlocks.slice() });
                if (!autosave) {
                    this.preview = this.initialBlocks.length !== 0;
                    this.close.emit('update');
                }
            });
    }

    onCancel(): void {
        this.close.emit('cancel');
    }

    private render(blocks: IUIBlock[]) {
        if (!blocks) {
            return;
        }
        this.preview = blocks.length !== 0;
        this.bootstrapForm(blocks);
    }

    private loadBlocks() {
        this.ready = false;
        this.loading = true;
        this.processing = false;
        this.submitted = false;

        // load blocks for p-level
        if (this.plevel) {
          of(this.plevel)
            .pipe(
              filter(plevel => !!plevel),
              switchMap(plevel => {
                return this.blocksService.getPlevelBlocks(plevel);
              })
            )
            .subscribe((blocks: IUIBlock[]) => {
              this.initialBlocks = blocks;
              this.render(this.initialBlocks.slice());
              this.ready = true;
              this.loading = false;
            });
          return;
        }

        // load blocks for option
        of(this.option)
            .pipe(
                filter(option => !!option),
                switchMap(option => {
                    return this.blocksService.getOptionBlocks(option);
                })
            )
            .subscribe((blocks: IUIBlock[]) => {
                this.initialBlocks = blocks;
                this.render(this.initialBlocks.slice());
                this.ready = true;
                this.loading = false;
            });
    }

    updatePredefinedBlocks() {
        setTimeout(() => {
            this.predefinedBlocks = UI_BLOCKS.filter(b => !(b.type === UIBlockType.ATTACHMENTS && this.attachmentsBlockComponent));
        }, 100);
    }

    onMove(block: IUIBlock, position: 1 | -1) {
        const blocks = this.f.blocks.value;
        const blockIndex = blocks.findIndex((b: IUIBlock) => b.BlockId === block.BlockId);

        blocks.splice(blockIndex, 1);
        blocks.splice(blockIndex + position, 0, block);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['option'] && changes['option'].currentValue || changes['plevel']) {
            this.loadBlocks();
        }
    }

    ngAfterViewInit(): void {
        this.updatePredefinedBlocks();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.blocks.value, event.previousIndex, event.currentIndex);
  }
}
