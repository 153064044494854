import { generateId } from "../generate_block_id";
import { IUIBlock } from "../ui-block.interface";
import {
    UIBlockAction,
    UIBlockType,
    UI_BLOCK_PREFIX
} from "../types";
import { UIImage } from "./image/ui-image";

export class UIImageBlock implements IUIBlock {
    Type: UIBlockType = UIBlockType.IMAGE;
    OptionId: string;
    Id: string;
    BlockId: string;
    CreateDate: string;
    UpdateDate: string;
    Position: number;

    _httpAction: UIBlockAction = UIBlockAction.UPDATE;

    private _image: UIImage;
    set Content(value: UIImage) {
        this._image = value instanceof UIImage ? value : new UIImage(value)
    }
    get Content(): UIImage {
        return this._image;
    }

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${UI_BLOCK_PREFIX.IMAGE}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
        if (!this._image.BlockId) {
            this._image.BlockId = this.BlockId;
        }
    }

    toDb() {
        return {
            Id: this.Id,
            OptionId: this.OptionId,
            BlockId: this.BlockId,
            Content: this.Content.toDb(),
            Type: this.Type,
            Position: this.Position,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }
}
