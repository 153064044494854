
import { takeWhile, filter, finalize, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, HostBinding, Output, EventEmitter, ViewChild, SimpleChanges, AfterViewInit, ChangeDetectorRef, HostListener, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { NoteService } from './note.service';
import { NoteModal } from './note-modal/note-modal';
import { FileService } from './attachments/file.service';
import { Note } from './note';

import { Task } from '@board/items/task/task';
import { PermissionService } from '@board/_services';
import { CurrentResourceService } from '@board/_services';
import { BoardViewService } from '@board/_services';
import { AccountService } from '@board-accounts/account.service';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { Comment, CommentService, CommentModal, CommentListComponent } from "@panel-components/comments";
import { EmailModal } from '@panel-components/email/email-modal';
import { Notification } from '@board/_components/notification';
import { NoteReaction, ReactionsOverlayService, ReactionsService, ReactionTypes } from '@reactions/index';
import { ReactionsModal } from '@reactions/modals/reactions-modal';
import { JoditComponent } from '@libraries/jodit/jodit.component';
import { SnapshotTask } from '@board/items/scheduled-task/snapshot-task/snapshot-task';
import { ShareOverlayService } from '@libraries/social-share/overlay/share-overlay.service';
import { SocialShareService } from '@libraries/social-share/social-share.service';
import { ProjectService } from '@board/items/project';

@Component({
  selector: 'mgc-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit, OnDestroy, AfterViewInit {
  private alive: boolean = true;

  @Input() task: Task;
  @Input() note: Note;
  @Input('text') noteText: string;
  @Input() scrollContainer: Element;

  @ViewChild(JoditComponent) joditEditor: JoditComponent;
  @ViewChild(CommentListComponent) commentList: CommentListComponent;

  @HostBinding('class.mgc-note') noteClass = true;
  @HostBinding('class.remove') get removeClass() {
    return this.note && this.note._state === 'remove';
  }
  @HostBinding('class.new') get newClass() {
    return this.note && this.note._state === 'new';
  }

  allowUpdate: boolean = false;
  allowEmail: boolean = false;
  allowComment: boolean = false;

  pushingNotification: boolean = false;
  notified: boolean = false;

  noteLink: string;

  currentReaction: NoteReaction;
  reactionsData: { type: string, value: number }[] = [];
  reactionClass: string;

  joditConfig = {
    toolbar: false,
    defaultMode: 1,
    readonly: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    preset: 'inline',
    minHeight: 30,
  }

  constructor(
    private dialog: MatDialog,
    private noteService: NoteService,
    private fileService: FileService,
    private permissionsSettings: PermissionService,
    private accountService: AccountService,
    private messageBus: MessageBusService,
    private commentService: CommentService,
    private currentResource: CurrentResourceService,
    private boardViewService: BoardViewService,
    private reactionsOverlay: ReactionsOverlayService,
    private reactionsService: ReactionsService,
    private socialShareOverlay: ShareOverlayService,
    private shareService: SocialShareService,
    private projectService: ProjectService,
  ) {
  }

  commentsLabel: string;
  commentsToggle(event: MouseEvent, show: boolean = null) {
    if (typeof show === 'boolean') {
      this.note._showComments = show;
    } else {
      this.note._showComments = !this.note._showComments
    }

    this.adjustLabel();
    if (event) {
      event.stopPropagation();
    }
  }

  private adjustLabel() {
    // if (this.note._showComments) {
    //   this.commentsLabel = 'Hide ';
    // } else {
    //   this.commentsLabel = 'Show ';
    // }
    this.commentsLabel = '';
    const len = this.note._comments;
    this.commentsLabel += `${len} comment${len > 1 ? 's' : ''}`;
  }

  get account() {
    return this.accountService.currentAccount;
  }


  openReactions(event) {
    this.reactionsOverlay.open(event.currentTarget, this.currentReaction).afterClosed()
      .subscribe((reactionType: ReactionTypes) => {
        this.currentReaction || (this.currentReaction = new NoteReaction({
          AccountId: this.account.Id,
          NoteId: this.note.Id,
        }));

        if (this.currentReaction.Type && this.currentReaction.Type.toLowerCase() === ReactionTypes[reactionType].toLowerCase()) {
          this.renderClass(null);
          this.reactionsService.deleteReaction(this.note, this.currentReaction).subscribe(data => {
            this.render(null);
          });
        } else {
          this.currentReaction.Type = ReactionTypes[reactionType];
          this.renderClass(this.currentReaction);
          this.reactionsService.react(this.note, this.currentReaction).subscribe(data => {
            this.render(this.note.findReaction(this.account.Id));
          });
        }

      });
  }

  openReactionsData($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    let config: MatDialogConfig = {
      maxWidth: '500px',
      minWidth: '350px',

      data: {
        item: this.note,
        groupedReactions: this.note.groupedReaction()
      }
    }

    this.dialog.open(ReactionsModal, config)
      .afterClosed().pipe(take(1)).subscribe(result => {
        //
      });
  }


  openNoteModal() {
    if (!this.permissionsSettings.allowsAction('update.note', this.note, { message: 'Not allowed to edit this note' })) {
      return false;
    }

    this.dialog.open(NoteModal, this.note.settingsOptions(this.task, true, this.boardViewService.smallView()))
      .afterClosed().pipe(take(1)).subscribe(result => {
        this.adjustLabel();
      });
  }

  openEmailModal() {
    if (this.isSnapshotTask) {
      return false;
    }
    if (!this.permissionsSettings.allowsAction('create.mail', this.note, { message: 'Not allowed send emails' })) {
      return false;
    }

    this.dialog.open(EmailModal, {
      data: {
        note: this.note,
        task: this.task
      }
    });
  }

  openCommentModal() {
    if (this.isSnapshotTask) {
      return false;
    }
    this.note._showComments = true;

    let comment = new Comment({
      Text: '',
      NoteId: this.note.Id,
      AccountId: this.accountService.currentAccount.Id
    });

    this.dialog.open(CommentModal, comment.settingsOptions({note: this.note}))
      .afterClosed().pipe(take(1)).subscribe(result => {
        this.adjustLabel();
      });
  }

  /**
   * Share sections
   */
  shareClass: string = 'share-icon no-share';
  totalShares: number = 0;
  personalShares: number = 0;
  shareEnabled: boolean = true;
  bootstrapShares() {
    const project = this.currentResource.project;
    if (!project.IsPublic) {
      this.shareEnabled = false;
      return;
    }

    this.projectService.getProjectPubdata(project).pipe(take(1))
      .subscribe(pubdata => {
        this.renderShareClass();

        const brand = (pubdata || {})._brand;
        // const brandId = brand && brand.UName ? brand.UName : DEFAULT_BRAND_UNAME;
        // const projectId = project.UName ? project.UName : project.Id;

        this.shareService.init({
          account: this.accountService.currentAccount,
          brand: brand,
          project: project,
          task: this.task
        });
        const options = { taskId: this.task.Id, noteId: this.note.Id };
        this.shareService.getTotalShares$(options).subscribe(tShares => this.totalShares = tShares)
        this.shareService.getPersonalShares$(options).subscribe(pShares => {
          this.personalShares = pShares
          this.renderShareClass(this.personalShares > 0);
        })
        this.shareService.shares(options).pipe(take(1)).subscribe();
      });
  }

  engageShare(event: MouseEvent) {
    this.shareService.note = this.note;
    this.socialShareOverlay.open(event.currentTarget).afterClosed().subscribe();
    // this.renderShareClass(true);
  }

  renderShareClass(shared: boolean = false) {
    let classes = [
      'share-icon',
    ];
    classes.push(shared ? 'shared' : 'no-share')
    this.shareClass = classes.join(' ');
  }
  /** end share*/


  pushNotification() {
    if (!this.permissionsSettings.allowsAction('update.note', this.note, { message: 'Not allowed to notify' })) {
      return false;
    }

    if (this.notified) {
      return false;
    }
    this.pushingNotification = true;
    this.noteService.pushNotification(this.note).pipe(
      take(1),
      finalize(() => {
        this.pushingNotification = false;
        this.notified = true;
      }))
      .subscribe(data => { })
  }

  commentRemoved() {
    this.adjustLabel();
  }

  removeNote() {
    if (!this.permissionsSettings.allowsAction('delete.note', this.note, { message: 'Not allowed to delete this note' })) {
      return false;
    }
    this.noteService.remove(this.note);
  }

  logSuccess($event) {
    this.noteService.alertService.snackSuccess('Note link copied to clipboard');
  }

  logError($event) {
    this.noteService.alertService.snackWarning('Error: Not able to copy note link', { horizontalPosition: 'center' });
  }

  private commentsInView(notification: Notification) {
    return this.task.Id === notification.task_id && this.note._showComments;
  }

  toggleReadLater() {
    this.note._readLater = !this.note._readLater;

    if (this.note._readLater) {
      this.noteService.flagNote(this.note, this.task.ProjectId, this.accountService.currentAccount.Id).pipe(
        take(1)).subscribe(() => {
          this.messageBus.publish(MessageBusActions.NOTE_FLAGGED, { flag: true, task: this.currentResource.task })
          this.currentResource.project._flaggedNotes++;
          this.currentResource.umbrella._flaggedNotes++;

          this.currentResource.project._refreshFlaggedNotes = true;
          this.accountService.currentAccount._refreshFlaggedNotes = true;
        })
    } else {
      this.noteService.unflagNote(this.note).pipe(take(1)).subscribe(() => {
        this.messageBus.publish(MessageBusActions.NOTE_FLAGGED, { flag: false, task: this.currentResource.task })
        this.currentResource.project._flaggedNotes--;
        this.currentResource.umbrella._flaggedNotes--;

        this.currentResource.project._refreshFlaggedNotes = true;
        this.accountService.currentAccount._refreshFlaggedNotes = true;
      })
    }
  }

  render(reaction: NoteReaction) {
    this.currentReaction = reaction;
    this.reactionsData = this.note.groupedReaction(3);
  }

  renderClass(reaction: NoteReaction) {
    let classes = [
      'reaction-icon',
    ];
    if (reaction) {
      classes.push('reacted');
      classes.push(reaction.Type);
    } else {
      classes.push('no-reaction');
    }
    this.reactionClass = classes.join(' ');
  }

  newPostsLoading: boolean = false;
  loadComments() {
    if (!this.commentList) {
      this.note._showComments = true;
      this.note._comments = 1;
      this.note._hasNewComments = false;
      return;
    }

    this.newPostsLoading = true;
    this.commentList.reload().pipe(take(1), finalize(() => this.newPostsLoading = false))
      .subscribe(result => {
        this.note._hasNewComments = !result;
      });
  }


  isSnapshotTask: boolean = false;
  ngOnInit() {
    // this.noteLink = window.location.protocol + '//' + window.location.host + '/board;n=' + this.note.Id

    this.isSnapshotTask = this.task instanceof SnapshotTask;
    let reaction = this.note.findReaction(this.account.Id);
    this.renderClass(reaction);
    this.render(reaction);

    this.allowUpdate = this.permissionsSettings.allowsAction('update.note', this.note, { silent: true });
    this.allowEmail = this.permissionsSettings.allowsAction('create.mail', this.note, { silent: true });
    this.allowComment = this.permissionsSettings.allowsAction('create.comment', this.note, { silent: true });

    this.bootstrapShares();

    this.fileService.getFiles$(this.note).pipe(
      filter(files => !!files),
      takeWhile(() => this.alive))
      .subscribe(files => {
        this.note._attachments = files;
      });


    this.messageBus.on(Notification.EVENTS.COMMENT).pipe(
      filter(notification => !!notification && notification.data.NoteId === this.note.Id),
      takeWhile(() => this.alive))
      .subscribe((notification: Notification) => {
        notification._inView = this.commentsInView(notification);
        if (notification._inView) {
          console.log('--- publish back on: ' + Notification.EVENTS.COMMENT + '.' + notification.id);
          // make an announcement in order to cancel any further processing on heigher level(notificationService)
          this.messageBus.publish(Notification.EVENTS.COMMENT + '.' + notification.id);
        }
        this.commentService.handleNotification(notification, this.note);
      });

    this.task.notifications.filter(n => n instanceof Notification).forEach((notification) => {
      this.commentService.handleNotification(notification, this.note);
    })

    this.commentsToggle(null, !(this.currentResource.project.Settings && this.currentResource.project.Settings.collapseComments));
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewInit() {
    // if (this.joditEditor) {
    //   this.joditEditor.value = this.embed.transform(this.noteText).toString();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['noteText'].currentValue && !changes['noteText'].firstChange && this.joditEditor) {
    //   if (this.joditEditor) {
    //     this.joditEditor.value = this.embed.transform(this.noteText).toString();
    //   }
    // }
  }

}
