import { Pipe, PipeTransform, Inject, NgModule } from '@angular/core';
import { PermissionService } from '@board/_services/permission/permission.service';
import { IPanelComponent } from '@panel-components/panel-components';


@Pipe({ name: 'panelAccess' })
export class PanelAccessPipe implements PipeTransform {
  constructor(
    private permsissionService: PermissionService,
  ) { }

  transform(panels: Array<IPanelComponent>): Array<IPanelComponent> {
    if (!panels) {
      return [];
    }
    // filter panels array, panels which are allowed (return true) will be kept, false will be filtered out
    return panels.filter(panel => this.permsissionService.showPanel(panel.id));
  }
}

@NgModule({
  declarations: [
    PanelAccessPipe,
  ],
  exports: [
    PanelAccessPipe,
  ]
})
export class PanelAccessPipeModule { }