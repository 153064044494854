<mat-select class="algotag-select" [placeholder]="categoryTags.length?'Choose options':'No more options'">
    <mat-option *ngFor="let tag of categoryTags" (click)="add(tag)">
        <span [innerHTML]="tag.Name"></span>
    </mat-option>
</mat-select>

<mat-chip-listbox class="algotag-chip-list">
    <mat-chip *ngFor="let tag of selectedTags">
        <span [innerHTML]="tag.Name"></span>
        <mat-icon svgIcon="board-mat:close" (click)="remove(tag)"></mat-icon>
    </mat-chip>
</mat-chip-listbox>
