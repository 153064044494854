import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MgcButtonModule, MgcDividerModule, MgcFormFieldModule, MgcLoaderModule, MgcMenuModule } from '@rallysite/mgc-components';
import { JoditModule } from '@libraries/jodit/jodit.module';
import { SafePipesModule } from '@rallysite/global-pipes';
import { UploaderModule } from '@libraries';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';
import { DatePipesModule } from '@board/_pipes';
import { CommentsModule } from '@panel-components/comments/x_comments.module';
import { PostAsModule } from '@board-components/post-as/post-as.module';
import { AttachmentsModule } from '@panel-components/notes/attachments/x_attachments.module';
import { AlertModule } from '@rallysite/components/alert';

import { UIBaseBlockComponent } from './ui-base-block.component';
import { UIImageBlockComponent } from './image-block/image-block.component';
import { UITextBlockComponent } from './text-block/text-block.component';
import { UIBlocksComponent } from './ui-blocks.component';
import { UIAttachmentsBlockComponent } from './attachments-block/attachments-block.component';
import { UIImageComponent } from './image-block/image/ui-image.component';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MgcMenuModule,
        MgcButtonModule,
        MgcFormFieldModule,
        MgcLoaderModule,
        MgcDividerModule,
        JoditModule,
        SafePipesModule,
        UploaderModule,
        AccountAvatarModule,
        DatePipesModule,
        CommentsModule,
        PostAsModule,
        AttachmentsModule,
        AlertModule,
        DragDropModule,
        CdkDropList,
        CdkDrag
    ],
    declarations: [
        UIBlocksComponent,
        UIBaseBlockComponent,
        UIImageBlockComponent,
        UITextBlockComponent,
        UIAttachmentsBlockComponent,
        UIImageComponent,
    ],
    exports: [
        UIBlocksComponent,
        UIImageBlockComponent,
        UIAttachmentsBlockComponent,
        UITextBlockComponent,
        UIImageComponent
    ]
})
export class UIBlocksModule { }
