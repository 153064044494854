import { Injectable, Injector, ElementRef } from '@angular/core';
import { Overlay, ConnectionPositionPair, PositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { PopoverRef, PopoverContent } from './popover-ref';
import { PopoverComponent } from './popover.component';
import { filter, takeUntil, switchMap } from 'rxjs/operators';
import { BehaviorSubject, interval } from 'rxjs';

export type PopoverParams<T> = {
  width?: string | number;
  height?: string | number;
  origin: HTMLElement;
  content: PopoverContent;
  data?: T;
}

@Injectable()
export class PopoverService {
  constructor(private overlay: Overlay, private injector: Injector) { }


  safeOpen<T>({ origin, content, data, width, height }: PopoverParams<T>, onclose: Function) {
    let safe;
    safe = new BehaviorSubject(false).pipe(filter(val => val));

    interval(500).pipe(
      takeUntil(safe))
      .subscribe(result => {
        if (origin && origin['nativeElement'].offsetHeight) {
          safe.next(true);
          const ref = this.open({ origin, content, data, width, height })
          ref.afterClosed$.subscribe(res => {
            onclose(res);
          })
        }
      })
  }



  open<T>({ origin, content, data, width, height }: PopoverParams<T>): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height }));
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return popoverRef;
  }

  private getOverlayConfig({ origin, width, height }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions())
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: -210,
        offsetY: -5,
      },
      // {
      //   originX: 'start',
      //   originY: 'center',
      //   overlayX: 'start',
      //   overlayY: 'center'
      // },
      // {
      //   originX: 'center',
      //   originY: 'top',
      //   overlayX: 'center',
      //   overlayY: 'bottom'
      // },
      // {
      //   originX: 'center',
      //   originY: 'bottom',
      //   overlayX: 'center',
      //   overlayY: 'top',
      // },
    ]
  }

}