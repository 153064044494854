import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Modal} from "@board/modals/modals-editor/modal.model";
import {GlobalModalsService} from "../../global-modals.service";

@Component({
  selector: 'assessment-modal',
  templateUrl: 'assessment-modal.component.html',
  styleUrls: ['assessment-modal.component.scss']
})
export class AssessmentModalComponent {
  surveyIdsResolved: boolean = false;

  brandId: string;
  surveyId: string;
  isBrandSurvey: boolean;

  constructor(
    public dialogRef: MatDialogRef<AssessmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      modal: Modal
    },
    private globalModalsService: GlobalModalsService
  ) {
    this.extractIds();
  }

  extractIds() {
    const url = new URL(this.data.modal.Config.surveyLink);
    const srvyId = url.searchParams.get('srvy_id');

    if (!srvyId) {
      this.surveyIdsResolved = false;
      return;
    }

    const parts = srvyId.split("-");

    this.brandId = parts[1] || null;
    this.surveyId = parts[2] || null;
    this.surveyIdsResolved = true;
    this.isBrandSurvey = this.surveyId !== "1";
  }

  surveyDone() {
    this.globalModalsService.resolveModal(this.data.modal.Id, 'done').subscribe(() => {
      if (this.data.modal.Config.closeModalOnComplete) {
        this.dialogRef.close();
      }
      this.globalModalsService.trackEvent(this.data.modal.Id, 'resolved').subscribe();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
