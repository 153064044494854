import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

import { of as observableOf } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class CounterService {

  private _endPoint: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) public config: AppConfig,
  ) {
    this._endPoint = `${this.config.endpoint}/views`;
  }

  views(id: string) {
    return this.http.get(`${this._endPoint}/${id}`).pipe(
      map((views) => {
        return views;
      }), catchError(err => {
        return observableOf(null);
      }))
  }


  pingFromLibrary(id: string) {
    const endpoint = `${this.config.endpoint}/store/views`;
    const img = document.createElement('img');
    img.src = `${endpoint}/${id}?px=1&cb=${this.cb}`;
}


  ping(projectId: string, taskId: string = null) {
    let img = document.createElement('img');
    img.src = `${this._endPoint}/project?pid=${projectId}`;
    if (taskId) {
      img.src += `&tid=${taskId}`;
    }
    img.src += `&px=1&cb=${this.cb}`;
  }

  get cb() {
    return Math.floor(Math.random() * 9999999999) + '';
  }
}
