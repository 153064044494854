import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { SQOptionsComponent } from '../sq-options.component';

@Component({
  selector: 'sq-options-single',
  templateUrl: './sq-options-single.component.html',
  styleUrls: ['./sq-options-single.component.scss']
})
export class SQOptionsSingleComponent extends SQOptionsComponent {

  //
  @Input() answerIdx: number = null;

  constructor(
    protected formBuilder: FormBuilder,
  ) {
    super();
  }

  /** FORM */
  form: FormGroup;
  bootstrapForm() {
    let answer = this.question.answer();
    if (answer instanceof Array) {
      answer = answer[0];
    }
    this.form = this.formBuilder.group({
      selectedOption: [answer || null, [Validators.required]]
    });

    this.formSubscriptions.push(
      this.form.get('selectedOption').valueChanges.subscribe(
        (option: ISurveyQuestionOption) => {
          this.optionChange.emit(option.Id ? option : null);
        }
      )
    );

    if (this.answerIdx) {
      const selectedOption = this.question.setAnswerByIdx(this.answerIdx).answer();
      this.form.patchValue({
        selectedOption
      });
    }

  }
  get f() { return this.form.controls; }
  /** end of FORM */

}
