import { NgModule, InjectionToken, Type } from '@angular/core';
import { ITokenHelper } from './socket-io.token.helper';

export const SOCKET_CONFIG_TOKEN = new InjectionToken<SocketIoConfig>('__SOCKET_IO_CONFIG__');

/** Config interface */
export interface SocketIoConfig {
    url: string;
    tokenHelperService?: Type<ITokenHelper>
    options?: any;
};

@NgModule({
    providers: [{
        provide: SOCKET_CONFIG_TOKEN,
        useValue: SOCKET_CONFIG_TOKEN
    }],
})
export class SocketConfigModule { }