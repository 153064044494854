import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'alert-snack',
    template: `
    <div class="snack">
        <div class="message" [innerHTML]="data.message|safe:'html'"></div>

        <div class="icon" (click)="action()">
            <svg height="100%" viewBox="0 0 24 24" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
        </div>
    </div>
    `,
    styles: [`
        .snack{
            display: flex;
            flex-direction: row;
        }
        .message{
            flex: 1;
        }
        .icon{
            max-width: 20px;
            min-width: 20px;
            max-height: 20px;
            min-height: 20px;

            margin-left: 10px;
            fill: currentColor;
            cursor: pointer;
        }
    `],
    providers: []
})
export class AlertSnack implements OnInit {

    data: any;

    constructor(
        public snackBarRef: MatSnackBarRef<AlertSnack>,
        @Inject(MAT_SNACK_BAR_DATA) data: any
    ) {
        this.data = data;
    }

    /** Performs the action on the snack bare. */
    action(): void {
        this.snackBarRef.dismissWithAction();
    }

    /** If the action button should be shown. */
    get hasAction(): boolean {
        return !!this.data.action;
    }

    ngOnInit() {

    }
}
