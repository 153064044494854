import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { UIBlockType } from '@board/surveys/survey-factory/survey-feedbacks/ui-blocks/types';
import { IUIBlock } from '@board/surveys/survey-factory/survey-feedbacks/ui-blocks/ui-block.interface';
import { SurveyQuestionType } from '@board/surveys/survey-factory/survey-questions/question-type.helpers';
import { ISurveySocial } from '@board/surveys/survey-factory/survey-settings/survey-settings.interface';
import { ISurvey } from '@board/surveys/survey.model';
import { ResizedEvent } from '@libraries/resize-detect';
import { PageAuthPlaceId, PageAuthViewType } from '@page-auth';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { SeoService, WINDOW } from '@rallysite/global-services';
import { User, UserService } from '@rallysite/user';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, finalize, switchMap, take } from 'rxjs/operators';
import { ISurveyQuestionOption } from '../models/survey-question-option.model';
import { ISurveyQuestion } from '../models/survey-question.model';
import { SurveyOverlayService } from '../_overlay/survey-overlay.service';
import { SQOptionsComponent } from './question-types/sq-options.component';

import { ISurveyScore } from './survey-score.interface';
import { SurveyService } from './survey.service';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { BoardViewService } from '@board/_services';
import {PlevelService} from '../../../_services/plevel.service';
import {AuthInlineTempDirective} from '@rallysite/auth/auth-inline-temp/auth-inline-temp.directive';

interface TTab {
  id: 'feedback' | 'answers' | 'settings';
  name: string;
  bgColor: string;
  color: string;
}

@Component({
  selector: 'brand-survey',
  templateUrl: './brand-survey.component.html',
})
export class BrandSurveyComponent implements OnInit, AfterViewInit {
  get user(): User {
    return this.userService.user;
  }

  constructor(
    protected surveyService: SurveyService,
    protected surveyOverlay: SurveyOverlayService,
    protected authService: AuthService,
    protected userService: UserService,
    protected seoService: SeoService,
    private router: Router,
    protected route: ActivatedRoute,
    @Inject(WINDOW) public window: Window,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private boardViewService: BoardViewService,
    private plevelService: PlevelService
  ) {
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  get leftPosition(): number {
    if (!this.currentTab) { return 0; }

    const idx = Math.max(0, this.tabs.filter(t => this.tabVisible(t)).findIndex(t => t.id === this.currentTab.id));
    let left = 0;
    if (this.tabQL.length) {
      for (let k = 0; k < idx; k++) {
        const tt = this.tabQL.find((t, i) => i === k);
        left += tt.nativeElement.offsetWidth;
      }
    }
    return left + (idx * 2 + 1) * this.tabPadding;
  }
  /** end TABS */

  get allowFinish() {
    const mustAnswer = this.requiredQuestions.filter(q => !q.selectedOption && !q.previousOption);
    return !mustAnswer.length;
  }

  get allowSkip() {
    const required = this.currentQuestion && this.currentQuestion.Required;
    const hasSelected = this.currentQuestion.selectedOption || this.currentQuestion.previousOption;

    return !required || (required && hasSelected);
  }

  get allowNext(): boolean {
    const required = this.currentQuestion && this.currentQuestion.Required;
    const prev = this.currentQuestion.previousOption;
    const hasSelected = this.currentQuestion.selectedOption ||
      (prev instanceof Array ? prev.length : prev);

    return !required || !!(required && hasSelected);
  }

  get hasNext(): boolean {
    return this.viewedQIndexes[this.questionInViewIndex] < this.questions.length - 1;
  }
  get hasPrev(): boolean {
    return this.questionInViewIndex >= 1;
  }

  get hideSurvey() {
    return this.emailChecking || this.surveySaved || this.alreadyTaken || this.takenSurveyId;
  }

  get alreadyTaken() {
    return (this.survey && this.survey.TakenId) || (this.score && this.score.TakenId);
  }
  @HostBinding('class.brand-survey') surveyClass = true;

  @ViewChild(AuthInlineTempDirective) authInlineDirective: AuthInlineTempDirective;
  @ViewChild('brandEl') brandEl: ElementRef<HTMLElement>;

  @ViewChildren('opref') optionsComponents: QueryList<SQOptionsComponent>;
  @ViewChildren('tabEl', { read: ElementRef }) tabQL: QueryList<ElementRef>;

  @Input() surveyId: string;
  @Input() brandId: string;
  @Input() takenSurveyId: string = null;

  @Input() hideBrandAndTitle = false;

  @Output() close: EventEmitter<string> = new EventEmitter<null>();
  @Output() surveyDone: EventEmitter<null> = new EventEmitter<null>();
  @Output() bgColor: EventEmitter<string> = new EventEmitter<null>();
  @Output() cleanView: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  qTypes = SurveyQuestionType;
  smallView: boolean = this.boardViewService.smallView();

  survey: ISurvey;
  surveySaved = false;
  surveyFound = true;

  firstTimeSurvey = true;
  haveDefaults = false;

  loading = false;
  loadingResults = false;
  processing = false;
  ready = false;
  error = false;
  emailChecking = false;
  isBrowser = false;
  feedbackHeight;

  questions: ISurveyQuestion[] = [];
  currentQuestion: ISurveyQuestion;

  requiredQuestions: ISurveyQuestion[] = [];

  score: ISurveyScore = {
    userScore: 0,
    maxScore: 0,
    averageScore: 0,
    percent: 0,
    percentAvg: 0,
  };
  feedbacks: { [key: number]: IUIBlock[] } = {};
  haveFeedbacks = false;
  questionsWithFeedbacks: ISurveyQuestion[] = [];

  gainedPLevel: number | null = null;

  // due to JumpTo feature, there are questions that are not visible to user
  // create a secondary variable that keeps all viewed questions by user
  viewedQIndexes: number[] = [];
  questionInViewIndex: number;

  blockType = UIBlockType;
  qop: number = null;
  /** FORM */
  form: FormGroup;
  /** */

  /** TABS */
  maxTabWidth = 170;
  barWidth = this.maxTabWidth;
  currentTab: TTab;
  tabPadding = 4;
  tabs: TTab[] = [
    { id: 'feedback', name: 'Feedback', bgColor: '#0b5394', color: '#f4f4f4' },
    { id: 'answers', name: 'Answers', bgColor: '#a64d79', color: '#f4f4f4' },
    { id: 'settings', name: 'Download', bgColor: '#e69138', color: '#f4f4f4' }
  ];


  nextSubmited = false;
  pdfProcessing = false;

  w: number;
  h: number;
  @HostListener('window:resize') windowResize() {
    this.setBarWidth();
    this.smallView = this.boardViewService.smallView();
  }
  bootstrapForm(options: { favorite: boolean }) {
    this.form = this.formBuilder.group({
      favorite: options.favorite,
    });

    this.form.get('favorite').valueChanges
      .pipe(
        debounceTime(1000),
        switchMap((isFavorite) => {
          return this.surveyService.saveFavorite(this.surveyId, isFavorite);
        })
      )
      .subscribe();
  }

  toggleFavorite(matSlideToggle: MatSlideToggleChange) {
    this.form.patchValue({
      favorite: matSlideToggle.checked
    });
  }

  getTab(id: TTab['id']): TTab {
    return this.tabs.find(t => t.id === id);
  }
  tabActive(tab: TTab) {
    return this.currentTab && tab.id === this.currentTab.id;
  }
  changeTab(tab: TTab = null) {
    this.currentTab = tab || this.tabs[1];
    setTimeout(() => {
      this.setBarWidth();
    }, 10);
  }
  tabVisible(tab: TTab) {
    switch (tab.id) {
      case 'settings':
        return !!this.user;
      case 'feedback':
        return this.haveFeedbacks;
      default:
    }

    return true;
  }
  setBarWidth() {
    if (!this.currentTab) {
      this.currentTab = this.tabs[1];
    }

    const idx = Math.max(0, this.tabs.filter(t => this.tabVisible(t)).findIndex(t => t.id === this.currentTab.id));
    if (this.tabQL.length) {
      const tt = this.tabQL.find((t, i) => i === idx);
      if (tt) {
        this.barWidth = tt.nativeElement.offsetWidth;
      }
    }
  }

  pLevel(): number {
    const questions = this.questions
      .filter(q => q.SurveyPgroupId);

    if (questions.length === 0) {
      return null;
    }

    const dq1Score = ((questions[0].selectedOption || questions[0].previousOption) as ISurveyQuestionOption).Points,
      brqScore = ((questions[1].selectedOption || questions[1].previousOption) as ISurveyQuestionOption).Points,
      dq2Score = ((questions[2].selectedOption || questions[2].previousOption) as ISurveyQuestionOption).Points;

    return this.plevelService.getPlevel(dq1Score, brqScore, dq2Score);
  }

  isCurrentQuestionLastOfPgroup(): boolean {
    return this.questions
      .filter(q => q.SurveyPgroupId)
      .reduce((a, b) => a && a.Position > b.Position ? a : b)
      .Id === this.currentQuestion.Id;
  }
  next(): null | void {
    this.nextSubmited = true;
    if (this.processing) { return; }

    if (!this.hasNext || !this.allowNext || !this.validateAnswer()) {
      return;
    }

    this.nextSubmited = false;

    let jumpTo = null;

    // Special behavior for P-group questions
    if (this.currentQuestion.SurveyPgroupId) {
       if (this.isCurrentQuestionLastOfPgroup()) {
         const group = this.currentQuestion.SurveyPgroup;
         const pLevel = this.pLevel();
         this.gainedPLevel = pLevel;

         jumpTo = group['P' + pLevel + 'NextQuestionId'];
       }
    } else {
      jumpTo = this.currentQuestion.jumpTo();
    }

    if (jumpTo) {
      const jumpIndex = this.questions.findIndex(q => q.Id === jumpTo);
      this.nextQ(jumpIndex);
    } else {
      const currentIndex = this.viewedQIndexes[this.questionInViewIndex];
      this.nextQ(currentIndex + 1);
    }
  }

  prev() {
    if (!this.hasPrev || this.processing) {
      return;
    }

    this.prevQ();
  }

  private nextQ(index: number = 0) {
    this.questionInViewIndex = index === 0 ? index : this.questionInViewIndex + 1;
    this.viewedQIndexes.push(index);
    const idx = this.viewedQIndexes[this.questionInViewIndex];
    this.currentQuestion = this.questions[idx];
  }
  private prevQ() {
    this.viewedQIndexes.pop();
    this.questionInViewIndex--;
    const idx = this.viewedQIndexes[this.questionInViewIndex];
    this.currentQuestion = this.questions[idx];
  }

  onQuestionOptionChange(option: ISurveyQuestionOption | ISurveyQuestionOption[]) {
    this.currentQuestion.selectedOption = option;

    if (this.qop > 0 && !!this.currentQuestion.selectedOption) {
      this.qop = null;
      this.next();
    }
  }

  private validateAnswer() {
    let validAnswer = true;
    this.optionsComponents.forEach((oC: SQOptionsComponent) => {
      validAnswer = oC.submit() && validAnswer;
    });

    return validAnswer;
  }

  finish() {
    this.nextSubmited = true;
    if (this.processing || !this.allowNext || !this.validateAnswer()) {
      return;
    }

    if (this.currentQuestion.SurveyPgroupId) {
      if (this.isCurrentQuestionLastOfPgroup()) {
        this.gainedPLevel = this.pLevel();
      }
    }

    this.nextSubmited = false;
    this.save();
  }

  protected save() {
    return this.authService.authenticate().subscribe(user => {
      if (user) {
        this.saveAnswers();
        return;
      }

      this.emailChecking = true;
      /**
       * will trigger onAuthClose() methos below
       */
      this.authInlineDirective.onAuth(
        PageAuthViewType.register,
        null,
        'To see your results please enter your email.',
        PageAuthPlaceId.survey,
        true
      );
    });
  }

  protected saveAnswers(email: string = null) {
    this.processing = true;
    this.error = false;
    this.postAnswers(email)
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe((result: ISurveyScore) => {
        if (result) {
          this.processResult(result);
          this.surveySaved = true;
          this.emailChecking = false;
        } else {
          this.error = true;
        }
      });
  }

  private processResult(result: ISurveyScore) {
    this.score = { ...result };
    const maxScore = this.score.maxScore || 0;
    this.score.averageScore = Math.round(this.score.othersScore / this.score.takenSurveys);
    this.score.percent = maxScore > 0 ? (this.score.userScore / maxScore * 100).toFixed(2) : 0;
    this.score.percentAvg = maxScore > 0 ? (this.score.averageScore / maxScore * 100).toFixed(2) : 0;
    this.feedbacks = result.feedbacks || {};
    this.haveFeedbacks = Object.keys(this.feedbacks).length > 0;
    this.changeTab(this.haveFeedbacks ? this.getTab('feedback') : this.getTab('answers'));

    this.bootstrapForm({
      favorite: result.isFavorite
    });

    let alreadyAssignedPlevelFeedback = false;

    this.questionsWithFeedbacks = this.questions.filter(q => {
      let hasFeedback = false;
      q.Options.forEach(qo => {

        hasFeedback = hasFeedback || !!this.feedbacks[qo.Id];
      });
      if (q.SurveyPgroupId && this.feedbacks['pgroup'] && !alreadyAssignedPlevelFeedback) {
        alreadyAssignedPlevelFeedback = true;
        hasFeedback = true;
      }
      return hasFeedback;
    });

    this.surveyDone.emit();

    this.questions.forEach(q => q.setComparativeResults(this.score.options));

    setTimeout(() => {
      const element = document.getElementById('resultsEl');
      this.feedbackHeight = this.convertPixelsToPDFPoints(element.offsetHeight);
      this.setBarWidth();
    }, 10);
  }

  /**
   * @param event  instance of User || {event: 'cancel', email: string}
   */
  onAuthClose(event) {
    this.authInlineDirective.clear();

    if (event instanceof User) {
      this.saveAnswers();
      this.pLevel();
    } else if (event && event.email) {
      this.saveAnswers(event.email);
    } else {
      this.processing = false;
      this.emailChecking = false;
    }
  }
  onResized(event: ResizedEvent) {

    if (this.isBrowser) {
      const extraH = Number(this.brandEl && this.brandEl.nativeElement && this.brandEl.nativeElement.offsetHeight);
      this.w = event.newWidth;
      this.h = event.newHeight + (extraH || 0);
      // console.log(['...............posting message', {
      //   sry_h: this.h,
      //   sry_w: this.w,
      //   h: event.newHeight,
      //   extraH: extraH
      // }])
      this.window.parent.postMessage({
        sry_h: this.h,
        sry_w: this.w
      }, '*');
    }
  }

  private updateSeo(survey: ISurvey) {
    const sm: ISurveySocial = survey.social;
    this.seoService.updateUrl(this.surveyService.surveyPageUrl(this.brandId, this.surveyId));

    const title = `${sm.title || survey.PublicName || survey.Name || 'Survey Not Found'}`;
    this.seoService.updateTitle(title);

    if (sm.description) {
      this.seoService.updateDescription(sm.description);
    }
    this.seoService.updateKeywords(sm.keywords || title);
    if (sm.image && sm.image.SystemName && sm.image.Path) {
      const parts = sm.image.SystemName.split('.');
      const imageUrl = `${this.appConfig.cdnImages}/${sm.image.Path}/${parts[0] + '_medium'}`;
      this.seoService.updateImages([imageUrl], sm.image.AltText || survey.PublicName || survey.Name || '');
    }
  }

  private async loadQuestions() {
    this.loading = true;
    forkJoin(
      this.surveyService.getSurvey(this.surveyId, this.brandId, this.takenSurveyId),
      this.getQuestions(),
      this.getAnswers(this.takenSurveyId)
    ).pipe(take(1),
      finalize(() => this.loading = false))
      .subscribe(values => {
        this.survey = values[0][0]; // ???
        this.bgColor.emit(this.survey.bgColor);
        this.updateSeo(this.survey);

        const questions = values[1];
        const answers = values[2];
        this.firstTimeSurvey = !answers || !answers.length;
        this.haveDefaults = this.firstTimeSurvey;

        if (answers) {
          questions.forEach(q => q.setPreviousOption(answers));
        }

        this.questions = questions;
        this.requiredQuestions = this.questions.filter(q => q.Required);
        this.nextQ();

        // double-check the takenSurveyId to match the param with the one from DB
        if (this.survey.TakenId && Number(this.takenSurveyId) === Number(this.survey.TakenId)) {
          this.loadingResults = true;
          this.surveyService
            .getResult({
              TakenId: this.survey.TakenId
            }, {
              surveyId: this.surveyId
            })
            .pipe(
              finalize(() => this.loadingResults = false)
            )
            .subscribe((result: ISurveyScore) => {
              if (result) {
                this.viewedQIndexes = this.questions.map((q, i) => i);
                this.processResult(result);
              }
            });
        } else {
          this.takenSurveyId = null;
          this.router.navigate([], { queryParams: { tsrvy: null }, queryParamsHandling: 'merge' });
        }

        this.ready = !!questions.length;
        this.surveyFound = !!questions.length;
        if (this.ready) {
          this.cleanView.emit(!this.survey.showPageHeaderFooter);
        } else {
          this.cleanView.emit(false);
        }
      });
  }

  protected getAnsweredOptions() {
    const questions = this.questions.filter(q => !!q);
    const answers: string[] = [];

    questions.forEach(q => {
      const answer = q.answer();
      if (!answer) {
        answers.push('');
      } else if (answer instanceof Array) {
        answer.forEach(a => {
          if (q.userAnswerRequired()) {
            answers.push(a.UserAnswer ? `${a.Id}|<${a.UserAnswer}` : '');
          } else {
            answers.push('' + a.Id);
          }
        });
      } else {
        if (q.userAnswerRequired()) {
          answers.push(answer.UserAnswer ? `${answer.Id}|<${answer.UserAnswer}` : '');
        } else {
          answers.push('' + answer.Id);
        }
      }
    });

    return answers;
  }

  ngOnInit() {
    this.isBrowser = this.surveyService.isBrowser;
    const params = this.route.snapshot.queryParams;
    if (params.qop) {
      this.qop = +params.qop;
    }

    if (this.takenSurveyId) {
      this.authService.authenticate().pipe(take(1), )
        .subscribe(() => {
          this.loadQuestions();
        });
    } else {
      this.loadQuestions();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setBarWidth();
    }, 20);

  }

  protected getQuestions() {
    return this.surveyService.getQuestions({
      surveyId: this.surveyId
    });
  }

  protected getAnswers(takenSurveyId: string) {
    return this.surveyService.getAnswers({
      surveyId: this.surveyId,
      takenSurveyId: this.takenSurveyId,
      userId: this.userService.user ? this.userService.user.Id : null
    });
  }

  protected postAnswers(email: string = null): Observable<ISurveyScore> {
    const payload = {
      SurveyId: this.surveyId,
      BrandId: this.brandId,
      options: this.getAnsweredOptions()
    };
    if (email) {
      payload['Email'] = email;
    }

    return this.surveyService.postAnswers(payload, {
      surveyId: this.surveyId
    });
  }

  getPdf() {
    if (this.pdfProcessing || !this.isBrowser) {
      return;
    }

    this.pdfProcessing = true;

    const feedback = [];

    this.questionsWithFeedbacks.forEach(questionWithFeedback => {
      if (questionWithFeedback.SurveyPgroupId && !!this.feedbacks['pgroup']) {
        feedback.push(this.feedbacks['pgroup']);
      } else {
        questionWithFeedback.Options.forEach(questionOption => {
          if (this.feedbacks[questionOption.Id]) {
            feedback.push(this.feedbacks[questionOption.Id]);
          }
        });
      }
    });


    const payload = {
      'feedback' : feedback,
      'height': this.feedbackHeight
    };

    return this.surveyService.getPdf(payload).pipe(
      finalize(() => this.pdfProcessing = false)
    ).subscribe();

  }

  convertPixelsToPDFPoints(heightInPixels) {
    return heightInPixels * (72 / 96);
  }

}
