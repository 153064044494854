  export class EventFormatter {
    private _defaultNamespace = 'App.Events'

    format (event) {
      if (event.charAt(0) !== '\\' && event.charAt(0) !== '.') {
        event = this._defaultNamespace + '.' + event
      } else {
        event = event.substr(1)
      }

      return event.replace(/\./g, '\\')
    }

    namespace (value) {
      this._defaultNamespace = value
    }

  }