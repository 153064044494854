import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: './loader.html',
    styleUrls : ['./loader.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
    @HostBinding ('class.loader') laoderClass = true;

    @Input('d') diameter = 30;
    // @Input('b') strokeWidth: number = 3;

    innerSize: number;
    constructor() {
    }

    ngOnInit() {
        this.innerSize = this.diameter - this.diameter * .2;
    }
}
