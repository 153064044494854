import { Account } from '@board-accounts/account.model';

export interface IUmbrella {
    isFollow: boolean;
    isCopy: boolean;
    Id?: string;
    AccountId: string;
    Type: string;
    Name: string;
    Status: string;
    UpdateDate?: string;
    _notifications: number;
    _flaggedNotes: number;
    _uid?: string;

    _type: string;
    _open: boolean;
    _show: boolean;

    isDefault: boolean;

    increaseNotificationCounter?();
}


export class UmbrellaPanels {
    Type: string;

    readonly _type: string = 'umbrella';

    _open = false;
    _show = true;

    get isDefault(): boolean {
        return this.Type === 'myprojects';
    }

    get isArchive(): boolean {
        return this.Type === 'archive';
    }

    get isStartUp(): boolean {
        return this.Type === 'startup';
    }

    get isCopy(): boolean {
        return this.Type === 'copied';
    }

    get isFollow(): boolean {
        return this.Type === 'joined';
    }

    get isDraggable(): boolean {
        return !(this.isStartUp || this.isArchive);
    }
}

export class Umbrella extends UmbrellaPanels implements IUmbrella {
    Id: string;
    AccountId: string;
    Name: string;
    Type: string;
    Status: string;
    UpdateDate: string;
    _notifications: number;
    _flaggedNotes: number;
    _uid = '';

    _state: string;

    constructor(data: any) {
        super();

        for (const prop in data) {
            this[prop] = data[prop];
        }
        this._uid = this.AccountId + '_' + this.Id;
        this._show = !this.isStartUp && !this.isArchive;

        if (this.isCopy) {
            this.Name = 'Copied';
        } else if (this.isFollow) {
            this.Name = 'Follow';
        }
    }

    increaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications + val;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }

    settingsOptions(account: Account, edit: boolean = false) {
        edit = !!edit;
        return {
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Collection',
                umbrella: this,
                account: account,
                edit: edit
            }
        };
    }

    toDb() {
        return {
            Id: this.Id,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            UpdateDate: this.UpdateDate
        };
    }
}

export class MyProjectsUmbrella extends UmbrellaPanels implements IUmbrella {
    Id: string;
    AccountId: string;
    Type: string;
    Name: string;
    Status: string;
    _notifications: number;
    _flaggedNotes: number;
    _uid = '';

    constructor(accountId: string, data: any) {
        super();

        for (const prop in data) {
            this[prop] = data[prop];
        }

        this.Id = 'myprojects';
        this.Type = 'myprojects';
        this.AccountId = accountId;
        this.Name = 'Keep on top';
        this._uid = this.AccountId + '_' + this.Id;
    }

    increaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications + val;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }

}

export class ArchiveUmbrella extends UmbrellaPanels implements IUmbrella {
    Id: string;
    AccountId: string;
    Type: string;
    Name: string;
    Status: string;
    _notifications: number;
    _flaggedNotes: number;
    _uid = '';

    _show = false;

    constructor(private accountId: string) {
        super();

        this.Id = 'archive';
        this.Type = 'archive';
        this.AccountId = accountId;
        this.Name = 'Archive';
        this._uid = this.AccountId + '_' + this.Id;
    }
}
