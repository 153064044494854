<div class="cp-modal-header">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <button tabindex="-1" mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="board-mat:close"></mat-icon>
  </button>
</div>

<form name="user-form" #f="ngForm" novalidate>
  <div mat-dialog-content class="cp-dialog-content">
    <central-details #centralDetais [data]="data"></central-details>
  </div>

  <p *ngIf="state===states.EMPTY"> ({{state}}) EMPTY ...</p>

  <alert [service]="[serviceAlertClass.USER]"></alert>

  <div mat-dialog-actions>
    <button mat-raised-button type="submit" (click)="f.form.valid && centralDetais.onSave()" tabindex="2" color="primary">
      <mgc-loader *ngIf="state===states.PROCESSING"></mgc-loader>
      <span>Save</span>
    </button>
    <button mat-button (click)="onCancel()" tabindex="-1">Cancel</button>
  </div>

</form>