import {Inject, Injectable} from '@angular/core';
import {BaseService} from '@board/_services';
import {of as observableOf} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '@rallysite/config';
import {catchError, map} from 'rxjs/operators';
import {AlertService} from '@rallysite/components/alert';
import {Modal} from "@board/modals/modals-editor/modal.model";

@Injectable({
  providedIn: 'root'
})
export class ModalFactoryService extends BaseService {

  private _endPoint: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/modals`;
  }

  saveModal(modal: Modal) {
    if (!Number.isInteger(modal.Id as number)) {
      return this.createModal(modal);
    }

    return this.updateModal(modal);
  }

  createModal(modal: Modal) {
    return this.http.post(`${this._endPoint}`, {...modal, id: null}).pipe(
      map((data: { Id: string, UpdateDate: string }) => {
        modal.Id = data.Id;
        modal.UpdateDate = data.UpdateDate;
        return true;
      }), catchError(error => {
        console.log('Could not update modal');
        return observableOf(null);
      })
    )
  }

  updateModal(modal: Modal) {
    return this.http.put(`${this._endPoint}/${modal.Id}`, {...modal}).pipe(
      map((data: { UpdateDate: string }) => {
        modal.UpdateDate = data.UpdateDate;
        return true;
      }), catchError(error => {
        console.log('Could not update modal');
        return observableOf(null);
      })
    )
  }
}
