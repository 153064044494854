<div [formGroup]="formGroup" class="phone-form">

    <mat-select class="flex45" *ngIf="countries?.length > 1"
      formControlName="country"
      (selectionChange)="onSelectionChange($event.value)"
      tabindex="-1"
      #singleSelect>

      <mat-select-trigger>
        <div class="flag {{country.value.cca2}}" [class.disabled]="disabled"></div>
      </mat-select-trigger>

      <mat-option *ngIf="countries?.length > 5">
        <ngx-mat-select-search formControlName="countryFilter"
                              placeholderLabel="Search..."
                              noEntriesFoundLabel="No Results">
        </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let country of filteredCountries| async" [value]="country">
        <div class="flag {{country.cca2}}"></div>
        {{country.name.common}}
      </mat-option>

    </mat-select>

    <input [id]="id"
           matInput
           type="tel"
           formControlName="phoneNumber"
           [placeholder]="placeholder"
           [errorStateMatcher]="phoneNumberErrorStateMatcher"
           (keydown)="onKeyDown($event)"
           (keyup)="onKeyUp($event)"
           (click)="onClick($event)"
           (paste)="onPaste($event)"
           (blur)="onBlur($event)"
           fxFlex="grow">


</div>