import { finalize, take } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { AuthService } from '@rallysite/auth-service';
import { AlertService } from '@rallysite/components/alert';
import { IPageAuthView } from '../page-auth-view.interface';
import { PageAuthViewType } from '@page-auth';

const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value.startsWith(' ')) {
    return {
      'trimError': { value: 'has leading whitespace' }
    };
  }
  if (control.value.endsWith(' ')) {
    return {
      'trimError': { value: 'has trailing whitespace' }
    };
  }

  return null;
};


@Component({
  selector: 'page-email-check',
  templateUrl: './page-email-check.component.html'
})
export class PageEmailCheckComponent implements IPageAuthView, OnInit {

  constructor(
    private auth: AuthService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.authForm.controls; }
  @Input() data: {
    label?: string,
    skipsignin?: boolean
  } = {};

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  processing = false;

  payload: any;

  /** FORM */
  authForm: FormGroup;

  submitted = false;

  getEmail() {
    return this.authForm.get('email').value || null;
  }
  bootstrapForm() {
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    this.authForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailPattern), trimValidator]],
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.authForm.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  onSubmit() {
    if (!this.submit() || this.processing) {
      return;
    }
    const email = this.authForm.get('email').value;

    this.processing = true;
    this.auth.checkEmail(email).pipe(take(1),
      finalize(() => {
        this.processing = false;
      }))
      .subscribe(exists => {
        if (exists === true && !!this.data.skipsignin) {
          this.close.emit({ email });
          return;
        }

        exists === true
          ? this.loadAuthView(PageAuthViewType.login)
          : this.loadAuthView(PageAuthViewType.register);
      });
  }

  closeIt() {
    this.close.emit('close');
  }

  private loadAuthView(authViewType: PageAuthViewType) {
    this.close.emit({
      authView: authViewType,
      email: this.authForm.get('email').value
    });
  }

  ngOnInit() {
    this.alertService.clear();
    this.bootstrapForm();
  }

}
