
import { takeWhile, catchError, map, filter, mergeMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User, UserService } from '@rallysite/user';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { BaseService } from '@board/_services/base-service.service';
import { CurrentIdService } from '@board/_services/current-id.service';
import { TargetResourceService, ITargets } from '@board/_services/target-resource.service';
import { CurrentResourceService } from '@board/_services';

import { AccountSettings } from './account.settings.class';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { Account } from './account.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  get currentAccount$(): Observable<Account> {
    return this._currentAccount.asObservable();
  }
  get currentAccount() {
    return this._currentAccount.getValue();
  }

  get accounts$(): Observable<Account[]> {
    const key = this.key;
    return this.dataStore[key]._accounts.asObservable();
  }
  get accounts(): any {
    const key = this.key;
    return this.dataStore[key].accounts;
  }

  get endPoint(): string {
    return this._endPoint;
  }

  get avatarsEndPoint(): string {
    return `${this.config.endpoint}/api/avatars`;
  }
  get waitingAccounts$(): Observable<Account[]> {
    return this._waitingAccounts.asObservable();
  }
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private targetService: TargetResourceService,
    private currentIdService: CurrentIdService,
    private currentResource: CurrentResourceService,
    private userService: UserService,
    private alertService: AlertService,
    private messageBus: MessageBusService,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super();
    if (AccountService.instance !== undefined && isPlatformBrowser(platformId)) {
      throw new Error('AccountService must be instantiated only once.');
    }
    AccountService.instance = this;

    this._endPoint = `${this.config.endpoint}/api/accounts`;

    this.userService.user$.pipe().subscribe(user => {
      if (!user) {
        this.setCurrentAccount(null);
      }
    });

    this.messageBus.on(MessageBusActions.ACCOUNT_METADATA)
      .subscribe(metadata => this.currentAccount.Metadata = metadata);
  }

  get user(): User {
    return this.userService.user;
  }

  get key() {
    return this.userService.user ? this.userService.user.Id : 'no_user';
  }

  static instance?: AccountService;

  private dataStore: any = {};
  private _endPoint: string;

  private _currentAccount: BehaviorSubject<Account> = new BehaviorSubject(null);

  private gettingAccounts: any = {};
  private _waitingAccounts: BehaviorSubject<Account[]>;

  getAccounts(): Observable<Account[]> {
    if (!this.user) {
      return observableOf([]);
    }

    if (this.inStorage()) {

      if (this.targetService.targetLevel > 0) {
        this.setCurrentAccountFromTarget(this.user);
      } else {
        this.setDefaultAccount();
      }

      return observableOf(this.getFromStorage());
    }

    return this._load().pipe(
      filter(accounts => !!accounts),
      map(accounts => {
        return accounts;
      }),
      catchError(error => {
        console.log('Could not load accounts');
        this.alertService.error(error, ServiceAlertClass.ALERTS.ACCOUNT_LIST);
        return observableOf([]);
      })
    );
  }

  private _load() {
    return this.userService.user$.pipe(
      filter(user => {
        return !!user;
      }),
      mergeMap(user => {
        const key = this.key;
        if (this.gettingAccounts[key]) {
          return this.waitingAccounts$;
        }

        this.gettingAccounts[key] = true;
        this._waitingAccounts = <BehaviorSubject<Account[]>>new BehaviorSubject(null);

        return this.http.get(this._endPoint).pipe(
          map(data => {
            this.initStorage();
            for (const k in data) {
              this.pushToStorage(new Account(data[k], user));
            }

            this.gettingAccounts[key] = false;
            this._waitingAccounts.next(this.getFromStorage());

            if (this.targetService.targetLevel > 0) {
              this.setCurrentAccountFromTarget(user);
            } else {
              this.setDefaultAccount();
            }

            return this.getFromStorage();
          })
        );
      }));
  }

  create(account: Account): Observable<Account> {
    return this.http.post(this._endPoint, account.toDb()).pipe(
      map(data => {
        const newAccount = new Account(data, this.user);
        this.pushToStorage(newAccount).emit();
        return newAccount;
      }),
      catchError(error => {
        console.log('Could not create account.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.ACCOUNT_CREATE);
        return observableOf(null);
      })
    );
  }

  update(account: Account): Observable<Account> {
    // this._processingState.next(AccountService.STATES.PROCESSING);
    return this.http.put(`${this._endPoint}/${account.Id}`, account.toDb()).pipe(
      map(data => {
        return account.__update(data);
      }), catchError(error => {
        console.log('Could not update account.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.ACCOUNT_UPDATE);
        return observableOf(null);
      })
    );
  }

  updateSettings(settings: AccountSettings, account?: Account) {
    account || (account = this.currentAccount);
    return this.http.put(`${this._endPoint}/${account.Id}/settings`, { Settings: settings.toDb() }).pipe(
      map(data => {
        account.__update({
          Settings: data['Settings'],
          UpdateDate: data['UpdateDate']
        });
        return account.Settings;
      }), catchError(error => {
        console.log('Could not update account settings');
        this.alertService.snackError(error);
        return observableOf(false);
      })
    );
  }

  orderAccounts(payload: { id: string, index }) {
    this.http.put(`${this._endPoint}/order`, payload)
      .subscribe(
        data => {
          this.userService.user.Settings.accounts = data['accounts'];
          this.userService.user.UpdateDate = data['UpdateDate'];

          // this._processingState.next(AccountService.STATES.DONE);
        },
        error => {
          console.log('Could not order accounts.');
          // if (error.status === 401) {
          //   this._processingState.next(AccountService.STATES.UNAUTHORIZED);
          // } else {
          //   this._processingState.next(AccountService.STATES.ERROR);
          // }
          this.alertService.snackError(error);
        }
      );
  }

  orderUmbrellas(account: Account, payload: { id: string, index: number }) {
    this.http.put(`${this._endPoint}/${account.Id}/metadata/umbrellas`, payload)
      .subscribe(
        data => {
          account.Metadata = data['Metadata'];

          // this._processingState.next(AccountService.STATES.DONE);
        },
        error => {
          console.log('Could not order umbrella.');
          // if (error.status === 401) {
          //   this._processingState.next(AccountService.STATES.UNAUTHORIZED);
          // } else {
          //   this._processingState.next(AccountService.STATES.ERROR);
          // }
          this.alertService.snackError(error);
        }
      );
  }

  orderProjects(account: Account, payload: { umbrellaId: string, id: string, index: number }) {
    this.http.put(`${this._endPoint}/${account.Id}/metadata/projects`, payload)
      .subscribe(
        data => {
          account.Metadata = data['Metadata'];

          // this._processingState.next(AccountService.STATES.DONE);
        },
        error => {
          console.log('Could not order umbrella.');
          // if (error.status === 401) {
          //   this._processingState.next(AccountService.STATES.UNAUTHORIZED);
          // } else {
          //   this._processingState.next(AccountService.STATES.ERROR);
          // }
          this.alertService.snackError(error);
        }
      );
  }

  resendEmail(account: Account) {
    // this._processingState.next(AccountService.STATES.PROCESSING);
    this.http.put(`${this._endPoint}/${account.Id}/resend`, {})
      .subscribe(
        data => {
          // this._processingState.next(AccountService.STATES.DONE);
        },
        error => {
          console.log('Could not resend the email.');
          // this._processingState.next(AccountService.STATES.ERROR);
          this.alertService.error(error, ServiceAlertClass.ALERTS.ACCOUNT_UPDATE);
        });
  }

  confirm(confirmationCode: string, accountId: string) {
    // this._processingState.next(AccountService.STATES.PROCESSING);
    return this.http.put(`${this._endPoint}/${confirmationCode}/${accountId}`, {});
  }

  initStorage() {
    const key = this.key;
    this.dataStore[key] = Object.assign(
      this.dataStore[key] || {
        _accounts: <BehaviorSubject<Account[]>>new BehaviorSubject(null),
      }, {
      accounts: [],
      currentAccount: null
    });
    return this;
  }

  private inStorage() {
    const key = this.key;
    if (!this.dataStore[key] || !this.dataStore[key].accounts) {
      return false;
    }
    return true;
  }

  private getFromStorage() {
    const key = this.key;
    return this.dataStore[key] ? this.sort(this.dataStore[key].accounts) : null;
  }

  pushToStorage(account: Account) {
    const key = this.key;
    this.dataStore[key].accounts.push(account);
    return this;
  }

  private emit() {
    const key = this.key;
    this.dataStore[key]._accounts.next(this.getFromStorage());
  }

  private findById(aId, accounts) {
    for (const a of accounts) {
      if (a.Id === aId) {
        return a;
      }
    }
    return null;
  }

  private sort(accounts: Account[]): Account[] {
    return this.user.sortAccounts(accounts);
  }


  private setCurrentAccountFromTarget(user) {
    let targetsSet = false;
    this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
      .subscribe((targets: ITargets) => {
        if (targets.accountId) {
          targetsSet = true;
          this.setCurrentAccount(this.findAccount(targets.accountId));
        }
      });
  }

  setCurrentAccount(account: Account) {
    this.currentIdService.currentAccountId = account ? account.Id : null;
    this.currentResource.currentItem = null;
    this._currentAccount.next(account);
  }


  setDefaultAccount(email: string = null) {
    if (email) {
      const account: Account = this.accounts.find((a: Account) => a.Email.toLowerCase() === email.toLowerCase());
      if (account) {
        account.crossUserEmail = email;
        this.setCurrentAccount(account);
        return;
      }
    }

    if (!this.currentAccount) {
      this.setCurrentAccount(this.getPrimaryAccount());
    }
  }

  private getPrimaryAccount() {
    const key = this.key;
    return this.sort(this.dataStore[key].accounts).find(account => {
      return account.Confirmed;
    });
  }

  private findAccount(accountId: string) {
    const key = this.key;
    if (!this.dataStore[key] || !this.dataStore[key].accounts.length) {
      return null;
    }
    let targetAccount: Account;
    for (const account of this.dataStore[key].accounts) {
      if (account.Id === accountId) {
        targetAccount = account;
        break;
      }
    }

    return targetAccount || this.dataStore[key].accounts[0];
  }
}
