import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { UserService } from './user.service';
import { DoNotShowAgainModule } from './do-not-show-again';
import { CentralDetailsComponent } from './central-details.component';
import { UserModal } from './user-modal';
import { AlertModule } from '@rallysite/components/alert';
import { MgcLoaderModule } from '@rallysite/mgc-components';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatIconModule,
        MatMenuModule,
        DoNotShowAgainModule,
        AlertModule,
        MgcLoaderModule,
        MatProgressBarModule,
    ],
    declarations: [
        CentralDetailsComponent,
        UserModal
    ],
    exports: [
        DoNotShowAgainModule
    ]
})
export class UserModule {
    constructor(@Optional() @SkipSelf() parentModule: UserModule) {
        if (parentModule) {
            throw new Error('UserModule is already loaded. It should only be imported in your application\'s main module.');
        }
    }

    static forRoot(): ModuleWithProviders<UserModule> {
        return {
            ngModule: UserModule,
            providers: [
                UserService,
            ]
        };
    }
}
