import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { File, IFile } from './file-model';
import { saveAs } from 'file-saver';

import { BaseService } from '@board/_services/base-service.service';
import { MobileJsBridgeService } from '@rallysite/global-services';
import { AlertService } from '@rallysite/components/alert';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

import { Comment } from '@panel-components/comments/comment.model';
import { Note } from '../note';
import { TaskV2AttachmentsBlock } from '@items/task-v2/blocks/attachments-block/attachments-block';


@Injectable({
    providedIn: 'root',
})
export class FileService extends BaseService {
    private dataStore: {};
    private _notes: {};
    private _comments: {};


    private _processingState: BehaviorSubject<number>;
    private _endPoint: string;

    constructor(
        private http: HttpClient,
        private mobileJsBridge: MobileJsBridgeService,
        private alertService: AlertService,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        super();
        this.dataStore = {};
        this._notes = {};
        this._comments = {};
        this._endPoint = `${this.config.endpoint}/api/files`;
        this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    }

    get processingState$(): Observable<number> {
        return this._processingState.asObservable();
    }
    resetProcessingState$(): Observable<number> {
        this._processingState.next(-1);
        return this.processingState$;
    }

    private getGId(item: Note | Comment | TaskV2AttachmentsBlock) {
        if (item instanceof Comment) {
            return item.NoteId + '_' + item.Id;
        }
        if (item instanceof Note) {
            return item.TaskId + '_' + item.Id;
        }

        // only task because its a task v2 which have the blocks structure
        return item.TaskId + (item.BlockId ? '_' + item.BlockId : '');
    }

    private fileStore(item: Note | Comment | TaskV2AttachmentsBlock) {
        const gid = this.getGId(item);
        if (item instanceof Comment) {
            if (!this._comments[gid]) {
                this._comments[gid] = {
                    files: <BehaviorSubject<File[]>>new BehaviorSubject(null),
                    loadingState: <BehaviorSubject<number>>new BehaviorSubject(-1),
                };
            }
            return this._comments[gid];
        }

        if (!this._notes[gid]) {
            this._notes[gid] = {
                files: <BehaviorSubject<File[]>>new BehaviorSubject(null),
                loadingState: <BehaviorSubject<number>>new BehaviorSubject(-1),
            };
        }
        return this._notes[gid];
    }

    getFiles$(item: Note | Comment | TaskV2AttachmentsBlock): Observable<File[]> {
        return this.fileStore(item).files.asObservable();
    }

    pushFiles(files: IFile[], item: Note | Comment | TaskV2AttachmentsBlock) {
        this.fileStore(item).files.next(files);
    }

    get endPoint(): string {
        return this._endPoint;
    }

    update(file: File): Observable<boolean> {
        file.inProgress = true;
        return this.http.put(`${this._endPoint}/${file.Id}`, file.toDb()).pipe(
            map(result => {
                file.inProgress = false;
                file.UpdateDate = result['UpdateDate'];
                return true;
            }),
            catchError((error) => {
                file.inProgress = false;
                console.log('Could not update file.');
                this.alertService.snackWarning(`Could not update file ${file.FileName}`);
                return observableOf(false);
            })
        );
    }


    remove(file: File): Observable<boolean> {
        file.isRemoving = true;
        return this.http.delete(`${this._endPoint}/${file.Id}`).pipe(
            map(data => {
                file.isRemoving = false;
                return true;
            }),
            catchError((error) => {
                file.isRemoving = false;
                console.log('Could not remove file.');
                this.alertService.snackWarning(`Could not remove file ${file.FileName}`);
                return observableOf(false);
            })
        );
    }

    download(file: IFile): Observable<File> {
        if (this.mobileJsBridge.existBridge) {
            this.mobileJsBridge.download(`${this.endPoint}/${file.Id}/download?filename=${file.FileName}`);
            return observableOf(null).pipe(delay(2000));
        }

        try {
            const isFileSaverSupported = !!new Blob;
            if (!isFileSaverSupported) {
                this.mobileJsBridge.warning('FileSaver not supported');
                return observableOf(null);
            }
        } catch (e) {
            this.mobileJsBridge.warning('Ex: FileSaver not supported');
            return observableOf(null);
        }

        return this.http.get(`${this._endPoint}/${file.Id}/download`, {
            responseType: 'blob'
        }).pipe(
            map(data => {
                const blob = new Blob([data], { type: file.MimeType });
                const url = window.URL.createObjectURL(data);
                saveAs(blob, file.FileName);
                return file;
            }),
            catchError((error) => {
                console.log('Could not download file.');
                this.alertService.snackWarning('Could not download file. Authentication required!', { horizontalPosition: 'center' });
                return observableOf(error);
            })
        );
    }

    sort(attachments: IFile[], newOrder: Array<string> = null) {
        if (!newOrder || !attachments.length) {
            return attachments;
        }
        const images: IFile[] = [];
        const files: IFile[] = [];
        const orderedAttachments = [];
        let img: IFile;

        for (const i in attachments) {
            const attachment = attachments[i];
            if (attachment.isImage) {
                images.push(attachment);
            } else {
                files.push(attachment);
            }
        }


        for (const i in newOrder) {
            img = images.find(a => a.Id === newOrder[i]);
            if (img) {
                orderedAttachments.push(img);
            }
        }

        const notOrdered = [];
        for (const i in images) {
            const img = images[i];
            if (!newOrder.find(ord => img.Id === ord)) {
                notOrdered.push(img);
            }
        }


        return orderedAttachments.concat(notOrdered).concat(files);
    }

}
