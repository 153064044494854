import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader';

@NgModule({
    imports: [
    ],
    
    declarations: [
        LoaderComponent
    ],

    exports: [
        LoaderComponent
    ]
})
export class LoaderModule { }