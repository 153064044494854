export enum ReactionTypes {
    LIKE = 'like',
    PRAY = 'pray',
    CELEBRATE = 'celebrate',
    QUESTIONABLE = 'questionable',
    LOVE = 'love',
    COFFEE = 'coffee'

    // INSIGHTFUL = 'insightful',
    // CURIOS = 'curios',
}

export enum ReactionIcons {
    LIKE = 'board-mat:like',
    PRAY = 'board-mat:pray',
    CELEBRATE = 'board-mat:celebrate',
    QUESTIONABLE = 'board-mat:questionable',
    LOVE = 'board-mat:love',
    COFFEE = 'board-mat:coffee'

    // INSIGHTFUL = 'board-mat:insightful',
    // CURIOS = 'board-mat:curios',
}
