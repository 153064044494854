
import { trigger, animate, style, group, query, transition } from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
    transition('* <=> *', [
        query(':enter, :leave', style({ opacity: 1}), { optional: true }),
        group([
            query(':enter', [
                style({ opacity: 0 }),
                animate('0.5s ease-in-out', style({ opacity: 1 }))
            ], { optional: true }),
            query(':leave', [
                style({ opacity: 1 }),
                animate('0.5s ease-in-out', style({ opacity: 0 }))
            ], { optional: true })
        ])
    ])
]);