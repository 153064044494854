import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG, AppConfig} from "@rallysite/config";
import {of} from "rxjs";
import {catchError} from "rxjs/operators";
import {AuthService} from "@rallysite/auth-service";

@Injectable({
  providedIn: 'root',
})
export class PlevelService {

  private _endPoint: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    private authService: AuthService
  ) {
    this._endPoint = `${config.endpoint}/api`;
  }

  getPlevel(dq1Score: number, brqScore: number, dq2Score: number, save: boolean = true) {
    const plevel = this.compute(dq1Score, brqScore, dq2Score);

    if (save && this.authService.isAuthenticated) {
      this.saveLevel({
        plevel,
        dq1Score,
        brqScore,
        dq2Score,
      }).subscribe();
    }

    console.info('User assigned with ' + plevel + ' plevel');

    return plevel;
  }

  private compute(dq1Score, brqScore, dq2Score) {
    if (dq1Score != 8) {
      return 1;
    }

    if ((dq1Score + brqScore) < 40) {
      return 2;
    }

    if ((brqScore + dq2Score) < 400) {
      return 3;
    }

    return 4;
  }

  private saveLevel(payload) {
    return this.http.post(`${this._endPoint}/user/plevel`, payload).pipe(
      catchError((e) => {
        console.log('Could not save plevel', e);
        return of();
      })
    )
  }
}
