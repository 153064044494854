import {SurveyQuestionType} from "@board/surveys/survey-factory/survey-questions/question-type.helpers";
import {ISurveyQuestionOption, SurveyQuestionOption} from "./survey-question-option.model";

export interface ISurveyPgroup {
  Id: string;
  P1NextQuestionId: string | null;
  P2NextQuestionId: string | null;
  P3NextQuestionId: string | null;
  P4NextQuestionId: string | null;
}

export interface ISurveyQuestion {
  Id: string;
  Text: string;
  Position: number;
  Required: boolean;
  Type: SurveyQuestionType;
  SurveyPgroupId: number | null;
  SurveyPgroup: ISurveyPgroup | null;

  Options: ISurveyQuestionOption[];

  selectedOption: ISurveyQuestionOption | ISurveyQuestionOption[];
  previousOption: ISurveyQuestionOption | ISurveyQuestionOption[];
  defaultOption: ISurveyQuestionOption | ISurveyQuestionOption[];

  setPreviousOption(answers): ISurveyQuestionOption | ISurveyQuestionOption[];

  setComparativeResults(options: ISurveyQuestionOption[]): void

  answer(): ISurveyQuestionOption | ISurveyQuestionOption[];

  jumpTo(): string;

  userAnswerRequired(): boolean;

  setAnswerByIdx(idx: number): ISurveyQuestion;
}

export class BaseSurveyQuestion implements ISurveyQuestion {
  Id: string;
  Type: SurveyQuestionType;

  private _Text: string;
  public get Text(): string {
        return this._Text;
    }
    public set Text(value: string) {
        this._Text = value;
    }
  private _Position: number;
  public get Position(): number {
        return this._Position;
    }
    public set Position(value: number) {
        this._Position = value;
    }
  private _Required: boolean;
  public get Required(): boolean {
        return this._Required;
    }
    public set Required(value: boolean) {
        this._Required = value;
    }
  SurveyPgroupId: number | null;
  SurveyPgroup: ISurveyPgroup | null;

  protected _options: ISurveyQuestionOption[];

  get Options(): ISurveyQuestionOption[] {
    return this._options;
  }

  set Options(options: ISurveyQuestionOption[]) {
    this._options = [];
    for (let op in options) {
      this._options.push(new SurveyQuestionOption(options[op]))
    }
  }

  _selectedOption: ISurveyQuestionOption | ISurveyQuestionOption[];
  get selectedOption(): ISurveyQuestionOption | ISurveyQuestionOption[] {
    return this._selectedOption;
  }

  set selectedOption(value: ISurveyQuestionOption | ISurveyQuestionOption[]) {
    this._selectedOption = value;
    this.previousOption = null;
    this.defaultOption = null;
  }

  previousOption: ISurveyQuestionOption | ISurveyQuestionOption[] = null;
  defaultOption: ISurveyQuestionOption | ISurveyQuestionOption[] = null;


  bootstrap() {
    if (this.Id === '1') {
      this.defaultOption = this.Options.find(o => o.Default) || this.Options[0];
    }

    // for user input answer we make sure exists a default option; its a MUST
    if (this.userAnswerRequired()) {
      this.defaultOption = this.Options[0];
    }
  }

  setAnswerByIdx(idx: number): ISurveyQuestion {
    if (idx) {
      const option = this.Options.find((op, i) => i === idx - 1);
      if (option) {
        this.selectedOption = option;
      }
    }
    return this;
  }

  userAnswerRequired(): boolean {
    return [
      SurveyQuestionType.STRING,
      SurveyQuestionType.YEAR,
      SurveyQuestionType.NUMBER,
    ].indexOf(this.Type) >= 0;
  }

  jumpTo(): string {
    let answer = this.answer() || [];
    if (!(answer instanceof Array)) {
      answer = [answer];
    }
    const found = answer.find(a => !!a.JumpTo);
    return found ? found.JumpTo : null;
  }

  setPreviousOption(answers: Array<{
    OptionId: number,
    UserAnswer?: string
  }>): ISurveyQuestionOption | ISurveyQuestionOption[] {
    if (this.Type === SurveyQuestionType.MULTIPLE) {
      this.previousOption = [];
    }

    for (let i = 0; i < this.Options.length; i++) {
      const op: ISurveyQuestionOption = this.Options[i];
      for (let j = 0; j < answers.length; j++) {
        const a = answers[j];
        if (a.OptionId === op.Id) {
          op.UserAnswer = a.UserAnswer || null;
          if (this.previousOption instanceof Array) {
            this.previousOption.push(op);
          } else {
            this.previousOption = op;
            break;
          }
        }
      }
    }
    if (this.previousOption) {
      this.defaultOption = null;
    }
    return this.previousOption;
  }

  setComparativeResults(options: ISurveyQuestionOption[]) {
    for (let i = 0; i < this.Options.length; i++) {
      const op: ISurveyQuestionOption = this.Options[i];
      for (let j = 0; j < options.length; j++) {
        if (options[j].Id === op.Id) {
          op.CountOthers = options[j].CountOthers;
          op.Points = options[j].Points;

          const answer = this.answer();
          if (answer instanceof Array) {
            op.UserSelection = !!answer.find(a => a.Id === op.Id);
          } else {
            op.UserSelection = answer ? answer.Id === op.Id : false;
          }
        }
      }
    }
  }

  answer(): ISurveyQuestionOption | ISurveyQuestionOption[] {
    if (this.selectedOption) {
      return this.selectedOption;
    }

    if (this.previousOption) {
      return this.previousOption;
    }

    if (/** !this.Required &&*/ this.defaultOption) {
      return this.defaultOption;
    }

    return null;
  }
}

export class SurveyQuestion extends BaseSurveyQuestion {

  constructor(data: ISurveyQuestion) {
    super();

    for (let prop in data) {
      this[prop] = data[prop];
    }

    this.bootstrap();
  }
}
