
import { takeWhile } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { Umbrella, MyProjectsUmbrella, ArchiveUmbrella, IUmbrella } from './umbrella';

import { BaseService, } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Account } from '@board-accounts/account.model';


@Injectable({
    providedIn: 'root'
})
export class UmbrellaService extends BaseService {

    private _umbrellas: BehaviorSubject<Umbrella[]>;
    private _umbrella: BehaviorSubject<Umbrella>;
    private dataStore: {
        umbrellas: Umbrella[],
        defaults: MyProjectsUmbrella[]
    }
    private _loadingState: BehaviorSubject<number>;
    private _processingState: BehaviorSubject<number>;
    private _endPoint: string;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
        private targetService: TargetResourceService,
        private messageBus: MessageBusService,
        private alertService: AlertService
    ) {
        super();
        this._endPoint = `${this.config.endpoint}/api/umbrellas`;
        this.dataStore = { umbrellas: [], defaults: [] };
        this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._umbrellas = <BehaviorSubject<Umbrella[]>>new BehaviorSubject(null);
        this._umbrella = <BehaviorSubject<Umbrella>>new BehaviorSubject(null);
    }

    get loadingState(): Observable<number> {
        return this._loadingState.asObservable();
    }
    get processingState(): Observable<number> {
        return this._processingState.asObservable();
    }
    resetProcessingState$(): Observable<number> {
        this._processingState.next(-1);
        return this.processingState;
    }

    get umbrellas$() {
        return this._umbrellas.asObservable();
    }
    get umbrella$(): Observable<Umbrella> {
        return this._umbrella.asObservable();
    }
    get umbrella(): Umbrella {
        return this._umbrella.getValue();
    }

    loadAll(account: Account) {
        if (!account) return;

        if (this.inStorage(account)) {
            this.fetch(account);
            // if (this.targetService.targetLevel > 0) {
            //     this.setCurrentUmbrellaFromTarget(account);
            // }
            return;
        }
        this._loadingState.next(UmbrellaService.STATES.LOADING);
        this.dataStore.umbrellas[account.Id] = [];

        let params = new HttpParams({
            fromObject: {
                'filter[AccountId]': account.Id
            }
        });

        this.http.get(this._endPoint, { params: params, observe: 'response' })
            .subscribe(
                (response) => {
                    let data = response.body;
                    let headers = response.headers as HttpHeaders;

                    // set default umbrella ahead of time
                    this.getDefault(account.Id);

                    let archive = null;
                    for (let key in data) {
                        let umbrella: Umbrella = data[key];
                        if (umbrella.Type === 'archive') {
                            archive = new Umbrella(umbrella);
                            continue;
                        }
                        if (umbrella.AccountId === account.Id) {
                            this.dataStore.umbrellas[account.Id].push(new Umbrella(umbrella))
                        }
                    };

                    if (!archive) {
                        archive = new ArchiveUmbrella(account.Id);
                    }
                    this.dataStore.umbrellas[account.Id].push(archive);

                    this.fetch(account);

                    if (this.targetService.targetLevel > 0) {
                        this.setCurrentUmbrellaFromTarget(account);
                    }
                    //  else if (this.dataStore.umbrellas[account.Id].length === UmbrellaService.STOCK.length) {
                    //     this.openUmbrella(myprojects);
                    // }
                },
                error => {
                    console.log('Could not load umbrellas');
                    delete (this.dataStore.umbrellas[account.Id]);
                    this._loadingState.next(UmbrellaService.STATES.ERROR);

                    this.alertService.error(error, ServiceAlertClass.ALERTS.UMBRELLA_LIST);
                }
            )
    }

    create(umbrella: Umbrella, account: Account) {
        this._processingState.next(UmbrellaService.STATES.PROCESSING);
        this.http.post(this._endPoint, umbrella.toDb())
            .subscribe(
                data => {
                    this.addUI(new Umbrella(data), account.Id);

                    this._processingState.next(UmbrellaService.STATES.DONE);
                    this.fetch(account);
                },
                error => {
                    console.log('Could not create umbrella.');
                    if (error.status === 401) {
                        this._processingState.next(UmbrellaService.STATES.UNAUTHORIZED);
                    } else {
                        this._processingState.next(UmbrellaService.STATES.ERROR);
                    }
                    this.alertService.error(error, ServiceAlertClass.ALERTS.UMBRELLA_CREATE);
                }
            )
    }

    update(umbrella: Umbrella, account: Account) {
        this._processingState.next(UmbrellaService.STATES.PROCESSING);
        this.http.put(`${this._endPoint}/${umbrella.Id}`, umbrella.toDb())
            .subscribe(
                data => {
                    let uUmbrella = new Umbrella(data);
                    this.updateUI(uUmbrella, account.Id);
                    this._umbrella.next(uUmbrella);

                    this._processingState.next(UmbrellaService.STATES.DONE);
                    this.fetch(account);
                },
                error => {
                    console.log('Could not update umbrella.');
                    if (error.status === 401) {
                        this._processingState.next(UmbrellaService.STATES.UNAUTHORIZED);
                    } else {
                        this._processingState.next(UmbrellaService.STATES.ERROR);
                    }
                    this.alertService.error(error, ServiceAlertClass.ALERTS.UMBRELLA_UPDATE);
                }
            )
    }

    remove(umbrella: Umbrella, account: Account) {
        this._processingState.next(UmbrellaService.STATES.PROCESSING);
        umbrella._state = 'remove';
        this.http.delete(`${this._endPoint}/${umbrella.Id}`)
            .subscribe(data => {
                this.removeUI(umbrella, account.Id);
                this._processingState.next(UmbrellaService.STATES.DONE);

            },
                error => {
                    console.log('Could not remove umbrella.');
                    if (error.status === 401) {
                        this._processingState.next(UmbrellaService.STATES.UNAUTHORIZED);
                    } else {
                        this._processingState.next(UmbrellaService.STATES.ERROR);
                    }

                    this.alertService.snackError(error);
                }
            )
    }

    private inStorage(account: Account) {
        return !!this.dataStore.umbrellas[account.Id];
    }

    private fetch(account: Account) {
        let pUmbrellas = Object.assign({}, this.dataStore).umbrellas[account.Id];
        this._umbrellas.next(pUmbrellas);

        if (pUmbrellas.length > 0) {
            this._loadingState.next(UmbrellaService.STATES.DONE);
        } else {
            this._loadingState.next(UmbrellaService.STATES.EMPTY);
        }
    }

    private addUI(umbrella: Umbrella, accountId?: string) {
        accountId || (accountId = umbrella.AccountId);
        if (this.dataStore.umbrellas[accountId]) {
            this.dataStore.umbrellas[accountId].splice(1, 0, umbrella);
        }
        return this;
    }
    private removeUI(umbrella: Umbrella, accountId?: string) {
        accountId || (accountId = umbrella.AccountId);
        if (this.dataStore.umbrellas[accountId]) {
            this.dataStore.umbrellas[accountId].forEach((t, i) => {
                if (t.Id === umbrella.Id) {
                    this.dataStore.umbrellas[accountId].splice(i, 1);
                }
            });
        }
        return this;
    }
    private updateUI(umbrella: Umbrella, accountId?: string) {
        accountId || (accountId = umbrella.AccountId);
        if (this.dataStore.umbrellas[accountId]) {
            this.dataStore.umbrellas[accountId].forEach((t, i) => {
                if (t.Id === umbrella.Id) {
                    this.dataStore.umbrellas[accountId][i] = umbrella;
                }
            });
        }
        return this;
    }

    private setCurrentUmbrellaFromTarget(account: Account) {
        let targetsSet: boolean = false;
        this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
            .subscribe((targets) => {
                if (targets.umbrellaId) {
                    targetsSet = true;
                    let targetUmbrella = this.findUmbrella(targets.umbrellaId, account.Id);
                    if (targetUmbrella) {
                        this.openTargetUmbrella(targetUmbrella);
                    }
                }
            })
    }

    findUmbrella(umbrellaId: string, accountId: string): IUmbrella {
        let targetUmbrella: IUmbrella;
        for (let umbrella of this.dataStore.umbrellas[accountId]) {
            if (umbrella.Id === umbrellaId) {
                targetUmbrella = umbrella;
                break;
            }
        }

        return targetUmbrella || null;
    }

    findArchive(accountId: string): IUmbrella {
        let archive: IUmbrella;
        for (let umbrella of this.dataStore.umbrellas[accountId]) {
            if (umbrella.Type === 'archive') {
                archive = umbrella;
                break;
            }
        }

        return archive || null;
    }

    getDefault(accountId: string): IUmbrella {

        if (!this.dataStore.defaults[accountId]) {
            this.dataStore.defaults[accountId] = new MyProjectsUmbrella(accountId, {});
        }
        return this.dataStore.defaults[accountId];
    }

    openUmbrella(umbrella: IUmbrella) {
        this.messageBus.publish(MessageBusActions.ACTIVATE_UMBRELLA, { umbrella: umbrella, action: 'open' });
        umbrella._open = !umbrella._open;
    }

    openTargetUmbrella(umbrella: IUmbrella) {
        this.messageBus.publish(MessageBusActions.ACTIVATE_UMBRELLA, { umbrella: umbrella, action: 'target' });
        umbrella._open = !umbrella._open;
    }
}