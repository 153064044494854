import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'organizer-actions-bar',
    template: `
        <span *ngIf="nSelected===0" class="title">Drag and drop your image into position to get your gallery nicely organized.</span>
        <ul *ngIf="nSelected > 0">
            <li>{{nSelected}} selected</li>
            <li class="action" *ngIf="nSelected !== galleryLength" (click)="selectAll()">Select All</li>
            <li class="action" (click)="deselect()">Deselect</li>
            <li class="action delete" (click)="deleteSelected()">Delete</li>
        </ul>
        <span><ng-content></ng-content></span>
    `,
    styleUrls: ['./organizer-actions-bar.component.scss']
})
export class OrganizerActionsBarComponent implements OnInit, OnDestroy {
    @HostBinding('class.mgc-organizer-actions-bar') actionBarClass = true;

    @Input('nSelected') nSelected: number = 0;
    @Input('galleryLength') galleryLength: number = 0;

    @Output() action: EventEmitter<string> = new EventEmitter<string>();

    constructor(
    ) { }

    selectAll() {
        this.action.next('select-all');
    }

    deselect() {
        this.action.next('deselect');
    }

    deleteSelected() {
        this.action.next('delete-selected');
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

}