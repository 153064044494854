import { Component, HostBinding, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ACCENTS, IAccentOption } from '../accent.class'
import { ICustomImageOptions } from "../../theme-options.interface";

@Component({
    selector: 'mgc-custom-image',
    templateUrl: './custom-image.component.html',
    styleUrls: ['./custom-image.component.scss'],
})
export class CustomImageComponent implements OnInit {
    @HostBinding('class.mgc-custom-image') customImageClass = true;

    @Input() customImageOptions: ICustomImageOptions;

    @Output() chooseMedia: EventEmitter<void> = new EventEmitter<void>()

    accentOptions: IAccentOption[];
    selectedAccentOption: IAccentOption;

    onAccentChange(option: IAccentOption) {
        this.selectedAccentOption = option;
    }

    chooseFromGallery() {
        this.chooseMedia.next();
    }

    updatedOptions() {
        return {
            ...this.customImageOptions,
            ...{
                accent: this.selectedAccentOption.id
            }
        }
    }

    ngOnInit() {
        this.accentOptions = Object.values(ACCENTS);

        this.selectedAccentOption = this.accentOptions.find(accent => accent.id == this.customImageOptions.accent) || this.accentOptions[0];

        // // make sure we have something setup in theme; defaults
        // this.themeImage || (this.themeImage = {
        //     id: this.defaultBgImageId,
        //     accent: this.selectedAccentOption.value
        // })
        // this.themeImage.id || (this.themeImage.id = this.defaultBgImageId);
        // this.themeImage.accent || (this.themeImage.accent = this.selectedAccentOption.value)

        // this.initialTheme = Object.assign({}, this.themeImage);
        // this.themeImageUpdate.next(this.themeImage);
    }
}

// private getSelectedAccent() {
//     if (!this.themeImage || (this.themeImage && !this.themeImage.accent)) {
//         return this.accentOptions[0];
//     }

//     for (let i = 0; i <= this.accentOptions.length; i++) {
//         let op = this.accentOptions[i];
//         if (op.value === this.themeImage.accent) {
//             return op;
//         }
//     }

//     return this.accentOptions[0];
// }
