import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService } from '@board-accounts/account.service';
import { BaseService } from '@board/_services';
import { Participant } from '@panel-components/participants';
import { AlertService, IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { of, Subscription } from 'rxjs';
import { filter, finalize, switchMap, take } from 'rxjs/operators';
import { TaskV2AttachmentsBlock } from './attachments-block/attachments-block';
import { TaskV2AttachmentsBlockComponent } from './attachments-block/attachments-block.component';
import { TaskV2ImageBlock } from './image-block/image-block';
import { TaskV2Image } from './image-block/image/task-v2-image';
import { TaskV2SurveyBlock } from './survey-block/survey-block';
import { ITaskV2Block } from './task-v2-block.interface';
import { ITaskV2BlocksMetadata } from './task-v2-blocks-metadata';
import { TaskV2BlocksService } from './task-v2-blocks.service';
import { TaskV2TextBlock } from './text-block/text-block';
import { TaskV2BlockAction, TaskV2BlockType, TASK_V2_BLOCKS } from './types';
import { TaskV2VideoBlock } from './video-block/video-block';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'task-v2-blocks',
  templateUrl: './task-v2-blocks.component.html',
  styleUrls: ['./task-v2-blocks.component.scss'],
})
export class TaskV2BlocksComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  constructor(
    private formBuilder: FormBuilder,
    private blocksService: TaskV2BlocksService,
    private accountService: AccountService,
    private alertService: AlertService,
  ) { }

  get f() { return this.form.controls; }

  /** end of FORM */


  get states() {
    return BaseService.STATES;
  }

  // @HostBinding('class.mgc-email-item-board') boardClass = true;

  @Input('item') task: any;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(TaskV2AttachmentsBlockComponent) attachmentsBlockComponent: TaskV2AttachmentsBlockComponent;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  ready: boolean;
  loading: boolean;
  processing: boolean;
  subscription: Subscription;

  predefinedBlocks = TASK_V2_BLOCKS;
  removedBlocks: ITaskV2Block[] = [];
  blockType = TaskV2BlockType;

  accountId: string = null;

  initialBlocks: ITaskV2Block[] = [];

  /** FORM */
  form: FormGroup;

  submitted = false;

  bootstrapForm(blocks: ITaskV2Block[]) {
    this.submitted = false;
    if (!blocks.length) {
      const block = new TaskV2TextBlock({ TaskId: this.task.Id });
      block._httpAction = TaskV2BlockAction.CREATE;
      blocks.push(block);
    }

    this.form = this.formBuilder.group({
      blocks: [blocks],
    });
  }

  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    return true;
  }

  onRemoveBlock(block: ITaskV2Block) {
    const blocks = this.f.blocks.value;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i] === block) {
        // if Id, then is comming from DB, so we mark it as delete
        // otherwise, no need to keep track of it
        if (block.Id) {
          block._httpAction = TaskV2BlockAction.DELETE;
          this.removedBlocks.push(block);
        }
        blocks.splice(i, 1);
        this.updatePredefinedBlocks();
      }
    }
  }

  onAddBlock(predifinedBlock) {
    let block: ITaskV2Block;
    switch (predifinedBlock.type) {
      case TaskV2BlockType.TEXT:
        block = new TaskV2TextBlock({ TaskId: this.task.Id });
        break;
      case TaskV2BlockType.IMAGE:
        const image = new TaskV2Image({
          TaskV2Id: this.task.Id,
          AccountId: this.accountService.currentAccount.Id,
        });
        block = new TaskV2ImageBlock({ TaskId: this.task.Id, Content: image });
        break;
      case TaskV2BlockType.VIDEO:
        block = new TaskV2VideoBlock({ TaskId: this.task.Id });
        break;
      case TaskV2BlockType.SURVEY:
        block = new TaskV2SurveyBlock({ TaskId: this.task.Id });
        break;
      case TaskV2BlockType.ATTACHMENTS:
        block = new TaskV2AttachmentsBlock({ TaskId: this.task.Id, Content: {} });
        this.updatePredefinedBlocks();
        break;
    }

    if (block) {
      block._httpAction = TaskV2BlockAction.CREATE;
      this.f.blocks.value.push(block);
    }
  }

  postAs(admin: Participant) {
    if (admin && admin.Id) {
      this.accountId = admin.Id;
    }
  }

  onSave(): void {
    if (!this.submit() || this.processing) {
      return;
    }

    const blocks: ITaskV2Block[] = this.form.value['blocks']
      .concat(this.removedBlocks)
      .map((block: ITaskV2Block) => {
        return block.toDb();
      });

    this.processing = true;
    this.alertService.clear();
    this.blocksService.saveBlocks(this.task, { accountId: this.accountId, blocks })
      .pipe(
        take(1),
        finalize(() => this.processing = false),
      )
      .subscribe((bMetadata: ITaskV2BlocksMetadata) => {
        if (!bMetadata) {
          this.alertService.error('Oops! There was an error while trying to save the data!');
          return;
        }

        this.initialBlocks = bMetadata.blocks;
        this.removedBlocks = [];

        if (this.attachmentsBlockComponent) {
          if (typeof this.attachmentsBlockComponent.onSave === 'function') {
            this.attachmentsBlockComponent.onSave();
            this.attachmentsBlockComponent.close.pipe(take(1)).subscribe(close => {
              if (close) {
                this.close.emit('update');
              }
            });
          }
          this.form.patchValue({
            blocks: this.initialBlocks.map(b => {
              if (b instanceof TaskV2AttachmentsBlock) {
                b.Content.files = this.attachmentsBlockComponent.block.Content.files;
                b.Content.imageOrder = this.attachmentsBlockComponent.block.Content.imageOrder;
              }
              return b;
            }).slice(),
          });
        } else {
          this.form.patchValue({ blocks: this.initialBlocks.slice() });
          this.close.emit('update');
        }

      });
  }

  onCancel(): void {
    this.close.emit('cancel');
  }

  private render(blocks) {
    if (!blocks) {
      return;
    }
    this.bootstrapForm(blocks);
  }

  private loadBlocks() {
    this.ready = false;
    this.loading = true;
    of(this.task)
      .pipe(
        filter(task => !!task),
        switchMap(task => {
          return this.blocksService.getBlocks(task);
        }),
      )
      .subscribe((bMetadata: ITaskV2BlocksMetadata) => {
        this.initialBlocks = bMetadata.blocks;
        this.accountId = bMetadata.account ? bMetadata.account.Id : null;
        this.render(this.initialBlocks.slice());
        this.ready = true;
        this.loading = false;
      });
  }

  updatePredefinedBlocks() {
    setTimeout(() => {
      this.predefinedBlocks = TASK_V2_BLOCKS.filter(b => !(b.type === TaskV2BlockType.ATTACHMENTS && this.attachmentsBlockComponent));
    }, 100);
  }

  onMove(block: ITaskV2Block, position: 1 | -1) {
    const blocks = this.f.blocks.value;
    const blockIndex = blocks.findIndex((b: ITaskV2Block) => b.BlockId === block.BlockId);

    blocks.splice(blockIndex, 1);
    blocks.splice(blockIndex + position, 0, block);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['task'] && changes['task'].currentValue) {
      this.loadBlocks();
    }
  }

  ngAfterViewInit(): void {
    this.updatePredefinedBlocks();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.blocks.value, event.previousIndex, event.currentIndex);
  }
}
