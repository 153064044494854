<mgc-dialog>

  <mgc-dialog-header title="Confirm email" (close)="closeDialog()"> </mgc-dialog-header>

  <mgc-dialog-content>
    <must-activate
      message="You need to check your email, copy the code and paste it into the activation code field below."
      (close)="closeDialog()"></must-activate>
  </mgc-dialog-content>

  <alert></alert>

  <mgc-dialog-actions>
      <mgc-stroked-button *ngIf="!activated" left [processing]="resending" (click)="resend()">Send Me The Code
      </mgc-stroked-button>
      <mgc-raised-button *ngIf="!activated" right [processing]="processing" (click)="onSubmit()">Activate
      </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Close</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>