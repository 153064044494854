import { Component, Input, HostBinding, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'brand-avatar',
    templateUrl: 'brand-avatar.component.html',
    styleUrls: ['brand-avatar.component.scss']
})

export class BrandAvatarComponent implements OnChanges {
    @HostBinding('class.brand-avatar') brandAvatar = true;

    fontSize: number;
    textPosY: number;
    path: string;
    sw: number;
    sc: string;
    dpath: string;
    defaultColor: string = 'rgba(0,0,0, 0.1)';

    private timestamp: any = null;

    private _brand = new BehaviorSubject<any>(null);
    @Input() type: string;
    @Input() textColor: string;
    @Input() shape: string = "rectangle";
    @Input() processing: boolean = false;
    @Input() previewAvatarFile: any = null;

    @HostBinding('style.border-radius') borderRadiusStyle = this.shape === 'rectangle' ? '10px' : '50%';

    @Input()
    set brand(value: any) {
        value || (value = {})
        this._brand.next(value);
        this.path = value.Thumbnail;
        this.sc = this.strokeColor || (this.brand.Initials ? this.fillColor : this.defaultColor);
    };
    get brand() {
        return this._brand.getValue();
    }

    @Input() size: number;
    @Input() strokeWidth: number;
    @Input() strokeColor: string;

    @HostBinding('style.height.px') get hPx(): number { return this.size; }
    @HostBinding('style.width.px') get wPx(): number { return this.size; }

    get fillColor(): string {
        return this.brand.Color || 'white';
    }

    constructor(
        private chRef: ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        this.sw = this.strokeWidth || 2;
        this.sc = this.strokeColor || (this.brand.Initials ? this.fillColor : this.defaultColor);
        this.fontSize = this.size / 2 - this.sw;
        this.textPosY = this.size / 2 + this.fontSize / 3;
    }

    ngDoCheck() {
        if (this.brand._timestamp && this.timestamp !== this.brand._timestamp) {
            this.timestamp = this.brand._timestamp;
            this.chRef.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.size && changes.size.currentValue) {
            this.fontSize = this.size / 2 - this.sw;
            this.textPosY = this.size / 2 + this.fontSize / 3;
        }
    }
}