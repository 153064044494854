<div style="display: none" cdkFocusInitial></div>

<ng-container *ngIf="!allow">
    <p class="warning">You are not allowed to view participant list.</p>
</ng-container>

<ng-container *ngIf="allow">
    <mgc-loader *ngIf="state===states.LOADING"></mgc-loader>

    <alert [service]="[serviceAlertClass.PARTICIPANT_LIST]"></alert>

    <ng-container *ngIf="state===states.DONE || state===states.EMPTY">
        <div class="add-block" (click)="newParticipant()" *ngIf="project?._umbrellaId!=='startup'">
            <mgc-icon-button icon="board-mat:add">Add participant</mgc-icon-button>
            <mgc-divider full-width="true" absolute-position></mgc-divider>
        </div>

        <div class="grid">
            <mgc-assigned-participant *ngIf="assignedParticipant" [participant]="assignedParticipant" [item]="item">
                <mgc-divider full-width="true" absolute-position></mgc-divider>
            </mgc-assigned-participant>

            <ng-container [@list]="participants.length" *ngFor="let participant of participants; trackBy: trackByFn">
                <mgc-participant *ngIf="assignedParticipant?.Id!==participant.Id"
                    [participant]="participant" [item]="item">
                    <mgc-divider full-width="true" absolute-position></mgc-divider>
                </mgc-participant>
            </ng-container>
        </div>

    </ng-container>
</ng-container>