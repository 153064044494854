import { IColor } from './color.interface';
import { ITheme, ThemeType } from './theme.interface';
import * as tinycolor from 'tinycolor2';
import { DARK_THEME_COLOR, LIGHT_THEME_COLOR } from '@libraries/theming';

export class Theme {
    id = '__default';
    type: ThemeType;
    name = 'Default Theme';
    primaryColor: string;
    navColor: string;
    focusColor: string;
    navContrast: string;
    properties: { [key: string]: string } = {
        '--theme-foreground-text': '#4A4A4A',
        '--theme-foreground-divider': '#E1E6EE',
        '--theme-foreground-link': '#005CC5',
        '--theme-foreground-icon': '#b6c8d8',
        '--theme-foreground-text-icon': '#868B8F',

        '--theme-background-base': 'white',
        '--theme-background-form': '#EEF3F7',
    };

    primaryPallete: IColor[];
    navPallete: IColor[];

    constructor(data: ITheme, type: ThemeType = 'light') {
        this.id = data.id;
        this.name = data.name;
        this.type = type;

        this.primaryColor = this.getPrimaryColor(data);
        this.navColor = this.getNavigationColor(data);

        const ncolor = this.getColorObject(tinycolor(this.navColor), 'nav-bg-contrast');
        this.navContrast = ncolor.darkContrast ? 'rgba(0,0,0, 1)' : 'white';

        const pcolor = tinycolor(this.primaryColor);
        pcolor.setAlpha(.1);
        this.focusColor = pcolor.toRgbString();

        const properties: { [key: string]: string; } = data.properties || {};

        this.properties = {
            ...this.properties,
            ...properties
        };

        this.savePrimaryPallete(this.primaryColor);
        this.saveNavigationColor(this.navColor);
    }

    get isLight() {
        return this.type === 'light';
    }

    getPrimaryColor(data) {
        return typeof (data.primaryColor) === 'string' ? data.primaryColor : (this.isLight ? data.primaryColor[0] : data.primaryColor[1]);
    }

    getNavigationColor(data) {
        if (typeof (data.navColor) === 'undefined') {
            return this.isLight ? LIGHT_THEME_COLOR : DARK_THEME_COLOR;
        } else {
            return typeof (data.navColor) === 'string' ? data.navColor : (this.isLight ? data.navColor[0] : data.navColor[1]);
        }
    }

    savePrimaryPallete(primaryColor: string) {
        this.primaryPallete = this.computePalleteColors(primaryColor);

        for (const color of this.primaryPallete) {
            this.properties[`--theme-primary-${color.name}`] = color.rgbVal;
            this.properties[`--theme-primary-contrast-${color.name}`] = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
        }
    }

    saveNavigationColor(hex: string) {
        // just for DEV scope;
        this.navPallete = this.computePalleteColors(hex);

        const color = this.getColorObject(tinycolor(hex), 'nav-bg');
        this.properties[`--theme-background-nav`] = color.rgb;
        this.properties[`--theme-background-nav-header`] = this.isLight ? LIGHT_THEME_COLOR : DARK_THEME_COLOR;
        this.properties[`--theme-foreground-nav`] = color.darkContrast ? '0,0,0' : '255,255,255';

        this.properties[`--theme-background-form-theme`] = color.darkContrast ? '#eeF3F7' : 'rgb(255 255 255 / 15%)';
        this.properties[`--theme-foreground-form-theme`] = color.darkContrast ? '#4A4A4A' : 'white';
    }

    computePalleteColors(hex: string): IColor[] {
        return [
            this.getColorObject(tinycolor(hex).lighten(52), '50'),
            this.getColorObject(tinycolor(hex).lighten(37), '100'),
            this.getColorObject(tinycolor(hex).lighten(26), '200'),
            this.getColorObject(tinycolor(hex).lighten(12), '300'),
            this.getColorObject(tinycolor(hex).lighten(6), '400'),
            this.getColorObject(tinycolor(hex), '500'),
            this.getColorObject(tinycolor(hex).darken(6), '600'),
            this.getColorObject(tinycolor(hex).darken(12), '700'),
            this.getColorObject(tinycolor(hex).darken(18), '800'),
            this.getColorObject(tinycolor(hex).darken(24), '900'),
            this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
            this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
            this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
            this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
        ];
    }

    private getColorObject(value, name): IColor {
        const c = tinycolor(value);
        return {
            name: name,
            hex: c.toHexString(),
            rgb: c.toRgbString(),
            rgbVal: c.toRgbString().replace(/rgb\(|\)/g, ''),
            darkContrast: c.isLight()
        };
    }
}
