import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MultiGalleryComponent } from './multi-gallery.component';
import { MultiGalleryModal } from './multi-gallery-modal';
import { UploaderModule } from '@libraries/uploader/x_uploader.module';
import { GalleryModule } from '@libraries/gallery';
import { MgcDialogModule, MgcButtonModule, MgcLoaderModule } from '@rallysite/mgc-components';
import { MultiGalleryService } from './multi-gallery.service';


@NgModule({
    imports: [
        CommonModule,
        MgcDialogModule,
        MgcButtonModule,
        MgcLoaderModule,
        MatIconModule,
        UploaderModule,
        GalleryModule
    ],
    declarations: [
        MultiGalleryComponent,
        MultiGalleryModal
    ],
    providers: [MultiGalleryService],
    exports: [
        MultiGalleryComponent
    ]
})
export class MultiGalleryModule { }
