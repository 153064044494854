<div #galleryContainer class="galleryContainer" [@galleryImageTransition]="true" 
    [style.padding.px]="imageMargin">

    <div class="innerGalleryContainer"
        [style.left.px] = "horizontalPosition"
        [@imageAnimator] = "transition">

        <div class="imagerow"  [style.transform]="translateX(scrollX)"
            (pan)="pan($event)">

            <div #imageElement class="image-wrapper card" 
                (click)="openImageViewer(img)"
                *ngFor='let img of gallery; let j = index' 
                [style.max-width.px]="idealWidth" 
                [style.min-width.px]="idealWidth"
                [style.height.px]="idealHeight" 
                [style.margin-right.px]="imageMargin"
                [ngStyle]="img['wrapperStyle']">
                
                <div class="elevate" [style.width.px]="img.width" [style.height.px]="img.height">
                <img 
                    [ngStyle]="getImageStyle(img)" 
                    [src]="img.src| safe: 'resourceUrl'" />
                </div>

                <div class="overlay" *ngIf="img.model?.isUploading">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="30"></mat-progress-spinner>
                </div>
                <div class="overlay" *ngIf="img.model?.isReadyForUpload">
                    <mat-progress-bar color="primary" mode="buffer" value="0" bufferValue="30" [style.height.px]="3"
                        [style.width.%]="40"></mat-progress-bar>
                </div>
            </div>
        </div>

        <ng-container *ngIf="gallery.length && !smallView">
            <button class="pager left" mat-fab [class.inactive]="!leftArrowActive" 
                (click)="navigate(-1)" color="basic">
                <mat-icon class="arrow-icon" svgIcon="board-mat:keyboard_arrow_left"></mat-icon>
            </button>
            <button class="pager right" mat-fab [class.inactive]="!rightArrowActive" 
                (click)="navigate(1)" color="basic">
                <mat-icon class="arrow-icon" svgIcon="board-mat:keyboard_arrow_right"></mat-icon>
            </button>
        </ng-container>
    </div>

    <ng-container *ngIf="gallery.length && smallView">
        <button class="pager small left" mat-fab [class.inactive]="!leftArrowActive" 
            (click)="navigate(1)" color="basic">
            <mat-icon class="arrow-icon" svgIcon="board-mat:keyboard_arrow_left"></mat-icon>
        </button>
        <button class="pager small right" mat-fab [class.inactive]="!rightArrowActive" 
            (click)="navigate(-1)" color="basic">
            <mat-icon class="arrow-icon" svgIcon="board-mat:keyboard_arrow_right"></mat-icon>
        </button>
    </ng-container>

</div>

<!-- <viewer [initialQuality]="minimalQualityCategory" [withDetails]="withDetails" [downloadable]="downloadable"></viewer> -->