<rs-uploader [style.font-size]="0" renderAs="content" [options]="avatarImageOptions"
  (onSuccess)="onAvatarSuccess($event)" (onBeforeUpload)="onBeforeUploadAvatar($event)"
  (onError)="onAvatarError($event)">
</rs-uploader>

<div class="image" *ngIf="currentAccount.Id" [class.logo-only]="logoOnly">
  <account-avatar [processing]="avatarUploading" [previewAvatarFile]="previewAvatarFile" size="120"
    [account]="currentAccount"></account-avatar>
  <div class="mgc-action-label" (click)="rsUploader.click()">edit image</div>
</div>

<div class="accounts-data" *ngIf="!logoOnly">

  <form [formGroup]="accountDataForm">

    <ng-template [ngIf]="!currentAccount.Id" [ngIfElse]="accountSelect">
      <mgc-form-field label="Email" [invalid]="submitted && f.Email.errors">
        <div class="field-wrapper">
          <input tabindex="-1" formControlName="Email" name="Email">
        </div>
        <ng-container invalid-feedback *ngIf="submitted && f.Email.errors">
          <div *ngIf="f.Email.errors.required">Email is required</div>
          <div *ngIf="f.Email.errors.email">Email must be a valid email address</div>
        </ng-container>
      </mgc-form-field>
    </ng-template>

    <mgc-form-field label="Email Label" [invalid]="submitted && f.Name.errors">
      <div class="field-wrapper">
        <input tabindex="-1" formControlName="Name" name="Label">
      </div>

      <ng-container invalid-feedback *ngIf="submitted && f.Name.errors">
        <div *ngIf="f.Name.errors.required">Email label is required</div>
      </ng-container>
    </mgc-form-field>

  </form>

  <ng-content></ng-content>

</div>




<ng-template #accountSelect>
  <mgc-form-field multiline>
    <div class="field-wrapper">
      <mat-select class="mgc-select" [(ngModel)]="currentAccount" (selectionChange)="accountChanged()" name="account">

        <mat-select-trigger>
          <div class="mgc-account form-icon">
            <account-avatar size="40" [account]="currentAccount"></account-avatar>
            <span class="info">
              <span class="name" [innerHTML]="currentAccount.Name"></span>
              <span class="details">
                <span [innerHTML]="currentAccount.Email"></span>
              </span>
            </span>
          </div>
        </mat-select-trigger>

        <mat-option class="mgc-option" *ngFor="let account of accounts" [value]="account"
          [disabled]="!account.Confirmed">

          <div class="mgc-account">
            <account-avatar size="40" [account]="account"></account-avatar>
            <span class="info">
              <span class="name" [innerHTML]="account.Name"></span>
              <span class="details">
                <span [innerHTML]="account.Email"></span>
              </span>
            </span>
          </div>
          <mgc-icon-button class="warning" tabIndex="-1" icon="board-mat:warning" *ngIf="!account.Confirmed">
          </mgc-icon-button>

        </mat-option>

      </mat-select>
    </div>
  </mgc-form-field>
</ng-template>