import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '@rallysite/auth-service';
import { AccountService } from '@board-accounts/account.service';
import { BoardViewService } from '@board/_services/board-view.service';
import { CurrentResourceService } from '@board/_services/current-resource.service';
import { NotAllowedModal } from './not-allowed-modal';
import { Project } from '@board/items/project';
import { EmailCampaign } from '@board/emails/email-campaign/email-campaign';
import { VideoCampaign } from '@board-videos/video-campaign/video-campaign';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private resmaps: Array<any> = [];
  private permaps: Array<any> = [];

  private map: any = {
    'c': ['create', 'store'],
    'r': ['read', 'view', 'show'],
    'u': ['update', 'edit'],
    'd': ['delete', 'destroy']
  }

  constructor(
    private authService: AuthService,
    private boardView: BoardViewService,
    private currentResource: CurrentResourceService,
    private accountService: AccountService,
    private dialog: MatDialog
  ) {
  }

  private getRestrictionMap(item) {
    let resmap = {}
    if (!item || !item._resmap) {
      return null
    }
    let key = `${this.accountService.currentAccount.Id}--${item.Id}`;
    if (this.resmaps[key]) {
      return this.resmaps[key]
    }

    // "resmap", "spr:0,sno:1,spe:0,sst:1,sse:1,sfi:0,rul:2"]
    let values = item._resmap.split(',')
    values.forEach(function (val) {
      let v = val.split(':')
      if (typeof v === 'object' && v.length === 2) {
        resmap[v[0]] = v[1]
      }
    })
    this.resmaps[key] = resmap;
    return this.resmaps[key]
  }

  private getPermissionMap(item) {
    let permap = {}
    if (!item || !item._permap) {
      return null
    }

    let key = `${this.accountService.currentAccount.Id}--${item.Id}`;
    if (this.permaps[key]) {
      return this.permaps[key]
    }

    // "permap", "pa:15[012|115|215|315|415|515],pr:255,go:255,ta:255,no:255,co:255"]
    let values = item._permap.split(',')
    values.forEach(function (val) {
      let v = val.split(':')
      if (typeof v === 'object' && v.length === 2) {
        permap[v[0]] = v[1]
      }
    })
    this.permaps[key] = permap;
    return this.permaps[key];
  }

  private dec2chars(dec, len) {
    let binArr = (dec >>> 0).toString(2).split('')
    binArr = this.arrayFill(binArr, 0, len)

    let actions = Object.keys(this.map)
    let chars = ''
    for (let i = 0; i < len; i++) {
      chars += (parseInt(binArr[i], 10) === 1) ? actions[i % actions.length] : '-'
    }
    return chars
  }

  private arrayFill(arr, val, len) {
    arr = arr.reverse()
    for (let i = 0; i < len; i++) {
      if (typeof arr[i] === 'undefined') {
        arr[i] = val
      }
    }
    return arr.reverse()
  }

  private grantAccess(action: string, modelType: string, level?: string) {
    level || (level = '')
    let permission = [action, modelType, level].join('.')
    switch (permission) {
      case 'create.umbrella.':
      case 'delete.umbrella.':
      case 'create.project.':
      case 'delete.project.': // archive
        return this.authService.isAuthenticated

    }
    return false
  }

  private ucFirst(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  showPanel(panelId): boolean {
    panelId = this.ucFirst(panelId);
    if (typeof this['show' + panelId] === 'function') {
      return this['show' + panelId]();
    }

    return false;
  }

  private showStatus() {
    var rmap = this.getRestrictionMap(this.currentResource.project);
    if (!rmap) {
      return false
    }
    return parseInt(rmap.sst, 10) === 1
  }

  private showAssignments() {
    return true;
  }

  private showSettings() {
    return true;
  }

  private showContest() {
    return true;
  }


  private showParticipants() {
    var rmap = this.getRestrictionMap(this.currentResource.project)
    if (!rmap) {
      return false
    }
    return parseInt(rmap.spe, 10) === 1
  }

  private showMe() {
    return true;
  }

  private showDashboard() {
    // if (this.boardView.smallView()){
    //   return true;
    // }
    // return false;
    return true;
  }

  private showPublish() {
    return true;
  }

  private showInfo() {
    return true;
  }


  allowsAction(action: string, item: any, options: {
    message?: string,
    silent?: boolean,
    project?: Project,
    campaign?: EmailCampaign|VideoCampaign
  } = {
      message: 'You are not allowed to access this resource!',
      silent: false
    }): boolean {
    let permissionsMap: any;
    let accessLevel: number = -1;
    let isOwner = false

    let resAction = action.split('.')[0]
    let resType = action.split('.')[1] || item._type

    let project = options.project || this.currentResource.project;
    const campaign = options.campaign || this.currentResource.emailCampaign || this.currentResource.videoCampaign;

    switch (resType) {
      case 'umbrella':
        return this.grantAccess(resAction, resType)
      case 'project':
        permissionsMap = this.getPermissionMap(project);
        isOwner = item.AccountId === this.accountService.currentAccount.Id;
        break
      case 'task':
        permissionsMap = this.getPermissionMap(project);
        isOwner = resAction === 'create' || item.AccountId === this.accountService.currentAccount.Id;
        break
      case 'group':
      case 'task-group':
        permissionsMap = this.getPermissionMap(project);
        isOwner = resAction === 'create' || item.AccountId === this.accountService.currentAccount.Id;
        break
      case 'note':
      case 'comment':
      case 'assignment':
      case 'aresponse':
        permissionsMap = this.getPermissionMap(project);
        isOwner = resAction === 'create' || item.AccountId === this.accountService.currentAccount.Id;
        break
      case 'participant':
        permissionsMap = this.getPermissionMap(project);
        accessLevel = parseInt(item.AccessType.replace(/[^0-9,.]/g, ''));
        if (item.Email) {
          isOwner = this.accountService.currentAccount.Email.toLowerCase() === item.Email.toLowerCase();
          if (isOwner) {
            options.message = resAction === 'delete' ? 'You are not allowed to delete yourself' : 'You are not allowed to change your own level access'
          }
        }
        break

      //
      //
      //
      case 'video-campaign':
      case 'email-campaign':
        permissionsMap = this.getPermissionMap(campaign);
        isOwner = item.AccountId === this.accountService.currentAccount.Id;
        break
      case 'video-item':
      case 'email-item':
        permissionsMap = this.getPermissionMap(campaign);
        isOwner = resAction === 'create' || item.AccountId === this.accountService.currentAccount.Id;
        break

      case 'video-participant':
      case 'email-participant':
        permissionsMap = this.getPermissionMap(campaign);
        accessLevel = parseInt(item.AccessType.replace(/[^0-9,.]/g, ''));
        if (item.Email) {
          isOwner = this.accountService.currentAccount.Email.toLowerCase() === item.Email.toLowerCase();
          if (isOwner) {
            options.message = resAction === 'delete' ? 'You are not allowed to delete yourself' : 'You are not allowed to change your own level access'
          }
        }
        break

      //
      //
      //
      case 'mail':
        permissionsMap = this.getPermissionMap(project);
        break

      case 'member':
        permissionsMap = this.getPermissionMap(options.project || this.currentResource.brand);
        accessLevel = parseInt(item.AccessType.replace(/[^0-9,.]/g, ''));
        if (item.Email) {
          isOwner = this.accountService.currentAccount.Email.toLowerCase() === item.Email.toLowerCase();
          if (isOwner) {
            options.message = resAction === 'delete' ? 'You are not allowed to delete yourself' : 'You are not allowed to change your own level access'
          }
        }
        break;

      default:
        this.notAllowedModal(options);
        return false
    }

    return this.allowsTo(resAction, resType, accessLevel, permissionsMap, isOwner, options)
  }

  private allowsTo(action, modelType, level, permissionsMap, isOwner, options) {
    let actions

    if (level < 0) {
      level = '';
    }
    if (this.grantAccess(action, modelType, level)) {
      return true
    }

    const parts = modelType.split('-');
    if (parts.length > 1) {
      modelType = parts.map(x => x.substring(0, 1)).join('');
    } else {
      modelType = modelType.substring(0, 2);
    }

    actions = this.parseActions(permissionsMap[modelType], level, isOwner)
    if (!actions[action + '' + level]) {
      this.notAllowedModal(options)
    }
    return actions[action + '' + level]
  }

  private parseActions(shortActions, level, isOwner) {
    let actionsCount
    let actions
    let chunk
    let pos
    let k
    let a
    let allow
    let valueActions

    shortActions = this.decodePermissions(shortActions)
    actionsCount = Object.keys(this.map).length

    if (isOwner) {
      actions = shortActions.substring(0, actionsCount).split('')
    } else {
      chunk = shortActions.substring(actionsCount)
      pos = level !== '' && level >= 0 ? chunk.indexOf(level) : false
      if (pos !== false && chunk.indexOf('[') >= 0) {
        actions = chunk.substring(pos + 1, pos + 1 + actionsCount).split('')
      } else {
        actions = chunk.split('')
      }
    }

    for (k in this.map) {
      allow = actions.indexOf(k) >= 0
      valueActions = this.map[k]
      for (a in valueActions) {
        actions[valueActions[a] + '' + level] = allow
      }
    }

    return actions
  }

  private decodePermissions(shortActions) {
    let pos
    let ownActions
    let otherActions
    let oaArr

    pos = shortActions.indexOf('[')
    if (pos >= 0) {
      ownActions = this.dec2chars(shortActions.substring(0, pos), 4)
      otherActions = shortActions.substring(pos).slice(1, -1).split('|')
      oaArr = []
      for (let i = 0; i < otherActions.length; i++) {
        oaArr.push(otherActions[i].substring(0, 1) + '' + this.dec2chars(otherActions[i].substring(1), 4))
      }
      shortActions = '' + ownActions + '[' + oaArr.join('|') + ']'
    } else {
      shortActions = this.dec2chars(shortActions, 8)
    }

    return shortActions
  }

  private notAllowedModal(options) {
    if (options.silent) {
      return false;
    }
    this.dialog.open(NotAllowedModal, {
      width: '50%',
      maxWidth: '400px',
      minWidth: '200px',
      disableClose: true,
      data: {
        message: options.message
      }
    });
  }

}
