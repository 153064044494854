import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DEFAULT_CUSTOM_COLOR_THEME } from "../../predefined.themes";
import { ICustomColorOptions } from "../../theme-options.interface";
import { CustomColorComponent } from "./custom-color.component";

@Component({
    selector: 'custom-color-modal',
    templateUrl: './custom-color.modal.html',
})
export class CustomColorModal implements OnInit {

    @ViewChild(CustomColorComponent) customColorComponent: CustomColorComponent;

    customColorOptions: ICustomColorOptions;

    constructor(
        public dialogRef: MatDialogRef<CustomColorModal>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.customColorOptions = data.customColorOptions || DEFAULT_CUSTOM_COLOR_THEME;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    onProcess() {
        if (!!this.customColorComponent) {
            this.dialogRef.close(this.customColorComponent.updatedOptions());
        }
    }

    ngOnInit() {
    }

}
