import { Component, Inject, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Umbrella } from '@board/items/umbrella';
import { Project } from '@board/items/project';
import { BaseTaskGroup } from '@board/items/task-group';
import { Task } from '@board/items/task';

@Component({
  selector: 'delete-confirm-modal',
  templateUrl: './delete-confirm-modal.html',
  styleUrls: ['./delete-confirm-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteConfirmModal {
  processing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: Umbrella | Project | BaseTaskGroup | Task,
      delete: Function,
      text: string,
      title: string,
      type: string,
      action: string
    }
  ) {
  }

  private close(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.data.item._state = '';
    if (this.data.item['_stateRemove']) {
      this.data.item['_stateRemove'] = '';
    }
    this.close();
  }

  delete(): void {
    this.processing = true;
    if (typeof (this.data.delete) === 'function') {
      this.data.delete();
    }
    this.close();
  }

  @HostListener("keydown.esc")
  onEsc() {
    this.close();
  }

}
