import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of as observableOf, } from 'rxjs';

import { Participant, IParticipant } from '../participants/participant';

import { BaseService } from '@board/_services/base-service.service';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Project } from '@board/items/project/project';
import { Task } from '@board/items/task/task';
import { map, catchError, filter } from 'rxjs/operators';
import { RequestCacheService } from 'app/_services/request-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService extends BaseService {

  /**
   * ui- show to user in dropdown; its a way of filtering what to show in UI
   */
  static readonly PERMISSION_MAP: Array<{ level: string, ui: boolean, alias: string, weight: number, default?: boolean }> = [
    { level: 'level-0', ui: true, alias: 'Co-Owner', weight: 100 },
    { level: 'level-1', default: true, ui: true, alias: 'Super Admin', weight: 80 },
    { level: 'level-2', ui: false, alias: 'Admin', weight: 60 },
    { level: 'level-3', ui: false, alias: 'Read + Write + Comments', weight: 40 },
    { level: 'level-4', ui: true, alias: 'User', weight: 20 },
    { level: 'level-5', ui: false, alias: 'Read Only', weight: 10 }
  ];

  static hasLevelOrAboveRights(level: string, participant: IParticipant) {
    let adminWeight = ParticipantService.PERMISSION_MAP.find(pm => pm.level === level).weight;
    let participantWeight = ParticipantService.PERMISSION_MAP.find(pm => pm.level === participant.AccessType).weight;
    return adminWeight && participantWeight && participantWeight >= adminWeight;
  }

  private _participants: BehaviorSubject<Participant[]>;
  private _participant: BehaviorSubject<Participant>;
  private dataStore: {
    coOwners: any
    superAdminsOrAbove: any
    participants: any
  }
  private _loadingState: BehaviorSubject<number>;
  private _processingState: BehaviorSubject<number>;
  private _endPoint: string;
  error: any;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private request: RequestCacheService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/participants`;
    this.dataStore = { participants: [], coOwners: [], superAdminsOrAbove: [] };
    this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
    this._participants = <BehaviorSubject<Participant[]>>new BehaviorSubject(null);
    this._participant = <BehaviorSubject<Participant>>new BehaviorSubject(null);
  }

  getDefaultAccessLevel() {
    for (let p of ParticipantService.PERMISSION_MAP) {
      if (p.default) {
        return p.level;
      }
    }
    return 'level-5';
  }

  get loadingState$(): Observable<number> {
    return this._loadingState.asObservable();
  }
  get processingState$(): Observable<number> {
    return this._processingState.asObservable();
  }
  resetProcessingState$(): Observable<number> {
    this._processingState.next(-1);
    return this.processingState$;
  }

  get participants$(): Observable<Participant[]> {
    return this._participants.asObservable();
  }
  get participant$(): Observable<Participant> {
    return this._participant.asObservable();
  }
  get participant(): Participant {
    return this._participant.getValue();
  }


  loadAll(project: Project) {
    if (!project) return;

    if (this.inStorage(project)) {
      this.fetch(project);
      return;
    }

    this._loadingState.next(ParticipantService.STATES.LOADING);
    this._load(project)
      .subscribe(
        data => {
          this.fetch(project);
        },
        error => {
          console.log('Could not load participants');
          this._loadingState.next(ParticipantService.STATES.ERROR);
          this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_LIST);
        }
      )
  }

  loadCoOwners(project: Project) {
    if (!!this.dataStore.coOwners[project.Id]) {
      return observableOf(this.dataStore.coOwners[project.Id]);
    }

    return this._load(project, 'coOwners')
  }

  loadSuperAdminsOrAbove(project: Project) {
    if (!!this.dataStore.superAdminsOrAbove[project.Id]) {
      return observableOf(this.dataStore.superAdminsOrAbove[project.Id]);
    }

    return this._load(project, 'superAdminsOrAbove')
  }

  /**
   * @param project 
   * @param paramsObj 
   */
  private _load(project: Project, type: string = 'participants') {
    const key = `${type}-${project.Id}`;
    if (this.request.inCache(key)) {
      return this.request.get(key);
    }
    this.request.cache(key);

    let paramsObj = {
      'filter[ProjectId]': project.Id
    };
    switch (type) {
      case 'coOwners':
        paramsObj['filter[AccessType]'] = ['level-0'];
        break;
      case 'superAdminsOrAbove':
        paramsObj['filter[AccessType][]'] = ['level-0', 'level-1'];
        break;
    }

    let params = new HttpParams({
      fromObject: paramsObj
    });
    return this.http.get(this._endPoint, { params: params }).pipe(
      map(data => {
        this.dataStore[type][project.Id] = [];
        for (let key in data) {
          let participant = data[key];
          if (participant.ProjectId === project.Id) {
            this.dataStore[type][project.Id].push((new Participant(participant)).init())
          }
        };
        return this.request.done(key, this.dataStore[type][project.Id]);
      }))
  }

  create(participant: Participant, project: Project) {
    participant._error = null;
    participant._state = ParticipantService.STATES.PROCESSING;
    this._processingState.next(ParticipantService.STATES.PROCESSING);
    this.http.post(this._endPoint, participant.toDb())
      .subscribe(data => {
        let p = new Participant(data).init();

        if (!p._error) {
          this.addUI(p, project.Id);
          if (participant._assign) {
            project.AssigneeId = data['Id'];
          }
        }

        this._participant.next(p);
        participant._state = ParticipantService.STATES.DONE;
        this._processingState.next(ParticipantService.STATES.DONE);
        this.fetch(project);
      },
        error => {
          console.log('Could not create participant.');
          if (error.status === 401) {
            participant._state = ParticipantService.STATES.UNAUTHORIZED;
            this._processingState.next(ParticipantService.STATES.UNAUTHORIZED);
          } else {
            participant._state = ParticipantService.STATES.ERROR;
            this._processingState.next(ParticipantService.STATES.ERROR);
          }
          this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_CREATE);
        }
      )
  }

  resend(participant: Participant, project: Project) {
    participant._error = null;
    participant._state = ParticipantService.STATES.RESENDING;
    this._processingState.next(ParticipantService.STATES.RESENDING);
    this.http.get(`${this._endPoint}/${participant.Id}/resend-invitation`)
      .subscribe(data => {
        participant.Confirmation = data['Confirmation'];
        participant.UpdateDate = data['UpdateDate'];
        this.updateUI(participant, project.Id);

        this._participant.next(participant);
        this.fetch(project);

        participant._state = ParticipantService.STATES.DONE;
        participant.resend.done = true;
        // this._processingState.next(ParticipantService.STATES.DONE);
      },
        error => {
          console.log('Could not resend email to participant.');
          if (error.status === 401) {
            participant._state = ParticipantService.STATES.UNAUTHORIZED;
            this._processingState.next(ParticipantService.STATES.UNAUTHORIZED);
          } else {
            participant._state = ParticipantService.STATES.ERROR;
            this._processingState.next(ParticipantService.STATES.ERROR);
          }
          this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
        }
      )
  }

  update(participant: Participant, project: Project) {
    participant._state = ParticipantService.STATES.PROCESSING;
    this._processingState.next(ParticipantService.STATES.PROCESSING);
    this.http.put(`${this._endPoint}/${participant.Id}`, participant.toDb())
      .subscribe(data => {
        for (let prop in data) {
          participant[prop] = data[prop];
        }
        // let uParticipant = (new Participant(data)).init();
        this.updateUI(participant, project.Id);
        // this._participant.next(participant);

        participant._state = ParticipantService.STATES.DONE;
        this._processingState.next(ParticipantService.STATES.DONE);
        this.fetch(project);
      },
        error => {
          console.log('Could not update participant.');
          if (error.status === 401) {
            participant._state = ParticipantService.STATES.UNAUTHORIZED;
            this._processingState.next(ParticipantService.STATES.UNAUTHORIZED);
          } else {
            if (error.status === 403) {
              this.alertService.warning("You are not allowed to change/affect an equal participant.", ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
            } else {
              this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
            }
            participant._state = ParticipantService.STATES.ERROR;
            this._processingState.next(ParticipantService.STATES.ERROR);
          }
        }
      )
  }

  remove(participant: Participant, project: Project) {
    this._processingState.next(ParticipantService.STATES.PROCESSING);
    participant._state = ParticipantService.STATES.PROCESSING;
    participant._stateRemove = 'remove';

    this.http.delete(`${this._endPoint}/${participant.Id}`)
      .subscribe(data => {
        this.removeUI(participant);

        this._processingState.next(ParticipantService.STATES.DONE);
        this.fetch(project);
      },
        error => {
          console.log('Could not remove project.');
          if (error.status === 401) {
            this._processingState.next(ParticipantService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(ParticipantService.STATES.ERROR);
          }
          this.alertService.snackError(error);
        }
      )
  }

  confirm(participant: Participant, project: Project): Observable<Participant> {
    participant._state = ParticipantService.STATES.PROCESSING;
    return this.http.put(`${this._endPoint}/${participant.Id}/confirm`, {
      ProjectId: project.Id,
      Confirmation: participant.Confirmation,
      ConfirmationNote: participant.ConfirmationNote,
      UpdateDate: participant.UpdateDate,
    }).pipe(
      map(data => {
        project._participant.UpdateDate = data['UpdateDate'];
        project._participant.Confirmation = data['Confirmation'];

        participant._state = ParticipantService.STATES.DONE;
        return project._participant;
      }), catchError(error => {
        console.log('Could not create participant.');
        if (error.status === 401) {
          participant._state = ParticipantService.STATES.UNAUTHORIZED;
        } else {
          participant._state = ParticipantService.STATES.ERROR;
        }
        this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
        return observableOf(null);
      })
    )
  }

  assign(participant: Participant, currentItem: Project | Task, project: Project) {
    participant._state = ParticipantService.STATES.PROCESSING;
    let payload = {};
    if (currentItem instanceof Project) {
      payload = { ProjectId: currentItem.Id };
    } else if (currentItem instanceof Task) {
      payload = { TaskId: currentItem.Id };
    }
    this.http.put(`${this._endPoint}/${participant.Id}/assign`, payload)
      .subscribe(
        data => {
          currentItem.__update({ AssigneeId: participant.Id });
          participant._state = ParticipantService.STATES.DONE;
          this.fetch(project);
        },
        error => {
          console.log('Could not assign participant.');
          participant._state = ParticipantService.STATES.ERROR;
          this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
        }
      )
  }

  unassign(participant: Participant, currentItem: Project | Task, project: Project) {
    participant._state = ParticipantService.STATES.PROCESSING;
    let payload = {};
    if (currentItem instanceof Project) {
      payload = { ProjectId: currentItem.Id };
    } else if (currentItem instanceof Task) {
      payload = { TaskId: currentItem.Id };
    }
    this.http.put(`${this._endPoint}/${participant.Id}/unassign`, payload)
      .subscribe(
        data => {
          currentItem.__update({ AssigneeId: null });
          participant._state = ParticipantService.STATES.DONE;
          this.fetch(project);
        },
        error => {
          console.log('Could not unassign participant.');
          participant._state = ParticipantService.STATES.ERROR;
          this.alertService.error(error, ServiceAlertClass.ALERTS.PARTICIPANT_UPDATE);
        }
      )
  }

  private inStorage(project: Project) {
    return project && !!this.dataStore.participants[project.Id];
  }

  private fetch(project: Project) {
    let iParticipants: Array<Participant> = Object.assign({}, this.dataStore).participants[project.Id];
    this._participants.next(this.sort(iParticipants));

    if (iParticipants.length > 0) {
      this._loadingState.next(ParticipantService.STATES.DONE);
    } else {
      this._loadingState.next(ParticipantService.STATES.EMPTY);
    }
  }

  private addUI(participant: Participant, projectId?: string) {
    projectId || (projectId = participant.ProjectId)
    if (this.dataStore.participants[projectId]) {
      this.dataStore.participants[projectId].splice(0, 0, participant);
    }
    return this;
  }
  private removeUI(participant: Participant, projectId?: string) {
    projectId || (projectId = participant.ProjectId)
    if (this.dataStore.participants[projectId]) {
      this.dataStore.participants[projectId].forEach((t, i) => {
        if (t.Id === participant.Id) {
          this.dataStore.participants[projectId].splice(i, 1);
        }
      });
    }
    return this;
  }
  private updateUI(participant: Participant, projectId?: string) {
    projectId || (projectId = participant.ProjectId)
    if (this.dataStore.participants[projectId]) {
      this.dataStore.participants[projectId].forEach((t, i) => {
        if (t.Id === participant.Id) {
          this.dataStore.participants[projectId][i] = participant;
        }
      });
    }
    return this;
  }

  private sort(participants: Array<Participant>): Array<Participant> {
    let accepted: Array<Participant> = [];
    let pending: Array<Participant> = [];
    let declined: Array<Participant> = [];
    let noaccount: Array<Participant> = [];
    let removedAccount: Array<Participant> = [];

    participants.forEach(p => {
      if (!p._account.Id) {
        noaccount.push(p);
      } else if (p._account.Id && p._account.Status === 'deleted') {
        removedAccount.push(p);
      } else {
        switch (p.Confirmation) {
          case 'yes': accepted.push(p); break;
          case 'pending': pending.push(p); break;
          case 'no': declined.push(p); break
        }
      }
    })

    let compareFn = (p1: Participant, p2: Participant): number => {
      if (p1._account && p2._account) {
        let name1 = p1._account.OwnerFirstName.toLocaleLowerCase();
        let name2 = p2._account.OwnerFirstName.toLocaleLowerCase()

        return name1 < name2 ? -1 : (name1 > name2 ? 1 : 0)
      }

      return 1;
    }

    accepted.sort(compareFn);
    pending.sort(compareFn);
    declined.sort(compareFn);

    return accepted.concat(pending).concat(declined).concat(noaccount).concat(removedAccount);
  }

  /**
   * 
   * @param id
   * @param project 
   */
  identifyParticipant(id: string, project: Project): Observable<Participant> {
    if (!project) {
      return observableOf(null);
    }

    if (this.inStorage(project)) {
      return observableOf(this.dataStore.participants[project.Id].find(p => p.Id === id));
    }

    return this._load(project).pipe(
      filter(participants => !!participants),
      map(participants => {
        return participants.find(p => p.Id === id);
      }),
      catchError(error => {
        return observableOf(null);
      })
    )
  }

}