import { File, IFile } from "@panel-components/notes/attachments";
import { generateId } from "../generate_block_id";
import { IUIBlock } from "../ui-block.interface";
import { UIBlockAction, UIBlockType, UI_BLOCK_PREFIX } from "../types";

export interface IUIAttachmentsContent {
    imageOrder: string[],
    files: IFile[]
}

export class UIAttachmentsBlock implements IUIBlock {
    Type: UIBlockType = UIBlockType.ATTACHMENTS;
    Id: string;
    OptionId: string;
    BlockId: string;
    Position: number;
    CreateDate: string;
    UpdateDate: string;

    private _content: IUIAttachmentsContent;
    set Content(data: IUIAttachmentsContent) {
        this._content = {
            imageOrder: data.imageOrder || [],
            files: (data.files || []).map((fData: IFile) => {
                return new File(fData);
            })
        }
    }
    get Content(): IUIAttachmentsContent {
        return this._content;
    }

    // annoing workaround to make it compliant with other models with attachments Note, Comment
    get Settings() {
        return this._content;
    }
    set Settings(data) {
        this.Content = data;
    }

    _httpAction: UIBlockAction = UIBlockAction.UPDATE;

    _state: string;

    constructor(data: any = {}) {
        data = { BlockId: generateId(`${UI_BLOCK_PREFIX.ATTACHMENTS}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            OptionId: this.OptionId,
            Type: this.Type,
            Content: {
                // store to db only the images order
                imageOrder: this.Content.imageOrder
            },
            Position: this.Position,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            OptionId: this.OptionId,
            Type: this.Type,
            Content: this.Content,
            Position: this.Position,
            Settings: this.Settings,
            _httpAction: this._httpAction
        }
    }

}
