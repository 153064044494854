
import { map, catchError, take } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from '@board/_services';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Note } from '@panel-components/notes/note';
import { Comment } from '@panel-components/comments/comment.model';

import { ReactionTypes } from './reactions.enum';
import { ReactionData } from './modals/reaction-data.model';
import { NoteReaction, CommentReaction } from './reaction.model';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { ITaskV2BlocksMetadata, TaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';


interface IReactionListOptions {
  item: Note | Comment | TaskV2BlocksMetadata,
  type: ReactionTypes,
  limit?: number,
  offset?: number
}


@Injectable()
export class ReactionsService extends BaseService {

  private _endPoint: string;

  private _reactions: BehaviorSubject<Array<ReactionData>>;
  get reactions$(): Observable<Array<ReactionData>> {
    return this._reactions.asObservable();
  }

  private dataStore = {
    reactions: {},
    total: {},
  }
  options: IReactionListOptions;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private ga: GoogleAnalyticsService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/reactions`;
  }


  get total(): number {
    let key = this.key();
    return this.dataStore.total[key];
  }

  private key(options: IReactionListOptions = null): string {
    options || (options = this.options)
    const id = options.item instanceof TaskV2BlocksMetadata ? options.item.taskv2Id : options.item.Id;
    if (!options.type) {
      return `${id}_all`;
    }
    return `${id}_${options.type}`;
  }


  getReactions(options: IReactionListOptions) {
    if (!options) return;
    this.options = options;

    if (this.inStorage()) {
      return observableOf(this.getFromStorage());
    }

    let params = new HttpParams({
      fromObject: this.payload(options)
    });

    return this.http.get(this._endPoint, { params: params, observe: 'response' }).pipe(
      take(1),
      map(response => {
        if (options.offset === 0) {
          this.initStorage(options);
        }
        let key = this.key(options);
        let data = response.body;
        let headers = response.headers as HttpHeaders;
        this.dataStore.total[key] = parseInt(headers.get('X-Items-Count'), 10) || 0;
        for (let key in data) {
          this.pushToStorage(new ReactionData(data[key]));
        };

        return this.getFromStorage();
      }), catchError(error => {
        console.log('Could not load reactions');
        // this.alertService.error(error, ServiceAlertClass.ALERTS.NOTE_LIST);
        return observableOf(null);
      })
    )
  }

  private payload(options: IReactionListOptions) {
    let payload = {
      'limit': (options.limit || 15).toString(),
      'offset': (options.offset || 0).toString(),
    }
    if (options.item instanceof TaskV2BlocksMetadata) {
      payload['filter[TaskV2Id]'] = options.item.taskv2Id;
    } else if (options.item instanceof Comment) {
      payload['filter[CommentId]'] = options.item.Id;
    } else {
      payload['filter[NoteId]'] = options.item.Id;
    }

    if (options.type) {
      payload['filter[Type]'] = options.type;
    }
    return payload;
  }


  react(item: Note | Comment | ITaskV2BlocksMetadata, reaction: NoteReaction | CommentReaction, requestOptions = {}) {
    let request: Observable<any>;
    let action = 'update';
    let itemTable = item instanceof Comment ? 'comment' : 'note';

    let endpoint = this._endPoint;
    if  (reaction.AnonymousId) {
      endpoint = `${this.config.endpoint}/store/reactions`;
    }

    if (!reaction.Id) {
      action = 'create';
      request = this.http.post(endpoint, reaction, requestOptions);
    } else {
      request = this.http.put(`${endpoint}/${itemTable}/${reaction.Id}`, reaction, requestOptions);
    }

    return request.pipe(
      map(data => {
        if (action === 'create') {
          item.addReaction(data);
        } else {
          item.updateReaction(data);
        }
        this.ga.platformEvent('reaction', `${reaction.Type}`);
      }), catchError(error => {
        console.log('cannot react')
        return observableOf(null);
      })
    )
  }

  deleteReaction(item: Note | Comment | ITaskV2BlocksMetadata, reaction: NoteReaction | CommentReaction, requestOptions = {}) {
    let itemTable = item instanceof Comment ? 'comment' : 'note';
    let endpoint = this._endPoint;
    if  (reaction.AnonymousId) {
      endpoint = `${this.config.endpoint}/store/reactions`;
    }
    return this.http.delete(`${endpoint}/${itemTable}/${reaction.Id}`, requestOptions).pipe(
      map(data => {
        item.deleteReaction(reaction);
      }), catchError(error => {
        console.log('cannot delete reaction')
        return observableOf(null);
      })
    )
  }

  private initStorage(options: IReactionListOptions) {
    let key = this.key(options);
    if (!this.dataStore.reactions) {
      this.dataStore.reactions = {}
      this.dataStore.total = {}
    }
    this.dataStore.reactions[key] = [];
    this.dataStore.total[key] = 0;
  }


  private inStorage() {
    let key = this.key();
    if (!this.dataStore.reactions || !this.dataStore.reactions[key]) {
      return false;
    }

    if (this.dataStore.reactions[key].length < this.options.limit + this.options.offset && this.dataStore.reactions[key].length < this.dataStore.total[key]) {
      return false
    }

    return !!this.dataStore.reactions[key]
  }

  private pushToStorage(reaction: ReactionData, inFront: boolean = false) {
    let key = this.key();
    if (inFront) {
      this.dataStore.reactions[key].splice(0, 0, reaction);
    } else {
      this.dataStore.reactions[key].push(reaction);
    }
    return this;
  }


  private getFromStorage() {
    let key = this.key();
    let n = Object.assign({}, this.dataStore).reactions[key].slice(0, this.options.limit + this.options.offset);
    return n;
  }

  private emit() {
    this._reactions.next(this.getFromStorage());
  }





}