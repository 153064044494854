<div class="board-navigation-panel">
    <modal-list class="mgc-container"
        [selectedModal]="modalInView"
        (modalSelected)="selectModalInView($event)"
    (modalLoading)="loading=$event;"></modal-list>
</div>
<div class="board-content-panel" [class.hide]="!modalInView">
    <modal-factory *ngIf="modalInView" class="mgc-container" (modalDeleted)="selectModalInView(null)" [modal]="modalInView">
    </modal-factory>
</div>
<div class="board-content-panel" *ngIf="!modalInView" >
    <div class="mgc-panel-body mgc-vcard">
        <div class="start-text show">
            <div>
                <h3>Welcome to Modal tools!</h3>
                <p>This tool allowing you manage modals. Please select a modal from the left list.</p>
            </div>
        </div>
        <div class="board-bg-image filtered"></div>
    </div>
</div>
