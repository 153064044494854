import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ConfirmOverlayRef } from './confirm-overlay-ref';

@Component({
  selector: 'confirm-overlay',
  styleUrls: ['./confirm-overlay.component.scss'],
  templateUrl: './confirm-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ConfirmOverlayComponent {
  @Input() text = '';

  constructor(
    private confirmOverlayRef: ConfirmOverlayRef,
  ) {
  }

  yes() {
    this.confirmOverlayRef.close(true);
  }

  no() {
    this.confirmOverlayRef.close(false);
  }
}
