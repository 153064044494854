<div class="survey-inner">

  <survey #survey (close)="onClose($event)"></survey>

  <div class="mgc-survey-footer" *ngIf="survey.ready">
    <div class="survey-buttons">
      <button type="button" class="survey-button left" *ngIf="survey.hasPrev"
        (click)="survey.prev()"><span>Previous</span></button>
      <button type="button" class="survey-button right" *ngIf="survey.hasNext && survey.allowSkip && !survey.error"
        (click)="survey.next()"><span>Skip</span></button>
      <button type="button" class="survey-button right" *ngIf="survey.error"
        (click)="onClose()"><span>Close</span></button>
    </div>
  </div>
</div>