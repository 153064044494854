import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfPreviewComponent } from './pdf-preview.component';
import { FileDetailsModule } from '@libraries/gallery/file-details/file-details.module';
import { SafePipesModule } from '@rallysite/global-pipes';

import { MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        FileDetailsModule,
        SafePipesModule
    ],
    declarations: [
        PdfPreviewComponent,
    ],
    exports: [PdfPreviewComponent]
})
export class PdfPreviewModule { }
