<form [formGroup]="participantDataForm">
  
  <mgc-form-field label="Participant Email *" [invalid]="submitted && f.Email.errors">
    <div class="field-wrapper">
      <input tabindex="-1" formControlName="Email" autocomplete="email">
      <span class="placeholder" *ngIf="!f.Email.value">Participant Email</span>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.Email.errors">
      <div *ngIf="f.Email.errors.required">Email is required</div>
      <div *ngIf="f.Email.errors.email">Email must be a valid email address</div>
    </ng-container>
  </mgc-form-field>

  <mgc-form-field label="Drop a note for new participant" multiline>
    <div class="field-wrapper">
      <textarea tabindex="-1" rows="2" name="InviterNote" formControlName="InviterNote"></textarea>
    </div>
  </mgc-form-field>

  <mgc-form-field label="New participant permission level">
    <mat-icon svgIcon="board-mat:perm_identity"></mat-icon>
    <div class="field-wrapper">
      <mat-select formControlName="AccessType" name="accessType">
        <mat-option *ngFor="let permission of permissionsMap | uiPermission" [value]="permission.level">
          <span [innerHTML]="permission.alias"></span>
        </mat-option>
      </mat-select>
    </div>
  </mgc-form-field>

  <div class="mgc-form-control" [style.margin-top.px]="-10">
    <mgc-information>
      All project participants with
      <strong>Read + Comments</strong> access and below will not be able to see
      <strong>Participants</strong> panel content inside this project
    </mgc-information>
  </div>

  <div class="mgc-form-control">
    <mat-slide-toggle class="mgc-toggle form-control" formControlName="_assign" [checked]="f._assign.value"
      (change)="toggleAssign($event)">
      Assign new participant to the current project
    </mat-slide-toggle>
  </div>

</form>