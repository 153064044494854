
import {of as observableOf, throwError as observableThrowError, Observable, BehaviorSubject, throwError} from 'rxjs';

import { map, catchError, filter, finalize, take, switchMap, mergeMap } from 'rxjs/operators';
import { Injectable, Inject, EventEmitter, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { JwtHelperService } from './token';
import { IUserService, USER_SERVICE } from './defaults';
import { PlatformProcessingOverlayService } from './platform-processing/platform-processing-overlay.service';
import { PlatformProcessingOverlayRef } from './platform-processing/platform-processing-overlay-ref';
import { WINDOW } from '@rallysite/global-services';
import { PageService } from '@pages/page.service';
import { PERSONAL_PAGES, PUBLIC_PAGES } from '@pages/pages.enum';
import { isPlatformBrowser } from '@angular/common';
import { User } from '@rallysite/user';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService,
    private jwtHelper: JwtHelperService,
    private processingOverlay: PlatformProcessingOverlayService,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(USER_SERVICE) private uService: IUserService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.redirectUrl = null;
    this.authUrl = null;

    this.localStorage = this.window.localStorage ? this.window.localStorage : {
      getItem: function () { return null; },
      setItem: function () { },
      removeItem: function () { }
    };

  }

  get token(): string {
    return this.jwtHelper.tokenGetter();
  }

  get isAuthenticated(): boolean {
    return this.token && !this.jwtHelper.isTokenExpired();
  }

  get anonymousId() {
    let anonymous = this.localStorage.getItem('uuid-token');
    if (!anonymous) {
      anonymous = this.create_UUID();
      this.localStorage.setItem('uuid-token', anonymous);
    }
    return anonymous;
  }


  // isAuthenticated$(fnTrue: Function, fnFalse: Function): Observable<boolean> {
  //   if (this.isAuthenticated) {
  //     return observableOf(fnTrue());
  //   };

  //   return this.refreshToken().pipe(
  //     map((token: string) => {
  //       if (this.isAuthenticated) {
  //         return fnTrue();
  //       }

  //       return fnFalse();
  //     }),
  //     catchError(error => {
  //       return observableOf(fnFalse());
  //     }))
  // }


  // authenticate(value: boolean) {
  //   if (this._isAuthenticated === value) {
  //     return;
  //   }

  //   this._isAuthenticated = value;
  //   if (this._isAuthenticated) {
  //     this.uService.loadUser();
  //   } else {
  //     localStorage.removeItem('cp_user');
  //     this.uService.removeUser();
  //   }
  // }

  set authUrl(value: UrlSegment) {
    this._authUrl = value || new UrlSegment('/auth', {});
  }
  get authUrl(): UrlSegment {
    return this._authUrl;
  }

  set redirectUrl(value: UrlSegment) {
    this._redirectUrl = value || new UrlSegment('/', {});

  }
  get redirectUrl(): UrlSegment {
    return this._redirectUrl;
  }

  /** UserService  methods / properties */
  get user$(): Observable<any> {
    return this.uService.user$;
  }

  private isRefreshingToken = false;

  private _redirectUrl: UrlSegment;
  private _authUrl: UrlSegment;

  private tokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private crossUser$: BehaviorSubject<User> = <BehaviorSubject<User>>new BehaviorSubject<User>(null);
  private crossUserLoading = false;
  private crossUserResolved = false;
  crossUserEmail: string;

  private ehUser$: BehaviorSubject<User> = <BehaviorSubject<User>>new BehaviorSubject<User>(null);
  private ehUserLoading = false;
  private ehUserResolved = false;
  needToSigIn: EventEmitter<boolean> = new EventEmitter();

  newUserRegistered: { email: string, message: string } = {
    email: '',
    message: ''
  };

  resetPasswordDone: { email: string, message: string } = {
    email: '',
    message: ''
  };

  cachedRequests: Array<HttpRequest<any>> = [];

  localStorage: any;
  crossUserRegister: BehaviorSubject<{ email: string; redirect?: boolean }> =
    <BehaviorSubject<{ email: string; redirect?: boolean }>>new BehaviorSubject(null);

  tokenGetter: () => string = this.jwtHelper.tokenGetter;
  private crossUserSubject() {
    return this.crossUser$.pipe(
      filter(user => user != null),
      take(1),
      map(user => {
        return user instanceof User ? user : null;
      }));
  }
  private ehUserSubject() {
    return this.ehUser$.pipe(
      filter(user => user != null),
      take(1),
      map(user => {
        return user instanceof User ? user : null;
      }));
  }

  setSocketId(value: string) {
    return this.jwtHelper.socketId = value;
  }

  private create_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  authenticate(refresh = true): Observable<any> {

    // we do this only in browser; otherwise the token will be considered
    // used since on server there will be another request which will consume the token
    if (isPlatformBrowser(this.platformId)) {

      if (this.crossUserLoading || this.crossUserResolved) {
        return this.crossUserSubject();
      }

      const qp = this.router.getCurrentNavigation() ?
        this.router.getCurrentNavigation().extractedUrl.queryParams :
        this.route.snapshot.queryParams;

      // authenticate with cross token
      if (qp['token']) {
        const sjwt = qp['token'];
        this.crossUserLoading = true;
        return this.crossTokenAuth(sjwt).pipe(
          finalize(() => {
            this.crossUserLoading = false;

          }),
          map((user: User) => {
            this.crossUserResolved = !!user;
            this.crossUser$.next(user instanceof User ? user : <User>{});
            return user;
          })
        );
      }

      if (this.ehUserLoading || this.ehUserResolved) {
        return this.ehUserSubject();
      }

      // authenticate with email hash
      if (qp['eh']) {
        this.ehUserLoading = true;
        return this.emailHashAuth(qp['eh']).pipe(
          finalize(() => {
            this.ehUserLoading = false;
            this.ehUserResolved = true;
          }),
          map((user: User) => {
            this.ehUser$.next(user instanceof User ? user : <User>{});
            return user;
          })
        );
      }
    }

    if (this.isAuthenticated) {
      return this.loadUser();
    }

    if (!refresh) {
      return observableOf(null);
    }

    return this.refreshToken().pipe(
      mergeMap((token: string) => {
        if (this.isAuthenticated) {
          return this.loadUser();
        }
        return observableOf(null);
      }),
      catchError(error => {
        return observableOf(null);
      }));
  }

  crossTokenAuth(sjwt: string) {

    this.cleanUser();

    const url = new URL(this.window.location.href);
    // if token will not be handled in DASHBOARD
    if (url.pathname.indexOf(PERSONAL_PAGES.DASHBOARD.path) < 0) {
      // remove token from link
      this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
    }

    const payload = { sjwt: sjwt };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/store/crossuser`, JSON.stringify(payload), { headers: headers }).pipe(
      mergeMap((response ) => {
        const data = response;
        this.crossUserEmail = data['email'];

        if (data['message'] === 'no-user') {
          this.crossUserRegister.next({ email: data['email'] });
        } else {
          this.parseTokenData(data);
          if (this.isAuthenticated) {
            return this.loadUser();
          }
        }
        return observableOf(null);
      }),
      catchError(error => {
        return observableOf(null);
      }));
  }

  /**
   *
   * @param hash
   * @returns
   */
  emailHashAuth(hash: string) {
    this.cleanUser();

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/elogin/${hash}`, { hash: hash }, { headers: headers }).pipe(
      mergeMap((response: any) => {
        const data = response;
        if (data['access_token']) {
          this.parseTokenData(data);
          if (this.isAuthenticated) {
            return this.loadUser();
          }
        }
        return observableOf(null);
      }),
      catchError(error => {
        return observableOf(null);
      }));
  }
  loadUser(): Observable<any> {
    return this.uService.loadUser();
  }
  isAdminUser$(): Observable<boolean> {
    return this.uService.isAdmin$();
  }
  allowScheduledEmails$(): Observable<boolean> {
    return this.uService.allowScheduledEmails$();
  }
  canAccessEmailsDashboard$(): Observable<boolean> {
    return this.uService.allowScheduledEmails$();
  }
  /**  */

  collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  register_step1(user: any): Observable<boolean> {
    const payload = {
      email: user.email,
    };
    return this.register(payload, 'step1');
  }

  register_step2(user: any): Observable<boolean> {
    const payload = {
      id: user.id,
      password: user.password,
      password_confirmation: user.cpassword
    };
    return this.register(payload, 'step2');
  }

  register_activate(user: any): Observable<boolean> {
    const payload = {
      email: user.email,
      acode: user.acode
    };
    return this.register(payload, 'activate');
  }

  register_resend_code(email: string): Observable<boolean> {
    const payload = {
      email: email,
    };
    return this.register(payload, 'resend');
  }


  checkEmail(email: string) {
    const payload = { email: email };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/register/email`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        return response.status === 200;
      }), catchError(error => {
        return observableOf(null);
      }));
  }

  register_with_email(user: any): Observable<any> {
    const payload = {
      email: user.email,
      agree: user.agree
    };
    return this.register(payload, 'step_email');
  }

  prepareRegisterWithEmailAndPassword(user: any): Observable<any> {
    const payload = {
      email: user.email,
      password: user.cpassword
    };
    return this.registerWithEmailAndPassword(payload);
  }


  private register(payload, step) {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/register/${step}`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }), catchError(error => {

        if (error.status === 400) {
          return throwError('The user already exists, you should use the Sign in instead button to login');
        }

        return throwError(error);
      }));
  }

  private registerWithEmailAndPassword(payload) {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/register/email_and_password`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        return response;
      }));
  }

  confirm(confirmationCode: string, accountId: string): Observable<boolean> {
    const payload = {
      grant_type: this.config.grantType,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/confirm/${confirmationCode}/${accountId}`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }));
  }

  verifyInvite(participantId: string, email: string): Observable<boolean> {
    const payload = {
      grant_type: this.config.grantType,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
      email: email.replace(/_at_/g, '@').replace(/_dot_/g, '.')
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/verify-invite/${participantId}`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }));
  }

  verifyEmail(email: string): Observable<boolean> {
    const payload = {
      grant_type: this.config.grantType,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
      email: email.replace(/_at_/g, '@').replace(/_dot_/g, '.')
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/verify-email`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }));
  }

  sendResetLink(userEmail: string): Observable<boolean> {
    const payload = {
      email: userEmail
    };
    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/reset`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }));
  }

  resetPassword(user: { string, email: string, password: string, cpassword: string, token: string }): Observable<boolean> {
    const payload = {
      // grant_type: this.config.grantType,
      // client_id: this.config.clientId,
      // client_secret: this.config.clientSecret,
      email: user.email,
      password: user.password,
      password_confirmation: user.cpassword,
      token: user.token
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/password/reset`, JSON.stringify(payload), { headers: headers }).pipe(
      map((response: any) => {
        const data = response;
        return data;
      }));
  }

  refreshToken(): Observable<string> {

    // making sure this will get called only in browser
    if (!isPlatformBrowser(this.platformId)) {
      return observableOf(null);
    }

    // if from any reason there is a valid token than ignore any further action
    // due to many concurent auth requests there might be an auth request that fails and tries to refresh the access_token
    // meanwhile if a crossUser or emailUser auto-auth or any other previous refresh action already finished, than we stop here
    if (this.isAuthenticated) {
      return observableOf(this.token);
    }

    const refreshToken: string = this.localStorage.getItem('refresh_token');
    if (!refreshToken) {
      this.cleanUser();
      return observableOf(null);
    }

    if (this.isRefreshingToken) {
      console.log(['There is ALREADY A refreshToken REQUEST; waiting']);

      return this.tokenSubject$.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return observableOf(token);
        }));
    }

    const processingDialogRef: PlatformProcessingOverlayRef = this.processingOverlay.open();

    this.isRefreshingToken = true;
    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    this.tokenSubject$.next(null);

    const payload = {
      grant_type: 'refresh_token',
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
      refresh_token: refreshToken
    };

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/refresh`, JSON.stringify(payload), { headers: headers }).pipe(
      finalize(() => {
        processingDialogRef.close();
        this.isRefreshingToken = false;
      }),
      map((response: any) => {
        const data = response;

        const access_token = data && data.access_token;
        if (access_token) {
          this.localStorage.setItem('access_token', access_token);
        } else {
          this.localStorage.removeItem('access_token');
        }

        const refresh_token = data && data.refresh_token;
        if (refresh_token) {
          this.localStorage.setItem('refresh_token', refresh_token);
        } else {
          this.localStorage.removeItem('refresh_token');
        }

        this.tokenSubject$.next(access_token);
        return access_token;
      }),
      catchError(error => {
        console.log('ISSUES WITH REFRESHED TOKEN');
        this.localStorage.removeItem('refresh_token');
        this.localStorage.removeItem('access_token');

        return observableOf(null);
      }));
  }

  login(user: {
    email: string,
    password: string,
    additionalEmail: string,
    remember: boolean
  }): Observable<any> {
    const payload = {
      grant_type: this.config.grantType,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
      username: user.email,
      password: user.password
    };

    if (user.additionalEmail) {
      payload['additionalEmail'] = user.additionalEmail;
    }

    const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.config.endpoint}/login`, JSON.stringify(payload), { headers: headers }).pipe(
      switchMap((response: any) => {
        const data = response || {};

        if (data.access_token) {
          this.localStorage.setItem('access_token', data.access_token);
        }

        if (data.refresh_token) {
          this.localStorage.setItem('refresh_token', data.refresh_token);
        } else {
          this.localStorage.removeItem('refresh_token');
        }

        // once in login make sure we clean up intermediate steps for crosss user
        this.crossUserRegister.next(null);

        return this.authenticate();
      }));
  }

  logout(options: { redirectTo?: string } = {}): Observable<boolean> {
    if (!this.isAuthenticated) {
      console.log('no token');
      this.afterLogout(options);
      return observableOf(true);
    }

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(this.config.endpoint + '/logout', null, { headers: headers }).pipe(
      map(() => {
        // give some time to any involved component (e.g. menuOverlay) to destroy properly
        setTimeout(() => { this.afterLogout(options); }, 10);
        return true;
      }),
      catchError(error => {
        console.log(['error', error]);
        return observableOf(false);
      })
    );
  }

  private afterLogout(options: { redirectTo?: string }): void {
    // clear token remove user from local storage to log user out
    this.cleanUser();

    if (options.redirectTo) {
      switch (options.redirectTo) {
        case 'auth':
          this.goToLoginPage();
          break;
        default:
      }
    } else {
      this.pageService.activePage$.pipe(take(1)).subscribe(page => {
        const isPublicPage = this.pageService.isPublicPage(page);
        if (!isPublicPage) {
          this.router.navigate([PUBLIC_PAGES.HOME.path]);
        }
      });
    }
  }

  updateTokens(at, rt) {
    if (at) {
      this.localStorage.setItem('access_token', at);
    } else {
      this.localStorage.removeItem('access_token');
    }

    if (rt) {
      this.localStorage.setItem('refresh_token', rt);
    } else {
      this.localStorage.removeItem('refresh_token');
    }
  }

  private parseTokenData(data) {
    const access_token = data && data.access_token;
    const refresh_token = data && data.refresh_token;
    this.updateTokens(access_token, refresh_token);

    this.tokenSubject$.next(access_token);
    return access_token;
  }


  cleanUser() {
    this.localStorage.removeItem('access_token');
    this.localStorage.removeItem('refresh_token');

    this.localStorage.removeItem('cp_user');
    this.uService.removeUser();
    this.uService.signOut();
  }

  goToLoginPage() {
    // Navigate to the login page with extras
    this.router.navigate(['/auth']);

    return observableThrowError(new HttpErrorResponse({
      error: 'need authentication',
      status: 401
    }));
  }
}
