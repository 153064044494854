<mgc-dialog>

  <mgc-dialog-header title="Confirm Required" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>
    <p>You must confirm your account through by clicking the link on your confirmation email. </p>
    <p>If you can't find your confirmation email select RESEND EMAIL below and we will send you a new confirmation email
      and link.</p>
  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.ACCOUNT_UPDATE]"></alert>

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="state===states.PROCESSING" (click)="send()" tabindex="2">
      <span>Resend Mail</span>
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>





<!-- <div class="cp-modal-header">
  <h1 mat-dialog-title> Confirm Required</h1>
  <button tabindex="-1" mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="board-mat:close"></mat-icon>
  </button>
</div>

<div mat-dialog-content class="cp-dialog-content">
  <p>You must confirm your account through by clicking the link on your confirmation email. </p>
  <p>If you can't find your confirmation email select RESEND EMAIL below and we will send you a new confirmation email and link.</p>
</div>

<alert [service]="[serviceAlertClass.ACCOUNT_UPDATE]"></alert>

<div mat-dialog-actions>
  <button mat-raised-button (click)="send()" color="primary">
    <mat-spinner *ngIf="state===states.PROCESSING" color="accent" [diameter]="20" [strokeWidth]="3" style="display: inline-block; vertical-align: middle;"></mat-spinner>
    <span>Resend Email</span>
  </button>
  <button mat-button mat-dialog-close>Close</button>
</div> -->