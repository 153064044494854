export enum AlgoTagType {
    HEAD = 'head',
    GROWTH = 'growth',
    NEED = 'need',
    GENDER = 'gender',
    AGE = 'age',
    PLEVEL = 'plevel',
    THEME = 'theme',
    THEME_NEED = 'theme-need',
    THEME_GROWTH = 'theme-growth',
}
