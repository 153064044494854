import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

import { IServiceAlertClass, ServiceAlertClass } from './services-alert.class';
import { AlertSnack } from './alert.snack';
import { HttpErrorResponse } from '@angular/common/http';

export interface ISnackOptions {
    horizontalPosition?: 'left' | 'center' | 'right';
    verticalPosition?: 'top' | 'bottom';
    duration?: number;
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(
        private router: Router,
        private zone: NgZone,
        private snackBar: MatSnackBar
    ) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.clear();
                }
            }
        });
    }

    clear() {
        this.subject.next({ service: ServiceAlertClass.ALERTS.CLEAR });
    }

    success(message: string, service: ServiceAlertClass = ServiceAlertClass.ALERTS.DEFAULT, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, service: service });
    }

    warning(message: string, service: ServiceAlertClass = ServiceAlertClass.ALERTS.DEFAULT, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message, service: service });
    }

    error(errorResponse: any, service: IServiceAlertClass = ServiceAlertClass.ALERTS.DEFAULT, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: this.handleError(errorResponse, service), service: service });
    }

    snackError(errorResponse: any, service: IServiceAlertClass = ServiceAlertClass.ALERTS.DEFAULT, options: ISnackOptions = { horizontalPosition: 'left' }) {

        this.zone.run(() => {
            this.snackBar.openFromComponent(AlertSnack, {
                panelClass: ['alert-snack', 'warning'],
                horizontalPosition: options.horizontalPosition,
                data: {
                    type: 'warning',
                    message: this.handleError(errorResponse, service),
                    action: 'Close'
                }
            });
        });
    }

    snackWarning(message, options: ISnackOptions = { horizontalPosition: 'left' }) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(AlertSnack, {
                panelClass: ['alert-snack', 'warning'],
                horizontalPosition: options.horizontalPosition,
                data: {
                    type: 'warning',
                    message: message,
                    action: 'Close'
                }
            });
        });
    }

    snackSuccess(message, options: ISnackOptions = {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
    }) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(AlertSnack, {
                panelClass: ['alert-snack', 'success'],
                horizontalPosition: options.horizontalPosition,
                verticalPosition: options.verticalPosition,
                duration: options.duration,
                data: {
                    type: 'done',
                    message: message,
                    action: 'Close'
                }
            });
        });
    }

    private handleError(error: any, service: IServiceAlertClass) {
        const message =
          'Oops! You might want to try refreshing! <span style="color: #cdc7ac;">' + service.code + ' - ' + (error.status || -1) + '</span>';

        if (typeof error === 'string') {
            return error;
        }

        if (error.status === 500) {
            return message;
        }

        let errMessage = '';
        const errors: Array<string> = [];
        if (error instanceof HttpErrorResponse) {
          // TODO can be issues test on webside
            errors.push(error.message);
        } else if (error.error) {
            if (error.error.message) {
                errors.push(error.error.message);
            } else if (error instanceof HttpErrorResponse) {
                errMessage = ' HTTP: General Network error! ' + (error.statusText || '') + ' >>> Please refresh!';
            } else {
                for (const e in error.error) {
                    errors.push(error.error[e]);
                }
            }
        } else if (error.errors) {
            // is just an object
            for (const e in error.errors) {
                errors.push(error.errors[e]);
            }
        }

        if (errors.length) {
            errMessage += errors.join(' ');
        }

        if (error.status === 409 /*Conflict*/) {
            const m = errMessage || error.message || '';
            return `Oops! ${m} Someone changed this as well. You might want to copy your work somewhere else, try refreshing the page and resume the work after that.`;
        }

        // return errMessage || (error.message ? error.message :
        //     (error.status ? `${error.status} - ${error.statusText}` : 'Server error'));
        return errMessage || (error.message ? error.message : message);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
