<div class="inner">
    <div class="note-header">
        <div class="left mgc-account">
            <account-avatar size="40" [account]="note._account"></account-avatar>
            <span class="info">
                <span class="name" [innerHTML]="note._account.OwnerFirstName"></span>
                <span class="details">
                    <span>{{ note.CreateDate | amFromUtc | amTz | amDateFormat:'MMM D, YYYY h:mm a z' }} </span>
                    <span *ngIf="note.CreateDate!=note.UpdateDate">Last Updated:
                        {{ note.UpdateDate | amFromUtc | amLocal | amTimeAgo }}</span>
                </span>
            </span>
        </div>
        <span class="right" *ngIf="!isSnapshotTask">
            <mgc-icon-button class="w30" *ngIf="allowUpdate" icon="board-mat:more_vert" (click)="openNoteModal()">
            </mgc-icon-button>
        </span>
    </div>

    <div class="note-body">
        <note-preview *ngIf="!note.Wysiwyg" [text]="(note.Text || '') | embed: ['embed']"></note-preview>
        <div *ngIf="note.Wysiwyg" [innerHTML]="note.Wysiwyg | embed: ['adjustableSize']"></div>

        <!-- <note-preview *ngIf="!note.Settings.doNotRender" [text]="(note.Text || '') | embed: ['embed']"></note-preview>
        <textarea *ngIf="note.Settings.doNotRender" readonly autosize="readonly" [autosize-text]="note.Text"
            class="note-content">{{note.Text || ''}}</textarea> -->

        <attachments *ngIf="note._attachments.length" [data]="note._attachments" [model]="note" preview>
        </attachments>
    </div>

    <div class="summary">
        <div class="reactions" *ngIf="reactionsData.length" (click)="openReactionsData($event)">
            <mgc-icon-button class="reaction-icon {{rd.type}}" *ngFor="let rd of reactionsData"
                icon="board-mat:{{rd.type}}">
            </mgc-icon-button>
            <span class="total-reactions" [innerHTML]="note._reactions.length"></span>
        </div>

        <div class="comments" *ngIf="note._comments">
            <span class="with-pipe" *ngIf="reactionsData.length"></span>
            <span class="toggle" (click)="commentsToggle($event)" [innerHTML]="commentsLabel"></span>
        </div>

        <div *ngIf="shareEnabled && totalShares">
            <span class="with-pipe" *ngIf="reactionsData.length || note._comments"></span>
            <span>{{totalShares | number}} {{ totalShares == 1 ? ' share' : ' shares'}}</span>
        </div>

    </div>
    <mgc-divider [full-width]="true" *ngIf="!isSnapshotTask"></mgc-divider>
    <div class="note-footer" *ngIf="!isSnapshotTask">
        <div class="left">
          <div class='mgc-icon-button-wrapper' (click)="openReactions($event)">
            <mgc-icon-button
              [class]="reactionClass"
              [icon]="'board-mat:'+ (currentReaction ? currentReaction.Type : 'like')"
            >
            </mgc-icon-button>
            <span class='icon-title' >{{currentReaction ? currentReaction.Type : 'like'}}</span>
          </div>

          <div *ngIf="shareEnabled" class='mgc-icon-button-wrapper' (click)="engageShare($event)">
            <mgc-icon-button
              [class]="shareClass"
              icon="board-mat:share1"
            >
            </mgc-icon-button>
            <span class='icon-title'>Share</span>
          </div>

          <div class='mgc-icon-button-wrapper' (click)="openEmailModal()">
            <mgc-icon-button
              *ngIf="allowEmail"
              icon="board-mat:email">
            </mgc-icon-button>
            <span class='icon-title'>Email</span>
          </div>
            <!-- <mgc-icon-button *ngIf="!allowEmail" icon="board-mat:email" disabled>Email</mgc-icon-button> -->

            <!-- <mgc-icon-button *ngIf="allowUpdate" icon="board-mat:notify" [disabled]="notified"
                (click)="pushNotification()">Notify
            </mgc-icon-button>
            <mgc-icon-button *ngIf="!allowUpdate" icon="board-mat:notify" disabled>Notify
            </mgc-icon-button> -->

          <div class='mgc-icon-button-wrapper' (click)="openCommentModal()">
            <mgc-icon-button
              *ngIf="allowComment"
              icon="board-mat:comment"
            >
                <!-- <span class="toggle with-pipe" *ngIf="note._comments" (click)="commentsToggle($event)"
                    [innerHTML]="commentsLabel"></span> -->
            </mgc-icon-button>
            <span class='icon-title'>Comment</span>
          </div>

            <!-- <mgc-icon-button *ngIf="!allowComment" icon="board-mat:comment">
                <span class="toggle with-pipe" *ngIf="note._comments" (click)="commentsToggle($event)"
                    [innerHTML]="commentsLabel"></span>
            </mgc-icon-button> -->

          <div class='mgc-icon-button-wrapper right' (click)="toggleReadLater()">
            <mgc-icon-button
              [class.flagged]="note._readLater"
              icon="board-mat:flag">
            </mgc-icon-button>
          </div>
        </div>


    </div>
</div>


<mgc-new-posts *ngIf="note._hasNewComments" messageType="comments" (onLoad)="loadComments()"
    [processing]="newPostsLoading"></mgc-new-posts>

<mgc-comment-list *ngIf="note._showComments && note._comments" [note]="note" (onCommentRemoved)="commentRemoved()">
</mgc-comment-list>
