import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalWithActivity} from '../../global-modals.service';
import {ModalBlockType} from '@board/modals/modals-editor/modal-factory/blocks/types';

@Component({
  selector: 'missed-modal',
  templateUrl: 'missed-modal.component.html',
  styleUrls: ['missed-modal.component.scss']
})
export class MissedModalComponent {
  blockType = ModalBlockType;

  constructor(
    public dialogRef: MatDialogRef<MissedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalWithActivity,
  ) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
