import { Injectable, Inject } from '@angular/core';
import { BaseService } from '@board/_services';
import { of as observableOf, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { map, catchError } from 'rxjs/operators';
import { TaskV2 } from '../task-v2.model';
import { ITaskV2Block } from './task-v2-block.interface';
import { ITaskV2BlocksMetadata, TaskV2BlocksMetadata } from './task-v2-blocks-metadata';
import { IModelService } from '@panel-components/notes';
import { TaskV2AttachmentsBlock } from './attachments-block/attachments-block';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';

export interface ITaskV2BlocksServiceOptions {
  task: TaskV2;
  force?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TaskV2BlocksService extends BaseService implements IModelService {

  private _endPoint: string;

  private dataStore: {
    [key: string]: ITaskV2BlocksMetadata;
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService,
  ) {
    super();
    this.dataStore = {};
    this._endPoint = `${this.config.endpoint}/api/task-blocks`;
  }

  getBlocks(task: TaskV2) {
    if (this.dataStore[task.Id]) {
      return observableOf(this.dataStore[task.Id]);
    }

    return this.http.get(`${this._endPoint}/${task.Id}`).pipe(
      map((data: ITaskV2BlocksMetadata) => {
        const bMetadata = new TaskV2BlocksMetadata(data);
        this.dataStore[task.Id] = bMetadata;

        if (bMetadata.blocks.length !== bMetadata.order.length ) {
          this.alertService.snackWarning('<span style="line-height:1.5"><b>Warning</b>: The order of the note blocks might not be as expected. <br/>Consider checking, reorganizing and saving.</span>');
        }

        return this.dataStore[task.Id];
      }), catchError(error => {
        console.log('Could not load task v2 blocks');
        return observableOf(null);
      })
    )
  }

  saveBlocks(task: TaskV2, { accountId, blocks }: { accountId: string, blocks: ITaskV2Block[] }) {
    return this.http.put(`${this._endPoint}/${task.Id}`, { accountId, blocks }).pipe(
      map((data: ITaskV2BlocksMetadata) => {
        const blocksMetadata = new TaskV2BlocksMetadata(data);
        this.dataStore[task.Id] = blocksMetadata;
        // !!! important To update task BlocksMetadata
        task.BlocksMetadata = data['BlocksMetadata'] || [];
        return this.dataStore[task.Id];
      }), catchError(error => {
        console.log('Could not save task v2 blocks');
        return observableOf(null);
      })
    )
  }


  /**
   * For attachments block; order Image files
   * @param newOrder 
   * @param note 
   * @returns 
   */
  orderImages(newOrder: Array<string>, block: TaskV2AttachmentsBlock) {
    return this.http.put(`${this._endPoint}/images-order/${block.BlockId}`, {
      'taskId': block.TaskId,
      'order': newOrder
    }).pipe(
      map(data => {
        block.Content.imageOrder = data['imageOrder'];
        return block;
      }),
      catchError(error => {
        console.log('Could not update Block Content. Image Order: ' + error.status);
        return observableOf(false);
      })
    )
  }

  remove(block: TaskV2AttachmentsBlock) {
    // blocks are remove on general save button if they 
    // are marked as deleted
    return of(null);
  }

}