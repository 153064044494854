<ng-container *ngIf="!preview">

    <rs-uploader [style.font-size]="0" #rsUploader renderAs="content" [options]="uploaderOptions"
        (onAfterAddingFile)="onAfterAddingFile($event)" (onBeforeUpload)="onBeforeUploadFile($event)"
        (onProgress)="onUploadProgress($event)" (onSuccess)="onUploadSuccess($event)" (onError)="onUploadError($event)"
        (onCompleteAll)="onCompleteAll()">
    </rs-uploader>

    <div class="media-block-drag">
        <mgc-icon-button class="drag-handle" icon="board-mat:unfold-more"></mgc-icon-button>
    </div>

    <div class="media-block-content pad-hrz-xs">
        <div class="media-block-item-header">
        </div>
        <div class="media-block-item-body">
            <attachments *ngIf="attachments.length" [data]="attachments" [model]="block"></attachments>
            <p *ngIf="!attachments.length" class="linky" style="text-align: center; padding: 20px 0; font-size: 18px;" (click)="rsUploader.click()"> Add files & images</p>
        </div>
        <div class="media-block-item-footer">
            <mgc-icon-button class="remove" (click)="onRemove(block)" icon="board-mat:trash"></mgc-icon-button>
            <p style="padding-left: 30px;padding-right: 30px;opacity: .5;">|</p>
            <mgc-raised-button (click)="rsUploader.click()"> Add Files & Images</mgc-raised-button>
        </div>
    </div>

</ng-container>


<ng-container *ngIf="preview">
    <attachments *ngIf="attachments.length" [data]="attachments" [model]="block" preview>
    </attachments>
</ng-container>