import { NgModule, Optional, SkipSelf, ModuleWithProviders, Type } from '@angular/core';
import { JwtModule, JWT_OPTIONS } from './token';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth-guard.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  DefaultUserService,
  IUserService,
  USER_SERVICE,
  CURRENT_ID_SERVICE,
  ICurrentIdService,
  DefaultCurrentIdService
} from './defaults';
import { AuthService } from './auth.service';
import { ServerBusySnack } from './server-busy.snack';
import { PlatformProcessingOverlayComponent } from './platform-processing/platform-processing-overlay.component';
import { PlatformProcessingOverlayService } from './platform-processing/platform-processing-overlay.service';
import { environment } from 'environments/environment';
import { WINDOW } from '@rallysite/global-services';

export function jwtOptionsFactory(w:Window, currentIdService: ICurrentIdService) {
  return {
    tokenGetter: () => {
      if (!w.localStorage) {
        return null;
      }

      let token = w.localStorage.getItem('access_token');
      if (!token && window['RSHandler']) {
        if (typeof window['RSHandler'].getToken === 'function') {
          token = window['RSHandler'].getToken();
        }
      }
      return token;
    },
    whitelistedDomains: environment.whitelistDomains || [],
    skipWhenExpired: false, // due to refresh
    throwNoTokenError: false,
    customHeaders: [
      { name: 'X-Persona-ID', fnValue: () => { return currentIdService.currentAccountId } },
      { name: 'X-Participant-ID', fnValue: () => { return currentIdService.currentParticipantId } },
    ]
  }
}

export interface AutServiceConfig {
  uService?: Type<IUserService>;
  currentIdService?: Type<ICurrentIdService>
}

@NgModule({
    imports: [
        MatSnackBarModule,
        JwtModule.withCustomProviders({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [WINDOW, CURRENT_ID_SERVICE]
            }
        }),
    ],
    declarations: [
        ServerBusySnack,
        PlatformProcessingOverlayComponent
    ],
    providers: [
        PlatformProcessingOverlayService
    ]
})
export class AuthServiceModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthServiceModule) {
    if (parentModule) {
      throw new Error('AuthServiceModule is already loaded. It should only be imported in your application\'s main module.');
    }
  }

  static forRoot(config?: AutServiceConfig): ModuleWithProviders<AuthServiceModule> {
    return {
      ngModule: AuthServiceModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        AuthGuard,
        AuthService,
        {
          provide: USER_SERVICE,
          useClass: config && config.uService || DefaultUserService
        },
        {
          provide: CURRENT_ID_SERVICE,
          useClass: config && config.currentIdService || DefaultCurrentIdService
        },

      ]
    };
  }
}
