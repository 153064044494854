import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MgcButtonComponent, MgcRaisedButtonComponent, MgcStrokedButtonComponent, MgcIconButtonComponent, MgcRaisedButtonComponentNoAnim } from './button.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,

        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        MgcButtonComponent,
        MgcRaisedButtonComponent,
        MgcRaisedButtonComponentNoAnim,
        MgcStrokedButtonComponent,
        MgcIconButtonComponent,
    ],

    exports: [
        MgcButtonComponent,
        MgcRaisedButtonComponent,
        MgcRaisedButtonComponentNoAnim,
        MgcStrokedButtonComponent,
        MgcIconButtonComponent,

        MatIconModule
    ]
})
export class MgcButtonModule {
}
