export const PUBLIC_PAGE_PARAMS = [
  "asub",
  "view",
  "view3",
  "view_ph",
  "frameId",
  "fmsg",
  "list",
  "reg",
  "co",
  "custom_tracking",
  "ha",
  "hhhi",
  "hh",
  "hhi",
  "hd",
  "hf",
];
