import {BehaviorSubject, of as observableOf} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {APP_CONFIG, AppConfig} from '@rallysite/config';

import {BaseService} from '@board/_services/base-service.service';
import {Modal} from './modal.model';

@Injectable({
  providedIn: 'root'
})
export class ModalListService extends BaseService {

  private _endPoint: string;

  private dataStore: {
    _modals: BehaviorSubject<Modal[]>
    modals: Modal[],
    total: number
  };

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    super();
    this.initStorage();
    this._endPoint = `${this.config.endpoint}/api/modals`;
  }

  modals$() {
    if (!this.dataStore) {
      this.dataStore = {
        _modals: <BehaviorSubject<Modal[]>>new BehaviorSubject(null),
        modals: [],
        total: 0,
      }
    }
    return this.dataStore._modals.asObservable();
  }

  getModals() {
    if (this.inStorage()) {
      return observableOf(this.getFromStorage());
    }

    return this.http.get(this._endPoint).pipe(
      map(response => {
        this.initStorage();

        let data = response as unknown as object[];
        this.dataStore.total = data.length;

        for (let k in data) {
          this.pushToStorage(new Modal(data[k]));
        }

        return this.getFromStorage();
      }), catchError(error => {
        console.log('Could not load modals');
        return observableOf(null);
      })
    )
  }

  removeModal(modal: Modal) {
    return this.http.delete(`${this._endPoint}/${modal.Id}`).pipe(
      map(() => {
        this.dataStore._modals.next(this.dataStore.modals.filter(m => m.Id != modal.Id))
      })
    );
  }

  updateOrders(orderedIds: number[]) {
    return this.http.post(this._endPoint + '/update-orders', {orderedIds});
  }

  private initStorage() {
    this.modals$();

    this.dataStore = Object.assign(this.dataStore, {
      modals: [],
      total: 0,
    })
  }

  private inStorage() {
    if (!this.dataStore || !this.dataStore.modals.length) {
      return false;
    }

    return !!this.dataStore.modals;
  }

  private pushToStorage(modal: Modal, top = false) {
    if (top) {
      this.dataStore.modals.splice(0, 0, modal);
    } else {
      this.dataStore.modals.push(modal);
    }
    return this;
  }

  private getFromStorage() {
    let n: any;

    n = Object.assign({}, this.dataStore).modals;

    return n;
  }
}
