import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'not-allowed-modal',
  templateUrl: './not-allowed-modal.html',
  styleUrls: ['./not-allowed-modal.scss'],
})
export class NotAllowedModal {
  constructor(
    public dialogRef: MatDialogRef<NotAllowedModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
    }
  ) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}
