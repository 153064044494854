import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskV2BaseBlockComponent } from '../taskv2-base-block.component';
import { TaskV2TextBlock } from './text-block';

const buttons = [
  'bold', 'italic', 'underline', '|', 'font', 'fontsize', 'brush', '|', 'link', 'dots'
];

@Component({
  selector: 'task-v2-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TaskV2TextBlockComponent extends TaskV2BaseBlockComponent {
  @Input() block: TaskV2TextBlock;

  @Output() remove: EventEmitter<TaskV2TextBlock> = new EventEmitter(null);

  joditConfig = {
    buttons: buttons.concat(['source', 'eraser', 'strikethrough', 'align', 'paragraph', 'image', 'superscript', 'subscript', 'ul', 'ol', 'hr', 'symbol', 'table']),
    buttonsLG: buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    colorPickerDefaultTab: 'color',
    minHeight: 250,
    // maxWidth: 800,
    toolbarButtonSize: "middle",
    "showCharsCounter": false,
    "showWordsCounter": false,
    "showXPathInStatusbar": false,
    "disablePlugins": "draganddropelement,draganddrop,sticky"
  };

  handleOnChange(data: any) {
    this.block.Content = data.editor.value.replace(/\r?\n/g, '');
  }

  onRemove() {
    this.remove.emit(this.block);
  }

}
