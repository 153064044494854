import { BaseItem } from "@board/items/base-item.model";
import { EmailCampaign } from "../email-campaign/email-campaign";
import { IPanelComponent, PanelComponents } from "@panel-components";
import {
  EmailItemSettings,
  IEmailItemSettings,
} from "./board/email-item-settings.class";

export class EmailItemAttributes {
  Id: string = null;
  ReferenceId: string = null;
  AccountId: string = null;
  CampaignId: string = null;
  GroupId: string = null;
  Default: boolean = null;
  Name: string = null;
  Status: string = null;

  LaunchDate: string = null;
  LaunchTimezone: string = null;
  Launched: boolean = null;

  Settings: IEmailItemSettings = null;

  CreateDate: string = null;
  UpdateDate: string = null;
}

export class BaseEmailItem extends BaseItem {
  readonly SIDE_PANELS: Array<IPanelComponent> = [
    PanelComponents.EMAIL_ITEM_NAME,
    PanelComponents.EMAIL_ITEM_LAUNCH_DATE,
    PanelComponents.EMAIL_ITEM_COPY,
    PanelComponents.EMAIL_ITEM_DEFAULT,
  ];
  readonly _type: string = "email-item";
  get sidePanels(): Array<IPanelComponent> {
    return this.SIDE_PANELS;
  }
  get mainPanel(): IPanelComponent {
    return PanelComponents.EMAIL_ITEM_BOARD;
  }

  sideDefaultPanel(allowFn: Function) {
    return PanelComponents.EMAIL_ITEM_NAME;
  }

  prevAttributes: EmailItemAttributes = new EmailItemAttributes();
  attributes: EmailItemAttributes = new EmailItemAttributes();

  get Id(): string {
    return this.attributes.Id;
  }
  set Id(value: string) {
    this.attributes.Id = value;
  }
  get ReferenceId(): string {
    return this.attributes.ReferenceId;
  }
  set ReferenceId(value: string) {
    this.attributes.ReferenceId = value;
  }

  get AccountId(): string {
    return this.attributes.AccountId;
  }
  set AccountId(value: string) {
    this.attributes.AccountId = value;
  }
  get CampaignId(): string {
    return this.attributes.CampaignId;
  }
  set CampaignId(value: string) {
    this.attributes.CampaignId = value;
  }
  get GroupId(): string {
    return this.attributes.GroupId;
  }
  set GroupId(value: string) {
    this.attributes.GroupId = value;
  }
  set Default(value: boolean) {
    this.attributes.Default = value;
  }
  get Default(): boolean {
    return this.attributes.Default;
  }
  get Name(): string {
    return this.attributes.Name;
  }
  set Name(value: string) {
    this.attributes.Name = value;
  }
  get Status(): string {
    return this.attributes.Status;
  }
  set Status(value: string) {
    this.attributes.Status = value;
  }

  set LaunchDate(value: string) {
    this.attributes.LaunchDate = value;
  }
  get LaunchDate(): string {
    return this.attributes.LaunchDate;
  }
  set Launched(value: boolean) {
    this.attributes.Launched = value;
  }
  get Launched(): boolean {
    return this.attributes.Launched;
  }

  set LaunchTimezone(value: string) {
    this.attributes.LaunchTimezone = value;
  }
  get LaunchTimezone(): string {
    return this.attributes.LaunchTimezone;
  }

  set Settings(value: IEmailItemSettings) {
    this.attributes.Settings =
      value instanceof EmailItemSettings
        ? value
        : new EmailItemSettings(value || []);
  }
  get Settings(): IEmailItemSettings {
    return this.attributes.Settings;
  }

  get CreateDate(): string {
    return this.attributes.CreateDate;
  }
  set CreateDate(value: string) {
    this.attributes.CreateDate = value;
  }
  get UpdateDate(): string {
    return this.attributes.UpdateDate;
  }
  set UpdateDate(value: string) {
    this.attributes.UpdateDate = value;
  }

  _state: string;
  _scrollY: number;

  modalOptions({
    campaign,
    panel,
    edit,
  }: {
    campaign: EmailCampaign;
    panel?: IPanelComponent;
    edit?: boolean;
  }) {
    edit = !!edit;
    return {
      maxWidth: edit ? "1000px" : "600px",
      data: {
        title: (edit ? "Edit " : "Create ") + "Email Item",
        emailItem: this,
        campaign: campaign,
        panel: panel,
        edit: edit,
      },
    };
  }

  toDb() {
    return this.sanitize({
      Id: this.Id,
      ReferenceId: this.ReferenceId,
      AccountId: this.AccountId,
      CampaignId: this.CampaignId,
      GroupId: this.GroupId,
      Name: this.Name,
      Status: this.Status,
      Settings: this.Settings.toDb(),
      LaunchDate: this.LaunchDate,
      LaunchTimezone: this.LaunchTimezone,
      UpdateDate: this.UpdateDate,
    });
  }

  isValid() {
    return false;
  }
}

export class EmailItem extends BaseEmailItem {
  constructor(data: any) {
    super();

    data = { Settings: null, Launched: false, Default: false, ...data };

    for (let prop in data) {
      this[prop] = data[prop];
      this.__clone(prop);
    }
  }

  isValid() {
    return (
      this.Settings.senderName &&
      this.Settings.emailSubject &&
      this.Settings.emailBody.body.length > 0
    );
  }
}
