<div class="cp-modal-header">
    <button class="close-button" tabindex="-1" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="board-mat:close"></mat-icon>
    </button>
</div>

<div class="cp-modal-content">
    <div class="preview-wrapper">
        <mat-spinner color="accent" [diameter]="60" [strokeWidth]="3" style="display: inline-block; vertical-align: middle;"></mat-spinner>
        <iframe class="iframe" [attr.src]="data.previewUrl | safe: 'resourceUrl'"></iframe>
    </div>

    <file-details class="file-details" [file]="file" [downloadable]="true"></file-details>
</div>

<!-- 

<div mat-dialog-content class="cp-dialog-content">
    <div class="preview-wrapper" [ngSwitch]="code">
        <div class="iframe-preview" *ngSwitchCase="'iframe'">
            <iframe class="iframe" [attr.src]="file.Id | fileUrl"></iframe>
        </div>
        <div class="image-preview" *ngSwitchCase="'image'">
            <fit-image [image]="file" [quality]="data.quality"></fit-image>
        </div>
        <div *ngSwitchDefault>
            <h1>No preview available</h1>
        </div>
    </div>
</div>

<div class="footer">
    <div class="preview-details" fxLayout="column" fxLayoutAlign="space-around center">
        <div class="title"> {{ file.FileName }} </div>
        <div>
            Added
            <span class="date" [attr.title]="file.CreateDate">{{ file.CreateDate | amFromUtc | amTz:tz | amDateFormat:'MMM D, YYYY h:mm a z' }}</span>
            <b> - {{ file.Size | fileSize }} </b>
        </div>
        <button mat-button  class="download" (click)="onDownload(file)">
            <mat-spinner *ngIf="inProgress" color="accent" [diameter]="20" [strokeWidth]="3" style="display: inline-block; vertical-align: middle;"></mat-spinner>
            <mat-icon *ngIf="!inProgress" svgIcon="board-mat:download"></mat-icon>
            <span>Download </span>
        </button>
    </div>
</div> -->