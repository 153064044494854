import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthGuardCanMatch } from '@rallysite/auth-service';
import { AboutComponent } from './legal/about/about.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('app/board/board.module').then(m => m.BoardModule),
    canMatch: [IsAuthGuardCanMatch],
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
  },
  {
    path: 'board',
    pathMatch: 'full',
    redirectTo: 'admin'
  },
  {
    path: 'assessments',
    pathMatch: 'full',
    loadChildren: () => import('app/_libraries/surveys/__spiritual-assessment/assessment.module').then(m => m.AssessmentModule),
    canMatch: [IsAuthGuardCanMatch],
  },
  {
    path: 'spiritual-dashboard',
    loadChildren: () => import('app/pages/spiritual-dashboard-page/spiritual-dashboard.module').then(m => m.SpiritualDashboardModule),
    canMatch: [IsAuthGuardCanMatch],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/pages/dashboard-page/dashboard.module').then(m => m.DashboardModule),
    canMatch: [IsAuthGuardCanMatch],
  },
  {
    path: 'surveys',
    pathMatch: 'full',
    loadChildren: () => import('app/_libraries/surveys/surveys.module').then(m => m.SurveysModule),
  },
  {
    path: 'i/auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
    canMatch: [IsAuthGuardCanMatch],
  },
  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
    canMatch: [IsAuthGuardCanMatch],
  },
  {
    path: 'legal/about',
    component: AboutComponent,
  },

  // already readjusted
  // make sure the following lines are always at the end of the array
  {
    path: '',
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
