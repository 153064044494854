import {
    Component,
    HostBinding,
} from '@angular/core';

@Component({
    selector: 'organizer-media-grid',
    template: `<ng-content></ng-content>`,
    styles: [`
        :host {
            display: block;
            padding: 5px;
            max-height: 400px;
            overflow-y: auto;
        }
    `]
})
export class OrganizerMediaGridComponent {
    @HostBinding('class.mgc-organizer-media-grid') gridClass = true;

}