<mgc-dialog-popup>
  <mgc-dialog-header
    [title]="data.modal.Title"
    [disableClose]="!data.modal.Config.enableClosingModal"
    (close)="closeDialog()"
  ></mgc-dialog-header>

  <mgc-dialog-content>
    <p>{{ data.modal.Description }}</p>
    <hr>
    <ng-container *ngIf="surveyIdsResolved">
      <brand-survey [brandId]="brandId"
                    [surveyId]="surveyId"
                    [hideBrandAndTitle]="true"
                    (surveyDone)="surveyDone()"></brand-survey>
    </ng-container>
    <div *ngIf="!surveyIdsResolved" class="resolving-error">
      <p>An error occurred while displaying modal content. Please contact support to resolve this issue.</p>
      <p>Please show the support manager this message:</p>
      <p><code>Unable to extract survey id from modal config</code></p>
    </div>
    <div class="text-center mt-4">
      <a href="#" *ngIf="data.modal.Config.enableClosingModal" (click)="closeDialog()">Close this modal</a>
    </div>
  </mgc-dialog-content>
</mgc-dialog-popup>
