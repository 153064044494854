import {
    convertJoinMeURLToEmbed,
    convertFacebookURLToEmbed,
    convertYoutubeURLToEmbed,
    convertVimeoURLToEmbed,
    convertTiktokURLToEmbed,
    isURL,
    convertZoomURLToEmbed,
    convertSurveyURLToEmbed,
    convertPlayerURLToEmbed
} from "./jodit.conversions";

export const customControls = function (Jodit: any, data: any = {}) {

    const player = {
        icon: 'player',
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertPlayerURLToEmbed, ['link', 'code'], {
                link: {
                    fullWidth: true
                },
                data: data.player || {}
            });
        },
        tags: ['iframe'],
        tooltip: 'Insert Player',
    };

    const youtube = {
        icon: 'youtube',
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertYoutubeURLToEmbed, ['link', 'code'], { link: { fullWidth: true } });
        },
        tags: ['iframe'],
        tooltip: 'Insert Youtube video',
    };

    const vimeo = {
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertVimeoURLToEmbed, ['link', 'code'], { link: { fullWidth: true } });
        },
        tags: ['iframe'],
        tooltip: 'Insert Vimeo video'
    };

    const facebook = {
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertFacebookURLToEmbed, ['link'], { link: { tall: true } });
        },
        tags: ['iframe'],
        tooltip: 'Insert Facebook Video'
    };

    const tiktok = {
        icon: 'tiktok',
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertTiktokURLToEmbed);
        },
        tags: ['iframe'],
        tooltip: 'Insert Tiktok video'
    };

    const joinme = {
        popup: function (editor, current, control, close) {
            return popup(Jodit, editor, current, control, close, convertJoinMeURLToEmbed);
        },
        tags: ['iframe'],
        tooltip: 'Insert join.me video conference'
    };

    const zoom = {
        icon: 'zoom',
        popup: function (editor, current, control, close) {
            return popupZoom(Jodit, editor, current, control, close, convertZoomURLToEmbed);
        },
        tags: ['a'],
        tooltip: 'Insert Zoom video conference'
    };

    const survey = {
        icon: 'survey',
        popup: function (editor, current, control, close) {
            return popupSurvey(Jodit, editor, current, control, close, convertSurveyURLToEmbed);
        },
        tags: ['iframe'],
        tooltip: 'Insert Survey'
    };

    const font = {
        list: {
            'Poppins,sans-serif': 'Poppins',
            'Helvetica,sans-serif': 'Helvetica',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana'
        }
    };

    return {
        player: player,
        youtube: youtube,
        vimeo: vimeo,
        facebook: facebook,
        tiktok: tiktok,
        joinme: joinme,
        zoom: zoom,
        survey: survey,
        font: font
    }
}

const popupSurvey = function (Jodit, editor, current, control, close, fConvert) {
    const Icon = Jodit.modules.Icon;
    const helpers = Jodit.modules.Helpers;
    const TabsWidget = Jodit.modules.TabsWidget;

    let bylink, tabs: any = [], selinfo = editor.selection.save();
    let insertCode = function (code) {
        editor.selection.restore(selinfo);
        editor.selection.insertHTML(code);
        close();
    };

    bylink = editor.create.fromHTML(`
                    <form class="jodit-form">
                        <div class="jodit jodit-form__group">
                            <input class="jodit-input" required name="url" placeholder="https://" type="url"/>
                            <input class="jodit-input" style="margin-top:5px" name="height" placeholder="Survey height (e.g. 800px)" value="800px" type="text"/>
                            <button class="jodit-button" type="submit">${editor.i18n('Insert')}</button>
                        </div>
                    </form>`
    );

    tabs.push({
        icon: 'link',
        name: 'Link',
        content: bylink
    });

    editor.e.on(bylink, 'submit', event => {
        event.preventDefault();
        if (!isURL(helpers.val(bylink, 'input[name=url]'))) {
            (bylink.querySelector(
                'input[name=url]'
            ) as HTMLInputElement).focus();
            (bylink.querySelector(
                'input[name=url]'
            ) as HTMLInputElement).classList.add('jodit_error');
            return false;
        }
        insertCode(fConvert(Jodit, helpers.val(bylink, 'input[name=url]'), helpers.val(bylink, 'input[name=height]')));
        return false;
    });

    return TabsWidget(editor, tabs);
}

const popupZoom = function (Jodit, editor, current, control, close, fConvert) {
    const Icon = Jodit.modules.Icon;
    const helpers = Jodit.modules.Helpers;
    const TabsWidget = Jodit.modules.TabsWidget;

    let bylink, tabs: any = [], selinfo = editor.selection.save();
    let insertCode = function (code) {
        editor.selection.restore(selinfo);
        editor.selection.insertHTML(code);
        close();
    };

    bylink = editor.create.fromHTML(`
                    <form class="jodit-form">
                        <div class="jodit jodit-form__group">
                            <input class="jodit-input" required name="url" placeholder="https://" type="url"/>
                            <input class="jodit-input" style="margin-top:5px" required name="title" placeholder="Meeting name" type="text"/>
                            <button class="jodit-button" type="submit">${editor.i18n('Insert')}</button>
                        </div>
                    </form>`
    );

    tabs.push({
        icon: 'link',
        name: 'Link',
        content: bylink
    });

    editor.e.on(bylink, 'submit', event => {
        event.preventDefault();
        if (!isURL(helpers.val(bylink, 'input[name=url]'))) {
            (bylink.querySelector(
                'input[name=url]'
            ) as HTMLInputElement).focus();
            (bylink.querySelector(
                'input[name=url]'
            ) as HTMLInputElement).classList.add('jodit_error');
            return false;
        }
        insertCode(fConvert(Jodit, helpers.val(bylink, 'input[name=url]'), helpers.val(bylink, 'input[name=title]')));
        return false;
    });

    return TabsWidget(editor, tabs);
}


const popup = function (Jodit, editor, current, control, close, fConvert, usetabs = ['link', 'code'], options = {}) {
    const Icon = Jodit.modules.Icon;
    const helpers = Jodit.modules.Helpers;
    const TabsWidget = Jodit.modules.TabsWidget;

    options || (options = {});

    let bylink, bycode, tabs: any = [], selinfo = editor.selection.save();
    let insertCode = function (code) {
        editor.selection.restore(selinfo);
        editor.selection.insertHTML(code);
        close();
    };

    usetabs.forEach(function (tab) {
        switch (tab) {
            case 'link':
                var sOptions = '';
                if (options['link'] && options['link']['tall'] && options['link']['fullWidth']) {
                    sOptions = `<label>
                                    <input class="jodit-checkbox" name="vgroup" value="partial" type="radio">
                                    <span>Partial wide video</span>
                                </label>
                                <label>
                                    <input class="jodit-checkbox" name="vgroup" value="fullWidth" type="radio">
                                    <span>Full wide video</span>
                                </label>
                                <label>
                                    <input class="jodit-checkbox" name="vgroup" value="tall" type="radio">
                                    <span>Tall video</span>
                                </label>`;
                } else if (options['link'] && options['link']['fullWidth']) {
                    sOptions = `<label ref="target_checkbox_box">
                                    <input ref="target_checkbox" class="jodit-checkbox" name="fullWidth" type="checkbox" checked="checked">
                                    <span>Full wide video</span>
                                </label>`
                } else if (options['link'] && options['link']['tall']) {
                    sOptions = `<label ref="target_checkbox_box">
                                    <input ref="target_checkbox" class="jodit-checkbox" name="tall" type="checkbox">
                                    <span>Tall video</span>
                                </label>`
                }

                bylink = editor.create.fromHTML(`
                    <form class="jodit-form">
                        <div class="jodit jodit-form__group">
                            <input class="jodit-input" required type="url" name="url" placeholder="http://"/>
                        </div>` + sOptions +
                    `<div class="jodit-buttons">
                            <button class="jodit-button" type="submit">${editor.i18n('Insert')}</button>
                        </div>
                    </form>`
                ) as HTMLFormElement;

                tabs.push({
                    icon: 'link',
                    name: 'Link',
                    content: bylink
                });

                editor.e.on(bylink, 'submit', event => {
                    event.preventDefault();
                    if (!isURL(helpers.val(bylink, 'input[name=url]'))) {
                        (bylink.querySelector(
                            'input[name=url]'
                        ) as HTMLInputElement).focus();
                        (bylink.querySelector(
                            'input[name=url]'
                        ) as HTMLInputElement).classList.add('jodit_error');
                        return false;
                    }
                    let isTall = false;
                    let isFullWidth = false;
                    if (options['link'] && options['link']['tall'] && options['link']['fullWidth']) {
                        const selected = bylink.querySelector('input[name=vgroup]:checked').value;
                        isTall = selected == 'tall';
                        isFullWidth = selected == 'fullWidth';
                    } else {
                        isTall = bylink.querySelector('input[name=tall]:checked');
                        isFullWidth = bylink.querySelector('input[name=fullWidth]:checked');
                    }
                    const type = isTall ? 'tall' : (isFullWidth ? 'fullWidth' : '');
                    insertCode(fConvert(Jodit, helpers.val(bylink, 'input[name=url]'), type, options['data']));
                    return false;
                });
                break;
            case 'code':
                bycode = editor.create.fromHTML(`
                    <form class="jodit-form">
                        <div class="jodit-form__group">
                            <textarea class="jodit-textarea" required name="code" placeholder="${editor.i18n('Embed code')}"></textarea>
                            <button class="jodit-button" type="submit">${editor.i18n('Insert')}</button>
                        </div>
                    </form>`
                );
                tabs.push({
                    icon: 'source',
                    name: 'Code',
                    content: bycode
                });

                editor.e.on(bycode, 'submit', event => {
                    event.preventDefault();
                    if (!helpers.trim(helpers.val(bycode, 'textarea[name=code]'))) {
                        (bycode.querySelector(
                            'textarea[name=code]'
                        ) as HTMLTextAreaElement).focus();
                        (bycode.querySelector(
                            'textarea[name=code]'
                        ) as HTMLTextAreaElement).classList.add('jodit_error');
                        return false;
                    }
                    insertCode(helpers.val(bycode, 'textarea[name=code]'));
                    return false;
                });
                break
        }
    });

    return TabsWidget(editor, tabs);
}