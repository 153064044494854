
import { takeWhile, finalize, filter, switchMap, map } from 'rxjs/operators';
import { of as observableOf, BehaviorSubject } from 'rxjs';

import {
  Component, OnInit, Input, SimpleChanges, HostBinding,
  ElementRef, ViewChild, HostListener
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NoteService } from './note.service';
import { Note } from './note';

import { list } from '@animations';
import { IServiceStates } from '@rallysite/global-interfaces';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import {
  IDoNotShowAgainOption,
  UserService,
  DNSA_OPTIONS,
  DnsaNoteListEmptyComponent,
  DoNotShowAgainOptionItem
} from '@rallysite/user';
import { MessageBusService } from '@rallysite/global-services';
import { CurrentResourceService } from '@board/_services/current-resource.service';
import { Task } from '@board/items/task';
import { Notification } from '@board/_components/notification';
import { QueryRequestService } from '@board/_services';
import { SnapshotTask } from '@board/items/scheduled-task/snapshot-task/snapshot-task';

@Component({
  selector: 'mgc-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
  animations: [list]
})
export class NoteListComponent implements OnInit {
  @HostBinding('class.mgc-note-list') noteListClass = true;

  @HostListener('scroll', ['$event']) triggerCycle(event: any): void {
    this.lazyLoading()
  }

  @Input() item: Task;

  _task: BehaviorSubject<Task> = new BehaviorSubject<Task>(null);
  get task$() {
    return this._task.asObservable();
  }


  @ViewChild('notesContainer') notesContainer: ElementRef;

  private alive: boolean = true;
  notes: Note[] = [];
  states: IServiceStates;
  state: number;
  focusedNote: Note;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;
  notesReady: boolean = false;

  private offset: number = 0;
  private limit: number = 5;
  readonly initialLoad: number = 10;

  loadingMore: boolean = false;
  moreToLoad: boolean = false;

  dnsaListEmpty: {
    checked: boolean,
    option: IDoNotShowAgainOption,
    closed: boolean
  };
  scrollContainer: Element;

  constructor(
    private messageBus: MessageBusService,
    private noteService: NoteService,
    public currentResource: CurrentResourceService,
    private userService: UserService,
    public dialog: MatDialog,
    public el: ElementRef,
    private qrs: QueryRequestService,
  ) {
    this.states = NoteService.STATES;
    el.nativeElement.offsetHeight;

  }

  get totalNotes() {
    return this.noteService.total;
  }

  trackByFn(index: number, note: Note): string {
    return note.Id;
  }

  private inView(notification: Notification) {
    return (this.item as Task).Id === notification.task_id;
  }

  handleDNSA() {
    this.dnsaListEmpty = {
      checked: this.userService.user.hasDnsaOption(DNSA_OPTIONS.NOTE_LIST_EMPTY),
      option: DNSA_OPTIONS.NOTE_LIST_EMPTY,
      closed: false
    }

    this.userService.userUpdated$
      .subscribe(() => {
        this.dnsaListEmpty = {
          checked: this.userService.user.hasDnsaOption(DNSA_OPTIONS.NOTE_LIST_EMPTY),
          option: DNSA_OPTIONS.NOTE_LIST_EMPTY,
          closed: false
        }
      })
  }
  onCloseDNSA(type: string) {
    this.dnsaListEmpty.closed = true;
  }

  private getContainerHeight() {
    if (this.el.nativeElement.clientHeight === 0) {
      // for IE11
      return this.el.nativeElement.scrollHeight;
    }
    return this.el.nativeElement.clientHeight;
  }

  private lazyLoading() {
    if (!this.notesContainer) {
      return;
    }
    let containerHeight = this.getContainerHeight();
    let contentHeight = this.notesContainer.nativeElement.offsetHeight;
    let scrolled = this.el.nativeElement.scrollTop;

    if (contentHeight - scrolled <= 2 * containerHeight) {
      this.loadMore();
    }
  }

  loadMore() {
    if (this.loadingMore) {
      return;
    }

    this.loadingMore = true;
    this.offset = this.notes.length;

    this.loadNotes(this.offset).pipe(
      finalize(() => {
        this.state = this.states.DONE;
        this.notesReady = true;
        this.loadingMore = false;
      }))
      .subscribe(notes => {
        this.render(notes);
      })
  }

  options: any;
  private loadNotes(offset: number = 0) {

    if (this.item instanceof SnapshotTask) {
      this.options = {
        item: this.item,
      };
      const notes = this.item.notes();
      return observableOf(notes);
    }

    this.options = {
      item: this.item,
      limit: (offset === 0 ? this.initialLoad || this.limit : this.limit),
      offset: offset
    };

    this.state = this.states.LOADING;
    return this.noteService.getNotes(this.options).pipe(
      map(notes => {
        return notes;
      })
    );
  }


  render(notes) {
    if (!notes) {
      return
    }

    this.notes = notes;
    if (this.item instanceof SnapshotTask) {
      this.moreToLoad = false;
      return;
    }

    this.dnsaOptionItem = new DoNotShowAgainOptionItem(DnsaNoteListEmptyComponent, DNSA_OPTIONS.NOTE_LIST_EMPTY);
    this.moreToLoad = this.notes.length < this.noteService.total;
  }

  debugMe: boolean = false;
  private debug() {
    this.debugMe = this.qrs.debugMe;
  }

  private bootstrapNotes() {
    this.task$.pipe(
      filter(task => {
        return !!task
      }),
      switchMap(task => {
        return this.loadNotes();
      })
    ).subscribe(notes => {
      this.state = this.states.DONE;
      this.notesReady = true;

      this.render(notes);
    })

    this.noteService.notes$.pipe(
      filter(notes => {
        if (!notes) {
          return false;
        }

        if (notes.length === 0 || (notes.length && notes[0].TaskId === this.item.Id)) {
          return true;
        }
        return false;
      }),
      takeWhile(() => this.alive))
      .subscribe(notes => {
        this.render(notes);
      });
  }

  dnsaOptionItem: any;
  ngOnInit() {
    this.debug();

    this.bootstrapNotes();

    this.handleDNSA();

    this.messageBus.on(Notification.EVENTS.NOTE).pipe(
      filter((notification: Notification) => {
        return notification.task_id === (this.item as Task).Id
      }),
      takeWhile(() => this.alive))
      .subscribe((notification: Notification) => {
        // console.log(['note list.component ', notification]);
        notification._inView = this.inView(notification);
        if (notification._inView) {
          // console.log('--- publish back on: ' + Notification.EVENTS.NOTE + '.' + notification.id);
          // make an announcement in order to cancel any further processing on heigher level(notificationService)
          this.messageBus.publish(Notification.EVENTS.NOTE + '.' + notification.id);
        }
        this.noteService.handleNotification(notification);
      })
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['item'] && changes['item'].currentValue) {
      // force scroll top
      this.el.nativeElement.scrollTop = 0;
      this.notesReady = false;

      this._task.next(this.item);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
