import { ThemeType } from "./theme.interface";

export interface ICustomImageOptions {
    id?: string;
    accent?: string;
}

export interface ICustomColorOptions {
    primary?: string;
    navigation?: string | string[];
}

export interface IThemeOptions {
    /** predefined theme ids */
    themeId?: string;

    /** light or dark theme 
      * make sense only for predefined themes */
    type?: ThemeType;

    /** when themeId is custom_image */
    custom_image?: ICustomImageOptions;

    /** when themeId is custom_color */
    custom_color?: ICustomColorOptions;
}