
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BaseService } from '@board/_services/base-service.service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

import { ProjectLibraryFile } from './project-library-file';
import { Project } from '../project';

@Injectable({
    providedIn: 'root'
})
export class ProjectLibraryService extends BaseService {

    private _libraryFiles: BehaviorSubject<ProjectLibraryFile[]>;
    private _loadingState: BehaviorSubject<number>;
    private _processingState: BehaviorSubject<number>;
    private _endPoint: string;

    private dataStore: ProjectLibraryFile[];

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        super();
        this.dataStore = null;

        this._endPoint = `${this.config.endpoint}/api/project-library`;
        this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._libraryFiles = <BehaviorSubject<ProjectLibraryFile[]>>new BehaviorSubject(null);
    }

    get loadingState$(): Observable<number> {
        return this._loadingState.asObservable();
    }
    get processingState$(): Observable<number> {
        return this._processingState.asObservable();
    }
    get libraryFiles$(): Observable<ProjectLibraryFile[]> {
        return this._libraryFiles.asObservable();
    }

    get endPoint(): string {
        return this._endPoint;
    }

    loadProjectLibraryFiles(project: Project, all: boolean = false) {
        this.dataStore = [];
        this._loadingState.next(ProjectLibraryService.STATES.LOADING);

        let paramsObj = {
            'filter[ProjectId]': project.Id,
        }
        if (!all) {
            const imageLibrary = project.__val('Metadata', ['imageLibrary']);
            if (imageLibrary && imageLibrary.length) {
                paramsObj['filter[Id][]'] = imageLibrary;
            } else {
                this.fetch();
                return;
            }
        }
        let params = new HttpParams({
            fromObject: paramsObj
        });

        this.http.get(this._endPoint, { params: params })
            .subscribe(
                data => {
                    for (let key in data) {
                        let plFile = data[key];
                        this.addUI(new ProjectLibraryFile(plFile));
                    }

                    this.fetch(project.Metadata.imageLibrary);
                },
                error => {
                    console.log('Could not load project library');
                    delete (this.dataStore);
                    this._loadingState.next(ProjectLibraryService.STATES.ERROR);
                }
            )
    }

    updateImageLibraryMetadata(project: Project, imagesIds: Array<string>, dry: boolean = false) {
        let payload = {
            imageLibrary: imagesIds
        }

        if (dry) {
            project.Metadata.imageLibrary = payload.imageLibrary;
            return observableOf(true);
        }

        return this.http.put(`${this._endPoint}/${project.Id}/metadata`, payload).pipe(
            map(data => {
                const metadata = project.Metadata;
                metadata.imageLibrary = data['imageLibrary'];
                project.__update(Object.assign(data, {
                    Metadata: metadata,
                    UpdateDate: data['UpdateDate']
                }))
                return true;
            }),
            catchError(error => {
                console.log('Could not update image library metadata.');
                if (error.status === 401) {
                    this._processingState.next(ProjectLibraryService.STATES.UNAUTHORIZED);
                } else {
                    this._processingState.next(ProjectLibraryService.STATES.ERROR);
                }
                return observableOf(false);
            })
        )
    }

    private fetch(orderList: Array<string> = null) {
        if (orderList && orderList.length) {
            let orderedStore: ProjectLibraryFile[] = [];
            orderList.forEach(id => {
                let file = this.dataStore.find(f => f.Id === id);
                if (file) {
                    orderedStore.push(file);
                }
            })
            this.dataStore = orderedStore;
        }

        this._libraryFiles.next(this.dataStore);
        if (this.dataStore.length > 0) {
            this._loadingState.next(ProjectLibraryService.STATES.DONE);
        } else {
            this._loadingState.next(ProjectLibraryService.STATES.EMPTY);
        }
    }

    private addUI(plFile: ProjectLibraryFile) {
        if (this.dataStore) {
            this.dataStore.splice(0, 0, plFile);
        }
        return this;
    }


}