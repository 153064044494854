
import { takeWhile, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Note } from '@panel-components/notes/note';
import { ServiceAlertClass, IAlertsClass } from '@rallysite/components/alert';
import { UploaderComponent, IUploaderOptions } from '@libraries/uploader';
import { AttachmentsComponent } from '@panel-components/notes/attachments';
import { FileService } from '@panel-components/notes/attachments';

import { AuthService } from '@rallysite/auth-service';
import { ConfirmOverlayService } from '@board/_components/confirm-overlay/confirm-overlay.service';
import { PermissionService, CurrentIdService } from '@board/_services';
import { Observable } from 'rxjs';

import { CommentService, ICommentServiceOptions } from '../comment.service';
import { Comment } from '../comment.model';
import { WithAttachmentsComponent } from '@panel-components/notes/with-attachments/with-attachments.component';
import { Participant } from '@panel-components/participants';
import { TaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';


@Component({
  templateUrl: './comment-modal.html',
  styleUrls: ['./comment-modal.scss']
})
export class CommentModal extends WithAttachmentsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(UploaderComponent) uploader: UploaderComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  @ViewChild('textarea') textarea: ElementRef;

  uploaderOptions: IUploaderOptions;

  private alive: boolean = true;
  note: Note = null;
  taskv2BlocksMetadata: TaskV2BlocksMetadata = null;
  comment: Comment;
  commentParent: Comment;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;
  private ownerAccountId: string;

  constructor(
    protected commentService: CommentService,
    fileService: FileService,
    private authService: AuthService,
    confirmOverlay: ConfirmOverlayService,
    private currentIdService: CurrentIdService,
    public dialogRef: MatDialogRef<CommentModal>,
    permissionsSettings: PermissionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commentService, fileService, confirmOverlay, permissionsSettings);
    this.model = this.comment = <Comment>data.comment;
    this.commentParent = <Comment>data.commentParent;
    this.note = data.note;
    this.taskv2BlocksMetadata = data.taskv2BlocksMetadata;
    this.ownerAccountId = this.model.AccountId;
  }

  postAs(admin: Participant) {
    if (admin && admin.Id != this.ownerAccountId) {
      this.comment.AccountId = admin.Id;
      this.comment.Settings.ownerAccountId = this.ownerAccountId;
    }
  }


  /** overridden methods */

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkUploaderOptions() {
    // update uploader options for CommentId, when comment is new created
    if (!this.uploaderOptions.payload.CommentId) {
      this.uploaderOptions.payload.CommentId = this.comment.Id;
    }
  }

  saveRequest(): Observable<any> {
    let request: Observable<any>;

    this.comment.Text = this.modelText;
    if (!this.comment.Id) {
      const options: ICommentServiceOptions = {
        comment: this.comment,
        taskv2BlocksMetadata: this.taskv2BlocksMetadata,
        note: this.note,
        commentParent: this.commentParent
      };
      request = this.commentService.create(options);
    } else {
      request = this.commentService.update(this.comment);
    }

    return request;
  }
  saveDone(comment: Comment) {
    this.comment = comment;
  }

  /** END overridden methods */


  protected bootstrap() {
    // create a shallow copy of comment attachments
    // on cancel, the comment._attachments will not contain the new added ones
    this.attachments = this.comment._attachments.slice();

    this.modelText = this.comment.Text;

    this.fileService.getFiles$(this.comment).pipe(
      filter(files => {
        return !!files;
      }),
      takeWhile(() => this.alive))
      .subscribe(files => {
        this.attachments = files;
      });

    const payload = {
      CommentId: this.comment.Id,
      TaskId: this.taskv2BlocksMetadata ? this.taskv2BlocksMetadata.taskv2Id : this.note.TaskId,
      AccountId: this.currentIdService.currentAccountId
    }
    if (this.comment.NoteId) {
      payload['NoteId'] = this.comment.NoteId
    }
    this.uploaderOptions = {
      autoUpload: false,
      filename: 'file',
      tokenGetter: this.authService.tokenGetter,
      endPoint: this.fileService.endPoint,
      payload: payload
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewInit() {
    this.textarea.nativeElement.focus();
  }

}
