<mgc-dialog>
    <mgc-dialog-header [title]="data.title" (close)="closeDialog()"></mgc-dialog-header>
  
    <mgc-dialog-content>
        <umbrella-settings #umbrellaSettings [data]="data" (onClose)="closeDialog()"></umbrella-settings>
    </mgc-dialog-content>
  
  <alert [service]="[serviceAlertClass.UMBRELLA_CREATE, serviceAlertClass.UMBRELLA_UPDATE]"></alert>
  
    <mgc-dialog-actions>
      <mgc-stroked-button left [processing]="data.umbrella._state==='remove' && state===states.PROCESSING" (click)="umbrellaSettings.onDelete($event)"
      *ngIf="data.edit">Delete</mgc-stroked-button>
      <mgc-raised-button right [processing]="data.umbrella._state!=='remove' && state===states.PROCESSING" (click)="umbrellaSettings.onSave()"
        tabindex="2">
        <span>{{data.edit ? 'Save': 'Create'}}</span>
      </mgc-raised-button>
      <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
    </mgc-dialog-actions>
  
  </mgc-dialog>