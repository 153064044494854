import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskV2BaseBlockComponent } from '../taskv2-base-block.component';
import { TaskV2ImageBlock } from './image-block';

@Component({
  selector: 'task-v2-image-block',
  templateUrl: './image-block.component.html',
  styleUrl: './image-block.component.scss',
})
export class TaskV2ImageBlockComponent extends TaskV2BaseBlockComponent {
  @Input() block: TaskV2ImageBlock;
  @Output() remove: EventEmitter<TaskV2ImageBlock> = new EventEmitter(null);

  onRemove() {
    this.remove.emit(this.block);
  }

}
