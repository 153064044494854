import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, StateKey, TransferState } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TransferStateConverter } from './transfer-state.converter';

@Injectable({
  providedIn: 'root',
})
export class TransferStateService {
  /**
   * The state keys.
   */
  private keys = new Map<string, StateKey<string>>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly transferState: TransferState
  ) { }

  fetch<T>(
    key: string,
    observableInput: Observable<T>,
    defaultValue?: T,
    converter?: TransferStateConverter<T>
  ): Observable<T> {
    if (this.has(key)) {
      console.log(`X-Transfer-State: HIT ${key}`);
      return of(this.get(key, defaultValue, converter)).pipe(
        tap(() => this.remove(key))
      );
    }
    return observableInput.pipe(
      tap((value) => {
        this.set(key, value, converter);
      })
    );
  }

  get<T>(
    key: string,
    defaultValue?: T | null,
    converter?: TransferStateConverter<T>
  ): T | null {
    if (!this.has(key)) {
      return defaultValue || null;
    }
    const value = this.transferState.get<T>(
      // TODO Fix type and remove ignore
      // @ts-ignore
      this.getStateKey(key),
      defaultValue
    );
    return converter ? converter.fromTransferState(value) : value;
  }

  has(key: string): boolean {
    return this.transferState.hasKey(this.getStateKey(key));
  }

  remove(key: string): void {
    if (!this.has(key)) {
      return;
    }
    this.transferState.remove(this.getStateKey(key));
  }

  set<T>(key: string, value: T, converter?: TransferStateConverter<T>): void {
    if (isPlatformServer(this.platformId)) {
      if (this.has(key)) {
        console.warn(
          `Setting existing value into TransferState using key: '${key}'`
        );
      }
      if (!environment.production) {
        console.log(`Storing TransferState for: '${key}'`);
      }

      const data = converter ? converter.toTransferState(value) : value;
      this.transferState.set(
        this.getStateKey(key),
        data
      );
    }
  }

  private getStateKey(key: string): StateKey<string> {
    if (this.keys.has(key)) {
      return this.keys.get(key);
    }
    this.keys.set(key, makeStateKey(key));
    return this.keys.get(key);
  }
}
