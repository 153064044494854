import { Injectable, InjectionToken } from '@angular/core';

export interface ICurrentIdService {
    currentAccountId: string;
    currentParticipantId: string;
}
export const CURRENT_ID_SERVICE = new InjectionToken<ICurrentIdService>('CURRENT_ID_SERVICE');

@Injectable()
export class DefaultCurrentIdService implements ICurrentIdService {

    get currentAccountId(): string {
        return null;
    }
    get currentParticipantId(): string {
        return null;
    }

}