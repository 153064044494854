import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MgcButtonModule, MgcFormFieldModule } from '@rallysite/mgc-components';
import { ImageComponent } from './image.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UploaderModule } from '@libraries';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmOverlayModule } from '@board-components/confirm-overlay/confirm-overlay.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatProgressBarModule,

    MgcFormFieldModule,
    MgcButtonModule,

    UploaderModule,
    ConfirmOverlayModule

  ],
  declarations: [
    ImageComponent,
  ],
  exports: [
    ImageComponent
  ]
})
export class ImageModule { }
