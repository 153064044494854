<mgc-dialog-popup>

  <mgc-dialog-header class="danger" [title]="title" (close)="closeDialog()">
  </mgc-dialog-header>

  <mgc-dialog-content>
    <ng-template mgc-popup></ng-template>
  </mgc-dialog-content>

  <mgc-dialog-actions>
      <ng-container right *ngFor="let button of steps[stepIndex].buttons">
        <mgc-stroked-button *ngIf="button.method===PopupMethod.CLOSE" (click)="onClick(button.method)">{{button.text}}
        </mgc-stroked-button>
        <mgc-raised-button
          *ngIf="button.method===PopupMethod.SUBMIT && submitAvailable"
          [processing]="processing"
          (click)="onClick(button.method)"
        >
          {{button.text}}
        </mgc-raised-button>
      </ng-container>
  </mgc-dialog-actions>

</mgc-dialog-popup>
