<rs-uploader [style.font-size]="0" renderAs="content" [options]="uploaderOptions"
    (onAfterAddingFile)="onAfterAddingFile($event)" (onBeforeUpload)="onBeforeUploadFile($event)"
    (onSuccess)="onUploadSuccess($event)" (onError)="onUploadError($event)">
</rs-uploader>

<mgc-raised-button (click)="browseClick()">Browse ...</mgc-raised-button>
<mgc-loader *ngIf="loading"></mgc-loader>


<alert></alert>

<ng-container *ngIf="records.total.length > 0">
    <ng-container *ngFor="let data of summary">
        <p *ngIf="records[data].length" class="{{data}}">
            <span># {{data}}</span>
            <span> {{records[data].length | number}}</span>
            <small *ngIf="records[data].length < 100"
                (click)="toggleShow(data)">{{_type===data && show ? 'close details' : 'details'}}</small>
        </p>
    </ng-container>
</ng-container>

<ng-container *ngIf="show">
    <ng-container *ngTemplateOutlet="recordsTpl;context:ctx"></ng-container>
</ng-container>


<ng-template #recordsTpl let-type="type">
    <table class="records {{type}}" *ngIf="records[type].length > 0">
        <thead>
            <tr>
                <th># </th>
                <th *ngFor="let h of headers">{{h.th}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let record of records[type]">
                <td> <span>{{record.row}}</span> </td>
                <td *ngFor="let h of headers"> <span>{{record[h.prop]}}</span> </td>
            </tr>
        </tbody>
    </table>
</ng-template>


<mat-checkbox [(ngModel)]="checked" name="checked" (change)="checkedChange($event)">Do NOT notify the participants</mat-checkbox>
