import { Injectable } from "@angular/core";
import { of as observableOf } from "rxjs";

@Injectable()
export class ModelService {
    orderImages(newOrder: Array<string>, note: any) {
        return observableOf(null);
    }
    remove(item: any, parent?: any) {
        return observableOf(null);
    }
}
