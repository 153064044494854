import {
    Component, ElementRef, EventEmitter,
    Input, OnDestroy, OnInit, Output, QueryList,
    SimpleChanges, ViewChild, ViewChildren, AfterViewChecked
} from '@angular/core'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { takeWhile, take, map } from 'rxjs/operators';
import { Observable, fromEvent, forkJoin } from 'rxjs';

import { GalleryService } from '../services/gallery.service'
import { ISize, SIZES } from '../size.class';
import { GalleryImage } from '../gallery-image.model';

import { BoardViewService } from '@board/_services/board-view.service';

export const CONSTRAIN_SIZE: ISize = {
    width: 400, height: 300
}

@Component({
    selector: 'gallery-thumbnail',
    templateUrl: './gallery-thumbnail.component.html',
    styleUrls: ['./gallery-thumbnail.component.scss'],
    providers: [GalleryService],
    animations: [
        trigger('imageAnimator', [
            state('revert', style({
                left: 0,
            })),
            state('swipe', style({
                left: 0,
                transform: '{{transform}}'
            }), { params: { transform: 'translateX(0px)' } }),
            state('stabilize', style({
                transform: '{{transform}}'
            }), { params: { transform: 'translateX(0px)' } }),
            transition('* => revert', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
            transition('* => swipe', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
            transition('* => stabilize', [
                style({
                    opacity: 1
                }), animate('10ms ease-out')
            ]),
        ]),

        trigger('galleryImageTransition', [
            state('true', style({
                opacity: 1
            })),
            state('void', style({
                opacity: 0
            })),
            transition('void => *', [
                style({
                    opacity: 0
                }),
                animate('300ms ease-in')]
            ),
            transition('* => void', [
                style({
                    opacity: 1
                }),
                animate('300ms ease-out')]
            )
        ]),
    ]
})
export class GalleryThumbnailComponent implements OnInit, OnDestroy, AfterViewChecked {
    private alive: boolean = true;
    gallery: Array<any> = []
    images: Array<any> = []
    minimalQualityCategory = 'medium'
    transition: any = '';

    horizontalPosition: number = 0;
    private prevHorizontalPosition: number = 0;
    private _translateX: number = 0;
    private currentIdx: number = 0;

    rightArrowActive: boolean = false;
    leftArrowActive: boolean = false;

    scrollX: number = 0;

    prevGalleryWidth: number = 0;

    private extraBufferLoad: number = 2;
    bufferLoad: number = 1;
    bufferCounter: number = 0;

    private scrollType: 'transform' | 'scroll' = 'transform';

    @Input() data: Array<any>;
    @Input() downloadable: boolean = false;
    @Input() withDetails: boolean = false;

    @Input('flexBorderSize') providedImageMargin: number = 30
    @Input('flexImageSize') providedImageSize: number = 10
    @Input('maxRowsPerPage') rowsPerPage: number = 1

    @Output() viewerChange = new EventEmitter<boolean>()

    @ViewChild('galleryContainer') galleryContainer: ElementRef
    @ViewChildren('imageElement') imageElements: QueryList<any>

    constructor(
        public galleryService: GalleryService,
        private boardView: BoardViewService
    ) {
    }

    private fetchDataAndRender(data: Array<any>): void {
        this.images = data.map((img) => new GalleryImage(img, SIZES));
        this.galleryService.updateImages(this.images)

        this.render();
    }

    private render(): void {
        this.gallery = [];

        for (let i = 0; i < this.images.length; i++) {
            let img: GalleryImage = this.images[i];
            if (img) {
                img['wrapperStyle'] = {
                    'background-color': '#555555',
                    'background-repeat': 'no-repeat',
                    'background-size': '150px 150px',
                    'background-position': '50% 50%',
                    'background-image': "url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA3MCA1MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLC02KSI+PHBhdGggc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MC4wNTtmaWxsLXJ1bGU6bm9uemVybyIgZD0ibTU3IDZoLTU2Yy0wLjU1IDAtMSAwLjQ1LTEgMXY0NGMwIDAuNTUgMC40NSAxIDEgMWg1NmMwLjU1IDAgMS0wLjQ1IDEtMXYtNDRjMC0wLjU1LTAuNDUtMS0xLTF6bS0xIDQ0aC01NHYtNDJoNTR6Ii8+PHBhdGggc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MC4wNTtmaWxsLXJ1bGU6bm9uemVybyIgZD0ibTE2IDI4YzMuMSAwIDUuNi0yLjUgNS42LTUuNiAwLTMuMS0yLjUtNS42LTUuNi01LjZzLTUuNiAyLjUtNS42IDUuNiAyLjUgNS42IDUuNiA1LjZ6bTAtOS4xYzIgMCAzLjYgMS42IDMuNiAzLjZzLTEuNiAzLjYtMy42IDMuNi0zLjYtMS42LTMuNi0zLjYgMS42LTMuNiAzLjYtMy42eiIvPjxwYXRoIHN0eWxlPSJmaWxsOiMwMDAwMDA7ZmlsbC1vcGFjaXR5OjAuMDU7ZmlsbC1ydWxlOm5vbnplcm8iIGQ9Im03IDQ2YzAuMjMgMCAwLjQ3LTAuMDgyIDAuNjYtMC4yNWwxNi0xNCAxMCAxMGMwLjM5IDAuMzkgMSAwLjM5IDEuNCAwczAuMzktMSAwLTEuNGwtNC44LTQuOCA5LjItMTAgMTEgMTBjMC40MSAwLjM3IDEgMC4zNCAxLjQtMC4wNjJzMC4zNS0xLTAuMDYyLTEuNGwtMTItMTFjLTAuMi0wLjE4LTAuNDYtMC4yNy0wLjcyLTAuMjYtMC4yNiAwLjAxMi0wLjUyIDAuMTMtMC42OSAwLjMybC05LjggMTEtNC43LTQuN2MtMC4zNy0wLjM3LTAuOTctMC4zOS0xLjQtMC4wNDRsLTE3IDE1Yy0wLjQyIDAuMzYtMC40NiAxLTAuMDkgMS40IDAuMiAwLjIyIDAuNDcgMC4zNCAwLjc1IDAuMzR6Ii8+PC9nPjwvc3ZnPg0K')"
                }
                this.gallery.push(img);
            }
        }
    }

    _imageStyle: Array<any> = [];
    getImageStyle(img: GalleryImage): string {
        return this._imageStyle[img.id];
    }
    setImageStyle(img: GalleryImage) {
        let w = img.width;
        let h = img.height;
        let x = 0;
        let y = 0;
        let transform: string;

        if (!img.model) {
            this._imageStyle[img.id] = {
                'background': 'trasnsparent',
                'min-width': `${w}px`,
                'max-width': `${w}px`,
                'height': `${h}px`,
            };
            return;
        }

        if (img.model.Rotation % 180 !== 0) {
            w = img.height;
            h = img.width;
        }

        transform = `translate(${x}px, ${y}px) rotate(${img.model.Rotation}deg) scale(${img.model.Scale})`;
        this._imageStyle[img.id] = {
            'background': 'trasnsparent',
            'max-width': `${w}px`,
            'min-width': `${w}px`,
            'height': `${h}px`,
            'transform': transform,
            'msTransform': transform,
            'webkitTransform': transform,
            'oTransform': transform,
        }
    }

    private rotated(img: GalleryImage, size: ISize): ISize {
        if (!img.model) {
            return size;
        }

        if (img.model.Rotation % 180 !== 0) {
            let ratio = size.height / size.width;
            size.width = size.height * ratio;
            size.height = size.width / ratio;
        }
        return size;
    }

    private constrainedSize(size: ISize, constrain: ISize = {
        width: this.idealWidth,
        height: this.idealHeight
    }) {
        let newWidth: number;
        let newHeight: number;
        let r: number;

        r = size.width / size.height
        newWidth = constrain.width;
        newHeight = constrain.width / r;

        if (newHeight > this.idealHeight){
            newHeight = constrain.height;
            newWidth = constrain.height * r;
        }

        return { width: newWidth, height: newHeight };
    }

    private adjustSize(img: GalleryImage, size: ISize = null) {
        if (!size) {
            return;
        }

        size = this.rotated(img, size);

        let constrainedSize = this.constrainedSize(size);
        img.width = constrainedSize.width;
        img.height = constrainedSize.height;

        this.setImageStyle(img);
    }

    revertWithAnimation() {
        setTimeout(() => {
            this.horizontalPosition = 0;
            this.transition = '';
        }, 250)
        this.transition = 'revert';
    }

    openImageViewer(img: any): void {
        this.galleryService.updateImages(this.images)
        this.galleryService.updateSelectedImageIndex(this.images.indexOf(img))
        this.galleryService.showImageViewer(true)
    }

    private get scrollDelta() {
        return this.idealWidth + this.imageMargin;
    }

    scrollRight() {
        let scrolled: number = this.scrollType === 'scroll' ? this.galleryContainer.nativeElement.scrollLeft : this.scrollX;
        let delta = this.scrollDelta;

        let remainedToScroll = this.calcOriginalGalleryWidth(this.gallery) - this.getGalleryContainerWidth() - this.scrollX + delta - this.imageMargin;
        if (remainedToScroll > 0) {
            scrolled = scrolled + delta;
        }

        if (this.scrollType === 'scroll') {
            this.galleryContainer.nativeElement.scrollLeft = scrolled;
        } else {
            this.scrollX = scrolled;
        }
        this.scaleGallery();
    }

    scrollLeft() {
        let scrolled: number = this.scrollType === 'scroll' ? this.galleryContainer.nativeElement.scrollLeft : this.scrollX;
        let delta = this.scrollDelta;

        let remainedToScroll = this.scrollX - delta;
        if (remainedToScroll > 0) {
            scrolled -= delta;
        } else {
            scrolled = 0;
        }

        if (this.scrollType === 'scroll') {
            this.galleryContainer.nativeElement.scrollLeft = scrolled;
        } else {
            this.scrollX = scrolled;
        }
        this.scaleGallery();
    }

    pan(swipe: any) {
        if (!this.boardView.smallView()) {
            return;
        }
        this.horizontalPosition = swipe.deltaX;
        if (swipe.isFinal) {
            this.navigate(swipe.deltaX);
        }
    }

    /**
     * direction (-1: left, 1: right)
     */
    navigate(swipeX: number): void {
        if (!this.smallView) {
            swipeX > 0 ? this.scrollRight() : this.scrollLeft();
            return;
        }

        let delta = this.scrollDelta;
        let x: number = this.prevHorizontalPosition;
        if (Math.abs(swipeX) > delta / 3 || Math.abs(swipeX) === 1) {
            if (swipeX > 0) {
                this.currentIdx = Math.max(0, this.currentIdx - 1);
            } else {
                this.currentIdx = Math.min(this.gallery.length - 1, this.currentIdx + 1);
            }
            x = Math.max(0, Math.min(this.currentIdx, this.gallery.length - 1)) * delta;
        }

        this.transition = { value: 'swipe', params: { transform: this.translateX(x) } };
        setTimeout(() => {
            this.transition = { value: 'stabilize', params: { transform: this.translateX(x) } };
            this.horizontalPosition = 0;
        }, 250)

        this.prevHorizontalPosition = x;

        this.scaleGallery();
    }

    translateX(scrollX: number = NaN) {
        if (isNaN(scrollX)) {
            return 'translateX(' + (this._translateX + this.horizontalPosition) + 'px)';
        }
        this._translateX = scrollX;
        return 'translateX(' + (-1 * scrollX) + 'px)';
    }

    get imageMargin(): number {
        const gcw = this.getGalleryContainerWidth();
        const ratio = gcw / 1920

        return Math.round(Math.max(1, this.providedImageMargin * ratio))
    }

    private loadImage(img: GalleryImage): Observable<boolean> {
        var image = new Image();
        img.loading = true;
        let $loadedImg = fromEvent(image, "load").pipe(
            take(1),
            map(event => {
                this.adjustSize(img, {
                    width: event.target['width'],
                    height: event.target['height']
                });
                img.src = img[this.minimalQualityCategory]['url'];
                img.naturalWidth = event.target['width'];
                img.naturalHeight = event.target['height'];
                img.loadedInGallery = true;
                img.loading = false
                img['wrapperStyle']['background-image'] = 'none';
                return true;
            })
        );
        image.src = img[this.minimalQualityCategory]['url'];
        return $loadedImg;
    }

    get smallView() {
        return this.boardView.smallView();
    }
    private get idealHeight(): number {
        return CONSTRAIN_SIZE.height;
    }
    private get idealWidth(): number {
        return this.boardView.smallView() ? this.getGalleryContainerWidth() - 2 * this.imageMargin : CONSTRAIN_SIZE.width;
    }

    private getGalleryContainerWidth(): number {
        if (this.galleryContainer.nativeElement.clientWidth === 0) {
            // for IE11
            return this.galleryContainer.nativeElement.scrollWidth
        }
        return this.galleryContainer.nativeElement.clientWidth
    }

    private calcOriginalGalleryWidth(galery: Array<any>): number {
        return (galery.length - 1) * (this.imageMargin + this.idealWidth);
    }

    private scaleGallery(): void {
        this.bufferCounter = 0;
        this.bufferLoad = Math.ceil(this.getGalleryContainerWidth() / SIZES[this.minimalQualityCategory].width) + this.extraBufferLoad;

        let loadImages: Observable<boolean>[] = [];
        this.gallery.forEach((img: GalleryImage, index) => {
            let inView = this.inView(img, index);
            if (!inView && !img.loadedInGallery) {
                this.bufferCounter++;
            }
            if (!(img.loading || img.loadedInGallery) && (inView || this.bufferCounter <= this.bufferLoad)) {
                loadImages.push(this.loadImage(img));
            }
        })

        if (loadImages.length) {
            forkJoin(loadImages).subscribe(() => {
                this.refreshNavigationState();
                this.prevGalleryWidth = this.getGalleryContainerWidth();
            });
        } else {
            this.refreshNavigationState();
            this.prevGalleryWidth = this.getGalleryContainerWidth();
        }
    }

    private inView(image: GalleryImage, index: number): boolean {
        if (!this.imageElements) {
            return false;
        }
        const imageElements = this.imageElements.toArray();
        let imageElement = imageElements[index].nativeElement;

        const elementRect = imageElement.getBoundingClientRect();
        const containerRect = this.galleryContainer.nativeElement.getBoundingClientRect();

        return (elementRect.right > containerRect.left && elementRect.left < containerRect.right)
    }

    private refreshNavigationState(): void {
        if (this.smallView) {
            setTimeout(() => {
                this.leftArrowActive = this.currentIdx > 0;
                this.rightArrowActive = this.currentIdx < this.gallery.length - 1;
            })
        } else {
            let ogw = this.calcOriginalGalleryWidth(this.gallery);
            let gcw = this.getGalleryContainerWidth()
            let leftToScroll = ogw - gcw - this.scrollX + this.scrollDelta;

            setTimeout(() => {
                this.leftArrowActive = this.scrollX > 0 || this.currentIdx > 0;
                this.rightArrowActive = leftToScroll > 0 || this.currentIdx > 0;
            })
        }
    }

    ngOnInit(): void {
        this.transition = 'stabilize';
        this.galleryService.showImageViewerChanged$.pipe(
            takeWhile(() => this.alive))
            .subscribe(
                (visibility: boolean) => this.viewerChange.emit(visibility)
            )
    }

    private prevScrollLeft: number = 0;
    ngAfterViewChecked() {
        let gcw: number = this.getGalleryContainerWidth();

        if (this.prevGalleryWidth > 0 && gcw !== this.prevGalleryWidth) {
            let ogw = this.calcOriginalGalleryWidth(this.gallery);
            let prevMax = Math.floor(ogw - this.prevGalleryWidth);

            // console.log(['ngAfterViewChecked WIDTH changed', gcw, ogw, this.prevGalleryWidth]);

            if (gcw > this.prevGalleryWidth && this.scrollX === prevMax) {
                this.scrollRight();
            } else {
                this.scaleGallery();
            }
        } else if (this.changed) {
            this.changed = false;
            this.scaleGallery();
        }
    }

    changed: boolean = true;
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {

            if (changes['data'].firstChange) {
                this.fetchDataAndRender(this.data);
                this.changed = true;
            } else {
                let ch = changes['data'].currentValue.filter(item => {
                    return !changes['data'].previousValue.find(i => i.id === item.id)
                })
                if (ch.length || changes['data'].currentValue != changes['data'].previousValue) {
                    this.fetchDataAndRender(this.data);
                    this.changed = true;
                }
            }
        }

    }

    ngOnDestroy(): void {
        this.alive = false;
    }
}
