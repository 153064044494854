import { Component } from '@angular/core';
import { ANIMATION_STATE_FORWARD, slideForwardAndBackward } from '@libraries/surveys/surveys/slide-animation';
import { SurveyComponent } from '@libraries/surveys/surveys/survey.component';
import { SurveyService } from '@libraries/surveys/surveys/survey.service';
import { SurveyOverlayService } from '@libraries/surveys/_overlay/survey-overlay.service';
import { AuthService } from '@rallysite/auth-service';
import { UserService } from '@rallysite/user';
import { AssessmentService } from './assessment.service';

@Component({
  selector: 'assessment',
  templateUrl: './../../surveys/survey.component.html',
  styleUrls: ['./../../surveys/survey.component.scss'],
  animations: [slideForwardAndBackward]
})
export class AssessmentComponent extends SurveyComponent {

  readonly SURVEY_ID = 1;

  constructor(
    protected assessmentService: AssessmentService,
    surveyService: SurveyService,
    surveyOverlay: SurveyOverlayService,
    authService: AuthService,
    userService: UserService
  ) {
    super(surveyService, surveyOverlay, authService, userService);
  }

  onAuthClose(ev) {
  }

  next(): null | void {
    this.updateProgress();

    if (!this.hasNext || this.processing) {
      return;
    }

    this.questionIndex++;
    this.processAnimation(ANIMATION_STATE_FORWARD);
  }


  protected getQuestions() {
    return this.assessmentService.getQuestions({
      surveyId: this.SURVEY_ID
    });
  }

  protected getAnswers() {
    return this.assessmentService.getAnswers({
      surveyId: this.SURVEY_ID,
      userId: this.userService.user ? this.userService.user.Id : null
    });
  }

  protected postAnswers() {
    return this.assessmentService.postAnswers({
      'survey_id': this.SURVEY_ID,
      'options': this.getAnsweredOptions()
    }, {
      surveyId: this.SURVEY_ID
    });
  }
}
