import { finalize, take } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ErrorStateMatcher } from '@angular/material/core';
import { MustMatch } from './must-match';
import { AuthService } from '@rallysite/auth-service';
import { AlertService } from '@rallysite/components/alert';
import { IPageAuthView } from '../page-auth-view.interface';
import { PageAuthPlaceId, PageAuthViewType } from '@page-auth';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import {CommunicationService} from '@board/communication-settings/service/communication.service';
import {ICommunicationOptions} from '@board/communication-settings/communications.types';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'page-sign-up',
  templateUrl: './page-sign-up.component.html',
  styleUrls: ['./page-sign-up.component.scss']
})
export class PageSignUpComponent implements IPageAuthView, OnInit {
  constructor(
    private auth: AuthService,
    private communicationService: CommunicationService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Inject(APP_CONFIG) public config: AppConfig
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.authForm.controls; }

  @Input() data: any;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  hidePassword = true;
  processing = false;

  payload: any;

  PageAuthPlaceId = PageAuthPlaceId;

  /** FORM */
  authForm: FormGroup;

  submitted = false;

  getEmail() {
    return  this.authForm.get('email').value || null;
  }
  bootstrapForm() {
    this.authForm = this.formBuilder.group({
      email: [this.data.email || '', [Validators.required, Validators.email]],
      agree: [false]
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.authForm.invalid) {
      return false;
    }

    this.alertService.clear();

    return true;
  }
  /** end of FORM */

  toggleAgree(matSlideToggle: MatSlideToggleChange) {
    this.authForm.patchValue({ agree: matSlideToggle.checked });
  }

  onSubmit() {
    if (!this.submit() || this.processing) {
      return;
    }

    this.processing = true;
    this.payload = Object.assign({}, this.authForm.value);

    this.auth.register_with_email(this.payload).pipe(take(1), )
      .subscribe(
        data => {
          if (typeof data.psw !== 'undefined') {
            this.payload.password = data.psw;
          }

          this.auth.cleanUser();
          this.login(this.payload);

          if (this.payload.agree === false) {
            const commPayload: ICommunicationOptions = {
              Email: this.authForm.get(['email']).value,
              Phone: 'false',
              CarrierId: null,
              unsubscribed: true,
              allowDirectEmails: null,
              emailRemindersFrequency: null,
              textRemindersFrequency: null,
              Week: '1111111',
            };

            // set this timeout just to be sure that the login is already happened
            setTimeout(() => {
              this.communicationService.saveSettings(commPayload)
                .subscribe();
            }, 500);
          }
        },
        error => {
          this.alertService.error(error);
          this.processing = false;
        });
  }

  private login(payload) {
    this.processing = true;
    this.auth.login(payload).pipe(take(1),
      finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          this.close.emit(PageAuthViewType.register);
        },
        error => {
          this.alertService.error(error);
        });
  }

  closeIt() {
    this.close.emit('close');
  }

  ngOnInit() {
    this.alertService.clear();
    this.bootstrapForm();
  }

}
