import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';

import { Project, } from '@board/items/project/project';
import { ProjectLibraryComponent } from '@board/items/project/project-library';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPublishSection } from './section.interface';
import { LANDING_HERO_IMAGE_RATIO } from './landing-hero.ratio';

const SHOWABLE_OPTIONS = [
  'showBackArrow',
  'showHero',
  'showBrandInfo',
  'showName',
  'showCarousel',
  'showDescription',
  'showShortDescription',
];

@Component({
  selector: 'publish-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss', ]
})
export class PublishLandingPageComponent implements IPublishSection, OnInit {

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private formBuilder: FormBuilder,
  ) {
  }
  // convenience getter for easy access to form fields
  get f() { return this.publishForm.controls; }
  get isDirty() {
    return this.publishForm.dirty;
  }

  get brand() {
    return this.project._pubData._brand || null;
  }
  @Input() project: Project;

  @ViewChild(ProjectLibraryComponent) projectLibrary: ProjectLibraryComponent;

  actionButton: any;

  landingHeroFrom: 'brand' | 'project' = 'project';

  imageRatio: number = 100 / LANDING_HERO_IMAGE_RATIO;

  /** FORM */
  publishForm: FormGroup;

  onLHChange(type) {
    this.publishForm.patchValue({ useLandingHeroFrom: type });
    this.makeFormDirty();
  }
  bootstrapForm() {
    this.publishForm = this.formBuilder.group(Object.assign({
      Description: [this.project.Description || ''],
      ShortDescription: [this.project.ShortDescription || ''],

      useLandingHeroFrom: [this.project.Settings.publish.useLandingHeroFrom]
    },
      (() => {
        const o: any = {};
        SHOWABLE_OPTIONS.forEach(prop => {
          o[prop] = this.project.Settings.publish[prop];
        });
        return o;
      })()
    ));
  }
  /** end of FORM */

  makeFormDirty() {
    if (this.publishForm) {
      this.publishForm.markAsDirty();
    }
  }

  markFormPristine() {
    if (this.publishForm) {
      this.publishForm.markAsPristine();
    }
  }

  submitForm() {
    if (this.publishForm.invalid) {
      return false;
    }

    return true;
  }

  formValues() {
    this.actionButton = this.project.Settings.publish.actionButton;

    return {
      Description: this.publishForm.value['Description'],
      ShortDescription: this.publishForm.value['ShortDescription'],
      Settings: {
        publish: Object.assign({
          useLandingHeroFrom: this.publishForm.value['useLandingHeroFrom']
        },
          (() => {
            const o: any = {};
            SHOWABLE_OPTIONS.forEach(prop => {
              o[prop] = this.publishForm.value[prop];
            });
            return o;
          })())
      }
    };
  }

  requests() {
    return [];
  }

  toggleOption(data: any) {
    this.publishForm.patchValue({ [data.option]: data.value });
    this.makeFormDirty();
  }

  ngOnInit() {
    this.actionButton = this.project.Settings.publish.actionButton;

    this.bootstrapForm();
  }

}
