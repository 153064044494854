import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { AlgoTagAction, IAlgoTag } from './algotag/algotag.model';

@Pipe({ 
  name: 'attached',
 })
export class AttachedPipe implements PipeTransform {

  constructor(
  ) { }

  transform(tags: IAlgoTag[], changed: number): IAlgoTag[] {
    const t = tags.filter((tag: IAlgoTag) => tag._httpAction !== AlgoTagAction.DETACH)
    console.log(t);
    return t;
  }
}

@NgModule({
  declarations: [
    AttachedPipe,
  ],
  exports: [
    AttachedPipe,
  ]
})
export class AlgoTagsPipesModule { }