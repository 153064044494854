<form [formGroup]="authForm" [style.padding-left.px]="0">

  <mgc-form-field label="Please confirm your password" [invalid]="submitted && f.password.errors">
    <div class="field-wrapper">
      <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" name="password" autocomplete="password">
      <span class="placeholder" *ngIf="!f.password.value">Enter your password here ...</span>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.email.errors">
      <div *ngIf="f.email.errors.required">Email is required</div>
    </ng-container>
    <ng-container invalid-feedback *ngIf="submitted && f.password.errors">
      <div *ngIf="f.password.errors.required">Password is required</div>
      <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
    </ng-container>
  </mgc-form-field>

  <!-- <mat-checkbox class="mgc-form-control" formControlName="agree" name="agree">I agree to the <a [attr.href]="'/legal/terms' | url:'platform'" target="_blank">Terms and
      Conditions and Privacy Policy</a>
  </mat-checkbox> -->

</form>