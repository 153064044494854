import { generateId } from "@items/task-v2/blocks/generate_block_id";
import { EmailImage } from "../../email-image/email-image";
import { EMAIL_BLOCKS_PREFIX, EmailBlockType } from "../block-types";

export class ImageBlock {
  id: string;
  type: string;
  guid: string;
  _image: EmailImage;
  set image(value) {
    this._image = value instanceof EmailImage ? value : new EmailImage(value);
  }
  get image() {
    return this._image;
  }

  _state: string;

  constructor(data: any = null) {
    data = {
      id: generateId(`${EMAIL_BLOCKS_PREFIX.IMAGE}_`),
      type: EmailBlockType.IMAGE,
      guid: this._guid,
      ...data,
    };
    for (let prop in data) {
      this[prop] = data[prop];
    }
  }

  get _guid() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  toDb() {
    return {
      id: this.id,
      type: this.type,
      guid: this.guid,
      image: this.image.toDb(),
    };
  }

  toJSON() {
    return this.toDb();
  }
}
