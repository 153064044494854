import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MgcButtonModule, MgcFormFieldModule } from '@rallysite/mgc-components';
import { TooltipOverlayComponent } from './tooltip-overlay.component';
import { TooltipOverlayService } from './tooltip-overlay.service';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipesModule } from '@rallysite/global-pipes';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MgcButtonModule,
        MgcFormFieldModule,
        OverlayModule,
        SafePipesModule
    ],
    declarations: [
        TooltipOverlayComponent,
    ],
    providers: [
        TooltipOverlayService,
    ],
    exports: []
})
export class TooltipOverlayModule { }
