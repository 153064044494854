import { NgModule, Pipe, PipeTransform, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { BrandAvatarComponent } from './brand-avatar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Brand } from '@board-brands/brand.model';

@Pipe({ name: 'brandAvatarUrl' })
export class BrandAvatarUrlPipe implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  transform(brand: Brand): string {
    return this.config.endpoint + '/api/brands/avatars/' + brand.Id + '?t=' + brand._timestamp;
  }
}

@Pipe({ name: 'avatarBlobUrl' })
export class AvatarBlobUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(file: any): SafeResourceUrl {
    let url = URL.createObjectURL(file.rawFile);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    declarations: [
        BrandAvatarComponent,
        BrandAvatarUrlPipe,
        AvatarBlobUrlPipe
    ],
    exports: [
        BrandAvatarComponent,
        BrandAvatarUrlPipe
    ]
})
export class BrandAvatarModule { }
