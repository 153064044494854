<mgc-loader *ngIf="loading"></mgc-loader>

<mat-accordion *ngIf="!loading" displayMode="flat" class="mgc-accordion" multi="true">

    <mat-expansion-panel class="accordion-asset" *ngFor="let panel of algoTagPanels" (opened)="panel.expanded=true;"
        (closed)="panel.expanded=false;" [expanded]="panel.expanded">

        <mat-expansion-panel-header class="asset-header">
            <span [innerHTML]="panel.label"></span>
        </mat-expansion-panel-header>

        <div class="asset-content" [ngSwitch]="panel.category">
            <ng-container *ngSwitchCase="'custom'">
                <algotag-autocomplete [selectedTags]="selectedTags.custom"
                    (removeChange)="onRemoveChange('custom', $event)">
                </algotag-autocomplete>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.THEME">
                <algotag-single-select [category]="panelCategory.THEME_NEED"
                    [selectedTags]="filteredTags[panelCategory.THEME_NEED]"
                    (removeChange)="onRemoveChange('predefined', $event)">
                    <p>I want to struggle less with...</p>
                </algotag-single-select>

                <algotag-single-select [category]="panelCategory.THEME_GROWTH"
                    [selectedTags]="filteredTags[panelCategory.THEME_GROWTH]"
                    (removeChange)="onRemoveChange('predefined', $event)">
                    <p>I want to grow in...</p>
                </algotag-single-select>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.HEAD">
                <algotag-score *ngFor="let algotag of filteredTags[panelCategory.HEAD]" [tag]="algotag">
                </algotag-score>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.GROWTH">
                <algotag-score *ngFor="let algotag of filteredTags[panelCategory.GROWTH]" [tag]="algotag">
                </algotag-score>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.NEED">
                <algotag-score *ngFor="let algotag of filteredTags[panelCategory.NEED]" [tag]="algotag">
                </algotag-score>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.GENDER">
                <algotag-dropdown [category]="panelCategory.GENDER" [selectedTags]="filteredTags[panelCategory.GENDER]"
                    (removeChange)="onRemoveChange('predefined', $event)">
                </algotag-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.AGE">
                <algotag-dropdown [category]="panelCategory.AGE" [selectedTags]="filteredTags[panelCategory.AGE]"
                    (removeChange)="onRemoveChange('predefined', $event)">
                </algotag-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="panelCategory.PLEVEL">
                <algotag-dropdown [category]="panelCategory.PLEVEL" [selectedTags]="filteredTags[panelCategory.PLEVEL]"
                    (removeChange)="onRemoveChange('predefined', $event)">
                </algotag-dropdown>
            </ng-container>
        </div>

    </mat-expansion-panel>


</mat-accordion>
