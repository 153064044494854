import { Component, ChangeDetectionStrategy, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { TooltipOverlayRef } from './tooltip-overlay-ref';
import { AuthService } from '@rallysite/auth-service';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TooltipService } from '../tooltip.service';
import { Tooltip } from '../tooltip.model';

@Component({
  selector: 'tooltip-overlay',
  styleUrls: ['./tooltip-overlay.component.scss'],
  templateUrl: './tooltip-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(100, style({ opacity: 0 }))]),
    ]),
  ],
})
export class TooltipOverlayComponent implements OnInit, OnDestroy {

  get canEdit() {
    return this.isAdmin && this.editable;
  }

  constructor(
    private tooltipOverlayRef: TooltipOverlayRef,
    private tooltipService: TooltipService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {}
  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  @HostBinding('class.mgc-tooltip-header') menuClass = true;

  private alive = true;
  isAdmin = false;
  editMode = false;
  editable = false;
  processing = false;

  tooltip: Tooltip;

  /** FORM */
  form: FormGroup;

  submitted = false;
  bootstrapForm() {
    this.form = this.formBuilder.group({
      heading: [this.tooltip.Heading],
      content: [this.tooltip.Content],
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  onClose() {
    this.tooltipOverlayRef.close();
  }

  toogleEditable() {
    if (!this.tooltip.Id) {
      return;
    }

    this.editable = !this.editable;
    setTimeout(() => {
      // this.tooltipOverlayRef.updatePosition();
    });
  }

  onEdit() {
    if (!this.canEdit) {
      return;
    }

    this.bootstrapForm();
    this.editMode = true;
    this.tooltipOverlayRef.updateSize({ width: 400 });
    // this.tooltipOverlayRef.updatePosition();
  }

  onSave() {
    if (!this.canEdit || !this.submit() || this.processing) {
      return;
    }

    this.tooltip.Heading = this.form.get('heading').value;
    this.tooltip.Content = this.form.get('content').value;

    this.processing = true;
    this.tooltipService
      .update(this.tooltip)
      .pipe(
        take(1),
        finalize(() => {
          this.editMode = false;
          this.processing = false;
        })
      )
      .subscribe(() => {});

    this.editMode = false;
    this.tooltipOverlayRef.updateSize({ width: 'auto' });
    // this.tooltipOverlayRef.updatePosition();
  }

  ngOnInit() {
    this.authService
      .isAdminUser$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isAdmin) => (this.isAdmin = isAdmin));
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
