import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlgoTagsService } from '../algotags.service';
import { AlgoTagAction, IAlgoTag } from './algotag.model';
import { AlgoTagType } from './algotag.types';

@Component({
  selector: 'algotag-dropdown',
  templateUrl: './algotag-dropdown.component.html',
  styleUrls: ['./algotag.component.scss']
})
export class AlgoTagDropdownComponent implements OnInit {

  @Input() selectedTags: IAlgoTag[] = [];
  @Input() category: AlgoTagType;

  @Output() removeChange: EventEmitter<IAlgoTag> = new EventEmitter(null);

  categoryTags: IAlgoTag[] = [];
  removedTags: IAlgoTag[] = [];

  constructor(
    private algoTagService: AlgoTagsService
  ) {
  }

  add(tag: IAlgoTag) {
    tag._httpAction = AlgoTagAction.ATTACH;
    this.algoTagService.addTo(this.selectedTags, tag);
    this.algoTagService.removeFrom(this.categoryTags, tag);
    this.removeChange.emit(tag);
  }

  remove(tag: IAlgoTag) {
    tag._httpAction = AlgoTagAction.DETACH;
    this.algoTagService.addTo(this.categoryTags, tag);
    this.algoTagService.removeFrom(this.selectedTags, tag);
    this.removeChange.emit(tag);
  }

  ngOnInit() {
    this.algoTagService.getPredefinedAlgoTags()
      .pipe(take(1))
      .subscribe(tags => {
        this.categoryTags = tags.filter((tag: IAlgoTag) => tag.Type === this.category && !this.selectedTags.find(t => t.Id === tag.Id))
        this.algoTagService.sort(this.categoryTags);
      })
  }

}
