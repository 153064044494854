<div class="wrap">
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>About us</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="tab-content">
                        <h3>Mission:</h3>
                        <p>At RallySite our mission is to create tools and services that help people Do More Better.</p>

                        <h3>What is RallySite?</h3>
                        <p>RallySite is a intuitive social platform to help people plan, organize, share, and manage projects
                            from concept to completion. What we built is a project management and planning app for – project
                            planning, list keeping, sharing ideas, centralization, collaboration, organizing, prioritizing,
                            setting start dates and due dates, assigning accountability, and delivery enhancement on projects
                            of all types and sizes. </p>

                        <h3>Use Cases:</h3>
                        <p>Manage a software development project, Plan a vacation, Share one file, Share a thousand files, Plan
                            a wedding, Manage a marketing initiative, Organize an anniversary party, Manage a home remodel
                            project, Start a new business, Organize a company, Organize a sports team, Plan for college,
                            What's your project?</p>

                        <h3>Unlimited Free Use:</h3>
                        <p>Yeah, go ahead and throw your hands in the air and keep your credit card in your pocket. RallySite
                            is 100% free. All features, unlimited projects, users, files, and communications. As great as
                            it is to save that $$$, we think there is a more compelling reason to be excited about free.
                            Imagine you want to use RallySite to centralize communications file sharing with a thousand business
                            accounts, or share your wedding details with hundreds of guests. With RallySite you can do all
                            of those things for free. We love it when you share!!</p>

                        <h3>Team:</h3>
                        <p>RallySite is a small team of people who are passionate and dedicated to our mission. We both make
                            software / the RallySite platform and offer consulting services around Project Management, Productivity,
                            Ad Operations, and Product.</p>

                        <h3>Working Culture:</h3>
                        <p>RallySite is about people. Our users, partners, and employees are our most important assets. Good
                            software is the output of great people.</p>

                        <h3>Who can work at RallySite?</h3>
                        <p>Everyone. Although we have a limited paying positions, we have unlimited internship opportunities
                            and it's where we pull our paid employees from.</p>

                        <h3>Where do people work?</h3>
                        <p>Everywhere. We're a virtual team located across the globe. When we do get together in person it's
                            at a table to break bread and socialize. Otherwise we work when and where it makes the most sense.</p>

                        <h3>How do we work?</h3>
                        <p>Really well. The flexibility of time and place allow more work production and more time for the reasons
                            we work. With tools like Skype, Join.ME, and RallySite we're exactly as connected as we need
                            to be.</p>

                        <h3>Contact:</h3>
                        <p>For all inquires, including advertising please send an email to Contact&#64;RallySite.com</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<footer>
    <div class="page-footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p> © Copyright 2017, HiveBoard LLC, All rights reserved. </p>
                </div>
            </div>
        </div>
    </div>
</footer>