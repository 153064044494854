<div class="mgc-panel-header mgc-vcard">
  <h2 class="modals-header">Modals</h2>
</div>

<div class="mgc-panel-header mgc-vcard" *ngIf="!loading">
  <mgc-raised-button class="new-button" [matMenuTriggerFor]="createNewModalMenu"><span>New Modal</span>
  </mgc-raised-button>
  <mat-menu #createNewModalMenu="matMenu" yPosition="below" xPosition="before">
    <button mat-menu-item (click)="addModal('assessment')">Assessment</button>
    <button mat-menu-item (click)="addModal('streak')">Streak</button>
    <button mat-menu-item (click)="addModal('missed')">Missed</button>
  </mat-menu>
</div>

<div #body id="nav-body" class="mgc-panel-body mgc-vcard">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" [style.height.px]="3">
  </mat-progress-bar>
  <div *ngIf="!loading" class="mgc-scroll-area">

    <mgc-nav-list>
      <mgc-list-item *ngIf="!modals?.length" class="message-item">
        No modals found!
      </mgc-list-item>

      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let modal of modals; trackBy: trackByFn" class="sortable-item" cdkDrag>
          <mgc-list-item draggable
                         [class.selected]="selectedModal && selectedModal.Id === modal.Id"
                         (click)="openModal(modal)"
          >
            <div class="content">
              <span>{{ modal.InternalName ? modal.InternalName : '(empty)' }} &mdash; {{ modal.ModalType }}</span>
              <ul>
                <li>
                  <span>{{ modal.CreateDate | amFromUtc | amTz | amDateFormat:'MMM D, YYYY'}}</span>
                </li>
              </ul>
            </div>
          </mgc-list-item>
        </div>
      </div>
    </mgc-nav-list>
  </div>
</div>
