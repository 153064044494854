import { Component, Input, HostBinding, Directive } from "@angular/core";
import { trigger, transition, useAnimation } from "@angular/animations";
import { fadeInX, fadeOutX } from "@animations";


@Directive({
    selector: 'mgc-divider[absolute-position]'
})
export class MgcDividerAbsoluteDirective {
    @HostBinding('style.position') position = 'absolute';
    @HostBinding('style.left') left = 0;
    @HostBinding('style.bottom') bottom = 0;
}

@Component({
    selector: 'mgc-divider',
    template: `
        <div class="line"></div>
    `,
    styles: [`
        :host{
            display: block;
            width: 100%;
            padding: 0 1rem;
            height: 1px;
            box-sizing: border-box;
            font-size: 0;
        }
        :host.fw{
            padding: 0;
        }
        .line{
            width: 100%;
            height: 100%;
            border-top-width: 1px;
            border-top-style: solid;
            border-top-color: currentColor;
        }
    `],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', useAnimation(fadeInX())),
            transition(':leave', useAnimation(fadeOutX())),
        ]),
    ]
})
export class MgcDividerComponent {
    @Input('full-width') fullWidth: boolean = false;

    @HostBinding('class.mgc-divider') dividerClass = true;
    @HostBinding('class.fw') get fullWidthClass() {
        return this.fullWidth;
    }

    @HostBinding('@fadeInOut') fadeInOut = true;


}
