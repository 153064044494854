import {
  Component, EventEmitter,
  HostBinding, Input, OnInit, Output,
  ViewChild
} from '@angular/core';
import { PageAuthViewType } from '@page-auth';
import { AuthService } from '@rallysite/auth-service';
import { AuthInlineDirective } from '@rallysite/auth/auth-inline/auth-inline.directive';
import { User, UserService } from '@rallysite/user';
import { forkJoin } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { ISurveyQuestionOption } from '../models/survey-question-option.model';
import { ISurveyQuestion } from '../models/survey-question.model';
import {
  SURVEY_CONTINUE,
  SURVEY_QUIT,
  SURVEY_SAVE,
  ISurveyOverlayRefResponseData
} from '../_overlay/survey-overlay-interfaces';
import { SurveyOverlayService } from '../_overlay/survey-overlay.service';
import {
  ANIMATION_STATE_BACKWARD,
  ANIMATION_STATE_FORWARD,
  slideForwardAndBackward
} from './slide-animation';
import { SurveyService } from './survey.service';
import {AuthInlineTempDirective} from '@rallysite/auth/auth-inline-temp/auth-inline-temp.directive';

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  animations: [slideForwardAndBackward]
})
export class SurveyComponent implements OnInit {
  @HostBinding('class.mgc-survey') surveyClass = true;

  @ViewChild(AuthInlineTempDirective) authInlineDirective: AuthInlineTempDirective;

  @Input() surveyId: string;
  @Input() brandId: string;

  @Output() close: EventEmitter<string> = new EventEmitter<null>();

  firstTimeSurvey: boolean = true;
  haveDefaults: boolean = false;
  surveySaved = false;

  loading = false;
  processing = false;
  ready = false;
  error = false;
  authenticate = false;
  isBrowser = false;

  animationDirection = ANIMATION_STATE_FORWARD;
  changingQuestion = false;

  questions: ISurveyQuestion[] = [];
  currentQuestion: ISurveyQuestion;

  requiredQuestions: ISurveyQuestion[] = [];

  private _questionIndex: number;
  set questionIndex(value: number) {
    this._questionIndex = value;
    this.currentQuestion = this.questions[this.questionIndex];
  };
  get questionIndex() {
    return this._questionIndex;
  }

  constructor(
    protected surveyService: SurveyService,
    protected surveyOverlay: SurveyOverlayService,
    protected authService: AuthService,
    protected userService: UserService
  ) {
  }

  get allowFinish() {
    const mustAnswer = this.requiredQuestions.filter(q => !q.selectedOption && !q.previousOption)
    return !mustAnswer.length;
  }

  get allowSkip() {
    const required = this.currentQuestion && this.currentQuestion.Required;
    const hasSelected = this.currentQuestion.selectedOption || this.currentQuestion.previousOption;

    return !required || (required && hasSelected);
  }

  get hasNext() {
    return this.questionIndex < this.questions.length - 1;
  }
  get hasPrev() {
    return this.questionIndex >= 1;
  }
  next(): null | void {
    this.updateProgress();

    if (this.processing) return;

    if (!this.hasNext && !this.haveDefaults && this.allowFinish) {
      this.handleOverlayResponse({ action: SURVEY_SAVE })
      return;
    }

    if (!this.hasNext) {
      return;
    }

    this.questionIndex++;
    this.processAnimation(ANIMATION_STATE_FORWARD);
  }

  prev() {
    this.updateProgress();

    if (!this.hasPrev || this.processing) {
      return;
    }

    this.questionIndex--;
    this.processAnimation(ANIMATION_STATE_BACKWARD);
  }

  protected updateProgress() {
    this.error = false;
    this.haveDefaults = !!this.questions.find(q => !q.Required && !!q.defaultOption)
  }

  finish(event: MouseEvent) {
    if (this.processing) {
      return;
    }

    if (!this.allowFinish) {
      this.surveyOverlay.open(event.currentTarget, {
        message: `To personalize your experience we need to know more about your Spiritual life.
                  <br><br>
                  Are you sure you want to quit now?
                  `,
        actions: [SURVEY_CONTINUE, SURVEY_QUIT]
      }).afterClosed().subscribe((response: ISurveyOverlayRefResponseData) => {
        this.handleOverlayResponse(response)
      });
      return;
    }

    if (this.haveDefaults) {
      this.surveyOverlay.open(event.currentTarget, {
        message: `By quitting now you will save some general system defaults and that will affect your personalizations. See those items marked in red.
                  <br><br>
                  Are you sure you don’t want to just finish all the questions and get a better more personal to you experience?
                  `,
        actions: [SURVEY_CONTINUE, SURVEY_SAVE]
      }).afterClosed().subscribe((response: ISurveyOverlayRefResponseData) => {
        this.handleOverlayResponse(response)
      });
      return;
    }

    this.save();
  }

  protected save() {
    this.processing = true;
    return this.authService.authenticate().subscribe(user => {
      if (user) {
        this.saveAnswers();
        return;
      }

      this.authenticate = true;
      /**
       * will trigger onAuthClose() methos below
       */
      this.authInlineDirective.onAuth(PageAuthViewType.email, null, 'To see how you stack up enter your email.');
    })
  }

  protected saveAnswers(email: string = null) {
    this.processing = true;
    this.error = false;
    this.postAnswers(email).pipe(take(1),
      finalize(() => this.processing = false))
      .subscribe(result => {
        if (result) {
          this.close.next('saved');
          this.surveySaved = true;
        } else {
          this.error = true;
        }
      })
  }

  /**
   * @param event  instance of User || {event: 'cancel', email: string}
   */
  onAuthClose(event) {
    this.processing = false;
    this.authenticate = false;
    this.authInlineDirective.clear();

    if (event instanceof User) {
      this.saveAnswers();
    } else if (event && event.email) {
      this.saveAnswers(event.email);
    }
  }

  private handleOverlayResponse(response: ISurveyOverlayRefResponseData) {
    response || (response = {});
    if (!response.action) {
      return
    }

    switch (response.action) {
      case SURVEY_SAVE:
        this.save();
        break;
      case SURVEY_QUIT:
        this.close.next('quit');
        break;
    }
  }

  protected processAnimation(animationDirection: string) {
    this.animationDirection = animationDirection;
    this.changingQuestion = true;
    setTimeout(() => this.changingQuestion = false);
  }

  private loadQuestions() {
    this.loading = true;

    forkJoin(
      this.getQuestions(),
      this.getAnswers()
    ).pipe(take(1),
      finalize(() => this.loading = false))
      .subscribe(values => {
        const questions = values[0];
        const answers = values[1];
        this.firstTimeSurvey = !answers || !answers.length;
        this.haveDefaults = this.firstTimeSurvey;

        if (answers) {
          questions.forEach(q => q.setPreviousOption(answers));
        }
        this.questions = questions;
        this.requiredQuestions = this.questions.filter(q => q.Required);
        this.questionIndex = 0;
        this.ready = !!questions.length;
      })
  }

  protected getAnsweredOptions() {
    return this.questions
      .filter(q => !!q)
      .map(q => {
        const answer = q.answer();
        return answer && !(answer instanceof Array) ? answer.Id : null
      });
  }

  selectOption(option: ISurveyQuestionOption) {
    if (this.processing) {
      return;
    }
    this.currentQuestion.selectedOption = option;
    this.next();
  }

  optionClass(option: ISurveyQuestionOption) {
    if (option === this.currentQuestion.selectedOption) {
      return 'selected-option';
    }

    if (option === this.currentQuestion.previousOption) {
      return 'prev-selected-option';
    }

    if (!this.currentQuestion.Required && option === this.currentQuestion.defaultOption) {
      return 'default-selected-option';
    }

    return '';
  }

  ngOnInit() {
    this.isBrowser = this.surveyService.isBrowser;
    this.loadQuestions();
  }

  protected getQuestions() {
    return this.surveyService.getQuestions({
      surveyId: this.surveyId
    });
  }

  protected getAnswers() {
    return this.surveyService.getAnswers({
      surveyId: this.surveyId,
      userId: this.userService.user ? this.userService.user.Id : null
    });
  }

  protected postAnswers(email: string = null) {
    const payload = {
      'SurveyId': this.surveyId,
      'BrandId': this.brandId,
      'options': this.getAnsweredOptions()
    }
    if (email) {
      payload['Email'] = email;
    }

    return this.surveyService.postAnswers(payload, {
      surveyId: this.surveyId
    });
  }

}
