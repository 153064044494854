import { isPlatformServer } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskV2BaseBlockComponent } from '../taskv2-base-block.component';
import { urlToSurveyiFrame } from '../video-block/url-to-iframe.helpers';
import { TaskV2SurveyBlock } from './survey-block';

@Component({
  selector: 'task-v2-survey-block',
  templateUrl: './survey-block.component.html',
  styleUrls: ['./survey-block.component.scss']
})
export class TaskV2SurveyBlockComponent extends TaskV2BaseBlockComponent {
  @HostBinding('class.no-shadow') get noShadowClass() {
    return this.preview;
  }

  constructor(
    el: ElementRef,
    formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super(el, formBuilder, platformId);
  }
  get f() { return this.form.controls; }

  @Input() block: TaskV2SurveyBlock;
  @Output() remove: EventEmitter<TaskV2SurveyBlock> = new EventEmitter(null);

  embed = '';

  /** FORM */
  form: FormGroup;

  submitted = false;
  bootstrapForm() {
    this.submitted = false;

    this.form = this.formBuilder.group({
      link: [this.block.Content, Validators.required],
    });
  }

  getProps() {
    return this.form.getRawValue();
  }
  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  private render() {
    if (isPlatformServer(this.platformId)) {
      this.embed = this.block.Content;
    } else {
      this.embed = urlToSurveyiFrame(this.block.Content, { el: this.el });
    }
  }

  onLinkChange(e) {
    if (this.submit()) {
      this.block.Content = this.form.value['link'];
      this.render();
    }
  }
  onRemove() {
    this.remove.emit(this.block);
  }

  ngOnInit() {
    if (!this.preview) {
      this.bootstrapForm();
    }
    this.render();
  }
}
