import { Injectable } from '@angular/core';

import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { IMultiGalleryCategory } from './multi-gallery.options.model';
import { ServiceStates, IServiceStates } from '@rallysite/global-interfaces';

@Injectable()
export class MultiGalleryService {
    static readonly STATES: IServiceStates = ServiceStates.STATES;

    private dataStore: any;
    private _loadingState: BehaviorSubject<number>;
    private _processingState: BehaviorSubject<number>;
    private _endPoint: string;

    private _files: BehaviorSubject<Array<any>>;

    constructor(
        private http: HttpClient,
    ) {
        this.dataStore = {};
        this._loadingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
        this._files = <BehaviorSubject<Array<any>>>new BehaviorSubject(null);
    }

    get loadingState$(): Observable<number> {
        return this._loadingState.asObservable();
    }
    get processingState$(): Observable<number> {
        return this._processingState.asObservable();
    }
    resetProcessingState$(): Observable<number> {
        this._processingState.next(-1);
        return this.processingState$;
    }

    get _files$(): Observable<any[]> {
        return this._files.asObservable();
    }

    set endPoint(value: string) {
        this._endPoint = value;
    }
    get endPoint(): string {
        return this._endPoint;
    }

    loadFiles(category: IMultiGalleryCategory) {
        this.endPoint = category.endPoint;

        if (this.inStore(category)) {
            return observableOf(this.getFromStore(category));
        }

        let paramsObj = {};
        for (let p in category.payload) {
            paramsObj[`filter[${p}]`] = category.payload[p];
        }
        paramsObj['orderBy[CreateDate]'] = 'DESC';
        let params = new HttpParams({
            fromObject: paramsObj
        });

        return this.http.get(this._endPoint, { params: params }).pipe(
            map((data) => {
                this.dataStore[category.id] = [];

                for (let key in data) {
                    this.addToStore(data[key], category.id);
                }
                return this.getFromStore(category);
            }),
            catchError(error => {
                console.log('Could not load files');
                return observableOf([]);
            }))
    }

    addSuccess(file: any, category: IMultiGalleryCategory) {
        return observableOf(file).pipe(
            map((data) => {
                this.prependTostore(file, category.id);

                return this.getFromStore(category);
            }),
            catchError(error => {
                console.log('Could not add figure.');
                return this.getFromStore(category);
            }))
    }

    // remove(figure: Figure, account: Persona) {
    //     this._processingState.next(FigureLibraryService.STATES.PROCESSING);
    //     this.http.delete(`${this._endPoint}/${figure.Id}`)
    //         .subscribe(response => {
    //             this.removeUI(figure);
    //             this._processingState.next(FigureLibraryService.STATES.DONE);
    //             this.fetch();

    //         }, error => {
    //             console.log('Could not delete figure.');
    //             if (error.status === 401) {
    //                 this._processingState.next(FigureLibraryService.STATES.UNAUTHORIZED);
    //             } else {
    //                 this._processingState.next(FigureLibraryService.STATES.ERROR);
    //             }
    //         })
    // }

    // private fetch() {
    //     let nFigures = Object.assign({}, this.dataStore[this.user.Id]);
    //     this._files.next(nFigures);

    //     if (nFigures.length > 0) {
    //         this._loadingState.next(FigureLibraryService.STATES.DONE);
    //     } else {
    //         this._loadingState.next(FigureLibraryService.STATES.EMPTY);
    //     }
    // }

    private addToStore(file: any, categoryId: string | number) {
        if (this.dataStore[categoryId]) {
            this.dataStore[categoryId].push(file);
        }
        return this;
    }

    private prependTostore(file: any, categoryId: string | number) {
        if (this.dataStore[categoryId]) {
            this.dataStore[categoryId].splice(0, 0, file);
        }
        return this;
    }

    private removeFromStore(file: any, categoryId: string | number) {
        if (this.dataStore[categoryId]) {
            this.dataStore[categoryId].forEach((f, i) => {
                if (f.Id === file.Id) {
                    this.dataStore[categoryId].splice(i, 1);
                }
            });
        }
        return this;
    }

    private cleanStore(categoryId: string | number) {
        if (this.dataStore[categoryId]) {
            this.dataStore.categoryId = null;
        }
    }

    private inStore(category: IMultiGalleryCategory): boolean {
        return !!this.dataStore[category.id];
    }

    private getFromStore(category: IMultiGalleryCategory) {
        return this.dataStore[category.id];
    }

}