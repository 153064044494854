import * as _moment from 'moment';
const moment = _moment;

export interface IItemStatus {
    id: string;
    name: string;
    class: string;
}

export const ITEM_STATUSES: Array<IItemStatus> = [
    { id: 'new', name: 'New', class: 'x-light-gray' },
    { id: 'active', name: 'In-Progress', class: 'x-green' },
    { id: 'inreview', name: 'In-Review', class: 'x-warning' },
    { id: 'attend', name: 'Attend', class: 'x-danger' },
    { id: 'complete', name: 'Complete', class: 'x-blue' },
    { id: 'dead', name: 'Dead', class: 'x-black' },
];

export class BaseItem {

    attributes: any;
    prevAttributes: any;

    __v(field: string) {
        const path = field.split('.');
        field = path.shift();
        if (path.length === 0) {
            return this.__val(field);
        } else {
            return this.__val(field, path);
        }
    }

    __val(field: any, path: Array<string> = []) {
        if (!path.length) {
            return this.prevAttributes[field] || null;
        }
        return path.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, this.prevAttributes[field]);
    }

    /**
     * Safely access nested objects.
     *
     * @param field ( e.g. Status)
     * @param path ( e.g. ['class'])
     */
    __obj(field: any, path: Array<string> = []) {
        const obj = this[`o_${field}`] || this[field];
        if (!path.length) {
            return this[`o_${field}`] || {};
        }

        return path.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, obj);
    }

    __update(data = null) {
        if (data) {
            for (const prop in data) {
                this[prop] = data[prop];
                this.__clone(prop);
            }
        } else {
            for (const p in this.attributes) {
                this.__clone(p);
            }
        }
    }

    __clone(prop: string) {
        if (this.attributes.hasOwnProperty(prop)) {
            if (this[prop] instanceof Object) {
                this.prevAttributes[prop] = JSON.parse(JSON.stringify(this[prop]));
            } else {
                this.prevAttributes[prop] = this[prop];
            }
        }
    }

    __revert(fields: Array<string> = null) {
        if (typeof fields === 'string') {
            fields = [fields];
        }
        if (fields) {
            for (const f in fields) {
                if (this.prevAttributes.hasOwnProperty(f)) {
                    this[f] = this.prevAttributes[f];
                }
            }
        } else {
            for (const p in this.prevAttributes) {
                this[p] = this.prevAttributes[p];
            }
        }
    }

    __only(fields: Array<string>) {
        let data: any;
        for (let i = 0; i < fields.length; i++) {
            data[fields[i]] = this.__val(fields[i]);
        }
        return data;
    }

    pristine(field: any) {
        return this.attributes[field] === this.prevAttributes[field];
    }

    __date(value) {
        return value ? moment(value).toDate() : null;
    }

    formatDbDate(date: Date, format: string = 'YYYY-MM-DD'): string {
        if (!date) { return null; }
        const d = moment(date).format(format);
        return d;
    }

    sanitize(data: any, except: string[] = []) {
        for (const prop in data) {
            if (data[prop] === null && except.indexOf(prop) < 0) {
                delete (data[prop]);
            }
        }
        return data;
    }

    string_to_slug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaaaeeeeiiiioooouuuunc------';

        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }

}
