import { Directive, ElementRef, Input, NgModule } from '@angular/core';
import { BoardViewService } from '@board/_services/board-view.service';

@Directive({
  selector: '[focusIndex]'
})
export class FocusIndexDirective {

  @Input() index: number = 1;

  constructor(
    el: ElementRef,
    private boardView: BoardViewService
  ) {
    el.nativeElement.tabIndex = this.boardView.smallView() ? -1 : this.index;
  }
}

@NgModule({
  imports: [
  ],
  declarations: [
    FocusIndexDirective,
  ],
  exports: [
    FocusIndexDirective
  ]
})
export class FocusIndexModule { }