import { generateId } from "../generate_block_id";
import { IModalBlock } from "../modal-block.interface";
import { ModalBlockAction, ModalBlockType, MODAL_BLOCK_PREFIX } from "../types";

export class ModalVideoBlock implements IModalBlock {
    Type: ModalBlockType = ModalBlockType.VIDEO;
    Id: string;
    ModalId: string;
    BlockId: string;
    Content: string = ''; // videoURL
    CreateDate: string;
    UpdateDate: string;

    _httpAction: ModalBlockAction = ModalBlockAction.UPDATE;

    _state: string;

    constructor(data: any = null) {
        data = { BlockId: generateId(`${MODAL_BLOCK_PREFIX.VIDEO}_`), ...data }
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            BlockId: this.BlockId,
            ModalId: this.ModalId,
            Type: this.Type,
            Content: this.Content,
            _httpAction: this._httpAction
        }
    }

    toJSON() {
        return this.toDb();
    }

}
