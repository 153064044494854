export interface ISize {
    width: number;
    height: number;
}

export const SIZES: {
    small: ISize,
    medium: ISize,
    large: ISize,
} = {
    small: {
        width: 500, height: 375
    },
    medium: {
        width: 1024, height: 768
    },
    large: {
        width: 1440, height: 1080
    }
}