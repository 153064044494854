import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  start(
    pageTitle: string,
    pagePath: string
  ) {
    if (this.config.ga_id &&
      isPlatformBrowser(this.platformId) && typeof gtag === 'function') {

      gtag('config', this.config.ga_id, {
        page_title: pageTitle,
        page_path: pagePath,
      })
    }
  }


  /**
   * https://developers.google.com/analytics/devguides/collection/gtagjs/events
   * 
   * @param action 
   * @param eventCategory 
   * @param eventLabel 
   * @param eventValue 
   */
  public eventEmitter(
    action: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (isPlatformBrowser(this.platformId) && typeof gtag === 'function') {
      gtag('event', action, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      })
    }
  }

  public platformEvent(action: string, eventLabel: string = null, eventValue: number = null  ) {
    // console.log(['platformEvent', action, 'platform', eventLabel, eventValue]);
    this.eventEmitter(action, 'platform', eventLabel, eventValue);
  }

}