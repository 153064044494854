import { Theme } from "../theme";
import { ITheme } from "../theme.interface";
import { ICustomColorOptions } from "../theme-options.interface";

export class CustomColorTheme extends Theme {
    id: string = 'custom_color';
    name: string = 'Custom Colors';

    constructor(iTheme: ITheme, colorOptions: ICustomColorOptions = {}) {
        super({
            ...iTheme, ...{
                primaryColor: colorOptions.primary || iTheme.primaryColor,
                navColor: colorOptions.navigation || iTheme.navColor,
            }
        }, null);
    }

}