<div class="inner">

    <div class="comment-header">
        <div class="left mgc-account">
            <account-avatar size="30" [account]="comment._account"></account-avatar>
            <span class="info">
                <span class="name" [innerHTML]="comment._account.OwnerFirstName"></span>
                <ng-container *ngIf="isSmallView">
                    <ng-container *ngTemplateOutlet="commentDetails"></ng-container>
                </ng-container>
            </span>
        </div>
        <span class="right" *ngIf="!isSnapshotTask">
            <ng-container *ngIf="!isSmallView">
                <ng-container *ngTemplateOutlet="commentDetails"></ng-container>
            </ng-container>
            <ng-container *ngIf="allowUpdate">
                <mgc-icon-button icon="board-mat:more_vert" [matMenuTriggerFor]="mgcMenu.menu"></mgc-icon-button>
                <mgc-menu #mgcMenu>
                    <mgc-menu-item *ngIf="!comment.isReply" [clipboard]="commentLink"
                        (clipboardCopy)="logSuccess( $event )" (clipboardError)="logError( $event )"
                        icon="board-mat:input">Copy link to comment
                    </mgc-menu-item>
                    <mgc-menu-item (click)="openCommentModal()" icon="board-mat:mode_edit">Edit</mgc-menu-item>
                    <mgc-menu-item (click)="removeComment()" icon="board-mat:delete">Delete</mgc-menu-item>
                </mgc-menu>
            </ng-container>
        </span>
    </div>

    <div class="comment-body" [class.reply]="comment.isReply">
        <comment-preview [text]="(comment.Text || '') | embed: ['embed']"></comment-preview>

        <attachments *ngIf="comment._attachments.length" [data]="comment._attachments" [model]="comment" preview>
        </attachments>
    </div>

    <div class="summary">
        <div class="reactions" *ngIf="reactionsData.length" (click)="openReactionsData($event)">
            <mgc-icon-button class="reaction-icon {{rd.type}}" *ngFor="let rd of reactionsData"
                icon="board-mat:{{rd.type}}">
            </mgc-icon-button>
            <span class="total-reactions" [innerHTML]="comment._reactions.length"></span>
        </div>

        <div class="replies" *ngIf="comment._replies">
            <span class="with-pipe" *ngIf="reactionsData.length"></span>
            <span (click)="repliesToggle($event)" [innerHTML]="repliesLabel"></span>
        </div>
    </div>

    <mgc-divider [full-width]="true" *ngIf="!isSnapshotTask"></mgc-divider>
    <div class="comment-footer" *ngIf="!isSnapshotTask">
        <div class="left">
          <div class='mgc-icon-button-wrapper' (click)="openReactions($event)">
            <mgc-icon-button
              [class]="reactionClass"
              [icon]="'board-mat:'+ (currentReaction ? currentReaction.Type : 'like')"
            >
            </mgc-icon-button>
            <span class='icon-title'>{{currentReaction ? currentReaction.Type : 'like'}}</span>
          </div>

            <ng-container *ngIf="!comment.isReply">
              <div class='mgc-icon-button-wrapper' (click)="openReplyModal()">
                <mgc-icon-button *ngIf="allowCreate" icon="board-mat:comment-reply">
                    <!-- <span class="with-pipe" *ngIf="comment._replies" (click)="repliesToggle($event)"
                        [innerHTML]="repliesLabel"></span> -->
                </mgc-icon-button>
                <span class='icon-title'>Reply</span>
              </div>
                <!-- <mgc-icon-button *ngIf="!allowCreate" icon="board-mat:comment-reply">
                    <span class="with-pipe" *ngIf="comment._replies" (click)="repliesToggle($event)"
                        [innerHTML]="repliesLabel"></span>
                </mgc-icon-button> -->
            </ng-container>
        </div>
    </div>
</div>


<mgc-comment-reply-list *ngIf="!comment.isReply && comment._showReplies && comment._replies"
    [note]="note"
    [taskv2BlocksMetadata]="taskv2BlocksMetadata"
    [commentParent]="comment"
    (onReplyRemoved)="replyRemoved()"></mgc-comment-reply-list>

<ng-template #commentDetails>
    <span class="details">
        <span>{{comment.CreateDate | amFromUtc | amLocal | amTimeAgo }} </span>
        <span *ngIf="comment.UpdateDate!==comment.CreateDate">( updated
            {{comment.UpdateDate | amFromUtc | amLocal | amTimeAgo }} )</span>
    </span>
</ng-template>
