<ng-container *ngIf="renderAsContent">
  <input type="file" cpFileSelect [uploader]="uploader" multiple #imageFile style="display: none;" />
  <ng-content></ng-content>
</ng-container>

<ng-container *ngIf="renderAsButton">
  <input type="file" cpFileSelect [uploader]="uploader" #imageFile style="display: none;" />
  <button (click)="imageFile.click()" class="button" [style.box-shadow]="'none'" [style.color]="'white'"
    [style.background]="'transparent'" mat-raised-button>{{ options.buttonText }}</button>
</ng-container>

<ng-container *ngIf="renderAsDefault">
  <ng-container *ngIf="uploader && uploader.queue.length === 0">
    <input type="file" cpFileSelect [uploader]="uploader" #imageFile style="display: none;" />
    <div class="drop-zone center" cpFileDrop [uploader]="uploader" [ngClass]="{'file-over-drop-zone': hasBaseDropZoneOver}"
      (fileOver)="fileOverBase($event)" (click)="imageFile.click()">
      <mat-icon svgIcon="board-mat:photo_library"></mat-icon>
      <div class="display-text">{{ options.displayText }}</div>
      <div class="subheading-text">- or -</div>
      <button class="button" mat-raised-button>{{ options.buttonText }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="uploader && uploader.queue.length > 0">
    <div class="image-wrapper" *ngFor="let item of uploader.queue">
      <div class="title ellipsis">Upload file:
        <strong>{{ item?.file?.name }}</strong>
      </div>
      <div class="image" *ngIf="item?.file">
        <img [class.alert]="state===states.ERROR" [src]="item?.file | blobUrl">
      </div>

      <div class="actions" *ngIf="!item.isUploading">
        <button mat-button color="primary" (click)="upload()" *ngIf="state!==states.DONE&&!options.autoUpload">Upload</button>
        <button mat-button class="retry" (click)="clearQueue()">Upload a different file</button>
      </div>
      <ng-container *ngIf="item.isUploading">
        <div class="progress-wrapper">
          <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>