import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PageAuthModule } from '@page-auth/page-auth.module';
import { AlertModule } from '@rallysite/components/alert';
import { StringPipesModule } from '@rallysite/global-pipes';
import { MgcButtonModule, MgcFormFieldModule } from '@rallysite/mgc-components';
import { AuthInlineTempComponent } from './auth-inline-temp-component';
import { AuthInlineTempDirective } from './auth-inline-temp.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatCheckboxModule,

    MgcFormFieldModule,
    MgcButtonModule,

    AlertModule,
    PageAuthModule,

    StringPipesModule,
  ],

  declarations: [
    AuthInlineTempComponent,
    AuthInlineTempDirective,
  ],
  exports: [
    AuthInlineTempDirective,
    PageAuthModule
  ]

})
export class AuthInlineTempModule { }
