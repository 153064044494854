import { Inject, Injectable } from '@angular/core';
import { WINDOW } from './window.service';

@Injectable({
    providedIn: 'root'
})
export class MobileJsBridgeService {

    existBridge: boolean = false;

    constructor(
        @Inject(WINDOW) private w: Window
    ) {
        if (this.w['RSHandler']) {
            this.existBridge = true;
        } else {
            console.log('MobileJSBridge not implemented; RSHandler missing');
        }
    }

    appLoaded(code: number, data?: string) {
        if (!this.existBridge) return;

        if (typeof this.w['RSHandler'].rsLoadedInt === 'function') {
            this.w['RSHandler'].rsLoadedInt(code);
        } else {
            console.log('rsLoadedInt() not implemented');
        }

        if (code === 1 && data && typeof this.w['RSHandler'].setTopics === 'function') {
            this.w['RSHandler'].getTopics = function () {
                return data;
            }
            this.w['RSHandler'].getUrl = function () {
                return this.w.location.href;
            }

            this.w['RSHandler'].setTopics(data);
        }
    }

    signOut() {
        if (!this.existBridge) return;

        if (typeof this.w['RSHandler'].signOut === 'function') {
            this.w['RSHandler'].signOut('Signed Out');
        }
        else {
            console.log('signOut() not implemented');
        }
    }

    warning(message: string) {
        if (!this.existBridge) return;

        if (typeof this.w['RSHandler'].rsWarn === 'function') {
            this.w['RSHandler'].rsWarn(message);
        }
    }

    download(url: string) {
        if (!this.existBridge) return;

        if (typeof this.w['RSHandler'].rsDownload === 'function') {
            this.w['RSHandler'].rsDownload(url);
        }

        if (typeof this.w['RSHandler'].iosRsDownload === 'function') {
           this.w.location.href = 'iosrallysite://rallysite.com?url=' + url;
        }
    }

    updateLocation(){
        if (!this.existBridge) return;
        
        if (typeof this.w['RSHandler'].iosUpdateLocation === 'function') {
            this.w.location.href = 'iosrallysiteurl://rallysite.com?url=' + window.location.href;
         }
    }

}