import { AlgoTagType } from "./algotag.types";

export type AlgoTagScore = 0 | 1 | 2 | 3 | 4;

export enum AlgoTagAction {
    ATTACH = 'attach',
    UPDATE = 'update',
    DETACH = 'detach',
    IDLE = 'idle'
}

export interface IAlgoTagBase {
    Id?: number;
    TagToItemId?: number;
    Name: string;
    _httpAction?: AlgoTagAction

    toDb?();
    toJSON?();
    isDirty?();
    toggleAttachDetachAction?(action: AlgoTagAction);
}

export interface IAlgoTag extends IAlgoTagBase {
    Type: AlgoTagType;
    Score: AlgoTagScore;

    setScore(val: AlgoTagScore): IAlgoTag;
}

export class AlgoTagBase implements IAlgoTagBase {
    Id: number;
    TagToItemId: number;
    Name: string;

    _httpAction: AlgoTagAction = AlgoTagAction.IDLE;
    private _prevHttpAction: AlgoTagAction

    constructor(data: IAlgoTagBase) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toggleAttachDetachAction(action: AlgoTagAction) {
        if (!this._prevHttpAction) {
            this._prevHttpAction = this._httpAction;
            this._httpAction = action;
        } else {
            this._httpAction = this._prevHttpAction;
            this._prevHttpAction = null;
        }
    }

    isDirty() {
        if (!this._httpAction) return false;

        const newAttached = !this.TagToItemId && this._httpAction === AlgoTagAction.ATTACH;
        const detachedOrUpdated = this.TagToItemId && (this._httpAction === AlgoTagAction.DETACH || this._httpAction === AlgoTagAction.UPDATE);

        return newAttached || detachedOrUpdated;
    }

    toDb() {
        return {
            Id: this.Id,
            TagToItemId: this.TagToItemId,
            Name: this.Name,
            _httpAction: this._httpAction
        }
    }
    toJSON() {
        return {
            Id: this.Id,
            TagToItemId: this.TagToItemId,
            Name: this.Name,
            _httpAction: this._httpAction
        }
    }
}

export class AlgoTag extends AlgoTagBase {
    Id: number;
    TagToItemId: number;
    Name: string;
    Type: AlgoTagType;

    Score: AlgoTagScore = 0;

    _httpAction: AlgoTagAction = AlgoTagAction.IDLE;

    constructor(data: IAlgoTag) {
        super(data);
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    setScore(val: AlgoTagScore) {
        if (val === this.Score) {
            return;
        }

        this.Score = val;
        if (this._httpAction !== AlgoTagAction.ATTACH) {
            this._httpAction = AlgoTagAction.UPDATE;
        }
        return this;
    }

    toDb() {
        return {
            Id: this.Id,
            TagToItemId: this.TagToItemId,
            Name: this.Name,
            Type: this.Type,
            Score: this.Score,
            _httpAction: this._httpAction
        }
    }
    toJSON() {
        return {
            Id: this.Id,
            TagToItemId: this.TagToItemId,
            Name: this.Name,
            Type: this.Type,
            Score: this.Score,
            _httpAction: this._httpAction
        }
    }
}

