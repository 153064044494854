<mgc-dialog>

  <mgc-dialog-header class="danger" [title]="data.title" (close)="closeDialog()">
    <mat-icon icon svgIcon="board-mat:warning"></mat-icon>
  </mgc-dialog-header>

  <mgc-dialog-content>
      <p [innerHTML]="data.text"></p>
  </mgc-dialog-content>


  <mgc-dialog-actions>
    <mgc-raised-button left [processing]="processing" (click)="delete()">{{data.action || 'Delete'}}</mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>

