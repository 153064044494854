<mat-accordion displayMode="flat" class="mgc-accordion">

  <mat-expansion-panel class="accordion-asset" *ngFor="let panel of panels" (opened)="togglePanel(panel.id, true)"
    (closed)="togglePanel(panel.id, false)" [expanded]="accordion[panel.id]">

    <mat-expansion-panel-header class="asset-header">
      <span [innerHTML]="panel.title"></span>
      <mat-icon [svgIcon]="caret(panel.id)"></mat-icon>
    </mat-expansion-panel-header>

    <div class="asset-content" [ngSwitch]="panel.id">
      <publish-landing-page *ngSwitchCase="'page'" [project]="project"></publish-landing-page>
      <publish-fotameco *ngSwitchCase="'fotameco'" [project]="project"></publish-fotameco>
      <publish-settings *ngSwitchCase="'settings'" [project]="project" [brands]="brands"></publish-settings>
    </div>

  </mat-expansion-panel>


</mat-accordion>