import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuDefaultOptions, MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { MgcMenuItemComponent } from './menu-item.component';
import { MgcMenuComponent } from './menu.component';

@NgModule({
    imports: [
        CommonModule,

        MatMenuModule,
        MatIconModule,
        MatButtonModule

    ],
    declarations: [
        MgcMenuItemComponent,
        MgcMenuComponent
    ],
    exports: [
        MatMenuModule,
        MgcMenuItemComponent,
        MgcMenuComponent
    ],

    providers: [
        {
            provide: MAT_MENU_DEFAULT_OPTIONS,
            useValue: Object.assign({}, <MatMenuDefaultOptions>{
                overlapTrigger: true,
                xPosition: 'before',
            })
        }
    ]
})
export class MgcMenuModule {
}
