import { ModalImage } from "./image-block/image/modal-image";

export enum ModalBlockType {
    IMAGE = 'image',
    VIDEO = 'video',
    TEXT = 'text',
}

export const MODAL_BLOCKS: {
    type: ModalBlockType,
    name: string
}[] = [
        { type: ModalBlockType.TEXT, name: 'Text Block' },
        { type: ModalBlockType.IMAGE, name: 'Image Block' },
        { type: ModalBlockType.VIDEO, name: 'Video Block' },
    ]

export const MODAL_BLOCK_PREFIX = {
    IMAGE: 'img',
    TEXT: 'txt',
    VIDEO: 'vid',
}

export enum ModalBlockAction {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete'
}

export type ModalBlockContent = string | ModalImage;
