import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneInputV2Component } from './phone-input-v2.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { MgcFormFieldModule } from '@rallysite/mgc-components';

@NgModule({
  declarations: [PhoneInputV2Component],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,

    MgcFormFieldModule
  ],
  exports: [PhoneInputV2Component]
})
export class PhoneInputV2Module {
}
