import { finalize, take } from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IPageAuthView } from '../page-auth-view.interface';
import { AuthService } from '@rallysite/auth-service';
import { AlertService } from '@rallysite/components/alert';
import { PageAuthViewType } from '@page-auth';


@Component({
  selector: 'page-co-register',
  templateUrl: './page-co-register.component.html',
  styleUrls: ['./page-co-register.component.scss']
})
export class PageCoRegisterComponent implements IPageAuthView, OnInit {

  constructor(
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
  ) { }

  // convenience getter for easy access to form fields
  get f() { return this.authForm.controls; }

  @Input() data: any;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  hidePassword = true;
  processing = false;

  payload: any;

  /** FORM */
  authForm: FormGroup;

  submitted = false;


  getEmail() {
    return  this.authForm.get('email').value || null;
  }
  bootstrapForm() {
    this.authForm = this.formBuilder.group({
      email: [this.data.email || '', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      agree: [true]
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.authForm.invalid) {
      return false;
    }

    this.alertService.clear();
    if (!this.authForm.get('agree').value) {
      this.alertService.warning('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy');
      return false;
    }

    return true;
  }
  /** end of FORM */

  toggleAgree(matSlideToggle: MatSlideToggleChange) {
    this.authForm.patchValue({ agree: matSlideToggle.checked });
  }

  onSubmit() {
    if (!this.submit() || this.processing) {
      return;
    }

    this.processing = true;
    this.payload = Object.assign({}, this.authForm.value, { cpassword: this.authForm.value['password'] });
    this.auth.prepareRegisterWithEmailAndPassword(this.payload).pipe(take(1), )
      .subscribe(
        data => {
          this.auth.cleanUser();
          this.login(this.payload);
        },
        error => {
          this.alertService.error(error);
          this.processing = false;
        });
  }

  private login(payload) {
    this.processing = true;
    this.auth.login(payload).pipe(take(1),
      finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          // from this moment on no more co-register, but move to register path
          this.close.emit(PageAuthViewType.register);
        },
        error => {
          this.alertService.error(error);
        });
  }

  closeIt() {
    this.close.emit('close');
  }

  ngOnInit() {
    this.alertService.clear();
    this.bootstrapForm();
  }

}
