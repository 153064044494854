<project-avatar-edit [project]="project">
  <div class="avatar-info">You can change the icon to the logo you want (e.g. your Organization) for each plan.</div>
</project-avatar-edit>


<mat-radio-group class="mgc-radio-group" name="theme" [value]="selectedThemeUID" (change)="onThemeChange($event)">
  <div>
    <label>Dark Themes</label>
    <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of predefined_iThemes"
      [value]="radioOptionId({id: iTheme.id, type:'dark'})">
      <span [innerHTML]="iTheme.name"></span>
    </mat-radio-button>
  </div>

  <div>
    <label>Light Themes</label>
    <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of predefined_iThemes"
      [value]="radioOptionId({id: iTheme.id, type:'light'})">
      <span [innerHTML]="iTheme.name"></span>
    </mat-radio-button>
  </div>

  <div>
    <label>Custom Themes</label>
    <mat-radio-button class="mgc-radio-button" *ngFor="let iTheme of custom_iThemes"
      [value]="radioOptionId({id: iTheme.id, type:null})">
      <span *ngIf="activeTheme.id!==iTheme.id" [innerHTML]="iTheme.name"></span>
      <mgc-stroked-button *ngIf="activeTheme.id===iTheme.id" class="height-sm" (click)="openModal(iTheme.id)">
        <span [innerHTML]="iTheme.name"></span>
      </mgc-stroked-button>
    </mat-radio-button>
  </div>

</mat-radio-group>