import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClipboardDirective } from './clipboard.directive';
import { ClipboardService } from './clipboard.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ClipboardDirective],
  exports: [ClipboardDirective],
  providers: [ClipboardService]
})
export class ClipboardModule { }