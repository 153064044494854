<alert [service]="[serviceAlertClass.UMBRELLA_LIST]"></alert>

<mgc-nav-list>
    <ng-container *ngIf="state===states.LOADING">
        <mgc-list-item class="message-item">
            <span class="name"> Loading collections ... </span>
        </mgc-list-item>
    </ng-container>

    <ng-container *ngIf="state===states.EMPTY">
        <mgc-list-item class="message-item">
            <span class="name"> No collection </span>
        </mgc-list-item>
    </ng-container>

    <ng-container *ngIf="state===states.DONE">
        <div cdkDropList (cdkDropListDropped)="drop($event)" [@list]="umbrellas.length">
            <ng-container *ngFor="let umbrella of umbrellas | sortUmbrellas; trackBy: trackByFn" cdkDrag>
                <board-umbrella *ngIf="umbrella._show && !umbrella.isArchive" [umbrella]="umbrella" [account]="account"
                    [currentItem]="currentItem" (onSelect)="toggleOpen(umbrella)">
                    <board-project-list *ngIf="umbrella._open" [umbrella]="umbrella" [currentItem]="currentItem">
                    </board-project-list>
                </board-umbrella>
            </ng-container>
        </div>
        <board-umbrella *ngIf="startupUmbrella" [umbrella]="startupUmbrella" [account]="account"
            [currentItem]="currentItem" (onSelect)="toggleOpen(startupUmbrella)">
            <board-project-list *ngIf="startupUmbrella._open" [umbrella]="startupUmbrella" [currentItem]="currentItem">
            </board-project-list>
        </board-umbrella>
        <board-umbrella *ngIf="archiveUmbrella" [umbrella]="archiveUmbrella" [account]="account"
            [currentItem]="currentItem" (onSelect)="toggleOpen(archiveUmbrella)">
            <board-project-list *ngIf="archiveUmbrella._open" [umbrella]="archiveUmbrella" [currentItem]="currentItem">
            </board-project-list>
        </board-umbrella>

    </ng-container>
</mgc-nav-list>
