import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AccountService} from '@board-accounts/account.service';
import {BaseService} from '@board/_services';
import {Participant} from '@panel-components/participants';
import {AlertService, IAlertsClass, ServiceAlertClass} from '@rallysite/components/alert';
import {of, Subscription} from 'rxjs';
import {filter, finalize, switchMap, take} from 'rxjs/operators';
import {ModalImageBlock} from './image-block/image-block';
import {ModalImage} from './image-block/image/modal-image';
import {IModalBlock} from './modal-block.interface';
import {IModalBlocksMetadata} from './modal-blocks-metadata';
import {ModalBlocksService} from './modal-blocks.service';
import {ModalTextBlock} from './text-block/text-block';
import {MODAL_BLOCKS, ModalBlockAction, ModalBlockType} from './types';
import {ModalVideoBlock} from './video-block/video-block';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'modal-blocks',
  templateUrl: './modal-blocks.component.html',
  styleUrls: ['./modal-blocks.component.scss']
})
export class ModalBlocksComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  constructor(
    private formBuilder: FormBuilder,
    private blocksService: ModalBlocksService,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
  }

  get f() {
    return this.form.controls;
  }

  /** end of FORM */


  get states() {
    return BaseService.STATES;
  }

  @Input('item') modal: any;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  ready: boolean;
  loading: boolean;
  processing: boolean;
  subscription: Subscription;

  predefinedBlocks = MODAL_BLOCKS;
  removedBlocks: IModalBlock[] = [];
  blockType = ModalBlockType;

  accountId: string = null;

  initialBlocks: IModalBlock[] = [];

  /** FORM */
  form: FormGroup;

  submitted = false;
  showNotification = false;

  bootstrapForm(blocks: IModalBlock[]) {
    this.submitted = false;
    if (!blocks.length) {
      const block = new ModalTextBlock({ModalId: this.modal.Id});
      block._httpAction = ModalBlockAction.CREATE;
      blocks.push(block);
    }

    this.form = this.formBuilder.group({
      blocks: [blocks]
    });
  }

  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    return true;
  }

  onRemoveBlock(block: IModalBlock) {
    const blocks = this.f.blocks.value;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i] == block) {
        // if Id, then is comming from DB, so we mark it as delete
        // otherwise, no need to keep track of it
        if (block.Id) {
          block._httpAction = ModalBlockAction.DELETE;
          this.removedBlocks.push(block);
        }
        blocks.splice(i, 1);
        this.updatePredefinedBlocks();
      }
    }
  }

  onAddBlock(predifinedBlock) {
    let block: IModalBlock;

    switch (predifinedBlock.type) {
      case ModalBlockType.TEXT:
        block = new ModalTextBlock({ModalId: this.modal.Id});
        break;
      case ModalBlockType.IMAGE:
        const image = new ModalImage({
          ModalId: this.modal.Id,
          AccountId: this.accountService.currentAccount.Id,
        });
        block = new ModalImageBlock({ModalId: this.modal.Id, Content: image});
        break;
      case ModalBlockType.VIDEO:
        block = new ModalVideoBlock({ModalId: this.modal.Id});
        break;
    }

    if (block) {
      block._httpAction = ModalBlockAction.CREATE;
      this.f.blocks.value.push(block);
    }
  }

  postAs(admin: Participant) {
    if (admin && admin.Id) {
      this.accountId = admin.Id;
    }
  }

  onSave(isModalBlock: boolean): void {
    if (!isModalBlock || !this.submit() || this.processing) {
      return;
    }

    const blocks: IModalBlock[] = this.form.value['blocks']
      .concat(this.removedBlocks)
      .map((block: IModalBlock) => {
        return block.toDb();
      });

    this.processing = true;
    this.alertService.clear();

    this.blocksService.saveBlocks(this.modal, {accountId: this.accountId, blocks})
      .pipe(
        take(1),
        finalize(() => this.processing = false)
      )
      .subscribe((bMetadata: IModalBlocksMetadata) => {
        if (!bMetadata) {
          this.alertService.error('Oops! There was an error while trying to save the data!');
          return;
        }

        this.showNotification = true;

        this.initialBlocks = bMetadata.blocks;
        this.removedBlocks = [];

        this.form.patchValue({blocks: this.initialBlocks.slice()});
        this.close.emit('update');
        this.alertService.success('Successfully saved.');

        setTimeout(() => this.showNotification = false, 1000 * 3);
      });
  }

  onCancel(): void {
    this.close.emit('cancel');
  }

  private render(blocks) {
    if (!blocks) {
      return;
    }
    this.bootstrapForm(blocks);
  }

  private loadBlocks() {
    this.ready = false;
    this.loading = true;
    of(this.modal)
      .pipe(
        filter(task => !!task),
        switchMap(task => {
          return this.blocksService.getBlocks(task);
        })
      )
      .subscribe((bMetadata: IModalBlocksMetadata) => {
        this.initialBlocks = bMetadata.blocks;
        this.accountId = bMetadata.account ? bMetadata.account.Id : null;
        this.render(this.initialBlocks.slice());
        this.ready = true;
        this.loading = false;
      });
  }

  updatePredefinedBlocks() {
    setTimeout(() => {
      this.predefinedBlocks = MODAL_BLOCKS;
    }, 100);
  }

  onMove(block: IModalBlock, position: 1 | -1) {
    const blocks = this.f.blocks.value;
    const blockIndex = blocks.findIndex((b: IModalBlock) => b.BlockId === block.BlockId);

    blocks.splice(blockIndex, 1);
    blocks.splice(blockIndex + position, 0, block);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modal'] && changes['modal'].currentValue) {
      this.loadBlocks();
    }
  }

  ngAfterViewInit(): void {
    this.updatePredefinedBlocks();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.blocks.value, event.previousIndex, event.currentIndex);
  }
}
