import { BaseSurveyQuestion } from '@libraries/surveys/models/survey-question.model';
import { IQuestionOption, QuestionOption } from './question-option.model';


export interface IQuestion {
    text: string;
    position: number;
    required: boolean;

    options: IQuestionOption[];
}

export class Question extends BaseSurveyQuestion implements IQuestion {
    text: string;
    position: number;
    required = false;

    set options(options: IQuestionOption[]) {
        this._options = [];
        for (const op in options) {
            this._options.push(new QuestionOption(options[op]));
        }
    }

    public get Text(): string {
        return this.text;
    }

    public get Position(): number {
        return this.position;
    }

    public get Required(): boolean {
        return this.required;
    }

    constructor(data: IQuestion) {
        super();

        for (const prop in data) {
            this[prop] = data[prop];
        }

        this.bootstrap();
    }

}
