import { Subject } from 'rxjs';
import { IServiceStates } from './service-states.interface';
import { EventEmitter } from '@angular/core';

export interface ISettingsComponent {
  data: any;

  state: number;
  states: IServiceStates;

  
  close$?: Subject<any>;
  close?: EventEmitter<any>;

  onSave(newData?: any);
  onCancel();

}
