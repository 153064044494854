import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { MgcButtonModule, MgcDialogModule, MgcLoaderModule, MgcDividerModule } from '@rallysite/mgc-components';

import { ReactionsOverlayComponent } from './reactions-overlay.component';
import { ReactionsOverlayService } from './reactions-overlay.service';
import { ReactionsService } from './reactions.service';
import { ReactionsDataComponent } from './modals/reactions-data.component';
import { ReactionsModal } from './modals/reactions-modal';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        MgcButtonModule,
        MgcDialogModule,
        MgcLoaderModule,
        MgcDividerModule,
        AccountAvatarModule
    ],
    declarations: [
        ReactionsOverlayComponent,
        ReactionsDataComponent,
        ReactionsModal
    ],
    providers: [
        ReactionsOverlayService,
        ReactionsService,
    ],
    exports: []
})
export class ReactionsModule {
}
