import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModal } from './popup.modal';
import { MgcDialogModule, MgcButtonModule, MgcFormFieldModule, MgcDividerModule } from '@rallysite/mgc-components';
import { SafePipesModule } from 'app/_pipes/safe.pipe';
import { WelcomePopupComponent } from './popups/welcome/welcome.component';
import { UserProfileComponent } from './popups/user-profile/user-profile.component';
import { AccountProfileComponent } from './popups/account-profile/account-profile.component';
import { PopupDirective } from './popups/popup.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';
import { PhonePopupComponent } from './popups/phone/phone-popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AlertModule } from '@rallysite/components/alert';
import { PhoneInputV2Module } from '@libraries/phone-input/phone-input-v2/phone-inpit-v2.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,

        MatRadioModule,
        MatSelectModule,
        MgcFormFieldModule,
        MgcDialogModule,
        MgcButtonModule,
        MgcDividerModule,
        SafePipesModule,
        AccountAvatarModule,
        AlertModule,
        PhoneInputV2Module
    ],
    declarations: [
        PopupModal,
        WelcomePopupComponent,
        UserProfileComponent,
        AccountProfileComponent,
        PhonePopupComponent,
        PopupDirective
    ],
    exports: [
        PopupModal
    ]
})
export class PopupModule { }
