import { filter, takeWhile, take, takeUntil, tap, switchMap, finalize } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, SimpleChanges, HostBinding, Inject } from '@angular/core';
import { Subscription, timer, interval, BehaviorSubject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { Project } from './project';
import { ProjectService } from './project.service';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { IUmbrella, UmbrellaService, Umbrella } from '@board/items/umbrella';
import { IServiceStates } from '@rallysite/global-interfaces';
import { IDoNotShowAgainOption, UserService } from '@rallysite/user';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { AccountService } from '@board/accounts';
import { Notification } from '@board/_components/notification';
import { trigger, transition, useAnimation } from '@angular/animations';
import { list, fadeInX } from '@animations';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'board-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  animations: [list,
    trigger('fadeInOut', [
      transition(':enter', useAnimation(fadeInX(0, 1, 0, 0, .2))),
      // transition(':leave', useAnimation(fadeOutX(1, 0, 0, 0, 0.1))),
    ]),
  ],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @HostBinding('@fadeInOut') fadeInOut = true;

  @Input() currentItem: any;
  @Input() umbrella: IUmbrella | Umbrella = null;

  loadSubscription: Subscription;
  projectsStartingSubscription: Subscription;
  projectsWatchSubscription: Subscription;

  private alive = true;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  standaloneList = false;

  states: IServiceStates;
  state: number;

  projects: Project[];
  // sortableOptions: SortableData;

  projectsStarting: Array<any> = [];

  dnsaOptionItem: any;
  dnsaOption: {
    checked: boolean,
    option: IDoNotShowAgainOption,
    closed: boolean
  };

  constructor(
    private messageBus: MessageBusService,
    private projectService: ProjectService,
    private accountService: AccountService,
    private umbrellaService: UmbrellaService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,

    public dialog: MatDialog,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    this.states = ProjectService.STATES;
  }

  newItemFocus = false;
  createState: number;
  inlineCreate(result: any) {
    this.createState = this.states.PROCESSING;
    this.projectService.create(result.item, <Umbrella>this.umbrella).pipe(
      take(1), finalize(() => this.createState = this.states.DONE))
      .subscribe(result => {
        if (result) {
          this.newItemFocus = true;
        }
      });
  }

  handleDNSA() {
    // NOT SHOWING IT

    // let option: IDoNotShowAgainOption = DNSA_OPTIONS.PROJECT_CREATE;
    // this.dnsaOption = {
    //   checked: this.userService.user.hasDnsaOption(option),
    //   option: option,
    //   closed: false
    // }

    // this.userService.userUpdated$
    //   .subscribe(() => {
    //     this.dnsaOption = {
    //       checked: this.userService.user.hasDnsaOption(option),
    //       option: option,
    //       closed: false
    //     }
    //   })
  }
  onCloseDNSA(type: string) {
    this.dnsaOption.closed = true;
  }

  goBack() {
    this.messageBus.publish(MessageBusActions.ACTIVATE_ACCOUNT, { account: this.accountService.currentAccount, action: 'open' });
  }

  trackByFn(index: number, project: Project): string {
    return project.Id + '_' + project.UpdateDate;
  }

  private inView(notification: Notification): boolean {
    return this.umbrella.Id === notification.targetPath._umbrellaId;
  }

  private loadProjects(umbrella: IUmbrella, force: boolean = false) {

    this.projectsWatchSubscription ? this.projectsWatchSubscription.unsubscribe() : '';
    this.projectsWatchSubscription = this.projectService.getProjects$(umbrella).pipe(
      filter(projects => !!projects))
      .subscribe((projects => {
        this.projects = projects;
      }));

    this.loadSubscription ? this.loadSubscription.unsubscribe() : '';
    this.loadSubscription = this.projectService.loadingState$(umbrella).pipe()
      .subscribe(state => {
        this.state = state;
      });

    this.projectsStartingSubscription ? this.projectsStartingSubscription.unsubscribe() : '';
    this.projectsStartingSubscription = this.projectService.getStartingProjects$(umbrella).pipe(
      filter(projects => !!projects))
      .subscribe(projects => {
        this.projectsStarting = projects;
        this.watchStartingProjects(this.projectsStarting);
      });

    this.projectService.loadAll(umbrella, force);
  }

  watchStartingProjects(rProjects) {
    const requests = [];
    const done = [];
    for (let i = 0; i < rProjects.length; i++) {
      const rProject = rProjects[i];
      const rId = rProject['RequestId'];
      done[rId] = requests[rId] = new BehaviorSubject(null).pipe(filter(val => {
        return !!val;
      }));

      timer(30000).pipe(
        takeUntil(done[rId]),
        tap(val => {
          this.projectsStarting.forEach((r, i) => {
            if (r.RequestId === rId) {
              r._status = 'timedout';
            }
          });
        })).subscribe(() => requests[rId].next('timedout'));

      interval(5000).pipe(
        takeUntil(done[rId]),
        switchMap(() => {
          return this.projectService.getProjectByRequest(rProject, this.umbrella).pipe(
            // tap(val => console.log(['tryyyyyy ', rId]))
          );
        }))
        .subscribe(result => {
          // result = Math.floor(Math.random() * 4)
          // console.log(['result ', rId, result]);
          if (result) {
            requests[rId].next(result);
            this.projectsStarting.forEach((r, i) => {
              if (r.RequestId === rId) {
                this.projectsStarting.splice(i, 1);
              }
            });
          }
        });
    }
  }

  ngOnInit() {
    this.messageBus.on(Notification.EVENTS.PROJECT).pipe(
      takeWhile(() => this.alive))
      .subscribe((notification: Notification) => {
        notification._inView = this.inView(notification);
        if (notification._inView) {
          // make an announcement in order to cancel any further processing on heigher level(notificationService)
          this.messageBus.publish(Notification.EVENTS.PROJECT + '.' + notification.id);
        }
        this.projectService.handleNotification(notification, this.umbrella);
      });

    if (this.standaloneList) {
      this.accountService.currentAccount$.pipe(
        filter(account => !!account),
        takeWhile(() => this.alive))
        .subscribe(account => {
          this.umbrella = this.umbrellaService.getDefault(account.Id);
          this.loadProjects(this.umbrella);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['umbrella'] && changes['umbrella'].currentValue) {
      this.loadProjects(this.umbrella);
    }

  }

  ngOnDestroy() {
    this.alive = false;
  }

  // TODO This method was missed; I added to fix type issues; Need to add functional
  removeProject(event) {

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
    this.accountService.orderProjects(
      this.accountService.currentAccount,
      { umbrellaId: this.umbrella.Id, id: this.projects[event.currentIndex].Id, index: event.currentIndex });

  }
}


@Component({
  selector: 'board-project-standalone-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  animations: [list,
    trigger('fadeInOut', [
      transition(':enter', useAnimation(fadeInX(0, 1, 0, 0, .2))),
      // transition(':leave', useAnimation(fadeOutX(1, 0, 0, 0, 0.1))),
    ]),
  ],
})
export class ProjectStandaloneListComponent extends ProjectListComponent {
  standaloneList = true;
}

