
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { BaseService } from '@board/_services/base-service.service';
import { TargetResourceService } from '@board/_services/target-resource.service';

import { APP_CONFIG, AppConfig } from '@rallysite/config';
import { IScheduledFolder, BaseScheduledFolder, DefaultScheduledFolder } from '@board/items/scheduled-folder';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Project } from '@board/items/project/project';
import { ScheduledTask } from './scheduled-task';
import { TargetTask } from '../task/task';


export interface IScheduledTaskServiceOptions {
  projectId?: string;
  folderId?: string;
  folder?: BaseScheduledFolder;
  limit?: number;
  offset?: number;
  force?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ScheduledTaskService extends BaseService {
  private dataStore: {
    [key: string]: {
      tasks: ScheduledTask[],
      _tasks: BehaviorSubject<ScheduledTask[]>
      total: number
    }
  };

  private targetTasks: {
    [id: string]: TargetTask
  } = {};

  private _targetTask: BehaviorSubject<TargetTask>;
  private _endPoint: string;

  currentKey: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private targetService: TargetResourceService,
    private messageBus: MessageBusService,
    private alertService: AlertService,
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/scheduled-tasks`;
    this.dataStore = {};
    this._targetTask = <BehaviorSubject<TargetTask>>new BehaviorSubject(null);
  }

  private key(options: any) {
    if (options.folder) {
      return options.folder.ProjectId + '_' + options.folder.getId();
    }

    if (options.folderId === DefaultScheduledFolder.ID) {
      options.folderId = null;
    }
    return options.projectId + '_' + options.folderIdv;
  }

  get targetTask$(): Observable<TargetTask> {
    return this._targetTask.asObservable();
  }

  tasks$(options: IScheduledTaskServiceOptions) {
    let key = this.key(options);
    if (!this.dataStore[key]) {
      this.dataStore[key] = {
        tasks: null, total: 0,
        _tasks: <BehaviorSubject<ScheduledTask[]>>new BehaviorSubject(null),
      }
    }
    return this.dataStore[key]._tasks.asObservable();
  }

  total(options): number {
    let key = this.key(options);
    return this.dataStore[key].total;
  }

  getTasks(options: IScheduledTaskServiceOptions) {
    if (!(options.folder instanceof BaseScheduledFolder)) {
      return observableOf(null);
    }
    let folder = options.folder;
    let key = this.currentKey = this.key(options);

    if (this.inStorage(options)) {
      return observableOf(this.getFromStorage(options));
    }

    let paramsData = {
      'filter[GroupId]': folder.getId(),
      'filter[ProjectId]': folder.ProjectId,
      // 'limit': (options.limit || 15).toString(),
      'offset': (options.offset || 0).toString(),
    }
    let params = new HttpParams({
      fromObject: paramsData
    });

    return this.http.get(this._endPoint, { params: params, observe: 'response' }).pipe(
      map(response => {
        if (options.offset === 0) {
          this.initStorage(options);
        }

        let data = response.body;
        let headers = response.headers as HttpHeaders;
        this.dataStore[key].total = parseInt(headers.get('X-Items-Count'), 10) || 0;

        for (let k in data) {
          this.pushToStorage(key, new ScheduledTask(data[k]));
        };

        if (this.targetService.targetLevel > 1) {
          this.setCurrentTaskFromTarget(folder);
        }

        return this.getFromStorage(options);
      }), catchError(error => {
        console.log('Could not load tasks');
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_LIST);
        return observableOf(null);
      })
    )
  }

  /**
 * This method is actioned by QueryRequestService
 * @param id 
 */
  loadTarget(id: string) {
    if (this.targetTasks[id]) {
      return observableOf(this.targetTasks[id]);
    }

    return this.http.get(`${this._endPoint}/${id}/target`).pipe(
      map(data => {
        this.targetTasks[id] = new TargetTask(data);
        return this.targetTasks[id];
      }),
      catchError(error => {
        console.log('Could not load scheduled target task');
        return observableOf(null);
      })
    )
  }

  create(task: ScheduledTask, project: Project, folder: BaseScheduledFolder) {
    let options = { folder: folder, projectId: project.Id, folderId: folder.Id };
    let key = this.key(options);
    return this.http.post(this._endPoint, task.toDb()).pipe(
      map(data => {
        let newTask = new ScheduledTask(data);
        this.pushToStorage(key, newTask).emit(key);
        return newTask;
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_CREATE);
        return observableOf(null);
      })
    )
  }

  update(task: ScheduledTask) {
    return this.http.put(`${this._endPoint}/${task.Id}`, task.toDb()).pipe(
      map(data => {
        task.__update(data);
        if (this.currentKey) {
          this.emit(this.currentKey);
        }
        return task;
      }), catchError(error => {
        this.alertService.error(error, ServiceAlertClass.ALERTS.TASK_UPDATE);
        return observableOf(null);
      })
    )
  }

  remove(task: ScheduledTask, folder: IScheduledFolder, project: Project) {
    let options = { folder: folder, projectId: project.Id, folderId: folder.Id };
    let key = this.key(options);
    task._state = 'remove';
    return this.http.delete(`${this._endPoint}/${task.Id}`).pipe(
      map(data => {
        this.removeFromStorage(key, task).emit(key);
        return true;
      }), catchError(error => {
        console.log('Could not remove task.');
        this.alertService.snackError(error);
        return observableOf(false);
      })
    )
  }


  private initStorage(options: any) {
    let key = this.key(options);
    // make sure _tasks subject exists
    this.tasks$(options);
    this.dataStore[key] = Object.assign(this.dataStore[key], {
      tasks: [],
      total: 0,
    })
  }

  private inStorage(options: IScheduledTaskServiceOptions) {
    if (options.force) {
      return false;
    }
    let key = this.key(options);
    if (!this.dataStore[key] || !this.dataStore[key].tasks) {
      return false;
    }

    if (options.limit > 0 && this.dataStore[key].tasks.length < options.limit + options.offset && this.dataStore[key].tasks.length < this.dataStore[key].total) {
      return false
    }

    return !!this.dataStore[key].tasks;
  }

  private pushToStorage(key: string, task: ScheduledTask, top = false) {
    if (top) {
      this.dataStore[key].tasks.splice(0, 0, task);
    } else {
      this.dataStore[key].tasks.push(task);
    }
    return this;
  }

  private removeFromStorage(key: string, task: ScheduledTask) {
    if (this.dataStore[key].tasks) {
      this.dataStore[key].tasks.forEach((t, i) => {
        if (t.Id === task.Id) {
          this.dataStore[key].tasks.splice(i, 1);
        }
      });
    }
    return this;
  }

  private getFromStorage(options: any) {
    let key: string;
    let n: any;

    if (typeof options === 'string') {
      key = options;
      n = Object.assign({}, this.dataStore[key]).tasks;
    } else if (options.limit > 0) {
      key = this.key(options);
      n = Object.assign({}, this.dataStore[key]).tasks.slice(0, options.limit + options.offset);
    } else {
      key = this.key(options);
      n = Object.assign({}, this.dataStore[key]).tasks;
    }

    return this.sort(n);
  }

  private emit(key: string) {
    this.dataStore[key]._tasks.next(this.getFromStorage(key));
  }

  private sort(tasks: ScheduledTask[]) {
    let withLaunchDate = tasks.filter(t => !!t.LaunchDate);
    let withoutLaunchDate = tasks.filter(t => !t.LaunchDate);

    return withoutLaunchDate.sort(function (a, b) {
      let dateA = new Date(a.CreateDate), dateB = new Date(b.CreateDate);
      return dateB.getTime() - dateA.getTime();
    }).concat(
      withLaunchDate.sort(function (a, b) {
        let dateA = new Date(a.LaunchDate), dateB = new Date(b.LaunchDate);
        return dateA.getTime() - dateB.getTime();
      })
    )
  }

  private setCurrentTaskFromTarget(folder: BaseScheduledFolder) {
    let targetsSet: boolean = false;
    this.targetService.currentTargets$.pipe(takeWhile(() => !targetsSet))
      .subscribe((targets) => {
        if (targets.scheduledTaskId) {
          targetsSet = true;
          let task: ScheduledTask = this.findTask(targets.scheduledTaskId, {
            folder: folder,
          });

          if (task) {
            this.openTask(task);
          }
        }
      })
  }

  findTask(taskId: string, options: IScheduledTaskServiceOptions): ScheduledTask {
    let task: ScheduledTask;
    let key = this.key(options);
    if (!this.dataStore[key]) {
      return null;
    }
    for (let t of this.dataStore[key].tasks) {
      if (t && t.Id === taskId) {
        task = t;
        break;
      }
    }

    return task || null;
  }


  openTask(task: ScheduledTask, project: Project = null) {
    if (this.targetService.targetLevel === 2) {
      this.targetService.reset();
      task._state = "target";
    }
    this.messageBus.publish(MessageBusActions.ACTIVATE_SCHEDULED_TASk, { task: task, action: 'open' });
  }

}