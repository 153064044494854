import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { WINDOW } from '@rallysite/global-services';
import { SurveyQuestionsConverter } from 'app/_services/transfer-state/converters/survey-questions.converter';
import { TransferStateService } from 'app/_services/transfer-state/transfer-state.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ISurveyQuestion, SurveyQuestion } from '../models/survey-question.model';
import { SurveyButtonComponent } from '../survey-modal/survey-button';
import { AssessmentButtonComponent } from '@spiritual-assessment/assessment-button';
import { saveAs } from 'file-saver';
import { Brand } from '@board-brands/brand.model';
import { ISurveyScore } from './survey-score.interface';
import { SurveyConverter } from 'app/_services/transfer-state/converters/survey.converter';
import { Survey } from '@board/surveys/survey.model';
import { IUIBlock } from '@board/surveys/survey-factory/survey-feedbacks/ui-blocks/ui-block.interface';
import {ISurveyQuestionOption} from '@libraries/surveys/models/survey-question-option.model';
import {PlevelService} from '../../../_services/plevel.service';

export interface ISurveyServiceOptions {
    surveyId: string | number;
    takenSurveyId?: string;
    storeType?: 'survey' | 'questions' | 'answers';
    limit?: number;
    offset?: number;
    force?: boolean;
    userId?: string;
}

@Injectable({
    providedIn: 'root',
})
export class SurveyService {

    protected _endPoint: string;
    protected assessTimeout = null;
    protected snackBarRef: MatSnackBarRef<SurveyButtonComponent | AssessmentButtonComponent>;

    protected dataStore: {
        questions?: ISurveyQuestion[]
        answers?: any,
        survey?: any,
        // latest survey/assessment
        latest?: any
    } = {};

    protected surveyData: {
        Title?: string,
        Description?: string
    };

    isBrowser = false;

    constructor(
        protected http: HttpClient,
        @Inject(WINDOW) public w: Window,
        @Inject(APP_CONFIG) protected config: AppConfig,
        protected _snackBar: MatSnackBar,
        protected authService: AuthService,
        @Inject(PLATFORM_ID) protected readonly platformId,
        protected readonly transferStateService: TransferStateService,
        protected readonly surveyQuestionsConverter: SurveyQuestionsConverter,
        protected readonly surveyConverter: SurveyConverter,
        protected plevelService: PlevelService
    ) {
        this._endPoint = `${this.config.endpoint}/api/surveys`;
        this.isBrowser = !isPlatformServer(platformId);
    }

    key(options: ISurveyServiceOptions) {
        let key = `sk_${options.surveyId}`;

        if (options.takenSurveyId) {
            key += `_${options.takenSurveyId}`;
        }

        if (options.userId) {
            key += `_${options.userId}`;
        }
        return key;
    }

    embedId(brandId: string, surveyId: string) {
        return `SRVY-${brandId}-${surveyId}`;
    }

    surveyPageUrl(brandId: string, surveyId: string) {
        return `${this.config.protocol}://${this.config.domain}/surveys?srvy_id=${this.embedId(brandId, surveyId)}`;
    }

    protected questionInstance(data) {
        return new SurveyQuestion(data);
    }

    protected get questionConvertor(): any {
        return this.surveyQuestionsConverter;
    }

    pLevel(questions: ISurveyQuestion[]): number | null {
      const pgroupQuestions = questions.filter(q => q.SurveyPgroupId);

      if (pgroupQuestions.length === 0) {
        return null;
      }

      const dq1Score = (pgroupQuestions[0].selectedOption as ISurveyQuestionOption).Points,
        brqScore = (pgroupQuestions[1].selectedOption as ISurveyQuestionOption).Points,
        dq2Score = (pgroupQuestions[2].selectedOption as ISurveyQuestionOption).Points;

      return this.plevelService.getPlevel(dq1Score, brqScore, dq2Score, false);
    }

    getSurvey(surveyId: string, brandId: string = null, takenSurveyId: string = null) {
        const options: ISurveyServiceOptions = {
            surveyId,
            takenSurveyId,
            storeType: 'survey'
        };

        if (this.inStorage(options)) {
            return of(this.getFromStorage(options));
        }

        let endpoint = `${this._endPoint}/${options.surveyId}`;
        if (brandId) {
            endpoint += `?brand=${brandId}` + (takenSurveyId ? `&tsrvy=${takenSurveyId}` : '');
        } else {
            endpoint += takenSurveyId ? `?tsrvy=${takenSurveyId}` : '';
        }

        return this.http.get(endpoint).pipe(
            map(data => {
                this.initStorage(options);
                this.pushToStorage(new Survey(data), options);
                return this.getFromStorage(options);
            }),
            catchError(error => {
                console.log('Could not load survey data');
                return of({});
            })
        );
    }

    getQuestions(options: ISurveyServiceOptions): Observable<ISurveyQuestion[]> {
        options.storeType = 'questions';
        if (this.inStorage(options)) {
            return of(this.getFromStorage(options));
        }

        const k = this.key(options);
        const tsKey = `ts_questions__${k}`;
        return this.transferData(
            this.transferStateService.fetch(tsKey,
                this.getQuestionsFromServer(options),
                [],
                this.questionConvertor
            ),
            options
        );
    }

    private getQuestionsFromServer(options: ISurveyServiceOptions) {
        const endpoint = `${this._endPoint}/${options.surveyId}/questions`;
        const params = {};

        const k = this.key(options);
        return this.http.get(endpoint, { params: params }).pipe(
            tap(() => {
                // initial load, but not ssr
                if (!options.offset) {
                    this.initStorage(options);
                }
            }),
            map(data => {
                const output: any = [];
                for (const key in data) {
                    output.push(this.questionInstance(data[key]));
                }
                return output;
            }),
            catchError(error => {
                console.log('Could not load questions');
                return of([]);
            })
        );
    }

    getAnswers(options: ISurveyServiceOptions): Observable<ISurveyQuestion[]> {
        if (isPlatformServer(this.platformId)) {
            return of([]);
        }
        options.storeType = 'answers';
        if (this.inStorage(options)) {
            return of(this.getFromStorage(options));
        }

        const endpoint = `${this._endPoint}/${options.surveyId}/answers`;
        const params = options.takenSurveyId ? { tsrvy: options.takenSurveyId } : {};

        return this.http.get(endpoint, { params: params }).pipe(
            map(data => {
                if (!options.offset) {
                    this.initStorage(options);
                }

                for (const key in data) {
                    this.pushToStorage(data[key], options);
                }
                return this.getFromStorage(options);
            }),
            catchError(error => {
                return of(null);
            })
        );
    }

    postAnswers(payload, options: ISurveyServiceOptions): Observable<ISurveyScore> {
        const key = this.key(options);
        const endpoint = `${this._endPoint}/answers`;
        return this.http.post(endpoint, payload).pipe(
            map(data => {
                this.dataStore.questions[key] = null;
                this.dataStore.answers[key] = null;
                this.setLatestDate(new Date());
                return <ISurveyScore>data;
            }), catchError(error => {
                console.log('Could not save answers.');
                return of(null);
            })
        );
    }

    saveFavorite(surveyId: string, isFavorite: boolean): Observable<boolean> {
        const endpoint = `${this._endPoint}/${surveyId}/favorite`;
        return this.http.post(endpoint, { isFavorite }).pipe(
            map((result: { isFavorite: boolean | null }) => {
                const { isFavorite } = result;
                return isFavorite;
            }), catchError(error => {
                console.log('Could not save favorite.');
                return of(null);
            })
        );
    }

    getResult(payload, options: ISurveyServiceOptions): Observable<ISurveyScore> {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }

        const key = this.key(options);
        const endpoint = `${this._endPoint}/${options.surveyId}/result`;

        return this.http.get(endpoint, { params: payload }).pipe(
            map(data => {
                return <ISurveyScore>data;
            }),
            catchError(error => {
                return of(null);
            })
        );
    }

    getFeedbackByOptionIds(surveyId, questions: ISurveyQuestion[], optionIds: number[]): Observable<{ [key: number]: IUIBlock[] }> {
        const endpoint = `${this._endPoint}/${surveyId}/feedback`;

        const pLevel = this.pLevel(questions);

        return this.http.post(endpoint, { options: optionIds, pLevel }).pipe(
            map((feedback: { [key: number]: IUIBlock[] }) => {
                return feedback;
            }),
            catchError(error => {
                console.log('Could not load feedback');
                return of(null);
            })
        );
    }

    getLatest() {
        return of(null);
    }
    setLatestDate(date) {
        //
    }

    engage(buttonComponent = null) {
        buttonComponent || (buttonComponent = SurveyButtonComponent);
        if (!this.authService.isAuthenticated || this.snackBarRef) {
            return;
        }
        clearTimeout(this.assessTimeout);
        this.assessTimeout = setTimeout(() => {
            this.snackBarRef = this._snackBar.openFromComponent(buttonComponent, {
                panelClass: 'survey-snakbar'
            });

            this.snackBarRef.afterDismissed().subscribe(() => {
                this.snackBarRef = null;
            });

        }, 1000 * 3);
    }

    enabled() {
        return true;
    }

    private initStorage(options: ISurveyServiceOptions) {
        const key = this.key(options);
        const type = options.storeType;

        this.dataStore[type] || (this.dataStore[type] = {});
        this.dataStore[type][key] || (this.dataStore[type][key] = {});

        this.dataStore[type][key] = {
            data: [],
            total: 0
        };
    }

    inStorage(options: ISurveyServiceOptions) {
        const key = this.key(options);
        const type = options.storeType;
        if (!this.dataStore[type] || !this.dataStore[type][key] || !this.dataStore[type][key].data) {
            return false;
        }

        if (options.limit >= 0 && options.offset >= 0) {
            if (this.dataStore[type][key].data.length < options.limit + options.offset && this.dataStore[type][key].data.length < this.dataStore[type][key].total) {
                return false;
            }
        }

        return true;
    }

    private pushToStorage(item: any, options: ISurveyServiceOptions, inFront: boolean = false) {
        const key = this.key(options);
        const type = options.storeType;

        if (inFront) {
            this.dataStore[type][key].data.splice(0, 0, item);
        } else {
            this.dataStore[type][key].data.push(item);
        }
        return this;
    }


    private getFromStorage(options: ISurveyServiceOptions) {
        const key = this.key(options);
        const type = options.storeType;

        if (options.limit >= 0 && options.offset >= 0) {
            return Object.assign({}, this.dataStore)[type][key].data.slice(0, options.limit + options.offset);
        } else {
            return Object.assign({}, this.dataStore)[type][key].data;
        }
    }


    private transferData<T>(
        observableInput: Observable<T>,
        options: ISurveyServiceOptions,
        tsKeyTotal: string = null
    ) {
        const key = this.key(options);
        const type = options.storeType;
        return observableInput.pipe(
            tap(() => {
                if (!options.offset && (!this.dataStore[type] || !this.dataStore[type][key])) {
                    this.initStorage(options);
                }
                if (tsKeyTotal) {
                    // const total = this.transferStateService.get(tsKeyTotal, this.getTotal(options)) || 0;
                    // this.setTotal(total, options);
                }
                // if (!isPlatformServer(this.platformId)) {
                //     this.transferStateService.remove(tsKeyTotal);
                // }
            }),
            map(data => {
                if (data instanceof Array) {
                    for (const key in data) {
                        this.pushToStorage(data[key], options);
                    }
                } else {
                    this.pushToStorage(data, options);
                }
                return this.getFromStorage(options);
            })
        );
    }

    getResultsReport(brand: Brand, surveyId: string | number): Observable<true> {
        const endpoint = `${this._endPoint}/report/results/${brand.Id}/${surveyId}`;

        return this.http.get(endpoint, { responseType: 'blob' }).pipe(
            map(data => {
                const blob = new Blob([data], { type: 'csv' });
                const url = window.URL.createObjectURL(data);
                saveAs(blob, `results--${brand.UName}-${surveyId}.csv`);
                return true;
            }),
            catchError(error => {
                console.log('Could not run report');
                return of(null);
            })
        );
    }

    getUsersReport(brand: Brand, surveyId: string | number): Observable<true> {
        const endpoint = `${this._endPoint}/report/users/${brand.Id}/${surveyId}`;

        return this.http.get(endpoint, { responseType: 'blob' }).pipe(
            map(data => {
                const blob = new Blob([data], { type: 'csv' });
                const url = window.URL.createObjectURL(data);
                saveAs(blob, `users--${brand.UName}-${surveyId}.csv`);
                return true;
            }),
            catchError(error => {
                console.log('Could not run report');
                return of(null);
            })
        );
    }

    getPdf(payload) {
      const endpoint = `${this._endPoint}/feedback/pdf`;

      return this.http.post(endpoint, payload, { responseType: 'blob' }).pipe(
        map(data => {
          const blob1 = new Blob([data], {type: 'pdf'});
          window.URL.createObjectURL(data);
          saveAs(blob1, `feedback.pdf`);

          return true;
        }),
        catchError(error => {
          console.log('Could not run report');
          return of(null);
        })
      )
    }


    download(): Observable<boolean> {
        const endpoint = `${this._endPoint}/report/comparison`;

        return this.http.get(endpoint, { responseType: 'blob' }).pipe(
            map(data => {
                const blob = new Blob([data], { type: 'pdf' });
                const url = window.URL.createObjectURL(data);
                saveAs(blob, 'user-comparison-report.pdf');
                return true;
            }),
            catchError((error) => {
                console.log('Could not download file.');
                return of(null);
            })
        );
    }

}
