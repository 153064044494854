import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap } from 'rxjs/operators';
import { AlgoTagsService } from '../algotags.service';
import { AlgoTagAction, IAlgoTagBase } from './algotag.model';

@Component({
  selector: 'algotag-autocomplete',
  templateUrl: './algotag-autocomplete.component.html',
  styleUrls: ['./algotag.component.scss']
})
export class AlgoTagAutoCompleteComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() selectedTags: IAlgoTagBase[] = [];
  @Output() removeChange: EventEmitter<IAlgoTagBase> = new EventEmitter(null);

  searchAlgoTagCtrl: FormControl;
  processing = false;
  saving = false;
  term = '';

  foundTags: IAlgoTagBase[] = [];
  filteredTags: IAlgoTagBase[] = [];
  removedTags: IAlgoTagBase[] = [];

  // watchChanged: number;

  constructor(
    private algoTagService: AlgoTagsService,
  ) {
    this.searchAlgoTagCtrl = new FormControl();
  }

  add(tag: IAlgoTagBase) {
    tag._httpAction = AlgoTagAction.ATTACH;
    this.algoTagService.addTo(this.selectedTags, tag);
    this.algoTagService.removeFrom(this.filteredTags, tag);
    this.removeChange.emit(tag);

    // tag.toggleAttachDetachAction(AlgoTagAction.ATTACH);
    // if (!this.selectedTags.find(st => st.Id === tag.Id)) {
    //   this.selectedTags.push(tag);
    //   this.algoTagService.sort(this.selectedTags);
    // }

    // for (let i = 0; i < this.filteredTags.length; i++) {
    //   if (this.filteredTags[i].Id === tag.Id) {
    //     this.filteredTags.splice(i, 1);
    //   }
    // }

    this.searchInput.nativeElement.blur();
    // this.watchChanged = Math.random();
  }

  remove(tag: IAlgoTagBase) {
    tag._httpAction = AlgoTagAction.DETACH;
    this.algoTagService.addTo(this.filteredTags, tag);
    this.algoTagService.removeFrom(this.selectedTags, tag);
    this.removeChange.emit(tag);

    // tag.toggleAttachDetachAction(AlgoTagAction.DETACH);;
    // this.filteredTags.push(tag);
    // this.algoTagService.sort(this.filteredTags);

    // // for (let i = 0; i < this.selectedTags.length; i++) {
    // //   if (this.selectedTags[i].Id === tag.Id) {
    // //     this.selectedTags.splice(i, 1);
    // //   }
    // // }
    // this.watchChanged = Math.random();
  }

  saveNewTag(ev: MouseEvent) {
    ev.preventDefault();

    if (this.saving) {
      return;
    }

    this.searchAlgoTagCtrl.disable();
    this.saving = true;
    this.algoTagService.saveNewAlgoTag(this.term)
      .pipe(
        finalize(() => this.saving = false)
      )
      .subscribe((tag: IAlgoTagBase) => {
        this.add(tag);

        // set as found
        this.foundTags.push(tag);
        this.algoTagService.sort(this.foundTags);
        this.searchAlgoTagCtrl.enable();
      });
  }

  ngOnInit() {
    // If it's a string, it's been typed into the input
    const tagTyped$ = this.searchAlgoTagCtrl.valueChanges.pipe(
      filter(val => typeof val === 'string'),
    );

    tagTyped$.pipe(
      debounceTime(500),
      distinctUntilChanged((prev, curr) => prev.trim() === curr.trim()),
      filter((term: string) => {
        this.term = term.trim();
        return this.term.length >= 3 || this.term.length === 0;
      }),
      switchMap((term: string) => {
        this.processing = true;
        return this.algoTagService.searchTags(term);
      }),
    ).subscribe(tags => {
      this.foundTags = tags;
      console.log(['this.selectedTags', this.selectedTags]);
      this.filteredTags =
        this.foundTags.filter((foundTag: IAlgoTagBase) =>
          !this.selectedTags.find(st => st.Id === foundTag.Id && st._httpAction !== AlgoTagAction.DETACH));
      this.processing = false;
    });

  }

}
