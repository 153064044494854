import { Component, HostListener, Input, ElementRef, ViewChild, OnInit, OnDestroy, Inject } from '@angular/core'
import { GalleryImage } from '../gallery-image.model';

import { animate, state, style, transition, trigger } from '@angular/animations'
import { ISize } from '../size.class';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ViewerOverlayRef } from './viewer-overlay-ref';
import { VIEWER_OVERLAY_DATA } from './viewer-overlay.tokens';
import { IViewerData } from '@libraries/gallery/viewer/viewer-overlay.data';

@Component({
    selector: 'viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.scss'],
    animations: [
        trigger('imageAnimator', [
            // STATES
            state('enterFromRight', style({
                opacity: 1,
                transform: 'translate(0px, 0px)'
            })),
            state('enterFromLeft', style({
                opacity: 1,
                transform: 'translate(0px, 0px)'
            })),
            state('leaveToLeft', style({
                opacity: 0,
                transform: 'translate(-100px, 0px)'
            })),
            state('leaveToRight', style({
                opacity: 0,
                transform: 'translate(100px, 0px)'
            })),
            state('revert', style({
                left: 0,
                transform: '{{transform}}'
            }), { params: { transform: 'translate(0px, 0px)' } }),

            // TRANSITIONS
            transition('* => enterFromRight', [
                style({
                    opacity: 0,
                    transform: 'translate(30px, 0px)'
                }), animate('250ms 500ms ease-in')
            ]),
            transition('* => enterFromLeft', [
                style({
                    opacity: 0,
                    transform: 'translate(-30px, 0px)'
                }),
                animate('250ms 500ms ease-in')
            ]),
            transition('* => leaveToLeft', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
            transition('* => leaveToRight', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
            transition('* => leaveToRight', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
            transition('* => revert', [
                style({
                    opacity: 1
                }), animate('250ms ease-out')
            ]),
        ]),
        trigger('viewerAnimator', [
            state('true', style({
                opacity: 1
            })),
            state('void', style({
                opacity: 0
            })),
            transition('void => *', [
                style({
                    opacity: 0
                }),
                animate('200ms ease-in')]
            ),
            transition('* => void', [
                style({
                    opacity: 1
                }),
                animate('200ms ease-out')]
            )
        ])
    ]
})

export class ViewerComponent implements OnInit, OnDestroy {

    touchMode: '' | 'pinch' | 'swipe' = '';

    initialQuality: string = 'small';
    downloadable: boolean = false
    withDetails: boolean = false;

    categorySelected: string = this.initialQuality;
    private qualitySelected: string = 'auto'

    images: Array<any> = [{}]
    private _currentIndex: number = 0;
    set currentIdx(val: number) {
        this._currentIndex = val;
        this.initialScaleFactor = this.images[val].scaleFactor || 1;
        this.touchMode = '';
    }
    get currentIdx(): number { return this._currentIndex }
    leftArrowVisible: boolean = true
    rightArrowVisible: boolean = true
    horizontalPosition: number

    private zoomFactor: number = 0.1;
    private translateX = 0;
    private translateY = 0;
    private prevX: number;
    private prevY: number;

    private distance: number = 0;
    private initialDistance: number = 0;
    private initialScaleFactor: number = 1;

    private clicks = new Subject();
    private tapCount: number = 0;

    private tapSubscription: Subscription;
    private pressed: boolean = false;
    draggableMode: boolean = false;
    private readonly TOUCH_DELAY: number = 200;

    @ViewChild('imageContainer') imageContainer: ElementRef;

    @HostListener('document:keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
        this.keyboardInteraction(event);
    }

    constructor(
        public viewerRef: ViewerOverlayRef,
        @Inject(VIEWER_OVERLAY_DATA) public data: IViewerData
    ) {

        this.initialQuality = data.initialQuality || 'small';
        this.downloadable = data.downloadable || false;
        this.withDetails = data.withDetails || false;

    }

    private getImageContainerSize(): ISize {
        let w = this.imageContainer.nativeElement.clientWidth;
        let h = this.imageContainer.nativeElement.clientHeight;

        //for IE11
        w || (w = this.imageContainer.nativeElement.scrollWidth);
        h || (h = this.imageContainer.nativeElement.scrollHeight);

        return {
            width: w,
            height: h
        }
    }

    private currentTransform(image) {
        let rotation = image.model ? image.model.Rotation : 0;
        let scaleFactor = 1;
        if (rotation % 180 !== 0 && this.imageContainer) {
            let clientSize = this.getImageContainerSize();
            let imgRatio = image.naturalHeight / image.naturalWidth;
            let w1;
            let h1;
            if (imgRatio > 1) {
                // height bigger than width

                // when rotate, height become new width
                // calculate scale factor based on that
                w1 = clientSize.width;
                h1 = clientSize.width / imgRatio
                scaleFactor = w1 / Math.max(clientSize.height, h1);
            } else {
                h1 = clientSize.height;
                w1 = clientSize.height / imgRatio
                scaleFactor = h1 / Math.min(clientSize.width, w1);
            }
        }

        scaleFactor *= (image.scaleFactor || 1);

        return `translate(${this.translateX}px, ${this.translateY}px) rotate(${rotation}deg) scale(${scaleFactor})`;
    }

    get leftArrowActive(): boolean {
        return this.currentIdx > 0
    }

    get rightArrowActive(): boolean {
        return this.currentIdx < this.images.length - 1
    }

    /** Events Handlers */
    toggleDrag(evt) {
        this.draggableMode = !this.draggableMode;
        if (!this.draggableMode) {
            this.resetImage(this.images[this.currentIdx]);
            this.updateStyle(this.images[this.currentIdx], this.currentIdx);
        }
    }

    private getDistance(touches) {
        let d = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) +
            Math.pow(touches[0].clientY - touches[1].clientY, 2));
        return parseInt(d.toString(), 10);
    }

    private touch(evt, touches: string = 'touches') {
        if (!evt) {
            return null;
        }
        return evt[touches] && evt[touches][0] ? evt[touches][0] : evt;
    }

    private down(/** Touch | Event */ event) {
        let touch = this.touch(event);

        this.pressed = true;
        this.onDragStart(touch);
    }

    private move(/** Touch | Event */ event) {
        this.onDragOver(this.touch(event));
    }

    private up(/** Touch | Event */ touch) {
        this.showNavigationArrows();
        this.pressed = false;
    }

    mouseDown(event: MouseEvent): void {
        this.tapCount++;
        this.clicks.next(event);

        this.down(event);
    }
    mouseMove(event: MouseEvent): void {
        this.move(event);
    }
    mouseUp(event: MouseEvent): void {
        this.up(event);
    }

    touchStart(evt) {
        let touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;
        if (touches.length === 1) {
            this.tapCount++;
            this.clicks.next(evt);
        }

        this.down(this.touch(evt));
    }

    touchMove(evt) {
        if (!(this.pressed && this.draggableMode)) return;

        let touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;
        let img;

        if (this.touchMode === '') {
            if (touches.length === 2) {
                this.touchMode = 'pinch';
                this.initialDistance = this.getDistance(touches);
            }
        }

        if (this.touchMode === 'pinch') {
            evt.preventDefault();

            this.distance = this.getDistance(touches);

            img = this.images[this.currentIdx];
            img.scaleFactor = this.initialScaleFactor * (this.distance / this.initialDistance);

            this.updateStyle(img);
        } else {
            this.move(event);
        }
    }

    touchEnd(evt) {
        this.up(this.touch(evt));

        if (this.touchMode === 'pinch') {
            this.initialScaleFactor = this.images[this.currentIdx].scaleFactor;
        }
        this.touchMode = '';
    }


    private onDragStart(evt) {
        if (!(this.pressed && this.draggableMode)) return;

        if (evt.dataTransfer && evt.dataTransfer.setDragImage) {
            evt.dataTransfer.setDragImage(evt.target.nextElementSibling, 0, 0);
        }
        this.prevX = evt.clientX;
        this.prevY = evt.clientY;
    }

    private onDragOver(evt) {
        if (!(this.pressed && this.draggableMode)) return;

        this.translateX += (evt.clientX - this.prevX);
        this.translateY += (evt.clientY - this.prevY);
        this.prevX = evt.clientX;
        this.prevY = evt.clientY;
        this.updateStyle(this.images[this.currentIdx]);
    }

    pan(swipe: any) {
        if (this.draggableMode) return;
        this.horizontalPosition = swipe.deltaX;
    }

    swipeLeft(swipe) {
        if (this.draggableMode) return;
        this.navigate(1, swipe);
    }

    swipeRight(swipe) {
        if (this.draggableMode) return;
        this.navigate(-1, swipe);
    }

    onResize(): void {
        this.images.forEach((image: GalleryImage) => {
            image.loadedInViewer = false
            image.activeInViewer = false
        })
        this.updateImage()
    }

    zoomIn(zoomFactor) {
        let img = this.images[this.currentIdx];
        img.scaleFactor = (img.scaleFactor || 1) * (1 + zoomFactor);
        this.updateStyle(img);
    }

    zoomOut(zoomFactor) {
        let img = this.images[this.currentIdx];
        img.scaleFactor || (img.scaleFactor = 1)

        if (img.scaleFactor > zoomFactor) {
            img.scaleFactor /= (1 + zoomFactor);
        }
        this.updateStyle(img);
    }
    scrollZoom(evt) {
        evt.deltaY > 0 ? this.zoomOut(this.zoomFactor) : this.zoomIn(this.zoomFactor);
        return false;
    }

    /** END Events Handlers */

    private getIndex(image) {
        for (let i = 0; i < this.images.length; i++) {
            if (this.images[i] === image) {
                return i;
            }
        }

        return -1;
    }

    imageUrl(image, index) {
        if (isNaN(index)) {
            index = this.getIndex(image);
        }
        return Math.abs(this.currentIdx - index) <= 1 ? image[this.categorySelected].url : ''
    }

    imageLoaded(image: any): void {
        image.loadedInViewer = true
        this.updateStyle(image);
    }

    revertWithAnimation(image) {
        this.resetImage(image);
        setTimeout(() => {
            this.horizontalPosition = 0;
            this.images[this.currentIdx]['transition'] = '';
        }, 250)
        image['transition'] = 'revert'
    }

    startAnimation(transition, image) {
        if (transition.indexOf('leave') >= 0) {
            this.resetImage(image);
        }
        image['transition'] = transition;
    }

    updateStyle(image: any, index: number = NaN, transition: string = '') {
        if (isNaN(index)) {
            index = this.getIndex(image);
        }
        if (transition) {
            this.startAnimation(transition, image);
        }

        let bg = image.loadedInViewer
            ? 'url(' + image[this.categorySelected].url + ')'
            : Math.abs(this.currentIdx - index) <= 1 ? 'url(' + image[this.initialQuality].url + ')' : '';

        let t = this.currentTransform(image);
        image._style = {
            'background-image': bg,
            'transform': t
        }
    }

    getImageStyle(img, index) {
        if (!img._style) {
            this.updateStyle(img, index);
        }
        return img._style;
    }

    resetImage(img) {
        img.scaleFactor = 1;
        this.translateX = 0;
        this.translateY = 0;
        // this.updateStyle(img);
    }

    /**
     * direction (-1: left, 1: right)
     * swipe (user swiped)
     */
    navigate(direction: number, swipe: any): void {
        if ((direction === 1 && this.currentIdx < this.images.length - 1) ||
            (direction === -1 && this.currentIdx > 0)) {

            if (direction == -1) {
                this.startAnimation('leaveToRight', this.images[this.currentIdx]);
                this.updateStyle(this.images[this.currentIdx - 1], this.currentIdx - 1, 'enterFromLeft');
            } else {
                this.startAnimation('leaveToLeft', this.images[this.currentIdx]);
                this.updateStyle(this.images[this.currentIdx + 1], this.currentIdx - 1, 'enterFromRight');
            }
            this.currentIdx += direction

            if (swipe) {
                this.hideNavigationArrows()
            } else {
                this.showNavigationArrows()
            }
            this.updateImage()
        } else {
            this.revertWithAnimation(this.images[this.currentIdx]);
        }
    }

    private showNavigationArrows(): void {
        this.leftArrowVisible = this.leftArrowActive;
        this.rightArrowVisible = this.rightArrowActive;
    }

    closeViewer(): void {
        this.images.forEach(image => {
            image['transition'] = undefined;
            image.scaleFactor = 1;
            this.translateX = 0;
            this.translateY = 0;
        })
        this.images.forEach(image => image.activeInViewer = false)
        // this.galleryService.showImageViewer(false)
        this.viewerRef.close();
    }

    private hideNavigationArrows(): void {
        this.leftArrowVisible = false
        this.rightArrowVisible = false
    }

    private updateImage(): void {
        // wait for animation to end
        setTimeout(() => {
            this.updateQuality()
            this.images[this.currentIdx].activeInViewer = true
            this.images.forEach(image => {
                if (image != this.images[this.currentIdx]) {
                    image.activeInViewer = false
                    this.horizontalPosition = 0
                }
            })
        }, 500)
    }

    private updateQuality(): void {
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight

        let currentImage: GalleryImage = this.images[this.currentIdx];
        switch (this.qualitySelected) {
            case 'auto': {
                // start with small
                this.categorySelected = 'small';

                if (screenWidth > currentImage['small'].width &&
                    screenHeight > currentImage['small'].height) {
                    this.categorySelected = 'medium'
                }
                if (screenWidth > currentImage['medium'].width &&
                    screenHeight > currentImage['medium'].height) {
                    this.categorySelected = 'large'
                }

                break
            }
            case 'low': {
                this.categorySelected = 'small'
                break
            }
            case 'mid': {
                this.categorySelected = 'medium'
                break
            }
            case 'high': {
                this.categorySelected = 'large'
                break
            }
            default: {
                this.categorySelected = 'medium'
            }
        }
    }

    private keyboardInteraction(event) {
        const prevent = [37, 39, 27, 36, 35]
            .find(no => no === event.keyCode)
        if (prevent) {
            event.preventDefault()
        }

        switch (prevent) {
            case 37:
                // left arrow - navigate left
                this.navigate(-1, false)
                break
            case 39:
                // right arrow - navigate right
                this.navigate(1, false)
                break
            case 27:
                // esc
                this.closeViewer()
                break
            case 36:
                // home - go to 1st item
                this.startAnimation('leaveToRight', this.images[this.currentIdx]);

                this.currentIdx = 0
                this.updateStyle(this.images[this.currentIdx], this.currentIdx, 'enterFromLeft');

                this.updateImage();
                break
            case 35:
                // end - go to last item
                this.startAnimation('leaveToLeft', this.images[this.currentIdx]);

                this.currentIdx = this.images.length - 1
                this.updateStyle(this.images[this.currentIdx], this.currentIdx, 'enterFromRight');

                this.updateImage()
                break
            default:
                break
        }
    }

    private showImage(index: number = 0) {
        this.currentIdx = index;
        this.images.forEach((image: GalleryImage) => image.activeInViewer = false)
        this.images[this.currentIdx].activeInViewer = true

        this.updateStyle(this.images[this.currentIdx], this.currentIdx);

        this.horizontalPosition = 0
        this.updateQuality()
    }


    ngOnInit() {
        this.images = this.data.images;
        this.showImage(this.data.index)

        this.tapSubscription = this.clicks.pipe(
            debounceTime(this.TOUCH_DELAY)
        ).subscribe(evt => {
            if (this.tapCount === 2) {
                this.toggleDrag(evt);
            }
            this.tapCount = 0;
        });
    }

    ngOnDestroy() {
        if (this.tapSubscription) {
            this.tapSubscription.unsubscribe();
        }
    }

}
