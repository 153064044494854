<div class="shape" *ngIf="shape==='circle'">
    <ng-content></ng-content>
    <ng-container>
        <img [ngClass]="{'mat-elevation-z4': !!elevation}" 
            [alt]="name || 'Project avatar related image'" 
            [src]="imageId | projectAvatarUrl:t" 
            [width]="size" 
            [height]="size" 
            [style.border-radius]="borderRadius">
    </ng-container>
</div>

<div class="shape" *ngIf="shape==='rectangle'">
    <ng-content></ng-content>
    <ng-container>
        <img [ngClass]="{'mat-elevation-z4': !!elevation}" 
            [alt]="name || 'Project avatar related image'" 
            [src]="imageId | projectAvatarUrl:t" 
            [width]="size" 
            [height]="size" 
            [style.border-radius]="borderRadius">
    </ng-container>
    <ng-container *ngIf="previewAvatarFile">
        <img class="preview" [src]="previewAvatarFile | blobUrl" 
            [alt]="name || 'Project avatar related image'" 
            [width]="size" 
            [height]="size" 
            [style.border-radius]="borderRadius">
    </ng-container>

    <div class="overlay" *ngIf="processing">
            <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
                <rect 
                    [attr.width]="size" 
                    [attr.height]="size" 
                    [attr.rx]="borderRadius" 
                    [attr.ry]="borderRadius" 
                    [attr.stroke]="sc" 
                    [attr.stroke-width]="0"
                    fill="currentColor" />
            </svg>
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
        

</div>


    