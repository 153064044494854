export enum PageAuthViewType {
  login = 'login',
  register = 'register',
  co_register = 'co_register',
  email = 'email'
}

export enum PageAuthPlaceId {
  survey = '__survey',
}
