import {Component, HostBinding, HostListener} from '@angular/core';
import {BoardViewService} from '@board/_services';
import {customColorTheme} from '@libraries/theming';
import {ThemingService} from '@libraries/theming/theming.service';
import {PageService} from '@pages/page.service';
import {RESTRICTED_PAGES, TOOLS_PAGES} from '@pages/pages.enum';
import {Modal} from "@board/modals/modals-editor/modal.model";
import {ModalListService} from "@board/modals/modals-editor/modal-list.service";

@Component({
  selector: 'modals-editor',
  templateUrl: './modals-editor.component.html',
})
export class ModalsEditorComponent {
  @HostBinding('class.mgc-board-surveys') pageClass = true;

  smallView: boolean = this.boardViewService.smallView();
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.smallView = this.boardViewService.smallView();
  }

  modalInView: Modal = null;
  loading = false;

  constructor(
    pageService: PageService,
    private boardViewService: BoardViewService,
    themingService: ThemingService,
  ) {
    pageService.emit(RESTRICTED_PAGES.MODALS);
    themingService.setPredefinedTheme(customColorTheme);
  }

  selectModalInView(modal: Modal) {
    this.modalInView = modal;
  }
}
