import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { CounterService } from './counter.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'counter',
    templateUrl: 'counter.component.html',
    styleUrls: ['counter.component.scss'],
})

export class CounterComponent {
    @Input() id: string;
    @Input() views: number;


    constructor(
        private countervice: CounterService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        // this.countervice.views(this.id).pipe(take(1)).subscribe(views => {
        //     if (!isNaN(views)) {
        //         this.views = views + 1;
        //     }
        // })

        if (isPlatformBrowser(this.platformId)) {
            this.countervice.pingFromLibrary(this.id);
        }
    }
}