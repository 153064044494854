<div class="processing-logo">

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60"
        viewBox="0 0 500 500">
        <defs>
            <style>
                .cls-1 {
                    fill: url(#radial-gradient);
                }

                .cls-2 {
                    fill: url(#radial-gradient-2);
                }

                .cls-3 {
                    fill: url(#radial-gradient-3);
                }

                .cls-4 {
                    fill: url(#radial-gradient-4);
                }

                .cls-5 {
                    fill: url(#radial-gradient-5);
                }

                .cls-6 {
                    fill: url(#radial-gradient-6);
                }

                .cls-7 {
                    fill: url(#radial-gradient-7);
                }

                .cls-8 {
                    fill: url(#radial-gradient-8);
                }

                .cls-9 {
                    fill: url(#radial-gradient-9);
                }

                .cls-10 {
                    fill: url(#radial-gradient-10);
                }

                .cls-11 {
                    fill: url(#radial-gradient-11);
                }

                .cls-12 {
                    fill: url(#radial-gradient-12);
                }

                .cls-13 {
                    fill: url(#radial-gradient-13);
                }

                .cls-14 {
                    fill: url(#radial-gradient-14);
                }

                .cls-15 {
                    fill: url(#radial-gradient-15);
                }

                .cls-16 {
                    fill: url(#radial-gradient-16);
                }

                .cls-17 {
                    fill: url(#radial-gradient-17);
                }

                .cls-18 {
                    fill: url(#radial-gradient-18);
                }

                .cls-19 {
                    fill: url(#radial-gradient-19);
                }

                .cls-20 {
                    fill: url(#radial-gradient-20);
                }

                .cls-21 {
                    fill: url(#radial-gradient-21);
                }

                .cls-22 {
                    fill: url(#radial-gradient-22);
                }

                .cls-23 {
                    fill: url(#radial-gradient-23);
                }

                .cls-24 {
                    fill: url(#radial-gradient-24);
                }
            </style>
            <radialGradient id="radial-gradient" cx="262.34" cy="187.53" r="80.24" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="0.71" />
            </radialGradient>
            <radialGradient id="radial-gradient-2" cx="246.57" cy="187.55" r="88.57" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="0.75" />
            </radialGradient>
            <radialGradient id="radial-gradient-3" cx="237.43" cy="310.22" r="64.63" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="1" />
            </radialGradient>
            <radialGradient id="radial-gradient-4" cx="253.62" cy="309.8" r="77.67" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-5" cx="313.61" cy="259.99" r="60.45" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="0.94" />
            </radialGradient>
            <radialGradient id="radial-gradient-6" cx="316.11" cy="243.87" r="94.45" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="0.61" />
            </radialGradient>
            <radialGradient id="radial-gradient-7" cx="184.16" cy="234.68" r="61.2" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-8" cx="207.89" cy="251.61" r="110.45" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity="0" />
                <stop offset="0.89" stop-color="#121212" stop-opacity="0.93" />
                <stop offset="0.9" stop-color="#101010" stop-opacity="0.94" />
                <stop offset="1" />
            </radialGradient>
            <radialGradient id="radial-gradient-9" cx="289.52" cy="282.67" r="77.31" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-10" cx="312.48" cy="281.81" r="73.42" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-11" cx="201.55" cy="207.13" r="45.55" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-12" cx="191.98" cy="218.52" r="65.66" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-13" cx="270.36" cy="307.65" r="74.62" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-14" cx="285.23" cy="301.11" r="92.67" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-15" cx="229.94" cy="187.43" r="69.77" xlink:href="#radial-gradient-2" />
            <radialGradient id="radial-gradient-16" cx="220.11" cy="203.43" r="90.24" xlink:href="#radial-gradient-2" />
            <radialGradient id="radial-gradient-17" cx="212.66" cy="295.31" r="65.66" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-18" cx="226.29" cy="289.77" r="105.27"
                xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-19" cx="295.92" cy="200.52" r="73.12" xlink:href="#radial-gradient" />
            <radialGradient id="radial-gradient-20" cx="280.32" cy="188.59" r="89.69" xlink:href="#radial-gradient" />
            <radialGradient id="radial-gradient-21" cx="198.31" cy="269.33" r="70.27" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-22" cx="202.01" cy="277.1" r="97.88" xlink:href="#radial-gradient-3" />
            <radialGradient id="radial-gradient-23" cx="310.83" cy="227.43" r="68.71" xlink:href="#radial-gradient-5" />
            <radialGradient id="radial-gradient-24" cx="300.34" cy="215.92" r="82.41" xlink:href="#radial-gradient-5" />
        </defs>
        <g id="Layer_45" data-name="Layer 45">
            <polygon class="cls-1" points="260.86 191.14 274.94 105.05 299.71 111.23 260.86 191.14" />
            <polygon class="cls-2" points="247.03 190.99 237.81 79.33 270.59 79.33 247.03 190.99" />
            <polygon class="cls-3" points="239.14 306.74 225.06 392.83 200.29 386.66 239.14 306.74" />
            <polygon class="cls-4" points="252.97 306.9 262.19 418.55 229.41 418.55 252.97 306.9" />
            <polygon class="cls-5" points="307.99 258.73 394.33 271.21 388.61 296.09 307.99 258.73" />
            <polygon class="cls-6" points="307.89 244.9 419.36 233.61 419.96 266.39 307.89 244.9" />
            <polygon class="cls-7" points="192.01 239.15 105.67 226.67 111.39 201.79 192.01 239.15" />
            <polygon class="cls-8" points="192.11 252.98 80.64 264.27 80.04 231.5 192.11 252.98" />
            <polygon class="cls-9" points="295.09 286.7 363.29 341.1 345.78 359.68 295.09 286.7" />
            <polygon class="cls-10" points="302 274.71 403.88 321.3 387.84 349.89 302 274.71" />
            <polygon class="cls-11" points="204.91 211.19 136.71 156.78 154.22 138.21 204.91 211.19" />
            <polygon class="cls-12" points="198 223.17 96.12 176.58 112.16 147.99 198 223.17" />
            <polygon class="cls-13" points="269.45 304.44 300.24 386.06 275.7 393.08 269.45 304.44" />
            <polygon class="cls-14" points="281.51 297.67 345.26 389.8 316.86 406.17 281.51 297.67" />
            <polygon class="cls-15" points="230.55 193.44 199.76 111.82 224.3 104.8 230.55 193.44" />
            <polygon class="cls-16" points="218.49 200.21 154.74 108.08 183.14 91.71 218.49 200.21" />
            <polygon class="cls-17" points="211.96 293.8 157.13 361.65 138.67 344.03 211.96 293.8" />
            <polygon class="cls-18" points="223.91 300.78 176.68 402.37 148.19 386.15 223.91 300.78" />
            <polygon class="cls-19" points="288.04 204.09 342.87 136.23 361.33 153.86 288.04 204.09" />
            <polygon class="cls-20" points="276.09 197.11 323.32 95.51 351.81 111.73 276.09 197.11" />
            <polygon class="cls-21" points="194.69 268.94 113.38 300.54 106.12 276.06 194.69 268.94" />
            <polygon class="cls-22" points="201.59 280.93 110.08 345.58 93.44 317.35 201.59 280.93" />
            <polygon class="cls-23" points="305.31 228.94 386.62 197.35 393.88 221.82 305.31 228.94" />
            <polygon class="cls-24" points="298.42 216.95 389.92 152.3 406.56 180.54 298.42 216.95" />
            <circle cx="287.33" cy="108.14" r="12.76" />
            <circle cx="254.2" cy="79.33" r="16.39" />
            <circle cx="212.67" cy="389.75" r="12.76" />
            <circle cx="245.8" cy="418.55" r="16.39" />
            <circle cx="398.24" cy="166.42" r="16.39" />
            <circle cx="390.25" cy="209.58" r="12.76" />
            <circle cx="101.76" cy="331.47" r="16.39" />
            <circle cx="109.75" cy="288.3" r="12.76" />
            <circle cx="337.57" cy="103.62" r="16.39" />
            <circle cx="352.1" cy="145.05" r="12.76" />
            <circle cx="162.43" cy="394.26" r="16.39" />
            <circle cx="147.9" cy="352.84" r="12.76" />
            <circle cx="168.94" cy="99.89" r="16.39" />
            <circle cx="212.03" cy="108.31" r="12.76" />
            <circle cx="331.06" cy="397.99" r="16.39" />
            <circle cx="287.97" cy="389.57" r="12.76" />
            <circle cx="104.14" cy="162.29" r="16.39" />
            <circle cx="145.47" cy="147.49" r="12.76" />
            <circle cx="395.86" cy="335.6" r="16.39" />
            <circle cx="354.53" cy="350.39" r="12.76" />
            <circle cx="80.34" cy="247.88" r="16.39" />
            <circle cx="108.53" cy="214.23" r="12.76" />
            <circle cx="391.47" cy="283.65" r="12.76" />
            <circle cx="419.66" cy="250" r="16.39" />
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 250 250"
                to="360 250 250" dur="3s" repeatCount="indefinite" />
        </g>
    </svg>
</div>

<span>processing</span>