import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { GalleryOrganizerComponent } from './gallery-organizer.component';
import { OrganizerMediaGridComponent } from './organizer-media-grid.component';
import { OrganizerMediaDetailsComponent } from './organizer-media-details.component';
import { OrganizerActionsBarComponent } from './organizer-actions-bar.component';
import { GridItemDirective } from './grid-item.directive';
import { GalleryOrganizerService } from './gallery-organizer.service';
import { GalleryOrganizerModalComponent } from './gallery-organizer-modal.component';
import { MultiGalleryModule } from '@libraries/multi-gallery/x_multi-gallery.module';
import { AlertModule } from '@rallysite/components/alert';
import { MgcDialogModule, MgcButtonModule } from '@rallysite/mgc-components';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        MultiGalleryModule,
        MgcDialogModule,
        MgcButtonModule,
        MatIconModule,
        AlertModule,
        DragDropModule,
        CdkDropList,
        CdkDrag
    ],
    declarations: [
        GalleryOrganizerComponent,
        GridItemDirective,
        OrganizerActionsBarComponent,
        OrganizerMediaGridComponent,
        OrganizerMediaDetailsComponent,
        GalleryOrganizerModalComponent
    ],
    providers: [GalleryOrganizerService],
    exports: [
        GalleryOrganizerComponent,
        OrganizerActionsBarComponent,
        OrganizerMediaGridComponent,
        GridItemDirective,
        GalleryOrganizerModalComponent
    ]
})
export class GalleryOrganizerModule {

  static withCustomProviders(providers: any): ModuleWithProviders<any> {
    return {
      ngModule: GalleryOrganizerModule,
      providers: [
        providers.OrganizerService ?
          {
            provide: GalleryOrganizerService,
            useClass: providers.OrganizerService
          } : GalleryOrganizerService,
      ]
    };
  }

}
