<ng-container>
  <mgc-auth-view
    [skipsignin]="skipsignin"
    [view]="_authViewType"
    [label]="label"
    [placeId]="placeId"
    [email]="email"
    (close)="onClose($event)"
  ></mgc-auth-view>
</ng-container>

<div class="btns" *ngIf="_authViewType===authViewType.email">
  <mgc-raised-button full [processing]="processing" (click)="onSubmit()">
    <span [innerHTML]="'Next'"></span>
  </mgc-raised-button>
  <mgc-stroked-button full (click)="onClose()">
    <span [innerHTML]="'Cancel'"></span>
  </mgc-stroked-button>
</div>

<div class="btns" *ngIf="_authViewType===authViewType.login">
  <a left (click)="switchAuth(authViewType.register)">Create new</a>
  <mgc-raised-button [processing]="processing" (click)="onSubmit()">Login</mgc-raised-button>
  <mgc-stroked-button full (click)="onClose()">
    <span [innerHTML]="'Cancel'"></span>
  </mgc-stroked-button>
</div>

<div class="btns" *ngIf="_authViewType===authViewType.register">
  <a left (click)="switchAuth(authViewType.login)">Sign in instead</a>
  <mgc-raised-button [processing]="processing" (click)="onSubmit()">Continue</mgc-raised-button>
<!--  <mgc-stroked-button full (click)="onClose()">-->
<!--    <span [innerHTML]="placeId===PageAuthPlaceId.survey?'Skip':'Cancel'"></span>-->
<!--  </mgc-stroked-button>-->
</div>
