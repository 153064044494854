
export interface IProjectLibraryFile {
    Id?: string;
    ProjectId: string;
    Status: 'active' | 'selected' | 'deleted';
    MimeType: string;
    SystemName: string;
    FileName: string;
    Rotation: number;
    Size: number;
    CreateDate: string;
    UpdateDate: string;

    getSizeSet(string);
}

export class ProjectLibraryFile implements IProjectLibraryFile {
    Id: string;
    ProjectId: string;
    Status: 'active' | 'selected' | 'deleted';
    MimeType: string;
    SystemName: string;
    FileName: string;
    Rotation: number;
    Size: number;
    CreateDate: string;
    UpdateDate: string;

    isImage: boolean = false;
    loaded: boolean = false;

    private _srcset: string;
    private _url: string;
    private _type: {
        class: string,
        ext: string
    }
    public removed: boolean = false;

    constructor(private data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }

        this.isImage = this.MimeType.indexOf('image') >= 0;
    }

    getSizeSet(endpoint: string){
        return {
            small: `${endpoint}/${this.Id}/url/small`,
            medium: `${endpoint}/${this.Id}/url/medium`,
            large: `${endpoint}/${this.Id}/url/large`,
        }
    }

}

