<div @animation class="tlt-wrapper">

    <div class="tlt-title">
        <span (dblclick)="toogleEditable()">
            Help Tip
        </span>
        <mgc-icon-button id="close" class="w30" tabindex="-1" (click)="onClose()" icon="board-mat:close"></mgc-icon-button>
    </div>

    <div *ngIf="!editMode" class="tlt-body mgc-scroll-area">
        <div *ngIf="tooltip.Heading" class="tlt-heading">
            <span [innerHTML]="tooltip.Heading||'' | safe:'html'"></span>
        </div>

        <div class="tlt-content">
            <span [innerHTML]="tooltip.Content||'' | safe:['lineBreak','linky']"></span>
        </div>

        <div *ngIf="tooltip.url">
            <a [attr.href]="tooltip.url" class="tlt-url">{{ tooltip.url }}</a>
        </div>
    </div>

    <div *ngIf="canEdit && editMode" class="tlt-body mgc-scroll-area">
        <form [formGroup]="form">
            <mgc-form-field>
                <div class="field-wrapper">
                    <input tabindex="-1" formControlName="heading" name="tooltip-heading">
                    <span class="placeholder" *ngIf="!f.heading.value">Heading title</span>
                </div>
            </mgc-form-field>
            <mgc-form-field multiline>
                <div class="field-wrapper">
                  <textarea autosize tabindex="-1" startRows="3" name="tooltip-content" placeholder="Tooltip content"
                  formControlName="content"></textarea>
                </div>
            </mgc-form-field>
        </form>

    </div>

    <div class="tlt-actions">
        <div class="left" *ngIf="canEdit">
            <div *ngIf="!editMode" (click)="onEdit()">Edit</div>
            <div *ngIf="editMode" (click)="onSave()">Save</div>
        </div>

        <div (click)="onClose()">Close</div>
    </div>

</div>