import { ReactionTypes } from "@reactions/reactions.enum";

export class ReactionData {
    Id: string;
    Type: ReactionTypes;

    UpdateDate: string;

    _account: any;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}
