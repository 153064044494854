<form [formGroup]="authForm">

    <mgc-form-field label="Email" [invalid]="submitted && f.email.errors">
        <div class="field-wrapper">
            <input formControlName="email" name="email" autocomplete="email">
            <span class="placeholder" *ngIf="!f.email.value">Enter your email here ...</span>
        </div>
        <ng-container invalid-feedback *ngIf="submitted && f.email.errors">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
        </ng-container>
    </mgc-form-field>

    <mgc-form-field label="Password" [invalid]="submitted && f.password.errors">
        <div class="field-wrapper">
            <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" name="password"
                autocomplete="password">
            <span class="placeholder" *ngIf="!f.password.value">Enter your password here ...</span>
        </div>
        <ng-container invalid-feedback *ngIf="submitted && f.password.errors">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
        </ng-container>
    </mgc-form-field>

    <mat-checkbox class="mgc-form-control normal-wrap" formControlName="agree" name="agree">I agree to the
        <a [attr.href]="config.legal.terms" target="_blank">Terms and
            Conditions and Privacy Policy</a>
    </mat-checkbox>

    <div class="auth-form-section">
        <a class="forgot" target="_blank" href="/auth/reset">Forgot your password?</a>
    </div>

</form>