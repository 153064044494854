import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FtmTask } from '@fotameco/ftm-task/ftm-task.model';
import { TaskV2 } from '@items/task-v2/task-v2.model';
import { Task } from '@items/task/task';
import { TaskV2BaseBlockComponent } from '../taskv2-base-block.component';
import { isTallFrame, urlToPlayeriFrame, urlType } from './url-to-iframe.helpers';
import { VideoUrlType, VIDEO_TYPES } from './url-types';
import { TaskV2VideoBlock } from './video-block';

@Component({
  selector: 'task-v2-video-block',
  templateUrl: './video-block.component.html',
  styleUrls: ['./video-block.component.scss']
})
export class TaskV2VideoBlockComponent extends TaskV2BaseBlockComponent implements AfterViewInit {

  constructor(
    el: ElementRef,
    formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) platformId: any,
    private renderer: Renderer2
  ) {
    super(el, formBuilder, platformId);
  }
  get f() { return this.form.controls; }
  @Input() block: TaskV2VideoBlock;
  @Input() task: TaskV2 | Task | FtmTask;

  @ViewChild('embedWrapper') embedWrapper: ElementRef;

  @Output() remove: EventEmitter<TaskV2VideoBlock> = new EventEmitter(null);

  embed = '';
  isTall = false;
  urlType: VIDEO_TYPES;

  /** FORM */
  form: FormGroup;

  submitted = false;


  @HostListener('window:resize', ['$event'])
  adjustWrapperSize(event = null) {
    if (this.embedWrapper) {

      if (this.isTall) {
        const { border, header, footer, ratio } = this.ratioAndSizes();
        const height = (this.embedWrapper.nativeElement.clientWidth + border * 2) * ratio + header + footer;
        this.renderer.setStyle(this.embedWrapper.nativeElement, 'height', `${height}px`);
        this.renderer.setStyle(this.embedWrapper.nativeElement, 'border-width', `${border}px`);
      }
    }
  }
  bootstrapForm() {
    this.submitted = false;

    this.form = this.formBuilder.group({
      link: [this.block.Content, Validators.required],
    });
  }

  getProps() {
    return this.form.getRawValue();
  }
  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */


  private render() {
    if (isPlatformServer(this.platformId)) {
      this.embed = this.block.Content;
    } else {
      this.embed = urlToPlayeriFrame(this.block.Content, {
        projectId: this.task.ProjectId,
        taskId: this.task.Id,
        pgr: window.location.href.includes('/library') ? 'lib' : 'warmup',
      });
      this.isTall = isTallFrame(this.block.Content);
      this.urlType = urlType(this.block.Content);

      setTimeout(() => {
        this.adjustWrapperSize();
      }, 10);
    }
  }

  ratioAndSizes() {
    if (this.urlType === VIDEO_TYPES.TIKTOK) {
      return { border: 0, header: 0, footer: 180, ratio: 1.0426 };
    }

    if (this.urlType === VIDEO_TYPES.INSTAGRAM) {
      return { border: 1, header: 54, footer: 42, ratio: 1.25 };
    }
  }

  onLinkChange(e) {
    if (this.submit()) {
      this.block.Content = this.form.value['link'];
      this.render();
    }
  }
  onRemove() {
    this.remove.emit(this.block);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.preview) {
      this.bootstrapForm();
    }
    this.render();
  }

  ngAfterViewInit() {
  }

}
