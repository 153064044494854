import {
    Component,
    OnInit,
    Input,
    HostBinding,
    EventEmitter,
    Output,
} from '@angular/core';
import { IMultiGalleryOptions, MultiGalleryOptions, IMultiGalleryCategory } from './multi-gallery.options.model';
import { MultiGalleryService } from './multi-gallery.service';
import { takeWhile, take } from 'rxjs/operators';

import { ServiceStates } from '@rallysite/global-interfaces';
import { IUploaderOptions } from '@libraries/uploader';

@Component({
    selector: 'multi-gallery',
    templateUrl: './multi-gallery.component.html',
    styleUrls: ['./multi-gallery.component.scss'],
})
export class MultiGalleryComponent implements OnInit {
    @HostBinding('class.mgc-multi-gallery') multiGalleryClass = true;

    @Input() options: IMultiGalleryOptions;
    @Input('imageQuality') quality: 'small' | 'medium' | 'large' = 'small';
    @Input('selectedItems') existingImages: Array<any> = [];

    @Output() submitDisabled = new EventEmitter<boolean>();

    uploaderOptions: IUploaderOptions;
    selectedCategory: IMultiGalleryCategory = null;

    files: Array<any>;
    galleryImages: any;

    state: number;

    get states() {
        return ServiceStates.STATES;
    }

    constructor(
        private multiGalleryService: MultiGalleryService,
    ) {
    }

    onImageBeforeUpload(item: any) {
        item['id'] = Math.random();
        let previewUrl = URL.createObjectURL(item.file.rawFile);

        this.galleryImages.splice(0, 0, {
            // this id will be updated when image is successfully updated
            id: item['id'],
            small: previewUrl,
            medium: previewUrl,
            large: previewUrl,
            item: item,
        })
    }

    onImageProgress(data: { item: any; response: any }) {

    }

    onImageSuccess(data: { item: any; response: any }) {
        let done: boolean = false;
        this.multiGalleryService.addSuccess(data.response, this.selectedCategory).pipe(
            take(1))
            .subscribe((files) => {
                // update the id of the item in gallery; 
                // if file is selected to be added into project we'll know the file by the id
                data.item['id'] = data.response['Id'];
                // update the files with the new added one
                this.files = files;
                this.galleryImages = this.convertToGallery(this.files);
            })
    }

    onImageError(data: { item: any; response: any }) {

    }

    toggleSelectImage(id) {
        this.galleryImages.forEach(img => {
            if (img.id === id) {
                img.selected = !img.selected;
            } else if (!this.options.multiSelect) {
                img.selected = false;
            }
        })

        this.submitDisabled.next(this.anySelectedFile)
    }

    get selectedFiles(): Array<any> {
        if (!this.galleryImages) return [];
        return this.galleryImages.filter(img => img.selected && !img.inUse);
    }

    get anySelectedFile(): boolean {
        if (!this.galleryImages) return false;
        return this.galleryImages.filter(img => img.selected).length > 0;
    }

    private convertToGallery(files: Array<any>) {
        let endPoint: string = this.multiGalleryService.endPoint;
        return files.map((file) => {
            return {
                id: file.Id,
                small: `${endPoint}/${file.Id}/url/small`,
                medium: `${endPoint}/${file.Id}/url/medium`,
                large: `${endPoint}/${file.Id}/url/large`,
            }
        })
    }

    private markInUseItems() {
        this.galleryImages.forEach(img => {
            img.inUse = img.selected = false;
            if (this.existingImages.find((itm => itm.id === img.id))) {
                img.inUse = img.selected = true;
            }
        });

        this.submitDisabled.next(this.anySelectedFile)
    }

    selectCategory(category: IMultiGalleryCategory) {
        if (this.selectedCategory && this.selectedCategory.id === category.id) {
            return;
        }

        this.selectedCategory = category;

        let loading: boolean = true;
        this.state = this.states.LOADING;
        this.multiGalleryService.loadFiles(category).pipe(
            takeWhile(() => loading))
            .subscribe(
                data => {
                    loading = false;

                    this.updateUploaderOptions();
                    this.files = data;
                    this.galleryImages = this.convertToGallery(this.files);
                    this.markInUseItems();
                    this.state = this.states.DONE;
                },
                error => {
                    loading = false;
                    this.state = this.states.ERROR;
                });
    }

    private updateUploaderOptions() {
        this.uploaderOptions = {
            autoUpload: true,
            filename: this.selectedCategory.filename,
            tokenGetter: this.options.tokenGetter,
            endPoint: this.selectedCategory.endPoint,
            payload: this.selectedCategory.payload,
        }
    }

    identifyCurrentCategory(categories: IMultiGalleryCategory[]) {
        if (categories.length === 0) {
            return null;
        }

        return categories.find(c => c.active) || categories[0];
    }

    ngOnInit() {
        this.options = new MultiGalleryOptions(this.options);
        this.selectCategory(this.identifyCurrentCategory(this.options.categories));
    }
}