import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewContainerRef } from '@angular/core';

import { DoNotShowAgainOptionDirective } from './do-no-show-again-option.directive';
import { DoNotShowAgainOptionComponent, DoNotShowAgainOptionItem } from './dnsa-option-components';

@Component({
  selector: 'do-not-show-me-again-inline',
  template: `
    <ng-template dnsa-option-info></ng-template>
    <do-not-show-again-option [option]="data.option" (close)="onClose($event)"></do-not-show-again-option>
  `
})
export class DoNotShowAgainInlineComponent implements OnInit {

  @Input() data: DoNotShowAgainOptionItem;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(DoNotShowAgainOptionDirective) dnsaOptionHost: DoNotShowAgainOptionDirective;

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) {
  }

  onClose(result: string) {
    this.close.next();
  }

  loadComponent() {
    const componentRef = this.viewContainerRef.createComponent(this.data.component);
    (<DoNotShowAgainOptionComponent>componentRef.instance).option = this.data.option;
  }

  ngOnInit() {
    this.loadComponent();
  }

}
