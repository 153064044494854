import { Component, ViewChild, OnInit, Input, Inject } from '@angular/core';
import { CSVEmailRecord } from './csv-email-record.model';
import { AlertService } from '@rallysite/components/alert';
import { IUploaderOptions, UploaderComponent } from '@libraries/uploader';
import { Project } from '@board/items/project/project';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AccountService } from '@board-accounts/account.service';

@Component({
    selector: 'csv-email',
    templateUrl: './csv-email.component.html',
    styleUrls: ['./csv-email.component.scss']
})
export class CSVEmailComponent implements OnInit {


    constructor(
        private alert: AlertService,
        private authService: AuthService,
        private accountService: AccountService,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {

    }

    @Input() project: Project;

    @ViewChild(UploaderComponent) uploader: UploaderComponent;

    private possibleHeaders = [{
        th: 'Email', prop: 'email'
    }, {
        th: 'Level', prop: 'level'
    }];
    headers: any = [];

    records: {
        total: any[],
        valid: any[],
        invalid: any[],
        duplicates: any[]
    } = {
            total: [],
            valid: [],
            invalid: [],
            duplicates: []
        };

    summary: string[] = Object.keys(this.records);
    ctx: {};

    uploaderOptions: IUploaderOptions;

    loading = false;
    processing = false;
    submited = false;

    checked = false;

    show = false;
    _type: string;

    inviterId: string;

    browseClick() {
        // setTimeout(() => {
        //     this.loading = true;
        // }, 500);
        this.uploader.clearQueue();
        this.uploader.click();
    }

    onBeforeUploadFile(item: any) { }
    onUploadSuccess(item: any) { }
    onUploadError(item: any) { }

    /** CSV-UPLOADER methods */
    onAfterAddingFile(item: any) {
        this.fileReset();

        const file = item.file.rawFile;

        if (!this.isValidCSVFile(file)) {
            this.alert.error('Please import valid .csv file.');
            return;
        }

        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
            const csvData = reader.result;
            const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

            const headersRow = this.getHeaderArray(csvRecordsArray);
            for (let i = 0; i < headersRow.length; i++) {
                if (this.possibleHeaders[i]) {
                    this.headers.push(this.possibleHeaders[i]);
                }
            }

            this.getDataRecordsArrayFromCSVFile(csvRecordsArray);

            this.loading = false;
            if (this.records.invalid.length > 0) {
                this.alert.warning('Please fix the invalid entries in order to move further and add the valid participants.');
            }
        };

        reader.onerror = () => {
            this.alert.error('Error is occured while reading file!');
        };
    }

    upload() {
        if (this.processing) {
            return;
        }
        this.processing = true;

        this.uploader.uploader.onCompleteAll = () => {
            this.processing = false;
            this.submited = true;
            this.alert.success(`All good. We\'ll send you an email when all participants have been processed.
            <br>After receiving the email, you need to refresh the list of participants to see the new added ones.`);
        };

        this.uploader.upload();
    }
    toggleShow(data: string) {
        this.ctx = {
            type: data
        };

        if (data === this._type) {
            this.show = !this.show;
        } else {
            this.show = true;
        }
        this._type = data;
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
        for (let i = 1; i < csvRecordsArray.length; i++) {
            const currentRecord = (<string>csvRecordsArray[i]).split(',');
            if (this.validRow(currentRecord)) {
                const csvRecord: CSVEmailRecord = new CSVEmailRecord({
                    row: i + 1,
                    email: currentRecord[0].trim(),
                    level: (currentRecord[1] || '').trim()
                });

                if (this.isDuplicate(csvRecord.email)) {
                    this.records.duplicates.push(csvRecord);
                } else if (this.validateEmail(csvRecord.email)) {
                    this.records.valid.push(csvRecord);
                } else {
                    this.records.invalid.push(csvRecord);
                }

                this.records.total.push(csvRecord);
            }
        }
    }

    isValidCSVFile(file: any) {
        return file.name.endsWith('.csv');
    }

    getHeaderArray(csvRecordsArr: any) {
        const headers = (<string>csvRecordsArr[0]).split(',');
        const headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    fileReset() {
        // this.csvReader.nativeElement.value = "";
        this.alert.clear();
        this.records = {
            duplicates: [],
            invalid: [],
            total: [],
            valid: []
        };
        this.submited = false;
        this.headers = [];
    }

    validRow(record) {
        return !!record[0].trim();
    }

    validateEmail(email: string) {
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
        return emailPattern.test(email);
    }

    isDuplicate(email: string) {
        return this.records.total.filter((row, i) => row.email.toLowerCase() == email.toLowerCase()).length > 0;
    }

    checkedChange(ev) {
        this.setUploaderOptions();
    }
    updateSender(sender: string = null) {
        this.inviterId = sender;
        setTimeout(() => {
            this.setUploaderOptions();
        }, 20);
    }

    setUploaderOptions() {
        this.uploaderOptions = {
            autoUpload: false,
            filename: 'csv',
            tokenGetter: this.authService.tokenGetter,
            endPoint: `${this.config.endpoint}/api/participants/csv`,
            payload: {
                ProjectId: this.project.Id,
                AccountId: this.accountService.currentAccount.Id,
                InviterId: this.inviterId || this.accountService.currentAccount.Id,
                doNotNotify: this.checked
            }
        };
    }

    ngOnInit() {
        this.setUploaderOptions();
    }
}
