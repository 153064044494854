import { BaseItem } from "@board/items/base-item.model";
import { VideoCampaign } from "../video-campaign/video-campaign";
import { IPanelComponent, PanelComponents } from "@panel-components";
import { VideoItemSettings, IVideoItemSettings } from "./board/video-item-settings.class";
import { IAccount } from "@board-accounts/account.model";

export class VideoItemAttributes {
    Id: string = null;
    ReferenceId: string = null;
    AccountId: string = null;
    CampaignId: string = null;
    GroupId: string = null;
    Name: string = null;
    Status: string = null;

    LaunchDate: string = null;
    LaunchTimezone: string = null;
    Launched: boolean = null;

    Settings: IVideoItemSettings = null;

    CreateDate: string = null;
    UpdateDate: string = null;

    _account: IAccount = null;
}

export class BaseVideoItem extends BaseItem {

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.VIDEO_ITEM_NAME,
        PanelComponents.VIDEO_ITEM_COPY,
    ];
    readonly _type: string = 'video-item';
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.VIDEO_ITEM_BOARD;
    }

    sideDefaultPanel(allowFn: Function) {
        return PanelComponents.VIDEO_ITEM_NAME;
    }

    prevAttributes: VideoItemAttributes = new VideoItemAttributes();
    attributes: VideoItemAttributes = new VideoItemAttributes();

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get ReferenceId(): string {
        return this.attributes.ReferenceId;
    }
    set ReferenceId(value: string) {
        this.attributes.ReferenceId = value;
    }

    get Account(): IAccount {
        return this.attributes._account;
    }
    set Account(value: IAccount) {
        this.attributes._account = value;
    }

    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get CampaignId(): string {
        return this.attributes.CampaignId;
    }
    set CampaignId(value: string) {
        this.attributes.CampaignId = value;
    }
    get GroupId(): string {
        return this.attributes.GroupId;
    }
    set GroupId(value: string) {
        this.attributes.GroupId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Status(): string {
        return this.attributes.Status;
    }
    set Status(value: string) {
        this.attributes.Status = value;
    }

    set LaunchDate(value: string) {
        this.attributes.LaunchDate = value;
    }
    get LaunchDate(): string {
        return this.attributes.LaunchDate;
    }
    set Launched(value: boolean) {
        this.attributes.Launched = value;
    }
    get Launched(): boolean {
        return this.attributes.Launched;
    }

    set LaunchTimezone(value: string) {
        this.attributes.LaunchTimezone = value;
    }
    get LaunchTimezone(): string {
        return this.attributes.LaunchTimezone;
    }

    set Settings(value: IVideoItemSettings) {
        this.attributes.Settings = value instanceof VideoItemSettings ? value : new VideoItemSettings(value || {});
    }
    get Settings(): IVideoItemSettings {
        return this.attributes.Settings;
    }

    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    _state: string;
    _scrollY: number;


    modalOptions({ campaign, panel, edit }: {
        campaign: VideoCampaign,
        panel?: IPanelComponent,
        edit?: boolean,
    }) {
        edit = !!edit;
        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Video Item',
                videoItem: this,
                campaign: campaign,
                panel: panel,
                edit: edit,
            }
        }
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            ReferenceId: this.ReferenceId,
            AccountId: this.AccountId,
            CampaignId: this.CampaignId,
            GroupId: this.GroupId,
            Name: this.Name,
            Status: this.Status,
            Settings: this.Settings.toDb(),
            LaunchDate: this.LaunchDate,
            LaunchTimezone: this.LaunchTimezone,
            UpdateDate: this.UpdateDate
        });
    }

    toJSON() {
        return this.toDb();
    }

    isValid() {
        return false;
    }
}

export class VideoItem extends BaseVideoItem {

    constructor(data: any) {
        super();

        data = { Settings: null, Launched: false, ...data };

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }
    }

    isValid() {
        return this.Settings.playlist.assets.length > 0
    }
}
