import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyButtonComponent } from './survey-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule
    ],
    declarations: [
        SurveyButtonComponent,
    ],
    exports: [
        SurveyButtonComponent,
    ]
})
export class SurveyButtonModule { }
