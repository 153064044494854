<!-- NewParticipantListComponent -->
<mgc-dialog *ngIf="choseAddType">

  <mgc-dialog-header title="Add participants" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>
    <div class="input-blocks">
      <div (click)="selectInputAs('upload')">
        <div class="block-icon">
          <mat-icon svgIcon="board-mat:csv-upload"></mat-icon>
          <label>Upload a file</label>
        </div>
        <span>Add participants by uploading a file from your computer.</span>

        <span class="sample" (click)="downloadSample($event)">
          {{sampleFileName}}
          <mat-chip-list class="mgc-chip-list" >
            <mat-chip (click)="downloadSample($event)">{{sampleRows.length - 1}} Emails</mat-chip>
          </mat-chip-list>
        </span>
      </div>
      <div (click)="selectInputAs('manual')">
        <div class="block-icon">
          <mat-icon svgIcon="board-mat:add"></mat-icon>
          <label>Manual Input</label>
        </div>
        <span>Type in contact by hand or copy and paste from spreadsheet. One contact at a time.</span>
      </div>
    </div>
  </mgc-dialog-content>


  <mgc-dialog-actions>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>


<!-- NewParticipantListComponent -->
<mgc-dialog *ngIf="refInView===REFs.NewParticipantListComponent">

  <mgc-dialog-header title="Add participants" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>
    <new-participant-list [project]="data.project" (close)="closeDialog()">
    </new-participant-list>
  </mgc-dialog-content>

  <!-- <alert [service]="[serviceAlertClass.PARTICIPANT_CREATE]"></alert> -->

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="processing" [disabled]="disabled" (click)="onSubmit()">Add Participants
    </mgc-raised-button>
    <!-- <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button> -->
  </mgc-dialog-actions>

</mgc-dialog>


<!-- NewParticipantDataComponent -->
<mgc-dialog *ngIf="refInView===REFs.NewParticipantDataComponent">

  <mgc-dialog-header title="Add participant" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>
    <new-participant-data [participant]="data.participant" [project]="data.project" (close)="closeDialog()">
    </new-participant-data>
  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.PARTICIPANT_CREATE]"></alert>

  <div *ngIf="showResend" class="warning" [style.padding-bottom.px]="15" [innerHTML]="participantData?.resend.message">
  </div>

  <mgc-dialog-actions>
    <mgc-stroked-button *ngIf="showResend" [processing]="resending" left (click)="resend()" [disabled]="resent">Resend
      Invitation</mgc-stroked-button>
    <mgc-raised-button right [processing]="processing" (click)="onSubmit()">Add Participant
    </mgc-raised-button>
    <!-- <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button> -->
  </mgc-dialog-actions>

</mgc-dialog>



<!-- MustActivateComponent -->
<mgc-dialog *ngIf="refInView===REFs.MustActivateComponent">

  <mgc-dialog-header title="Activation required" (close)="closeDialog()"> </mgc-dialog-header>

  <mgc-dialog-content>
    <must-activate message="You need to confirm your email address before you can add people to projects"
      (close)="closeDialog()"></must-activate>
  </mgc-dialog-content>

  <alert></alert>

  <mgc-dialog-actions>
    <mgc-stroked-button *ngIf="!activated" left [processing]="resending" (click)="resend()">Send Me The Code
    </mgc-stroked-button>
    <mgc-raised-button *ngIf="!activated" right [processing]="processing" (click)="onSubmit()">Activate
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Close</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>