import { FileUploaderOptions } from "./file-upload";

export interface IUploaderOptions {
    filename?: string;
    displayText?: string;
    buttonText?: string;
    endPoint: string;
    payload: any;
    tokenGetter: () => string;
    autoUpload?: boolean;
    authToken?: string;
}

export class UploaderOptions implements IUploaderOptions, FileUploaderOptions {
    filename: string;
    displayText: string;
    buttonText: string;
    endPoint: string;
    payload: any;
    tokenGetter: () => string;
    autoUpload?: boolean;
    authToken: string;

    constructor(obj: IUploaderOptions) {

        function use<T>(source: T, defaultValue: T): T {
            return obj && (source !== undefined) ? source : defaultValue;
        }

        this.filename = use(obj.filename, 'file_name');
        this.displayText = use(obj.displayText, 'Drop an image here');
        this.buttonText = use(obj.buttonText, 'Upload images');
        this.endPoint = use(obj.endPoint, '');
        this.payload = use(obj.payload, null);
        this.tokenGetter = use(obj.tokenGetter, () => null);
        this.autoUpload = use(obj.autoUpload, false);


        this.authToken = 'Bearer ' + this.tokenGetter();
    }
}