
export interface IPanelComponent {
    id: string;
    name: string;
    icon: string;
    modal?: boolean;
}

export class PanelComponents {

    public static VIEW_LIST: IPanelComponent = {
        id: 'list',
        name: 'List',
        icon: 'view_list'
    };
    public static DASHBOARD: IPanelComponent = {
        id: 'dashboard',
        name: 'My History',
        icon: 'list'
    };
    public static NOTES: IPanelComponent = {
        id: 'notes',
        name: 'Notes',
        icon: 'textsms'
    };
    public static SETTINGS: IPanelComponent = {
        id: 'settings',
        name: 'Settings',
        icon: 'settings',
    };

    public static SETTINGS_PROJECT_NAME: IPanelComponent = {
        id: 'settings_name',
        name: 'Plan Settings',
        icon: 'settings',
    };

    public static SETTINGS_RESTRICTED_USERS: IPanelComponent = {
        id: 'settings_restricted_users',
        name: 'Collection',
        icon: 'collection',
    };

    public static SETTINGS_PROJECT_E_MESSAGE: IPanelComponent = {
        id: 'settings_emessage',
        name: 'E-Messaging',
        icon: 'email',
    };

    public static SETTINGS_PROJECT_THEME: IPanelComponent = {
        id: 'settings_theme',
        name: 'Theme/Colors',
        icon: 'palette',
    };

    public static SETTINGS_TASK_NAME: IPanelComponent = {
        id: 'settings_name',
        name: 'Item Name',
        icon: 'title',
    };

    public static SETTINGS_SOCIAL_MEDIA: IPanelComponent = {
        id: 'social_medial',
        name: 'Social Media',
        icon: 'share',
    };

    public static SETTINGS_TASK_TAGGING: IPanelComponent = {
        id: 'task_tagging',
        name: 'Tagging',
        icon: 'tag',
    };

    public static SETTINGS_TASK_PUSH_DATE: IPanelComponent = {
        id: 'task_push',
        name: 'Send Push',
        icon: 'date_range',
    };

    public static SETTINGS_PROJECT_TAGGING: IPanelComponent = {
        id: 'plan_tagging',
        name: 'Tagging',
        icon: 'tag',
    };

    public static SETTINGS_ADVANCED: IPanelComponent = {
        id: 'settings_advanced',
        name: 'Advanced',
        icon: 'cogs',
    };
    public static SETTINGS_COLLECTION: IPanelComponent = {
        id: 'settings_collection',
        name: 'Choose Collection',
        icon: 'collection',
    };

    public static SETTINGS_FOLDER: IPanelComponent = {
        id: 'settings_folder',
        name: 'Choose Folder',
        icon: 'folder',
    };

    public static PARTICIPANTS: IPanelComponent = {
        id: 'participants',
        name: 'Participants',
        icon: 'people'
    };
    public static ASSIGNMENTS: IPanelComponent = {
        id: 'assignments',
        name: 'Subtasks',
        icon: 'subtask'
    };
    public static STATISTICS: IPanelComponent = {
        id: 'statistics',
        name: 'Reporting',
        icon: 'chart'
    };

    public static STATUS: IPanelComponent = {
        id: 'status',
        name: 'Status',
        icon: 'date_range'
    };

    public static STATUS_DATES: IPanelComponent = {
        id: 'status_dates',
        name: 'Dates',
        icon: 'date_range'
    };

    public static STATUS_STATE: IPanelComponent = {
        id: 'status_state',
        name: 'Status',
        icon: 'task'
    };

    public static SCHEDULED_TASK_NAME: IPanelComponent = {
        id: 'scheduled_task_name',
        name: 'Item Name',
        icon: 'title',
    };

    public static SCHEDULED_TASK_URL: IPanelComponent = {
        id: 'scheduled_task_url',
        name: 'Item Url',
        icon: 'title',
    };

    public static SCHEDULED_TASK_LAUNCH_DATE: IPanelComponent = {
        id: 'scheduled_task_launch_date',
        name: 'Launch Date',
        icon: 'date_range',
    };

    public static EMAIL_CAMPAIGN_NAME: IPanelComponent = {
        id: 'email_campaign_name',
        name: 'Campaign name',
        icon: 'title',
    };

    public static EMAIL_ITEM_NAME: IPanelComponent = {
        id: 'email_item_name',
        name: 'Email name',
        icon: 'title',
    };

    public static EMAIL_ITEM_LAUNCH_DATE: IPanelComponent = {
        id: 'email_item_launch_date',
        name: 'Launch Date',
        icon: 'date_range',
    };

    public static EMAIL_ITEM_COPY: IPanelComponent = {
        id: 'email_item_copy',
        name: 'Email Copy',
        icon: 'copy',
    };

    public static EMAIL_ITEM_DEFAULT: IPanelComponent = {
        id: 'email_item_default',
        name: 'Make Default',
        icon: 'task',
    };

    public static EMAIL_ITEM_BOARD: IPanelComponent = {
        id: 'email_item_board',
        name: 'Email board',
        icon: 'dashboard',
    };

    public static EMAIL_PARTICIPANTS: IPanelComponent = {
        id: 'email_participants',
        name: 'Participants',
        icon: 'people'
    };

    public static EMAIL_SEND_GROUPS: IPanelComponent = {
        id: 'email_send_group',
        name: 'Send Group',
        icon: 'email-send-group'
    };




    public static VIDEO_CAMPAIGN_NAME: IPanelComponent = {
        id: 'video_campaign_name',
        name: 'Media Campaign name',
        icon: 'title',
    };

    public static VIDEO_ITEM_NAME: IPanelComponent = {
        id: 'video_item_name',
        name: 'Player name',
        icon: 'title',
    };

    public static VIDEO_ITEM_COPY: IPanelComponent = {
        id: 'video_item_',
        name: 'Player Copy',
        icon: 'copy',
    };

    public static VIDEO_ITEM_BOARD: IPanelComponent = {
        id: 'video_item_board',
        name: 'Video board',
        icon: 'dashboard',
    };

    public static VIDEO_PARTICIPANTS: IPanelComponent = {
        id: 'video_participants',
        name: 'Participants',
        icon: 'people'
    };




    public static ME: IPanelComponent = {
        id: 'me',
        name: 'Me',
        icon: 'person'
    };
    public static DESCRIPTION: IPanelComponent = {
        id: 'description',
        name: 'Description',
        icon: 'web'
    };
    public static PUBLISH: IPanelComponent = {
        id: 'publish',
        name: 'Publish',
        icon: 'publish_p'
    };
    public static INFO: IPanelComponent = {
        id: 'info',
        name: 'Info',
        icon: 'info_outline'
    };
    public static CONTEST: IPanelComponent = {
        id: 'contest',
        name: 'Contest',
        icon: 'contest'
    };
    public static EMPTY: IPanelComponent = {
        id: 'empty',
        name: '',
        icon: ''
    };
}
