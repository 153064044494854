const facebook = new URL( './facebook_square.svg', import.meta.url);
const vimeo = new URL('./vimeo.svg', import.meta.url);
const joinme = new URL('./joinme.svg', import.meta.url);
const youtube = new URL('./youtube.svg', import.meta.url);
const tiktok = new URL('./tiktok.svg', import.meta.url);
const zoom = new URL('./zoom_rectangle.svg', import.meta.url);
const survey = new URL('./surveymonkey.svg', import.meta.url);
const player = new URL('./player.svg', import.meta.url);

export {
    facebook,
    vimeo,
    joinme,
    player,
    youtube,
    tiktok,
    zoom,
    survey,
};
