import { IUIAttachmentsContent } from "./attachments-block/attachments-block";
import { UIImage } from "./image-block/image/ui-image";

export enum UIBlockType {
    IMAGE = 'image',
    VIDEO = 'video',
    TEXT = 'text',
    ATTACHMENTS = 'attachments',
    EMBED = 'embed'
}

export const UI_BLOCKS: {
    type: UIBlockType,
    name: string
}[] = [
        { type: UIBlockType.TEXT, name: 'Text Block' },
        { type: UIBlockType.IMAGE, name: 'Image Block' },
        // { type: UIBlockType.VIDEO, name: 'Video Block' },
        // { type: UIBlockType.ATTACHMENTS, name: 'Attachments Block' },
    ]

export const UI_BLOCK_PREFIX = {
    IMAGE: 'img',
    TEXT: 'txt',
    ATTACHMENTS: 'att',
    VIDEO: 'vid',
    EMBED: 'emb',
}

export enum UIBlockAction {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete'
}

export type UIBlockContent = string | UIImage | IUIAttachmentsContent;