<alert [service]="[serviceAlertClass.PROJECT_LIST]"></alert>

<mgc-nav-list>
    <ng-container *ngIf="state===states.LOADING">
        <mgc-list-item class="message-item">
            <mat-spinner color="primary" [diameter]="20" [strokeWidth]="3"
                style="display: inline-block; vertical-align: middle;"></mat-spinner>
        </mgc-list-item>
    </ng-container>

    <ng-container *ngIf="state===states.DONE">

        <!-- <ng-container *ngIf="standaloneList">
            <mgc-list-item project draggable>
                <div class="prefix"></div>
                <div class="content">
                    <create-item-inline [focus]="newItemFocus" itemType="project" [umbrella]="umbrella"
                        (createRequest)="inlineCreate($event, true)"></create-item-inline>
                </div>
            </mgc-list-item>
            <mat-progress-bar *ngIf="createState===states.PROCESSING" mode="indeterminate" [style.height.px]="2">
            </mat-progress-bar>

            <div class="arrow-box top help-box dnsa-content elevate"
                *ngIf="dnsaOption && !dnsaOption.checked && !dnsaOption.closed">
                <do-not-show-me-again-inline [data]="dnsaOptionItem" (close)="onCloseDNSA($event)">
                </do-not-show-me-again-inline>
            </div>
        </ng-container> -->

        <ng-container *ngIf="!standaloneList && projects.length === 0 && projectsStarting.length === 0">
            <mgc-list-item class="message-item">
                <ng-container [ngSwitch]="true">
                    <span *ngSwitchCase="umbrella.isCopy" class="name"> Find Plans to COPY in the
                        <a routerLink="/library" target="_blank" title="Plan Templates"> RallySite Plan Store</a>
                    </span>
                    <span *ngSwitchCase="umbrella.isFollow" class="name"> Find Plans to FOLLOW in the
                        <a routerLink="/library" target="_blank" title="Plan Templates"> RallySite Plan Store</a>
                    </span>
                    <span *ngSwitchDefault class="name"> This collection has no plans.</span>
                </ng-container>
            </mgc-list-item>
        </ng-container>

        <ng-container *ngIf="state===states.DONE || ( projects.length > 0 && projectsStarting.length > 0 )">
            <div cdkDropList (cdkDropListDropped)="drop($event)" [@list]="projects.length">
                <ng-container *ngFor="let project of projectsStarting" cdkDrag>
                    <mgc-list-item project draggable [style.opacity]=".6">
                        <div class="prefix"></div>
                        <div class="content" [attr.title]="project.Name">
                            <span [innerHTML]="project.Name"> </span>
                        </div>
                        <div class="suffix">
                            <span class="copy-label">
                                <span [innerHTML]="project._status || 'starting ...'"></span>
                                <mgc-loader *ngIf="!project._status" [diameter]="12" [strokeWidth]="1"></mgc-loader>
                            </span>
                        </div>
                    </mgc-list-item>
                </ng-container>

                <ng-container
                    *ngFor="let project of projects | sortProjects:umbrella:projects.length; trackBy: trackByFn">
                    <board-project [project]="project" [currentItem]="currentItem" [umbrella]="umbrella"
                        (deleteRequest)="removeProject($event)">
                    </board-project>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

</mgc-nav-list>
