
import { take, finalize } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPopupView } from '../popup-view.interface';
import { ShouldNotMatch } from './should-not-match';
import { UserService, User } from '@rallysite/user';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements IPopupView, OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    @Output() close: EventEmitter<any> = new EventEmitter<any>();
    data: any;
    processing = false;

    user: User;
    submitAvailable = true;

    /** FORM */
    form: FormGroup;

    submitted = false;

    bootstrapForm() {

        this.user = this.data.user;

        this.form = this.formBuilder.group({
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required]],
        }, {
            validator: ShouldNotMatch('FirstName', this.user.Email.substring(0, this.user.Email.indexOf('@')))
        });
    }
    submit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return false;
        }

        return true;
    }
    /** end of FORM */

    onSubmit(): void {
        if (!this.submit() || this.processing) {
            return;
        }

        this.processing = true;
        this.userService.update(Object.assign(this.data.user, this.form.value)).pipe(
            finalize(() => this.processing = false),
            take(1)).subscribe(result => {
                if (result) {
                    this.close.emit('done');
                }
            });
    }

    ngOnInit() {
        this.bootstrapForm();
    }
}
