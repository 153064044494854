import { NgModule } from '@angular/core'
import { GalleryService } from './services/gallery.service'
import { GalleryComponent } from './gallery/gallery.component'
import { ViewerComponent } from './viewer/viewer.component'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { GalleryGridComponent } from './gallery-grid/gallery-grid.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileDetailsModule } from './file-details/file-details.module';
import { GalleryThumbnailComponent } from './gallery-thumbnail/gallery-thumbnail.component';

import { SafePipesModule } from '@rallysite/global-pipes';
import { ViewerOverlayService } from './viewer/viewer-overlay.service';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        SafePipesModule,
        FileDetailsModule,
        OverlayModule
    ],
    declarations: [
        GalleryComponent,
        GalleryThumbnailComponent,
        GalleryGridComponent,
        ViewerComponent,
    ],
    providers: [
        GalleryService,
        ViewerOverlayService,
    ],
    exports: [
        GalleryComponent,
        GalleryThumbnailComponent,
        GalleryGridComponent,
        ViewerComponent
    ]
})
export class GalleryModule {
}
