export enum EmailBlockType {
  INTRO = 'intro-block',
  CONTENT = 'content-block',
  REPLIES = 'replies-block',
  FOOTER = 'footer-block',
  TEXT = 'text-block',
  IMAGE = 'image-block',
}

export interface IEmailBlockPredefined {
  type: EmailBlockType;
  name?: string;
}
export interface IEmailBlock extends IEmailBlockPredefined {
  id: string;
}

export const EMAIL_BLOCKS: IEmailBlockPredefined[] = [
  { type: EmailBlockType.TEXT, name: 'Text Block' },
  { type: EmailBlockType.IMAGE, name: 'Image Block' },
  { type: EmailBlockType.REPLIES, name: 'Replies Block' },
];

export const DAILY_EMAIL_BLOCKS: IEmailBlockPredefined[] = [
  { type: EmailBlockType.INTRO, name: 'Intro Block' },
  { type: EmailBlockType.CONTENT, name: 'Content Block' },
  { type: EmailBlockType.REPLIES, name: 'Replies Block' },
  { type: EmailBlockType.FOOTER, name: 'Footer Block' },
  { type: EmailBlockType.TEXT, name: 'Text Block' },
  { type: EmailBlockType.IMAGE, name: 'Image Block' },
];

export const EMAIL_BLOCKS_PREFIX = {
  INTRO: 'intro',
  CONTENT: 'content',
  REPLIES: 'replies',
  FOOTER: 'footer',
  TEXT: 'text',
  IMAGE: 'image',
};
