
import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Umbrella } from './umbrella';

import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { UmbrellaService } from './umbrella.service';
import { Account } from '@board-accounts/account.model';


@Component({
  selector: 'umbrella-modal',
  templateUrl: './umbrella-modal.html',
})
export class UmbrellaModal implements OnInit, OnDestroy {
  private alive: boolean = true;
  state: number;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS

  constructor(
    public umbrellaService: UmbrellaService,
    public dialogRef: MatDialogRef<UmbrellaModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      umbrella: Umbrella,
      account: Account,
      edit: boolean
    }) {
  }

  get states() {
    return UmbrellaService.STATES;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.umbrellaService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (this.state === this.states.DONE) {
          this.dialogRef.close(this.umbrellaService.umbrella);
        } else if (this.state === this.states.UNAUTHORIZED) {
          this.dialogRef.close('unauthorized');
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
