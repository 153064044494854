import { MatDialogConfig } from "@angular/material/dialog";

import { IFile, File } from "./attachments/file-model";

import { IAccount } from "@board-accounts/account.model";
import { Task } from "@board/items/task";
import { NoteReaction } from "@reactions/reaction.model";
import { ReactionTypes } from "@reactions/reactions.enum";
import { ScheduledTask } from "@board/items/scheduled-task/scheduled-task";

export interface INote {
    Id?: string;
    TaskId: string;
    AccountId: string;
    Status: string;
    IsPinned: boolean;
    Text: string;
    Wysiwyg: string;
    Settings: {
        doNotRender: boolean,
        imageOrder: Array<string>,
        ownerAccountId?: string
    };
    EmbeddableText: string;
    CreateDate: string;
    UpdateDate: string;

    _account: IAccount;
    _attachments?: Array<IFile>;
    _comments: number;
    _readLater: boolean;

    _reactions: NoteReaction[];

    _showComments: boolean;
    _hasNewComments: boolean;

    isFake(): boolean;
}

export interface ITargetNote {
    Id?: string;
    TaskId: string;
    ProjectId: string;
    GroupId: string;
    AccountId: string;
    _umbrellaId: string;

    _showComments: boolean
}

export class Note implements INote {
    protected _Id: string;
    public get Id(): string {
        return this._Id;
    }
    public set Id(value: string) {
        this._Id = value;
    }
    isFake() {
        return false;
    }

    TaskId: string;
    AccountId: string;
    Status: string;
    IsPinned: boolean;
    Text: string = '';
    Wysiwyg: string = null;
    Settings: {
        doNotRender: boolean,
        imageOrder: Array<string>
        ownerAccountId?: string
    };
    EmbeddableText: string;
    CreateDate: string;
    UpdateDate: string;

    _account: IAccount;
    _attachments: Array<IFile> = [];
    _comments: number;
    _readLater: boolean = false;

    __reactions: NoteReaction[];
    _showComments: boolean = true;
    _hasNewComments: boolean = false;

    _focused: boolean = false;
    _state: string = '';

    constructor(data: any) {
        this.bootstrap(data);
    }

    private bootstrap(data) {
        for (let prop in data) {
            if (prop.toLowerCase() === 'settings' && data[prop]) {
                this.Settings = {
                    doNotRender: data[prop]['doNotRender'],
                    imageOrder: data[prop]['imageOrder'],
                }
            } else if (prop.toLowerCase() === '_attachments' && data[prop]) {
                this._attachments = [];
                for (let i in data[prop]) {
                    this._attachments.push(new File(data[prop][i]));
                }
            } else {
                this[prop] = data[prop];
            }
        }

        this._comments || (this._comments = 0)
        this._readLater || (this._readLater = false)
        this.Settings || (this.Settings = {
            doNotRender: false,
            imageOrder: null
        })
        this._reactions || (this._reactions = [])
    }


    set _reactions(data: NoteReaction[]) {
        this.__reactions = [];
        for (let prop in data) {
            this.__reactions.push(new NoteReaction(data[prop]))
        }
    }
    get _reactions(): NoteReaction[] {
        return this.__reactions;
    }

    addReaction(reaction: any) {
        if (reaction instanceof NoteReaction) {
            this.__reactions.push(reaction);
        } else {
            this.__reactions.push(new NoteReaction(reaction));
        }
        return this;
    }
    updateReaction(reaction: any) {
        this.__reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                r.Type = reaction.Type;
                r.UpdateDate = reaction.UpdateDate;
            }
        });
    }
    deleteReaction(reaction: any) {
        this.__reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                this.__reactions.splice(i, 1);
            }
        });
    }
    findReaction(accountId: string, anonymousId: string = null) {
        return this.__reactions.find((reaction) => {
            return (accountId && reaction.AccountId === accountId) ||
                (anonymousId && reaction.AnonymousId === anonymousId);
        });
    }

    groupedReaction(slice: number = null) {
        slice || (slice = Object.keys(ReactionTypes).length);

        let grouped = this.__reactions.reduce((rv, x) => {
            (rv[x['Type']] = rv[x['Type']] || []).push(x);
            return rv;
        }, {});

        let mapped = Object.keys(grouped).map(function (type) {
            return {
                type: type,
                value: grouped[type].length
            };
        });

        return mapped.sort((a, b) => b.value - a.value).slice(0, slice);
    };




    __update(data) {
        this.bootstrap(data);
    }

    settingsOptions(task: Task | ScheduledTask, edit: boolean = false, smallView: boolean = false): MatDialogConfig {
        edit = !!edit;

        let config: MatDialogConfig = {
            data: {
                title: (edit ? 'Edit ' : 'New ') + 'Post',
                note: this,
                task: task,
                edit: edit
            }
        }
        if (smallView) {
            config.position = {
                top: '10px'
            };
        }

        return config;;
    }

    toDb() {
        return {
            Id: this.Id,
            TaskId: this.TaskId,
            AccountId: this.AccountId,
            Status: this.Status,
            IsPinned: this.IsPinned,
            Text: this.Text,
            Wysiwyg: this.Wysiwyg,
            Settings: this.Settings,
            EmbeddableText: this.EmbeddableText,
            UpdateDate: this.UpdateDate
        }
    }
}

export class TargetNote implements ITargetNote {
    Id: string;
    TaskId: string;
    ProjectId: string;
    GroupId: string;
    AccountId: string;
    _umbrellaId: string;

    _showComments: boolean = false;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}
