
import { Component, OnInit, OnDestroy, Input, HostBinding, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Note } from '../notes/note';
import { CommentService, ICommentServiceOptions } from './comment.service';
import { CommentModal } from './modal/comment-modal';
import { Comment } from './comment.model';
import { PermissionService, BoardViewService, CurrentResourceService } from '@board/_services';
import { FileService } from '@panel-components/notes/attachments';
import { filter, takeWhile, take } from 'rxjs/operators';
import { AccountService } from '@board-accounts/index';
import { CommentReaction, ReactionsOverlayService, ReactionsService, ReactionTypes } from '@reactions/index';
import { ReactionsModal } from '@reactions/modals/reactions-modal';
import { SnapshotTask } from '@board/items/scheduled-task/snapshot-task/snapshot-task';
import { ITaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';
import { IComment } from '.';


@Component({
  selector: 'mgc-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {

  @Input() comment: Comment;
  @Input() commentParent: Comment = null;
  @Input() note: Note;
  @Input() taskv2BlocksMetadata: ITaskV2BlocksMetadata;

  @Output() onCommentRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.mgc-comment') commentClass = true;
  @HostBinding('class.remove') get removeClass() {
    return this.comment && this.comment._state === 'remove';
  }
  @HostBinding('class.new') get newClass() {
    return this.comment && this.comment._state === 'new';
  }

  private alive: boolean = true;
  allowCreate: boolean = true;
  allowUpdate: boolean = true;
  pushingNotification: boolean = false;

  commentLink: string;

  noteLink: string;

  currentReaction: CommentReaction;
  reactionsData: { type: string, value: number }[] = [];
  reactionClass: string;

  constructor(
    private dialog: MatDialog,
    private commentService: CommentService,
    private fileService: FileService,
    private permissionsSettings: PermissionService,
    private boardView: BoardViewService,
    private accountService: AccountService,
    private reactionsOverlay: ReactionsOverlayService,
    private reactionsService: ReactionsService,
    private currentResource: CurrentResourceService,
  ) {
  }


  repliesLabel: string;
  repliesToggle(event: MouseEvent, show: boolean = null) {
    if (typeof show === 'boolean') {
      this.comment._showReplies = show;
    } else {
      this.comment._showReplies = !this.comment._showReplies
    }

    this.adjustLabel();
    if (event) {
      event.stopPropagation();
    }
  }

  private adjustLabel() {
    // if (this.comment._showReplies) {
    //   this.repliesLabel = 'Hide ';
    // } else {
    //   this.repliesLabel = 'Show ';
    // }

    this.repliesLabel = '';
    const len = this.comment._replies;
    this.repliesLabel += `${len} ${len > 1 ? 'replies' : 'reply'}`;
  }

  get account() {
    return this.accountService.currentAccount;
  }

  openReactions(event) {
    if (this.isSnapshotTask) {
      return;
    }
    this.reactionsOverlay.open(event.currentTarget, this.currentReaction).afterClosed()
      .subscribe((reactionType: ReactionTypes) => {
        this.currentReaction || (this.currentReaction = new CommentReaction({
          AccountId: this.account.Id,
          CommentId: this.comment.Id,
        }));

        if (this.currentReaction.Type && this.currentReaction.Type.toLowerCase() === ReactionTypes[reactionType].toLowerCase()) {
          this.renderClass(null);
          this.reactionsService.deleteReaction(this.comment, this.currentReaction).subscribe(data => {
            this.render(null);
          });

        } else {
          this.currentReaction.Type = ReactionTypes[reactionType];
          this.renderClass(this.currentReaction);
          this.reactionsService.react(this.comment, this.currentReaction).subscribe(data => {
            this.render(this.comment.findReaction(this.account.Id));
          });
        }

      });
  }

  openReactionsData($event) {
    if (this.isSnapshotTask) {
      return;
    }
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    let config: MatDialogConfig = {
      maxWidth: '500px',
      minWidth: '350px',

      data: {
        item: this.comment,
        groupedReactions: this.comment.groupedReaction()
      }
    }

    this.dialog.open(ReactionsModal, config)
      .afterClosed().pipe(take(1)).subscribe(result => {
        //
      });
  }



  get isSmallView() {
    return this.boardView.smallView();
  }

  openCommentModal() {
    if (this.isSnapshotTask) {
      return;
    }
    this.dialog.open(CommentModal, this.comment.settingsOptions({
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      commentParent: this.commentParent,
      edit: true
    }));
  }

  openReplyModal() {
    this.comment._showReplies = true;

    let commentReply = new Comment(<IComment>{
      Text: '',
      ReplyToCommentId: this.comment.Id,
      NoteId: this.note ? this.note.Id : null,
      TaskV2Id: this.taskv2BlocksMetadata ? this.taskv2BlocksMetadata.taskv2Id : null,
      AccountId: this.accountService.currentAccount.Id
    });

    this.dialog.open(CommentModal, commentReply.settingsOptions({
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      commentParent: this.comment,
    }))
      .afterClosed().pipe(take(1)).subscribe(result => {
        this.adjustLabel();
      });
  }

  replyRemoved() {
    this.adjustLabel();
  }

  removeComment() {
    if (!this.permissionsSettings.allowsAction('delete.comment', this.comment, { message: 'Not allowed to delete this comment' })) {
      return false;
    }
    const options: ICommentServiceOptions = {
      comment: this.comment,
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      commentParent: this.commentParent
    }
    this.commentService.remove(options)
      .subscribe((removed: boolean) => {
        if (removed) {
          this.onCommentRemoved.emit(true);
        }
      })
  }

  logSuccess($event) {
    this.commentService.alertService.snackSuccess('Comment link copied to clipboard');
  }

  logError($event) {
    this.commentService.alertService.snackWarning('Error: Not able to copy comment link', { horizontalPosition: 'center' });
  }

  render(reaction: CommentReaction) {
    this.currentReaction = reaction;
    this.reactionsData = this.comment.groupedReaction(3);
  }

  renderClass(reaction: CommentReaction) {
    let classes = [
      'reaction-icon',
    ];
    if (reaction) {
      classes.push('reacted');
      classes.push(reaction.Type);
    } else {
      classes.push('no-reaction');
    }
    this.reactionClass = classes.join(' ');
  }

  isSnapshotTask: boolean = false;
  ngOnInit() {
    this.isSnapshotTask = this.currentResource.currentItem instanceof SnapshotTask;

    this.commentLink = `${window.location.protocol}//${window.location.host}/board;n=${this.comment.NoteId};c=1`;

    let reaction = this.comment.findReaction(this.account.Id);
    this.renderClass(reaction);
    this.render(reaction);

    this.allowCreate = this.permissionsSettings.allowsAction('create.comment', this.note, { silent: true });
    this.allowUpdate = this.permissionsSettings.allowsAction('update.comment', this.comment, { silent: true });

    this.fileService.getFiles$(this.comment).pipe(
      filter(files => !!files),
      takeWhile(() => this.alive))
      .subscribe(files => {
        this.comment._attachments = files;
      });

    this.repliesToggle(null, true);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
