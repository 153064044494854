import { OverlayRef } from "@angular/cdk/overlay";
import { Subject, Observable } from "rxjs";

export class ShareOverlayRef {

  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<any>();

  constructor(
    private overlayRef: OverlayRef) {
  }

  close(data: any = null): ShareOverlayRef {
    this._beforeClose.next();
    this._beforeClose.complete();
    this.overlayRef.detachBackdrop();

    this.overlayRef.dispose();

    this._afterClosed.next(data)
    this._afterClosed.complete();
    return this;
  }

  afterClosed(): Observable<any> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }
}