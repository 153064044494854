import { IAccount } from "@board-accounts/account.model";
import { ITaskV2BlocksMetadata } from "@items/task-v2/blocks/task-v2-blocks-metadata";
import { INote } from "@panel-components/notes";
import { IFile, File } from "@panel-components/notes/attachments/file-model";
import { CommentReaction } from "@reactions/reaction.model";
import { ReactionTypes } from "@reactions/reactions.enum";


export interface ICommentSettingsOptions {
    note?: INote,
    taskv2BlocksMetadata?: ITaskV2BlocksMetadata,
    commentParent?: Comment,
    edit?: boolean
}

export interface IComment {
    Id?: string;
    NoteId?: string;
    TaskV2Id?: string;
    AccountId: string;
    AnonymousId: string;
    ReplyToCommentId: string;
    Status: string;
    Text: string;
    Settings: {
        doNotRender: boolean,
        imageOrder: Array<string>,
        ownerAccountId?: string
    };
    CreateDate: string;
    UpdateDate: string;

    _account: IAccount;
    _attachments?: Array<IFile>;

    __reactions: CommentReaction[];
    _replies: number;

    toDb();
}

export class Comment implements IComment {
    Id: string;
    NoteId: string = null;
    TaskV2Id: string = null;
    AccountId: string;
    AnonymousId: string;
    ReplyToCommentId: string;
    Status: string;
    Text: string = '';
    Settings: {
        doNotRender: boolean,
        imageOrder: Array<string>,
        ownerAccountId?: string
    };
    CreateDate: string;
    UpdateDate: string;
    _account: IAccount;
    _attachments: Array<IFile> = [];
    _state: string;

    __reactions: CommentReaction[];
    _replies: number;
    _showReplies: boolean = false;

    constructor(data: any) {
        this.bootstrap(data);
    }

    private bootstrap(data) {
        for (let prop in data) {
            if (prop.toLowerCase() === 'settings' && data[prop]) {
                this.Settings = {
                    doNotRender: data[prop]['doNotRender'],
                    imageOrder: data[prop]['imageOrder'],
                }
            } else if (prop.toLowerCase() === '_attachments' && data[prop]) {
                this._attachments = [];
                for (let i in data[prop]) {
                    this._attachments.push(new File(data[prop][i]));
                }
            } else {
                this[prop] = data[prop];
            }
        }

        this._replies || (this._replies = 0)

        this.Settings || (this.Settings = {
            doNotRender: false,
            imageOrder: null
        })
        this._reactions || (this._reactions = [])
    }


    set _reactions(data: CommentReaction[]) {
        this.__reactions = [];
        for (let prop in data) {
            this.__reactions.push(new CommentReaction(data[prop]))
        }
    }
    get _reactions(): CommentReaction[] {
        return this.__reactions;
    }

    addReaction(reaction: any) {
        if (reaction instanceof CommentReaction) {
            this.__reactions.push(reaction);
        } else {
            this.__reactions.push(new CommentReaction(reaction));
        }
        return this;
    }
    updateReaction(reaction: any) {
        this.__reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                r.Type = reaction.Type;
                r.UpdateDate = reaction.UpdateDate;
            }
        });
    }
    deleteReaction(reaction: any) {
        this.__reactions.forEach((r, i) => {
            if (r.Id === reaction.Id) {
                this.__reactions.splice(i, 1);
            }
        });
    }
    findReaction(accountId: string, anonymousId: string = null) {
        return this.__reactions.find((reaction) => {
            return (accountId && reaction.AccountId === accountId) ||
                (anonymousId && reaction.AnonymousId === anonymousId);
        });
    }

    groupedReaction(slice: number = null) {
        slice || (slice = Object.keys(ReactionTypes).length);

        let grouped = this.__reactions.reduce((rv, x) => {
            (rv[x['Type']] = rv[x['Type']] || []).push(x);
            return rv;
        }, {});

        let mapped = Object.keys(grouped).map(function (type) {
            return {
                type: type,
                value: grouped[type].length
            };
        });

        return mapped.sort((a, b) => b.value - a.value).slice(0, slice);
    };

    __update(data) {
        this.bootstrap(data);
    }

    get isReply() {
        return typeof this.ReplyToCommentId === 'string';
    }

    settingsOptions(options: ICommentSettingsOptions) {
        const { note, commentParent, edit, taskv2BlocksMetadata } = options;
        return {
            data: {
                title: (edit ? 'Edit ' : 'New ') + (this.isReply ? 'Reply' : 'Comment'),
                comment: this,
                commentParent: commentParent,
                note: note,
                taskv2BlocksMetadata: taskv2BlocksMetadata,
                edit: !!edit
            }
        }
    }

    toDb() {
        return {
            Id: this.Id,
            NoteId: this.NoteId,
            TaskV2Id: this.TaskV2Id,
            AccountId: this.AccountId,
            AnonymousId: this.AnonymousId,
            ReplyToCommentId: this.ReplyToCommentId,
            Status: this.Status,
            Text: this.Text,
            Settings: this.Settings,
            UpdateDate: this.UpdateDate
        }
    }
}