
export interface ISurveyOverlayAction {
  id: 'cancel' | 'save' | 'quit' | 'continue';
  label: string
}

export interface ISurveyOverlayRefResponseData {
  action?: ISurveyOverlayAction;
}

export const SURVEY_SAVE: ISurveyOverlayAction = {
  id: 'save',
  label: 'Save & Quit'
}
export const SURVEY_QUIT: ISurveyOverlayAction = {
  id: 'quit',
  label: 'Quit'
}
export const SURVEY_CONTINUE: ISurveyOverlayAction = {
  id: 'continue',
  label: 'Continue Assessment'
}
export const SURVEY_CANCEL: ISurveyOverlayAction = {
  id: 'cancel',
  label: 'Cancel'
}