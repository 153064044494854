export interface ITasksTab {
  id: string;
  name: string;
  default?: boolean;
}

export const TASKS_TABS = {
  TASKS: <ITasksTab>{
    id: 'tasks',
    name: 'Live',
    default: true
  },

  SCHEDULED_TASKS: <ITasksTab>{
    id: 'scheduled-tasks',
    name: 'Pending',
  },
}

