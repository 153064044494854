import { Subject } from 'rxjs';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IDoNotShowAgainOption } from '../do-not-show-again.options.class';
import { UserService } from '../../user.service';
import { takeWhile } from 'rxjs/operators';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';

@Component({
    selector: 'do-not-show-again-option',
    templateUrl: `./dnsa-option.component.html`,
    styleUrl: './dnsa-option.component.scss',
    styles: [`
        :host{
            display: block;
            padding-top: 20px;
        }
        .dnsa-option-actions{
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    `]
})
export class DoNotShowAgainOptionComponent implements OnInit {
    @Input() option: IDoNotShowAgainOption;
    @Output() close: EventEmitter<string> = new EventEmitter<string>();
    close$: Subject<any> = new Subject<any>();

    private alive = true;
    state: number;

    serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

    checked = false;
    constructor(
        private userService: UserService
    ) {
    }

    get states() {
        return UserService.STATES;
    }

    onClose() {
        if (this.checked) {
            this.userService.dnsaOption(this.option);
        }
        this.closeFn('close');
    }

    private closeFn(result: string) {
        this.close$.next(result);
        this.close.next(result);
    }

    ngOnInit() {

        // this.userService.resetProcessingState$().pipe(
        //     takeWhile(() => this.alive))
        //     .subscribe(state => {
        //         this.state = state;
        //         if (this.state === this.states.DONE) {
        //             this.option.checked = false;
        //             this.closeFn('close');
        //         } else if (this.state === this.states.UNAUTHORIZED) {
        //             this.closeFn('unauthorized');
        //         }
        //     });
    }

    ngOnDestroy() {
        this.alive = false;
    }

}
