import { Component, OnInit } from '@angular/core';
import { ThemingService } from './theming.service';
import { IColor } from './themes/color.interface';

@Component({
    selector: 'theming',
    templateUrl: './theming.component.html',
    styles: [`
        :host{
            display: block;
            background: white;
            margin: 20px;
            padding: 10px;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index:1000000;
            border: 1px solid #3e3e3e;
        }
    `]
})
export class ThemingComponent implements OnInit {

    primaryColor = '#1D7A18';
    primaryColorPalette: IColor[] = [];

    navColor = '#530F53';
    navColorPalette: IColor[] = [];

    constructor(
        private themingService: ThemingService
    ) {
    }

    ngOnInit() {
    }
}  