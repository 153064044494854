import { ProjectMetadata } from './project-metadata.class';
import { BaseItem, IItemStatus, ITEM_STATUSES } from '../base-item.model';

import {
    IPanelComponent,
    PanelComponents
} from '@board/panels/_components/panel-components';
import { Notification } from '@board/_components/notification';
import { IUmbrella } from '@board/items/umbrella';
import { Participant } from '@board/panels/_components/participants';
import { IPanels } from '@rallysite/global-interfaces';
import { Contest } from '@panel-components/contest/contest.class';
import { IProjectSettings, ProjectSettings } from './settings/project-settings.class';
import { IThemeOptions } from '@libraries/theming';
import { IProjectLabels } from './project-labels';

export interface ITargetProject {
    Id?: string;
    AccountId: string;
    _umbrellaId: string;
}
export class ProjectAttributes {
    Id: string = null;
    AccountId: string = null;
    AssigneeId: string = null;
    BrandId: string = null;
    Name: string = null;
    UName: string = null;
    Status: string = null;
    Description: string = null;
    ShortDescription: string = null;
    Settings: IProjectSettings = null;
    Metadata: any = null;
    AllowJoin: boolean = null;
    AllowCopy: boolean = null;
    AllowComments: boolean = null;
    IsPublic: boolean = null;
    InUse: boolean = null;
    GTPushNotifications: boolean = null;
    GTOrganisationId: number = null;

    StartDate: Date = null;
    EndDate: Date = null;
    WorkHours: number = null;
    Cost: number = null;

    UpdateDate: string = null;
}

export class BaseProject extends BaseItem {

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.SETTINGS_RESTRICTED_USERS,

        PanelComponents.SETTINGS_PROJECT_NAME,
        PanelComponents.SETTINGS_PROJECT_THEME,
        // PanelComponents.SETTINGS_ADVANCED,
        // PanelComponents.DASHBOARD,
        PanelComponents.PARTICIPANTS,
        PanelComponents.STATISTICS,
        PanelComponents.PUBLISH,
        PanelComponents.SETTINGS_PROJECT_E_MESSAGE,
        PanelComponents.SETTINGS_PROJECT_TAGGING
    ];
    readonly _type: string = 'project';

    get sidePanels(): Array<IPanelComponent> {
        if (!!this['_contest']) {
            const p = [PanelComponents.CONTEST];
            for (const panel of this.SIDE_PANELS) {
                p.push(panel);
            }
            return p;
        }

        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.EMPTY;
    }

    prevAttributes: ProjectAttributes;
    attributes: ProjectAttributes;

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get AssigneeId(): string {
        return this.attributes.AssigneeId;
    }
    set AssigneeId(value: string) {
        this.attributes.AssigneeId = value;
    }
    get BrandId(): string {
        return this.attributes.BrandId;
    }
    set BrandId(value: string) {
        this.attributes.BrandId = value;
    }
    get UName(): string {
        return this.attributes.UName;
    }
    set UName(value: string) {
        this.attributes.UName = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Metadata(): any {
        return this.attributes.Metadata;
    }
    set Metadata(value: any) {
        this.attributes.Metadata = new ProjectMetadata(value || []);
    }
    get Settings(): IProjectSettings {
        return this.attributes.Settings;
    }
    set Settings(value: IProjectSettings) {
        this.attributes.Settings = new ProjectSettings(value || []);
    }
    get Description(): string {
        return this.attributes.Description;
    }
    set Description(value: string) {
        this.attributes.Description = value;
    }
    get ShortDescription(): string {
        return this.attributes.ShortDescription;
    }
    set ShortDescription(value: string) {
        this.attributes.ShortDescription = value;
    }
    get AllowJoin(): boolean { return this.attributes.AllowJoin; }
    set AllowJoin(value: boolean) { this.attributes.AllowJoin = value; }

    get AllowCopy(): boolean { return this.attributes.AllowCopy; }
    set AllowCopy(value: boolean) { this.attributes.AllowCopy = value; }

    get AllowComments(): boolean { return this.attributes.AllowComments; }
    set AllowComments(value: boolean) { this.attributes.AllowComments = value; }

    get IsPublic(): boolean { return this.attributes.IsPublic; }
    set IsPublic(value: boolean) { this.attributes.IsPublic = value; }

    get InUse(): boolean { return this.attributes.InUse; }
    set InUse(value: boolean) { this.attributes.InUse = value; }

    get GTPushNotifications(): boolean { return this.attributes.GTPushNotifications; }
    set GTPushNotifications(value: boolean) { this.attributes.GTPushNotifications = value; }

    get GTOrganisationId(): number { return this.attributes.GTOrganisationId; }
    set GTOrganisationId(value: number) { console.log({value});
    this.attributes.GTOrganisationId = value; }

    set StartDate(value: Date) {
        this.attributes.StartDate = this.__date(value);
    }
    get StartDate(): Date {
        return this.attributes.StartDate;
    }
    set EndDate(value: Date) {
        this.attributes.EndDate = this.__date(value);
    }
    get EndDate(): Date {
        return this.attributes.EndDate;
    }
    set WorkHours(value: number) {
        this.attributes.WorkHours = value;
    }
    get WorkHours(): number {
        return this.attributes.WorkHours;
    }
    set Cost(value: number) {
        this.attributes.Cost = value;
    }
    get Cost(): number {
        return this.attributes.Cost;
    }

    o_Status: IItemStatus;
    set Status(value: string) {
        this.attributes.Status = value;

        for (const iStatus of ITEM_STATUSES) {
            if (iStatus.id === value) {
                this.o_Status = iStatus;
                break;
            }
        }
    }
    get Status(): string {
        return this.attributes.Status;
    }

    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

    set theme(data: IThemeOptions) {
        this.Settings.theme = data;
    }
    get theme(): IThemeOptions {
        return this.Settings.theme;
    }

}

export class Project extends BaseProject implements IPanels {

    get _id() { return this.UName || this.Id; }

    _participant: Participant;
    _resmap: string;
    _permap: string;
    _statistics: any;

    _notifications = 0;
    notifications: Array<string> = [];
    _flaggedNotes = 0;
    _refreshFlaggedNotes = false;
    _isNew: any = null;

    _state: string;

    _umbrellaId: string;
    _umbrellaUId: string;

    _pubData: any;

    _scrollY = 0;

    __contest: Contest;
    get _contest(): Contest {
        return this.__contest;
    }
    set _contest(data) {
        this.__contest = new Contest(data);
    }
    get contest(): Contest {
        return this.__contest;
    }

    _isBrpPlan = false;
    get isBRP() {
        return !!this._isBrpPlan;
    }

    avatarTimestamp = 1;
    get avatar(): string {
        return this.Settings.avatar;
    }


    get labels(): IProjectLabels {
        return this.Settings.labels;
    }

    constructor(data: any, umbrellaId: string, umbrellaUId: string) {
        super();

        this._umbrellaId = umbrellaId;
        this._umbrellaUId = umbrellaUId;

        this.attributes = new ProjectAttributes();
        this.prevAttributes = new ProjectAttributes();

        for (const prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        this.bootstrapDefaults(data);
    }

    bootstrapDefaults(data) {
        ['Settings', 'Metadata'].forEach(prop => {
            if (!data[prop]) {
                this[prop] = null;
            }
        });

        if (typeof this.AllowJoin !== 'boolean') {
            this.AllowJoin = true;
        }
    }

    isStandalone() {
        return this._umbrellaId === 'myprojects';
    }
    isNotConfirmed() {
        return this._participant.Confirmation === 'pending';
    }

    increaseNotificationCounter(notification: Notification) {
        if (notification.isProject()) {
            if (this.notifications.indexOf(notification.id) < 0) {
                this._notifications++;
                this.notifications.push(notification.id);
                return true;
            }
            return false;
        }
        this._notifications++;
        return true;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }

    readUINotifications() {
        this.decreaseNotificationCounter(this.notifications.length);
        this.notifications = [];
    }

    settingsOptions({ umbrella, edit, panel }: {
        umbrella: IUmbrella,
        edit?: boolean,
        panel?: IPanelComponent
    }) {
        return {
            maxWidth: edit ? '1000px' : '800px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + 'Plan',
                project: this,
                umbrella: umbrella,
                edit: !!edit,
                panel: panel
            }
        };
    }

    get _defaultPanel() {
        return this.Settings.defaultPanel;
    }

    sideDefaultPanel(allowFn: Function) {
        if (this.contest) {
            return PanelComponents.CONTEST;
        }

        if (allowFn(this.Settings.sideDefaultPanel.id)) {
            return this.Settings.sideDefaultPanel;
        }
        return PanelComponents.SETTINGS;
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            AccountId: this.AccountId,
            Name: this.Name,
            // UName: this.UName,

            Status: this.Status,
            StartDate: this.formatDbDate(this.StartDate),
            EndDate: this.formatDbDate(this.EndDate),
            WorkHours: this.WorkHours,
            Cost: this.Cost,

            // Description: this.Description,
            // ShortDescription: this.ShortDescription,
            Settings: this.Settings.toDb(),
            Metadata: this.Metadata,
            AllowJoin: this.AllowJoin,
            // AllowCopy: this.AllowCopy,
            // IsPublic: this.IsPublic,
            UpdateDate: this.UpdateDate,
            _umbrellaId: this._umbrellaId,

            InUse: this.InUse,
            GTPushNotifications: this.GTPushNotifications,
            GTOrganisationId: this.GTOrganisationId
        });
    }

    toDbLight() {
        return this.sanitize({
            Id: this.Id,
            AccountId: this.AccountId,
            Name: this.Name,
            Status: this.Status,
            UpdateDate: this.UpdateDate,
            _umbrellaId: this._umbrellaId
        });
    }

    toDbPublish() {
        return this.sanitize({
            Id: this.Id,
            Name: this.Name,
            UName: this.UName,
            BrandId: this.BrandId,
            Description: this.Description,
            ShortDescription: this.ShortDescription,
            AllowJoin: this.AllowJoin,
            AllowCopy: this.AllowCopy,
            AllowComments: this.AllowComments,
            IsPublic: this.IsPublic,
            Settings: this.Settings.toDbPublish(),
            UpdateDate: this.UpdateDate
        }, ['BrandId']);
    }

}

export class TargetProject implements ITargetProject {
    Id: string;
    AccountId: string;
    _umbrellaId: string;

    constructor(data: any) {
        for (const prop in data) {
            this[prop] = data[prop];
        }
    }
}
