import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
  env: string;
  storeDomain: string;
  domain: string;
  endpoint: string;
  protocol: string;
  socketUrl: string;
  socketPort: string;
  clientId: string;
  clientSecret: string;
  grantType: string;
  svgPath?: string;
  cdnProject: string;
  cdnEmailFiles: string;
  cdnImages: string;
  playerPath: string;
  beaconEndpoint: string;
  pageViewTrackEndpoint: string;
  datahubUrl: string;
  trendingBrand: string;
  dailyPrayerPlan: string;
  reminderEmailCampaignId: string;
  //
  app_name: string;
  contact: {
    email: string,
    url: string
  };
  support: {
    email: string,
    tutorialUrl: string
  };
  ga_id?: string;
  google_site_verification: string;
  legal: {
    terms?: string
  };
  platformTags: string[];
  logo: {
    md: string
  };
}

export const APP_DI_CONFIG: AppConfig = {
  env: environment.env,
  storeDomain: environment.storeDomain,
  domain: environment.domain,
  endpoint: environment.protocol + '://' + environment.endpoint,
  protocol: environment.protocol,
  socketUrl: environment['socketUrl'],
  socketPort: environment.socketPort,
  clientId: environment.client.id,
  clientSecret: environment.client.secret,
  grantType: environment.client.grantType,
  svgPath: 'assets/svg',
  cdnProject: environment.cdnProject,
  cdnEmailFiles: environment.cdnEmailFiles,
  cdnImages: environment.cdnImages,
  playerPath: environment['playerPath'] || '',
  //
  app_name: environment.app_name,
  contact: environment.contact,
  support: environment.support,
  ga_id: environment.ga_id,
  google_site_verification: environment['google_site_verification'],
  legal: environment.legal,
  platformTags: <string[]>environment['platformTags'] || [],
  pageViewTrackEndpoint: environment['pageViewTrackEndpoint'] || '',
  beaconEndpoint: environment['beaconEndpoint'] || '',
  datahubUrl: environment['datahubUrl'] || '',
  trendingBrand: environment['trendingBrand'] || 'trending',
  dailyPrayerPlan: environment['dailyPrayerPlan'] || '',
  reminderEmailCampaignId: environment['reminderEmailCampaignId'] || '',
  logo: environment['logo'] || {
    md: '/assets/images/logo_md.png'
  }
};

@NgModule({
  providers: [{
    provide: APP_CONFIG, useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
