<form [formGroup]="form">
  <mgc-form-field label="Survey Link" [invalid]="submitted && f.surveyLink.errors">
    <div class="field-wrapper">
      <input tabindex="-1" formControlName="surveyLink">
      <span class="placeholder" *ngIf="!f.surveyLink.value">Survey Link</span>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.surveyLink.errors">
      <div *ngIf="f.surveyLink.errors.required">This field is required</div>
    </ng-container>
  </mgc-form-field>
  <p class="field-description mb-4">
    Sample: <code>{{ sampleUrl }}</code>
  </p>

  <mgc-form-field label="Days Since Last P. Level Assessment">
    <div class="field-wrapper">
      <input tabindex="-1" formControlName="daysSinceLastPlevelAssessment">
      <span class="placeholder" *ngIf="!this.form.controls.daysSinceLastPlevelAssessment">Days Since Last P. Level Assessment</span>
    </div>
  </mgc-form-field>

  <mat-slide-toggle [style.margin-top.px]="15"
                    class="mgc-toggle mb-4"
                    formControlName="closeModalOnComplete"
                    [checked]="f.closeModalOnComplete.value"> Completion of survey terminates modal screen
  </mat-slide-toggle>
</form>
