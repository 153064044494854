import { takeWhile, take } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, HostBinding, SimpleChange, ViewChild, Output, EventEmitter } from '@angular/core';
import { IServiceStates, ServiceStates } from '@rallysite/global-interfaces';
import { Account } from '@board-accounts/account.model';
import { AccountService } from '@board-accounts/account.service';
import { AuthService } from '@rallysite/auth-service';
import { IUploaderOptions, UploaderComponent } from '@libraries/uploader';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AccountConfirmNoteModal } from '@board-accounts/_components/modals';

@Component({
  selector: 'account-data',
  templateUrl: './account-data.component.html',
  styleUrls: ['./account-data.component.scss'],
})
export class AccountDataComponent implements OnInit, OnDestroy {

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {
  }

  get states(): IServiceStates {
    return ServiceStates.STATES;
  }

  // convenience getter for easy access to form fields
  get f() { return this.accountDataForm.controls; }
  @HostBinding('class.mgc-account-data') accountDataClass = true;

  @ViewChild(UploaderComponent) rsUploader: UploaderComponent;

  @Input('account') currentAccount: Account;
  @Input('logoOnly') logoOnly = false;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();


  private alive = true;
  state: number;
  accounts: Account[];

  avatarImageOptions: IUploaderOptions;

  _i_accountEmail: string;
  _i_accountLabel: string;

  /** FORM */
  accountDataForm: FormGroup;

  submitted = false;

  avatarUploading = false;
  previewAvatarFile: any;

  public dirty() {
    return this._i_accountEmail !== this.currentAccount.Email ||
      this._i_accountLabel !== this.accountDataForm.get('Name').value;
  }

  bootstrapForm() {
    this.accountDataForm = this.formBuilder.group({
      Email: [this.currentAccount.Email || '', [Validators.required, Validators.email]],
      Name: [this.currentAccount.Name || '', [Validators.required]],
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.accountDataForm.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  onSave(): Observable<any> {
    if (!this.submit() || this.state === this.states.PROCESSING) {
      return of(null);
    }

    this.currentAccount = Object.assign(this.currentAccount, this.accountDataForm.value);
    if (this.currentAccount.Id) {
      if (this.dirty()) {
        return this.accountService.update(this.currentAccount);
      }
      return of(null);
    }

    return this.accountService.create(this.currentAccount);
  }

  onCancel(): void {
    this.close.emit('cancel');
  }

  accountChanged(event: MatSelectChange = null) {
    this.setAvatarImageOptions();
    this.bootstrapForm();

    this._i_accountEmail = this.currentAccount.Email;
    this._i_accountLabel = this.currentAccount.Name;
  }

  helper(account) {
    if (!account.Confirmed) {
      this.dialog.open(AccountConfirmNoteModal, {
        data: {
          account: account
        }
      });
      return;
    }
  }

  /** AVATAR methods */
  private setAvatarImageOptions() {
    this.avatarImageOptions = <IUploaderOptions>{
      autoUpload: true,
      filename: 'avatar',
      tokenGetter: this.authService.tokenGetter,
      endPoint: this.accountService.avatarsEndPoint,
      payload: {
        AccountId: this.currentAccount.Id,
      },
    };
  }
  onBeforeUploadAvatar(item: any) {
    this.avatarUploading = true;
    this.previewAvatarFile = item.file;
  }
  onAvatarSuccess(data: { item: any; response: any }) {
    this.avatarUploading = false;

    this.currentAccount.UpdateDate = data.response.UpdateDate;
    this.currentAccount._timestamp = Math.random();
    this.currentAccount.Thumbnail = data.response.Thumbnail;
  }
  onAvatarError(data: { item: any; response: any }) {
    this.avatarUploading = false;
  }
  /** end AVATAR Methods */

  ngOnInit() {
    this.accountService.getAccounts().pipe(take(1))
      .subscribe(accounts => this.accounts = accounts);
  }

  ngAfterViewInit() {
  }

  ngOnChanges(change: SimpleChange) {
    if (change['currentAccount'] && change['currentAccount'].currentValue) {
      this.accountChanged();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
