<div class="algotag-search">
    <input #searchInput class="search-box-input" tabIndex="1" placeholder="Start typing ..." aria-label="Start typing "
        [matAutocomplete]="auto" [formControl]="searchAlgoTagCtrl">
    <mgc-loader *ngIf="saving"></mgc-loader>
</div>

<mat-autocomplete class="search-autocomplete" #auto="matAutocomplete" >

    <mat-option *ngIf="processing">
        <mgc-loader></mgc-loader>
    </mat-option>

    <mat-option *ngIf="term.length >= 3 && !processing && !filteredTags.length" [value]="term" >
        <span> {{ foundTags.length?'No more results ...':'No results found' }}
            <button class="new-algotag" *ngIf="!foundTags.length" (click)="saveNewTag($event)">Save/Add as New Tag</button>
        </span>
    </mat-option>

    <ng-container *ngIf="!processing && filteredTags.length">
        <ng-container *ngFor="let tag of filteredTags">
            <mat-option [attr.title]="tag.Name" [value]="term" (click)="add(tag)">
                <span class="name" [innerHTML]="tag.Name"></span>
            </mat-option>
        </ng-container>
    </ng-container>

</mat-autocomplete>

<mat-chip-listbox class="algotag-chip-list">
    <mat-chip *ngFor="let tag of selectedTags">
        <span [innerHTML]="tag.Name"></span>
        <mat-icon svgIcon="board-mat:close" (click)="remove(tag)"></mat-icon>
    </mat-chip>
</mat-chip-listbox>
