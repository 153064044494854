<form [formGroup]="form" *ngIf="!preview">
    <div class="media-block-drag flx-clm">
        <mgc-icon-button class="click-move" (click)="onMove(-1)" icon="board-mat:keyboard_arrow_up"></mgc-icon-button>
        <mgc-icon-button class="drag-handle" icon="board-mat:unfold-more" cdkDragHandle></mgc-icon-button>
        <mgc-icon-button class="click-move" (click)="onMove(1)" icon="board-mat:keyboard_arrow_down"></mgc-icon-button>
    </div>

    <div class="media-block-content pad-hrz-xs">
        <div class="media-block-item-header">
            <input class="form-input" placeholder="Add YouTube / Vimeo / TikTok / Instagram Link here..." formControlName="link"
                (input)="onLinkChange($event)" [class.invalid-feedback]="submitted && f.link.errors" />
        </div>
        <div class="media-block-item-body">
            <ng-container *ngTemplateOutlet="videoBodyTpl"></ng-container>
        </div>
        <div class="media-block-item-footer">
            <mgc-icon-button class="remove" (click)="onRemove(block)" icon="board-mat:trash"></mgc-icon-button>
        </div>
    </div>
</form>

<p #embedWrapper class="embed-container" [class.tall]="isTall" *ngIf="preview && block.Content"
    [innerHTML]="embed | safe: 'html'"></p>

<ng-template #videoBodyTpl>
    <div #embedWrapper class="t2-video-preview embed-container" [class.tall]="isTall" *ngIf="f.link.value"
        [innerHTML]="embed | safe: 'html'">
    </div>

    <div class="t2-video-preview" *ngIf="!f.link.value">
        <p>no preview</p>
    </div>

</ng-template>
