import { Component, OnInit, Input } from '@angular/core';

import { Project, } from '@board/items/project/project';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IPublishSection } from './section.interface';
import { ThemingService } from '@libraries/theming/theming.service';
import { Theme } from '@libraries/theming';

const SHOWABLE_OPTIONS = [
  // 'showFotameco',
  'showActionButton',
  'skipTopTask',
  'autoselectOnMobile',
  'navigationToggle',
  'navigationStartsClosed',
  'allowAnonymous',
  'showAuthModalOnEntry',
  'showAuthModalOnItemClick',
  'allowGetEmbedCode',
  'goToNextStep'
];

@Component({
  selector: 'publish-fotameco',
  templateUrl: './fotameco-section.component.html',
  styles: [`
    .input-number{
      max-width: 60px;
      background: rgba(0,0,0, .05);
      padding: 1px 6px;
      border-radius: 4px;
      margin-left: -3px;
    }
    .input-number+span{
      margin-left: 10px;
    }
  `]
})
export class PublishFotamecoComponent implements IPublishSection, OnInit {
  @Input() project: Project;

  fotameco: any;
  theme: Theme;

  constructor(
    private formBuilder: FormBuilder,
    private themingService: ThemingService
  ) {
  }

  /** FORM */
  publishForm: FormGroup;
  bootstrapForm() {
    this.publishForm = this.formBuilder.group(Object.assign({
      fotamecoEmbedId: [this.fotameco.embedId],
      showAuthModalOnEntryAfter: [this.project.Settings.publish.showAuthModalOnEntryAfter || 3],
      goToNextStepLabel: [this.project.Settings.publish.goToNextStepLabel || ''],
      goToNextStepUrl: [this.project.Settings.publish.goToNextStepUrl || ''],
      actionButtonText: [
        this.project.Settings.publish.actionButton.text
        || (this.project.AllowJoin ? 'SUBSCRIBE' : (this.project.AllowCopy ? 'COPY' : '- custom-text -'))
      ],
    },
      (() => {
        const o: any = {};
        SHOWABLE_OPTIONS.forEach(prop => {
          o[prop] = this.project.Settings.publish[prop];
        });
        return o;
      })()
    ));
  }

  // convenience getter for easy access to form fields
  get f() { return this.publishForm.controls; }
  get isDirty() {
    return this.publishForm.dirty;
  }
  /** end of FORM */

  makeFormDirty() {
    if (this.publishForm) {
      this.publishForm.markAsDirty();
    }
  }

  markFormPristine() {
    if (this.publishForm) {
      this.publishForm.markAsPristine();
    }
  }

  submitForm() {
    if (this.publishForm.invalid) {
      return false;
    }

    return true;
  }

  formValues() {
    return {
      Settings: {
        publish: Object.assign({
          actionButton: {
            text: this.publishForm.value['actionButtonText'],
          },
          fotameco: {
            embedId: this.publishForm.value['fotamecoEmbedId'],
            showAuthModalOnEntryAfter: this.publishForm.value['showAuthModalOnEntryAfter'],
          },
          goToNextStepLabel: this.publishForm.value['goToNextStepLabel'],
          goToNextStepUrl: this.publishForm.value['goToNextStepUrl'],
        },
          (() => {
            const o: any = {};
            SHOWABLE_OPTIONS.forEach(prop => {
              o[prop] = this.publishForm.value[prop];
            });
            return o;
          })())
      }
    };
  }

  requests() {
    return [];
  }

  get actionButtonStatus() {
    return this.project.AllowJoin ? 'SUBSCRIBE' : (this.project.AllowCopy ? 'COPY' : 'PRIVATE');
  }

  toggleOption(data: any) {
    this.publishForm.patchValue({ [data.option]: data.value });
    this.makeFormDirty();
  }

  ngOnInit() {
    this.fotameco = this.project.Settings.publish.fotameco;
    this.theme = this.themingService.getActiveTheme();

    this.bootstrapForm();
  }

}
