
import { takeWhile } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EmailService } from './email.service';
import { EmailComponent } from './email.component';

import { Note } from '@panel-components/notes';
import { Task } from '@items/task';
import { ScheduledTask } from '@items/scheduled-task/scheduled-task';


@Component({
  templateUrl: './email-modal.html',
  styleUrl: './email-modal.scss',
})
export class EmailModal implements OnInit, OnDestroy {
  private alive = true;
  state: number;

  isScheduledTask = false;

  constructor(
    private emailService: EmailService,
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      note: Note,
      task: Task | ScheduledTask
    }
  ) {
    this.isScheduledTask = data.task instanceof ScheduledTask;
  }

  get states() {
    return EmailService.STATES;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.emailService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        if (this.state === this.states.DONE) {
          this.dialogRef.close();
        } else if (this.state === this.states.UNAUTHORIZED) {
          this.dialogRef.close('unauthorized');
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
