import {
  Component, Output, EventEmitter, OnInit
} from '@angular/core';

export enum PROJECT_STATISTICS_TABS {
  TAB1 = 'Subscribers',
  TAB2 = 'Copies'
}

@Component({
  selector: 'mgc-project-statistics-tabs',
  template: `
    <div class="mgc-tabs">
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="tab1"
          (click)="tabClick(tabs.TAB1)" [innerHTML]="tabs.TAB1"></span>
      <span class="mgc-tab" [style.width.px]="tabWidth" [class.active]="tab2"
          (click)="tabClick(tabs.TAB2)" [innerHTML]="tabs.TAB2"></span>
      <div class="mgc-link-bar" [style.width.px]="tabWidth" [style.left.px]="leftPosition"></div>
    </div>
  `, styles: [`
    :host{
      display: block;
      height: 40px;
      margin-bottom: 5px;
    }
  `]

})
export class ProjectStatisticsTabsComponent implements OnInit {
  @Output() tabChange: EventEmitter<PROJECT_STATISTICS_TABS> = new EventEmitter<null>();

  constructor(
  ) {
  }

  get tabs() {
    return PROJECT_STATISTICS_TABS;
  }

  /** TABS */
  tabWidth = 100;
  tab: PROJECT_STATISTICS_TABS = PROJECT_STATISTICS_TABS.TAB1;
  get tab1() {
    return this.tab === PROJECT_STATISTICS_TABS.TAB1;
  }
  get tab2() {
    return this.tab === PROJECT_STATISTICS_TABS.TAB2;
  }
  get leftPosition(): number {
    return this.tab1 ? 0 : this.tabWidth;
  }
  /** end TABS */

  tabClick(tab: PROJECT_STATISTICS_TABS) {
    this.tabChange.emit(this.tab = tab);
  }

  ngOnInit(){
    this.tabChange.emit(this.tab);
  }

}
