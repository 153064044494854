
import { takeWhile, take, finalize, map } from 'rxjs/operators';
import { Component, OnInit, Input, HostBinding, Output, EventEmitter, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Comment } from './comment.model';
import { CommentService, ICommentServiceOptions } from './comment.service';

import { list } from '@animations';
import { IServiceStates } from '@rallysite/global-interfaces';
import { IAlertsClass, ServiceAlertClass } from '@rallysite/components/alert';
import { CurrentResourceService } from '@board/_services';

import { Note } from "@panel-components/notes/note";
import { timer } from 'rxjs';
import { CommentComponent } from './comment.component';
import { SnapshotTask } from '@board/items/scheduled-task/snapshot-task/snapshot-task';
import { TaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';

@Component({
  selector: 'mgc-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  animations: [list]
})
export class CommentListComponent implements OnInit {
  @HostBinding('class.mgc-comment-list') commentListClass = true;

  /** it will be either note or taskv2 */
  @Input() note: Note = null;
  @Input() taskv2BlocksMetadata: TaskV2BlocksMetadata = null;
  @Input() commentParent: Comment = null;
  @Output() onCommentRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onReplyRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(CommentComponent) commentsCompoents: QueryList<CommentComponent>

  commentsReady: boolean = false;

  private alive: boolean = true;
  comments: Comment[] = [];
  states: IServiceStates;
  state: number;
  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  protected offset: number = 0;
  protected limit: number = 10;
  readonly initialLoad: number = 5;

  constructor(
    private commentService: CommentService,
    private currentResource: CurrentResourceService,
    public dialog: MatDialog,
  ) {
    this.states = CommentService.STATES;
  }

  trackByFn(index: number, comment: Comment): string {
    return comment.Id;
  }

  commentRemoved() {
    if (this.commentParent) {
      this.onReplyRemoved.emit();
    } else {
      this.onCommentRemoved.emit();
    }
  }

  loadingMore: boolean = false;
  moreToLoad: boolean = false;
  loadMore() {
    if (this.loadingMore) {
      return;
    }

    this.loadingMore = true;
    this.offset = this.comments.length;

    this.loadComments(this.offset);
  }


  reload() {
    this.options = {
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      comment: this.commentParent,
      limit: this.initialLoad,
      offset: 0,
      force: true
    };
    return this.commentService.clean(this.options).getComments(this.options).pipe(
      map(comments => {
        if (!comments) {
          return false;
        }

        timer(100).subscribe(() => {
          this.commentsCompoents.forEach((c: CommentComponent) => {
            c.repliesToggle(null, true);
          });
        })

        this.render(comments);
        return true;
      }));
  }

  options: ICommentServiceOptions;
  private loadComments(offset: number = 0) {
    const currentItem = this.currentResource.currentItem
    if (currentItem instanceof SnapshotTask) {
      this.commentsReady = true;
      this.loadingMore = false;

      this.options = {
        snapshot: true,
        note: this.note,
        taskv2BlocksMetadata: this.taskv2BlocksMetadata,
        comment: this.commentParent,
      };
      if (this.commentParent) {
        const replies = currentItem.replies(this.note.Id, this.commentParent.Id)
        this.commentService.setTotal(this.options, replies)
        this.render(replies);
      } else {
        const comments = currentItem.comments(this.note.Id)
        this.commentService.setTotal(this.options, comments.length)
        this.render(comments);
      }
      return
    }

    this.options = {
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      comment: this.commentParent,
      limit: (offset === 0 ? this.initialLoad || this.limit : this.limit),
      offset: offset
    };

    this.state = this.states.LOADING;
    this.commentService.getComments(this.options).pipe(
      take(1),
      finalize(() => {
        this.state = this.states.DONE;
        this.commentsReady = true;
        this.loadingMore = false;
      }))
      .subscribe(comments => {
        this.render(comments);
      })
  }

  private render(comments: Comment[]) {
    if (!comments) {
      return
    }

    this.comments = comments;
    this.moreToLoad = this.comments.length < this.commentService.total(this.options);
  }


  ngOnInit() {
    this.commentService.comments$({
      note: this.note,
      taskv2BlocksMetadata: this.taskv2BlocksMetadata,
      comment: this.commentParent
    }).pipe(
      takeWhile(() => this.alive))
      .subscribe(comments => {
        this.render(comments);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['note'] && changes['note'].currentValue) {
      this.commentsReady = false;
      this.loadComments();
    }

    if (changes['taskv2BlocksMetadata'] && changes['taskv2BlocksMetadata'].currentValue) {
      this.commentsReady = false;
      this.loadComments();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

@Component({
  selector: 'mgc-comment-reply-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  animations: [list]
})
export class CommentReplyListComponent extends CommentListComponent {
  protected offset: number = 0;
  protected limit: number = 5;
  readonly initialLoad: number = 1;
}
