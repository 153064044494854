import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AlgoTagListComponent } from "@board-components/algotags/algotag-list.component";
import { AlgoTagType } from "@board-components/algotags/algotag/algotag.types";
import { finalize } from "rxjs/operators";
import { Project } from "../project";

@Component({
  selector: 'project-tagging',
  templateUrl: './project-tagging.component.html',
})
export class ProjectTaggingComponent implements OnInit {

  @ViewChild(AlgoTagListComponent) algoTagList: AlgoTagListComponent;
  @Input() project: Project;
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();

  processing: boolean = false;
  key: string;

  types: AlgoTagType[] = [AlgoTagType.AGE, AlgoTagType.GENDER, AlgoTagType.PLEVEL]

  constructor(
  ) { }

  onCancel(): void {
    this.onClose.emit('cancel');
  }

  onSave(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;
    this.algoTagList.save()
      .pipe(
        finalize(() => this.processing = false)
      )
      .subscribe(response => {
        if (response) {
          this.onClose.emit('update');
        }
      })
  }

  ngOnInit() {
    this.key = `p_${this.project.Id}`;
  }

}
