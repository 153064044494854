import { BaseItem } from '@items/base-item.model';
import { IPanelComponent, PanelComponents } from '@panel-components';

export interface IVideoItemSettingsPlaylistAsset {
    id: string;
    name: string;
    type: string;
    vendor: string;
    thumbnail: string;
    poster: string;
    link: string;
    skip: string | number;
    toDb(): any;
}
export interface IVideoItemSettingsPlaylist {
    autoplay: boolean;
    playnext: boolean;
    replay: boolean;
    assets: Array<IVideoItemSettingsPlaylistAsset | VideoItemSettingsPlaylistAsset>;
    toDb(): any;
}
export interface IVideoItemSettings {
    defaultPanel: IPanelComponent;
    sideDefaultPanel: IPanelComponent;

    tags: Array<any>;
    playlist: IVideoItemSettingsPlaylist;

    toDb(): void;
    toJSON(): void;
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export class VideoItemSettingsPlaylistAsset extends BaseItem implements IVideoItemSettingsPlaylistAsset {
    id: any;
    name: string;
    type: string;
    vendor: string;
    thumbnail: string;
    poster: string;
    link: string;
    skip: number | string;

    expanded = false;

    uid() {
        return String(this.id).replace('new_', '');
    }

    isVideo() {
        return this.type === 'video';
    }
    isNew() {
        return String(this.id).startsWith('new_');
    }
    constructor(data) {
        super();
        data = {
            id: 'new_' + uuidv4(),
            ...data
        };
        for (const key in data) {
            this[key] = data[key];
        }
    }
    toDb() {
        return this.sanitize({
            id: this.id,
            name: this.name,
            type: this.type,
            vendor: this.vendor,
            thumbnail: this.thumbnail,
            poster: this.poster,
            link: this.link,
            skip: this.skip,
        });
    }
}
export class VideoItemSettingsPlaylist extends BaseItem implements IVideoItemSettingsPlaylist {
    autoplay: false;
    playnext: false;
    replay: false;
    _assets: IVideoItemSettingsPlaylistAsset[];
    get assets(): IVideoItemSettingsPlaylistAsset[] {
        return this._assets;
    }
    set assets(data: IVideoItemSettingsPlaylistAsset[]) {
        this._assets = [];
        if (!data || data.length === 0) {
            this.addAsset({ type: 'video' });
        }
        (data || []).forEach(this.addAsset.bind(this));
    }
    addAsset(data) {
        this._assets.push(new VideoItemSettingsPlaylistAsset(data));
    }
    removeAsset(data) {
        // ...
    }
    constructor(data) {
        super();
        data = {
            assets: [],
            ...data
        };
        for (const key in data) {
            this[key] = data[key];
        }
    }
    toJSON() {
        return this.toDb();
    }
    toDb() {
        return this.sanitize({
            autoplay: this.autoplay,
            playnext: this.playnext,
            replay: this.replay,
            assets: this.assets.map(a => a.toDb())
        });
    }
}

export class VideoItemSettings implements IVideoItemSettings {
    private _defaultPanel: IPanelComponent;
    private _sideDefaultPanel: IPanelComponent;
    private _playlist: IVideoItemSettingsPlaylist;
    get playlist(): IVideoItemSettingsPlaylist {
        return this._playlist;
    }
    set playlist(data: IVideoItemSettingsPlaylist) {
        this._playlist = new VideoItemSettingsPlaylist(data || {});
    }

    get defaultPanel(): IPanelComponent {
        return this._defaultPanel || PanelComponents.VIDEO_ITEM_BOARD;
    }
    tags: [];

    constructor(data?: any) {
        data = {
            playlist: {},
            ...data
        };
        for (const prop in data) {
            this[prop] = data[prop];
        }
    }


    set defaultPanel(value) {
        const id = typeof (value) === 'object' ? value.id : value;
        this._defaultPanel = this.findPanel(id);
    }

    get sideDefaultPanel(): IPanelComponent {
        return this._sideDefaultPanel || PanelComponents.SETTINGS;
    }
    set sideDefaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        if (id === PanelComponents.STATUS.id) {
            id = PanelComponents.SETTINGS.id;
        }
        this._sideDefaultPanel = this.findPanel(id);
    }

    private findPanel(id: string) {
        let dPanel: IPanelComponent = null;
        for (const panel in PanelComponents) {
            if (id === PanelComponents[panel].id) {
                dPanel = PanelComponents[panel];
                break;
            }
        }
        return dPanel;
    }

    toDb() {
        return {
            defaultPanel: this.defaultPanel.id,
            sideDefaultPanel: this.sideDefaultPanel.id,

            tags: this.tags,
            playlist: this.playlist.toDb()
        };
    }

    toJSON() {
        return this.toDb();
    }

}
