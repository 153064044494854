import { WelcomePopupComponent } from './welcome/welcome.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { IPopupView } from './popup-view.interface';
import { Type } from '@angular/core';
import { PhonePopupComponent } from './phone/phone-popup.component';

export enum PopupType {
  WELCOME = 'welcome',
  USER_PROFILE = 'user-profile',
  ACCOUNT_PROFILE = 'account-profile',
  PHONE = 'phone',
}

export enum PopupMethod {
  CLOSE = 'close',
  SUBMIT = 'submit',
}

export interface IPopupCompSettings {
  title: string;
  component: Type<IPopupView>;
  showWithDelay?: number; // of seconds,
  steps?: any;
  // when defined, buttons will be automatically be moved
  // as first element in steps
  buttons?: Array<{
    text: string,
    method: string
  }>;
}

export const PopupTypeComponent: {
  [PopupType.WELCOME]: IPopupCompSettings,
  [PopupType.USER_PROFILE]: IPopupCompSettings,
  [PopupType.ACCOUNT_PROFILE]: IPopupCompSettings,
  [PopupType.PHONE]: IPopupCompSettings,
} = {
  [PopupType.WELCOME]: {
    title: 'Welcome!',
    component: WelcomePopupComponent,
    showWithDelay: 1000,
    buttons: [{
      text: 'Close',
      method: PopupMethod.CLOSE,
    }]
  },
  [PopupType.USER_PROFILE]: {
    title: 'Your name',
    component: UserProfileComponent,
    showWithDelay: 5000,
    buttons: [{
      text: 'Update',
      method: PopupMethod.SUBMIT,
    }]
  },
  [PopupType.ACCOUNT_PROFILE]: {
    title: 'Profile image',
    component: AccountProfileComponent,
    showWithDelay: 5000,
    buttons: [{
      text: 'Save',
      method: PopupMethod.SUBMIT,
    }, {
      text: 'Close',
      method: PopupMethod.CLOSE,
    }]
  },

  [PopupType.PHONE]: {
    title: 'Your Text Messages',
    component: PhonePopupComponent,
    showWithDelay: 1000,
    steps: [
      {/** 1st step */
        buttons: [{
          text: 'Submit',
          method: PopupMethod.SUBMIT,
        }],
      },
      {/** 2nd step */
        buttons: [{
          text: 'Close',
          method: PopupMethod.CLOSE,
        }],
      }]
    // steps: [
    //   {/** 1st step */
    //     buttons: [{
    //       text: 'Next',
    //       method: PopupMethod.SUBMIT,
    //     }],
    //   },
    //   {/** 2nd step */
    //     buttons: [{
    //       text: 'Submit',
    //       method: PopupMethod.SUBMIT,
    //     }]
    //   },
    //   {/** 3rd step */
    //     buttons: [{
    //       text: 'Close',
    //       method: PopupMethod.CLOSE,
    //     }],
    //   }]

  },
};
