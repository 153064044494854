import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { ISurveyQuestion } from '@libraries/surveys/models/survey-question.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sq-options',
  template: '<ng-content></ng-content>',
})
export class SQOptionsComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.q-option-wrapper') qowClass: true;


  @Input() question: ISurveyQuestion;

  @Output() optionChange: EventEmitter<ISurveyQuestionOption | ISurveyQuestionOption[]> = new EventEmitter<ISurveyQuestionOption | ISurveyQuestionOption[]>(null);

  options: ISurveyQuestionOption[] = []
  formSubscriptions: Subscription[] = [];

  constructor() { }

  bootstrapForm() {
  }

  submit() {
    return true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    for (let i = 0; i < this.formSubscriptions.length; i++) {
      this.formSubscriptions[i].unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['question']) {
      this.options = this.question.Options;

      for (let i = 0; i < this.formSubscriptions.length; i++) {
        this.formSubscriptions[i].unsubscribe();
      }
      this.bootstrapForm();
    }
  }

}
