export interface IMultiGalleryCategory {
    id?: string | number;
    text: string;
    filename: string;
    endPoint: string;
    payload: any;
    autoUpload: boolean;
    active: boolean;
    allowPOST?: boolean;
}

export interface IMultiGalleryOptions {
    tokenGetter: () => string;
    categories: IMultiGalleryCategory[];
    multiSelect?: boolean;
}

export class MultiGalleryOptions implements IMultiGalleryOptions {
    tokenGetter: () => string;
    categories: IMultiGalleryCategory[];
    multiSelect: boolean;

    constructor(obj: IMultiGalleryOptions) {

        function use<T>(source: T, defaultValue: T): T {
            return obj && (source !== undefined) ? source : defaultValue;
        }

        let categId = 0;
        this.tokenGetter = use(obj.tokenGetter, () => null);
        this.categories = obj.categories.map(c => <IMultiGalleryCategory>{
            id: use(c.id, categId++),
            text: use(c.text, 'Default'),
            filename: use(c.filename, 'file_name'),
            endPoint: use(c.endPoint, ''),
            payload: use(c.payload, null),
            autoUpload: use(c.autoUpload, true),
            active: use(c.active, false),
            allowPOST: use(c.allowPOST, true),
        })
        this.multiSelect = use(obj.multiSelect, true);
    }

}