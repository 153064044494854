<mgc-loader *ngIf="loading"></mgc-loader>

<ng-container *ngIf="ready">
    <div class="media-header mgc-vcard pad-hrz-xs pad-sep-xxs clearfix">
        <div style="width: 100%; display: flex;">
            <div style="margin-right: auto;">
                <mgc-stroked-button
                  class='add-element-or-block'
                  style="margin-right: 15px;"
                  [matMenuTriggerFor]="mgcMenu.menu"
                >
                  Add Element/Block
                </mgc-stroked-button>

                <mgc-menu #mgcMenu>
                    <mgc-menu-item *ngFor="let block of predefinedBlocks" (click)="onAddBlock(block)">
                        <span [innerHTML]="'Add ' + block.name"></span>
                    </mgc-menu-item>
                </mgc-menu>

                <mgc-raised-button class='save-button' [disabled]="form.invalid" [processing]="processing" (click)="onSave()">SAVE
                </mgc-raised-button>
            </div>
            <mgc-icon-button *ngIf="initialBlocks.length!=0" style="cursor: pointer;" class="w30" icon="board-mat:close"
                (click)="onCancel()">Close
            </mgc-icon-button>
        </div>
        <alert></alert>
    </div>

    <div class="media-body mgc-panel-body mgc-scroll-area">

        <mgc-post-as (ownerAs)="postAs($event)" [accountId]="accountId"></mgc-post-as>
        <div style="padding: 1px;" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let block of f.blocks.value">
                <ng-container [ngSwitch]="block.Type">
                    <task-v2-text-block cdkDrag *ngSwitchCase="blockType.TEXT" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </task-v2-text-block>
                    <task-v2-image-block cdkDrag *ngSwitchCase="blockType.IMAGE" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </task-v2-image-block>
                    <task-v2-video-block cdkDrag *ngSwitchCase="blockType.VIDEO" [block]="block" (move)="onMove(block, $event)"
                        [task]="task" (remove)="onRemoveBlock($event)">
                    </task-v2-video-block>
                    <task-v2-survey-block cdkDrag *ngSwitchCase="blockType.SURVEY" [block]="block" (move)="onMove(block, $event)"
                        (remove)="onRemoveBlock($event)">
                    </task-v2-survey-block>
                    <task-v2-attachments-block cdkDrag *ngSwitchCase="blockType.ATTACHMENTS" [block]="block"
                        (remove)="onRemoveBlock($event)">
                    </task-v2-attachments-block>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
