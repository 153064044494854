
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiGalleryComponent } from './multi-gallery.component';

@Component({
  selector: 'multi-gallery-modal',
  template: `
    <div class="cp-modal-header">
      <h1 mat-dialog-title>Choose Images</h1>
      <button tabindex="-1" mat-icon-button mat-dialog-close>
        <mat-icon svgIcon="board-mat:close"></mat-icon>
      </button>
    </div>

    <div mat-dialog-content class="cp-dialog-content">
      <multi-gallery #multiGallery [options]="data.options"></multi-gallery>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button tabindex="2" color="primary" (click)="addToProject(multiGallery.selectedFiles)" [disabled]="multiGallery.selectedFiles.length === 0" >
        <span>Add to project</span>
      </button>
      <button mat-button (click)="onCancel()" tabindex="-1">Cancel</button>
    </div>
  `
  // templateUrl: './account-modal.html',
})
export class MultiGalleryModal implements OnInit, OnDestroy {
  private alive: boolean = true;
  state: number;

  constructor(
    public dialogRef: MatDialogRef<MultiGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addToProject(files: Array<any>) {
    this.dialogRef.close(files);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.alive = false;
  }

}
