export interface INotification {
    id: string;
    type: string;
    notifiable_id: string;
    notifiable_type: string;
    project_id: string;
    task_id: string;
    read_at: string;
    socket: string;
    data: any;
    targetPath: ITargetPath;
}

export interface ITargetPath {
    /** Id its depending of the data notification.type*/
    Id: string;
    AccountId: string
    _umbrellaId: string;
    ProjectId: string;
    GroupId?: string;
    TaskId?: string;
}

export class Notification implements INotification {

    id: string;
    type: string;
    notifiable_id: string;
    notifiable_type: string;
    project_id: string;
    task_id: string;
    read_at: string;
    socket: string;
    data: any;
    targetPath: ITargetPath;

    _inView: boolean = false;

    static readonly EVENTS = {
        COMMENT: 'App\\Notifications\\Comment',
        NOTE: 'App\\Notifications\\Note',
        TASK: 'App\\Notifications\\Task',
        PROJECT: 'App\\Notifications\\Project',
    }

    constructor(_data: any) {
        for (let prop in _data) {
            this[prop] = _data[prop];
        }
    }

    isComment() {
        return this.type.indexOf(Notification.EVENTS.COMMENT) >= 0;
    }

    isNote() {
        return this.type.indexOf(Notification.EVENTS.NOTE) >= 0;
    }

    isTask() {
        return this.type.indexOf(Notification.EVENTS.TASK) >= 0;
    }

    isProject() {
        return this.type.indexOf(Notification.EVENTS.PROJECT) >= 0;
    }

    eventType() {
        let s: Array<string> = this.type.split('\\');
        return s[0] + '\\' + s[1] + '\\' + s[2];
    }

    action() {
        switch (true) {
            case this.type.toLowerCase().indexOf('added') >= 0:
                return 'create';
            case this.type.toLowerCase().indexOf('updated') >= 0:
                return 'update';
            case this.type.toLowerCase().indexOf('deleted') >= 0:
                return 'delete';
            case this.type.toLowerCase().indexOf('copied') >= 0:
                return 'copy';
            default:
                return 'read';
        }
    }



}