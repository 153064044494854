import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { animate, style, transition, trigger } from '@angular/animations';
import { ReactionTypes, ReactionIcons } from './reactions.enum';
import { NoteReaction, CommentReaction } from './reaction.model';
import { ReactionsOverlayRef } from './reactions-overlay-ref';

@Component({
  selector: 'reactions-overlay',
  styleUrls: ['./reactions-overlay.component.scss'],
  templateUrl: './reactions-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ReactionsOverlayComponent {

  @Input() currentReaction: NoteReaction | CommentReaction = null;

  get reactions() {
    return Object.keys(ReactionTypes);
  }

  get reactionTypes() {
    return ReactionTypes;
  }
  get reactionIcons() {
    return ReactionIcons;
  }

  constructor(
    private reactionsOverlayRef: ReactionsOverlayRef,
  ) {
  }

  react(reactionType: ReactionTypes) {
    // console.log([reactionType, ReactionTypes[reactionType]]);
    this.reactionsOverlayRef.close(reactionType);
  }
}
