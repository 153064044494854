import { IBoardViewSettingsData } from "./boardview-settings.data";

export interface BoardViewSettingsOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: IBoardViewSettingsData;
}

export const DEFAULT_CONFIG: BoardViewSettingsOverlayConfig = {
  hasBackdrop: true,
  panelClass: 'board-view-settings-overlay',
  backdropClass: 'board-view-settings-backdrop'
}