import { EventFormatter } from "./event-formatter.class";

export class SocketChannel {

  private _eventFormatter: EventFormatter;
  private _subscription: any;
  private _events: any = {}

  here: Function;
  joining: Function;
  leaving: Function;

  constructor(
    private socket: any,
    private name: string,
    private options: any) {

    this._eventFormatter = new EventFormatter();

    if (this.options.namespace) {
      this._eventFormatter.namespace(this.options.namespace)
    }

    this.subscribe();
  }

  unsubscribe() {
    this.unbind()

    this.socket.emit('unsubscribe', {
      channel: this.name,
      auth: this.options.auth || {}
    })
  }
  
  listen(event, callback) {
    this.on(this._eventFormatter.format(event), callback)
    return this
  }

  on(event, callback) {
    let listener = (channel, data) => {
      if (this.name === channel) {
        callback(data)
      }
    }

    this.socket.on(event, listener)
    this.bind(event, listener)
  }

  notification(callback) {
    return this.listen('.App.Notifications.Channels.BroadcastRedisNotifications.RedisNotificationCreated', callback)
  }


  /** PRIVATE Methods */
  private subscribe() {
    this._subscription = this.socket.emit('subscribe', {
      channel: this.name,
      auth: this.options.auth || {}
    })
  }

  private bind(event, callback) {
    this._events[event] = this._events[event] || []
    this._events[event].push(callback)
  }

  private unbind() {
    Object.keys(this._events).forEach((event) => {
      this._events[event].forEach((callback) => {
        this._subscription.removeListener(event, callback)
      })

      delete this._events[event]
    })
  }
}