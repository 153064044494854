<div #galleryContainer class="galleryContainer" [@galleryImageTransition]="true">
    <div class="innerGalleryContainer">
        <div class="imagerow" [style.transform]="translateX(scrollX)">
            <ng-content></ng-content>

            <div #imageElement class="image-wrapper mgc-gallery-item" *ngFor='let img of gallery; let j = index'
                [style.max-width.px]="img[minimalQualityCategory].width"
                [style.min-width.px]="img[minimalQualityCategory].width"
                [style.height.px]="img[minimalQualityCategory].height" [style.margin-right.px]="calcImageMargin()">

                <img (click)="openImageViewer(img)" [ngStyle]="getImageStyle(img)"
                    [src]="img.src | safe:'resourceUrl'" />

                <div class="overlay" *ngIf="img.model?.isUploading">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="30"></mat-progress-spinner>
                </div>
                <div class="overlay" *ngIf="img.model?.isReadyForUpload">
                    <mat-progress-bar color="primary" mode="buffer" value="0" bufferValue="30" [style.height.px]="3"
                        [style.width.%]="40"></mat-progress-bar>
                </div>
            </div>
        </div>

        <ng-container *ngIf="gallery.length">
            <button class="pager left fab-btn" [class.inactive]="!leftArrowActive" (click)="navigate(-1)" color="basic">
                <div class="arrow-icon">
                    <svg id="keyboard_arrow_left" viewBox="1 -1 24 24">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                        <path d="M0-.5h24v24H0z" fill="none" />
                    </svg>
                </div>

            </button>
            <button class="pager right fab-btn" [class.inactive]="!rightArrowActive" (click)="navigate(1)"
                color="basic">
                <div class="arrow-icon">
                    <svg id="keyboard_arrow_right" viewBox="0 0 24 24">
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                        <path fill="none" d="M0 0h24v24H0V0z" />
                    </svg>
                </div>
            </button>
        </ng-container>

    </div>
</div>

<!-- <viewer [initialQuality]="minimalQualityCategory" [withDetails]="withDetails" [downloadable]="downloadable"></viewer> -->