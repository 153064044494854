import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ParticipantService } from '@panel-components/participants/participant.service';
import { Participant } from '@panel-components/participants/participant';
import { Project } from '@board/items/project/project';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'new-participant-data',
  templateUrl: './new-participant-data.component.html',
})
export class NewParticipantDataComponent implements OnInit, OnDestroy {

  get permissionsMap() {
    return ParticipantService.PERMISSION_MAP;
  }

  constructor(
    private participantService: ParticipantService,
    private formBuilder: FormBuilder,
  ) {
  }

  get states() {
    return ParticipantService.STATES;
  }

  // convenience getter for easy access to form fields
  get f() { return this.participantDataForm.controls; }
  @Input() participant: Participant;
  @Input() project: Project;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  private alive = true;
  state: number;
  resend: {
    show: boolean,
    message: string
  } = {
      show: false,
      message: ''
    };

  /** FORM */
  participantDataForm: FormGroup;

  submitted = false;

  bootstrapForm() {
    this.participantDataForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
      AccessType: [this.participant.AccessType, [Validators.required]],
      InviterNote: [''],
      _assign: [this.participant._assign || false]
    });
  }
  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.participantDataForm.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

  onSubmit() {
    if (!this.submit()) {
      return;
    }

    this.participant = Object.assign(this.participant, this.participantDataForm.value);
    this.participantService.create(this.participant, this.project);
  }


  resendInvitation() {
    if (!this.participant.resend.done) {
      this.participantService.resend(this.participant, this.project);
    }
  }

  onCancel(): void {
    this.close.next('cancel');
  }

  toggleAssign(matSlideToggle: MatSlideToggleChange) {
    this.participantDataForm.patchValue({ _assign: matSlideToggle.checked });
  }

  ngOnInit() {
    this.bootstrapForm();

    this.participantService.resetProcessingState$().pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
        switch (state) {
          case this.states.DONE:
            this.participant = this.participantService.participant;
            if (this.participant._error) {
              this.resend = {
                show: true,
                message: this.participant._error
              };
            } else {
              this.close.next();
            }
            break;
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
