import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MgcButtonModule, MgcDividerModule, MgcFormFieldModule, MgcLoaderModule, MgcMenuModule } from '@rallysite/mgc-components';
import { TaskV2BlocksComponent } from './blocks/task-v2-blocks.component';
import { TaskV2ImageBlockComponent } from './blocks/image-block/image-block.component';
import { TaskV2VideoBlockComponent } from './blocks/video-block/video-block.component';
import { TaskV2SurveyBlockComponent } from './blocks/survey-block/survey-block.component';
import { TaskV2EmbedBlockComponent } from './blocks/embed-block/embed-block.component';
import { TaskV2AttachmentsBlockComponent } from './blocks/attachments-block/attachments-block.component';
import { TaskV2TextBlockComponent } from './blocks/text-block/text-block.component';
import { TaskV2ImageComponent } from './blocks/image-block/image/task-v2-image.component';
import { JoditModule } from '@libraries/jodit/jodit.module';
import { SafePipesModule } from '@rallysite/global-pipes';
import { UploaderModule } from '@libraries';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TaskV2BoardComponent } from './task-v2-board.component';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';
import { DatePipesModule } from '@board/_pipes';
import { CommentsModule } from '@panel-components/comments/x_comments.module';
import { PostAsModule } from '@board-components/post-as/post-as.module';
import { AttachmentsModule } from '@panel-components/notes/attachments/x_attachments.module';
import { AlertModule } from '@rallysite/components/alert';
import { TaskV2BaseBlockComponent } from './blocks/taskv2-base-block.component';
import { AlgoTagsModule } from '@board-components/algotags/algotags.module';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MgcMenuModule,
        MgcButtonModule,
        MgcFormFieldModule,
        MgcLoaderModule,
        MgcDividerModule,
        JoditModule,
        SafePipesModule,
        UploaderModule,
        AccountAvatarModule,
        DatePipesModule,
        CommentsModule,
        PostAsModule,
        AttachmentsModule,
        AlertModule,
        AlgoTagsModule,
        DragDropModule,
        CdkDropList,
        CdkDrag
    ],
    declarations: [
        TaskV2BlocksComponent,
        TaskV2BoardComponent,
        TaskV2BaseBlockComponent,
        TaskV2ImageBlockComponent,
        TaskV2AttachmentsBlockComponent,
        TaskV2EmbedBlockComponent,
        TaskV2TextBlockComponent,
        TaskV2SurveyBlockComponent,
        TaskV2VideoBlockComponent,
        TaskV2ImageComponent
    ],
    exports: [
        TaskV2BlocksComponent,
        TaskV2BoardComponent,
        TaskV2ImageBlockComponent,
        TaskV2AttachmentsBlockComponent,
        TaskV2EmbedBlockComponent,
        TaskV2TextBlockComponent,
        TaskV2SurveyBlockComponent,
        TaskV2VideoBlockComponent,
        TaskV2ImageComponent
    ]
})
export class TaskV2Module { }
