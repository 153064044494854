import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlgoTagsService } from '../algotags.service';
import { AlgoTagAction, IAlgoTag } from './algotag.model';
import { AlgoTagType } from './algotag.types';

@Component({
  selector: 'algotag-single-select',
  templateUrl: './algotag-single-select.component.html',
  styleUrls: ['./algotag.component.scss']
})
export class AlgoTagSingleSelectComponent implements OnInit {

  @Input() selectedTags: IAlgoTag[] = [];
  @Input() category: AlgoTagType;

  @Output() removeChange: EventEmitter<IAlgoTag[]> = new EventEmitter(null);

  categoryTags: IAlgoTag[] = [];
  removedTags: IAlgoTag[] = [];

  constructor(
    private algoTagService: AlgoTagsService
  ) {
  }

  isSelected(tag: IAlgoTag) {
    return this.selectedTags.findIndex(t => t.Id == tag.Id) > -1
  }

  isNone(tag: IAlgoTag) {
    return tag.Name.toLowerCase() === 'none';
  }

  add(tag: IAlgoTag) {
    // remove the selected tag from list; not the case but just to make sure
    this.algoTagService.removeFrom(this.selectedTags, tag);
    const tags = this.selectedTags.slice().map(t => {
      t._httpAction = AlgoTagAction.DETACH
      return t;
    });
    this.selectedTags.length = 0;

    // add
    tag._httpAction = AlgoTagAction.ATTACH;
    this.algoTagService.addTo(this.selectedTags, tag);
    this.removeChange.emit(tags);
  }

  ngOnInit() {
    this.algoTagService.getPredefinedAlgoTags()
      .pipe(take(1))
      .subscribe(tags => {
        this.categoryTags = tags.filter((tag: IAlgoTag) => tag.Type === this.category)
        this.algoTagService.sort(this.categoryTags);
        // re-arange none tag; place it at the end
        const noneTag = this.categoryTags.find(this.isNone)
        const noneTagIndex = this.categoryTags.findIndex(this.isNone)
        if (noneTagIndex > -1) {
          this.categoryTags.splice(noneTagIndex, 1);
          this.categoryTags.push(noneTag);
        }
      })
  }

}
