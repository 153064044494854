import { Component, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, SimpleChanges } from "@angular/core";
import { UploaderOptions, IUploaderOptions } from "./uploader.options.model";
import { UploaderService } from "./uploader.service";
import { FileUploader } from "./file-upload";

@Component({
    selector: 'rs-uploader, [rsUploader]',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
    providers: [UploaderService],
})
export class UploaderComponent {
    @Input() options: IUploaderOptions;
    @Input() renderAs: 'button' | 'content' | 'default' = 'default';

    @Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() onProgress: EventEmitter<any> = new EventEmitter<any>();
    @Output() onError: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBeforeUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAfterAddingFile: EventEmitter<any> = new EventEmitter<any>();

    @Output() onCompleteAll: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('imageFile') imageFileInput: ElementRef;

    @HostListener('click') onClick() {
        if (this.renderAsContent) {
            this.imageFileInput.nativeElement.click();
        }
    };

    uploader: FileUploader;
    state: number;
    hasBaseDropZoneOver: boolean = false;

    get renderAsDefault(): boolean {
        return this.renderAs === 'default';
    }

    get renderAsButton(): boolean {
        return this.renderAs === 'button';
    }

    get renderAsContent(): boolean {
        return this.renderAs === 'content';
    }

    constructor(
        private uploaderService: UploaderService
    ) {

    }

    click() {
        this.imageFileInput.nativeElement.click();
    }

    get states() {
        return UploaderService.STATES;
    }

    get queue() {
        return this.uploader.queue;
    }

    clearQueue() {
        this.state = this.states.IDLE;
        this.uploader.clearQueue();
    }

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    upload() {
        this.uploader.uploadAll()
    }

    ngOnInit() {
        let options = new UploaderOptions(this.options || <IUploaderOptions>{});
        this.uploader = this.uploaderService.initUploader(options);

        this.uploader.onAfterAddingFile = (item) => {
            this.onAfterAddingFile.next(item);
        }
        this.uploader.onBeforeUploadItem = (item) => {
            this.onBeforeUpload.next(item);
        }
        this.uploader.onErrorItem = (item, response, status, headers) => {
            this.state = this.states.ERROR;
            console.log(['onErrorItem response', item, response])
            this.onError.next({ item: item, response: JSON.parse(response) });
        }
        this.uploader.onSuccessItem = (item, response, status, headers) => {
            this.state = this.states.DONE;
            this.onSuccess.next({ item: item, response: JSON.parse(response) });
        }
        this.uploader.onProgressItem = (item, progress) => {
            this.state = this.states.PROCESSING;
            this.onProgress.next({ item: item, progress: progress });
        }
        this.uploader.onCompleteAll = () => {
            this.state = this.states.DONE;
            this.onCompleteAll.next();
        }

        this.clearQueue();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options'] && !changes['options'].isFirstChange() && changes['options'].currentValue) {
            let options = new UploaderOptions(this.options || <IUploaderOptions>{});
            this.uploader.setOptions(this.uploaderService.parseOptions(options));
        }
    }

}
