import { Injectable, EventEmitter } from '@angular/core';
import { Notification } from './notification.class';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';


@Injectable({
    providedIn: 'root'
  })
export class NotificationService {
    constructor(
        private messageBus: MessageBusService
    ) {

    }

    handleNotification(data: any) {
        console.log(['notification : ---------  ', data]);
        let notification = new Notification(data);

        this.ping(notification).pipe(take(1)).subscribe(inView => {
            console.log(['inView +++++++++++++ ', inView]);
            if (!inView) {
                this.messageBus.publish(MessageBusActions.NOTIFICATION_NEW, notification);
            }
        });
    }

    ping(notification: Notification): EventEmitter<boolean> {
        let inView: EventEmitter<boolean> = new EventEmitter();
        let eventType: string = notification.eventType();
        let timeout = setTimeout(() => {
            pingSubscription.unsubscribe();
            console.log('    emit FALSE');
            inView.emit(false);
        }, 100);

        console.log('subscribe on : ' + eventType + '.' + notification.id);
        let pingSubscription: Subscription = this.messageBus.on(eventType + '.' + notification.id)
            .subscribe(() => {
                // only true is considered
                // when not inView, no message is sent back
                // accordingly will continue with false, due to the timeout

                clearTimeout(timeout);
                pingSubscription.unsubscribe();

                console.log('    emit TRUE');
                inView.emit(true);
            });
        console.log('ping: ' + eventType);
        setTimeout(() => {
            this.messageBus.publish(eventType, notification);
        }, 10);

        return inView;
    }

}