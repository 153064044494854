import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyProgressComponent } from './survey-progress.component';

@NgModule({
  imports: [
    CommonModule,
  ],

  declarations: [
    SurveyProgressComponent
  ],
  exports: [
    SurveyProgressComponent
  ]
})
export class SurveyProgressModule { }