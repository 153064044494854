import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttachmentOverlayComponent } from './attachment-overlay.component';
import { AttachmentsComponent } from './attachments.component';
import { PdfPreviewModule } from './pdf-preview/x_pdf-preview.module';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { GalleryModule, UploaderModule, GalleryOrganizerModule } from '@libraries';
import { MgcLoaderModule } from '@rallysite/mgc-components';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';


@Pipe({ name: 'size' })
export class SizePipe implements PipeTransform {
    transform(value: number): string {
        let unit: string;
        if (value < 1024) {
            unit = 'Bytes';
        } else if (value / 1024 < 1024) {
            value = value / 1024;
            unit = 'KB';
        } else if (value / 1024 / 1024 < 1024) {
            value = value / 1024 / 1024;
            unit = 'MB';
        }

        return value.toFixed(2) + ' ' + unit;
    }
}

@NgModule({
    imports: [
        CommonModule,
        GalleryModule,

        MatIconModule,
        MgcLoaderModule,
        MatProgressBarModule,
        MatDialogModule,

        GalleryOrganizerModule,
        UploaderModule,
        PdfPreviewModule,
        DragDropModule,
        CdkDropList,
        CdkDrag
    ],
    declarations: [
        AttachmentsComponent,
        AttachmentOverlayComponent,

        SizePipe
    ],
    exports: [AttachmentsComponent]
})
export class AttachmentsModule { }
