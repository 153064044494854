import { filter, takeWhile } from 'rxjs/operators';

import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IUmbrella, Umbrella } from './umbrella';
import { UmbrellaService } from './umbrella.service';
import { UmbrellaModal } from './umbrella-modal';

import { Account } from '@board-accounts/account.model';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';

@Component({
  selector: 'board-umbrella',
  templateUrl: './umbrella.component.html',
  styleUrls: ['./umbrella.component.scss'],
})
export class UmbrellaComponent implements OnInit {
  @HostBinding() class = 'mgc-collection';
  @HostBinding('class.sortable-item') get sortbaleClass(): boolean {
    return this.umbrella && this.umbrella.isDraggable;
  }
  @HostBinding('class.focus') get focusClass(): boolean {
    return this.umbrella && this.umbrella._open && this.umbrella._state !== 'removing';
  }
  @HostBinding('class.remove') get removeClass(): boolean {
    return this.umbrella && this.umbrella._state === 'remove';
  }
  @HostBinding('class.highlight') get highlightClass(): boolean {
    return this.umbrella && this.umbrella._state === 'removing';
  }
  @HostBinding('attr.id') get __id() {
    return this.umbrella ? this.umbrella.Id : '';
  }

  alive = true;
  extended = false;
  @Input() currentItem: any;
  @Input() umbrella: Umbrella;
  @Input() account: Account;

  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private messageBus: MessageBusService,
    private umbrellaService: UmbrellaService,
    private dialog: MatDialog,
  ) { }


  toggleOpen() {
    this.onSelect.next();
  }

  /**
   * Go back to umbrellas
   */
  goBack() {
    this.messageBus.publish(MessageBusActions.ACTIVATE_ACCOUNT, { account: this.account, action: 'open' });
  }

  goBackBreadcrumbs(event) {
    switch (event) {
      case null:
        this.goBack();
        break;
    }
  }

  openSettings() {
    const dialogRef = this.dialog.open(UmbrellaModal, this.umbrella.settingsOptions(this.account, true));
    dialogRef.afterClosed().pipe(takeWhile(() => this.alive)).subscribe();
  }

  ngOnInit() {
    this.umbrellaService.umbrella$.pipe(
      filter(umbrella => !!umbrella && umbrella.Id === this.umbrella.Id && umbrella.UpdateDate !== this.umbrella.UpdateDate),
      takeWhile(() => this.alive))
      .subscribe(umbrella => {
        this.umbrella = umbrella;

        // update changes real time
        if (this.currentItem.Id === umbrella.Id) {
          this.toggleOpen();
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
