import { Component, Input, HostBinding } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { list, fadeInX, fadeOutX } from '@animations';

@Component({
    selector: 'mgc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.scss']
})
export class MgcButtonComponent {
    @Input() color = 'primary';
    @Input() processing = false;
    @Input() disabled = false;

    @HostBinding('class.mgc-button') setClass = true;
}

@Component({
    selector: 'mgc-raised-button',
    templateUrl: './button-raised.component.html',
    styleUrls: ['./button.scss'],
    animations: [list,
        trigger('fadeInOut', [
            transition(':enter', useAnimation(fadeInX(0, 1, 0, 0, .2))),
            transition(':leave', useAnimation(fadeOutX(1, 0, 0, 0, 0.1))),
        ]),
    ],
})
export class MgcRaisedButtonComponent extends MgcButtonComponent {
    @HostBinding('class.mgc-raised-button') setClass = true;
    @HostBinding('@fadeInOut') fadeInOut = true;
}

@Component({
    selector: 'mgc-raised-button-no-anim',
    templateUrl: './button-raised.component.html',
    styleUrls: ['./button.scss'],
})
export class MgcRaisedButtonComponentNoAnim extends MgcButtonComponent {
    @HostBinding('class.mgc-raised-button') setClass = true;
}

@Component({
    selector: 'mgc-stroked-button',
    templateUrl: './button-stroked.component.html',
    styleUrls: ['./button.scss', 'button-stroked.scss']
})
export class MgcStrokedButtonComponent extends MgcButtonComponent {
    @HostBinding('class.mgc-stroked-button') setClass = true;
}

@Component({
    selector: 'mgc-icon-button',
    templateUrl: './button-icon.component.html',
    styleUrls: ['button-icon.scss']
})
export class MgcIconButtonComponent extends MgcButtonComponent {
    @HostBinding('class.mgc-icon-button') setClass = true;

    @Input() icon = '';
}
