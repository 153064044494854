
import { takeWhile, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, SimpleChange, HostBinding } from '@angular/core';

import { ParticipantService } from './participant.service';
import { Participant } from './participant';
import { ParticipantAddModal } from './modals/participant-add-modal';

import { MatDialog } from '@angular/material/dialog';
import { list } from '@animations';
import { ServiceAlertClass, IAlertsClass } from '@rallysite/components/alert';
import { CurrentResourceService, PermissionService } from '@board/_services';
import { IServiceStates } from '@rallysite/global-interfaces';
import { Project } from '@board/items/project/project';

@Component({
  selector: 'mgc-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss'],
  animations: [list],
})
export class ParticipantListComponent implements OnInit, OnDestroy {
  @HostBinding('class.mgc-participant-list') participantListClass = true;
  @Input() item: any;

  participants: Participant[] = [];
  states: IServiceStates;
  state: number;
  alive: boolean = true;
  assignedParticipant: Participant;
  allow: boolean = false;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  constructor(
    private participantService: ParticipantService,
    private permissionService: PermissionService,
    public currentResourceService: CurrentResourceService,
    public dialog: MatDialog
  ) {
    this.states = ParticipantService.STATES;
  }

  newParticipant(): void {
    let participant = new Participant({
      ProjectId: this.project.Id,
      AccessType: this.participantService.getDefaultAccessLevel(),
      Status: 'pending',
    })

    let dialogRef = this.dialog.open(ParticipantAddModal, {
      data: {
        title: 'Add Participant',
        participant: participant,
        project: this.project,
      }
    });
  }

  trackByFn(index: number, participant: Participant): string {
    return participant.Id + '_' + participant.UpdateDate;
  }

  private get project(): Project {
    return this.currentResourceService.project;
  }

  private findAssignee(participants: Participant[]) {
    for (let participant of participants) {
      if (participant.Id === this.item.AssigneeId) {
        return participant;
      }
    }
    return null;
  }

  private bootstrap() {
    this.participantService.participants$.pipe(
      filter(participants => !!participants),
      takeWhile(() => this.alive))
      .subscribe(participants => {
        this.assignedParticipant = this.findAssignee(participants);
        this.participants = participants;
      })

    this.participantService.loadingState$.pipe(
      takeWhile(() => this.alive))
      .subscribe(state => {
        this.state = state;
      });
  }

  ngOnInit() {
    if (!this.permissionService.allowsAction('view.participant', this.currentResourceService.project._participant, { silent: true })) {
      this.allow = false;
    } else {
      this.allow = true;
      this.bootstrap();
    }
  }

  ngOnChanges(change: SimpleChange) {
    if (change['item'] && change['item'].currentValue) {
      this.participantService.loadAll(this.currentResourceService.project);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

}
