<div class="settings-section">

  <div class="settings-box noborder top-align">

    <mat-form-field class="width100">
      <input tabindex="-1" matInput #name="ngModel" [(ngModel)]="data.user.FirstName" name="fname" placeholder="First Name">
    </mat-form-field>

    <mat-form-field class="width100">
      <input tabindex="-1" matInput #name="ngModel" [(ngModel)]="data.user.LastName" name="lname" placeholder="Last Name">
    </mat-form-field>

  </div>

  <form name="form" #f="ngForm" novalidate>
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            New Password
          </mat-panel-title>
          <mat-panel-description>
            Change your password
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-form-field class="width70">
          <input matInput #password="ngModel" autocomplete="current-password" [(ngModel)]="userPassword.opassword" [type]="hide ? 'password' : 'text'"
            name="opassword" autocomplete="old-password" placeholder="Old password" required minlength="6">
          <mat-icon class="cp-icon pale" matSuffix (click)="hide=!hide" svgIcon="{{hide ? 'auth:visibility' : 'auth:visibility_off'}}"></mat-icon>
          <mat-error *ngIf="password.hasError('minlength')">Password length must be greater than 6</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="width70">
          <input matInput #password="ngModel" autocomplete="new-password" [(ngModel)]="userPassword.password" [type]="hide ? 'password' : 'text'"
            name="password" autocomplete="new-password" placeholder="New password" required validateEqual="cpassword" reverse="true" minlength="6">
          <mat-icon class="cp-icon pale" matSuffix (click)="hide=!hide" svgIcon="{{hide ? 'auth:visibility' : 'auth:visibility_off'}}"></mat-icon>
          <mat-error *ngIf="password.hasError('minlength')">Password length must be greater than 6</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field class="width70">
          <input matInput #cpassword="ngModel" autocomplete="new-password" [(ngModel)]="userPassword.cpassword" [type]="hide ? 'password' : 'text'"
            name="cpassword" autocomplete="confirm-password"  placeholder="Confirm new password" required validateEqual="password">
          <mat-icon class="cp-icon pale" matSuffix (click)="hide=!hide" svgIcon="{{hide ? 'auth:visibility' : 'auth:visibility_off'}}"></mat-icon>
          <mat-error *ngIf="!cpassword.valid || !cpassword.pristine">Password did not match</mat-error>
        </mat-form-field>
      </mat-expansion-panel>

    </mat-accordion>
  </form>

  <h3>Contact details</h3>

  <div class="mat-elevation-z1">
    <div class="settings-box noborder" *ngFor="let detail of data.user.ContactDetails; trackBy: trackByFn">
      <mat-form-field class="width100">
        <input tabindex="-1" matInput #name="ngModel" [(ngModel)]="detail.value" [name]="detail.id" [placeholder]="detail.name">
        <mat-icon class="cp-icon" matSuffix [matMenuTriggerFor]="detailMenu" [matMenuTriggerData]="{detail: detail}" aria-label="Remove detail"
          svgIcon="board-mat:more_vert"></mat-icon>
      </mat-form-field>
    </div>

    <div class="settings-box noborder">
      <table class="width100" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="width100">
              <input tabindex="-1" matInput #name="ngModel" [(ngModel)]="newDetail.type" placeholder="Type" (keyup.enter)="addDetail()"
                (blur)="addDetail()">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="width100">
              <input tabindex="-1" matInput #name="ngModel" [(ngModel)]="newDetail.value" placeholder="Value" (keyup.enter)="addDetail()"
                (blur)="addDetail()">
            </mat-form-field>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <h3>Help Tips</h3>
  <do-not-show-again-settings [options]="dnsaOptions"></do-not-show-again-settings>

</div>

<mat-menu #detailMenu="matMenu" [overlapTrigger]="true" xPosition="before">
  <ng-template matMenuContent let-detail="detail">
    <button mat-menu-item (click)="removeDetail(detail)">
      <mat-icon svgIcon="board-mat:delete"></mat-icon>
      <span>Delete {{detail.name}}</span>
    </button>
  </ng-template>
</mat-menu>