import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "@board/modals/modals-editor/modal.model";

@Component({
  selector: 'config-common',
  templateUrl: './config-common.component.html',
})
export class ConfigCommonComponent implements OnInit {
  @Input() modal;
  @Output() modalChange = new EventEmitter<Modal>();
  @Input() submitted;

  form: FormGroup;
  get f() { return this.form.controls; }

  ngOnInit() {
    this.modalChange.emit({
      ...this.modal,
      Config: {
        ...this.modal.Config,
        enableClosingModal: this.modal.Config.enableClosingModal || false,
        closingFrequency: this.modal.Config.closingFrequency || 'one',
      }
    })

    this.form = new FormGroup({
      enableClosingModal: new FormControl(this.modal.Config.enableClosingModal || false),
      closingFrequency: new FormControl(this.modal.Config.closingFrequency || 'one'),
    });

    this.form.valueChanges.subscribe(newConfig => {
      this.modalChange.emit({
        ...this.modal,
        Config: {
          ...this.modal.Config,
          ...newConfig,
        }
      })
    });
  }
}
