import { Inject, Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';

import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '@rallysite/components/alert';
import { ICommunicationOptions } from '../communications.types';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private _endPoint: string;

  constructor(
    private http: HttpClient,
    public alertService: AlertService,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
    this._endPoint = `${this.config.endpoint}/api`;
  }

  // toggleUnsubscribeBrand(brandOptions: IUnsubscribeBrandOptions) {
  //   const endpoint = `${this._endPoint}/toggle-brand-subscription/${brandOptions.brandId}`;

  //   return this.http.put(endpoint, {}).pipe(
  //     map(response => {
  //       return response;
  //     }), catchError(error => {
  //       console.log('Could not toggle unsubscribe brand');
  //       return observableOf(null);
  //     })
  //   );
  // }

  saveSettings(options: ICommunicationOptions) {
    const endpoint = `${this._endPoint}/communication-settings`;
    return this.http.put(endpoint, options).pipe(
      map(response => {
        this.alertService.snackSuccess(`Your settings were successfully saved.`,
          { horizontalPosition: 'center', verticalPosition: 'bottom', duration: 2000 });
        return response;
      }), catchError(error => {
        console.log('Could not save communication settings');
        this.alertService.snackError(error.error);
        return observableOf(null);
      })
    );
  }
}
