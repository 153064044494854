import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountService } from '@board-accounts/account.service';
import { CurrentResourceService, PermissionService } from '@board/_services';
import { ProjectService } from '@items/project';
import { ShareOverlayService } from '@libraries/social-share/overlay/share-overlay.service';
import { SocialShareService } from '@libraries/social-share/social-share.service';
import { Comment, CommentModal } from '@panel-components/comments';
import { EmailModal } from '@panel-components/email';
import { ReactionsModal } from '@reactions/modals/reactions-modal';
import { NoteReaction } from '@reactions/reaction.model';
import { ReactionsOverlayService } from '@reactions/reactions-overlay.service';
import { ReactionTypes } from '@reactions/reactions.enum';
import { ReactionsService } from '@reactions/reactions.service';
import { of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { ITaskV2BlocksMetadata } from './blocks/task-v2-blocks-metadata';
import { TaskV2BlocksService } from './blocks/task-v2-blocks.service';
import { TaskV2BlockType } from './blocks/types';
import { TaskV2 } from './task-v2.model';

@Component({
    selector: 'task-v2-board',
    templateUrl: './task-v2-board.component.html',
    styleUrls: ['./task-v2-board.component.scss']
})
export class TaskV2BoardComponent implements OnInit, OnDestroy, OnChanges {

    constructor(
        private blocksService: TaskV2BlocksService,
        private permissionsSettings: PermissionService,
        private currentResource: CurrentResourceService,
        private accountService: AccountService,
        private reactionsOverlay: ReactionsOverlayService,
        private reactionsService: ReactionsService,
        private shareService: SocialShareService,
        private projectService: ProjectService,
        private socialShareOverlay: ShareOverlayService,
        private dialog: MatDialog,
    ) { }

    get account() {
        return this.accountService.currentAccount;
    }
    @Input('item') task: TaskV2;
    @Output() edit: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    allowUpdate = false;
    allowEmail = false;
    allowComment = false;

    ready: boolean;
    loading: boolean;
    blocksMetadata: ITaskV2BlocksMetadata;

    currentReaction: NoteReaction;
    reactionsData: { type: string, value: number }[] = [];
    reactionClass: string;

    blockType = TaskV2BlockType;

    createDate: Date | string;
    updateDate: Date | string;

    commentsLabel: string;



    /**
     * Share sections
     */
    shareClass = 'share-icon no-share';
    totalShares = 0;
    personalShares = 0;
    shareEnabled = true;

    private loadBlocks() {
        this.ready = false;
        this.loading = true;
        of(this.task)
            .pipe(
                filter(task => !!task),
                switchMap(task => {
                    return this.blocksService.getBlocks(task);
                })
            )
            .subscribe((blocksMetadata: ITaskV2BlocksMetadata) => {
                this.blocksMetadata = blocksMetadata;
                this.updateDate = blocksMetadata.updatedAt;
                this.ready = true;
                this.loading = false;

                this.bootstrap();
            });
    }
    commentsToggle(event: MouseEvent, show: boolean = null) {
        if (typeof show === 'boolean') {
            this.blocksMetadata.showComments = show;
        } else {
            this.blocksMetadata.showComments = !this.blocksMetadata.showComments;
        }

        this.adjustLabel();
        if (event) {
            event.stopPropagation();
        }
    }

    private adjustLabel() {
        this.commentsLabel = '';
        const len = this.blocksMetadata.nComments;
        this.commentsLabel += `${len} comment${len > 1 ? 's' : ''}`;
    }

    openEmailModal() {
        // DISABLED FOR NOW

        // if (this.isSnapshotTask) {
        //   return false;
        // }
        if (!this.permissionsSettings.allowsAction('create.mail', this.task, { message: 'Not allowed send emails' })) {
            return false;
        }

        this.dialog.open(EmailModal, {
            data: {
                // TODO: need new taskv2blocks metadata object
                task: this.task
            }
        });
    }


    openCommentModal() {
        // if (this.isSnapshotTask) {
        //   return false;
        // }
        this.blocksMetadata.showComments = true;

        const comment = new Comment({
            Text: '',
            TaskV2Id: this.blocksMetadata.taskv2Id,
            AccountId: this.account.Id
        });

        this.dialog.open(CommentModal, comment.settingsOptions({ taskv2BlocksMetadata: this.blocksMetadata }))
            .afterClosed().pipe(take(1)).subscribe(result => {
                this.adjustLabel();
            });
    }
    bootstrapShares() {
        const project = this.currentResource.project;
        if (!project.IsPublic) {
            this.shareEnabled = false;
            return;
        }

        this.projectService.getProjectPubdata(project).pipe(take(1))
            .subscribe(pubdata => {
                this.renderShareClass();

                const brand = (pubdata || {})._brand;
                this.shareService.init({
                    account: this.accountService.currentAccount,
                    brand: brand,
                    project: project,
                    task: this.task
                });
                const options = { taskId: this.task.Id };
                this.shareService.getTotalShares$(options).subscribe(tShares => this.totalShares = tShares);
                this.shareService.getPersonalShares$(options).subscribe(pShares => {
                    this.personalShares = pShares;
                    this.renderShareClass(this.personalShares > 0);
                });
                this.shareService.shares(options).pipe(take(1)).subscribe();
            });
    }

    engageShare(event: MouseEvent) {
        this.socialShareOverlay.open(event.currentTarget).afterClosed().subscribe();
    }

    renderShareClass(shared: boolean = false) {
        const classes = [
            'share-icon',
        ];
        classes.push(shared ? 'shared' : 'no-share');
        this.shareClass = classes.join(' ');
    }
    /** end share*/



    /**Reactions */
    openReactions(event) {
        this.reactionsOverlay.open(event.currentTarget, this.currentReaction).afterClosed()
            .subscribe((reactionType: ReactionTypes) => {
                this.currentReaction || (this.currentReaction = new NoteReaction({
                    AccountId: this.account.Id,
                    TaskV2Id: this.task.Id,
                }));

                if (this.currentReaction.Type && this.currentReaction.Type.toLowerCase() === ReactionTypes[reactionType].toLowerCase()) {
                    this.renderClass(null);
                    this.reactionsService.deleteReaction(this.blocksMetadata, this.currentReaction).subscribe(data => {
                        this.render(null);
                    });
                } else {
                    this.currentReaction.Type = ReactionTypes[reactionType];
                    this.renderClass(this.currentReaction);
                    this.reactionsService.react(this.blocksMetadata, this.currentReaction).subscribe(data => {
                        this.render(this.blocksMetadata.findReaction(this.account.Id));
                    });
                }

            });
    }

    openReactionsData($event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        const config: MatDialogConfig = {
            maxWidth: '500px',
            minWidth: '350px',

            data: {
                item: this.blocksMetadata,
                groupedReactions: this.blocksMetadata.groupedReaction()
            }
        };

        this.dialog.open(ReactionsModal, config)
            .afterClosed().pipe(take(1)).subscribe(result => {
                //
            });
    }

    render(reaction: NoteReaction) {
        this.currentReaction = reaction;
        this.reactionsData = this.blocksMetadata.groupedReaction(3);
    }

    renderClass(reaction: NoteReaction) {
        const classes = [
            'reaction-icon',
        ];
        if (reaction) {
            classes.push('reacted');
            classes.push(reaction.Type);
        } else {
            classes.push('no-reaction');
        }
        this.reactionClass = classes.join(' ');
    }
    /** END Reactions */


    onEdit() {
        this.edit.emit(true);
    }

    private bootstrap() {
        const reaction = this.blocksMetadata.findReaction(this.account.Id);
        this.renderClass(reaction);
        this.render(reaction);

        this.commentsToggle(null, !(this.currentResource.project.Settings && this.currentResource.project.Settings.collapseComments));

        if (this.blocksMetadata.blocks.length === 0) {
            this.edit.emit(true);
        }

        this.bootstrapShares();
    }

    ngOnInit() {
        // this.isSnapshotTask = this.task instanceof SnapshotTask;

        // this.allowUpdate = this.permissionsSettings.allowsAction('update.note', this.note, { silent: true });
        // this.allowEmail = this.permissionsSettings.allowsAction('create.mail', this.note, { silent: true });
        // this.allowComment = this.permissionsSettings.allowsAction('create.comment', this.note, { silent: true });

        this.allowUpdate = true;
        this.allowEmail = true;
        this.allowComment = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['task'] && changes['task'].currentValue) {
            if (this.task.StartDate) {
                this.createDate = this.task.StartDate.toString();
            } else {
                this.createDate = this.task.CreateDate;
            }
            this.loadBlocks();
        }
    }

    ngOnDestroy() {

    }

}
