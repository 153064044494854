import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { QueryRequestService } from './query-request.service';
import { Umbrella, MyProjectsUmbrella, ArchiveUmbrella } from '@board/items/umbrella/umbrella';
import { Project } from '@board/items/project/project';
import { Task } from '@board/items/task/task';
import { ScheduledTask } from '@board/items/scheduled-task/scheduled-task';
import { SnapshotTask } from '@board/items/scheduled-task/snapshot-task/snapshot-task';
import { BaseEmailCampaignGroup } from '@board/emails/email-campaign-group/email-campaign.group';
import { BaseEmailCampaign } from '@board/emails/email-campaign/email-campaign';
import { BaseEmailItem } from '@board/emails/email-item/email-item';
import { BaseVideoCampaignGroup } from '@board/videos/video-campaign-group/video-campaign.group';
import { BaseVideoCampaign } from '@board/videos/video-campaign/video-campaign';
import { BaseVideoItem } from '@board/videos/video-item/video-item';
import { ITasksTab } from '@board/items/project/tasks-tabs';
import { Brand } from '@board-brands/brand.model';
import { TaskV2 } from '@items/task-v2/task-v2.model';

@Injectable({
    providedIn: 'root'
})
export class CurrentResourceService {

    private _umbrella: BehaviorSubject<Umbrella | MyProjectsUmbrella | ArchiveUmbrella>;
    private _project: BehaviorSubject<Project>;
    private _task: BehaviorSubject<Task|TaskV2>;
    private _scheduledTask: BehaviorSubject<ScheduledTask | SnapshotTask>;
    private _currentItem: BehaviorSubject<any>;

    private _emailCampaignGroup: BehaviorSubject<BaseEmailCampaignGroup>;
    private _emailCampaign: BehaviorSubject<BaseEmailCampaign>;
    private _emailItem: BehaviorSubject<BaseEmailItem>;

    private _videoCampaignGroup: BehaviorSubject<BaseVideoCampaignGroup>;
    private _videoCampaign: BehaviorSubject<BaseVideoCampaign>;
    private _videoItem: BehaviorSubject<BaseVideoItem>;

    private _currentNavTab: BehaviorSubject<ITasksTab>;

    private dataStore: {
        currentNavTab: ITasksTab,
        currentItem: any,
        umbrella: Umbrella | MyProjectsUmbrella | ArchiveUmbrella,
        project: Project,
        task: Task|TaskV2,
        scheduledTask: ScheduledTask | SnapshotTask,
        brand: Brand,
        //
        emailCampaignGroup: BaseEmailCampaignGroup,
        emailCampaign: BaseEmailCampaign,
        emailItem: BaseEmailItem,

        videoCampaignGroup: BaseVideoCampaignGroup,
        videoCampaign: BaseVideoCampaign,
        videoItem: BaseVideoItem
    };

    constructor(
        private queryRequest: QueryRequestService
    ) {
        this.dataStore = {
            currentNavTab: null,
            currentItem: null,
            umbrella: null,
            project: null,
            task: null,
            scheduledTask: null,
            brand: null,
            emailCampaignGroup: null,
            emailCampaign: null,
            emailItem: null,
            videoCampaignGroup: null,
            videoCampaign: null,
            videoItem: null
        };
        this._umbrella = <BehaviorSubject<Umbrella>>new BehaviorSubject(null);
        this._project = <BehaviorSubject<Project>>new BehaviorSubject(null);
        this._task = <BehaviorSubject<Task|TaskV2>>new BehaviorSubject(null);
        this._scheduledTask = <BehaviorSubject<ScheduledTask>>new BehaviorSubject(null);
        this._currentItem = <BehaviorSubject<any>>new BehaviorSubject(null);
        this._currentNavTab = <BehaviorSubject<ITasksTab>>new BehaviorSubject(null);
        //
        this._emailCampaignGroup = <BehaviorSubject<BaseEmailCampaignGroup>>new BehaviorSubject(null);
        this._emailCampaign = <BehaviorSubject<BaseEmailCampaign>>new BehaviorSubject(null);
        this._emailItem = <BehaviorSubject<BaseEmailItem>>new BehaviorSubject(null);

        this._videoCampaignGroup = <BehaviorSubject<BaseVideoCampaignGroup>>new BehaviorSubject(null);
        this._videoCampaign = <BehaviorSubject<BaseVideoCampaign>>new BehaviorSubject(null);
        this._videoItem = <BehaviorSubject<BaseVideoItem>>new BehaviorSubject(null);
    }

    reset() {
        this.umbrella = null;
        this.project = null;
        this.task = null;
        this.scheduledTask = null;
        this.currentItem = null;
        this._currentNavTab = <BehaviorSubject<ITasksTab>>new BehaviorSubject(null);
        this.emailCampaignGroup = null;
        this.emailCampaign = null;
        this.emailItem = null;
        this.videoCampaignGroup = null;
        this.videoCampaign = null;
        this.videoItem = null;
        this.queryRequest.update({});
    }

    get umbrella$(): Observable<Umbrella | MyProjectsUmbrella | ArchiveUmbrella> {
        return this._umbrella.asObservable();
    }
    get project$(): Observable<Project> {
        return this._project.asObservable();
    }
    get task$(): Observable<Task|TaskV2> {
        return this._task.asObservable();
    }
    get currentItem$(): Observable<any> {
        return this._currentItem.asObservable();
    }
    get currentNavTab$(): Observable<ITasksTab> {
        return this._currentNavTab.asObservable();
    }
    get emailCampaignGroup$(): Observable<BaseEmailCampaignGroup> {
        return this._emailCampaignGroup.asObservable();
    }
    get emailCampaign$(): Observable<BaseEmailCampaign> {
        return this._emailCampaign.asObservable();
    }
    get emailItem$(): Observable<BaseEmailItem> {
        return this._emailItem.asObservable();
    }
    get videoCampaignGroup$(): Observable<BaseVideoCampaignGroup> {
        return this._videoCampaignGroup.asObservable();
    }
    get videoCampaign$(): Observable<BaseVideoCampaign> {
        return this._videoCampaign.asObservable();
    }
    get videoItem$(): Observable<BaseVideoItem> {
        return this._videoItem.asObservable();
    }

    get umbrella(): Umbrella | MyProjectsUmbrella | ArchiveUmbrella {
        return this.dataStore.umbrella;
    }
    get project(): Project {
        return this.dataStore.project;
    }
    get task(): Task|TaskV2 {
        return this.dataStore.task;
    }
    get scheduledTask():  ScheduledTask | SnapshotTask {
        return this.dataStore.scheduledTask;
    }
    get currentItem(): any {
        return this.dataStore.currentItem;
    }
    get currentNavTab(): ITasksTab {
        return this.dataStore.currentNavTab;
    }
    get brand(): Brand {
        return this.dataStore.brand;
    }
    get emailCampaignGroup(): BaseEmailCampaignGroup {
        return this.dataStore.emailCampaignGroup;
    }
    get emailCampaign(): BaseEmailCampaign {
        return this.dataStore.emailCampaign;
    }
    get emailItem(): BaseEmailItem {
        return this.dataStore.emailItem;
    }
    get videoCampaignGroup(): BaseVideoCampaignGroup {
        return this.dataStore.videoCampaignGroup;
    }
    get videoCampaign(): BaseVideoCampaign {
        return this.dataStore.videoCampaign;
    }
    get videoItem(): BaseVideoItem {
        return this.dataStore.videoItem;
    }


    set brand(item: Brand) {
        this.dataStore.brand = item;
    }

    set umbrella(item: Umbrella | MyProjectsUmbrella | ArchiveUmbrella) {
        this.dataStore.umbrella = item;
        this._umbrella.next(item);
    }
    set project(item: Project) {
        this.dataStore.project = item;
        this._project.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }
    set task(item: Task|TaskV2) {
        this.dataStore.task = item;
        this._task.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }
    set scheduledTask(item: ScheduledTask | SnapshotTask) {
        this.dataStore.scheduledTask = item;
        this._scheduledTask.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }
    set emailCampaignGroup(item: BaseEmailCampaignGroup) {
        this.dataStore.emailCampaignGroup = item;
        this._emailCampaignGroup.next(item);
    }
    set emailCampaign(item: BaseEmailCampaign) {
        this.dataStore.emailCampaign = item;
        this._emailCampaign.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }
    set emailItem(item: BaseEmailItem) {
        this.dataStore.emailItem = item;
        this._emailItem.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }

    set videoCampaignGroup(item: BaseVideoCampaignGroup) {
        this.dataStore.videoCampaignGroup = item;
        this._videoCampaignGroup.next(item);
    }
    set videoCampaign(item: BaseVideoCampaign) {
        this.dataStore.videoCampaign = item;
        this._videoCampaign.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }
    set videoItem(item: BaseVideoItem) {
        this.dataStore.videoItem = item;
        this._videoItem.next(item);
        if (this.currentItem && item && this.currentItem.Id === item.Id && this.currentItem.UpdateDate !== item.UpdateDate) {
            this.dataStore.currentItem = item;
            this._currentItem.next(item);
        }
    }



    set currentItem(item: any) {
        if (item === this.dataStore.currentItem) {
            return;
        }
        switch (true) {
            case item instanceof Umbrella:
            case item instanceof MyProjectsUmbrella:
            case item instanceof ArchiveUmbrella:
                this.reset();
                this.umbrella = item;
                break;
            case item instanceof Project:
                this.project = item;
                this.task = null;
                this.queryRequest.update({ p: item.Id });
                break;
            case item instanceof Task:
            case item instanceof TaskV2:
                this.task = item;
                this.queryRequest.update({ t: item.Id });
                break;
            case item instanceof ScheduledTask:
            case item instanceof SnapshotTask:
                this.scheduledTask = item;
                this.queryRequest.update({ s: item.Id });
                break;
            case item === null:
                this.dataStore.umbrella = this.dataStore.project = this.dataStore.task = null;
                this.dataStore.emailCampaignGroup = this.dataStore.emailCampaign = this.dataStore.emailItem = null;
                this.dataStore.videoCampaignGroup = this.dataStore.videoCampaign = this.dataStore.videoItem = null;
                this.queryRequest.update({});
                break;
            case item instanceof BaseEmailCampaignGroup:
                this.reset();
                this.emailCampaignGroup = item;
                break;
            case item instanceof BaseEmailCampaign:
                this.emailCampaign = item;
                this.emailItem = null;
                this.queryRequest.update({ ec: item.Id });
                break;
            case item instanceof BaseEmailItem:
                this.emailItem = item;
                this.queryRequest.update({ ei: item.Id });
                break;

            case item instanceof BaseVideoCampaignGroup:
                this.reset();
                this.videoCampaignGroup = item;
                break;
            case item instanceof BaseVideoCampaign:
                this.videoCampaign = item;
                this.videoItem = null;
                this.queryRequest.update({ vc: item.Id });
                break;
            case item instanceof BaseVideoItem:
                this.videoItem = item;
                this.queryRequest.update({ vi: item.Id });
                break;
        }
        this.dataStore.currentItem = item;
        this._currentItem.next(item);
    }

    set currentNavTab(tab: ITasksTab) {
        if (tab === this.dataStore.currentNavTab) {
            return;
        }

        this.dataStore.currentNavTab = tab;
        this._currentNavTab.next(tab);
    }

}
