import { playerRegexr } from "@rallysite/global-pipes";
import { environment } from "environments/environment";

const Jodit: any = {}

export const isURL = function (str) {
    let pattern = new RegExp('^(https?:\\/\\/)' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?', 'i');
    return pattern.test(str);
}

export const _trim = function (str) {
    if (typeof str !== 'string') {
        str = str.toString();
    }
    return str.substring(1, str.length - 1);
}


// export const joditResponsive = `<div data-jodit-responsive="1"><iframe src="{{IFRAME_URL}}" style="border:0" frameborder="0" allowfullscreen></iframe></div>`;
export const joditResponsive = `<div data-jodit-responsive="1"><iframe src="{{IFRAME_URL}}" style="border:0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe></div>`;

export const convertPlayerURLToEmbed = function (Jodit, url, type: 'tall' | 'fullWidth', data: {}) {
    let width = '100%';
    let height = 345;

    if (!isURL(url)) {
        return url;
    }

    if (playerRegexr.test(url)) {

        // both or none
        if (typeof data['getProjectId'] === "function" && typeof data['getTaskId'] === "function") {
            const qs = `plan_id=${data['getProjectId']()}&task_id=${data['getTaskId']()}`;

            const qIndex = url.indexOf('?');
            if (qIndex === -1) {
                url += `?${qs}`;
            } else {
                url += `&${qs}`;
            }
        }
        
        const iframeUrl = `${url}`;

        if (type == 'fullWidth') {
            return joditResponsive.replace(/{{IFRAME_URL}}/, iframeUrl);
        }

        const widthRegexr = new RegExp(_trim(playerRegexr).replace('ply_id', 'width'));
        const heightRegexr = new RegExp(_trim(playerRegexr).replace('ply_id', 'height'));

        const w = widthRegexr.test(url) ? widthRegexr.exec(url)[2] : width;
        const h = heightRegexr.test(url) ? heightRegexr.exec(url)[2] : height;

        return `<iframe width="${w}" height="${h}" src="${iframeUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe>`;
    }

    return url;
}

export const convertYoutubeURLToEmbed = function (Jodit, url, type: 'tall' | 'fullWidth') {
    let helpers = Jodit.modules.Helpers;
    let width = '100%';
    let height = 345;

    if (!isURL(url)) {
        return url;
    }
    let parser = document.createElement('a');
    parser.href = url;

    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'youtube.com':
        case 'www.youtube.com':
        case 'youtu.be':
        case 'www.youtu.be':
            let query = parser.search
                ? helpers.parseQuery(parser.search)
                : { v: parser.pathname.substr(1) };

            if (!query.v) {
                return url;
            }

            let iframeUrl = `${protocol}//www.youtube.com/embed/${query.v}`;
            if (type == 'fullWidth') {
                return joditResponsive.replace(/{{IFRAME_URL}}/, iframeUrl);
            }
            return `<iframe width="${width}" height="${height}" src="${iframeUrl}" frameborder="0" allowfullscreen></iframe>`;
    }
    return url;
}

export const convertVimeoURLToEmbed = function (Jodit, url, type: 'tall' | 'fullWidth') {
    let width = '100%';
    let height = 345;

    if (!isURL(url)) {
        return url;
    }

    let parser = document.createElement('a'),
        pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    parser.href = url;
    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'www.vimeo.com':
        case 'vimeo.com':
            if (!pattern1.test(url)) {
                return url;
            }

            let iframeUrl = `${protocol}//player.vimeo.com/video/$1`;
            if (type == 'fullWidth') {
                return url.replace(pattern1, joditResponsive.replace(/{{IFRAME_URL}}/, iframeUrl));
            }

            return url.replace(pattern1, `<iframe width="${width}" height="${height}" src="${iframeUrl}" frameborder="0" allowfullscreen></iframe>`);
    }
    return url;
}


export const convertJoinMeURLToEmbed = function (Jodit, url) {
    if (!isURL(url)) {
        return url;
    }
    let parser = document.createElement('a'), pattern1 = /(?:http?s?:\/\/)?(?:.*)?(?:join\.me)\/?(.+)/g;
    parser.href = url;
    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'join.me':
        case 'www.join.me':
        case 'secure.join.me':
            return pattern1.test(url)
                ? url.replace(pattern1, `<style>.joinme-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .joinme-container iframe, .joinme-container object, .joinme-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style>
<div class="joinme-container"><iframe src="${protocol}//secure.join.me/$1" style="border:0" frameborder="0" allowfullscreen></iframe></div>`)
                : url;
    }
    return url;
};


export const convertSurveyURLToEmbed = function (Jodit, url, height) {
    if (!isURL(url)) {
        return url;
    }
    height || (height = '800px');
    height = /^\d+$/.test(height) ? `${height}px` : height;
    return `<style>.survey-container {position: relative;height: ${height};overflow: hidden;max-width: 100%;}.survey-container iframe {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}</style>
<div class="ww survey-container"><iframe src="${url}" style="border:0" frameborder="0"></iframe></div>`
};

export const convertZoomURLToEmbed = function (Jodit, url, title) {
    if (!isURL(url)) {
        return url;
    }
    let parser = document.createElement('a'), pattern1 = /(?:http?s?:\/\/)?(?:.*)?(?:zoom\.us)\/?(.+)/g;
    parser.href = url;
    let protocol = parser.protocol || '';

    switch (true) {
        case /.*zoom\.us$/.test(parser.hostname):
            if (pattern1.test(url)) {
                let iframe = document.createElement('iframe');
                iframe.height = '100%';
                iframe.width = '100%';
                // iframe.marginWidth = '0';
                // iframe.marginHeight = '0';
                // iframe.frameBorder = '0';
                // iframe.scrolling = 'no';
                if (iframe.style) {
                    iframe.style.margin = '0px';
                    iframe.style.padding = '0px';
                    iframe.style.border = '0px none';
                    iframe.style.display = 'block';
                    iframe.style.overflow = 'hidden';
                }
                const domain = `${environment.protocol}://${environment.domain}`;
                let html = `
<style>
    a.zoom-container {
        font-family: Poppins, sans-serif !important;
        font-size: 15px !important;
        display: block;
        position: relative;
        text-decoration: none;
        box-shadow:
            0 0 0 1px rgba(0,0,0,.15), 
            0 2px 3px rgba(0,0,0,.2);
        color: gray;
        margin: 3px;

        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        cursor: pointer;
    }
    a.zoom-container:hover, a.zoom-container:focus{
        color: gray;
    }

    .zoom-container .zoom-img {
        display: block;
        height: 60%;
        max-height: 190px;
        width: 100%;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-image: url(${domain}/assets/images/zoom.png);
        background-position: center;
        background-size: cover;
    }

    .zoom-container .zoom-info{
        display: block;
        padding: 15px 10px;
    }
    .zoom-container .zoom-info > h3 {
        margin: 5px  0 15px 0;
        text-decoration: none;

    }
    .zoom-container .zoom-info > span {
        display: flex;
        align-items: center;
    }

    .zoom-container .zoom-info > span svg{
        margin-right: 10px;
        width: 20px;
        height: 20px;
        min-width: 20px;
    }
    .zoom-container .zoom-info > span span{
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 90%;
    }
</style>
    <a class="zoom-container" href="${url}" target="_blank">
        <div class="zoom-img"></div>
        <div class="zoom-info">
            <h3>${title}</h3>
            <span>
                <svg height="24" width="24" viewBox="0 0 24 24"><rect x=".1" y=".1" width="24" height="24" rx="4" ry="4" fill="#2d8cff" opacity=".93"/><path d="m19 8-3.3 2.4v3.1l3.3 2.4c0.24 0.18 0.51 0.24 0.51-0.26v-7.4c0-0.44-0.22-0.49-0.51-0.26zm-15 5.8v-5.5c0-0.23 0.19-0.41 0.41-0.41h8.1c1.3 0 2.3 1 2.3 2.3v5.5c0 0.23-0.19 0.41-0.41 0.41h-8.1c-1.3 0-2.3-1-2.3-2.3z" fill="#fff" stroke-width=".49"/></svg>
                <span>${url}</span>
            </span>
        </div>
    </a>`;
                iframe.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(html);
                return iframe;
            } else {
                return url;
            }
    }
    return url;
};

export const convertFacebookURLToEmbed = function (Jodit, url, type: string) {
    let width = '100%';
    let height = 345;
    let tallRatio = 0.56;
    let tallW = 360;
    let tallH = 360 / tallRatio;

    let isTall: boolean = type == 'tall';

    url = url.replace(/\/+$/, '');

    if (!isURL(url)) {
        return url;
    }
    let parser = document.createElement('a'), pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:facebook\.com)\/?(.+)/g;
    parser.href = url;
    switch (parser.hostname) {
        case 'facebook.com':
        case 'www.facebook.com':
            if (!pattern1.test(url)) {
                return url;
            }
            let iFrame = (isTall ?
                `<iframe style="width:${tallW}px; height:${tallH}px"` :
                `<iframe width="${width}" height="${height}"`) +
                '" src=https://www.facebook.com/v6.0/plugins/video.php?allowfullscreen=true&autoplay=true&href=' +
                url +
                '/" frameborder="0" allowfullscreen></iframe>'

            return url.replace(pattern1, iFrame);
    }
    return url;
};


export const convertTiktokURLToEmbed = function (Jodit, url) {
    let helpers = Jodit.modules.Helpers;
    let width = '100%';
    let height = 345;

    url = url.replace(/\/+$/, '');

    if (!isURL(url)) {
        return url;
    }
    let parser = document.createElement('a'), pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:tiktok\.com)(?:.*video)\/?(.+)/g;
    // https://www.tiktok.com/@christine_andrew/video/6798704164827450629

    parser.href = url;
    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'tiktok.com':
        case 'www.tiktok.com':
            return pattern1.test(url)
                ? url.replace(pattern1, '<iframe style="width:100%; height:800px"' +
                    '" src="' + protocol +
                    '//www.tiktok.com/embed/v2/$1" frameborder="0" allowfullscreen></iframe>')
                : url;
    }
    return url;
};