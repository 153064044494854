<div class="preview-theme">
    <img class="phone" src="assets/images/iphone-front.png">

    <div class="image-wrapper">
        <div class="image" [style.background-image]="'url('+(customImageOptions.id | navigationImage:'large':true)+')'"><div class="darken-ov"></div></div>
        <img class="accent" [src]="selectedAccentOption.image">
    </div>
</div>

<div class="options">

    <h2 class="title">create your theme</h2>
    <mgc-stroked-button class="button" tabindex="-1" (click)="chooseFromGallery()">Choose from gallery
    </mgc-stroked-button>

    <label>Choose the accents that you like the most:</label>
    <mat-radio-group class="mgc-radio-group" name="accent" [value]="selectedAccentOption">
        <mat-radio-button class="mgc-radio-button" *ngFor="let accent of accentOptions" [value]="accent"
           (click)="onAccentChange(accent)">
            <span [innerHTML]="accent.name"></span>
        </mat-radio-button>
    </mat-radio-group>

</div>