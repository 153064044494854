import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class GalleryService {
    private imagesUpdatedSource: BehaviorSubject<Array<any>>;
    private imageSelectedIndexUpdatedSource: BehaviorSubject<number>;
    private showImageViewerSource: BehaviorSubject<boolean>;

    constructor() {
        this.imagesUpdatedSource = <BehaviorSubject<Array<any>>>new BehaviorSubject(null)
        this.imageSelectedIndexUpdatedSource = <BehaviorSubject<number>>new BehaviorSubject(null)
        this.showImageViewerSource = <BehaviorSubject<boolean>>new BehaviorSubject(false)
    }

    get imagesUpdated$(): Observable<Array<any>> {
        return this.imagesUpdatedSource.asObservable()
    }
    get imageSelectedIndexUpdated$(): Observable<number> {
        return this.imageSelectedIndexUpdatedSource.asObservable();
    }
    get showImageViewerChanged$(): Observable<boolean> {
        return this.showImageViewerSource.asObservable()
    }

    updateImages(images: Array<any>): void {
        this.imagesUpdatedSource.next(images)
    }

    updateSelectedImageIndex(newIndex: number): void {
        this.imageSelectedIndexUpdatedSource.next(newIndex)
    }

    showImageViewer(show: boolean): void {
        this.showImageViewerSource.next(show)
    }
}