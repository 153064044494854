import { BaseItem, IItemStatus, ITEM_STATUSES } from "../base-item.model";

import { IPanelComponent, PanelComponents } from "@panel-components/panel-components";
import { Project } from "@board/items/project/project";
import { IPanels } from "@rallysite/global-interfaces";
import { IScheduledFolder, DefaultScheduledFolder } from "../scheduled-folder";
import { IScheduledTaskSettings, ScheduledTaskSettings } from "./settings/scheduled-task-settings.class";
import { Notification } from "@board/_components/notification";
import { IEmailPayload } from "@panel-components/email/email-payload.interface";
import { Account } from "@board-accounts/index";

export class ScheduledTaskAttributes {
    Id: string = null;
    AccountId: string = null;
    AssigneeId: string = null;
    ProjectId: string = null;
    GroupId: string = null;
    Name: string = null;
    Status: string = null;
    Settings: IScheduledTaskSettings = null;
    BlocksMetadata: any = null;

    LaunchDate: string = null;
    LaunchTimezone: string = null;
    ScheduledEmail: IEmailPayload = null;

    PushTitle: string = null;
    PushContent: string = null;
    PushLinkLabel: string = null;

    CreateDate: string = null;
    UpdateDate: string = null;
}

export class ScheduledBaseTask extends BaseItem {
    isV2 = false;
    BlocksMetadata: any = null;

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.SCHEDULED_TASK_NAME,
        PanelComponents.SCHEDULED_TASK_URL,
        PanelComponents.SCHEDULED_TASK_LAUNCH_DATE,
        PanelComponents.SETTINGS_SOCIAL_MEDIA,
        PanelComponents.SETTINGS_TASK_TAGGING,
        PanelComponents.SETTINGS_TASK_PUSH_DATE,
    ];
    readonly _type: string = 'scheduled-task';
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.NOTES;
    }

    prevAttributes: ScheduledTaskAttributes;
    attributes: ScheduledTaskAttributes;

    get Id(): string {
        return this.attributes.Id;
    }
    set Id(value: string) {
        this.attributes.Id = value;
    }
    get AccountId(): string {
        return this.attributes.AccountId;
    }
    set AccountId(value: string) {
        this.attributes.AccountId = value;
    }
    get AssigneeId(): string {
        return this.attributes.AssigneeId;
    }
    set AssigneeId(value: string) {
        this.attributes.AssigneeId = value;
    }
    get ProjectId(): string {
        return this.attributes.ProjectId;
    }
    set ProjectId(value: string) {
        this.attributes.ProjectId = value;
    }
    get GroupId(): string {
        return this.attributes.GroupId;
    }
    set GroupId(value: string) {
        this.attributes.GroupId = value;
    }
    get Name(): string {
        return this.attributes.Name;
    }
    set Name(value: string) {
        this.attributes.Name = value;
    }
    get Settings(): any {
        return this.attributes.Settings;
    }
    set Settings(value: any) {
        this.attributes.Settings = new ScheduledTaskSettings(value || []);
    }

    set LaunchDate(value: string) {
        this.attributes.LaunchDate = value;
    }
    get LaunchDate(): string {
        return this.attributes.LaunchDate;
    }

    set LaunchTimezone(value: string) {
        this.attributes.LaunchTimezone = value;
    }
    get LaunchTimezone(): string {
        return this.attributes.LaunchTimezone;
    }

    set ScheduledEmail(value: IEmailPayload) {
        this.attributes.ScheduledEmail = value || <IEmailPayload>{};
    }
    get ScheduledEmail(): IEmailPayload {
        return this.attributes.ScheduledEmail || <IEmailPayload>{};
    }

    
    set PushTitle(value: string) {
        this.attributes.PushTitle = value;
    }
    get PushTitle(): string {
        return this.attributes.PushTitle;
    }

    set PushContent(value: string) {
        this.attributes.PushContent = value;
    }
    get PushContent(): string {
        return this.attributes.PushContent;
    }

    set PushLinkLabel(value: string) {
        this.attributes.PushLinkLabel = value;
    }
    get PushLinkLabel(): string {
        return this.attributes.PushLinkLabel;
    }


    o_Status: IItemStatus;
    set Status(value: string) {
        this.attributes.Status = value;

        for (let iStatus of ITEM_STATUSES) {
            if (iStatus.id === value) {
                this.o_Status = iStatus;
                break;
            }
        }
    }
    get Status(): string {
        return this.attributes.Status;
    }
    get CreateDate(): string {
        return this.attributes.CreateDate;
    }
    set CreateDate(value: string) {
        this.attributes.CreateDate = value;
    }
    get UpdateDate(): string {
        return this.attributes.UpdateDate;
    }
    set UpdateDate(value: string) {
        this.attributes.UpdateDate = value;
    }

}

export class ScheduledTask extends ScheduledBaseTask implements IPanels {
    _notifications: number = 0;
    notifications: Notification[] = []; // Array<string | Notification> 
    get notificationsIds(): Array<string> {
        return this.notifications.filter(n => {
            return typeof n !== 'string' ? !(/^xxxxx.+/.test(n.id)) : true;
        }).map((n: string | Notification) => {
            if (typeof n === 'string') {
                return n;
            } else {
                return n.id;
            }
        });
    }

    _flaggedNotes: number = 0;
    _quickAdd: boolean = false;
    completeMap = [];
    isMarkedComplete(account: Account | string) {
        if (!account){
            return false;
        }
        const accountId = typeof account === 'string' ? account : account.Id;
        const find = this.completeMap.find(cm => cm.AccountId === accountId)
        if (find) {
            return find.complete;
        }
        return false;
    }

    markComplete(accountId: string, value: boolean) {
        const find = this.completeMap.find(cm => cm.AccountId === accountId)
        if (find) {
            find.complete = value;
        } else {
            this.completeMap.push({
                AccountId: accountId,
                complete: value
            });
        }
    }

    _assignments: {
        total: number,
        complete: number
    };

    _folder: IScheduledFolder;
    _prevGroupId: string;
    _state: string;
    _scrollY: number = 0;

    constructor(data: any) {
        super();

        this.attributes = new ScheduledTaskAttributes();
        this.prevAttributes = new ScheduledTaskAttributes();

        for (let prop in data) {
            this[prop] = data[prop];
            this.__clone(prop);
        }

        if (!data['Settings']) {
            this.Settings = null;
        }

        this._prevGroupId = this.GroupId;
        this._assignments || (this._assignments = { total: 0, complete: 0 })
        this.isV2 = !!this.BlocksMetadata;
    }

    settingsOptions({ project, edit, panel }: {
        project: Project,
        edit?: boolean,
        panel?: IPanelComponent
    }) {
        edit = !!edit;
        this._folder || (this._folder = new DefaultScheduledFolder(project, {}));

        return {
            maxWidth: edit ? '1000px' : '600px',
            data: {
                title: (edit ? 'Edit ' : 'Create ') + ('Pending Item' + (this.isV2 ? ' v2' : '')),
                task: this,
                project: project,
                group: this._folder,
                edit: edit,
                panel: panel
            }
        }
    }

    get _defaultPanel() {
        return this.Settings.defaultPanel;
    }

    sideDefaultPanel(allowFn: Function) {
        if (this['contest']) {
            return PanelComponents.CONTEST;
        }

        if (allowFn(this.Settings.sideDefaultPanel.id)) {
            return this.Settings.sideDefaultPanel;
        }
        return PanelComponents.ME;
    }

    toDb() {
        return this.sanitize({
            Id: this.Id,
            ProjectId: this.ProjectId,
            GroupId: this.GroupId,
            Name: this.Name,
            AccountId: this.AccountId,
            Settings: this.Settings.toDb(),
            BlocksMetadata: this.BlocksMetadata,

            Status: this.Status,
            LaunchDate: this.LaunchDate,
            LaunchTimezone: this.LaunchTimezone,
            ScheduledEmail: this.ScheduledEmail,

            PushTitle: this.PushTitle,
            PushContent: this.PushContent,
            PushLinkLabel: this.PushLinkLabel,

            UpdateDate: this.UpdateDate
        }, ['GroupId']);
    }
}
