import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    template: '<ng-content></ng-content>',
})
export class ModalBaseBlockComponent implements OnInit {
    @HostBinding('class.sortable-item') get sortableClass() {
        return !this.preview;
    }
    @HostBinding('class.media-block-item') get blockClass() {
        return !this.preview;
    }

    @Input('preview') _preview: boolean = false;
    get preview() {
        return this._preview !== false;
    }
    @Output() move: EventEmitter<1 | -1> = new EventEmitter();

    constructor(
        protected el: ElementRef,
        protected formBuilder: FormBuilder,
        @Inject(PLATFORM_ID) protected platformId: any,
    ) { }

    onMove(position: 1 | -1) {
        this.move.emit(position);
    }

    ngOnInit() {
    }

}
