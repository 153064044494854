import { ITaskV2AttachmentsBlockContent } from "./attachments-block/attachments-block";
import { TaskV2Image } from "./image-block/image/task-v2-image";

export enum TaskV2BlockType {
    IMAGE = 'image',
    // TIKTOK = 'tiktok',
    // INSTAGRAM = 'instagram',
    VIDEO = 'video',
    TEXT = 'text',
    ATTACHMENTS = 'attachments',
    SURVEY = 'survey',
    EMBED = 'embed'
}

export const TASK_V2_BLOCKS: {
    type: TaskV2BlockType,
    name: string
}[] = [
        { type: TaskV2BlockType.TEXT, name: 'Text Block' },
        { type: TaskV2BlockType.IMAGE, name: 'Image Block' },
        { type: TaskV2BlockType.VIDEO, name: 'Video Block' },
        // { type: TaskV2BlockType.TIKTOK, name: 'TikTok Block' },
        // { type: TaskV2BlockType.INSTAGRAM, name: 'Instagram Block' },
        { type: TaskV2BlockType.SURVEY, name: 'Survey Block' },
        { type: TaskV2BlockType.ATTACHMENTS, name: 'Attachments Block' },
    ]

export const TASK_V2_BLOCK_PREFIX = {
    IMAGE: 'img',
    TEXT: 'txt',
    ATTACHMENTS: 'att',
    VIDEO: 'vid',
    // TIKTOK: 'tkt',
    // INSTAGRAM: 'nst',
    SURVEY: 'sry',
    EMBED: 'emb',
}

export enum TaskV2BlockAction {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete'
}

export type TaskV2BlockContent = string | TaskV2Image | ITaskV2AttachmentsBlockContent;