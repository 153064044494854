import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ISurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { SQOptionsComponent } from '../sq-options.component';

@Component({
  selector: 'sq-options-multiple',
  templateUrl: './sq-options-multiple.component.html',
  styleUrls: ['./sq-options-multiple.component.scss']
})
export class SQOptionsMultipleComponent extends SQOptionsComponent {

  answers: ISurveyQuestionOption[] =  [];

  constructor(
    protected formBuilder: FormBuilder,
  ) {
    super();
  }

  checked(option: ISurveyQuestionOption) {
    return this.answers.find(po => po.Id === option.Id);
  }

  toggleOption(checked, option: ISurveyQuestionOption) {
      if (checked === true) {
        this.answers.push(option);
      } else {
        for (let i = 0; i < this.answers.length; i++) {
          if (this.answers[i].Id === option.Id) {
            this.answers.splice(i, 1);
          }
        }
      }
      this.optionChange.emit(this.answers.length ? this.answers : null);
  }

  bootstrapForm() {
    const answer = this.question.answer();
    let options: ISurveyQuestionOption[] = [];
    if (!answer) {
      options = [];
    } else if (answer instanceof Array) {
      options = answer.slice();
    } else {
      options.push(answer);
    }

    this.answers = options;
  }

}
