import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { fadeIn } from './grid-animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: '[gallery-organizer], gallery-organizer',
    template: `
        <organizer-actions-bar>
            <mgc-stroked-button color="primary" (click)="addFromGallery()">Add From Gallery</mgc-stroked-button>
        </organizer-actions-bar>

        <organizer-media-grid @fadeIn cdkDropList (cdkDropListDropped)="drop($event)" runInsideAngular="false">
            <ng-container *ngIf="!items || (items && !items.length)" cdkDrag>
                <span class="mgc-gallery-info-message">no image, you have to add them from gallery</span>
            </ng-container>
            <ng-container *ngFor="let item of items; let j = index">
                <grid-item class="sortable-item drag-handle mgc-gallery-item"
                    [selected]="selectedById[item.id]" (selectedChange)="onItemSelected($event, j, item)">
                    <div class="figcaption">{{ j + 1 }}</div>
                    <div class="strip">
                        <mgc-icon-button (click)="remove(item)" class="active" icon="board-mat:trash"></mgc-icon-button>
                    </div>
                    <div class="covered-bg" [style.background-image]="'url('+item[quality]+')'"></div>
                </grid-item>
            </ng-container>
        </organizer-media-grid>
    `,
    animations: [fadeIn],
    styleUrls: ['./gallery-organizer.component.scss'],
})
export class GalleryOrganizerComponent implements OnInit {
    @HostBinding('class.mgc-gallery-organizer') organizerClass = true;
    @Input() items: Array<any>;
    @Input('imageQuality') quality: 'small' | 'medium' | 'large' = 'small';

    @Output() itemsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    @Output() onDone: EventEmitter<void> = new EventEmitter<void>();
    @Output() chooseMedia: EventEmitter<void> = new EventEmitter<void>();

    selected: Array<boolean> = [];
    selectedById: Array<boolean> = [];
    itemsSelected = 0;

    constructor(
    ) { }

    done() {
        this.onDone.next();
    }

    onItemSelected(select: boolean, index: number, item) {
        if (select) {
            this.itemsSelected += 1;
        } else {
            this.itemsSelected -= 1;
        }

        this.selected[index] = select;
        this.selectedById[item.id] = select;
    }

    // onAction(actionType: string) {
    //     switch (actionType) {
    //         case 'select-all':
    //             this.selected = this.selected.map(i => true);
    //             this.itemsSelected = this.items.length;
    //             break;
    //         case 'deselect':
    //             this.deselect();
    //             break;
    //         case 'delete-selected':
    //             let items = this.items.filter((item, index) => {
    //                 return !this.selected[index];
    //             })
    //             this.itemsChange.next(items);
    //             this.deselect();
    //             break;
    //     }
    // }

    remove(item) {
        const items = this.items.filter((itm) => {
            return item != itm;
        });
        this.itemsChange.next(items);
    }

    addFromGallery() {
        this.chooseMedia.next();
    }

    private deselect() {
        this.selected = this.selected.map(i => false);
        this.itemsSelected = 0;
    }

    ngOnInit() {
        this.items.forEach((item, i) => this.selected[i] = false);
    }

    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }
}
