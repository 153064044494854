import { NgModule, PipeTransform, Pipe } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SafePipesModule } from "@rallysite/global-pipes";
import { AlertModule } from "@rallysite/components/alert";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ParticipantService } from "@panel-components/participants/participant.service";
import { PanelAccessPipeModule } from "@board/panels/pannel-access.pipe";
import { DatePipesModule } from "@board/_pipes";
import { MgcLoaderModule, MgcButtonModule, MgcDividerModule, MgcFormFieldModule, MgcMenuModule, MgcInformationModule } from "@rallysite/mgc-components";
import { PermissionModule } from "@board/_services/permission/x_permission.module";
import { FocusIndexModule } from "@board/_components/focus.directive";

@Pipe({ name: 'translatePermission' })
export class PermissionTranslatePipe implements PipeTransform {
  constructor(
  ) { }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    for (let permission of ParticipantService.PERMISSION_MAP) {
      if (permission.level === value) {
        return permission.alias;
      }
    }
    return value;
  }
}

@Pipe({ name: 'uiPermission' })
export class UiPermissionPipe implements PipeTransform {
  constructor(
  ) { }

  transform(values: any[]): any {
    if (!values) {
      return values;
    }
    return values.filter(v => v.ui);
  }
}


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatIconModule,
    MatCheckboxModule,

    MgcLoaderModule,
    MgcDividerModule,
    MgcButtonModule,
    MgcMenuModule,
    MgcFormFieldModule,
    MgcInformationModule,

    AlertModule,
    SafePipesModule,
    DatePipesModule,
    PanelAccessPipeModule,
    PermissionModule,

    FocusIndexModule

  ],
  declarations: [
    PermissionTranslatePipe,
    UiPermissionPipe
  ],

  exports: [
    MatIconModule,
    MatCheckboxModule,

    MgcLoaderModule,
    MgcDividerModule,
    MgcButtonModule,
    MgcMenuModule,
    MgcFormFieldModule,
    MgcInformationModule,

    AlertModule,
    SafePipesModule,
    DatePipesModule,
    PanelAccessPipeModule,
    PermissionModule,

    PermissionTranslatePipe,
    UiPermissionPipe,
    FocusIndexModule
  ],
})
export class SharedPanelComponentsModule { }
