import { OnInit, Component } from '@angular/core';

import { BoardViewSettingsService } from '@board/_components/view-settings';
import { PageService } from '@pages/page.service';
import { RESTRICTED_PAGES } from '@pages/pages.enum';
import { TooltipIds } from '@libraries/tooltip/tooltip.enum';

@Component({
  selector: 'modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
  providers: [
    BoardViewSettingsService
  ]
})
export class ModalsComponent implements OnInit {

  public modals = [
    {
      systemName: 'p-level-assessment',
      label: 'Survey Modal',
      enabled: true,
      tooltipId: TooltipIds.MODAL_SURVEY,
    },
    {
      systemName: null,
      label: 'Empty Message Area Help Tips',
      enabled: false,
      tooltipId: TooltipIds.MODAL_EMPTY_MESSAGE_AREA,
    },
    {
      systemName: null,
      label: 'Create Collection Help Tips',
      enabled: false,
      tooltipId: TooltipIds.MODAL_CREATE_COLLECTION_HELP,
    },
    {
      systemName: null,
      label: 'Create Folder Help Tips',
      enabled: false,
      tooltipId: TooltipIds.MODAL_CREATE_FOLDER_HELP,
    },
    {
      systemName: null,
      label: 'Create Personal Sub Account Help Tips',
      enabled: false,
      tooltipId: TooltipIds.MODAL_CREATE_PERSONA_SUB_ACCOUNT_HELP,
    },
    {
      systemName: null,
      label: 'Phone Number',
      enabled: false,
      tooltipId: TooltipIds.MODAL_PHONE_NUMBER,
    },
  ];

  tooltipIds = TooltipIds;

  constructor(
    pageService: PageService
  ) {
    pageService.emit(RESTRICTED_PAGES.MODALS);
  }

  ngOnInit() {

  }

}
