import { NgModule } from '@angular/core';
import { MgcLoaderComponent } from './loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        MatProgressSpinnerModule
    ],
    declarations: [
        MgcLoaderComponent
    ],

    exports: [
        MgcLoaderComponent
    ]
})
export class MgcLoaderModule { }
