<mgc-dialog-sidenav>
  <mgc-dialog-header header class="full-color" [title]="project.Name" (close)="closeDialog('close')">
    <mgc-icon-button no-focus class="w40" suffix-icon (click)="toggleExpand()"
      [icon]="'board-mat:' + (expanded ? 'expand_exit': 'expand_landscape')"></mgc-icon-button>
  </mgc-dialog-header>

  <div sidenav class="item-list">
    <div *ngFor="let item of refList" class="sidenav-item" [class.selected]="refInView===item.refItem" (click)="showContent(item.refItem)">
      <mat-icon [svgIcon]="'board-mat:'+item.icon"></mat-icon>
      <span [innerHTML]="item.name"></span>
    </div>
  </div>

  <mgc-form-field multiline sidenav-mobile>
    <div class="field-wrapper">
      <mat-icon [svgIcon]="'board-mat:'+selectedItem.icon"></mat-icon>
      <mat-select class="mgc-select" [value]="refInView" name="refInView">
        <mat-option class="option-in-dialog" [value]="item.refItem" *ngFor="let item of refList" (click)="showContent(item.refItem)">
          <mat-icon [svgIcon]="'board-mat:'+item.icon"></mat-icon>
          <span [innerHTML]="item.name"></span>
        </mat-option>
      </mat-select>
    </div>
  </mgc-form-field>

  <mgc-dialog-content>
    <mgc-publish-tabs content-header *ngIf="refInView===REFs.PublishComponent && allowUpdateProject"
      [tab]="selectedPublishTab" (tabChange)="publishTabChange($event)"></mgc-publish-tabs>

    <ng-container [ngSwitch]="refInView">
      <project-settings-restricted-users *ngSwitchCase="REFs.ProjectSettingsRestrictedUsersComponent" [data]="data" (onClose)="closeDialog($event)">
      </project-settings-restricted-users>

      <project-settings-name *ngSwitchCase="REFs.ProjectSettingsNameComponent" [data]="data" (onClose)="closeDialog($event)">
      </project-settings-name>

      <project-settings-theme *ngSwitchCase="REFs.ProjectSettingsThemeComponent" [project]="project" (onClose)="closeDialog($event)">
      </project-settings-theme>

      <!-- <project-settings-advanced *ngSwitchCase="REFs.ProjectSettingsAdvancedComponent" [data]="data" (onClose)="closeDialog($event)">
      </project-settings-advanced>
      <project-settings-collection *ngSwitchCase="REFs.ProjectSettingsCollectionComponent" [data]="data" (onClose)="closeDialog($event)">
      </project-settings-collection> -->

      <mgc-participant-list *ngSwitchCase="REFs.ParticipantListComponent" [item]="project"></mgc-participant-list>
      <mgc-publish *ngSwitchCase="REFs.PublishComponent" [item]="project" [tab]="selectedPublishTab"></mgc-publish>
      <mgc-project-statistics *ngSwitchCase="REFs.ProjectStatisticsComponent" [item]="project"></mgc-project-statistics>

      <project-settings-e-message *ngSwitchCase="REFs.ProjectSettingsEMessageComponent" [data]="data" (onClose)="closeDialog($event)">
      </project-settings-e-message>

      <project-tagging *ngSwitchCase="REFs.ProjectTaggingComponent" [project]="project" (onSave)="onSave()"
        (onClose)="closeDialog($event)"></project-tagging>

    </ng-container>
  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.PROJECT_CREATE, serviceAlertClass.PROJECT_UPDATE]"></alert>

  <mgc-dialog-actions *ngIf="refIsSettings">
    <mgc-stroked-button left *ngIf="allowArchive" [processing]="project._state=='remove' && processing" (click)="onDelete($event)" tabindex="-1"
      [disabled]="!ready">Archive</mgc-stroked-button>
    <mgc-raised-button right [processing]="project._state!='remove' && processing" (click)="onSave()" tabindex="2"
      [disabled]="!ready">  <span>Save</span></mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog('cancel')" tabindex="-1" [disabled]="!ready">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

  <mgc-dialog-actions *ngIf="refInView===REFs.PublishComponent && selectedPublishTab===pubTabs.SETTINGS">
    <mgc-raised-button right [processing]="processing" (click)="onSave()"
      tabindex="2" color="primary" [disabled]="!isDirty">Save
    </mgc-raised-button>
  </mgc-dialog-actions>

</mgc-dialog-sidenav>
