<div style="display: none" cdkFocusInitial></div>

<span>Type new post here ...</span>
<mgc-icon-button icon="board-mat:camera_alt" title="Insert photo"></mgc-icon-button>
<mgc-icon-button icon="board-mat:attachment" title="Insert attachment"></mgc-icon-button>

<!-- <mgc-form-field (click)="newNoteModal()">
    <div class="field-wrapper">
        <input tabindex="-1">
        <span class="placeholder">Type new post here ...</span>
    </div>
</mgc-form-field> -->

<!-- 
<ng-container *ngIf="!smallView">
    <button mat-icon-button (click)="newNoteModal()" title="Chat">
        <mat-icon class="cp-icon" svgIcon="board-mat:chat" aria-label="Chat"></mat-icon>
    </button>
    <button mat-icon-button (click)="newNoteModal()" title="Insert photo">
        <mat-icon class="cp-icon" svgIcon="board-mat:camera_alt" aria-label="Insert photo"></mat-icon>
    </button>
    <button mat-icon-button (click)="newNoteModal()" title="Insert attachment">
        <mat-icon class="cp-icon" svgIcon="board-mat:attachment" aria-label="Attachment"></mat-icon>
    </button>
</ng-container> -->