import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { MgcButtonModule } from '@rallysite/mgc-components';
import { ConfirmOverlayComponent } from './confirm-overlay.component';
import { ConfirmOverlayService } from './confirm-overlay.service';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        MgcButtonModule
    ],
    declarations: [
        ConfirmOverlayComponent,
    ],
    providers: [
        ConfirmOverlayService,
    ],
    exports: []
})
export class ConfirmOverlayModule {
}
