<mgc-dialog>

  <mgc-dialog-header [title]="data.title" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>

    <mgc-form-field multiline readonly>
      <div class="field-wrapper">
        <div class="mgc-participant">
          <account-avatar size="40" [account]="participant._account || {}"></account-avatar>
          <span class="info">
            <span class="name" [innerHTML]="participant._account.OwnerFirstName"></span>
            <span class="details">
              <span>{{participant.AccessType | translatePermission}}</span>
            </span>
          </span>
        </div>
      </div>
    </mgc-form-field>


    <ng-container *ngIf="!data.participant._account.SharedContactDetails">
      <mgc-form-field label="Email" readonly>
        <div class="field-wrapper">
          <span [innerHTML]="data.participant.Email"></span>
        </div>
      </mgc-form-field>
    </ng-container>

    <ng-container *ngFor="let detail of data.participant._account.SharedContactDetails">
      <mgc-form-field [label]="detail.name" readonly>
        <div class="field-wrapper">
          <span [innerHTML]="detail.value || '***'"></span>
        </div>
      </mgc-form-field>
    </ng-container>

    <mgc-form-field label="Participant permission level">
      <mat-icon svgIcon="board-mat:perm_identity"></mat-icon>
      <div class="field-wrapper">
        <mat-select [(ngModel)]="participant.AccessType" name="accessType">
          <mat-option *ngFor="let permission of permissionsMap | uiPermission" [value]="permission.level">
            <span [innerHTML]="permission.alias"></span>
          </mat-option>
        </mat-select>
      </div>
    </mgc-form-field>

    <div class="mgc-form-control" *ngIf="participant.resend.show">
      <mgc-information class="{{participant.resend.alert}}">{{participant.resend.message}}</mgc-information>
    </div>

  </mgc-dialog-content>

  <alert [service]="[serviceAlertClass.PARTICIPANT_VIEW, serviceAlertClass.PARTICIPANT_UPDATE]"></alert>


  <mgc-dialog-actions>
    <mgc-stroked-button left [processing]="participant._state===states.RESENDING" tabindex="-1"
      *ngIf="participant.resend.show" (click)="resendInvitation()" [disabled]="participant.resend.done">Resend Email</mgc-stroked-button>

    <mgc-raised-button right [processing]="participant._state===states.PROCESSING" tabindex="2"
      (click)="onSave()">Save</mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()">Cancel</mgc-stroked-button>

  </mgc-dialog-actions>

</mgc-dialog>