
export interface IAccentOption {
    id: string;
    name: string;
    color: string;
    image: string;
}

export const ACCENTS: { [key: string]: IAccentOption } = {
    'warm': { id: 'warm', name: 'Warm Yellow', color: '#f09430', image: 'assets/images/theme_accent/warm_yellow.png' },
    'bright': { id: 'bright', name: 'Bright Blue', color: '#427aff', image: 'assets/images/theme_accent/bright_blue.png' },
    'happy': { id: 'happy', name: 'Happy Pink', color: '#ed3268', image: 'assets/images/theme_accent/happy_pink.png' }
}