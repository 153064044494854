export interface ISurveyQuestionOption {
    Id: number;
    Position: number;
    Text: string;
    Default: boolean;
    JumpTo: string;

    /** Below data is filled after user complete the survey */
    CountOthers?: number;
    Points?: number;

    UserSelection?: boolean;
    /** Only for question that requires a user answer*/
    CorrectAnswer?: string;
    UserAnswer?: string;

    toJSON(): any;
}

export class SurveyQuestionOption implements ISurveyQuestionOption {
    Id: number;
    Position: number;
    Text: string;
    Default: boolean;
    JumpTo: string;

    /** Below data is filled after user complete the survey */
    CountOthers: number;
    Points: number;

    UserSelection: boolean;

    /** Only for question that requires a user answer*/
    CorrectAnswer: string;
    UserAnswer: string;

    constructor(data: ISurveyQuestionOption) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toJSON() {
        return {
            Id: this.Id,
            Text: this.Text,
            Position: this.Position,
            Default: this.Default,
            JumpTo: this.JumpTo,
            //
            CountOthers: this.CountOthers,
            Points: this.Points,
        
            UserSelection: this.UserSelection,
            CorrectAnswer: this.CorrectAnswer,
            UserAnswer: this.UserAnswer
        }
    }
}