<loader d="80" *ngIf="loading || !isBrowser"></loader>

<!-- <p style="position: absolute;top: 10px;  left: 26px;">wh={{ w }} : {{ h }} </p> -->

<p *ngIf="isBrowser && !loading && !surveyFound">No survey found.</p>


  <ng-container *ngIf="ready">
    <div #brandEl *ngIf="survey?._brand && survey.showBrand && !hideBrandAndTitle" class="brand-survey-inner" [class.sr-only]="!isBrowser"
      style="padding: 15px 10px 5px;">
      <div class="mgc-brand bs-card" style="background-color: white; padding: 10px;">
        <brand-avatar size="40" [brand]="survey?._brand"></brand-avatar>
        <span class="info"><span class="name" [innerHTML]="survey?._brand.Name"></span></span>
      </div>
    </div>

    <div (close)="onAuthClose($event)" mgc-auth-inline></div>

    <div class="brand-survey-inner" [class.hide]="hideSurvey" [class.sr-only]="!isBrowser"
      (resized)="onResized($event)">

      <ng-container *ngTemplateOutlet="infoTpl"></ng-container>

      <div class="bs-card">
        <div class="bs-card-header"><span [innerHTML]="'Question '+(questionInViewIndex+1)"></span></div>

        <div class="brand-survey-question" *ngIf="currentQuestion">
          <div class="q-text">
            <b *ngIf="currentQuestion.Required">*</b>
            <span [innerHTML]="currentQuestion.Text"></span>
          </div>
          <div class="q-options">
            <ng-container [ngSwitch]="currentQuestion.Type">
              <sq-options-dropdown #opref *ngSwitchCase="qTypes.DROPDOWN" [question]="currentQuestion"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-dropdown>
              <sq-options-multiple #opref *ngSwitchCase="qTypes.MULTIPLE" [question]="currentQuestion"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-multiple>
              <sq-options-single #opref *ngSwitchCase="qTypes.SINGLE" [question]="currentQuestion"
                [answerIdx]="questionInViewIndex===0?qop:null"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-single>
              <sq-options-string #opref *ngSwitchCase="qTypes.STRING" [question]="currentQuestion"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-string>
              <sq-options-year #opref *ngSwitchCase="qTypes.YEAR" [question]="currentQuestion"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-year>
              <sq-options-number #opref *ngSwitchCase="qTypes.NUMBER" [question]="currentQuestion"
                (optionChange)="onQuestionOptionChange($event)"></sq-options-number>
            </ng-container>
          </div>

          <div class="q-alert" *ngIf="nextSubmited && !allowNext">
            <mat-icon svgIcon="board-mat:info_outline"></mat-icon>
            <span>This is a required question</span>
          </div>
        </div>

        <mgc-divider></mgc-divider>

        <div class="brand-survey-footer">
          <div class="brand-survey-buttons">
            <button type="button" class="brand-survey-button" [disabled]="!hasPrev"
              (click)="prev()"><span>Back</span></button>
            <button type="button" class="brand-survey-button" *ngIf="hasNext"
              (click)="next()"><span>Next</span></button>
            <button *ngIf="!hasNext" class="brand-survey-button finish" (click)="finish()">
              <loader *ngIf="processing" d="22"></loader>
              <span>Finish</span>
            </button>
          </div>
          <survey-progress [max]="questions.length - (viewedQIndexes[questionInViewIndex]-questionInViewIndex)"
            [value]="questionInViewIndex+1"></survey-progress>
        </div>

      </div>
    </div>
  </ng-container>


  <div class="brand-survey-inner bs-survey-end" *ngIf="ready && (surveySaved || alreadyTaken || takenSurveyId)"
    (resized)="onResized($event)">

    <p *ngIf="alreadyTaken && !takenSurveyId">
      You've already done this quiz. Below are the results of the previous taken quiz.
    </p>

    <ng-container *ngTemplateOutlet="infoTpl"></ng-container>

    <div class="bs-card bs-results" *ngIf="survey.HasScore">
      <div class="bs-data">
        <h3>Your Comparative Results</h3>
        <h3>You:
          <mgc-loader diameter="16" strokeWidth="2" *ngIf="loadingResults"></mgc-loader>
          <span *ngIf="!loadingResults">{{ score.userScore }} of {{ score.maxScore }} points / {{ score.percent
            }}%</span>
        </h3>
        <h3 *ngIf="score.takenSurveys && !loadingResults">AVG of {{ score.takenSurveys }} Others:
          <span>{{ score.averageScore }} of {{ score.maxScore }} points / {{ score.percentAvg }}%</span>
        </h3>
      </div>
      <mat-icon class="bar-chart" svgIcon="board-mat:bar-chart"></mat-icon>
    </div>

    <div class="bs-options-statistics">
      <mat-progress-bar *ngIf="loadingResults || processing" mode="indeterminate" [style.height.px]="2">
      </mat-progress-bar>

      <div *ngIf="!loadingResults">
        <div class="mgc-tabs">
          <ng-container *ngFor="let tab of tabs">
            <span #tabEl *ngIf="tabVisible(tab)" class="mgc-tab" [style.height.px]="32" [style.text-transform]="'none'"
              [style.margin.px]="tabPadding" [style.max-width.px]="maxTabWidth" [style.width.%]="100"
              [style.color]="tabActive(tab)?tab.color:'#ffffff'"
              [style.backgroundColor]="tabActive(tab)?tab.bgColor:tab.bgColor+'90'" [style.borderTopLeftRadius.px]="5"
              [style.borderTopRightRadius.px]="5" [class.active]="tabActive(tab)" (click)="changeTab(tab)"
              [innerHTML]="tab.name"></span>
          </ng-container>
          <div class="mgc-link-bar" [style.width.px]="barWidth" [style.backgroundColor]="currentTab?.bgColor"
            [style.left.px]="leftPosition"></div>
        </div>



        <div>
          <ng-container *ngIf="currentTab?.id==='answers'">
            <div class="bs-card" *ngFor="let idx of viewedQIndexes; let i=index">
              <div class="bs-card-header"><span [innerHTML]="'Question '+(i+1)"></span></div>
              <div class="brand-survey-question">
                <div class="q-text">
                  <span [innerHTML]="questions[idx].Text"></span>
                </div>
                <div class="q-options">
                  <div class="q-option-result" [class.selected]="option.UserSelection"
                    *ngFor="let option of questions[idx].Options">
                    <mat-icon *ngIf="option.UserSelection" class="q-icon" svgIcon="board-mat:done"></mat-icon>
                    <div class="option-text">
                        <ng-container *ngIf="option.UserAnswer">
                          {{ option.UserAnswer }}
                        </ng-container>
                        <ng-container *ngIf="!option.UserAnswer && option.Text">
                          <ng-container *ngIf="option.Text == '-user-input-string-'">
                            No Text
                          </ng-container>
                          <ng-container *ngIf="option.Text !== '-user-input-string-'">
                            {{ option.Text }}
                          </ng-container>
                        </ng-container>
                    </div>
                    <div class="statistics {{ option.Points>0?'good':'wrong' }}">
                      <mat-icon svgIcon="board-mat:arrow_right_alt"></mat-icon>
                      <span *ngIf="score.takenSurveys "> {{ (option.CountOthers/score.takenSurveys*100).toFixed(0) }}%
                        of
                        people
                        chose this </span>
                      <span *ngIf="survey.HasScore">&nbsp;|| {{ option.Points }} {{ option.Points === 1 ?
                        'point':'points'
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div id="resultsEl">

            <ng-container *ngIf="haveFeedbacks && currentTab?.id==='feedback'">
              <ng-container *ngFor="let question of questionsWithFeedbacks">
                <div class="bs-card padd bs-section" *ngIf="question.SurveyPgroupId && !!feedbacks['pgroup']">
                  <ng-container *ngFor="let block of feedbacks['pgroup']">
                    <ng-container [ngSwitch]="block.Type">
                      <ui-text-block *ngSwitchCase="blockType.TEXT" preview [block]="block">
                      </ui-text-block>
                      <ui-image-block *ngSwitchCase="blockType.IMAGE" preview [block]="block">
                      </ui-image-block>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="bs-card padd bs-section" *ngIf="!question.SurveyPgroupId">
                  <ng-container *ngFor="let option of question.Options">
                    <ng-container *ngIf="feedbacks[option.Id]">
                      <ng-container *ngFor="let block of feedbacks[option.Id]">
                        <ng-container [ngSwitch]="block.Type">
                          <ui-text-block *ngSwitchCase="blockType.TEXT" preview [block]="block">
                          </ui-text-block>
                          <ui-image-block *ngSwitchCase="blockType.IMAGE" preview [block]="block">
                          </ui-image-block>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  </div>
                </ng-container>
            </ng-container>

          </div>
          <ng-container *ngIf="user && currentTab?.id==='settings'">
            <form [formGroup]="form">
              <div class="bs-section" [style.padding-left.px]="10">
                <mat-slide-toggle class="mgc-toggle" formControlName="favorite" (change)="toggleFavorite($event)">
                  Favorite - Add a link to this survey into my daily reminder emails.
                </mat-slide-toggle>
              </div>
              <p style="font-weight: bold; margin-top: 25px; margin-bottom: 10px">Download only available on desktop:</p>
              <button id="get-pdf" data-html2canvas-ignore (click)="getPdf()">
                <span style="margin-right: 5px;">Download PDF</span>
                <mat-icon *ngIf="!pdfProcessing" svgIcon="board-mat:download"></mat-icon>
                <mat-spinner *ngIf="pdfProcessing" color="'white'" [diameter]="20" [strokeWidth]="3"
                             style="display: inline-block; vertical-align: middle;"></mat-spinner>
              </button>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>



<ng-template #infoTpl>
  <ng-container *ngIf="!hideBrandAndTitle">
    <div *ngIf="survey.showHeroImage && survey.heroImage" class="bs-image">
      <mgc-image preview [image]="survey.heroImage" [options]="{}"></mgc-image>
    </div>

    <div *ngIf="survey.showSurveyInfo" class="brand-survey-info bs-card">
      <h1 [innerHTML]="survey?.PublicName || survey?.Name || 'Survey name'"></h1>
      <h4 [innerHTML]="survey?.Description||''"></h4>
    </div>
  </ng-container>
</ng-template>
