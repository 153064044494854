
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';


interface IRequestCache {
  processing: boolean;
  request: BehaviorSubject<any>;
}

const DEFAULT_KEY: string = 'RC_';

@Injectable({
  providedIn: 'root',
})
export class RequestCacheService {

  private cachedData: { [key: string]: IRequestCache } = {};

  constructor(
  ) {
  }

  inCache(key: string = DEFAULT_KEY): boolean {
    return !!this.cachedData[key] && this.cachedData[key].processing;
  }

  cache(key: string = DEFAULT_KEY) {
    this.cachedData[key] = {
      processing: true,
      request: new BehaviorSubject(null)
    }
  }

  get(key: string = DEFAULT_KEY): Observable<any> {
    if (!this.cachedData[key] || !this.cachedData[key].request) {
      return of(null);
    }
    return this.cachedData[key].request.asObservable();
  }

  done(key: string = DEFAULT_KEY, data: any): void {
    this.cachedData[key].request.next(data);
    delete this.cachedData[key];
    return data;
  }


}