
export type ThemeType = 'light' | 'dark';

export interface ITheme {
    id: string;
    name: string,

    // light or dark theme
    type?: ThemeType; 
    
    // if array there will be two versions of primary for light and dark types
    primaryColor: string | string[],
    navColor?: string | string[],
    properties?: { [key: string]: string };
    // {
    // '--theme-primary-50'?: number[],
    // '--theme-primary-100'?: number[],
    // '--theme-primary-200'?: number[],
    // '--theme-primary-300'?: number[],
    // '--theme-primary-400'?: number[],
    // '--theme-primary-500'?: number[],
    // '--theme-primary-600'?: number[],
    // '--theme-primary-700'?: number[],
    // '--theme-primary-800'?: number[],
    // '--theme-primary-900'?: number[],
    // '--theme-primary-A100'?: number[],
    // '--theme-primary-A200'?: number[],
    // '--theme-primary-A300'?: number[],
    // '--theme-primary-A400'?: number[]
    // '--theme-primary-contrast-50'?: string,
    // '--theme-primary-contrast-100'?: string,
    // '--theme-primary-contrast-200'?: string,
    // '--theme-primary-contrast-300'?: string,
    // '--theme-primary-contrast-400'?: string,
    // '--theme-primary-contrast-500'?: string,
    // '--theme-primary-contrast-600'?: string,
    // '--theme-primary-contrast-700'?: string,
    // '--theme-primary-contrast-800'?: string,
    // '--theme-primary-contrast-900'?: string,
    // '--theme-primary-contrast-A100'?: string,
    // '--theme-primary-contrast-A200'?: string,
    // '--theme-primary-contrast-A400'?: string,
    // '--theme-primary-contrast-A700'?: string,

    // // foreground light
    // '--theme-foreground-text': string,
    // '--theme-foreground-nav'?: string,
    // '--theme-foreground-divider': string,
    // '--theme-foreground-link': string,
    // '--theme-foreground-icon': string,
    // '--theme-foreground-text-icon': string,

    // // background light
    // '--theme-background-base': string
    // '--theme-background-nav'?: string,
    // '--theme-background-form': string,
    // }
}