import { Pipe, PipeTransform, Inject, NgModule } from "@angular/core";
import { APP_CONFIG, AppConfig } from "@rallysite/config";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({ name: 'projectAvatarUrl' })
export class ProjectAvatarUrlPipe implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  transform(avatar: string, t: number = 1): string {
    if (!avatar) {
      return '/assets/images/project-avatar.jpg';
    }
    
    return `${this.config.cdnProject}/${avatar}?t=${t}`;
    // return `${this.config.endpoint}/api/avatar/project/${imageId}?t=${t}`;
  }
}

@Pipe({ name: 'endpoint' })
export class AvatarEndPoint implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }

  transform(id: string): string {
    return `${this.config.endpoint}/api/avatar/project/${id}`;
  }
}

@Pipe({ name: 'blobUrl' })
export class BlobUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(file: any): SafeResourceUrl {
    let url = URL.createObjectURL(file.rawFile);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@NgModule({
  imports: [],
  declarations: [ProjectAvatarUrlPipe, BlobUrlPipe, AvatarEndPoint],
  exports: [ProjectAvatarUrlPipe, BlobUrlPipe, AvatarEndPoint],
})

export class AvatarPipesModule { }