import { IAccount, Account } from "@board-accounts/account.model";

export interface IParticipant {
  Id?: string;
  Email: string;
  ProjectId: string;
  AccessType: string;
  InviterNote: string;
  Status: string;
  Confirmation: 'yes' | 'no' | 'pending';
  ConfirmationNote: string;
  Settings: {
    allowEmailNotifications: boolean
  };
  UpdateDate: string;

  _account?: IAccount;
  _error?: any;

  resend: {
    alert: string,
    type: string,
    show: boolean,
    message: string,
    done?: boolean;
  }

  isOwner(project: any);
}

export class Participant implements IParticipant {
  Id?: string;
  Email: string;
  ProjectId: string;
  AccessType: string;
  InviterNote: string;
  Status: string;
  Confirmation: 'yes' | 'no' | 'pending';
  ConfirmationNote: string;
  Settings: {
    allowEmailNotifications: boolean
  };
  UpdateDate: string;

  _account: IAccount;
  _error: any;

  _state: number;
  _stateRemove: string = '';
  _assign: boolean;

  resend: {
    alert: string,
    type: string,
    show: boolean,
    message: string,
    done?: boolean;
  } = {
      alert: '',
      type: '',
      show: false,
      message: '',
      done: false
    }

  constructor(data: any) {
    for (let prop in data) {
      if (prop.toLowerCase() === 'settings' && data[prop]) {
        this.Settings = {
          allowEmailNotifications: data[prop]['allowEmailNotifications'],
        }
      } else {
        this[prop] = data[prop];
      }
    }

    this.Settings || (this.Settings = {
      allowEmailNotifications: true,
    });

    this.handleResend();
  }

  isOwner(project: any) {
    if (this._account) {
      return this._account.Id === project.AccountId;
    }
    return false
  }

  init() {
    if (!this._account) {
      this._account = new Account({});
    } else if (this._account && this._account.Status === 'deleted') {
      this._account = new Account({ Id: this._account.Id, Status: 'deleted' });
    }
    if (!this._account.OwnerFirstName) {
      this._account.OwnerFirstName = this.Email.split('@')[0];
    }
    this._account.Name = (this._account.Name || '').split('@')[0];
    return this;
  }

  handleResend() {
    if (!this._account || (this._account && !this._account.Id)) {
      this.resend = {
        alert: 'warning',
        type: 'noaccount',
        show: true,
        message: 'This person does not have an active account yet (101). Would you like to resend the invitation?'
      }
    } else if (this._account.Status === 'deleted') {
      this.resend = {
        alert: 'danger',
        type: 'removed-account',
        show: true,
        message: 'This person has deactivated their account for this email address (104). Would you like to resend the invitation?'
      }
    } else if (this.Confirmation === 'no') {
      this.resend = {
        alert: 'danger',
        type: 'declined',
        show: true,
        message: 'This person has declined this project (201). Would you like to resend the invitation?'
      }
    } else if (this.Confirmation === 'pending') {
      this.resend = {
        alert: 'warning',
        type: 'pending',
        show: true,
        message: 'This person has an active account, but has not yet accepted this project (202). Would you like to resend the invitation?'
      }
    } else {
      this.resend = {
        alert: '',
        type: '',
        show: false,
        message: ''
      }
    }
    return this;
  }

  toDb() {
    return {
      Email: this.Email,
      ProjectId: this.ProjectId,
      AccessType: this.AccessType,
      InviterNote: this.InviterNote,
      Status: this.Status,
      UpdateDate: this.UpdateDate,
      _assign: this._assign
    }
  }
}

