
import {finalize, take} from 'rxjs/operators';
import {
    Component, Inject,
    OnInit, OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../file.service';
import { IFile } from '../file-model';


@Component({
    selector: 'pdf-preview',
    templateUrl: './pdf-preview.component.html',
    styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit, OnDestroy {
    file: IFile;
    isDownloading: boolean = false;

    constructor(
        private fileService: FileService,
        public dialogRef: MatDialogRef<PdfPreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            file: IFile,
            previewUrl: string
        }) {
        this.file = data.file;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    download($ev, file: IFile) {
        if ($ev) {
          $ev.stopPropagation();
        }

        if (this.isDownloading) {
          return;
        }

        file.inProgress = true;
        this.fileService.download(file).pipe(
          finalize(() => {
            file.inProgress = false
          }),
          take(1)
        ).subscribe();
      }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
