import { Component, HostBinding, OnInit, Input, Inject, PLATFORM_ID } from "@angular/core";
import { ICustomColorOptions } from "../../theme-options.interface";
// import { ColorEvent } from "ngx-color";
import { ThemingService } from "@libraries/theming/theming.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
    selector: 'mgc-custom-color',
    templateUrl: './custom-color.component.html',
    styleUrls: ['./custom-color.component.scss'],
})
export class CustomColorComponent implements OnInit {
    @HostBinding('class.mgc-custom-color') customColorClass = true;

    @Input() customColorOptions: ICustomColorOptions;

    options: ICustomColorOptions;
    isBrowser: boolean;

    constructor(
        private themingService: ThemingService,
        @Inject(PLATFORM_ID) readonly platformId
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    updatedOptions() {
        return {
            ...this.customColorOptions,
            ...this.options
        }
    }

    changePrimaryComplete($event: any) {
        this.options.primary = $event.color.hex;
        this.themingService.setTheme({ themeId: 'custom_color', custom_color: this.options });
    }

    changeNavigationComplete($event: any) {
        this.options.navigation = $event.color.hex;
        this.themingService.setTheme({ themeId: 'custom_color', custom_color: this.options });
    }

    ngOnInit() {
        this.options = Object.assign({}, this.customColorOptions);
    }
}