<!-- <mgc-form-field label="From" disabled>
  <div class="field-wrapper">
    <input tabindex="-1" [value]="email.fromName + '('+ email.from +')'  " name="email" disabled>
  </div>
</mgc-form-field> -->
<mgc-post-as (ownerAs)="sendAs($event)" label="Send As" [accountId]=email.senderId></mgc-post-as>


<mat-checkbox [(ngModel)]="toAll" (change)="toggleAll()"> To All Subscribers</mat-checkbox>
<mgc-form-field *ngIf="!isScheduledTask">
  <div class="field-wrapper">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let recipient of email.recipients"
        [class.confirmed]="confirmedConfirmation(recipient.confirmation)"
        [class.pending]="pendingConfirmation(recipient.confirmation)" [selectable]="selectable" [removable]="removable"
        (removed)="remove(recipient)">
        {{recipient.address}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input #to matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur" [formControl]="toControl" [matAutocomplete]="auto" type="text" />
      <mat-autocomplete class="mgc-autocomplete" autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="optionSelected($event)" [displayWith]="displayFn">
        <ng-container *ngFor="let participant of filteredParticipants">
          <mat-option [disabled]="!participant.Settings.allowEmailNotifications" (click)="clicked($event)"
            [value]="participant">
            <div class="mgc-account">
              <account-avatar size="30" [account]="participant._account || {}"></account-avatar>
              <span class="info">
                <span class="name" [innerHTML]="participant._account.OwnerFirstName"></span>
                <span class="details">
                  <span class="{{participant.resend.alert}}">{{participant.AccessType | translatePermission}} <span
                      style="color: red" *ngIf="!participant.Settings.allowEmailNotifications">(disabled)</span></span>
                </span>
              </span>
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-chip-list>
  </div>
</mgc-form-field>

<mgc-divider [full-width]="true"></mgc-divider>

<mgc-form-field label="Email Subject">
  <div class="field-wrapper">
    <input #name="ngModel" placeholder="Subject" matInput tabindex="-1" [(ngModel)]="email.subject" name="subject">
  </div>
</mgc-form-field>

<mgc-form-field multiline label="Add Custom Text">
  <div class="field-wrapper">
    <textarea autosize tabindex="-1" startRows="3" name="EmailBody" placeholder="Add your custom text and HTML here"
    [(ngModel)]="email.customBody"></textarea>
  </div>
</mgc-form-field>

<mgc-toggle-section class="email-toggle-section" label="Add Text From Item" option="showNoteBody"
  [obj]="email" (change)="toggleOption($event)">
</mgc-toggle-section>

<div class="text-area" *ngIf="email.showNoteBody">
  <span [innerHTML]="email.body | safe: ['lineBreak', 'linky']"></span>
</div>