import { Component, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'mgc-menu',
    template: `
        <mat-menu #menu="matMenu" class="mgc-menu"  [xPosition]="xPosition" [yPosition]="yPosition">
            <ng-template matMenuContent>
                <ng-content></ng-content>
            </ng-template>
        </mat-menu>
    `,
})
export class MgcMenuComponent {
    @Input() xPosition = 'before';
    @Input() yPosition = 'below';
    // @ts-ignore
  @ViewChild('menu') menu: MatMenu;
}
