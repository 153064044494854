<mgc-dialog>

  <mgc-dialog-header title="Confirm Required" (close)="closeDialog()"></mgc-dialog-header>

  <mgc-dialog-content>

    <div mat-dialog-content class="cp-dialog-content">

      <mat-radio-group name="options" [(ngModel)]="choosedOption">
        <mat-radio-button value="preview">Accept this project</mat-radio-button>

        <mat-radio-button class="start" value="move">Move this Project into
          {{ selectedUmbrella ? selectedUmbrella.Name : 'Collection' }}</mat-radio-button>

        <mgc-form-field *ngIf="choosedOption==='move'">
          <mat-icon svgIcon="board-mat:collection"></mat-icon>
          <div class="field-wrapper">
            <mat-select [(ngModel)]="selectedUmbrella" name="umbrella" [disabled]="choosedOption!=='move'">
              <!-- <mat-option [value]="defaultUmbrella">
                <span [innerHTML]="defaultUmbrella.Name"></span>
              </mat-option> -->
              <mat-option *ngFor="let umbrella of umbrellas" [value]="umbrella">
                <span [innerHTML]="umbrella.Name"></span>
              </mat-option>
            </mat-select>
          </div>
        </mgc-form-field>

      </mat-radio-group>

      <!-- <mgc-form-field label="Drop a note" multiline [style.margin-top.px]="30">
        <div class="field-wrapper">
          <textarea tabindex="-1" rows="4" name="ConfirmationNote"
            [(ngModel)]="participant.ConfirmationNote"></textarea>
        </div>
      </mgc-form-field> -->

    </div>

    <alert [service]="[serviceAlertClass.PARTICIPANT_UPDATE]"></alert>


  </mgc-dialog-content>

  <mgc-dialog-actions>
    <mgc-raised-button right [processing]="state===states.PROCESSING || state===projectStates.PROCESSING"
      (click)="onConfirm('yes')" tabindex="2">
      <span>Proceed</span>
    </mgc-raised-button>
    <mgc-stroked-button right (click)="closeDialog()" tabindex="-1">Cancel</mgc-stroked-button>
  </mgc-dialog-actions>

</mgc-dialog>
