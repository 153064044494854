import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ConfirmOverlayService } from '@board-components/confirm-overlay/confirm-overlay.service';
import { CurrentIdService, PermissionService } from '@board/_services';
import { FileService, IFile } from '@panel-components/notes/attachments';
import { WithAttachmentsComponent } from '@panel-components/notes/with-attachments/with-attachments.component';
import { AuthService } from '@rallysite/auth-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { TaskV2BlocksService } from '../task-v2-blocks.service';
import { TaskV2AttachmentsBlock } from './attachments-block';

@Component({
  selector: 'task-v2-attachments-block',
  templateUrl: './attachments-block.component.html',
  styleUrls: ['./attachments-block.component.scss']
})
export class TaskV2AttachmentsBlockComponent extends WithAttachmentsComponent implements OnInit {
  @HostBinding('class.sortable-item') get sortableClass() {
    return !this.preview;
  }
  @HostBinding('class.media-block-item') get blockClass() {
    return !this.preview;
  }

  @Input() block: TaskV2AttachmentsBlock;
  @Input('preview') _preview: boolean = false;
  get preview() {
    return this._preview !== false;
  }
  @Output() remove: EventEmitter<TaskV2AttachmentsBlock> = new EventEmitter(null);
  @Output() close: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private alive = true;
  attachments: IFile[] = [];

  constructor(
    private authService: AuthService,
    private currentIdService: CurrentIdService,
    protected fileService: FileService,
    protected blockService: TaskV2BlocksService,
    confirmOverlay: ConfirmOverlayService,
    permissionsSettings: PermissionService
  ) {
    super(blockService, fileService, confirmOverlay, permissionsSettings);
  }

  onRemove() {
    this.remove.emit(this.block);
  }

  checkUploaderOptions() {
  }
  saveRequest(): Observable<any> {
    return of(this.block);
  }
  saveDone() {
    // put back files into the object since we used a slice() when getting them
    // this.block.Content.files = this.attachments;
    // this.block.Content.imageOrder = this.attachments.filter(f => f.isImage).map(f => f.Id);
  }
  closeDialog() {
    this.close.next(true);
  }

  protected bootstrap() {
    // create a shallow copy of note attachments
    // on cancel, the note._attachments will not contain the new added ones

    this.model = this.block;

    this.fileService.getFiles$(this.block).pipe(
      filter(files => {
        return !!files;
      }),
      takeWhile(() => this.alive))
      .subscribe(files => {
        this.block.Content.files = files.slice();
        this.attachments = this.block.Content.files
      });
    this.fileService.pushFiles(this.block.Content.files, this.block);

    this.uploaderOptions = {
      autoUpload: false,
      filename: 'file',
      tokenGetter: this.authService.tokenGetter,
      endPoint: this.fileService.endPoint,
      payload: {
        TaskId: this.block.TaskId,
        AccountId: this.currentIdService.currentAccountId
      }
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }


}
