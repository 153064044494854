import { User, ContactDetail, IUserContactDetail } from "@rallysite/user";
import { IAccountSettings, AccountSettings } from "./account.settings.class";
import { IPanelComponent, PanelComponents } from "@panel-components/panel-components";
import { IPanels } from "@rallysite/global-interfaces";
import { IThemeOptions } from "@libraries/theming";
import { IFrequencyValue } from "@board/communication-settings/communications.types";

export interface IAccount {
    Id?: string;
    UserId: string;
    Name: string;
    Email: string;
    Status: string;
    Thumbnail: string;
    Initials: string;
    Color: string;
    Metadata: JSON;
    Settings: IAccountSettings;
    SharedContactDetails: Array<IUserContactDetail>;
    Confirmed?: boolean;
    UpdateDate: string;
    _notifications: number;

    OwnerFirstName?: string;
    OwnerLastName?: string;

    _timestamp?: number;
    _refreshFlaggedNotes: boolean;

    // <<
    _unsubscribed: boolean;
    _allowDirectEmails: boolean;
    _emailRemindersFrequency: IFrequencyValue;
    _emailRemindersDays: string;
    // >>

    crossUserEmail?: string;
}

export class Account implements IAccount, IPanels {
    Id: string;
    UserId: string;
    Name: string;
    Email: string;
    Status: string;
    Thumbnail: string;
    Initials: string;
    Color: string;

    crossUserEmail?: string;

    private _metadata: JSON;
    set Metadata(value: JSON) {
        this._metadata = value || JSON.parse('[]');
    }
    get Metadata(): JSON {
        return this._metadata;
    }

    private _settings: IAccountSettings;
    set Settings(value: IAccountSettings) {
        this._settings = value instanceof AccountSettings ? value : new AccountSettings(value || {});
    }
    get Settings(): IAccountSettings {
        return this._settings;
    }

    SharedContactDetails: Array<IUserContactDetail>;
    Confirmed: boolean;
    UpdateDate: string;
    _notifications: number;

    OwnerFirstName: string;
    OwnerLastName: string;

    _selectedDetailsIds: Array<string>;
    _type: string = 'account';
    _timestamp: number;

    _breadcrumbName: string = "";
    _refreshFlaggedNotes: boolean = false;

    // <<
    _unsubscribed: boolean = false
    _allowDirectEmails: boolean;
    _emailRemindersFrequency: IFrequencyValue = null;
    _emailRemindersDays: string = '1111111';
    // >>

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.DASHBOARD,
    ];
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    sideDefaultPanel(allowFn: Function) {
        return PanelComponents.DASHBOARD;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.EMPTY;
    }

    set theme(data: IThemeOptions) {
        this.Settings.theme = data;
    }
    get theme(): IThemeOptions {
        return this.Settings.theme;
    }

    constructor(data: any, private user?: User) {
        this.bootstrap(data);
    }

    bootstrap(data) {
        for (let prop in data) {
            if (prop.toLowerCase() === 'sharedcontactdetails' && this.user) {
                this._selectedDetailsIds = data[prop];
                this.handleSharedContactDetails(this.user.ContactDetails);
                continue;
            }
            this[prop] = data[prop];
        }
        this._timestamp = Math.random();
        this._breadcrumbName = (this.Name ? this.Name.charAt(0).toUpperCase() : '') + 'Groups';
        // if (this._emailRemindersFrequency === 'weekly') {
        //     this._emailRemindersFrequency = 'daily';
        //     this._emailRemindersDays = '1000000';
        // }
    }

    handleSharedContactDetails(userDetails: Array<IUserContactDetail>): Array<IUserContactDetail> {
        let completeDetails: Array<IUserContactDetail> = [];

        let emailDetail: IUserContactDetail = new ContactDetail({
            id: 'email',
            type: 'email',
            name: 'Email',
            value: this.Email,
            _checked: true
        });

        if (!this._selectedDetailsIds) {
            this.SharedContactDetails = userDetails.map(d => {
                let cd = new ContactDetail(d);
                cd._checked = true;
                return cd;
            });
        } else {
            this.SharedContactDetails = userDetails.map(d => {
                let cd = new ContactDetail(d);
                cd._checked = !!this._selectedDetailsIds.find(id => cd.id === id);
                return cd;
            })
            emailDetail._checked = !!this._selectedDetailsIds.find(id => 'email' === id)
        }
        this.SharedContactDetails.splice(0, 0, emailDetail);

        return this.SharedContactDetails;
    }

    increaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications + val;
    }
    decreaseNotificationCounter(val: number = 1) {
        this._notifications = this._notifications - val;
    }

    settingsOptions(user: User, edit: boolean = false) {
        edit = !!edit;
        return {
            autoFocus: false,
            width: '70%',
            maxWidth: '800px',
            minWidth: '400px',
            disableClose: true,
            data: {
                title: (edit ? 'Edit ' : 'Add ') + 'Account',
                account: this,
                user: user,
                edit: edit
            }
        }
    }

    __update(data) {
        this.bootstrap(data);
        return this;
    }

    toDb() {
        return {
            Id: this.Id,
            UserId: this.UserId,
            Name: this.Name,
            Email: this.Email,
            Status: this.Status,
            Thumbnail: this.Thumbnail,
            Initials: this.Initials,
            Color: this.Color,
            Metadata: this.Metadata,
            SharedContactDetails: this.SharedContactDetails.filter(d => d._checked).map(d => d.id),
            UpdateDate: this.UpdateDate
        }
    }

}