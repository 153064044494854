<form [formGroup]="form">
    <div *ngIf="phoneStep" class="content">
        <p><b>Would you like to receive occasional text messages?</b></p>
        <p>Enter your phone number below to receive occasional special messages.</p>

        <mgc-form-field label="Phone Number" [invalid]="submitted && f.Phone.errors">
            <div class="field-wrapper">
              <phone-input-v2 formControlName="Phone" defaultCountry="US"></phone-input-v2>
            </div>

            <ng-container invalid-feedback *ngIf="submitted && f.Phone.errors">
              <div *ngIf="f.Phone.hasError('format')"> Phone number is <strong> invalid</strong></div>
              <div *ngIf="f.Phone.hasError('required')"> Phone number is <strong> required</strong></div>
            </ng-container>
          </mgc-form-field>

        <mat-radio-group class="mgc-radio-group" name="showAgain" formControlName="showAgain">
            <mat-radio-button class="mgc-radio-button" value="later" (click)="showAgainChanged($event, 'later')">
                <span>Maybe Later ...</span>
            </mat-radio-button>
            <mat-radio-button class="mgc-radio-button" value="never" (click)="showAgainChanged($event, 'never')">
                <span style="color:#ce5757;">Do not show me this again</span>
            </mat-radio-button>
        </mat-radio-group>

        <alert></alert>
    </div>
</form>

<div *ngIf="doneStep" class="content">
    <alert></alert>
</div>
