import {
  Component, OnInit, Input, TemplateRef, ViewChild
} from '@angular/core';


import { Project, } from '@board/items/project/project';
import { ProjectService } from '@board/items/project/project.service';
import {
  PublishLandingPageComponent,
  PublishFotamecoComponent,
  PublishSettingsComponent
} from './sections';
import { take, finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Brand } from '@board-brands/brand.model';

@Component({
  selector: 'mgc-publish-edit',
  templateUrl: './publish-edit.component.html',
})
export class PublishEditComponent implements OnInit {
  @Input() project: Project;
  @Input() pubdata: any;
  @Input() brands: Brand[];

  @ViewChild(PublishLandingPageComponent) pageComponent: PublishLandingPageComponent;
  @ViewChild(PublishFotamecoComponent) fotamecoComponent: PublishFotamecoComponent;
  @ViewChild(PublishSettingsComponent) settingsComponent: PublishSettingsComponent;

  get pubComponents() {
    return [this.pageComponent, this.fotamecoComponent, this.settingsComponent]
  }

  /** Accordion DATA */
  accordion: {
    page: boolean,
    fotameco: boolean,
    settings: boolean
  } = {
      page: false,
      fotameco: false,
      settings: false,
    }

  panels = [{
    id: 'settings', title: 'Publish Live Settings'
  }, {
    id: 'page', title: 'Landing Page'
  }, {
    id: 'fotameco', title: 'Webpage Embed Settings'
  },];

  caret(prop: string) {
    return 'board-mat:' + (this.accordion[prop] ? 'keyboard_arrow_up' : 'keyboard_arrow_down');
  }

  togglePanel(prop: string, value: boolean) {
    this.accordion[prop] = value;
  }
  /** END Accordion DATA */

  state: number;
  get states() {
    return ProjectService.STATES;
  }

  get processing() {
    return this.state === this.states.PROCESSING;
  }

  constructor(
    private projectService: ProjectService
  ) {
  }

  get isDirty() {
    return this.pageComponent.isDirty ||
      this.fotamecoComponent.isDirty ||
      this.settingsComponent.isDirty
  }

  onSave(): void {
    if (this.processing || !this.isDirty) {
      return;
    }

    if (this.pubComponents.find(c => !c.submitForm())) {
      return;
    }

    this.state = this.states.PROCESSING;
    if (this.pageComponent.projectLibrary.updateImageLibraryMetadata) {
      this.pageComponent.projectLibrary.updateImageLibraryMetadata.pipe(take(1)).subscribe(allgood => {
        if (allgood) {
          this.savePubSettings();
        }
      });
    } else {
      this.savePubSettings();
    }
  }

  recursiveAssign(a, b) {
    if (Object(b) !== b) return b;
    if (Object(a) !== a) a = {};
    for (let key in b) {
      a[key] = this.recursiveAssign(a[key], b[key]);
    }
    return a;
  }

  savePubSettings() {

    let data: any = {};
    this.pubComponents.forEach(c => {
      let values = c.formValues();
      data = this.recursiveAssign(data, values);
    })

    const publish = { ...this.project.Settings.publish, ...data.Settings.publish };
    data.Settings.publish = publish;
    Object.assign(this.project, data);

    // SAVING
    let requests = [
      this.projectService.updatePublishSettings(this.project)
    ];

    this.pubComponents.forEach(c => {
      let req = c.requests();
      if (req.length) {
        requests = requests.concat(req);
      }
    });

    forkJoin(requests).pipe(
      finalize(() => this.state = this.states.DONE))
      .subscribe(vals => {
        if (vals.every(v => !!v)) {
          this.pubComponents.forEach(c => c.markFormPristine());
        }

        if (this.settingsComponent.promoSection.dirty) {
          this.settingsComponent.promoSection.save().subscribe();
        }
      });
  }

  onCancel(): void {
    this.project.__revert();
  }


  ngOnInit() {
  }

}
