import { NgModule, ModuleWithProviders } from '@angular/core';
import { WithAttachmentsComponent } from './with-attachments.component';
import { ModelService } from './model.service';


@NgModule({
    imports: [
    ],
    declarations: [
        WithAttachmentsComponent,
    ],
    exports: [
        WithAttachmentsComponent
    ]
})
export class WithAttachmentsModule {

    public static withDependencies(service: any): ModuleWithProviders<WithAttachmentsModule> {
        return {
            ngModule: WithAttachmentsModule,
            providers: [
                { provide: ModelService, useClass: service }
            ]
        }
    }
}