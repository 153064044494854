import { ComponentRef, ElementRef, Injectable, Injector } from "@angular/core";
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  OverlayConfig,
} from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { TooltipOverlayRef } from "./tooltip-overlay-ref";
import { TooltipOverlayComponent } from "./tooltip-overlay.component";
import { ITooltip } from "../tooltip.model";

@Injectable()
export class TooltipOverlayService {
  private overlayRef: OverlayRef;
  private elementRef: ElementRef;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {}

  open(
    el: any,
    tooltip: ITooltip & { url?: string },
    defaultConfig: OverlayConfig = {}
  ) {
    this.elementRef = el;
    const overlayConfig = this.getOverlayConfig(defaultConfig);

    this.overlayRef = this.overlay.create(overlayConfig);

    // Instantiate remote control
    const dialogRef = new TooltipOverlayRef(this.overlayRef);
    const injector = this.createInjector(dialogRef);

    const tooltipRef: ComponentRef<TooltipOverlayComponent> =
      this.overlayRef.attach(
        new ComponentPortal(TooltipOverlayComponent, null, injector)
      );
    tooltipRef.instance.tooltip = tooltip;

    // Subscribe to a stream that emits when the backdrop was clicked
    this.overlayRef.backdropClick().subscribe((_) => dialogRef.close(false));

    return dialogRef;
  }

  private createInjector(dialogRef: TooltipOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(TooltipOverlayRef, dialogRef);

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(defaultConfig: OverlayConfig = {}): OverlayConfig {

    console.log({elementRef: this.elementRef});
    
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .setOrigin(this.elementRef)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: "start",
          originY: "top",
          overlayX: "start",
          overlayY: "bottom",
          offsetY: 5,
          offsetX: 0,
        },
      ]);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: "mgc-overlay-backdrop",
      panelClass: "tooltip-overlay",
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      minWidth: "200px",
      maxWidth: "400px",
      minHeight: "100px",
      maxHeight: "600px",
      ...defaultConfig,
    });

    return overlayConfig;
  }
}
