import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PostAsComponent } from './post-as.component';
import { MgcLoaderModule, MgcFormFieldModule } from '@rallysite/mgc-components';
import { MatSelectModule } from '@angular/material/select';
import { AccountAvatarModule } from '@board-accounts/_components/account-avatar';

@NgModule({
    imports: [
        CommonModule,

        MgcLoaderModule,
        MatSelectModule,
        AccountAvatarModule,
        MgcFormFieldModule
    ],
    declarations: [
        PostAsComponent
    ],
    exports: [
        PostAsComponent
    ],
})
export class PostAsModule { }
