import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MgcButtonModule, MgcDialogModule, MgcDividerModule, MgcFormFieldModule, MgcInformationModule } from '@rallysite/mgc-components';
import { AlertModule } from '@rallysite/components/alert';
import { PageAuthComponent } from './page-auth.component';
import { PageAuthDirective } from './page-auth.directive';
import { PageSignInComponent } from './page-sign-in/page-sign-in.component';
import { PageSignUpComponent } from './page-sign-up/page-sign-up.component';
import { PageCoRegisterComponent } from './page-co-register/page-co-register.component';
import { PageEmailCheckComponent } from './page-email-check/page-email-check.component';
import { PageAuthModal } from './modal-page-auth/page-auth.modal';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MgcButtonModule,
        MgcFormFieldModule,
        MgcDividerModule,
        MgcDialogModule,
        MgcInformationModule,
        AlertModule,
    ],
    declarations: [
        PageAuthComponent,
        PageSignInComponent,
        PageSignUpComponent,
        PageCoRegisterComponent,
        PageEmailCheckComponent,
        PageAuthDirective,
        PageAuthModal
    ],
    exports: [
        PageAuthComponent,
        PageEmailCheckComponent,
        PageAuthDirective
    ]
})
export class PageAuthModule { }
