import { UIImageBlock } from "./image-block/image-block";
import { UITextBlock } from "./text-block/text-block";
import { UIBlockType } from "./types";
import { IUIBlock } from "./ui-block.interface";

export const uiBlockFactory = (data: IUIBlock) => {
    switch (data.Type) {
        case UIBlockType.TEXT:
            return new UITextBlock(data);
        case UIBlockType.IMAGE:
            return new UIImageBlock(data);
        // case UIBlockType.VIDEO:
        //     return new SubscriptionsBlock(data);
        // case UIBlockType.ATTACHMENTS:
        //     return new CustomBlock(data);
        default:
            return null;
    }
}