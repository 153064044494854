import { Component, OnInit } from '@angular/core';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';

@Component({
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  constructor(
    private messageBus: MessageBusService
  ) {
  }

  ngOnInit() {
    this.messageBus.publish(MessageBusActions.APP_LOADED);
  }
}
