import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISurveyQuestionOption } from '@libraries/surveys/models/survey-question-option.model';
import { SQOptionsComponent } from '../sq-options.component';

@Component({
  selector: 'sq-options-year',
  templateUrl: './sq-options-year.component.html',
  styleUrls: ['./sq-options-year.component.scss']
})
export class SQOptionsYearComponent extends SQOptionsComponent {

  constructor(
    protected formBuilder: FormBuilder,
  ) {
    super();
  }

  get f() { return this.form.controls; }

  option: ISurveyQuestionOption;

  /** FORM */
  form: FormGroup;
  submitted = false;
  bootstrapForm() {
    this.option = <ISurveyQuestionOption>this.question.answer();
    const validators = [Validators.pattern('^(2|1){1}[0-9]{3}$')];
    if (this.question.Required) {
      validators.push(Validators.required);
    }
    this.form = this.formBuilder.group({
      UserAnswer: [this.option.UserAnswer, validators],
    });
    this.formSubscriptions.push(
      this.form.get('UserAnswer').valueChanges.subscribe(
        (userAnswer: string) => {
          this.option.UserAnswer = userAnswer;
          this.optionChange.emit(this.option.Id ? this.option : null);
        }
      )
    );
  }
  submit() {
    this.submitted = true;

    if (this.form.invalid) {
      return false;
    }

    return true;
  }
  /** end of FORM */

}
