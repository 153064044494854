import { Component, HostBinding } from "@angular/core";

@Component({
    selector: 'mgc-dialog-sidenav',
    template: `
        <div class="dialog-header">
            <ng-content select="[header]"></ng-content>
        </div>

        <div class="dialog-sidenav-mobile">
            <ng-content select="[sidenav-mobile]"></ng-content>
        </div>

        <div class="dialog-sidenav-wrapper">
            <div class="dialog-sidenav">
                <ng-content select="[sidenav]"></ng-content>
            </div>
            <div class="dialog-content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./dialog-sidenav.component.scss']
})
export class MgcDialogSidenavComponent {
    @HostBinding('class') setClass = "mgc-dialog with-sidenav";
}
