import { VideoUrlType, VIDEO_TYPES } from "./url-types"

const playerRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:.+embed\.html.+ply_id=)([\w-]*)(?:.+)?)/
const vimeoRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:vimeo\.com\/)([\w-]*))/
const youtubeRegexr = /((?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:(?:youtube\.[^<>\]]+?(?:(?:\/embed\/)|(?:\/watch\?v=)|(?:\/v\/))+?)|(?:youtu\.be\/))([\w-]*))/

// https://www.tiktok.com/@christine_andrew/video/6798704164827450629
const tiktokRegexr = /(?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:tiktok\.com)(?:.*video)\/?(.+)/

//https://www.instagram.com/reel/Ccu62wmlKGF/?utm_source=ig_web_copy_link
const instagramRegexr = /(?:(?:https?:\/\/(?:www\.)?)|www\.)?(?:instagram\.com)(?:(?:\/p\/)|(?:\/reel\/)*)([\w-]*)/

export interface IPlayerParams {
    projectId?: string;
    taskId?: string,
    pgr?: string,
}

const isURL = function (str) {
    let pattern = new RegExp('^(https?:\\/\\/)' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?', 'i');
    return pattern.test(str);
}

const parseQuery = function (queryString) {
    let query = {}, a = queryString.substr(1).split('&');
    for (let i = 0; i < a.length; i += 1) {
        const keyValue = a[i].split('=');
        query[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1] || '');
    }
    return query;
}

const iFrameResponsive = '<iframe src="{{IFRAME_URL}}" style="border:0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe>';

const convertToYoutube = (url: string) => {
    let parser = document.createElement('a');
    parser.href = url;

    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'youtube.com':
        case 'www.youtube.com':
        case 'youtu.be':
        case 'www.youtu.be':
            let query: any = parser.search
                ? parseQuery(parser.search)
                : { v: parser.pathname.substr(1) };

            if (!query.v) {
                return url;
            }

            let iframeUrl = `${protocol}//www.youtube.com/embed/${query.v}`;
            return iFrameResponsive.replace(/{{IFRAME_URL}}/, iframeUrl);
    }
    return url;
}

const convertToVimeo = (url: string) => {
    let parser = document.createElement('a'),
        pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    parser.href = url;
    let protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'www.vimeo.com':
        case 'vimeo.com':
            if (!pattern.test(url)) {
                return url;
            }

            let iframeUrl = `${protocol}//player.vimeo.com/video/$1`;
            return url.replace(pattern, iFrameResponsive.replace(/{{IFRAME_URL}}/, iframeUrl));
    }
    return url;
}

const convertToPlayer = function (url: string, data: IPlayerParams) {
    if (playerRegexr.test(url)) {
        // both or none
        if (data.projectId && data.taskId) {
            const qs = `plan_id=${data.projectId}&task_id=${data.taskId}&pgr=${data.pgr}`;

            const qIndex = url.indexOf('?');
            if (qIndex === -1) {
                url += `?${qs}`;
            } else {
                url += `&${qs}`;
            }
        }

        const iframeUrl = `${url}`;
        return iFrameResponsive.replace(/{{IFRAME_URL}}/, iframeUrl);
    }

    return url;
}

/**
 * @param url = https://www.tiktok.com/@christine_andrew/video/6798704164827450629
 * @returns
 */
const convertToTikTok = function (url: string) {
    const parser = document.createElement('a');
    parser.href = url;
    const protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'tiktok.com':
        case 'www.tiktok.com':
            if (!tiktokRegexr.test(url)) {
                return url;
            }

            const iframeUrl = `${protocol}//www.tiktok.com/embed/v2/$1`;
            return url.replace(tiktokRegexr, iFrameResponsive.replace(/{{IFRAME_URL}}/, iframeUrl));
    }
    return url;
};

/**
 * @param url = https://www.instagram.com/reel/Ccu62wmlKGF/?utm_source=ig_web_copy_link
 * @returns
 */
const convertToInstagram = function (url: string) {
    const parser = document.createElement('a');
    parser.href = url;
    const protocol = parser.protocol || '';
    switch (parser.hostname) {
        case 'instagram.com':
        case 'www.instagram.com':
            if (!instagramRegexr.test(url)) {
                return url;
            }

            const iframeUrl = `${protocol}//www.instagram.com/reel/$1/embed/`;
            return url.replace(instagramRegexr, iFrameResponsive.replace(/{{IFRAME_URL}}/, iframeUrl));
    }
    return url;
};



export const urlToPlayeriFrame = (url: string, data: IPlayerParams = {}) => {
    if (!isURL(url)) {
        return url;
    }

    switch (true) {
        case youtubeRegexr.test(url):
            return convertToYoutube(url);
        case vimeoRegexr.test(url):
            return convertToVimeo(url);
        case playerRegexr.test(url):
            return convertToPlayer(url, data);
        case tiktokRegexr.test(url):
            return convertToTikTok(url);
        case instagramRegexr.test(url):
            return convertToInstagram(url);
    }

    return '';
}

export const urlToSurveyiFrame = (url: string, data: any = {}) => {
    if (!isURL(url)) {
        return url;
    }

    const iFrameId = 'sry-' + Math.round(Math.random() * 10000);
    const script = document.createElement('script');
    script.innerHTML = `
        window.addEventListener('message', function(event) {
            if (!event || !event.data) return;
            const d = event.data;
            if (d.sry_h) {
                const ifrm = document.getElementById('${iFrameId}');
                if (ifrm && ifrm.style) {
                    ifrm.style.height = d.sry_h + 'px';
                }
            }
        });
    `;
    if (data.el) {
        data.el.nativeElement.appendChild(script);
    }

    return `<iframe id="${iFrameId}" name="${iFrameId}" src="${url}" style="border:0; width:100%; min-height:400px;transition: height 0.3s ease 0s;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen" allowfullscreen></iframe>`;
}

export const isTallFrame = (url: string): boolean => {
    switch (true) {
        case tiktokRegexr.test(url):
        case instagramRegexr.test(url):
            return true;
    }

    return false;
}

export const urlType = (url: string): VIDEO_TYPES => {
    switch (true) {
        case tiktokRegexr.test(url):
            return VIDEO_TYPES.TIKTOK;
        case instagramRegexr.test(url):
            return VIDEO_TYPES.INSTAGRAM;
        case playerRegexr.test(url):
            return VIDEO_TYPES.PLAYER;
        case youtubeRegexr.test(url):
            return VIDEO_TYPES.YOUTUBE;
        case vimeoRegexr.test(url):
            return VIDEO_TYPES.VIMEO;
    }
    return null;
}
