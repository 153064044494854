import { Pipe, PipeTransform, Inject, NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfig, APP_CONFIG } from '@rallysite/config';

@Pipe({ name: 'navigationImage' })
export class NavigationImagePipe implements PipeTransform {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sanitizer: DomSanitizer
  ) { }

  transform(figureId: string, type: string = 'small', justUrl: boolean = false): SafeResourceUrl {
    if (figureId === '__default') {
      return '/assets/images/default_stock_5.jpg'
    }

    let url = this.config.endpoint + '/api/figures/' + figureId + '/url/' + type;
    return justUrl ? url : this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
  declarations: [
    NavigationImagePipe
  ],
  exports: [
    NavigationImagePipe,
  ],
  providers: [
    NavigationImagePipe
  ]
})
export class NavigationPipeModule { }