import { APP_ID, Injectable, NgModule, APP_INITIALIZER, ErrorHandler  } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HttpClientModule} from '@angular/common/http';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

/* App Root */
import {AppComponent} from './app.component';
import {APP_DI_CONFIG, AppConfigModule} from '@rallysite/config';
import {AuthService, AuthServiceModule, ICurrentIdService, IUser, IUserService} from './auth-service';
import {UserModule, UserService} from './user';

/* Routing Module */
import {AppRoutingModule} from './app-routing.module';
import {Observable} from 'rxjs';
import {CurrentIdService} from '@board/_services/current-id.service';
import {MobileJsBridgeService, WINDOW_PROVIDERS} from './_services';
import {SafePipesModule} from './_pipes';
import {ITokenHelper, SocketIoConfig, SocketIoModule} from '@rallysite/socket-io';
import {AboutComponent} from './legal/about/about.component';
import {RedirectModal} from '@board/items/project/modal/redirect-modal';
import {MgcDialogModule} from '@rallysite/mgc-components';
import {MgcButtonModule} from '@rallysite/mgc-components';
import {ThemingModule} from '@libraries/theming/theming.module';
import {
  customColorTheme,
  customImageTheme,
  indianaRedTheme,
  monacoBlueTheme,
  orangeColorTheme,
  publicColorTheme,
  sunsetOrangeTheme,
  tonalGrayTheme
} from '@libraries/theming/themes/predefined.themes';
import {SurveyModalModule} from '@libraries/surveys/survey-modal/survey-modal.module';
import {AssessmentModalModule} from '@libraries/surveys/__spiritual-assessment/assessment-modal.module';
import {PopupModule} from '@board-components/popup/x_popup.module';
import {GlobalModalsModule} from './global-modals/global-modals.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@Injectable()
export class OverridingUserService implements IUserService {

  constructor(
    private uService: UserService,
    private mobileJsService: MobileJsBridgeService
  ) { }

  loadUser(): Observable<IUser> {
    return this.uService.loadUser();
  }
  signOut() {
    this.mobileJsService.signOut();
  }
  removeUser() {
    this.uService.removeUser();
  }

  isAdmin$(): Observable<boolean> {
    return this.uService.isAdmin();
  }

  allowScheduledEmails$(): Observable<boolean> {
    return this.uService.allowScheduledEmails();
  }
  canAccessEmailsDashboard$(): Observable<boolean> {
    return this.uService.canAccessEmailsDashboard();
  }

  get user$(): Observable<IUser> {
    return <Observable<IUser>>this.uService.user$;
  }
}

@Injectable()
export class OverridingCurrentIdService implements ICurrentIdService {

  constructor(
    private currentIdService: CurrentIdService,
  ) { }

  get currentAccountId(): string {
    return this.currentIdService.currentAccountId;
  }
  get currentParticipantId(): string {
    return this.currentIdService.currentParticipantId;
  }
}

@Injectable()
export class TokenHelperService implements ITokenHelper {
  constructor(
    private authService: AuthService,
  ) { }

  setSocketId(value: string) {
    this.authService.setSocketId(value);
  }
  getAuthToken() {
    return this.authService.tokenGetter();
  }
}


@NgModule({
    imports: [
        DragDropModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppConfigModule,
        AppRoutingModule,
        MgcDialogModule,
        MgcButtonModule,
        UserModule.forRoot(),
        AuthServiceModule.forRoot({
            uService: OverridingUserService,
            currentIdService: OverridingCurrentIdService
        }),
        SocketIoModule.forRoot(<SocketIoConfig>{
            url: APP_DI_CONFIG.socketUrl || (APP_DI_CONFIG.endpoint + '' + APP_DI_CONFIG.socketPort),
            tokenHelperService: TokenHelperService
        }),
        ThemingModule.forRoot({
            themes: [
                monacoBlueTheme,
                sunsetOrangeTheme,
                indianaRedTheme,
                tonalGrayTheme,
                customColorTheme,
                publicColorTheme,
                customImageTheme,
                orangeColorTheme
            ],
            active: customImageTheme
        }),
        SafePipesModule,
        SurveyModalModule,
        AssessmentModalModule,
        PopupModule,
        GlobalModalsModule,
        HammerModule,
    ],
    declarations: [
        AppComponent,
        AboutComponent,
        RedirectModal,
    ],
  providers: [
    WINDOW_PROVIDERS,
    {provide: APP_ID, useValue: 'rallysite-platform'},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(
        {
          showDialog: false,
        }
      ),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
