import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  AfterViewChecked,
  Renderer2,
} from '@angular/core';
import { GalleryService } from '../services/gallery.service';
import { takeWhile, take, map } from 'rxjs/operators';
import { Observable, fromEvent, forkJoin } from 'rxjs';
import { ISize, SIZES } from '../size.class';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GalleryImage } from '../gallery-image.model';
import { ViewerOverlayService } from '../viewer/viewer-overlay.service';
import { BoardViewService } from '@board/_services';
import { IViewerData } from '@libraries/gallery/viewer/viewer-overlay.data';

@Component({
    selector: 'gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    providers: [GalleryService],
    animations: [
        trigger('galleryImageTransition', [
            state('true', style({
                opacity: 1
            })),
            state('void', style({
                opacity: 0
            })),
            transition('void => *', [
                style({
                    opacity: 0
                }),
                animate('300ms ease-in')]
            ),
            transition('* => void', [
                style({
                    opacity: 1
                }),
                animate('300ms ease-out')]
            )
        ]),
    ]
})
export class GalleryComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {


    constructor(
        public galleryService: GalleryService,
        private renderer: Renderer2,
        private viewerOverlay: ViewerOverlayService,
        private boardView: BoardViewService
    ) {
    }

    private get scrollDelta() {
        return Math.max(450, this.getGalleryContainerWidth() / 2);
    }
    private alive = true;
    gallery: Array<any> = [];
    images: Array<any> = [];

    rowIndex = 0;
    rightArrowActive = false;
    leftArrowActive = false;

    scrollX = 0;

    prevGalleryWidth = 0;
    prevOriginalGalleryWidth = 0;

    private extraBufferLoad = 2;
    bufferLoad = 1;
    bufferCounter = 0;

    private scrollType: 'transform' | 'scroll' = 'transform';

    @Input() data: Array<any>;
    @Input() downloadable = false;
    @Input() withDetails = false;
    @Input('quality') minimalQualityCategory: 'small' | 'medium' | 'large' = 'medium';

    @Input('flexBorderSize') providedImageMargin = 30;
    @Input('flexImageSize') providedImageSize = 2.7;
    @Input('maxRowsPerPage') rowsPerPage = 1;
    @Input('dynamicSize') dynamicSize = false;

    @ViewChild('galleryContainer') galleryContainer: ElementRef;
    @ViewChildren('imageElement', { read: ElementRef }) imageElements: QueryList<ElementRef>;

    @Output() viewerChange = new EventEmitter<boolean>();

    _imageStyle: Array<any> = [];

    refreshTimeout: any;

    private prevScrollLeft = 0;

    changed = true;

    private fetchDataAndRender(data: Array<any>): void {
        this.images = data.map((img) => new GalleryImage(img, SIZES));
        this.galleryService.updateImages(this.images);

        this.render();
    }
    getImageStyle(img) {
        return this._imageStyle[img.id];
    }
    setImageStyle(img: any) {
        let w = img[this.minimalQualityCategory].width;
        let h = img[this.minimalQualityCategory].heigh;
        let x = 0;
        let y = 0;
        let transform;

        if (!img.model) {
            this._imageStyle[img.id] = {
                'background': 'trasnsparent',
                'min-width': `${w}px`,
                'max-width': `${w}px`,
                'height': `${h}px`,
            };
            return;
        }

        if (img.model.Rotation % 180 !== 0) {
            w = img[this.minimalQualityCategory].height;
            h = img[this.minimalQualityCategory].width;

            x = (h - w) / 2;
            y = -1 * x;

        } else {
            x = y = 0;
        }

        transform = `translate(${x}px, ${y}px) rotate(${img.model.Rotation}deg) scale(${img.model.Scale})`;
        this._imageStyle[img.id] = {
            'background': 'trasnsparent',
            'min-width': `${w}px`,
            'max-width': `${w}px`,
            'height': `${h}px`,
            'transform': transform,
            'msTransform': transform,
            'webkitTransform': transform,
            'oTransform': transform,
        };



    }

    private adjustImageWrapperSize(img) {

    }


    private rotated(img: GalleryImage, size: ISize): ISize {
        if (!img.model) {
            return size;
        }

        if (img.model.Rotation % 180 !== 0) {
            const ratio = size.height / size.width;
            size.width = size.height * ratio;
            size.height = size.width / ratio;
        }
        return size;
    }

    private adjustSize(img: GalleryImage, size: ISize = null, quality: 'small' | 'medium' | 'large' = 'medium') {
        if (size) {
            size = this.rotated(img, size);
        }
        size || (size = {
            width: img[quality].width,
            height: img[quality].height
        });

        const ratio = this.calcIdealHeight() / size.height;
        img[quality]['width'] = size.width * ratio;
        img[quality]['height'] = size.height * ratio;

        this.setImageStyle(img);
    }

    private render(): void {
        this.gallery = [];

        for (let i = 0; i < this.images.length; i++) {
            const img: GalleryImage = this.images[i];
            if (img) {
                this.adjustSize(img);
                this.gallery.push(img);
            }
        }
    }

    openImageViewer(img: any): void {
        // this.galleryService.updateImages(this.images)
        // this.galleryService.updateSelectedImageIndex(this.images.indexOf(img))
        // this.galleryService.showImageViewer(true)

        this.viewerOverlay.open({
            data: <IViewerData>{
                images: this.images,
                index: this.images.indexOf(img),
                initialQuality: this.minimalQualityCategory,
                downloadable: this.downloadable,
                withDetails: this.withDetails
            }
        });

        // // Close overlay after 2 seconds
        // setTimeout(() => {
        //     dialogRef.close();
        // }, 2000);
    }



    translateX(scrollX) {
        return 'translateX(' + (-1 * scrollX) + 'px)';
    }

    scrollRight() {
        let scrolled: number = this.scrollType === 'scroll' ? this.galleryContainer.nativeElement.scrollLeft : this.scrollX;
        const delta = this.scrollDelta;

        const max = Math.floor(this.calcOriginalGalleryWidth(this.gallery) - this.getGalleryContainerWidth());
        const scroll = scrolled + delta;

        if (Math.abs(max - scroll) < delta) {
            scrolled = max;
        } else {
            scrolled = Math.min(max, scroll);
        }

        if (this.scrollType === 'scroll') {
            this.galleryContainer.nativeElement.scrollLeft = scrolled;
        } else {
            this.scrollX = scrolled;
        }
    }

    scrollLeft() {
        let scrolled: number = this.scrollType === 'scroll' ? this.galleryContainer.nativeElement.scrollLeft : this.scrollX;
        const delta = this.scrollDelta;

        const min = 0;
        const scroll = scrolled - delta;

        if (Math.abs(min - scroll) < delta) {
            scrolled = min;
        } else {
            scrolled = Math.max(min, scroll);
        }

        if (this.scrollType === 'scroll') {
            this.galleryContainer.nativeElement.scrollLeft = scrolled;
        } else {
            this.scrollX = scrolled;
        }
    }

    /**
     * direction (-1: left, 1: right)
     */
    navigate(direction: number): void {
        // if ((direction === 1 && this.rowIndex < this.gallery.length - this.rowsPerPage)
        //     || (direction === -1 && this.rowIndex > 0)) {
        //     this.rowIndex += (this.rowsPerPage * direction)
        // }

        direction > 0 ? this.scrollRight() : this.scrollLeft();
        this.scaleGallery();
    }

    calcImageMargin(): number {
        const gcw = this.getGalleryContainerWidth();
        const ratio = gcw / 1920;

        return Math.round(Math.max(15, this.providedImageMargin * ratio));
    }

    private loadImage(img: GalleryImage): Observable<boolean> {
        const image = new Image();
        img.loading = true;
        const $loadedImg = fromEvent(image, 'load').pipe(
            take(1),
            map(event => {
                this.adjustSize(img, {
                    width: event.target['width'],
                    height: event.target['height']
                });
                img.src = img[this.minimalQualityCategory]['url'];
                img.naturalWidth = event.target['width'];
                img.naturalHeight = event.target['height'];
                img.loadedInGallery = true;
                img.loading = false;
                return true;
            })
        );
        image.src = img[this.minimalQualityCategory]['url'];
        return $loadedImg;
    }


    private calcIdealHeight(): number {
        return SIZES[this.minimalQualityCategory].height / (40 / this.providedImageSize) + 100;
    }

    private getGalleryContainerWidth(): number {
        if (!this.galleryContainer) {
          return;
        }

        if (this.galleryContainer.nativeElement.clientWidth === 0) {
            // for IE11
            return this.galleryContainer.nativeElement.scrollWidth;
        }

        return this.galleryContainer.nativeElement.clientWidth;
    }

    private calcOriginalGalleryWidth(galery: Array<any>): number {
        let originalRowWidth = (galery.length - 1) * this.calcImageMargin();
        const idealH = this.calcIdealHeight();

        galery.forEach(img => {
            const individualRatio = idealH / img[this.minimalQualityCategory]['height'];
            img[this.minimalQualityCategory]['height'] = idealH;
            img[this.minimalQualityCategory]['width'] = img[this.minimalQualityCategory]['width'] * individualRatio;
            originalRowWidth += img[this.minimalQualityCategory]['width'];
        });

        return originalRowWidth;
    }

    private scaleGallery(): void {
        this.bufferCounter = 0;
        this.bufferLoad = Math.ceil(this.getGalleryContainerWidth() / SIZES[this.minimalQualityCategory].width) + this.extraBufferLoad;

        const loadImages: Observable<boolean>[] = [];
        this.gallery.forEach((img: GalleryImage, index) => {
            const inView = this.inView(img, index);
            if (!inView && !img.loadedInGallery) {
                this.bufferCounter++;
            }
            if (!(img.loading || img.loadedInGallery) && (inView || this.bufferCounter <= this.bufferLoad)) {
                loadImages.push(this.loadImage(img));
            }
        });

        if (loadImages.length) {
            forkJoin(loadImages).subscribe(results => {
                this.refreshNavigationState();
                this.prevGalleryWidth = this.getGalleryContainerWidth();
            });
        } else {
            this.refreshNavigationState();
            this.prevGalleryWidth = this.getGalleryContainerWidth();
        }
    }

    private inView(image: GalleryImage, index: number): boolean {
        if (!this.imageElements) {
            return false;
        }
        const imageElements = this.imageElements.toArray();

        const imageElement = imageElements[index].nativeElement;

        const elementRect = imageElement.getBoundingClientRect();
        const containerRect = this.galleryContainer.nativeElement.getBoundingClientRect();

        return (elementRect.right > containerRect.left && elementRect.left < containerRect.right);
    }
    private refreshNavigationState(): void {
        this.leftArrowActive = this.scrollX > 0;
        const ogw = this.calcOriginalGalleryWidth(this.gallery);
        const gcw = this.getGalleryContainerWidth();
        const maxScroll = Math.floor(ogw - gcw);

        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(() => {
            this.rightArrowActive = this.scrollX < maxScroll && ogw > gcw;
        });

    }

    ngOnInit(): void {
        if (!this.dynamicSize) {
            // these value should be provided in @Input
            this.providedImageMargin = 30;
            this.providedImageSize = 2.7;
        } else {
            this.boardView.handle();
            this.providedImageMargin = 20;

            const width = this.boardView.width;
            switch (true) {
                // case width > 1200:
                //     this.providedImageSize = 15;
                //     break;
                case width > 992:
                    this.providedImageSize = 10;
                    break;
                case width > 768:
                    this.providedImageSize = 8;
                    break;
                case width > 576:
                    this.providedImageSize = 4;
                    break;
                default:
                    this.providedImageSize = 1;
            }
        }


        this.galleryService.showImageViewerChanged$.pipe(
            takeWhile(() => this.alive))
            .subscribe(
                (visibility: boolean) => this.viewerChange.emit(visibility)
            );
    }

    ngAfterViewInit() {
        if (!this.imageElements.length) {
            this.renderer.setStyle(this.galleryContainer.nativeElement, 'height', `${this.calcIdealHeight()}px`);
        }
        // const margin: number = this.calcImageMargin();
        // this.imageElements.forEach((imgRef: ElementRef, index: number) => {
        //     let height = this.gallery[index][this.minimalQualityCategory].height;
        //     let width = this.gallery[index][this.minimalQualityCategory].width;

        //     this.renderer.setStyle(imgRef.nativeElement, 'max-width', `${width}px`);
        //     this.renderer.setStyle(imgRef.nativeElement, 'min-width', `${width}px`);
        //     this.renderer.setStyle(imgRef.nativeElement, 'height', `${height}px`);
        //     this.renderer.setStyle(imgRef.nativeElement, 'margin-right', `${margin}px`);
        // });
    }
    ngAfterViewChecked() {
        const gcw: number = this.getGalleryContainerWidth();
        if (!gcw) {
            return;
        }

        if (this.prevGalleryWidth > 0 && (gcw !== this.prevGalleryWidth)) {
            const ogw = this.calcOriginalGalleryWidth(this.gallery);
            const prevMax = Math.floor(ogw - this.prevGalleryWidth);

            // console.log(['ngAfterViewChecked WIDTH changed', gcw, ogw, this.prevGalleryWidth]);

            if (gcw > this.prevGalleryWidth && this.scrollX === prevMax) {
                this.scrollRight();
            }
            this.scaleGallery();
        } else if (this.galleryContainer.nativeElement.scrollLeft !== this.prevScrollLeft) {

            this.prevScrollLeft = this.galleryContainer.nativeElement.scrollLeft;

            this.scaleGallery();
        } else if (this.changed) {
            this.changed = false;
            this.scaleGallery();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {

            if (changes['data'].firstChange) {
                this.fetchDataAndRender(this.data);
                this.changed = true;
            } else {
                const ch = changes['data'].currentValue.filter(item => {
                    return !changes['data'].previousValue.find(i => i.id === item.id);
                });
                if (ch.length || changes['data'].currentValue !== changes['data'].previousValue) {
                    this.fetchDataAndRender(this.data);
                    this.changed = true;
                }
            }
        }

    }

    ngOnDestroy(): void {
        this.alive = false;
    }
}
