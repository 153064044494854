import { Component, HostBinding, Input } from '@angular/core';


@Component({
    selector: 'mgc-loader',
    template: `
        <mat-spinner
          [color]="color"
          [diameter]="diameter"
          [strokeWidth]="strokeWidth"
          style="display: inline-block; vertical-align: middle; --mdc-circular-progress-active-indicator-color: rgba(34, 34, 34, 1)">

        </mat-spinner>
    `,
    styles: [`

    `]
})
export class MgcLoaderComponent {
    @HostBinding('class.mgc-loader') loaderClass = true;
    @Input() color = 'accent';
    @Input() diameter = 20;
    @Input() strokeWidth = 3;
}
