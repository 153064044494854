export class TaskV2Image {
    Id: string;
    TaskV2Id: string;
    BlockId: string;
    AccountId: string;
    MimeType: string;
    Rotation: number;
    Size: number;
    Ratio: number;
    FileName: string;
    SystemName: string;
    CreateDate: string;
    UpdateDate: string;
    //
    Link: string;
    AltText: string;

    constructor(data: any = null) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    update(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return {
            Id: this.Id,
            TaskV2Id: this.TaskV2Id,
            BlockId: this.BlockId,
            AccountId: this.AccountId,
            MimeType: this.MimeType,
            Rotation: this.Rotation,
            Size: this.Size,
            Ratio: this.Ratio,
            FileName: this.FileName,
            SystemName: this.SystemName,
            CreateDate: this.CreateDate,
            UpdateDate: this.UpdateDate,
            //
            Link: this.Link,
            AltText: this.AltText
        }
    }

    toJSON() {
        return this.toDb();
    }
}
