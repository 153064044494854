import {
    Directive, EventEmitter,
    OnInit,
    OnDestroy,
    Output, HostListener, HostBinding, Input
} from '@angular/core';

@Directive({
    selector: '[grid-item], grid-item',
})
export class GridItemDirective implements OnInit, OnDestroy {
    @HostBinding('class.grid-item') gridItemClass = true;
    @HostBinding('style.width.px') width: number = 150;
    @HostBinding('style.height.px') height: number = 150;

    @HostBinding('class.item-selected') isSelected: boolean = false;

    @HostListener('click') onClick() {
        this.isSelected = !this.isSelected;
        this.selectedChange.next(this.isSelected);
    }

    @Input('selected')
    set selected(value: boolean) {
        this.isSelected = value;
    }
    @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

}