import {
  Component, EventEmitter, HostBinding, Inject,
  Input, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IUploaderOptions, UploaderComponent } from '@libraries/uploader';
import { AuthService } from '@rallysite/auth-service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { ConfirmOverlayService } from '../confirm-overlay/confirm-overlay.service';

import { Image } from './image';

export interface IImageOptions {
  endPoint: string;
  payload: any;
  ratio?: number;
  actionsPosition?: 'tl' | 'tr' | 'bl' | 'br';
  props?: Array<string>;
  removable?: boolean;
  filename?: string;
  layout?: 'v1' | 'v2';
}

interface IProp {
  id: string;
  name: string;
  label: string;
  placeholder: string;
}

/** the image ratio */
const DEFAULT_IMAGE_RATIO: number = 7 / 2;

@Component({
  selector: 'mgc-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @HostBinding('class.preview') get previewClass() {
    return this.preview;
  }
  get preview() {
    return this._preview !== false;
  }

  get imageRatio(): number {
    if (this.image && this.image.Ratio) {
      return 100 / this.image.Ratio;
    }
    return 100 / (this.options.ratio || DEFAULT_IMAGE_RATIO);
  }

  constructor(
    private authService: AuthService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private formBuilder: FormBuilder,
    private confirmOverlay: ConfirmOverlayService,
  ) { }
  get f() { return this.form.controls; }


  get haveImage() {
    return this.imageStyle.hasOwnProperty('background-image') || this.previewImageFile;
  }

  @Input() image: Image;
  @Input() options: IImageOptions;

  @Input('preview') _preview = false;
  @ViewChild(UploaderComponent) uploader: UploaderComponent;

  @Output() onUpdate: EventEmitter<Image> = new EventEmitter(null);
  @Output() onRemove: EventEmitter<boolean> = new EventEmitter(false);

  uploaderOptions: IUploaderOptions;
  defaultBgStyle: any = {
    'background-color': '#f1f1f1',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
    'background-size': 'cover',
    'transition': 'margin .3s, background-image .4s linear'
  };
  imageBlobStyle: any;
  imageStyle: any;

  props: IProp[];
  activeProp: IProp;


  /** FORM */
  form: FormGroup;

  imageUploading = false;
  previewImageFile: any;
  imageError = false;
  bootstrapForm() {
    this.props = (this.options.props || ['link', 'alt']).map(p => {
      switch (p.toLowerCase()) {
        case 'link':
          return { id: 'Link', name: 'Link', label: 'Link', placeholder: 'https://' };
        case 'alt':
          return { id: 'AltText', name: 'Alt', label: 'Alt text', placeholder: 'Add a short image description here.' };
      }
    });

    const i = this.image || <Image>{};
    this.form = this.formBuilder.group({
      Link: [i.Link || ''],
      AltText: [i.AltText || ''],
    });
  }
  /** end of FORM */

  activateProp(prop: IProp) {
    this.activeProp = prop;
  }

  click() {
    if (this.imageUploading) {
      return;
    }
    this.uploader.click();
  }
remove(event) {
    this.confirmOverlay.open(event.currentTarget, 'Remove Image! You sure?').afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.onRemove.emit(true);
        }
      });
  }
  onBeforeUpload(item: any) {
    this.imageError = false;
    this.imageUploading = true;
    this.previewImageFile = item.file;

    this.image = new Image({...this.options.payload, ...this.form.getRawValue()});
    this.imageBlobStyle = this.getImageBlobStyle();
  }
  onSuccess(data: { item: any; response: any }) {
    this.imageUploading = false;
    this.update(data.response.file);
  }
  onError(data: { item: any; response: any }) {
    this.imageUploading = false;
    this.imageError = true;
  }

  update(data: any) {
    if (!this.image) { return; }

    this.image.update(data);
    this.onUpdate.emit(this.image);
  }

  onPropUpdate(prop: IProp) {
    this.update({ [prop.id]: this.form.value[prop.id] });
    this.activeProp = null;
  }

  private getImageStyle() {
    if (!this.image || !this.image.SystemName) {
      return this.defaultBgStyle;
    }

    const parts = this.image.SystemName.split('.');

    const imageUrl = `${this.config.cdnImages}/${this.image.Path}/${parts[0] + '_medium'}`;
    return Object.assign({}, this.defaultBgStyle, {
      'background-image': `url(${imageUrl})`
    });
  }

  private getImageBlobStyle() {
    if (!this.previewImageFile) {
      return this.defaultBgStyle;
    }

    const imageUrl = URL.createObjectURL(this.previewImageFile.rawFile);
    return Object.assign({}, this.defaultBgStyle, {
      'background-image': `url(${imageUrl})`
    });
  }

  ngOnInit() {
    this.options.layout || (this.options.layout = 'v1');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['image']) {
      this.bootstrapForm();
      this.previewImageFile = null;
      this.imageStyle = this.getImageStyle();

      this.uploaderOptions = {
        autoUpload: true,
        filename: this.options.filename || 'image',
        tokenGetter: this.authService.tokenGetter,
        endPoint: this.options.endPoint,
        payload: this.options.payload
      };
    }
  }

}
