
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Account, AccountService } from '@board-accounts/index';
import { Project } from '@board/items/project/project';
import { Task } from '@board/items/task/task';
import { FtmTask } from '@fotameco/ftm-task/ftm-task.model';
import { Note } from '@panel-components/notes/note';
import { AuthService } from '@rallysite/auth-service';
import { APP_CONFIG, AppConfig } from "@rallysite/config";
import { WINDOW } from '@rallysite/global-services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ISocialShareOptions } from './social-share.enum';
import { PageProject } from '@pages-content/page-projects/page-project.model';
import { Brand, DEFAULT_BRAND_UNAME } from '@board-brands/brand.model';
import { SsrTask } from '@fotameco/ftm-task/ssr-task.model';
import { PUBLIC_PAGES } from '@pages/pages.enum';
import { TaskV2 } from '@items/task-v2/task-v2.model';

export interface IShareKeyOptions {
  taskId: string;
  noteId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocialShareService {

  topPageUrl: string;

  brand: Brand;
  project: Project | PageProject;
  task: Task | FtmTask | SsrTask | TaskV2;
  note: Note;

  private currentAccount: Account;

  private _endPoint: string;

  /** Personal Shares */
  private _personalShares: BehaviorSubject<number>[] = [];
  getPersonalShares(options: IShareKeyOptions): BehaviorSubject<number> {
    const key = this.skey(options);
    if (!this._personalShares[key]) {
      this._personalShares[key] = new BehaviorSubject<number>(0);
    }
    return this._personalShares[key];
  }
  getPersonalShares$(options: IShareKeyOptions): Observable<number> {
    return this.getPersonalShares(options).asObservable();
  }
  /** */


  /** Total Shares */
  private _totalShares: BehaviorSubject<number>[] = [];
  getTotalShares(options: IShareKeyOptions): BehaviorSubject<number> {
    const key = this.skey(options);
    if (!this._totalShares[key]) {
      this._totalShares[key] = new BehaviorSubject<number>(0);
    }
    return this._totalShares[key];
  }
  getTotalShares$(options: IShareKeyOptions): Observable<number> {
    return this.getTotalShares(options).asObservable();
  }
  /** */

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) public w: Window,
    @Inject(APP_CONFIG) private config: AppConfig,
    private accountsService: AccountService,
    private authService: AuthService,
  ) {
    this._endPoint = `${this.config.endpoint}/social-share`;
  }

  init({ account, brand, project, task }: { account?: Account, brand?: Brand, project?: Project, task?: Task | TaskV2 }) {
    this.currentAccount = account;
    this.brand = brand;
    this.project = project;
    this.task = task;
  }

  private skey(options: IShareKeyOptions): string {
    if (options.noteId) {
      return `${options.taskId}_${options.noteId}`;
    }
    return `${options.taskId}`;
  }

  getPageLink(project: PageProject | Project = null) {
    project || (project = this.project)
    if (!project) {
      return this.w.location ? this.w.location.href : '';
    }

    const params = {
      taskId: this.task ? this.task.Id : null,
      taskUName: this.task ? this.task.string_to_slug(this.task.Name) : null
    }

    if (this.topPageUrl) {
      return this.getTopPageUrl(params);
    }
    return this.getCurrentPageUrl(project, params);
  }

  private getTopPageUrl(params) {
    return `${this.topPageUrl}?tnm=${params.taskUName}&tid=${params.taskId}`;
  }

  public getCurrentPageUrl(project, params) {
    return `${this.getProjectPage(project)}/${params.taskUName}/${params.taskId}`;
  }

  private getProjectPage(project) {
    const projectId = project.UName || project.Id;
    const brandId = this.brand && this.brand.UName ? this.brand.UName : (project.BrandUName || DEFAULT_BRAND_UNAME);

    return `${this.config.protocol}://${this.config.domain}${PUBLIC_PAGES.LIBRARY.path}/${brandId}/${projectId}`;
  }

  getSubject() {
    if (this.task && this.task.socialMedia && this.task.socialMedia.title) {
      return this.task.socialMedia.title;
    }

    if (this.project) {
      return this.project.Name;
    }

    return 'Email Subject';
  }

  getBody() {
    const body: string = "This is worth checking out... ";
    if (this.task && this.task.socialMedia && this.task.socialMedia.description) {
      return this.task.socialMedia.description;
    }

    if (this.project && this.project.Metadata && this.project.Metadata.heroImage) {
      return this.project.Metadata.heroImage.message || this.project.ShortDescription || body;
    }

    return this.project.ShortDescription || body;
  }

  shares(options: IShareKeyOptions) {
    let params = [];
    const account = this.currentAccount || this.accountsService.currentAccount;
    if (account && account.Id) {
      params.push(`acc=${account.Id}`)
    } else {
      params.push(`ann=${this.authService.anonymousId}`)
    }
    if (options.taskId) {
      params.push(`tid=${options.taskId}`);
    }
    if (options.noteId) {
      params.push(`nid=${options.noteId}`);
    }
    return this.http.get(`${this._endPoint}?${params.join('&')}`).pipe(
      map((shares) => {
        if (shares['pShares']) {
          const personalShares = this.reduceShares(shares['pShares']);
          this.pEmit(personalShares, options);
          delete shares['pShares'];
        }

        const totalShares = this.reduceShares(shares);
        this.tEmit(totalShares, options);

        return totalShares;
      }), catchError(err => {
        return of(null);
      }))
  }


  private reduceShares(shares: any) {
    return Object.keys(shares).reduce((acc, prop) => {
      // exclude ids
      if (['AccountId', 'AnonymousId', 'TaskId', 'NoteId', 'TClicks', 'Clicks'].find(p => p.toLowerCase() === prop.toLowerCase())) {
        return acc;
      }
      return acc += parseInt(shares[prop]);
    }, 0);
  }

  userParams() {
    let params = [];
    const account = this.currentAccount || this.accountsService.currentAccount;
    if (account && account.Id) {
      params.push(`acc=${account.Id}`)
    } else {
      params.push(`ann=${this.authService.anonymousId}`)
    }
    if (this.task) {
      params.push(`tid=${this.task.Id}`);
    }
    if (this.note) {
      params.push(`nid=${this.note.Id}`);
    }
    return params;
  }

  countShare(share: ISocialShareOptions) {
    const qp = [`sh=${share.id}`, `cb=${this.cb()}`, ... this.userParams()];
    document.createElement('img').src = `${this._endPoint}/px?${qp.join('&')}`;

    const options = { taskId: this.task.Id, noteId: this.note ? this.note.Id : null }
    this.tEmit((this.getTotalShares(options).getValue() || 0) + 1, options);
    this.pEmit((this.getPersonalShares(options).getValue() || 0) + 1, options);
  }

  countClicks() {
    const qp = ['sh=click', `cb=${this.cb()}`, ... this.userParams()];
    document.createElement('img').src = `${this._endPoint}/px?${qp.join('&')}`;
  }

  private cb() {
    return Math.floor(Math.random() * 9999999999) + '';
  }

  private tEmit(value, options) {
    const key = this.skey(options);
    this._totalShares[key].next(value);
  }

  private pEmit(value, options) {
    const key = this.skey(options);
    this._personalShares[key].next(value);
  }

}