import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from '@board/modals/modals-editor/modal.model';
import {APP_CONFIG, AppConfig} from "@rallysite/config";

@Component({
  selector: 'config-assessment',
  templateUrl: './config-assessment.component.html',
  styleUrl: './config-assessment.component.scss'
})
export class ConfigAssessmentComponent implements OnInit {
  @Input() modal;
  @Output() modalChange = new EventEmitter<Modal>();
  @Input() submitted;

  form: FormGroup;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  get sampleUrl() {
    return 'https://' + this.config.domain + '/surveys?srvy_id=SRVY-OsIRhCbSM2s-QT5qgdloEHL';
  }
  get f() { return this.form.controls; }
  ngOnInit() {
    this.form = new FormGroup({
      surveyLink: new FormControl(this.modal.Config.surveyLink, [
        Validators.required,
      ]),
      daysSinceLastPlevelAssessment: new FormControl(this.modal.Config.daysSinceLastPlevelAssessment),
      closeModalOnComplete: new FormControl(this.modal.Config.closeModalOnComplete),
    });

    this.form.valueChanges.subscribe(newConfig => {
      this.modalChange.emit({
        ...this.modal,
        Config: {
          ...this.modal.Config,
          ...newConfig,
        }
      });
    });
  }
}
