<mgc-dialog>
  <mgc-dialog-header title="Not Allowed" (close)="closeDialog()"></mgc-dialog-header>
</mgc-dialog>


<mgc-dialog-content>
  <mat-icon matListIcon svgIcon="board-mat:warning"></mat-icon>
  <span>{{data.message || 'You are not authorized for this action.'}}</span>
</mgc-dialog-content>

<mgc-dialog-actions>
  <mgc-raised-button>Close</mgc-raised-button>
</mgc-dialog-actions>
