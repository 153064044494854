import { NgModule } from '@angular/core';
import { MgcInformationComponent } from './information.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        MatIconModule

    ],
    declarations: [
        MgcInformationComponent,
    ],

    exports: [
        MgcInformationComponent,
    ]
})
export class MgcInformationModule { }
