<mgc-loader *ngIf="!ready"></mgc-loader>

<form [formGroup]="settingsForm" *ngIf="ready">

  <div class="mgc-row">
    <mgc-form-field label="Plan Name *" class="mgc-col-sm-6" [class.disabled]="!allowUpdate"
      [invalid]="submitted && f.Name.errors">
      <div class="field-wrapper">
        <input tabindex="-1" formControlName="Name">
        <span class="placeholder" *ngIf="!f.Name.value">Plan name</span>
      </div>
      <ng-container invalid-feedback *ngIf="submitted && f.Name.errors">
        <div *ngIf="f.Name.errors.required">Plan name is required</div>
      </ng-container>
    </mgc-form-field>
  </div>

  <div class="mgc-row">
    <mgc-form-field label="Choose collection" *ngIf="!data.umbrella.isStartUp" class="mgc-col-sm-6">
      <mat-icon svgIcon="board-mat:collection"></mat-icon>
      <div class="field-wrapper">
        <mat-select formControlName="umbrella" name="umbrella">
          <mat-option [value]="defaultUmbrella">
            <span [innerHTML]="defaultUmbrella.Name"></span>
          </mat-option>
          <ng-container *ngFor="let umbrella of umbrellas | sortUmbrellas">
            <mat-option *ngIf="!umbrella.isStartUp || !project.Id" [value]="umbrella">
              <mat-icon svgIcon="board-mat:collection"></mat-icon>
              <span [innerHTML]="umbrella.Name"></span>
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </mgc-form-field>
  </div>

  <mat-slide-toggle *ngIf="data.umbrella.isStartUp" [style.margin-bottom.px]="10" [checked]="project.InUse"
    (change)="toggleInUse($event)">
    Plan in use for new accounts
  </mat-slide-toggle>


  <div class="mgc-row" *ngIf="allowUpdate">
    <mgc-form-field label="Back button text from open Item to Item menu" class="mgc-col-sm-6"
      [invalid]="submitted && f.label_seeItemList.errors">
      <div class="field-wrapper">
        <input tabindex="-1" formControlName="label_seeItemList">
        <!-- <span class="placeholder" *ngIf="!f.label_seeItemList.value"></span> -->
      </div>
      <!-- <ng-container invalid-feedback *ngIf="submitted && f.label_seeItemList.errors">
        <div *ngIf="f.label_seeItemList.errors.required">Plan name is required</div>
      </ng-container> -->
    </mgc-form-field>
  </div>

  <!-- <h4 [style.margin-top.px]="5">Plan Item Settings</h4> -->
  <!-- <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.enableTaskCompleteButton"
        (change)="toggleTaskCompleteButton($event)">
        Enable <b>Click To Complete</b> item option
      </mat-slide-toggle>
    </div>
  </div> -->


  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.collapseComments"
        (change)="toggleCollapseCommentsOption($event)">
        Collapse comments
      </mat-slide-toggle>
    </div>
  </div>

  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.allowAnyAuthUserToComment"
        (change)="toggleAllowAnyAuthUserToCommentOption($event)">
        Allow any authenticated user to comment
      </mat-slide-toggle>
    </div>
  </div>

  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.GTPushNotifications"
        (change)="toggleGTPushNotifications($event)">
        Can configure items as push notifications
      </mat-slide-toggle>
      <mgc-information [style.margin-bottom.px]="15" [style.margin-left.px]="5">
        This will enable the option to push notifications through the GoTandem App
      </mgc-information>
    </div>

    <mgc-form-field *ngIf="project.GTPushNotifications" label="GT OrganizationId *" class="mgc-col-sm-6"
      [class.disabled]="!allowUpdate" [invalid]="submitted && f.GTOrganisationId.errors">
      <div class="field-wrapper">
        <input type="number" tabindex="-1" formControlName="GTOrganisationId">
        <span class="placeholder" *ngIf="!f.GTOrganisationId.value">GT Organization Id</span>
      </div>
      <ng-container invalid-feedback *ngIf="submitted && f.GTOrganisationId.errors">
        <div *ngIf="f.GTOrganisationId.errors.required">GT OrganisationId is required</div>
      </ng-container>
    </mgc-form-field>

  </div>

  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.hideDates"
                        (change)="toggleHideDatesOption($event)">
        Hide Dates
      </mat-slide-toggle>
    </div>
  </div>

  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.hideAuthor"
                        (change)="toggleHideAuthorOption($event)">
        Hide Author
      </mat-slide-toggle>
    </div>
  </div>

  <div class="mgc-row">
    <div class="mgc-form-control mgc-col-sm-6">
      <mat-slide-toggle class="mgc-toggle" [checked]="project.Settings.enableRecycler"
                        (change)="toggleEnableRecyclerOption($event)">
        Enable Recycler
      </mat-slide-toggle>
    </div>
  </div>

</form>
