import { ISocialMedia } from "@board/items/task/setings/task-settings.class";
import { IPanelComponent, PanelComponents } from "@panel-components";

export interface IScheduledTaskSettings {
    defaultPanel: IPanelComponent;
    sideDefaultPanel: IPanelComponent;
    sidePanelIsOpen: boolean;

    socialMedia: ISocialMedia;

    toDb();
}

export class ScheduledTaskSettings implements IScheduledTaskSettings {
    private _defaultPanel: IPanelComponent;
    private _sideDefaultPanel: IPanelComponent;
    sidePanelIsOpen: boolean;
    socialMedia: ISocialMedia = {};

    constructor(data?: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    get defaultPanel(): IPanelComponent {
        return this._defaultPanel || PanelComponents.NOTES;
    }
    set defaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        this._defaultPanel = this.findPanel(id);
    }

    get sideDefaultPanel(): IPanelComponent {
        return this._sideDefaultPanel || PanelComponents.SETTINGS;
    }
    set sideDefaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        if (id === PanelComponents.STATUS.id) {
            id = PanelComponents.SETTINGS.id;
        }
        this._sideDefaultPanel = this.findPanel(id);
    }

    private findPanel(id: string) {
        let dPanel: IPanelComponent = null;
        for (let panel in PanelComponents) {
            if (id === PanelComponents[panel].id) {
                dPanel = PanelComponents[panel];
                break;
            }
        }
        return dPanel;
    }

    toDb() {
        return {
            defaultPanel: this.defaultPanel.id,
            sideDefaultPanel: this.sideDefaultPanel.id,
            sidePanelIsOpen: this.sidePanelIsOpen,
            socialMedia: this.socialMedia
        }
    }

}