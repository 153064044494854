import { Component, Input, HostBinding, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'project-avatar',
    templateUrl: 'project-avatar.component.html',
    styleUrls: ['project-avatar.component.scss']
})

export class ProjectAvatarComponent {
    @HostBinding('class.project-avatar') projectAvatar = true;

    // elevation: boolean = true;

    @Input() processing: boolean = false;
    @Input() previewAvatarFile: any = null;
    @Input() shape: 'rectangle' | 'circle' = 'rectangle';
    @Input() t : number = 1;

    @Input() size: number = 30;

    @HostBinding('style.border-radius')
    get borderRadius() {
        return this.shape === 'rectangle' ? `${Math.round(this.size / 6)}px` : '50%';
    }

    @Input() name: string = '';
    @Input() imageId: string = null;

    @Input() strokeWidth: number;
    @Input() strokeColor: string;

    @HostBinding('style.height.px') hPx: number;
    @HostBinding('style.width.px') wPx: number;

    sw: number;
    sc: string;

    constructor(
        private chRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.hPx = this.wPx = this.size;

        this.sw = this.strokeWidth || 2;
        this.sc = this.strokeColor || 'rgba(0,0,0,.87)';
    }

    ngDoCheck() {
        // if (this.brand._timestamp && this.timestamp !== this.brand._timestamp) {
        //     this.timestamp = this.brand._timestamp;
        //     this.chRef.detectChanges();
        // }
    }
}