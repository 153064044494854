import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIBaseBlockComponent } from '../ui-base-block.component';
import { UIImageBlock } from './image-block';

@Component({
  selector: 'ui-image-block',
  templateUrl: './image-block.component.html',
})
export class UIImageBlockComponent extends UIBaseBlockComponent {
  @Input() block: UIImageBlock;
  @Output() remove: EventEmitter<UIImageBlock> = new EventEmitter(null);
  @Output() updated: EventEmitter<boolean> = new EventEmitter();

  onRemove() {
    this.remove.emit(this.block);
  }

  imageUpdated(ev: boolean){
    this.updated.emit(ev);
  }

}
