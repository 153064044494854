import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { SurveyOverlayRef } from './survey-overlay-ref';
import { SurveyOverlayComponent } from './survey-overlay.component';

@Injectable()
export class SurveyOverlayService {

    private overlayRef: OverlayRef;
    private elementRef: ElementRef

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private overlayPositionBuilder: OverlayPositionBuilder,
    ) {
    }

    open(el, { message, actions }) {
        this.elementRef = el;
        const overlayConfig = this.getOverlayConfig();

        this.overlayRef = this.overlay.create(overlayConfig);

        // Instantiate remote control
        const dialogRef = new SurveyOverlayRef(this.overlayRef);
        const injector = this.createInjector(dialogRef);

        const confirmRef: ComponentRef<SurveyOverlayComponent>
            = this.overlayRef.attach(new ComponentPortal(SurveyOverlayComponent, null, injector));
        confirmRef.instance.message = message;
        confirmRef.instance.actions = actions;

        // Subscribe to a stream that emits when the backdrop was clicked
        this.overlayRef.backdropClick().subscribe(_ => dialogRef.close());

        return dialogRef;
    }

    private createInjector(dialogRef: SurveyOverlayRef): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(SurveyOverlayRef, dialogRef);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'center',
                originY: 'bottom',

                overlayX: 'end',
                overlayY: 'top',

                offsetX: 47,
                offsetY: 7,
            }]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'mgc-overlay-backdrop',
            panelClass: 'survey-overlay',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy,
            width: '270px'
        });

        return overlayConfig;
    }
}
