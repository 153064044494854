
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ServiceAlertClass, IAlertsClass } from '@rallysite/components/alert';
import { Umbrella } from '@board/items/umbrella/umbrella';
import { Project } from '@board/items/project/project';
import { IPanelComponent, PanelComponents } from '@panel-components';

import { ParticipantListComponent } from '@panel-components/participants/participant-list.component';
import { PublishComponent } from '@panel-components/publish/publish.component';
import { PUBLISH_TABS } from '@panel-components/publish/publish-tabs.component';
import { PermissionService } from '@board/_services';
import { UserService } from '@rallysite/user';
import {
  ProjectSettingsComponent,
  ProjectSettingsNameComponent,
  ProjectSettingsEMessageComponent,
  ProjectSettingsRestrictedUsersComponent,
} from '../settings';
import { ProjectStatisticsComponent } from '../statistics/project-statistics.component';
import { ProjectSettingsThemeComponent } from '../settings/project-settings-theme.component';
import { ProjectTaggingComponent } from '../settings/project-tagging.component';

const REFs = {
  ProjectSettingsRestrictedUsersComponent: 'ProjectSettingsRestrictedUsersComponent',
  ProjectSettingsNameComponent: 'ProjectSettingsNameComponent',
  ProjectSettingsThemeComponent: 'ProjectSettingsThemeComponent',
  ProjectSettingsEMessageComponent: 'ProjectSettingsEMessageComponent',
  ParticipantListComponent: 'ParticipantListComponent',
  PublishComponent: 'PublishComponent',
  ProjectStatisticsComponent: 'ProjectStatisticsComponent',
  ProjectTaggingComponent: 'ProjectTaggingComponent',
};

@Component({
  selector: 'project-modal',
  templateUrl: './project-modal.html',
})
export class ProjectModal implements OnInit {

  get REFs() { return REFs; }
  get pubTabs() { return PUBLISH_TABS; }

  constructor(
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<ProjectSettingsComponent>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      project: Project,
      umbrella: Umbrella,
      edit: boolean,
      panel: IPanelComponent
    }
  ) {
    this.project = data.project;

    this.isArchive = data.umbrella && data.umbrella.isArchive;
  }

  get refIsSettings() {
    return this.refInView === REFs.ProjectSettingsNameComponent ||
      this.refInView === REFs.ProjectSettingsRestrictedUsersComponent ||
      this.refInView === REFs.ProjectSettingsEMessageComponent ||
      this.refInView === REFs.ProjectSettingsThemeComponent ||
      this.refInView === REFs.ProjectTaggingComponent;
  }

  get allowArchive() {
    return !this.isArchive &&
      (this.refInView === REFs.ProjectSettingsNameComponent ||
        this.refInView === REFs.ProjectSettingsRestrictedUsersComponent);
  }

  get ready() {
    let comp: any;
    switch (true) {
      case !!this.projectSettingsName:
        comp = this.projectSettingsName;
        break;
      case !!this.projectRestrictedUsersSettings:
        comp = this.projectRestrictedUsersSettings;
        break;

      default:
        return true;
    }
    return !!comp && comp.ready;
  }

  get processing() {
    let comp: any;
    switch (true) {
      case !!this.projectSettingsName:
        comp = this.projectSettingsName;
        break;
      case !!this.projectRestrictedUsersSettings:
        comp = this.projectRestrictedUsersSettings;
        break;
      case !!this.projectSettingsEMessage:
        comp = this.projectSettingsEMessage;
        break;
      case !!this.projectSettingsTheme:
        comp = this.projectSettingsTheme;
        break;
      case !!this.publishComponent:
        comp = this.publishComponent;
        break;
      case !!this.projectTagging:
        comp = this.projectTagging;
        break;
    }
    return !!comp && !!comp.processing;
  }

  get isDirty() {
    let comp: any;
    switch (true) {
      case !!this.publishComponent:
        comp = this.publishComponent;
        break;
    }
    return !!comp && comp.isDirty;
  }

  @ViewChild(ProjectSettingsRestrictedUsersComponent) projectRestrictedUsersSettings: ProjectSettingsRestrictedUsersComponent;
  @ViewChild(ProjectSettingsNameComponent) projectSettingsName: ProjectSettingsNameComponent;
  @ViewChild(ProjectSettingsThemeComponent) projectSettingsTheme: ProjectSettingsThemeComponent;
  @ViewChild(ProjectSettingsEMessageComponent) projectSettingsEMessage: ProjectSettingsEMessageComponent;
  @ViewChild(ParticipantListComponent) participantList: ParticipantListComponent;
  @ViewChild(PublishComponent) publishComponent: PublishComponent;
  @ViewChild(ProjectStatisticsComponent) statisticsComponent: ProjectStatisticsComponent;
  @ViewChild(ProjectTaggingComponent) projectTagging: ProjectTaggingComponent;

  state: number;
  allowUpdateProject = false;
  allowViewParticipants = false;

  projectDataReady = false;

  serviceAlertClass: IAlertsClass = ServiceAlertClass.ALERTS;

  isArchive = false;
  refInView: string;

  refList: Array<any> = [];

  project: Project;
  selectedPublishTab: PUBLISH_TABS;

  expanded = false;

  selectedItem: any = {};
  publishTabChange(tab: PUBLISH_TABS) {
    setTimeout(() => this.selectedPublishTab = tab, 0);
  }
  toggleExpand() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.dialogRef.addPanelClass(['dialog-fullscreen']);
    } else {
      this.dialogRef.removePanelClass(['dialog-fullscreen']);
    }
  }

  closeDialog(reason): void {
    if (['close', 'cancel'].indexOf(reason) >= 0) {
      // its cancel
      this.data.project.__revert();
      if (!!this.projectSettingsTheme) {
        this.projectSettingsTheme.revertTheme();
      }
    }

    this.dialogRef.close(reason);
  }
  showContent(item: any) {
    if (typeof item === 'string') {
      this.refInView = item;
      this.selectedItem = this.refList.find(ref => ref.refItem === item);
    } else {
      this.selectedItem = item;
      this.refInView = item.refItem;
    }
  }

  onSave(event = null) {
    let comp: any;
    switch (true) {
      case !!this.projectSettingsName:
        comp = this.projectSettingsName;
        break;
      case !!this.projectRestrictedUsersSettings:
        comp = this.projectRestrictedUsersSettings;
        break;
      case !!this.projectSettingsEMessage:
        comp = this.projectSettingsEMessage;
        break;
      case !!this.projectSettingsTheme:
        comp = this.projectSettingsTheme;
        break;
      case !!this.publishComponent:
        comp = this.publishComponent;
        break;
      case !!this.projectTagging:
        comp = this.projectTagging;
        break;
    }
    if (comp) {
      comp.onSave();
    }
  }

  onDelete(event = null) {
    let comp: any;
    switch (true) {
      case !!this.projectSettingsName:
        comp = this.projectSettingsName;
        break;
      case !!this.projectRestrictedUsersSettings:
        comp = this.projectRestrictedUsersSettings;
        break;
    }
    if (comp) {
      comp.onDelete(event);
    }
  }


  /**
   * bootstrap
   */
  private bootstrap() {

    this.project.sidePanels.forEach((panel) => {
      switch (panel.id) {
        case PanelComponents.SETTINGS_PROJECT_NAME.id:
          if (this.allowUpdateProject) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectSettingsNameComponent, });
          }
          break;
        case PanelComponents.SETTINGS_RESTRICTED_USERS.id:
          if (!this.allowUpdateProject) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectSettingsRestrictedUsersComponent, });
          }
          break;
        case PanelComponents.SETTINGS_PROJECT_E_MESSAGE.id:
          if (this.allowUpdateProject) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectSettingsEMessageComponent, });
          }
          break;
        case PanelComponents.SETTINGS_PROJECT_THEME.id:
          if (this.allowUpdateProject) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectSettingsThemeComponent, });
          }
          break;
        case PanelComponents.STATISTICS.id:
          if (this.allowUpdateProject) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectStatisticsComponent, });
          }
          break;
        case PanelComponents.PARTICIPANTS.id:
          if (this.allowViewParticipants) {
            this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ParticipantListComponent, });
          }
          break;
        case PanelComponents.PUBLISH.id:
          this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.PublishComponent, });
          break;
        case PanelComponents.SETTINGS_PROJECT_TAGGING.id:
          this.refList.push({ name: panel.name, icon: panel.icon, refItem: REFs.ProjectTaggingComponent, });
          break;
      }
    });

    switch (this.data.panel.id) {
      case PanelComponents.SETTINGS_PROJECT_NAME.id:
        this.showContent(this.REFs.ProjectSettingsNameComponent);
        break;
      case PanelComponents.SETTINGS_RESTRICTED_USERS.id:
        this.showContent(this.REFs.ProjectSettingsRestrictedUsersComponent);
        break;
      case PanelComponents.SETTINGS_PROJECT_E_MESSAGE.id:
        this.showContent(this.REFs.ProjectSettingsEMessageComponent);
        break;
      case PanelComponents.SETTINGS_PROJECT_THEME.id:
        this.showContent(this.REFs.ProjectSettingsThemeComponent);
        break;
      case PanelComponents.PARTICIPANTS.id:
        this.showContent(this.REFs.ParticipantListComponent);
        break;
      case PanelComponents.STATISTICS.id:
        this.showContent(this.REFs.ProjectStatisticsComponent);
        break;
      case PanelComponents.PUBLISH.id:
        this.showContent(this.REFs.PublishComponent);
        break;
      case PanelComponents.SETTINGS_PROJECT_TAGGING.id:
        this.showContent(this.REFs.ProjectTaggingComponent);
        break;
    }
  }

  ngOnInit() {
    this.allowUpdateProject = this.permissionService.allowsAction('update.project', this.project, { silent: true });
    this.allowViewParticipants =
      this.permissionService.allowsAction('view.participant', this.project._participant, { silent: true, project: this.project });

    this.bootstrap();
  }

}
