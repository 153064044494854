import { ReactionTypes } from "./reactions.enum";

export class NoteReaction {
    Id: string;
    AccountId: string;
    NoteId?: string;
    TaskV2Id?: string;
    AnonymousId: string;

    Type: ReactionTypes;

    UpdateDate: string;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}

export class CommentReaction {
    Id: string;
    AccountId: string;
    CommentId: string;
    AnonymousId: string;

    Type: ReactionTypes;

    UpdateDate: string;

    constructor(data: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }
}

