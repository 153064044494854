import { SocketChannel } from './socket-io-channel.class';

import { io } from 'socket.io-client';

export class SocketConnector {
  private _socket: any;
  private _channels: Array<SocketChannel> = [];
  private _options = {};
  private _defaultOptions = {
    auth: {
      headers: {}
    },
    authEndpoint: '/broadcasting/auth',
    host: null
  };

  constructor(
    private options: any
  ) {
    this.setOptions(options);
    this.connect();
  }

  listen(name, event, callback) {
    return this.channel(name).listen(event, callback);
  }

  channel(name) {
    if (!this._channels[name]) {
      this._channels[name] = new SocketChannel(this._socket, name, this.options);
    }
    return this._channels[name];
  }

  privateChannel(name) {
    return this.channel('private-' + name);
  }

  presenceChannel(name) {
    const ch = this.channel('presence-' + name);

    ch.here = (callback) => {
      ch.on('presence:subscribed', (members) => {
        callback(members.map((m) => {
          m.user_info;
        }));
      });
      return ch;
    };

    ch.joining = (callback) => {
      ch.on('presence:joining', (member) => {
        callback(member.user_info);
      });
      return ch;
    };

    ch.leaving = (callback) => {
      ch.on('presence:leaving', (member) => {
        callback(member.user_info);
      });
      return ch;
    };

    return ch;
  }

  leave(name) {
    const channels = [
      name,
      'private-' + name,
      'presence-' + name
    ];

    channels.forEach((name) => {
      if (this._channels[name]) {
        this._channels[name].unsubscribe();

        delete this._channels[name];
      }
    });
  }

  onSocketId(id: string): any {
    return { id };
  }

  /** PRIVATE METHODS */

  private setOptions(options) {
    this.options = this._extend(this._defaultOptions, options);
    return this.options;
  }

  private _extend(dst, src) {
    if (dst && src) {
      for (const key in src) {
        if (src.hasOwnProperty(key)) {
          dst[key] = src[key];
        }
      }
    }
    return dst;
  }

  private connect() {
    this._socket = io(this.options.host);
    this._socket.on('connect', () => {
      this.onSocketId(this._socket.id);
    });
    return this._socket;
  }
}
