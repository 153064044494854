<form [formGroup]="form">
  <mgc-form-field [label]="" class="input-wrapper" nobg [invalid]="submitted && f.UserAnswer.errors">
    <div class="field-wrapper">
      <input class="q-option" type="number" 
        formControlName="UserAnswer" placeholder="Your answer here"/>
    </div>
    <ng-container invalid-feedback *ngIf="submitted && f.UserAnswer.errors">
      <div *ngIf="f.UserAnswer.errors.pattern">Please enter a number.</div>
      <div *ngIf="f.UserAnswer.errors.required">This is a required field.</div>
    </ng-container>
  </mgc-form-field>
</form>