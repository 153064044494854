import { IPanelComponent, PanelComponents } from "@panel-components/panel-components";
import { customColorTheme, IThemeOptions } from "@libraries/theming";
import { IProjectLabels, PROJECT_LABELS_DEFAULTS } from "../project-labels";


export interface IProjectSettings {
    defaultPanel: IPanelComponent;
    sideDefaultPanel: IPanelComponent;
    restrictions: ISettingsRestrictions;
    defaultGroup: IDefaultGroup;
    publish: IPublishOptions;
    eMessage: IeMessage;
    theme: IThemeOptions;
    avatar: string;

    sidePanelIsOpen: boolean;
    enableTaskCompleteButton: boolean;
    collapseComments: boolean;
    hideDates: boolean;
    hideAuthor: boolean;
    enableRecycler: boolean;
    allowAnyAuthUserToComment: boolean;

    labels: IProjectLabels;

    toDb();
    toDbPublish();
    toJSON();
}

export interface ISettingsRestrictions {
    // "newcomerLevel":"level-0",
    // "underLevel":"level-1",
    // "showPresentation":true,
    // "showNotes":true,
    // "showPeople":true,
    // "showSettings":true,
    // "showStatus":true,
    // "showFiles":true,
    // "allowNotesWrite":true,
    // "allowCommentsWrite":true

    // newcomerLevel: string;
    underLevel: string;
    showPresentation: boolean;
    showNotes: boolean;
    showPeople: boolean;
    showSettings: boolean;
    showStatus: boolean;
    showFiles: boolean;
    allowNotesWrite: boolean;
    allowCommentsWrite: boolean

    toDb();
}

export interface IDefaultGroup {
    name: string;
    color: {
        name: string,
        bg: Number[],
        fg: Number[]
    };
}

export interface IeMessage {
    customUrl?: {
        enabled?: boolean,
        value?: string,
        label?: string
    };
}

export interface IPublishOptions {
    actionButton?: { // TODO: remove
        // fgColor?: string,
        // bgColor?: string,
        text?: string
    },
    fotameco?: {
        // primaryColor?: string, // TODO: remove
        embedId?: string,
    },
    footerDescription?: string,
    copySupport?: {
        enabled: boolean,
        email: string
    },
    showAuthModalOnEntryAfter?: number,
    goToNextStepLabel?: string,
    goToNextStepUrl?: string,
    useLandingHeroFrom?: 'brand' | 'project';
}

export class Restrictions implements ISettingsRestrictions {
    // newcomerLevel: string = 'level-4';
    underLevel: string = 'level-4';
    showPresentation: boolean = true;
    showNotes: boolean = true;
    showPeople: boolean = true;
    showSettings: boolean = true;
    showStatus: boolean = true;
    showFiles: boolean = true;
    allowNotesWrite: boolean = true;
    allowCommentsWrite: boolean = true

    constructor(data?: any) {
        for (let prop in data) {
            this[prop] = data[prop];
        }
    }

    toDb() {
        return this;
    }
}


export const PUBLISH_SHOWABLE_OPTIONS = [
    'showBackArrow',
    'showHero',
    'showBrandInfo',
    'showName',
    'showCarousel',
    'showDescription',
    'showShortDescription',
    'showActionButton',
    'showSummaryImage',
    'showFotameco',
    'autoselectOnMobile',
    'skipTopTask',
    'allowAnonymous',
    'showAuthModalOnEntry',
    'showAuthModalOnItemClick',
    'navigationToggle',
    'navigationStartsClosed',
    'allowGetEmbedCode',
    'goToNextStep'
];

export const PUBLISH_ENABLED_OPTIONS = [
    'showName',
    'showActionButton',
    'showFotameco',
    'autoselectOnMobile',
    'allowGetEmbedCode',
    'goToNextStep'
];

export class ProjectSettings implements IProjectSettings {
    private _defaultPanel: IPanelComponent;
    private _sideDefaultPanel: IPanelComponent;
    private _restrictions: ISettingsRestrictions;
    private _defaultGroup: IDefaultGroup;
    private _publish: IPublishOptions;
    private _eMessage: IeMessage;

    _labels: IProjectLabels = PROJECT_LABELS_DEFAULTS;
    set labels(data: IProjectLabels) {
        data || (data = {});
        this._labels = { ...PROJECT_LABELS_DEFAULTS, ...data };
    }
    get labels(): IProjectLabels {
        return this._labels;
    }

    sidePanelIsOpen: boolean;
    enableTaskCompleteButton: boolean;
    collapseComments: boolean;
    hideDates: boolean;
    hideAuthor: boolean;
    enableRecycler: boolean;
    allowAnyAuthUserToComment: boolean;
    avatar: string;

    private _themeData: IThemeOptions;
    set theme(object: IThemeOptions) {
        this._themeData = { themeId: object.themeId, type: object.type };
        if (object.custom_image) {
            this._themeData.custom_image = object.custom_image;
        }
        if (object.custom_color) {
            this._themeData.custom_color = object.custom_color;
        }
    }
    get theme(): IThemeOptions {
        return this._themeData
    }

    constructor(data: any = []) {
        for (let prop in data) {
            this[prop] = data[prop];
        }

        if (typeof this.sidePanelIsOpen === 'undefined') {
            this.sidePanelIsOpen = false;
        }
        if (typeof this.enableTaskCompleteButton === 'undefined') {
            this.enableTaskCompleteButton = true;
        }
        if (typeof this.collapseComments === 'undefined') {
            this.collapseComments = false;
        }
        if (typeof this.hideDates === 'undefined') {
          this.hideDates = false;
        }
        if (typeof this.hideAuthor === 'undefined') {
          this.hideAuthor = false;
        }
        if (typeof this.enableRecycler === 'undefined') {
          this.enableRecycler = false;
        }
        if (typeof this.allowAnyAuthUserToComment === 'undefined') {
            this.allowAnyAuthUserToComment = false;
        }
        if (!this._publish) {
            this.publish = null;
        }

        if (!this._themeData) {
            // because on theme we must pull some properties from publish, e.g. primaryColor, we need to update theme propery
            // doing it here after publish is initialized
            this.theme = this.pullThemeFromPublish();
        }

        if (!this._eMessage) {
            this.eMessage = null;
        }
    }

    private pullThemeFromPublish(): IThemeOptions {
        // a fallback to previous colors used on publish version
        return {
            themeId: 'custom_color',
            custom_color: {
                primary: this.publish.fotameco['primaryColor'] || customColorTheme.primaryColor,
                navigation: customColorTheme.navColor
            }
        };
    }

    get defaultPanel(): IPanelComponent {
        return this._defaultPanel || PanelComponents.DASHBOARD;
    }
    set defaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        this._defaultPanel = this.findPanel(id);
    }

    get sideDefaultPanel(): IPanelComponent {
        return this._sideDefaultPanel || PanelComponents.DASHBOARD;
    }
    set sideDefaultPanel(value) {
        let id = typeof (value) === 'object' ? value.id : value;
        if (id === PanelComponents.STATUS.id || id === PanelComponents.SETTINGS.id) {
            id = PanelComponents.DASHBOARD.id;
        }
        this._sideDefaultPanel = this.findPanel(id);
    }

    get restrictions(): ISettingsRestrictions {
        return this._restrictions || (this._restrictions = new Restrictions());
    }
    set restrictions(value: ISettingsRestrictions) {
        this._restrictions = new Restrictions(value);
    }

    set defaultGroup(value: IDefaultGroup) {
        this._defaultGroup = value;
    }
    get defaultGroup(): IDefaultGroup {
        return this._defaultGroup || {
            name: 'Folder',
            color: null
        }
    }

    private cb(len: number = 4) {
        return (Math.floor(Math.random() * 9999999999) + '').substr(0, len);
    }

    set publish(value: IPublishOptions) {
        value || (value = {})
        value.actionButton || (value.actionButton = {})
        value.actionButton.text || (value.actionButton.text = '')
        // value.actionButton.bgColor || (value.actionButton.bgColor = '#606682');
        // value.actionButton.fgColor || (value.actionButton.fgColor = '#fefefe');

        value.fotameco || (value.fotameco = {})
        // value.fotameco.primaryColor || (value.fotameco.primaryColor = 'rgb(96,102,130)');
        value.fotameco.embedId || (value.fotameco.embedId = 'TAG-' + this.cb(4) + '-' + this.cb(1));

        value.copySupport || (value.copySupport = {
            enabled: false,
            email: null
        })

        PUBLISH_SHOWABLE_OPTIONS.forEach(prop => {
            if (typeof value[prop] !== 'boolean') {
                value[prop] = PUBLISH_ENABLED_OPTIONS.indexOf(prop) >= 0;
            }
        })

        value.footerDescription || (value.footerDescription = '')
        value.useLandingHeroFrom || (value.useLandingHeroFrom = 'brand')

        this._publish = value;
    }
    get publish(): IPublishOptions {
        return this._publish;
    }

    set eMessage(value: IeMessage) {
        value || (value = {})
        value.customUrl || (value.customUrl = {})
        value.customUrl.enabled || (value.customUrl.enabled = false);
        value.customUrl.value || (value.customUrl.value = '');
        value.customUrl.label || (value.customUrl.label = 'Click here to Enter');

        this._eMessage = value;
    }
    get eMessage() {
        return this._eMessage;
    }


    private findPanel(id: string) {
        let dPanel: IPanelComponent = null;
        for (let panel in PanelComponents) {
            if (id === PanelComponents[panel].id) {
                dPanel = PanelComponents[panel];
                break;
            }
        }
        return dPanel;
    }

    toDb() {
        return {
            defaultPanel: this.defaultPanel.id,
            sideDefaultPanel: this.sideDefaultPanel.id,
            restrictions: this.restrictions.toDb(),
            sidePanelIsOpen: this.sidePanelIsOpen,
            enableTaskCompleteButton: this.enableTaskCompleteButton,
            collapseComments: this.collapseComments,
            hideDates: this.hideDates,
            hideAuthor: this.hideAuthor,
            enableRecycler: this.enableRecycler,
            allowAnyAuthUserToComment: this.allowAnyAuthUserToComment,
            labels: this.labels,
            publish: this.publish,
            eMessage: this.eMessage,
            theme: this.theme,
            avatar: this.avatar
        }
    }

    toDbPublish() {
        return {
            publish: this.publish,
        }
    }

    toJSON() {
        return {
            defaultGroup: this.defaultGroup,
            restrictions: this.restrictions,
            defaultPanel: this.defaultPanel,
            sideDefaultPanel: this. sideDefaultPanel,
            publish: this.publish,
            eMessage: this.eMessage,
            sidePanelIsOpen: this.sidePanelIsOpen,
            enableTaskCompleteButton: this.enableTaskCompleteButton,
            collapseComments: this.collapseComments,
            hideDates: this.hideDates,
            hideAuthor: this.hideAuthor,
            enableRecycler: this.enableRecycler,
            allowAnyAuthUserToComment: this.allowAnyAuthUserToComment,
            labels: this.labels,
            theme: this.theme,
            avatar: this.avatar
        }
    }
}
