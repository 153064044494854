import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'survey-progress',
  templateUrl: './survey-progress.component.html',
  styleUrls: ['./survey-progress.component.scss']
})
export class SurveyProgressComponent implements OnInit, OnChanges {
  @HostBinding('class.brand-survey-progress') surveyProgressClass = true;

  @Input() value: number;
  @Input() max: number;

  progress = 0;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      const value = Math.max(0, Math.min(this.value, this.max));
      this.progress = value / this.max * 100;
    }
  }

}
