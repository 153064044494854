import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'mgc-dialog',
    template: `
        <div class="mgc-dialog-drag"
            cdkDrag
            cdkDragRootElement=".cdk-overlay-pane"
            cdkDragHandle
        ></div>
        <div class="mgc-draggable-content">
        <ng-content></ng-content>
        </div>
    `
})
export class MgcDialogComponent {
    @HostBinding('class.mgc-dialog') setClass = true;
}

@Component({
    selector: 'mgc-dialog-popup',
    template: `
        <div
            cdkDrag
            cdkDragRootElement=".cdk-overlay-pane"
            cdkDragHandle
        ></div>
        <div>
            <ng-content></ng-content>
        </div>
    `
})
export class MgcDialogPopupComponent {
    @HostBinding('class.mgc-dialog') setClass = true;
}

