import { Injectable } from "@angular/core";
import { ISurvey, Survey } from "@board/surveys/survey.model";
import { TransferStateConverter } from "../transfer-state.converter";

type SerializedData = ISurvey

@Injectable({
  providedIn: 'root',
})
export class SurveyConverter extends TransferStateConverter<ISurvey> {
  constructor(
  ) {
    super();
  }

  fromTransferState(data: SerializedData): ISurvey {
    return new Survey(data);
  }

  toTransferState(data: ISurvey): SerializedData {
    return data;
  }
}