import { IPanelComponent, PanelComponents } from "@panel-components";
import { Note } from "@panel-components/notes/note";
import { Comment } from "@panel-components/comments/comment.model";

export class SnapshotTask {

    readonly SIDE_PANELS: Array<IPanelComponent> = [
        PanelComponents.EMPTY,
    ];
    readonly _type: string = 'scheduled-task';
    get sidePanels(): Array<IPanelComponent> {
        return this.SIDE_PANELS;
    }
    get mainPanel(): IPanelComponent {
        return PanelComponents.NOTES;
    }

    Id: string = null;
    AccountId: string = null;
    AssigneeId: string = null;
    ProjectId: string = null;
    GroupId: string = null;
    Name: string = null;
    Status: string = null;
    LaunchStatus: string = null;
    Settings: any = null;

    LaunchDate: Date = null;
    LaunchTimezone: Date = null;

    Notes: any[];
    CreateDate: string = null;
    UpdateDate: string = null;

    _notes: Note[] = [];
    _comments: Comment[] = [];
    _replies: Comment[] = [];

    _state: string = '';
    _notifications: number = 0;
    notifications: Notification[] = []; // Array<string | Notification> 
    _scrollY: number = 0;

    constructor(data: any) {

        for (let prop in data) {
            this[prop] = data[prop];
        }

        if (this.Notes.length && this.Notes.length) {
            this.handleMessages();
        }
    }

    get _defaultPanel() {
        return PanelComponents.NOTES;
    }

    sideDefaultPanel(allowFn: Function) {
        return PanelComponents.EMPTY;
    }

    handleMessages() {
        for (let n = 0; n < this.Notes.length; n++) {
            const noteData = this.Notes[n];
            let note = new Note(noteData);
            note._comments = noteData.Comments && noteData.Comments.length ? noteData.Comments.length : 0;
            this._notes.push(note);

            if (!note._comments ) continue;
            for (let c = 0; c < noteData.Comments.length; c++) {
                const commentData = noteData.Comments[c];
                let comment = new Comment(commentData);
                comment._replies = commentData.Replies && commentData.Replies.length ? commentData.Replies.length : 0;
                this._comments.push(comment);

                if (!comment._replies) continue;
                for (let r = 0; r < commentData.Replies.length; r++) {
                    const replyData = commentData.Replies[r];
                    this._replies.push(new Comment(replyData));
                }
            }

        }
    }

    notes() {
        return this._notes;
    }

    comments(noteId: string) {
        return this._comments.filter(c => c.NoteId == noteId);
    }

    replies(noteId: string, commentId: string) {
        return this._replies.filter(r => r.ReplyToCommentId == commentId)
    }
}
