import { Directive, EventEmitter, ElementRef, Input, HostListener, Output } from '@angular/core';

import { FileUploader } from './file-uploader.class';

@Directive({
  selector: "[cpFileSelect]"
})
export class FileSelectDirective {
  @Input() public uploader: FileUploader;
  @Output() public onFileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  private element: ElementRef;
  public constructor(element: ElementRef) {
    this.element = element;
  }

  public isEmptyAfterSelection(): boolean {
    return !!this.element.nativeElement.attributes.multiple;
  }

  @HostListener('change')
  public onChange(): any {
    let files = this.element.nativeElement.files;
    this.uploader.addToQueue(files);

    this.onFileSelected.emit(files);
    if (this.isEmptyAfterSelection()) {
      this.element.nativeElement.value = '';
    }
  }
}