
import { map, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, of as observableOf } from 'rxjs';

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Comment } from './comment.model';
import { BaseService } from '@board/_services/base-service.service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { AlertService, ServiceAlertClass } from '@rallysite/components/alert';
import { Note } from '@panel-components/notes/note';
import { Notification } from '@board/_components/notification';
import { IModelService } from '@panel-components/notes/note.service';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { ITaskV2BlocksMetadata } from '@items/task-v2/blocks/task-v2-blocks-metadata';

export interface ICommentServiceOptions {
  snapshot?: boolean,
  note?: Note,
  taskv2BlocksMetadata?: ITaskV2BlocksMetadata,
  comment?: Comment,
  commentParent?: Comment,
  limit?: number,
  offset?: number,
  force?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class CommentService extends BaseService implements IModelService {

  private dataStore: {}
  private _endPoint: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    public alertService: AlertService,
    private ga: GoogleAnalyticsService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api/comments`;
    this.dataStore = {};
  }

  comments$(options: ICommentServiceOptions) {
    let key = this.key(options);
    if (!this.dataStore[key]) {
      this.dataStore[key] = {
        _comments: <BehaviorSubject<Comment[]>>new BehaviorSubject(null),
      }
    }
    return this.dataStore[key]._comments.asObservable();
  }

  private key(options: ICommentServiceOptions) {
    const id = options.taskv2BlocksMetadata ? options.taskv2BlocksMetadata.taskv2Id : options.note.Id
    let k = (options.snapshot ? 'snap__' : '') + id;
    if (options.comment && options.comment.Id) {
      k += `_${options.comment.Id}`;
    }
    return k;
  }

  total(options): number {
    let key = this.key(options);
    return this.dataStore[key].total;
  }

  setTotal(options, value): number {
    let key = this.key(options);
    this.initStorage(options);
    return this.dataStore[key].total = value;
  }

  getComments(options: ICommentServiceOptions): Observable<Comment[]> {
    let key = this.key(options);
    if (this.inStorage(options)) {
      return observableOf(this.getFromStorage(options));
    }

    let paramsData = {
      'limit': (options.limit || 15).toString(),
      'offset': (options.offset || 0).toString(),
    }
    if (options.taskv2BlocksMetadata) {
      paramsData['filter[TaskV2Id]'] = options.taskv2BlocksMetadata.taskv2Id;
    } else {
      paramsData['filter[NoteId]'] = options.note.Id;
    }

    if (options.comment) {
      paramsData['filter[ReplyToCommentId]'] = options.comment.Id;
    }

    let params = new HttpParams({
      fromObject: paramsData
    });

    return this.http.get(this._endPoint, { params: params, observe: 'response' }).pipe(
      map(response => {
        if (options.offset === 0) {
          this.initStorage(options);
        }

        let data = response.body;
        let headers = response.headers as HttpHeaders;
        this.dataStore[key].total = parseInt(headers.get('X-Items-Count'), 10) || 0;

        for (let k in data) {
          this.pushToStorage(key, new Comment(data[k]));
        };

        return this.getFromStorage(options);
      }), catchError(error => {
        console.log('Could not load comments');
        this.alertService.error(error, ServiceAlertClass.ALERTS.COMMENT_LIST);
        return observableOf(null);
      })
    )
  }

  create(options: ICommentServiceOptions) {
    let comment = options.comment;
    let commentParent = options.commentParent;
    // swicth key options
    let key = this.key({
      note: options.note,
      taskv2BlocksMetadata: options.taskv2BlocksMetadata,
      comment: options.commentParent
    });
    return this.http.post(this._endPoint, comment.toDb()).pipe(
      map(data => {
        let newComment = new Comment(data);
        newComment._state = 'new';
        newComment._attachments = comment._attachments;

        setTimeout(() => {
          newComment._state = ''
        }, 100);

        if (!comment.isReply) {

          if (options.taskv2BlocksMetadata) {
            if (options.taskv2BlocksMetadata.nComments === 0) {
              this.initStorage(options);
            }
            options.taskv2BlocksMetadata.nComments++;
          } else {
            if (options.note._comments === 0) {
              this.initStorage(options);
            }
            options.note._comments++;
          }

          this.ga.platformEvent('comment');
        } else {
          if (commentParent._replies === 0) {
            // Init parent comment storage first so we can append comment to it store
            this.initStorage({
              ...options,
              comment: commentParent
            });
          }
          commentParent._replies++;
          this.ga.platformEvent('reply');
        }

        this.pushToStorage(key, newComment, true).emit(key);

        return newComment;
      }), catchError(error => {
        console.log('Could not create comment.');
        this.alertService.error(error, ServiceAlertClass.ALERTS.COMMENT_CREATE);
        return observableOf(null);
      })
    )
  }

  update(comment: Comment) {
    return this.http.put(`${this._endPoint}/${comment.Id}`, comment.toDb()).pipe(
      map(data => {
        comment.UpdateDate = data['UpdateDate'];
        return comment;
      }), catchError(error => {
        console.log('Could not update comment. STATUS: ' + error.status);
        this.alertService.error(error, ServiceAlertClass.ALERTS.COMMENT_UPDATE);
        return observableOf(null);
      })
    )
  }

  remove(options: ICommentServiceOptions) {
    let comment = options.comment;
    // swicth key options
    let key = this.key({
      note: options.note,
      taskv2BlocksMetadata: options.taskv2BlocksMetadata,
      comment: options.commentParent
    });
    comment._state = 'remove';
    return this.http.delete(`${this._endPoint}/${comment.Id}`).pipe(
      map((data) => {
        if (!comment.isReply) {
          if (options.taskv2BlocksMetadata) {
            options.taskv2BlocksMetadata.nComments--;
          } else {
            options.note._comments--;
          }
        } else if (options.commentParent) {
          options.commentParent._replies--;
        }
        this.removeFromStorage(key, comment).emit(key);
        return true;
      }),
      catchError(error => {
        console.log('Could not remove comment.');
        this.alertService.snackError(error);
        return observableOf(false)
      })
    )
  }

  /**
   * @param options
   */
  clean(options: ICommentServiceOptions) {
    Object.keys(this.dataStore).forEach(key => {
      const id = options.taskv2BlocksMetadata ? options.taskv2BlocksMetadata.taskv2Id : options.note.Id;
      if (new RegExp('^' + id).test(key)) {
        delete (this.dataStore[key]);
      }
    });
    return this;
  }

  handleNotification(notification: Notification, note: Note) {
    if (notification.isComment() && notification.data.NoteId === note.Id) {
      note._hasNewComments = true;
    }
  }

  pushNotification(comment: Comment): Observable<boolean> {
    return this.http.post(`${this._endPoint}/${comment.Id}/push`, comment.toDb()).pipe(
      map(data => {
        this.alertService.snackSuccess('Participants were succesfully notified');
        return true;
      }),
      catchError(error => {
        console.log('Could not push comment notification');
        this.alertService.snackWarning('Couldn\'t push comment notification');
        return observableOf(false);
      })
    )
  }

  orderImages(newOrder: Array<string>, comment: Comment) {
    return this.http.put(`${this._endPoint}/${comment.Id}/order`, {
      'UpdateDate': comment.UpdateDate,
      'order': newOrder
    }).pipe(
      map(data => {
        comment.Settings.imageOrder = data['imageOrder'];
        comment.UpdateDate = data['UpdateDate'];
        return comment;
      }),
      catchError(error => {
        console.log('Could not update comment. Image Order: ' + error.status);
        return observableOf(false);
      })
    )
  }

  private initStorage(options: ICommentServiceOptions) {
    let key = this.key(options);
    // make sure _comments subject exists exists
    this.comments$(options);
    this.dataStore[key] = Object.assign(this.dataStore[key], {
      comments: [],
      total: 0,
    })
  }

  private inStorage(options: ICommentServiceOptions) {
    if (options.force) {
      return false;
    }
    let key = this.key(options);
    if (!this.dataStore[key] || !this.dataStore[key].comments) {
      return false;
    }

    if (this.dataStore[key].comments.length < options.limit + options.offset && this.dataStore[key].comments.length < this.dataStore[key].total) {
      return false
    }

    return !!this.dataStore[key].comments;
  }

  private pushToStorage(key: string, comment: Comment, inFront: boolean = false) {
    if (inFront) {
      this.dataStore[key].comments.splice(0, 0, comment);
    } else {
      this.dataStore[key].comments.push(comment);
    }
    return this;
  }

  private removeFromStorage(key: string, comment: Comment) {
    if (this.dataStore[key].comments) {
      this.dataStore[key].comments.forEach((c, i) => {
        if (c.Id === comment.Id) {
          this.dataStore[key].comments.splice(i, 1);
        }
      });
    }
    return this;
  }

  private getFromStorage(options: ICommentServiceOptions | string) {
    let key: string;
    let n: any;

    if (typeof options === 'string') {
      key = options;
      n = Object.assign({}, this.dataStore[key]).comments;
    } else {
      key = this.key(options);
      n = Object.assign({}, this.dataStore[key]).comments.slice(0, options.limit + options.offset);
    }

    return n;
  }

  private emit(key: string) {
    this.dataStore[key]._comments.next(this.getFromStorage(key));
  }

}
