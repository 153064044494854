import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileUploadModule } from './file-upload';
import { UploaderComponent } from './uploader.component';

@Pipe({ name: 'blobUrl' })
export class BlobUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(file: any): SafeResourceUrl {
    let url = URL.createObjectURL(file.rawFile);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

// @Pipe({ name: 'bgImageUrl' })
// export class BgImageUrlPipe implements PipeTransform {

//   constructor(
//     private sanitizer: DomSanitizer
//   ) { }

//   transform(file: any): SafeResourceUrl {
//     let url = URL.createObjectURL(file.rawFile);
//     let sn = this.sanitizer.bypassSecurityTrustResourceUrl(url);
//     // console.log(['fileImageURL', 'url(' + sn + ')']);
//     return 'url(' + sn + ')';
//   }
// }

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    UploaderComponent,
    BlobUrlPipe,
    // BgImageUrlPipe
  ],
  exports: [
    UploaderComponent,
  ]
})
export class UploaderModule { }
