import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { JwtHelperService } from './jwthelper.service';
import { JWT_OPTIONS } from './jwtoptions.token';

import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  tokenGetter: () => string | Promise<string>;
  headerName: string;
  authScheme: string;
  whitelistedDomains: Array<string | RegExp>;
  throwNoTokenError: boolean;
  skipWhenExpired: boolean;
  customHeaders: Array<{
    name: string,
    fnValue: () => string
  }>;

  constructor(
    @Inject(JWT_OPTIONS) config: any,
    public jwtHelper: JwtHelperService,
  ) {
    this.tokenGetter = config.tokenGetter;
    this.headerName = config.headerName || 'Authorization';
    this.authScheme =
      config.authScheme || config.authScheme === ''
        ? config.authScheme
        : 'Bearer ';
    this.whitelistedDomains = config.whitelistedDomains; //auth-service.module is fetching this info
    this.throwNoTokenError = config.throwNoTokenError || false;
    this.skipWhenExpired = config.skipWhenExpired;
    this.customHeaders = config.customHeaders;
  }

  isWhitelistedDomain(request: HttpRequest<any>): boolean {
    let requestUrl: URL;
    try {
      requestUrl = new URL(request.url);
      return (
        this.whitelistedDomains.findIndex(
          domain =>
            typeof domain === 'string'
              ? domain === requestUrl.host
              : domain instanceof RegExp ? domain.test(requestUrl.host) : false
        ) > -1
      );
    } catch (err) {
      // if we're here, the request is made
      // to the same domain as the Angular app
      // so it's safe to proceed
      return true;
    }
  }

  handleInterception(
    token: string,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {

    if (!token && this.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    if (this.isWhitelistedDomain(request)){
      request = request.clone({
        setHeaders: this.headers(token)
      });
    }

    // if (token && tokenIsExpired && this.skipWhenExpired) {
    //   request = request.clone();
    // } else if (token && this.isWhitelistedDomain(request)) {
    //   request = request.clone({
    //     setHeaders: this.headers(token)
    //   });
    // }
    return next.handle(request);
  }

  private headers(token) {
    let headers = {
      ['X-Requested-With']: 'XMLHttpRequest',
      ['Content-Type']: 'application/json'
    }

    let tokenIsExpired: boolean = token ? this.jwtHelper.isTokenExpired(token) : true;;
    if (token && !tokenIsExpired){
      headers[this.headerName] =  `${this.authScheme}${token}`;
    }

    if (this.jwtHelper.socketId) {
      headers['X-Socket-ID'] = this.jwtHelper.socketId;
    }

    for (let h in this.customHeaders) {
      let header = this.customHeaders[h];
      let hVal = header.fnValue();
      if (hVal) {
        headers[header.name] = hVal;
      }
    }
    // if (this.currentIdService.currentPersonaId){
    //   headers['X-Persona-ID'] = this.currentIdService.currentPersonaId;
    // }
    // if (this.currentIdService.currentParticipantId){
    //   headers['X-Participant-ID'] = this.currentIdService.currentParticipantId;
    // }

    return headers;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: any = this.tokenGetter();

    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string) => {
          return this.handleInterception(asyncToken, request, next);
        }));
    } else {
      return this.handleInterception(token, request, next);
    }
  }
}