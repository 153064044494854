import { Component, Input, HostBinding } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
    selector: 'mgc-form-field',
    template: `
        <label *ngIf="label">{{label}}</label>
        <div class="form-field" [class.invalid-feedback]="invalid" [class.disabled]="disabled">
            <ng-content></ng-content>
        </div>
        <div *ngIf="invalid" class="invalid-feedback">
            <ng-content select="[invalid-feedback]"></ng-content>
        </div>
    `,
    styleUrls: ['./form-field.scss']
})
export class MgcFormFieldComponent {
    @HostBinding('class.mgc-form-field') formFieldClass = true;
    @HostBinding('class.mgc-form-control') formControlClass = true;
    @Input() label = '';
    @Input() invalid: boolean | ValidationErrors = false;
    @Input() disabled = false;
}
