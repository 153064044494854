import {
    ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener,
    Input, OnDestroy, OnInit, Output, QueryList,
    ViewChildren, HostBinding, ChangeDetectionStrategy, ViewChild
} from '@angular/core'
import { GalleryService } from '../services/gallery.service';
import { ISize, SIZES } from '../size.class';
import { fromEvent, Observable, forkJoin } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { GalleryImage } from '../gallery-image.model';

import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'gallery-grid',
    templateUrl: './gallery-grid.component.html',
    styleUrls: ['./gallery-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryGridComponent implements OnInit, OnDestroy {
    @HostBinding('class.mgc-gallery-grid') galleryGridClass = true;
    @HostBinding('class.mgc-scroll-area') scrollAreaClass = true;

    gallery: Array<any> = []
    images: Array<any> = []
    rowIndex: number = 0
    rightArrowInactive: boolean = false
    leftArrowInactive: boolean = false

    prevGalleryWidth: number = 0;

    @Input('data') data: Array<any>;
    @Input('imageQuality') minimalQualityCategory: 'small' | 'medium' | 'large' = 'small';
    @Input() multiSelect: boolean = true;

    @Input('flexBorderSize') providedImageMargin: number = 10
    @Input('flexImageSize') providedImageSize: number = 2
    @Input('maxRowsPerPage') rowsPerPage: number = 200

    @Output() toggleSelect = new EventEmitter<string>();

    @ViewChild('galleryContainer') galleryContainer: ElementRef
    @ViewChildren('imageElement') imageElements: QueryList<any>

    @HostListener('scroll', ['$event']) triggerCycle(event: any): void {
        this.scaleGallery()
    }

    @HostListener('window:resize', ['$event']) windowResize(event: any): void {
        this.render()
    }

    constructor(
        private http: HttpClient,
        public galleryService: GalleryService,
        public changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

    }

    prevData: Array<any>;
    ngDoCheck() {
        if (this.data !== undefined) {
            if (!this.prevData) {
                this.fetchDataAndRender(this.data);
                this.changed = true;
            } else {
                let chC = this.data.filter(item => {
                    return !this.prevData.find(i => i.id === item.id)
                })
                let chP = this.prevData.filter(item => {
                    return !this.data.find(i => i.id === item.id)
                })
                if (chC.length || chP.length) {
                    this.fetchDataAndRender(this.data);
                    this.changed = true;
                }
            }
            this.prevData = this.data.slice();
        }
    }

    ngAfterViewChecked() {
        if (this.changed) {
            this.changed = false;
            this.scaleGallery();
        }
    }

    changed: boolean = true;
    // ngOnChanges(changes: SimpleChanges): void {
    //     if (changes['data'] && changes['data'].currentValue) {

    //         if (changes['data'].firstChange) {
    //             this.fetchDataAndRender(this.data);
    //             this.changed = true;
    //         } else {
    //             let chC = changes['data'].currentValue.filter(item => {
    //                 return !changes['data'].previousValue.find(i => i.id === item.id)
    //             })
    //             let chP = changes['data'].previousValue.filter(item => {
    //                 return !changes['data'].currentValue.find(i => i.id === item.id)
    //             })
    //             if (chC.length || chP.length) {
    //                 this.fetchDataAndRender(this.data);
    //                 this.changed = true;
    //             }
    //         }
    //     }
    // }

    ngOnDestroy(): void {
    }

    toggleSelectImg(img: any) {
        if (this.multiSelect && img.inUse) {
            return;
        }
        let id = img.id;
        this.images.forEach(img => {
            if (img.id === id) {
                img.selected = !img.selected;
            } else if (!this.multiSelect) {
                img.selected = false;
            }
        })

        // send back a notification to update as well without rendering current screen
        this.toggleSelect.next(id);
    }

    /**
     * direction (-1: left, 1: right)
     */
    navigate(direction: number): void {
        if ((direction === 1 && this.rowIndex < this.gallery.length - this.rowsPerPage)
            || (direction === -1 && this.rowIndex > 0)) {
            this.rowIndex += (this.rowsPerPage * direction)
        }
        this.refreshNavigationErrorState()
    }

    calcImageMargin(gw: number = undefined): number {
        // const galleryWidth = gw !== undefined ? gw : this.getGalleryWidth();
        // const ratio = galleryWidth / 1920
        // return Math.round(Math.max(1, this.providedImageMargin * ratio))

        return this.providedImageMargin;
    }

    findImage(img) {
        for (let i = 0; i < this.images.length; i++) {
            if (this.images[i].small.url === img.small) {
                return this.images[i];
            }
        }
        return null;
    }

    trackByRowIdx(index) {
        return index;
    }

    trackByImgId(index, item) {
        return item.id;
    }

    private fetchDataAndRender(data: Array<any>): void {

        this.images = data.map((img) => {
            let existing = this.findImage(img)
            if (existing) {
                return existing;
            }
            return new GalleryImage(img, SIZES);
        });

        this.render();
    }

    private render(): void {
        this.gallery = []

        let tempRow = [this.images[0]]
        let currentRowIndex = 0
        let i = 0

        for (i; i < this.images.length; i++) {
            while (this.images[i + 1] && this.shouldAddCandidate(tempRow, this.images[i + 1])) {
                i++
            }
            if (this.images[i + 1]) {
                tempRow.pop()
            }
            this.gallery[currentRowIndex++] = tempRow

            tempRow = [this.images[i + 1]]
        }
    }

    private shouldAddCandidate(imgRow: Array<any>, candidate: any): boolean {
        let idealH = this.calcIdealHeight();
        const oldDifference = idealH - this.calcRowHeight(imgRow);
        imgRow.push(candidate)
        const newDifference = idealH - this.calcRowHeight(imgRow);

        if ((oldDifference < 0 && newDifference < 0) || (oldDifference > 0 && newDifference > 0)) {
            return Math.abs(oldDifference) > Math.abs(newDifference)
        }

        return false;
    }

    private calcRowHeight(imgRow: Array<any>): number {

        const originalRowWidth = this.calcOriginalRowWidth(imgRow);
        let gw = this.getGalleryWidth();

        const ratio = (gw - (imgRow.length - 1) * this.calcImageMargin(gw)) / originalRowWidth;
        const rowHeight = imgRow[0][this.minimalQualityCategory].height * ratio;

        return rowHeight;
    }

    private calcOriginalRowWidth(imgRow: Array<any>): number {
        let originalRowWidth = 0
        let idealH = this.calcIdealHeight();
        imgRow.forEach(img => {
            const individualRatio = idealH / img[this.minimalQualityCategory].height
            img[this.minimalQualityCategory].width = img[this.minimalQualityCategory].width * individualRatio
            img[this.minimalQualityCategory].height = idealH;
            originalRowWidth += img[this.minimalQualityCategory].width
        })

        return originalRowWidth
    }

    private calcIdealHeight(): number {
        // let gw = this.getGalleryWidth();
        // let ih = gw / (80 / this.providedImageSize) + 100;

        // return ih;

        return SIZES[this.minimalQualityCategory].height / (40 / this.providedImageSize) + 100;
    }

    private getGalleryWidth(): number {
        if (this.galleryContainer.nativeElement.clientWidth === 0) {
            // for IE11
            return this.galleryContainer.nativeElement.scrollWidth
        }
        return this.galleryContainer.nativeElement.clientWidth
    }

    private scaleGallery(): void {
        let imageCounter = 0
        let maximumGalleryImageHeight = 0

        let loadImages: Observable<boolean>[] = [];

        let gw = this.getGalleryWidth();
        this.gallery.slice(this.rowIndex, this.rowIndex + this.rowsPerPage)
            .forEach(imgRow => {
                const originalRowWidth = this.calcOriginalRowWidth(imgRow)

                if (imgRow !== this.gallery[this.gallery.length - 1]) {
                    const ratio = (gw - (imgRow.length - 1) * this.calcImageMargin()) / originalRowWidth

                    imgRow.forEach((img: any) => {
                        img.width = img[this.minimalQualityCategory].width * ratio
                        img.height = img[this.minimalQualityCategory].height * ratio
                        maximumGalleryImageHeight = Math.max(maximumGalleryImageHeight, img.height)
                        if (this.checkForAsyncLoading(img, imageCounter++)) {
                            loadImages.push(this.loadImage(img));
                        }
                    })
                } else {
                    imgRow.forEach((img: any) => {
                        img.width = img[this.minimalQualityCategory].width;
                        img.height = img[this.minimalQualityCategory].height;
                        maximumGalleryImageHeight = Math.max(maximumGalleryImageHeight, img.height)
                        if (this.checkForAsyncLoading(img, imageCounter++)) {
                            loadImages.push(this.loadImage(img));
                        }
                    })
                }
            })

        if (loadImages.length) {
            forkJoin(loadImages).subscribe(results => {
                this.refreshNavigationErrorState()
                this.render();
                this.changeDetectorRef.detectChanges();
            });
        } else {
            this.refreshNavigationErrorState()
            // this.changeDetectorRef.detectChanges();
        }

        this.changeDetectorRef.detectChanges();
    }

    private adjustSize(img: GalleryImage, size: ISize = null, quality: 'small' | 'medium' | 'large' = 'medium') {
        let idealH = this.calcIdealHeight();
        const ratio = idealH / size.height
        img[this.minimalQualityCategory].width = size.width * ratio;
        img[this.minimalQualityCategory].height = idealH;
    }

    private loadImage(img: GalleryImage): Observable<any> {
        var image = new Image();
        img.loading = true;
        let $loadedImg = fromEvent(image, "load").pipe(
            take(1),
            map(event => {
                this.adjustSize(img, {
                    width: event.target['width'],
                    height: event.target['height']
                });
                img[this.minimalQualityCategory].loaded = true;
                img.src = img[this.minimalQualityCategory].url;
                img.loadedInGallery = true;
                img.loading = false
                return true;
            })
        );

        image.src = img[this.minimalQualityCategory].url;
        return $loadedImg;
    }

    private checkForAsyncLoading(img: GalleryImage, imageCounter: number): boolean {
        if (!this.imageElements) {
            return false;
        }
        const imageElements = this.imageElements.toArray()

        // if image is meant to be loaded && is in view
        if (img.loadedInGallery ||
            (imageElements.length > 0 &&
                imageElements[imageCounter] &&
                this.isScrolledIntoView(imageElements[imageCounter].nativeElement))) {

            return !img[this.minimalQualityCategory].loaded;
        }

        return false;
    }

    private isScrolledIntoView(element: any): boolean {
        const elementTop = element.getBoundingClientRect().top
        const elementBottom = element.getBoundingClientRect().bottom

        return elementTop < window.innerHeight && elementBottom >= 0 && (elementBottom > 0 || elementTop > 0)
    }

    private refreshNavigationErrorState(): void {
        this.leftArrowInactive = this.rowIndex == 0
        this.rightArrowInactive = this.rowIndex > (this.gallery.length - this.rowsPerPage)
    }
}
