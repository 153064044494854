import { finalize, take } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core'

import * as moment from "moment";
import { IFile } from '@panel-components/notes/attachments/file-model';
import { FileService } from '@panel-components/notes/attachments/file.service';

@Component({
    selector: 'file-details',
    templateUrl: './file-details.component.html',
    styleUrls: ['./file-details.component.scss'],
    providers: [FileService]
})

export class FileDetailsComponent implements OnInit {

    @Input() file: any;
    @Input() downloadable: boolean = false;

    private isDownloading: boolean = false;

    tz: string;

    constructor(
        private fileService: FileService) {

    }

    download($ev, file: IFile) {
        if ($ev) {
            $ev.stopPropagation();
        }

        if (this.isDownloading) {
            return;
        }

        file.inProgress = true;
        this.fileService.download(file).pipe(
            finalize(() => {
                file.inProgress = false
            }),
            take(1)
        ).subscribe();
    }

    ngOnInit() {
        this.tz = moment.tz.guess();
    }

}