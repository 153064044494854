
import { Component, Input, AfterViewInit, ElementRef, HostBinding, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Project } from './project';
import { ProjectService } from './project.service';

import { Umbrella } from '@board/items/umbrella';
import { takeWhile, take } from 'rxjs/operators';
import { BoardViewSettingsService } from '@board/_components/view-settings';
import { MessageBusService, MessageBusActions } from '@rallysite/global-services';
import { environment } from 'environments/environment';
import { ParticipantService } from '@panel-components/participants';

@Component({
  selector: 'board-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, AfterViewInit {
  @HostBinding() class = 'mgc-project sortable-item';
  @HostBinding('class.not-confirmed') get notConfirmedClass(): boolean {
    return this.project && this.project._participant && this.project._participant.Confirmation === 'pending';
  }
  @HostBinding('class.active') get activeClass(): boolean {
    return this.currentItem && this.project && this.project.Id === this.currentItem.Id && this.project._state !== 'removing';
  }
  @HostBinding('class.remove') get removeClass(): boolean {
    return this.project && this.project._state === 'remove';
  }
  @HostBinding('class.highlight') get highlightClass(): boolean {
    return this.project && this.project._state === 'removing';
  }
  @HostBinding('class.ex-target') get exTragetClass(): boolean {
    return this.project && this.project._state === 'ex-target';
  }
  @HostBinding('class.ex-target-done') get exTragetDoneClass(): boolean {
    return this.project && this.project._state === 'ex-target-done';
  }

  @HostBinding('attr.id') get __id() {
    return this.project ? this.project.Id : '';
  }

  private alive = true;

  @Input() currentItem: any;
  @Input() project: Project;
  @Input() umbrella: Umbrella;

  detailedView = false;
  summaryView = false;

  isOpening = false;
  @Output() deleteRequest = new EventEmitter<unknown>();

  constructor(
    private projectService: ProjectService,
    private participantService: ParticipantService,
    private element: ElementRef,
    private dialog: MatDialog,
    private boardViewSettingsService: BoardViewSettingsService,
    private messageBus: MessageBusService,
  ) { }

  open(project: Project) {
    if (environment.redirectIfBRP && this.project.isBRP) {
      this.projectService.redirect(environment.redirectIfBRP, project);
      return;
    }

    this.isOpening = true;
    if (project.isNotConfirmed()) {
      // this.dialog.open(ParticipantConfirmModal, {
      //   data: {
      //     project: project
      //   }
      // })
      this.project._participant.Confirmation = 'yes';
      this.participantService.confirm(this.project._participant, this.project).pipe(
        take(1)).subscribe(result => {
          if (result) {
            this.projectService.openProject(project).subscribe();
          }
        });
    } else {
      this.projectService.openProject(project).subscribe();
    }
  }

  ngOnInit() {
    this.boardViewSettingsService.viewMode$.pipe(
      takeWhile(() => this.alive))
      .subscribe(viewMode => {
        this.detailedView = viewMode === 'detailed';
        this.summaryView = viewMode === 'summary';
      });
  }

  ngAfterViewInit() {
    if (this.project._state === 'ex-target') {
      setTimeout(() => {
        if (this.project._scrollY >= 0) {
          this.messageBus.publish(MessageBusActions.SCROLL_Y, this.project._scrollY);
        }
        this.project._state = 'ex-target-done';
      }, 10);
    }

    if (this.project._state === 'removing') {
      setTimeout(() => {
        this.element.nativeElement.scrollIntoView({ block: 'center', behavior: 'instant' });
      }, 10);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
