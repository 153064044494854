<div class="avatar" *ngIf="type!=='overlay'" [title]="account.Name || 'Anonymous'">
    <div *ngIf="!account.Id" [style.height.px]="size*0.86" [style.width.px]="size*0.86">
        <svg x="0" y="0" viewBox="0 0 24 24" width="100%" height="100%">
            <path stroke="none" fill=gray d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        </svg>
    </div>

    <ng-container *ngIf="account.Id && !account.Thumbnail">
        <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
            <circle 
                [attr.cx]="size/2" 
                [attr.cy]="size/2" 
                [attr.r]="size/2 - sw"
                [attr.stroke]="sc" 
                [attr.stroke-width]="sw"
                [attr.fill]="fillColor" />
            <text 
                [attr.x]="size/2"
                [attr.y]="textPosY" 
                [attr.font-size]="fontSize"
                [attr.fill]="textColor||'white'" 
                text-anchor="middle" 
                [style.line-height.px]="fontSize">{{account.Initials || 'O'}}</text>
        </svg>
    </ng-container>

    <ng-container *ngIf="account.Id &&  account.Thumbnail">
        <img [ngClass]="{'mat-elevation-z4': !!elevation}" [alt]="account?.Name || 'Account avatar related image'" [src]="account | avatarUrl:timestamp" [width]="size" [height]="size" style="border-radius: 50%; display: block;">
    </ng-container>

    <ng-container *ngIf="previewAvatarFile">
        <img class="preview" [alt]="account?.Name || 'Account avatar related image'" [src]="previewAvatarFile | avatarBlobUrl" [width]="size" [height]="size" style="border-radius: 50%; display: block;">
    </ng-container>

</div>

<div class="halo" *ngIf="halo">
    <svg x="0" y="0" [attr.height]="size+hp*2" [attr.width]="size+hp*2">
        <circle 
            stroke="currentColor"
            [attr.cx]="(size+hp*2)/2" 
            [attr.cy]="(size+hp*2)/2" 
            [attr.r]="(size+hp*2)/2 - sw"
            fill="none"/>
    </svg>
</div>

<div class="overlay" *ngIf="processing">
    <svg x="0" y="0" [attr.height]="size" [attr.width]="size">
        <circle 
            [attr.cx]="size/2" 
            [attr.cy]="size/2" 
            [attr.r]="size/2"
            stroke="none" 
            fill="currentColor"/>
    </svg>
    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</div>

