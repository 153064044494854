<div @animation>

    <span [innerHTML]="message"></span>

    <div class="survey-actions">
        <button *ngFor="let action of actions" (click)="onClose(action)">
            <span [innerHTML]="action.label"></span>
        </button>
    </div>

</div>