import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from '@board/_services/base-service.service';
import { AppConfig, APP_CONFIG } from '@rallysite/config';
import { Project } from '@board/items/project/project';
import { GoogleAnalyticsService } from '@libraries/google-analytics/google-analytics.service';
import { ScheduledTask } from '@items/scheduled-task/scheduled-task';
import { IEmailPayload } from './email-payload.interface';

@Injectable()
export class EmailService extends BaseService {

  private _processingState: BehaviorSubject<number>;

  private _endPoint: string;
  error: any;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private ga: GoogleAnalyticsService
  ) {
    super();
    this._endPoint = `${this.config.endpoint}/api`;
    this._processingState = <BehaviorSubject<number>>new BehaviorSubject(-1);
  }

  get processingState$(): Observable<number> {
    return this._processingState.asObservable();
  }
  resetProcessingState$(): Observable<number> {
    this._processingState.next(-1);
    return this.processingState$;
  }

  scheduleEmail(project: Project, email: IEmailPayload, task: ScheduledTask) {
    const emailPayload = Object.assign({}, email);
    emailPayload.body = '';
    emailPayload.to = ['*'];
    delete emailPayload.recipients;

    this._processingState.next(EmailService.STATES.PROCESSING);
    this.http.post(`${this._endPoint}/${project.Id}/schedule-email`, emailPayload)
      .subscribe((data: ScheduledTask) => {
        task.__update({
          ScheduledEmail: data['ScheduledEmail'],
          UpdateDate: data['UpdateDate']
        });
        this._processingState.next(EmailService.STATES.DONE);
      },
        error => {
          console.log('Could not schedule email.');
          if (error.status === 401) {
            this._processingState.next(EmailService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(EmailService.STATES.ERROR);
          }
        }
      )
  }

  sendEmail(project: Project, email: IEmailPayload) {
    const emailPayload = Object.assign({}, email);
    emailPayload.to = email.recipients.map(e => e.address),
    delete emailPayload.recipients;

    this._processingState.next(EmailService.STATES.PROCESSING);

    this.ga.platformEvent('email', `${project.Name}`, email.recipients.length);

    this.http.post(`${this._endPoint}/${project.Id}/email`, emailPayload)
      .subscribe(
        data => {
          this._processingState.next(EmailService.STATES.DONE);
        },
        error => {
          console.log('Could not send email.');
          if (error.status === 401) {
            this._processingState.next(EmailService.STATES.UNAUTHORIZED);
          } else {
            this._processingState.next(EmailService.STATES.ERROR);
          }
        }
      )
  }


}