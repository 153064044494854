import { ICustomColorOptions } from "./theme-options.interface";
import { ITheme } from "./theme.interface";

export const monacoBlueTheme: ITheme = {
  id: 'monaco_blue',
  name: 'Monaco Blue',
  primaryColor: '#4e7be9',
};

export const sunsetOrangeTheme: ITheme = {
  id: 'sunset_orange',
  name: 'Sunset Orange',
  primaryColor: '#f0942f',
};

export const indianaRedTheme: ITheme = {
  id: 'indiana_red',
  name: 'Indiana Red',
  primaryColor: '#df2f4d',
};

export const tonalGrayTheme: ITheme = {
  id: 'tonal_gray',
  name: 'Tonal Gray',
  primaryColor: ['#434343', '#666666'],
};

/**
 *  CUSTOM COLORS THEME
 */
export const DARK_THEME_COLOR: string = '#192031';
export const LIGHT_THEME_COLOR: string = '#FFFFFF';

export const DEFAULT_CUSTOM_COLOR_THEME: ICustomColorOptions = {
  primary: '#222222',
  navigation: LIGHT_THEME_COLOR
};

export const customColorTheme: ITheme = {
  id: 'custom_color',
  name: 'Custom Color',
  primaryColor: DEFAULT_CUSTOM_COLOR_THEME.primary,
  navColor: DEFAULT_CUSTOM_COLOR_THEME.navigation
};


export const publicColorTheme: ITheme = {
  id: 'public_color',
  name: 'Public Color',
  primaryColor: '#606682',
  navColor: LIGHT_THEME_COLOR
};


export const orangeColorTheme: ITheme = {
  id: 'orange_color',
  name: 'Orange Color',
  primaryColor: '#d25e2a',
  navColor: LIGHT_THEME_COLOR
};


/**
 *  CUSTOM IMAGE THEME
 */
export const customImageTheme: ITheme = {
  id: 'custom_image',
  name: 'Custom Image',
  primaryColor: '#df2f4d',
  navColor: 'rgba(0,0,0,.2)'
};
