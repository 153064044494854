import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'mgc-menu-item',
    template: `
        <button mat-menu-item>
            <mat-icon *ngIf="icon" [svgIcon]="icon" [attr.color]="iconColor"></mat-icon>
            <span><ng-content></ng-content></span>
        </button>
    `,
    // styleUrls: ['./button.scss']
})
export class MgcMenuItemComponent {
    @HostBinding('class.mgc-menu-item') menuItemClass = true;

    @Input() icon = '';
    @Input() iconColor = '';

}
