<form [formGroup]="settingsForm">

  <mat-slide-toggle [style.margin-bottom.px]="10" class="mgc-toggle" formControlName="eMessageCustomUrlEnabled"
    [checked]="f.eMessageCustomUrlEnabled.value" (change)="toggleMessageCustomUrl($event)">Show custom url in all
    e-messages you send to participants </mat-slide-toggle>

  <mgc-form-field label="Custom label/text from admin">
    <div class="field-wrapper">
      <input tabindex="-1" formControlName="eMessageCustomUrlLabel">
      <span class="placeholder" *ngIf="!f.eMessageCustomUrlLabel.value">e.g. Click here to Enter</span>
    </div>
  </mgc-form-field>

  <mgc-form-field label="Custom url from admin">
    <div class="field-wrapper">
      <input tabindex="-1" formControlName="eMessageCustomUrlValue">
      <span class="placeholder" *ngIf="!f.eMessageCustomUrlValue.value">http://</span>
    </div>
  </mgc-form-field>

</form>