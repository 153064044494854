import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {APP_CONFIG, AppConfig} from "@rallysite/config";
import {AuthService} from '@rallysite/auth-service';
import {User, UserService} from '@rallysite/user';
import {take} from 'rxjs/operators';
import {sendBeacon} from "./helpers";

export interface IPageViewTrack {
  uid: string,
  aid: string,
  source: string;
  pageurl: string;
  embedpageurl?: string;
  referrerurl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageViewService {
  endpoint: string = '';

  constructor(
    @Inject(APP_CONFIG) readonly config: AppConfig,
    private authService: AuthService,
    private userService: UserService,
  ) {
    if (config.pageViewTrackEndpoint) {
      this.endpoint = config.pageViewTrackEndpoint;
    }
  }

  public send(track: Partial<IPageViewTrack>): Promise<void> {
    // if url has any get params -> return and don't track
    if (!this.shouldBeTracked(track)) {
      return Promise.resolve();
    }

    if (!this.endpoint && !track.pageurl) {
      return Promise.resolve();
    }

    this.authService.authenticate(false).pipe(take(1),)
      .subscribe((user: User) => {
        const uid = user ? user.Id : null;
        const aid = !user ? this.authService.anonymousId : null;

        const payload = {
          uid: uid || '',
          aid: aid || '',
          source: 'btba',
          ...track,
        } as Record<string, string>;

        const url = `${this.endpoint}?${new URLSearchParams(payload).toString()}`;

        if (navigator && navigator.sendBeacon) {
          navigator.sendBeacon(url);
          return Promise.resolve();
        }

        return sendBeacon(url);

      });
  }

  /**
   * We don't track pageview on urls having more than 1 param
   * @param track
   * @private
   */
  private shouldBeTracked(track: Partial<IPageViewTrack>): boolean {
    if (!track.pageurl.includes('?') || track.pageurl.endsWith('?')) {
      return true;
    }

    const exp = track.pageurl.split('?');
    const params = new URLSearchParams(exp[1]);

    // In case any unsafe param is in url - we skip it
    const unsafeParams = [
      'token',
      'eh',
    ];

    for (const safeParam of unsafeParams) {
      if (params.has(safeParam)) {
        return false;
      }
    }

    return true;
  }
}
