import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { SurveyModalComponent } from './survey-modal.component';

@Component({
  selector: 'survey-button',
  templateUrl: './survey-button.html',
  styleUrls: ['./survey-button.scss'],
})
export class SurveyButtonComponent {

  constructor(
    private dialog: MatDialog,
    public snackBarRef: MatSnackBarRef<SurveyButtonComponent>,
  ) {
  }

  engage() {

    this.snackBarRef.dismiss();

    this.dialog.open(SurveyModalComponent, {
      maxWidth: '500px',
      minHeight: '60vh',
      height: '90vh'
    }).afterClosed().pipe(
      take(1))
      .subscribe(() => {
        //
      })

  }

  ngOnInit(){
  }

}
